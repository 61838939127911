import punycode from 'punycode';
import { EditorState, RawDraftContentState } from 'draft-js';
import { editorStateFromRaw } from 'megadraft';
import { isString } from 'lodash';

export interface CountType {
  wordCount: number;
  charCount: number;
}

const getCountFromText = (text: string): CountType => {
  if (!text) {
    return { wordCount: 0, charCount: 0 };
  }
  const charCount = punycode.ucs2.decode(text).length;
  const words = text.split(' ').filter((word: string) => word.length > 0);
  const wordCount =
    words.length === 1 ? (words[0].length === 0 ? 0 : 1) : words.length;

  return { charCount, wordCount };
};

const getCountFromEditorState = (e: EditorState): CountType => {
  const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed
  const contentState = e.getCurrentContent();
  const plainText = contentState.getPlainText('');
  const text = plainText.replace(regex, '').trim(); // replace above characters w/ nothing
  const charCount = punycode.ucs2.decode(text).length;
  const words = text.split(' ').filter((word: string) => word.length > 0);
  const wordCount =
    words.length === 1 ? (words[0].length === 0 ? 0 : 1) : words.length;

  const blocksCount = contentState
    .getBlocksAsArray()
    .filter(
      (block) =>
        block.get('type') === 'atomic' &&
        block.getIn(['data', 'type']) === 'aside',
    )
    .reduce(
      (count, block) => {
        const asideDraftContent = block.getIn(['data', 'frameDraft']);
        const asideTitle = block.getIn(['data', 'frameTitle']);
        const asideTitleCount = getCountFromText(asideTitle);
        let asideCount = { wordCount: 0, charCount: 0 };

        // when we just instanciated the block
        if (!asideDraftContent && !asideTitle) {
          return count;
        }

        // here we convert from immutable struct to plain JS because in the case of the aside block plugin
        // parent draft convert asideDraftContent to immutable Map.
        if (asideDraftContent) {
          let asideDraftContentJSON = asideDraftContent;

          if (isString(asideDraftContentJSON)) {
            try {
              asideDraftContentJSON = JSON.parse(asideDraftContent);
            } catch {}
          }

          asideCount = getCountFromEditorState(
            editorStateFromRaw(
              asideDraftContentJSON.toJS
                ? asideDraftContentJSON.toJS()
                : asideDraftContentJSON,
            ),
          );
        }

        return {
          wordCount:
            count.wordCount + asideCount.wordCount + asideTitleCount.wordCount,
          charCount:
            count.charCount + asideCount.charCount + asideTitleCount.charCount,
        };
      },
      { wordCount: 0, charCount: 0 },
    );

  return {
    charCount: charCount + blocksCount.charCount,
    wordCount: wordCount + blocksCount.wordCount,
  };
};

export function getCount(
  content: string | null | RawDraftContentState,
  type?: 'Draft',
) {
  return content
    ? type
      ? getCountFromEditorState(editorStateFromRaw(content))
      : getCountFromText(content as string)
    : { wordCount: 0, charCount: 0 };
}
