import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import type {
  CategoryWhereUniqueInput,
  CreatePageCategory,
  CreatePageCategoryVariables,
  UpdatePageCategory,
  UpdatePageCategoryVariables,
} from '../../../../__generated__/queries-web';
import type { Category } from '../../types';
import { PAGE_CATEGORY_FRAGMENT } from '../fragments/category.web.graphql';
import { getParentMutationVariables, getTabsMutationVariables } from '../utils';

/// UPDATE CATEGORY ////////////////////////////////////////////
const UPDATE_PAGE_CATEGORY = gql`
  mutation UpdatePageCategory(
    $where: CategoryWhereUniqueInput!
    $data: CategoryUpdateInput!
  ) {
    updateCategory(where: $where, data: $data) {
      ...CategoryFragment
    }
  }
  ${PAGE_CATEGORY_FRAGMENT}
`;

export const useUpdatePageCategoryIsHeadline = () => {
  const [updatePageCategory] = useMutation<
    UpdatePageCategory,
    UpdatePageCategoryVariables
  >(UPDATE_PAGE_CATEGORY);

  return useCallback(
    (id: string, isHeadlined: boolean) =>
      updatePageCategory({
        variables: {
          where: { id },
          data: { isHeadlined },
        },
        fetchPolicy: 'no-cache',
      }),
    [updatePageCategory],
  );
};

export const useUpdatePageCategoryLockerId = () => {
  const [updatePageCategory] = useMutation<
    UpdatePageCategory,
    UpdatePageCategoryVariables
  >(UPDATE_PAGE_CATEGORY);

  return useCallback(
    (
      id: string | undefined,
      lockerId: string | null,
      noCacheUpdate?: boolean,
    ) => {
      if (!id) return Promise.resolve();
      return updatePageCategory({
        variables: {
          where: { id },
          data: { lockerId },
        },
        fetchPolicy: noCacheUpdate ? 'no-cache' : undefined,
      });
    },
    [updatePageCategory],
  );
};

/// CREATE CATEGORY ////////////////////////////////////////////
const CREATE_PAGE_CATEGORY = gql`
  mutation CreatePageCategory($data: CategoryCreateInput!) {
    createCategory(data: $data) {
      ...CategoryFragment
    }
  }
  ${PAGE_CATEGORY_FRAGMENT}
`;

export interface UseSavePageCategoryVariables {
  data: Partial<Category>;
  where: CategoryWhereUniqueInput;
}

export const useSavePageCategory = () => {
  const [updateCategory, { client }] = useMutation<
    UpdatePageCategory,
    UpdatePageCategoryVariables
  >(UPDATE_PAGE_CATEGORY);
  const [createCategory] = useMutation<
    CreatePageCategory,
    CreatePageCategoryVariables
  >(CREATE_PAGE_CATEGORY);

  return ({ data, where = {} }: UseSavePageCategoryVariables) => {
    const { parent, ...restData } = data;
    const newVariables = {
      data: {
        ...restData,
        tabs: getTabsMutationVariables({ data: restData, where }, client),
      },
      where,
    };

    return where.id
      ? updateCategory({
          variables: newVariables as UpdatePageCategoryVariables,
        })
      : createCategory({
          variables: {
            ...newVariables,
            data: {
              ...newVariables.data,
              parent: getParentMutationVariables({ data, where }),
            },
          } as CreatePageCategoryVariables,
        });
  };
};
