import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { generatePath, Link } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { paths } from '../../../../routing/Router/paths';
import { useStyles } from './styles';
import { Typography } from '@mui/material';
import { CardState } from '../../CardEdit/types';
import { StatusSelector } from '../CardStatusSelector';

interface CardAppProps {
  onSave(card: CardState): void;
  card: CardState;
  handleStatusChange(value: string): void;
}

export const CardAppBar = function ({
  onSave,
  card,
  handleStatusChange,
}: CardAppProps) {
  const classes = useStyles();

  return (
    <AppBar position="fixed">
      <Toolbar className={classes.content}>
        <div className={classes.rightContent}>
          <Link
            to={{
              pathname: generatePath(paths.CARD_LIST, {
                brandKey: card.brandKey,
              }),
              search: `status=${card.status}`,
            }}
          >
            <IconButton size="large">
              <ArrowBack className={classes.backArrow} />
            </IconButton>
          </Link>

          <Typography variant="h6">{card.title || 'Nouvel encart'}</Typography>
        </div>
        <div className={classes.leftContent}>
          <Button
            color="inherit"
            className={classes.saveButton}
            onClick={() => onSave(card)}
            data-testid="card=form-save"
          >
            Sauvegarder
          </Button>
          <StatusSelector
            handleStatusChange={handleStatusChange}
            card={card}
            onSave={onSave}
          />
        </div>
      </Toolbar>
    </AppBar>
  );
};
