import { Auth } from '@prismamedia/one-components';
import { Role } from '@prismamedia/one-user';
import { TagProviderName } from '../__generated__/queries-web';

export const auth = new Auth(config.API_ONE_AUTH);

export const canCreateTag = (tagProviders: TagProviderName[] = []) => {
  if (
    !auth.isAuthenticated() ||
    !tagProviders.find((tagProvider) => {
      return [TagProviderName.Tag, TagProviderName.VideoTag].includes(
        tagProvider,
      );
    })
  ) {
    return false;
  }

  return (
    auth.user.isSuperAdmin() ||
    auth.user.isAdmin() ||
    auth.user.hasRole(Role.WebAdmin)
  );
};
