import makeStyles from '@mui/styles/makeStyles';

const TAG_CARD_HEIGHT = 65;

export const useStyles = makeStyles((theme) => ({
  deleteIcon: {
    borderRadius: '50%',
    boxShadow: 'none',
    color: 'white',
    display: 'none', // We showing this button only on hover
    position: 'absolute',
    top: '50%',
    right: '-7px',
    transform: 'translateY(-50%)',
    zIndex: theme.zIndex.drawer + 1,
  },
  innerWrapper: {
    alignSelf: 'center',
    flex: '1',
    padding: theme.spacing(2, 4, 2, 2),
  },
  title: {
    fontWeight: 500,
    lineHeight: '1.25rem',
    pointerEvents: 'none',
  },
  wrapper: {
    alignItems: 'stretch',
    borderRadius: '0',
    border: `solid 1px ${theme.palette.grey[200]}`,
    boxShadow: 'none',
    display: 'flex',
    marginBottom: theme.spacing(1.5),
    minHeight: `${TAG_CARD_HEIGHT}px`,
    overflow: 'visible',
    textAlign: 'left',
    position: 'relative',

    '&:hover $deleteIcon': {
      display: 'inline-flex',
    },

    '&:last-child': {
      marginBottom: '0',
    },
  },
}));
