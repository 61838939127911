import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    marginBottom: 20,
    marginTop: 20,
    position: 'relative',
    cursor: 'pointer',
  },
  content: {
    width: '80%',
    position: 'relative',
  },
  media: {
    height: 100,
    width: '20%',
    backgroundColor: theme.palette.grey[200],
  },
  header: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)',
    '& > span': {
      color: '#d0021b',
      fontSize: '14px',
    },
  },
  brandkey: {
    position: 'absolute',
    color: 'white',
    backgroundColor: 'black',
    top: 5,
    left: 5,
    padding: '0 4px',
  },
}));
