import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  baseIcon: {
    position: 'absolute',
    top: '4px',
    left: '4px',
    borderRadius: '90px',
  },
  hoveredUnselectedIcon: {
    backgroundColor: '#FFFFFF',
    color: 'black',
    opacity: 0.4,
    '&:hover': {
      opacity: 1,
    },
  },
  notHoveredUnselectedIcon: {
    display: 'none',
  },
  selectedIcon: {
    backgroundColor: theme.palette.secondary.main,
    color: '#FFFFFF',
  },
  hoveredSelectedIcon: {
    border: '1px solid #FFFFFF',
  },
}));
