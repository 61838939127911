import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  CreateMachineTagForDuplication,
  CreateMachineTagForDuplicationVariables,
} from '../../../__generated__/queries-web';
import { GET_ARTICLE_QUERY } from '../../../apollo/queries/articles.web.graphql';

export const DUPLICATE_TO_TOPIC_MACHINE_TAG = 'topicassignmentto:id';

const CREATE_MACHINE_TAG_FOR_DUPLICATION = gql`
  mutation CreateMachineTagForDuplication($data: MachineTagCreateInput!) {
    createMachineTag(data: $data) {
      id
    }
  }
`;

export const useCreateMachineTag = () => {
  const [createMachineTag] = useMutation<
    CreateMachineTagForDuplication,
    CreateMachineTagForDuplicationVariables
  >(CREATE_MACHINE_TAG_FOR_DUPLICATION, {
    refetchQueries: [GET_ARTICLE_QUERY],
  });

  return useCallback(
    (variables: CreateMachineTagForDuplicationVariables) =>
      createMachineTag({ variables }),
    [createMachineTag],
  );
};
