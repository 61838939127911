import {
  getCard_card_cardTags,
  getCard_card_cardTags_tag,
} from '../../../../../__generated__/queries-web';

export type GetCardTag = Omit<getCard_card_cardTags_tag, '__typename'> & {
  order: number;
};
export const getCardTag = ({
  order,
  id,
  title,
  type,
}: GetCardTag): getCard_card_cardTags => ({
  __typename: 'CardTag',
  order,
  tag: {
    __typename: 'Tag',
    id,
    title,
    type,
  },
});
