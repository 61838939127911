import { Card, CardContent, Fab, Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';
import React, { FC } from 'react';
import { TagType } from '../../../__generated__/queries-web';
import { TAG_ITEM_DATA_TESTID } from './constants';
import { Thumbnail } from './Thumbnail';
import { useStyles } from './styles';

interface TagItemProps {
  onDelete?: { (item: Record<'id' | 'title', string>): void };
  id: string;
  title: string;
  type: TagType | 'Event' | 'Guide';
}
const TagItem: FC<TagItemProps> = ({ id, onDelete, title, type }) => {
  const classes = useStyles();

  return (
    <Card className={classes.wrapper} data-testid={TAG_ITEM_DATA_TESTID}>
      <Thumbnail id={id} type={type} />

      <CardContent className={classes.innerWrapper}>
        <Typography className={classes.title} component="h6">
          {title}
        </Typography>
      </CardContent>

      {onDelete && (
        <Fab
          className={classes.deleteIcon}
          color="primary"
          onClick={() => {
            onDelete({ id, title });
          }}
          size="small"
        >
          <Delete />
        </Fab>
      )}
    </Card>
  );
};

export { TagItem };
