import { gql, useQuery } from '@apollo/client';

const GET_CATEGORY_MEDIAS = gql`
  query GetSearchCategoryMedias($where: CategoryWhereUniqueInput!) {
    category(where: $where) {
      medias
    }
  }
`;

export const useSearchCategoryMedias = (id: string) =>
  useQuery<any, any>(GET_CATEGORY_MEDIAS, {
    variables: { where: { id } },
  });
