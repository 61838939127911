import { BrandKey, CategoryFormat } from '../../../__generated__/queries-web';
import { CATEGORY_MOCK } from '../../../tests/constants';

export const MOCK_DEFAULT_ROUTE_PARAMS = {
  brandKey: BrandKey.TEL,
  format: CategoryFormat.Event,
  lvl1: CATEGORY_MOCK.id,
  lvl2: CATEGORY_MOCK.children[0].id,
};

/// DATA-TEST-ID ////////////////////////////////////////////
export enum DataTestIdListPage {
  CARD_WRAPPER = 'CARD_WRAPPER',
  FAVORITES_FILTER_CHECKBOX = 'FAVORITES_FILTER_CHECKBOX',
  FAVORITES_ICON_WRAPPER = 'FAVORITES_ICON_WRAPPER',
  EDIT_WRAPPER = 'EDIT_WRAPPER',
  FILTER_BUTTON = 'FILTER_BUTTON',
  LIST_BREADCRUMBS_LINK = 'LIST_BREADCRUMBS_LINK',
  LIST_BREADCRUMBS_MENU = 'LIST_BREADCRUMBS_MENU',
  LIST_BREADCRUMBS_TEXT = 'LIST_BREADCRUMBS_TEXT',
  LIST_WRAPPER = 'LIST_WRAPPER',
  PAGE_CREATE_WRAPPER = 'PAGE_CREATE_WRAPPER',
  SKELETONS_WRAPPER = 'SKELETONS_WRAPPER',
}

export enum DataTestIdEditPage {
  CATEGORY_FORM_TITLE_LABEL = 'CATEGORY_FORM_TITLE_LABEL',
  CATEGORY_FORM_DESCRIPTION_LABEL = 'CATEGORY_FORM_DESCRIPTION_LABEL',
}
