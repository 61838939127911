import { makeStyles } from '@mui/styles';

const SIDEBAR_WIDTH = 180;

export const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    zIndex: 3,
  },
  innerWrapper: {
    display: 'flex',
    minHeight: '600px',
    maxWidth: '1100px',
    width: `calc(100vw - (${theme.spacing(4)} * 2))`,
  },
  title: {
    marginLeft: theme.spacing(1),
    flex: '1',
  },
  wrapperPreview: {
    bottom: '0',
    display: 'flex',
    overflowY: 'auto',
    padding: theme.spacing(3),
    paddingLeft: `calc(${SIDEBAR_WIDTH}px + (${theme.spacing(
      4,
    )} + ${theme.spacing(3)}))`,
    paddingTop: '90px',
    position: 'absolute',
    right: '0',
    textAlign: 'center',
    top: 0,

    '& > div': {
      margin: 'auto',
    },
  },
  wrapperEmptyPreview: {
    color: theme.palette.grey[200],
    width: '100%',

    '& svg': {
      fontSize: '80px',
    },
  },
}));
