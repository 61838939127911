import { ExpandLess, ExpandMore, PhotoCamera } from '@mui/icons-material';
import {
  Chip,
  CircularProgress,
  Collapse,
  IconButton,
  ListSubheader,
  Typography,
} from '@mui/material';
import { isNull } from 'lodash';
import React, { FC } from 'react';
import { PhotoCollapsesTypes } from '../types';
import { useStyles } from './styles';

interface PhotoCollapseProps {
  collapseType: PhotoCollapsesTypes;
  defaultItemsLength: number | null;
  Icon?: JSX.Element;
  isOpen: boolean;
  isPhotoLoading: boolean;
  onCollapseClick: (collapse: PhotoCollapsesTypes | null) => void;
}
export const PhotoCollapse: FC<PhotoCollapseProps> = ({
  children,
  collapseType,
  defaultItemsLength,
  Icon,
  isOpen,
  isPhotoLoading,
  onCollapseClick,
}) => {
  const classes = useStyles({ isOpen, isPhotoLoading });
  const hasLoadedDefaultData = !isNull(defaultItemsLength);

  return (
    <>
      {/**
       * Collapse title
       */}
      <ListSubheader className={classes.collapseHeader} disableSticky>
        <Typography className={classes.headerLine} variant="body2" gutterBottom>
          <span className={classes.suggestText}>
            {collapseType} {Icon}
          </span>

          <span>
            <Chip
              className={classes.nbItemsBadge}
              component="span"
              disabled={!hasLoadedDefaultData}
              icon={
                !hasLoadedDefaultData && isPhotoLoading ? (
                  <CircularProgress size="14px" />
                ) : (
                  <PhotoCamera fontSize="small" />
                )
              }
              label={!hasLoadedDefaultData ? '--' : defaultItemsLength}
              size="small"
              variant="outlined"
            />

            <IconButton
              aria-label="expand"
              onClick={() => {
                onCollapseClick(!isOpen ? collapseType : null);
              }}
              size="large"
            >
              {isOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </span>
        </Typography>
      </ListSubheader>

      {/**
       * Collapse content
       */}
      <Collapse className={classes.wrapperCollapse} in={isOpen} timeout={0}>
        {children}
      </Collapse>
    </>
  );
};
