import {
  BrandKey,
  CategoryFormat,
  CategoryStatus,
} from '../__generated__/queries-web';
import { getCategoryMock, getRandomBrandKey, getRandomFormat } from './utils';

export const CATEGORY_MOCK = {
  brandKey: BrandKey.BONE,
  bodyDraft: '',
  categoryHistoryCount: 9,
  createdAt: '2020-01-31T13:09:54.000Z',
  format: CategoryFormat.Event,
  description: null,
  isHeadlined: false,
  id: 'ID_0',
  level: 1,
  lockedAt: null,
  lockedBy: null,
  medias: null,
  parent: null,
  path: '',
  publishedAt: '2020-01-31T13:09:54.000Z',
  slug: '',
  status: CategoryStatus.Published,
  subtitle: '',
  tabs: [],
  tags: '[]',
  title: 'TITLE 0',
  updatedAt: '2021-06-02T15:23:10.000Z',
  editedAt: '2021-06-02T15:23:10.000Z',
  urls: null,
  __typename: 'Category' as any,
  children: [
    {
      brandKey: BrandKey.BIN,
      bodyDraft: '',
      categoryHistoryCount: 9,
      createdAt: '2020-01-31T13:09:54.000Z',
      format: CategoryFormat.Event,
      description: null,
      isHeadlined: false,
      id: 'ID_1',
      level: 2,
      lockedAt: null,
      lockedBy: null,
      medias: null,
      parent: null,
      path: '',
      publishedAt: '2020-01-31T13:09:54.000Z',
      slug: '',
      status: CategoryStatus.Published,
      subtitle: '',
      tabs: [],
      tags: '[]',
      title: 'TITLE 1',
      updatedAt: '2021-06-02T15:23:10.000Z',
      editedAt: '2020-01-31T13:09:54.000Z',
      urls: null,
      __typename: 'Category' as any,
      children: [
        {
          brandKey: BrandKey.BIN,
          bodyDraft: '',
          categoryHistoryCount: 8,
          createdAt: '2020-10-09T08:01:17.000Z',
          format: CategoryFormat.Event,
          description:
            "Business Insider France s'associe à l'opération \"La France des solutions\", lancée par l'association Reporters d'Espoirs.",
          isHeadlined: true,
          id: 'ID_1_1',
          level: 3,
          lockedAt: null,
          lockedBy: null,
          medias:
            '[{"key":null,"caption":null,"format":"illustration","iframely":{"html":"<img src=\\"https:\\/\\/one.img.pmdstatic.net\\/fit\\/http.3A.2F.2Fprd2-bone-image.2Es3-website-eu-west-1.2Eamazonaws.2Ecom.2Fbin.2F2020.2F10.2F09.2F02cdd05f-c54e-4b6c-a22b-dbf9cb8cf4bd.2Ejpeg\\/6000x4000\\/quality\\/80\\/thumbnail.jpg\\" width=\\"6000\\" height=\\"4000\\" \\/>","links":{"app":[{"rel":["app","ssl","html5"],"type":"text\\/html","html":"<img src=\\"https:\\/\\/one.img.pmdstatic.net\\/fit\\/http.3A.2F.2Fprd2-bone-image.2Es3-website-eu-west-1.2Eamazonaws.2Ecom.2Fbin.2F2020.2F10.2F09.2F02cdd05f-c54e-4b6c-a22b-dbf9cb8cf4bd.2Ejpeg\\/6000x4000\\/quality\\/80\\/thumbnail.jpg\\" width=\\"6000\\" height=\\"4000\\" \\/>","media":{"width":6000,"height":4000}}],"thumbnail":[{"rel":["thumbnail","ssl"],"type":"image\\/jpeg","href":"https:\\/\\/one.img.pmdstatic.net\\/fit\\/http.3A.2F.2Fprd2-bone-image.2Es3-website-eu-west-1.2Eamazonaws.2Ecom.2Fbin.2F2020.2F10.2F09.2F02cdd05f-c54e-4b6c-a22b-dbf9cb8cf4bd.2Ejpeg\\/200x133\\/quality\\/80\\/thumbnail.jpg","media":{"width":200,"height":133}}]},"meta":{"path":"\\/bin\\/2020\\/10\\/09\\/02cdd05f-c54e-4b6c-a22b-dbf9cb8cf4bd.jpeg","sha1":"f962a538d3703cc9886fca85431760cf44375801","width":6000,"height":4000,"site":"PMD_BONE_Image","ratio":1.5,"updatedAt":"2020-10-09T08:20:56+0200","date":"2020-10-09T06:20:56.000Z","url":"http:\\/\\/prd2-bone-image.s3-website-eu-west-1.amazonaws.com\\/bin\\/2020\\/10\\/09\\/02cdd05f-c54e-4b6c-a22b-dbf9cb8cf4bd.jpeg","canonical":"http:\\/\\/prd2-bone-image.s3-website-eu-west-1.amazonaws.com\\/bin\\/2020\\/10\\/09\\/02cdd05f-c54e-4b6c-a22b-dbf9cb8cf4bd.jpeg","credit":"Antonio Janeski\\/Unsplash","id":"02cdd05f-c54e-4b6c-a22b-dbf9cb8cf4bd","createdAt":"2020-10-09T08:20:56+0200","namespace":"bin","fileSize":4273251,"extension":"jpeg"},"rel":["app","ssl","html5"]},"focusPoint":{"x":null,"y":null,"relativeX":null,"relativeY":null},"target":null,"rel":null,"source":null}]',
          parent: null,
          path: '',
          publishedAt: '2020-10-09T08:01:17.000Z',
          slug: '',
          status: CategoryStatus.Archived,
          subtitle: '',
          tabs: [],
          tags: '[]',
          title: 'La France des solutions',
          updatedAt: '2021-06-02T15:23:10.000Z',
          editedAt: '2020-01-31T13:09:54.000Z',
          urls: null,
          __typename: 'Category' as any,
          children: [],
        },
        {
          brandKey: BrandKey.BIN,
          bodyDraft: '',
          categoryHistoryCount: 7,
          createdAt: '2020-02-13T10:10:32.000Z',
          format: CategoryFormat.Event,
          description:
            "Dans cette édition spéciale, Business Insider France s'intéresse à l'innovation responsable et à ses enjeux. Vous découvrirez sur cette page les résultats de la 2e édition de notre \"Baromètre de l'économie positive et durable\", réalisé par YouGov.",
          isHeadlined: false,
          id: 'ID_1_2',
          level: 3,
          lockedAt: null,
          lockedBy: null,
          medias:
            '[{"key":null,"caption":null,"format":"illustration","iframely":{"html":"<img src=\\"https:\\/\\/one.img.pmdstatic.net\\/fit\\/http.3A.2F.2Fprd2-bone-image.2Es3-website-eu-west-1.2Eamazonaws.2Ecom.2Fbin.2F2020.2F02.2F13.2Ff9b473d3-179e-4014-9fa9-af1752fb1cd6.2Ejpeg\\/2000x1500\\/quality\\/80\\/thumbnail.jpg\\" width=\\"2000\\" height=\\"1500\\" \\/>","links":{"app":[{"rel":["app","ssl","html5"],"type":"text\\/html","html":"<img src=\\"https:\\/\\/one.img.pmdstatic.net\\/fit\\/http.3A.2F.2Fprd2-bone-image.2Es3-website-eu-west-1.2Eamazonaws.2Ecom.2Fbin.2F2020.2F02.2F13.2Ff9b473d3-179e-4014-9fa9-af1752fb1cd6.2Ejpeg\\/2000x1500\\/quality\\/80\\/thumbnail.jpg\\" width=\\"2000\\" height=\\"1500\\" \\/>","media":{"width":2000,"height":1500}}],"thumbnail":[{"rel":["thumbnail","ssl"],"type":"image\\/jpeg","href":"https:\\/\\/one.img.pmdstatic.net\\/fit\\/http.3A.2F.2Fprd2-bone-image.2Es3-website-eu-west-1.2Eamazonaws.2Ecom.2Fbin.2F2020.2F02.2F13.2Ff9b473d3-179e-4014-9fa9-af1752fb1cd6.2Ejpeg\\/200x150\\/quality\\/80\\/thumbnail.jpg","media":{"width":200,"height":150}}]},"meta":{"path":"\\/bin\\/2020\\/02\\/13\\/f9b473d3-179e-4014-9fa9-af1752fb1cd6.jpeg","sha1":"71c72ad8fa3b9e27156dca7eab33a5c99002ef49","width":2000,"height":1500,"site":"PMD_BONE_Image","ratio":1.33,"updatedAt":"2020-02-13T10:23:12+0100","date":"2020-02-13T09:23:12.000Z","url":"http:\\/\\/prd2-bone-image.s3-website-eu-west-1.amazonaws.com\\/bin\\/2020\\/02\\/13\\/f9b473d3-179e-4014-9fa9-af1752fb1cd6.jpeg","canonical":"http:\\/\\/prd2-bone-image.s3-website-eu-west-1.amazonaws.com\\/bin\\/2020\\/02\\/13\\/f9b473d3-179e-4014-9fa9-af1752fb1cd6.jpeg","credit":"Andrew Measham\\/Unsplash","id":"f9b473d3-179e-4014-9fa9-af1752fb1cd6","createdAt":"2020-02-13T10:23:12+0100","namespace":"bin","fileSize":2849068,"extension":"jpeg"},"rel":["app","ssl","html5"]},"focusPoint":{"x":null,"y":null,"relativeX":null,"relativeY":null},"target":null,"rel":null,"source":null}]',
          parent: null,
          path: '',
          publishedAt: '2020-02-13T10:10:32.000Z',
          slug: '',
          status: CategoryStatus.Draft,
          subtitle: '',
          tabs: [],
          tags: '[]',
          title: 'Innovation responsable',
          updatedAt: '2021-06-21T16:48:33.000Z',
          editedAt: '2020-01-31T13:09:54.000Z',
          urls: null,
          __typename: 'Category' as any,
          children: [],
        },
        {
          brandKey: BrandKey.BIN,
          bodyDraft: '',
          categoryHistoryCount: 6,
          createdAt: '2021-07-13T07:50:31.000Z',
          format: CategoryFormat.Event,
          description:
            'Conseils, avantages, praticité, on vous dit tout sur le compte joint !',
          isHeadlined: false,
          id: 'ID_1_3',
          level: 3,
          lockedAt: null,
          lockedBy: null,
          medias:
            '[{"key":null,"caption":null,"format":"illustration","iframely":{"html":"<img src=\\"https:\\/\\/one.img.pmdstatic.net\\/fit\\/~1~cap~2021~06~22~74eee61c-cb66-4ed2-8a78-89f86eec454f.jpeg\\/1440x512\\/q\\/80\\/thumbnail.jpg\\" width=\\"1440\\" height=\\"512\\" \\/>","links":{"app":[{"rel":["app","ssl","html5"],"type":"text\\/html","html":"<img src=\\"https:\\/\\/one.img.pmdstatic.net\\/fit\\/~1~cap~2021~06~22~74eee61c-cb66-4ed2-8a78-89f86eec454f.jpeg\\/1440x512\\/q\\/80\\/thumbnail.jpg\\" width=\\"1440\\" height=\\"512\\" \\/>","media":{"width":1440,"height":512}}],"thumbnail":[{"rel":["thumbnail","ssl"],"type":"image\\/jpeg","href":"https:\\/\\/one.img.pmdstatic.net\\/fit\\/https.3A.2F.2Fi.2Epmdstatic.2Enet.2Fcap.2F2021.2F06.2F22.2F74eee61c-cb66-4ed2-8a78-89f86eec454f.2Ejpeg\\/200x71\\/q\\/80\\/thumbnail.jpg","media":{"width":200,"height":71}}]},"meta":{"path":"\\/cap\\/2021\\/06\\/22\\/74eee61c-cb66-4ed2-8a78-89f86eec454f.jpeg","sha1":"46426ce4930843168e23901553c6ef44e93452f7","width":1440,"height":512,"site":"PMD_BONE_Image","ratio":2.81,"updatedAt":"2021-07-13T07:49:04+0200","date":"2021-07-13T05:49:04.000Z","url":"https:\\/\\/i.pmdstatic.net\\/cap\\/2021\\/06\\/22\\/74eee61c-cb66-4ed2-8a78-89f86eec454f.jpeg","canonical":"https:\\/\\/i.pmdstatic.net\\/cap\\/2021\\/06\\/22\\/74eee61c-cb66-4ed2-8a78-89f86eec454f.jpeg","credit":"@Istock by Getty Images","id":"74eee61c-cb66-4ed2-8a78-89f86eec454f","createdAt":"2021-06-22T16:52:54+0200","namespace":"cap","originalName":"phpDgkP2Z","fileSize":96592,"extension":"jpeg"},"rel":["app","ssl","html5"]},"focusPoint":{"x":null,"y":null,"relativeX":null,"relativeY":null},"target":null,"rel":null,"source":null}]',
          parent: null,
          path: '',
          publishedAt: '2021-07-13T07:50:31.000Z',
          slug: '',
          status: CategoryStatus.Published,
          subtitle: '',
          tabs: [],
          tags: '[]',
          title: 'Tout savoir sur le compte joint !',
          updatedAt: '2021-07-13T09:40:31.000Z',
          editedAt: '2020-01-31T13:09:54.000Z',
          urls: null,
          __typename: 'Category' as any,
          children: [],
        },
      ],
    },
  ],
};

export const CATEGORIES_MOCK = [
  {
    ...CATEGORY_MOCK,
    children: new Array(4).fill(null).map((_, index) => {
      const brandKey = getRandomBrandKey(index);
      const format = getRandomFormat(index);
      const level = index + 2;
      return getCategoryMock(CATEGORY_MOCK.children[0], {
        brandKey,
        format,
        level,
        index,
      });
    }),
  },
];
