import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    alignItems: 'center',
    display: 'flex',

    '& > span': {
      color: theme.palette.grey[500],
      flexShrink: 0,
      paddingRight: theme.spacing(1.5),
      textAlign: 'right',
      width: '35px',
    },

    '& > section': {
      flex: '1 auto',
    },
  },
  dragIconButton: {
    opacity: 0.5,

    '&:hover': {
      opacity: 1,
    },

    '& > svg': {
      zoom: `${1} !important`,
    },
  },
  cardRightSide: {
    alignItems: 'center',
    display: 'flex',
    height: `calc(100% - ${theme.spacing(2)})`,
    marginLeft: theme.spacing(1),

    '& > span': {
      margin: theme.spacing(0, 2),
      textAlign: 'center',
      textTransform: 'capitalize',
      width: '50px',
    },
  },
}));
