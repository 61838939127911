import { gql } from '@apollo/client';

export const CANCEL_PUSH = gql`
  mutation updatePush($where: PushWhereUniqueInput!, $data: PushUpdateInput!) {
    updatePush(where: $where, data: $data) {
      id
      title
      body
      isCancelled
    }
  }
`;
