import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Typography,
} from '@mui/material';
import { ChangeEvent, FC } from 'react';
import {
  ArticleFormat,
  BrandKey,
  GetArticle_article_articleQualifiers,
  GetQualifiers_qualifiers,
  QualifierOrderByInput,
  QualifierStatus,
} from '../../../__generated__/queries-web';
import { useQualifiers } from './getQualifers.web.graphql';
import { useStyles } from './styles';

interface QualifiersProps {
  brandKey: BrandKey;
  articleQualifiers: GetArticle_article_articleQualifiers[];
  onChange: (updatedQualifiers: GetArticle_article_articleQualifiers[]) => void;
  articleFormat: ArticleFormat;
  label?: string;
}

export const Qualifiers: FC<QualifiersProps> = ({
  brandKey,
  articleQualifiers,
  onChange,
  articleFormat,
  label,
}) => {
  const classes = useStyles();
  const { data } = useQualifiers({
    first: 100,
    where: { brandKey, format: articleFormat },
    orderBy: [QualifierOrderByInput.title_ASC],
  });

  const publishedQualifiers = data?.qualifiers?.filter(
    (qualifier: GetQualifiers_qualifiers) =>
      qualifier.status !== QualifierStatus.Draft,
  );

  const isChecked = (id: string) =>
    articleQualifiers.some(({ qualifier }) => qualifier.id === id);

  const handleChange = (selectedQualifier: GetQualifiers_qualifiers) => (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    let updatedQualifiers = [];
    if (!event.target.checked) {
      updatedQualifiers = articleQualifiers.filter(
        ({ qualifier }) => qualifier.id !== selectedQualifier.id,
      );
    } else {
      updatedQualifiers = [
        ...articleQualifiers,
        ({
          qualifier: {
            ...selectedQualifier,
          },
          __typename: 'ArticleQualifier',
        } as unknown) as GetArticle_article_articleQualifiers,
      ];
    }
    onChange(updatedQualifiers);
  };

  if (!publishedQualifiers?.length) return null;

  return (
    <FormControl className={classes.formSuggest} variant="standard">
      {label ? (
        <Typography className={classes.subTitle} component="h3">
          {label}{' '}
          <span>
            ({publishedQualifiers?.filter(({ id }) => isChecked(id)).length})
          </span>
        </Typography>
      ) : (
        <InputLabel shrink>Qualifiers :</InputLabel>
      )}
      <FormGroup>
        {publishedQualifiers?.map((qualifier: GetQualifiers_qualifiers) => (
          <FormControlLabel
            key={qualifier.id}
            control={
              <Checkbox
                checked={isChecked(qualifier.id)}
                className={classes.checkbox}
                onChange={handleChange(qualifier)}
                value={qualifier.id}
              />
            }
            label={qualifier.title}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};
