import React, { ChangeEvent, FC, useState } from 'react';
import {
  TextField,
  Paper,
  List,
  Chip,
  Typography,
  Avatar,
} from '@mui/material';

import { useStyles } from './styles';
import { SuggestItemType, AsyncSuggestion } from './types';
import { FlatSuggest } from './FlatSuggest';
import { AsyncSuggest } from './AsyncSuggest';
import { SuggestItem } from './SuggestItem';

interface SuggestProps {
  selectedItems: SuggestItemType[];
  suggestions: SuggestItemType[] | AsyncSuggestion;
  placeholder?: string;
  multiple?: boolean;
  maxNumberSuggest?: number;
  onChange: (items: SuggestItemType[]) => void;
  isPrefetched: boolean;
  isAvatar?: boolean;
}

export const Suggest: FC<SuggestProps> = ({
  selectedItems,
  placeholder = '',
  multiple = false,
  suggestions = [],
  maxNumberSuggest = Infinity,
  onChange,
  isPrefetched,
  isAvatar = false,
}) => {
  const classes = useStyles();
  const [isMenuOpen, setOpenMenu] = useState<boolean>(false);
  const [textInputValue, setTextInputValue] = useState<string>('');

  const handleChange = (selectedItem: SuggestItemType) => {
    const isAlreadySelected = selectedItems.some(
      (item: SuggestItemType) => item.id === selectedItem.id,
    );
    if (
      isAlreadySelected ||
      (!multiple && selectedItems.length === 1) ||
      (multiple && selectedItems.length + 1 > maxNumberSuggest)
    ) {
      return;
    }
    const newSelectedItems = [...selectedItems, selectedItem];
    onChange(newSelectedItems);
    setTextInputValue('');
  };

  const handleDelete = (itemToDelete: SuggestItemType) => () => {
    const newSelectedItems = selectedItems.filter(
      (item: SuggestItemType) => item.id !== itemToDelete.id,
    );
    onChange(newSelectedItems);
  };

  return (
    <div>
      {selectedItems.map((item: SuggestItemType) => (
        <Chip
          avatar={
            isAvatar ? (
              <Avatar alt={item.title} src={item.avatarUrl} />
            ) : undefined
          }
          key={item.id}
          tabIndex={-1}
          label={item.title}
          className={classes.chip}
          onDelete={handleDelete(item)}
        />
      ))}
      <div style={{ position: 'relative' }}>
        <TextField
          classes={{
            root: classes.textField,
          }}
          data-testid="suggestField"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            onBlur: () => setOpenMenu(false),
            onChange: (event: ChangeEvent<HTMLInputElement>) => {
              setTextInputValue(event.target.value);
            },
            onFocus: () => setOpenMenu(true),
            placeholder,
          }}
          value={textInputValue}
          variant="standard"
        />
        {isMenuOpen ? (
          <Paper square className={classes.paper}>
            <List>
              {isPrefetched && (
                <FlatSuggest
                  suggestions={suggestions}
                  searchValue={textInputValue}
                >
                  {(items) =>
                    items.map((item: SuggestItemType, index: number) => (
                      <SuggestItem
                        ancestorLevel={items[0]?.level}
                        data-testid="suggestItem"
                        item={item}
                        key={`${item.title}-${index}`}
                        onClick={handleChange}
                      />
                    ))
                  }
                </FlatSuggest>
              )}
              {!isPrefetched && (
                <AsyncSuggest
                  suggestions={suggestions as AsyncSuggestion}
                  searchValue={textInputValue}
                >
                  {({ items, isLoading }) =>
                    isLoading ? (
                      <Typography>Loading</Typography>
                    ) : (
                      items.map((item: SuggestItemType, index: number) => (
                        <SuggestItem
                          ancestorLevel={items[0]?.level}
                          item={item}
                          key={`${item.title}-${index}`}
                          onClick={handleChange}
                        />
                      ))
                    )
                  }
                </AsyncSuggest>
              )}
            </List>
          </Paper>
        ) : null}
      </div>
    </div>
  );
};
