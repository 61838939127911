import {
  getMediasWithoutErrors,
  isContentBlockDataIsOneMediaWithErrors,
} from '@prismamedia/one-components';
import isString from 'lodash/isString';
import omit from 'lodash/omit';
import { TabType } from '../../../__generated__/queries-web';
import type {
  CategoryFormSectionBoardContent,
  CategoryFormSectionTextContent,
  CategoryFormSectionUrlContent,
  Section,
  SectionForm,
} from '../views/Edit/components/Section/types';

const getCategorySectionContent = ({
  content,
  type,
}: Pick<SectionForm, 'content' | 'type'>) => {
  let newContent = content;

  switch (type) {
    case TabType.Url: {
      newContent = content as CategoryFormSectionUrlContent;
      newContent = newContent.map((urlContent) =>
        urlContent && !isString(urlContent)
          ? getMediasWithoutErrors([urlContent])?.[0]
          : urlContent,
      ) as CategoryFormSectionUrlContent;
      break;
    }

    case TabType.Text: {
      newContent = content as CategoryFormSectionTextContent;
      newContent = {
        ...newContent,
        body: {
          ...newContent.body,
          blocks: newContent.body.blocks.map((block) => ({
            ...block,
            ...(isContentBlockDataIsOneMediaWithErrors(block.data) && {
              data: omit(block.data, ['errors']),
            }),
          })),
        },
      };
      break;
    }

    case TabType.Board: {
      newContent = content as CategoryFormSectionBoardContent[];
      newContent = newContent.map(({ body, ...restData }) => {
        if (restData.type !== 'slideshow') {
          return { ...restData };
        }
        return {
          body,
          ...restData,
        };
      });
      break;
    }
  }

  return newContent ? JSON.stringify(newContent) : null;
};

export const getCategorySection = ({
  content,
  createdAt,
  description,
  id,
  isActive,
  order,
  title,
  type,
  metas,
}: SectionForm): Partial<Section> => ({
  content: getCategorySectionContent({ content, type }),
  createdAt,
  description,
  id,
  isOnline: isActive,
  order,
  title,
  type,
  metas,
});
