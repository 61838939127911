import React, { FC } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
} from '@mui/material';

import { ConfirmationDialogAction } from './types';

interface ConfirmDialogProps {
  open: boolean;
  dialogActions: ConfirmationDialogAction[];
  message: string;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  open,
  dialogActions,
  message,
}) => {
  return (
    <Dialog fullWidth={false} maxWidth="md" open={open}>
      <DialogTitle>Changement de statut</DialogTitle>
      <DialogContent>
        {/* eslint-disable-next-line react/no-danger */}
        <p dangerouslySetInnerHTML={{ __html: message }} />
      </DialogContent>
      <DialogActions>
        {dialogActions.map((action, i) => (
          <Button key={i} onClick={action.callback} color="primary">
            {action.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};
