import { gql, useApolloClient } from '@apollo/client';
import {
  GetOrphans,
  GetOrphansVariables,
} from '../../__generated__/queries-web';
import { ORPHAN_PAGE_FRAGMENT } from '../fragments/orphan.web.graphql';

const SEARCH_ORPHAN_PAGES = gql`
  query GetOrphans($where: OrphanPageWhereInput, $first: Int!, $skip: Int!) {
    orphanPages(
      first: $first
      where: $where
      orderBy: publishedAt_DESC
      skip: $skip
    ) {
      ...OrphanPageFragment
      __typename
    }
  }
  ${ORPHAN_PAGE_FRAGMENT}
`;

export const useSearchOrphanPageGetter = () => {
  const client = useApolloClient();

  return (variables: GetOrphansVariables) =>
    client.query<GetOrphans, GetOrphansVariables>({
      query: SEARCH_ORPHAN_PAGES,
      variables,
    });
};
