import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Menu, MenuItem } from '@mui/material';
import {
  NotificationTypeEnum,
  ScheduleDialog,
  useDialog,
  useNotification,
} from '@prismamedia/one-components';
import React, { FC, useContext } from 'react';
import { formatDate } from '../../../../../../../utils/dateUtils';
import { CategoryStatus } from '../../../../../../../__generated__/queries-web';
import { ALLOWED_CATEGORY_STATUES } from '../../../../../constants';
import { FormContext } from '../../../../../form.context';
import { Category } from '../../../../../types';
import { useStyles } from './styles';

interface StatusMenuProps {
  status: CategoryStatus | null | undefined;
  onStatusChange: (status: CategoryStatus) => void;
}
export const StatusMenu: FC<StatusMenuProps> = ({ status, onStatusChange }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { form, handlers } = useContext(FormContext);
  const isOpen = Boolean(anchorEl);

  const { pushNotification } = useNotification();
  const { closeDialog, openDialog } = useDialog();

  const scheduleCategory = (newStatus: CategoryStatus) => async (
    dateTime: any,
  ) => {
    const newForm = {
      ...form,
      publishedAt: new Date(dateTime).toISOString(),
      status: newStatus,
    };

    handlers.handleFormSubmit(
      newForm,
      (newCategory?: Category) => {
        const notification = newCategory
          ? {
              type: NotificationTypeEnum.success,
              message: `[La page ${form.title} sera publié le ${formatDate(
                new Date(dateTime),
                'dd/MM/yyyy à HH:mm',
              )}]`,
            }
          : {
              type: NotificationTypeEnum.error,
              message: `[Impossible de programmer la page ${form.title}]`,
            };

        pushNotification(notification);
      },
      { skipNotification: true },
    );
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (newStatus: CategoryStatus) => {
    if (newStatus === CategoryStatus.Scheduled) {
      openDialog(
        <ScheduleDialog
          closeDialog={closeDialog}
          schedule={scheduleCategory(newStatus)}
        />,
      );
    } else {
      onStatusChange(newStatus);
    }

    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-expanded={isOpen ? 'true' : undefined}
        aria-haspopup="true"
        className={classes.button}
        endIcon={<KeyboardArrowDownIcon />}
        onClick={handleButtonClick}
        variant="outlined"
      >
        {ALLOWED_CATEGORY_STATUES.find((statues) => statues.id === status)
          ?.label || '--'}
      </Button>

      <Menu anchorEl={anchorEl} onClose={handleClose} open={isOpen}>
        {ALLOWED_CATEGORY_STATUES.map((statues) => (
          <MenuItem
            disableRipple
            disabled={status === statues.id}
            key={statues.id}
            onClick={() => handleMenuItemClick(statues.id)}
          >
            {statues.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
