import { MEDIA_TYPES_LABEL } from '@prismamedia/one-components';
import { getImageFromArticleBody } from '../../../../../../../../utils/getImageFromArticleBody';
import { getImageFromMedias } from '../../../../../../../../utils/media';
import type { CategoryFormSectionBoardContent } from '../../types';
import { CategoryFormSectionContentType } from '../../types';

export const getBoardContentType = (
  pluginType: MEDIA_TYPES_LABEL,
): CategoryFormSectionContentType => {
  switch (pluginType) {
    case MEDIA_TYPES_LABEL.SLIDESHOWS:
      return CategoryFormSectionContentType.SLIDESHOW;
    case MEDIA_TYPES_LABEL.RECIPES:
      return CategoryFormSectionContentType.RECIPE;
    default:
      return CategoryFormSectionContentType.ARTICLE;
  }
};

export const getBoardContentTypeLabel = (
  type: CategoryFormSectionContentType,
): string => {
  switch (type) {
    case CategoryFormSectionContentType.ARTICLE:
      return 'article';
    case CategoryFormSectionContentType.SLIDESHOW:
      return 'diapo';
    case CategoryFormSectionContentType.RECIPE:
      return 'recette';
    default:
      return '';
  }
};

export const getBoardContentMedia = (
  type: CategoryFormSectionContentType,
  content: Partial<CategoryFormSectionBoardContent>,
): string | null => {
  switch (type) {
    case CategoryFormSectionContentType.ARTICLE:
    case CategoryFormSectionContentType.RECIPE:
      return (
        getImageFromMedias(content.medias)?.mediaForDisplay ||
        content?.imageSrc?.mediaForDisplay ||
        null
      );

    case CategoryFormSectionContentType.SLIDESHOW:
      return getImageFromArticleBody(content.body, false) || null;

    default:
      return null;
  }
};
