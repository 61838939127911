import { gql, useMutation } from '@apollo/client';
import {
  UpdateLiveDates,
  UpdateLiveDatesVariables,
} from '../../../__generated__/queries-web';
import { LIVE_FRAGMENT } from './livePostFragment.web.graphql';

const UPDATE_LIVE_DATES = gql`
  mutation UpdateLiveDates($where: LiveWhereUniqueInput!, $endDate: DateTime) {
    updateLive(where: $where, data: { endedAt: $endDate }) {
      ...LiveFragment
    }
  }
  ${LIVE_FRAGMENT}
`;

interface UpsertParams {
  articleId: string;
  closeLive: boolean;
}

export const useLiveUpdateDates = ({ closeLive, articleId }: UpsertParams) =>
  useMutation<UpdateLiveDates, UpdateLiveDatesVariables>(UPDATE_LIVE_DATES, {
    variables: {
      endDate: closeLive ? new Date().toISOString() : null,
      where: {
        id: articleId,
      },
    },
  });
