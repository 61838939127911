import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.grey[600],
    paddingLeft: '8px',
    paddingRight: '24px',
  },
  popper: {
    zIndex: '2',
  },
}));
