import BackupTableIcon from '@mui/icons-material/BackupTable';
import {
  IconLabelButton,
  PluginButtonProps,
} from '@prismamedia/one-components';
import React, { FC, useState } from 'react';
import { DEFAULT_TABLE_BLOCK_DATA } from '../constants';
import { TableDialog } from '../TableDialog';
import { TablePluginAction } from '../types';
import { useEditTable } from '../useEditTable';

export const TableButton: FC<PluginButtonProps> = (
  megadraftProps: PluginButtonProps,
) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const {
    handlers: { handleValidTableDialog },
    states: { tablePluginData },
  } = useEditTable(DEFAULT_TABLE_BLOCK_DATA, megadraftProps);

  return (
    <>
      <IconLabelButton
        label="Tableau"
        onClick={() => {
          setIsDialogOpen(true);
        }}
      >
        <BackupTableIcon />
      </IconLabelButton>

      {/* Modal */}
      {isDialogOpen && (
        <TableDialog
          action={TablePluginAction.CREATE_TABLE}
          isOpen={isDialogOpen}
          onCloseDialog={() => {
            setIsDialogOpen(false);
          }}
          onValidTable={(data) => {
            setIsDialogOpen(false);
            handleValidTableDialog(data, TablePluginAction.CREATE_TABLE);
          }}
          tablePluginData={tablePluginData}
        />
      )}
    </>
  );
};
