import { TextField } from '@mui/material';
import {
  PageWithDrawer,
  NonEditableField,
  getRequiredErrorMessage,
  objectsAreDifferents,
  EditAppBar,
  FieldValidator,
} from '@prismamedia/one-components';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { replaceParams } from '../../../routing/Router';
import { paths } from '../../../routing/Router/paths';
import { CreateRole, GetRole_role } from '../../../__generated__/queries-auth';
import { useGetRole } from './getUserRole.auth.graphql';
import { useStyles } from './styles';
import { useCreateRole, useUpdateRole } from './updateUserRole.auth.graphql';

const emptyItem: GetRole_role = {
  __typename: 'Role',
  id: '',
  slug: '',
};

const saveMandatoryFields: FieldValidator<GetRole_role>[] = [
  {
    label: 'Slug',
    validate: ({ slug }) => !!slug,
  },
];

export const UserRoleEditPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [item, setItem] = useState<GetRole_role | undefined>(undefined);
  const { id } = useParams();
  const isCreate = id === 'new';
  const { data, loading, error } = useGetRole(id, isCreate);
  const [saveLoading, setSaveLoading] = useState(false);
  const updateItem = useUpdateRole();
  const createItem = useCreateRole();
  const itemDiff = objectsAreDifferents(
    item,
    isCreate ? emptyItem : data?.role,
  );
  const requiredFields = getRequiredErrorMessage<GetRole_role>(
    item,
    saveMandatoryFields,
  );

  useEffect(() => {
    setItem(isCreate ? emptyItem : data?.role || undefined);
  }, [isCreate, data, setItem]);

  const onSave = async () => {
    if (item) {
      setSaveLoading(true);
      const res = isCreate
        ? await createItem({
            slug: item.slug,
          })
        : await updateItem(item.id, {
            slug: item.slug,
          });
      setSaveLoading(false);
      if (res && isCreate) {
        history.replace(
          replaceParams(paths.USER_ROLE_EDIT, {
            id: (res.data as CreateRole).createRole.id,
          }),
        );
      }
    }
  };

  return (
    <>
      <EditAppBar
        title={
          isCreate
            ? item?.slug || 'Nouveau role utilisateur'
            : data?.role?.slug || ''
        }
        onNavigateBack={() => history.push(paths.USER_ROLE_LIST)}
        save={{
          disabled: !itemDiff && !isCreate,
          loading: saveLoading,
          onClick: onSave,
          requiredFields,
        }}
      />
      <PageWithDrawer
        loading={loading}
        error={
          error ||
          (!item && !data?.role
            ? Error("Ce role utilisateur n'existe pas")
            : undefined)
        }
        rightDrawer={
          <NonEditableField
            className={classes.field}
            label="Id"
            value={item?.id}
          />
        }
      >
        {item && (
          <>
            <TextField
              className={classes.field}
              label="Slug"
              onChange={({ target: { value } }) =>
                setItem((prev) => (prev ? { ...prev, slug: value } : prev))
              }
              value={item.slug}
              variant="standard"
            />
          </>
        )}
      </PageWithDrawer>
    </>
  );
};
