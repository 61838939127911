import {
  actions as DRAFT_ACTIONS,
  PossibleActions,
} from '../../../../../DraftActions';

export const CELL_EDITOR_ACTIONS = [
  DRAFT_ACTIONS[PossibleActions.BOLD].action,
  DRAFT_ACTIONS[PossibleActions.ITALIC].action,
  DRAFT_ACTIONS[PossibleActions.STRIKETHROUGH].action,
  DRAFT_ACTIONS[PossibleActions.LIST].action,
  DRAFT_ACTIONS[PossibleActions.ORDERED_LIST].action,
];
