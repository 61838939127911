import { gql, useQuery } from '@apollo/client';
import { BRAND_CATEGORY_FRAGMENT } from '../../pages/SlideshowEdit/CategorySelector/getBrandCategories.web.graphql';
import {
  GetBrandKeys,
  GetBrandKeysVariables,
} from '../../__generated__/queries-web';

const GET_BRANDKEYS = gql`
  query GetBrandKeys($first: Int!, $level: Int!) {
    categories(where: { level: $level, format: Category }, first: $first) {
      ...BrandCategoryFragment
      brandKey
    }
  }
  ${BRAND_CATEGORY_FRAGMENT}
`;

export const useGetBrandKey = () =>
  useQuery<GetBrandKeys, GetBrandKeysVariables>(GET_BRANDKEYS, {
    variables: {
      first: 100,
      level: 2,
    },
  });
