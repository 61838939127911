import { useApolloClient } from '@apollo/client';
import { Box, Grid, Typography } from '@mui/material';
import {
  AutocompleteItem,
  AutocompleteMode,
  SearchBar,
  SearchBarVariant,
  SearchParams,
} from '@prismamedia/one-components';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { TagsProps } from '../../';
import { searchEvents as getEventsFromBrandCategories } from '../../../../apollo/queries/getSearchEventsList';
import { GET_BRAND_CATEGORIES } from '../../../../pages/SlideshowEdit/CategorySelector/getBrandCategories.web.graphql';
import {
  BrandKey,
  CategoryFormat,
  GetArticle_article_articleGuides_category,
  GetBrandCategories,
  GetBrandCategoriesVariables,
} from '../../../../__generated__/queries-web';
import { SuggestItemType } from '../../../Suggest/types';
import { TagItem } from '../../TagItem';
import { useEditableTag } from '../../useEditTag';
import { AutocompleteListHeader, getTagsAutoCompleteList } from '../../utils';
import { useStyles as useSharedStyles } from '../styles';
import {
  ARTICLE_ENHANCEMENT_GUIDES_TITLE,
  GUIDE_AUTOCOMPLETE_LIST_HEADER_LABEL,
  GUIDE_SEARCH_BAR_PLACEHOLDER,
  NO_ARTICLE_GUIDE_SELECTED_TITLE,
} from './constants';

interface GuidesProps {
  brandKey: BrandKey;
  onTagsChange: TagsProps['onTagsChange'];
  field: string;
  tags: GetArticle_article_articleGuides_category[];
}

export const Guides: FC<GuidesProps> = ({
  brandKey,
  field,
  onTagsChange,
  tags: selectedTagList,
}) => {
  const client = useApolloClient();
  const {
    newTags,
    handlers: { handleAddTag, handleDeleteTag },
  } = useEditableTag<GetArticle_article_articleGuides_category>(
    selectedTagList,
    brandKey,
  );

  const sharedClasses = useSharedStyles({
    selectedTagListLength: selectedTagList.length,
  });

  const [guidesTree, setGuidesTree] = useState<SuggestItemType>();
  const [filteredGuideListLength, setFilteredGuideListLength] = useState<
    number | undefined
  >();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    search: '',
  });

  const getFilteredEventList = useCallback(
    (search: string): Promise<AutocompleteItem[] | undefined> => {
      setSearchParams({ search });

      return new Promise((resolve) => {
        if (guidesTree) {
          const guideList = getEventsFromBrandCategories(guidesTree, search)
            .items;

          const filteredGuideList = getTagsAutoCompleteList(
            guideList,
            selectedTagList,
            { withSecondaryLabel: true },
          );

          setFilteredGuideListLength(filteredGuideList.length);

          return resolve(filteredGuideList);
        }

        return resolve(undefined);
      });
    },
    [guidesTree, selectedTagList],
  );

  useEffect(() => {
    const fetchGuideList = async () => {
      const { data: categories } = await client.query<
        GetBrandCategories,
        GetBrandCategoriesVariables
      >({
        query: GET_BRAND_CATEGORIES,
        variables: {
          brandKey: brandKey || BrandKey.CAP,
          format: CategoryFormat.Guide,
        },
      });

      if (categories?.categories?.length) {
        setGuidesTree(categories?.categories[0]);
      }
    };

    fetchGuideList();
  }, [brandKey, client]);

  useEffect(() => {
    newTags && onTagsChange(newTags, field);
  }, [field, newTags, onTagsChange]);

  return (
    <Grid
      alignItems="flex-end"
      className={sharedClasses.wrapper}
      container
      justifyContent="space-between"
    >
      <Grid item xs={4}>
        <Typography className={sharedClasses.subTitle} component="h3">
          {ARTICLE_ENHANCEMENT_GUIDES_TITLE}{' '}
          <span>({selectedTagList.length})</span>
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <SearchBar
          autocomplete={{
            fetchList: getFilteredEventList,
            ...(!searchParams.search?.length && {
              listHeader: (
                <AutocompleteListHeader
                  label={GUIDE_AUTOCOMPLETE_LIST_HEADER_LABEL}
                  listLength={filteredGuideListLength || 0}
                />
              ),
            }),
            mode: AutocompleteMode.MULTIPLE,
            onSelect: ({ additionnals }) => {
              additionnals && handleAddTag(additionnals);
            },
          }}
          fetchListMinValueLength={0}
          placeholder={GUIDE_SEARCH_BAR_PLACEHOLDER}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          variant={SearchBarVariant.SECONDARY}
        />
      </Grid>
      <Box className={sharedClasses.innerWrapper} component="section">
        {selectedTagList.map((event) => (
          <TagItem
            {...event}
            key={event.id || event.title}
            onDelete={handleDeleteTag}
            type="Guide"
          />
        ))}

        {!selectedTagList.length && (
          <Typography component="p">
            {NO_ARTICLE_GUIDE_SELECTED_TITLE}
          </Typography>
        )}
      </Box>
    </Grid>
  );
};
