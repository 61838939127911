import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  grid: {
    maxHeight: 250,
    overflowY: 'scroll',
  },
  item: {
    height: 80,
    width: 80,
    margin: theme.spacing(0, 0, 1, 1),
  },
}));
