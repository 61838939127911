import { FilterList } from '@mui/icons-material';
import {
  Button,
  Card,
  Checkbox,
  Chip,
  ClickAwayListener,
  Divider,
  FormControlLabel,
  Grow,
  Popper,
  Typography,
} from '@mui/material';
import { isArray, xor } from 'lodash';
import type { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { CategoryStatus } from '../../../../../../__generated__/queries-web';
import { useQueryParams } from '../../../../../../utils/useUriHelpers';
import { ALLOWED_CATEGORY_STATUES } from '../../../../constants';
import { DataTestIdListPage } from '../../../../tests/constants';
import { getActiveFilterQuantity } from '../../utils';
import {
  INITIAL_QUERY_PARAMS,
  FILTER_FAVORITE_TITLE,
  FILTER_TITLE,
  RESET_FILTER_TITLE,
} from './constants';
import { useStyles } from './styles';

interface FilterProps {
  isFilterOpen: boolean;
  setIsFilterOpen: Dispatch<SetStateAction<boolean>>;
}
export const Filter: FC<FilterProps> = ({ isFilterOpen, setIsFilterOpen }) => {
  const filterButtonRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const classes = useStyles();

  const searchParams = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search],
  );

  const { queryParams, setQueryParam } = useQueryParams(INITIAL_QUERY_PARAMS);

  const activeFilterQuantity = useMemo(
    () => getActiveFilterQuantity(queryParams),
    [queryParams],
  );

  const handleFilterChange = useCallback(
    <K extends keyof typeof INITIAL_QUERY_PARAMS, T>(
      filterName: K,
      filterValue: T,
    ) => {
      setQueryParam(filterName, filterValue, 'replace');
    },
    [setQueryParam],
  );

  const handleInitFilter = useCallback(
    (forceReinit?: boolean) => {
      Object.keys(INITIAL_QUERY_PARAMS).forEach((filterKey) => {
        if (forceReinit || !searchParams.get(filterKey)) {
          const key = filterKey as keyof typeof INITIAL_QUERY_PARAMS;
          const value = INITIAL_QUERY_PARAMS[key];

          // We won't showing Archived categories by default
          const filteredValue =
            !forceReinit && isArray(value)
              ? value.filter((status) => status !== CategoryStatus.Archived)
              : value;

          setQueryParam(key, filteredValue, 'replace');
        }
      });
    },
    [searchParams, setQueryParam],
  );

  useEffect(() => {
    handleInitFilter();
    // eslint-disable-next-line
  }, [history.location.search]);

  return (
    <div className={classes.wrapper} ref={filterButtonRef}>
      <Chip
        className={classes.filterButton}
        color={activeFilterQuantity ? 'primary' : 'default'}
        data-testid={DataTestIdListPage.FILTER_BUTTON}
        icon={<FilterList />}
        label={activeFilterQuantity}
        onClick={() => {
          setIsFilterOpen(!isFilterOpen);
        }}
      />

      <Popper
        anchorEl={filterButtonRef.current}
        className={classes.popper}
        open={isFilterOpen}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'right center' : 'center bottom',
            }}
          >
            <div>
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={(event: any) => {
                  if (!filterButtonRef.current?.contains(event.target)) {
                    setIsFilterOpen(false);
                  }
                }}
              >
                <Card classes={{ root: classes.card }} raised>
                  <Typography variant="subtitle1">{FILTER_TITLE}</Typography>

                  {/* Status */}
                  <div className={classes.section}>
                    {ALLOWED_CATEGORY_STATUES.map((status) => {
                      const isCheched = !!queryParams?.filterStatus?.includes(
                        status.id,
                      );
                      const isDisabled = !!(
                        isCheched &&
                        queryParams?.filterStatus &&
                        !Array.isArray(queryParams.filterStatus)
                      );

                      return (
                        <FormControlLabel
                          className={classes.formControl}
                          label={status.label}
                          key={status.id}
                          control={
                            <Checkbox
                              checked={isCheched}
                              color="primary"
                              data-testid={status.id}
                              disabled={isDisabled}
                              onChange={() => {
                                handleFilterChange(
                                  'filterStatus',
                                  xor(
                                    isArray(queryParams.filterStatus)
                                      ? [...queryParams.filterStatus]
                                      : [queryParams.filterStatus],
                                    [status.id],
                                  ),
                                );
                              }}
                            />
                          }
                        />
                      );
                    })}
                  </div>

                  <Divider />

                  {/* Favorites */}
                  <div className={classes.section}>
                    <FormControlLabel
                      className={classes.formControl}
                      label={FILTER_FAVORITE_TITLE}
                      key="favorite"
                      control={
                        <Checkbox
                          checked={queryParams?.filterFavorites === 'true'}
                          data-testid={
                            DataTestIdListPage.FAVORITES_FILTER_CHECKBOX
                          }
                          color="primary"
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            handleFilterChange(
                              'filterFavorites',
                              event.target.checked,
                            );
                          }}
                        />
                      }
                    />
                  </div>

                  <Divider />

                  <Button
                    className={classes.resetButton}
                    color="secondary"
                    variant={!activeFilterQuantity ? 'contained' : 'outlined'}
                    disabled={!activeFilterQuantity}
                    onClick={() => {
                      handleInitFilter(true);
                      setIsFilterOpen(false);
                    }}
                  >
                    {RESET_FILTER_TITLE}
                  </Button>
                </Card>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
