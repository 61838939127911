import makeStyles from '@mui/styles/makeStyles';

export const MENU_ITEM_HEIGHT = 45;

export const useStyles = makeStyles((theme) => ({
  errorIcon: {
    fill: `${theme.palette.error.dark} !important`,
    zoom: `${1} !important`,
  },
  errorTooltip: {
    backgroundColor: theme.palette.error.main,
  },

  headLineField: {
    paddingLeft: theme.spacing(1.25),
    marginBottom: theme.spacing(0.5),

    '& > span:first-child': {
      marginRight: theme.spacing(0.5),
    },
  },
  menuItem: {
    height: MENU_ITEM_HEIGHT,
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1.5),

    '& > div > span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  menuItemSelected: {
    color: `${theme.palette.primary.dark} !important`,

    '& svg': {
      fill: theme.palette.primary.dark,
    },
  },
  wrapper: {
    '& section': {
      marginBottom: theme.spacing(1),

      '& svg': {
        zoom: 1.2,
      },
    },
  },
  wrapperSectionItems: {
    marginTop: theme.spacing(1),
  },
}));
