import { ApolloClient } from '@apollo/client';
import type {
  CategoryFragment_tabs,
  CategoryNestedTabsUpdateInput,
  CategoryNestedParentCreateInput,
  CategoryNestedTabsCreateInput,
} from '../../../__generated__/queries-web';
import { PAGE_CATEGORY_FRAGMENT } from './fragments/category.web.graphql';
import type { UseSavePageCategoryVariables } from './mutations/category.web.graphql';

export const getTabsMutationVariables = (
  variables: UseSavePageCategoryVariables,
  client: ApolloClient<any>,
): UseSavePageCategoryVariables['data'] extends { createdAt: GraphQL$DateTime }
  ? CategoryNestedTabsUpdateInput
  : CategoryNestedTabsCreateInput => {
  const {
    data: { tabs = [] },
    where,
  } = variables;
  const tabsCache: CategoryFragment_tabs[] = client.readFragment({
    id: `Category:${where.id || ''}`,
    fragment: PAGE_CATEGORY_FRAGMENT,
  })?.tabs;

  const initialRelationVariables = {
    create: [],
    ...(where.id && {
      delete: tabsCache
        ? tabsCache
            .map(({ id }) => ({ id }))
            .filter(
              ({ id: cacheId }) => !tabs.map((tab) => tab.id).includes(cacheId),
            )
        : [],
      update: [],
    }),
  };

  return tabs.reduce(
    (prevTabs: CategoryNestedTabsUpdateInput, { createdAt, id, ...restTab }) =>
      !createdAt
        ? { ...prevTabs, create: [...(prevTabs.create || []), restTab] }
        : {
            ...prevTabs,
            update: [
              ...(prevTabs.update || []),
              { data: restTab, where: { id } },
            ],
          },
    initialRelationVariables,
  );
};

export const getParentMutationVariables = (
  variables: UseSavePageCategoryVariables,
): CategoryNestedParentCreateInput => {
  return { connect: { id: variables.data?.parent?.id } };
};
