import { Description, Restaurant, Videocam } from '@mui/icons-material';

import { InternalLinkType } from './InternalLink';

interface LinkType {
  type: InternalLinkType;
  icon: JSX.Element;
  label: string;
  tabLabel: string;
  buttonLabel: string;
}

export const linkTypes: LinkType[] = [
  {
    type: InternalLinkType.RECIPE,
    icon: <Restaurant />,
    label: 'Recette',
    tabLabel: 'Recettes',
    buttonLabel: 'Voir la recette',
  },
  {
    type: InternalLinkType.ARTICLE,
    icon: <Description />,
    label: 'Article',
    tabLabel: 'Articles',
    buttonLabel: "Voir l'article",
  },
  {
    type: InternalLinkType.VIDEO,
    icon: <Videocam />,
    label: 'Vidéo',
    tabLabel: 'Vidéos',
    buttonLabel: 'Voir la vidéo',
  },
  {
    type: InternalLinkType.PROGRAM,
    icon: <Description />,
    label: 'Cinéma',
    tabLabel: 'Cinéma',
    buttonLabel: 'Voir ???',
  },
  {
    type: InternalLinkType.BROADCAST,
    icon: <Description />,
    label: 'Séries TV',
    tabLabel: 'Séries TV',
    buttonLabel: 'Voir ???',
  },
  {
    type: InternalLinkType.PRODUCT,
    icon: <Description />,
    label: 'Produit',
    tabLabel: 'Produit',
    buttonLabel: 'Voir ???',
  },
  {
    type: InternalLinkType.PRODUCT_BRAND,
    icon: <Description />,
    label: 'Marques',
    tabLabel: 'Marques',
    buttonLabel: 'Voir ???',
  },
];
