import { gql } from '@apollo/client';
import { CARD_FRAGMENT } from '../Queries/card.web.graphql';

export const UPSERT_CARD = gql`
  ${CARD_FRAGMENT}
  mutation upsertCard(
    $where: CardWhereUniqueInput!
    $create: CardCreateInput!
    $update: CardUpdateInput!
  ) {
    upsertCard(where: $where, create: $create, update: $update) {
      ...cardFragment
    }
  }
`;
