import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  checkbox: {
    padding: theme.spacing(0.85),
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1.5, 3),
  },
}));
