import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material';
import { AdditionalSortableItemProps } from '../../../../DragAndDrop/SortableItem';

type TagStyleProps = Partial<AdditionalSortableItemProps> & {
  withStatus: boolean | undefined;
};

export const useStyles = makeStyles<Theme, TagStyleProps>((theme) => ({
  deleteIcon: {
    boxShadow: 'none',
    display: 'none', // We showing this button only on hover
    position: 'absolute',
    top: '-13px',
    right: '-13px',
    zIndex: theme.zIndex.drawer + 1,
    zoom: 0.8,
  },
  dragIconButton: {
    opacity: 0.5,

    '&:hover': {
      opacity: 1,
    },

    '& > svg': {
      zoom: `${1} !important`,
    },
  },
  statusWrapper: {
    alignItems: 'center',
    backgroundColor: theme.palette.grey[200],
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    paddingRight: theme.spacing(0.5),
    width: 50,

    '& > span': {
      transform: 'rotate(-90deg)',
    },
  },
  statusSwitchDumb: {
    boxShadow: '-1px 0px 2px 1px rgb(0 0 0 / 5%)',
  },
  title: {
    fontWeight: 500,
    lineHeight: '1.25rem',
    textAlign: 'center',
    padding: theme.spacing(0, 1, 1, 0.5),
    pointerEvents: 'none',
  },
  innerWrapper: () => ({
    alignItems: 'center',
    marginRight: theme.spacing(-0.5),
    bottom: 0,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,

    // Overwrite default button user-agent style
    backgroundColor: 'inherit',
    border: 'none',
    cursor: 'inherit',
    padding: 0,
    width: '100%',

    '& > div': {
      alignItems: 'center',
      backgroundColor: ({ withStatus }) =>
        withStatus ? theme.palette.common.white : 'inherit',
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderTopLeftRadius: theme.shape.borderRadius,
      display: 'flex',
      gap: theme.spacing(1.2),
      height: '100%',
      justifyContent: 'flex-end',
      position: 'relative',
      left: ({ withStatus }) => (withStatus ? theme.spacing(-0.5) : 0),
      padding: theme.spacing(0.75, 0.75, 0.75, 1.5),
      width: '100%',
      zIndex: 2,

      '& > h6': {
        display: '-webkit-box',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        maxHeight: '60px',
        overflow: 'hidden',
        padding: 0,
        textOverflow: 'ellipsis',
        textAlign: 'left',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
      },
    },
  }),
  wrapper: {
    backgroundColor: ({ isDragging }) =>
      isDragging ? theme.palette.grey['100'] : theme.palette.common.white,
    border: ({ isDragging }) =>
      isDragging ? `none` : `solid 1px ${theme.palette.grey[200]}`,
    borderRadius: theme.shape.borderRadius,
    height: 'inherit',
    position: 'relative',

    '&::before': {
      display: 'block',
      content: "''",
      paddingBottom: '40%',
    },

    '&:hover $deleteIcon': {
      display: 'inline-flex',
    },
  },
}));
