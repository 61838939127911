import { gql } from '@apollo/client';
import {
  GetUploadUrl,
  GetUploadUrlVariables,
} from '../../__generated__/queries-image';
import { useGetter } from '../useGetter';

export const GET_UPLOAD_URL = gql`
  query GetUploadUrl($namespace: String!, $originalName: String!) {
    getUploadUrl(namespace: $namespace, originalName: $originalName) {
      uploadURL
      key
      id
    }
  }
`;

export const useUploadUrlGetter = () =>
  useGetter<GetUploadUrl, GetUploadUrlVariables>(GET_UPLOAD_URL);
