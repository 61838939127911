import { formatDate } from '../../../utils/dateUtils';
import { ArticleStatus } from '../../../__generated__/queries-web';

export const formatSubTitle = (
  status: ArticleStatus,
  publishedAt: number | Date | string | null,
  editedAt: number | Date | string | null,
): string => {
  const dateFormat = 'dd/MM/yyyy à HH:mm';
  let publish = '';
  if (
    publishedAt &&
    (status === ArticleStatus.Published || status === ArticleStatus.Scheduled)
  ) {
    publish =
      status === ArticleStatus.Published ? 'Publié le' : 'Programmé pour le';
    publish += ` ${formatDate(publishedAt, dateFormat)} - `;
  }

  const edit = editedAt
    ? `Dernière modification ${formatDate(editedAt, dateFormat)}`
    : '';
  return `${publish}${edit}`;
};
