import type { RawDraftContentBlock, RawDraftContentState } from 'draft-js';

export enum TablePluginAction {
  CREATE_TABLE,
  UPDATE_TABLE,
}

interface CellData<CellState = RawDraftContentState> {
  cellContentState: CellState;
  position: number[];
}

interface TablePluginContentState extends RawDraftContentState {
  blocks: (RawDraftContentBlock & { data: CellData })[];
}

export interface TablePluginData<TableState = TablePluginContentState> {
  accentuation: {
    bottom: boolean;
    left: boolean;
    right: boolean;
    top: boolean;
  };
  source: string | null;
  tableContentState: TableState;
  title: string | null;
  type: string;
}

export type TableShapeCol = Record<'key', string>; // { key: value }
export type TableShapeRow = TableShapeCol[]; // [{ key: value }]
export type TableShape = TableShapeRow[]; // [[ { key: value }], [{ key: value }]]
