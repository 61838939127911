import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  content: {
    height: '100%',
  },
  bottomBar: {
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: 64,
    display: 'flex',
    padding: theme.spacing(0, 2),
  },
  photoSlider: {
    width: 300,
  },
  centered: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
