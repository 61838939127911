import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { GeneralInformationProps } from '../';
import { Tags } from '../../../../components/Tags';
import { getTagsConfiguration } from '../../../ArticleEdit/utils/tagsConfiguration';
import { actions } from '../../reducer';
import {
  DIAPO_ENHANCEMENT_MAIN_TITLE,
  TAGS_DIALOG_CLOSE_BUTTON_TEXT,
  TAGS_DIALOG_TOOLTIP_TEXT,
} from './constants';
import { useStyles } from './styles';

interface TagsDialog
  extends Omit<GeneralInformationProps, 'categoryId' | 'publishedAt'> {
  isTagsDialogOpen: boolean;
}

const TagsDialog: FC<TagsDialog> = ({
  dispatch,
  isTagsDialogOpen,
  article,
}) => {
  const history = useHistory();
  const classes = useStyles();

  const tagsConfiguration = useMemo(
    () =>
      getTagsConfiguration({
        articleTags: article.articleTags,
        brandKey: article.brandKey,
      }),
    [article.articleTags, article.brandKey],
  );

  const handleTagsChange = useCallback(
    (newArticleTags, field) => {
      dispatch(
        actions.setArticleTags({
          field,
          value: newArticleTags,
        }),
      );
    },
    [dispatch],
  );

  return (
    <Dialog
      maxWidth="md"
      onClose={() => {
        history.replace({ hash: '' });
      }}
      open={isTagsDialogOpen}
      scroll="paper"
    >
      <DialogContent className={classes.tagsDialogContent} dividers>
        <Tags
          article={article}
          brandKey={article.brandKey}
          configuration={tagsConfiguration}
          onTagsChange={handleTagsChange}
          title={DIAPO_ENHANCEMENT_MAIN_TITLE}
        />
      </DialogContent>
      <DialogActions className={classes.tagsDialogFooter}>
        <Typography className={classes.tagsDialogCaption} variant="caption">
          {TAGS_DIALOG_TOOLTIP_TEXT}.
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            history.replace({ hash: '' });
          }}
          className={classes.validateButton}
        >
          {TAGS_DIALOG_CLOSE_BUTTON_TEXT}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { TagsDialog };
