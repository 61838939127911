import { gql, useMutation } from '@apollo/client';
import {
  UpdateDailymotion,
  UpdateDailymotionVariables,
} from '../../__generated__/queries-web';

const UPDATE_DAILYMOTION = gql`
  mutation UpdateDailymotion(
    $brandKey: BrandKey!
    $videoId: String!
    $thumbnailUrl: String!
  ) {
    dailymotionUpdate(
      brandKey: $brandKey
      videoId: $videoId
      thumbnailUrl: $thumbnailUrl
    ) {
      id
      private_id
      status
      title
    }
  }
`;

export const useDailymotionUpdate = () =>
  useMutation<UpdateDailymotion, UpdateDailymotionVariables>(
    UPDATE_DAILYMOTION,
  );
