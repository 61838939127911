import React, { FC } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useStyles } from './styles';

interface MobilePushCardProps {
  image: string;
  message: string;
  title?: string | null;
}

export const MobilePushCard: FC<MobilePushCardProps> = ({
  image,
  title,
  message,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="h5" component="h3">
        Aperçu
      </Typography>
      <Card className={classes.card}>
        <CardMedia className={classes.media} image={image} />
        <CardContent>
          {title && <Typography variant="h6">{title}</Typography>}
          <Typography variant="body2" color="textSecondary" component="p">
            {message}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};
