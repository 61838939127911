import { gql } from '@apollo/client';
import { useCreate } from '../../utils/useUpdateCreate';
import {
  AttributeCreateInput,
  CreateAttribute,
} from '../../__generated__/queries-web';
import { GET_ATTRIBUTES } from './getAttributes.web.graphql';

const CREATE_ATTRIBUTE = gql`
  mutation CreateAttribute($data: AttributeCreateInput!) {
    createAttribute(data: $data) {
      id
    }
  }
`;

export const useCreateAttribute = () =>
  useCreate<CreateAttribute, AttributeCreateInput>(CREATE_ATTRIBUTE, [
    GET_ATTRIBUTES,
  ]);
