import { ChipsInput, isDefined } from '@prismamedia/one-components';
import { FC } from 'react';
import {
  ArticleFormat,
  ArticleStatus,
} from '../../../__generated__/queries-web';
import { getAllowedStatus, statuses } from '../../../utils/statuses';

const articleStatus = getAllowedStatus().map((status) => ({
  id: status,
  label: statuses(ArticleFormat.Rich)[status].label,
  color: statuses(ArticleFormat.Rich)[status].color,
}));

interface StatusesSelectProps {
  label: string;
  className?: string;
  value?: ArticleStatus[];
  onChange?: (value?: ArticleStatus[]) => void;
}

export const StatusesSelect: FC<StatusesSelectProps> = ({
  label,
  className,
  value,
  onChange,
}) => {
  const fetchList = async (search: string) =>
    articleStatus.filter(({ label: statusLabel }) =>
      statusLabel.includes(search),
    );

  const internalValue = value
    ?.map((val) => articleStatus.find(({ id }) => id === val))
    .filter(isDefined);

  return (
    <ChipsInput
      variant="outlined"
      label={label}
      value={internalValue}
      className={className}
      onChange={(val) =>
        onChange?.(
          val?.length ? val.map(({ id }) => id as ArticleStatus) : undefined,
        )
      }
      autocomplete={{ fetchList }}
    />
  );
};
