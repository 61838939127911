import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  wrapper: {
    borderRadius: 4,
    border: 0,
    backgroundColor: 'rgba(97, 97, 97, 0.92)',
    color: 'white',
    padding: '4px 8px',
    outline: 0,
  },
}));
