import type { CSSProperties, FC } from 'react';
import React from 'react';
import type { StackProps } from '@mui/material';
import { Stack, Skeleton } from '@mui/material';
import { DataTestIdListPage } from '../../pages/Pages/tests/constants';
import { useStyles } from './styles';

export interface SkeletonsProps extends StackProps {
  height?: number;
  quantity?: number;
  width?: number;
  style?: CSSProperties;
}
export const Skeletons: FC<SkeletonsProps> = ({
  height = 15,
  quantity = 1,
  spacing = 1,
  style = {},
  width = '100%',
  ...StackProps
}) => {
  const classes = useStyles();

  return (
    <Stack
      data-testid={DataTestIdListPage.SKELETONS_WRAPPER}
      spacing={spacing}
      {...StackProps}
    >
      {Array.from(new Array(quantity)).map((_, index: number) => (
        <Skeleton
          className={classes.skeleton}
          height={height}
          key={index}
          style={style}
          width={width}
        />
      ))}
    </Stack>
  );
};
