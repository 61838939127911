import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    separator: {
      marginBottom: '2rem',
    },
    radioGroup: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    sidebar: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingTop: '1rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      boxShadow: '0px 2px 7px 0px rgba(0,0,0,0.2)',
      minHeight: '96vh',
    },
    imageContainer: {
      marginBottom: '1rem',
      flexWrap: 'wrap',
      display: 'flex',
      flexDirection: 'row',
    },
    image: {
      marginTop: '1rem',
      marginRight: '0.4rem',
      cursor: 'pointer',
    },
    selectedImage: {
      border: '0.2rem solid',
      borderColor: 'rgb(244, 67, 54)',
    },
    charCounter: {
      alignSelf: 'flex-end',
      fontSize: '1rem',
    },
    inputElement: {
      display: 'none',
    },
    uploader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      cursor: 'pointer',
      marginBottom: '1rem',
    },
    info: {
      marginTop: '1rem',
    },
    backArrow: {
      color: theme.palette.common.white,
    },
    leftToolbar: {
      display: 'flex',
      alignItems: 'center',
    },
    sendButton: {
      color: theme.palette.common.white,
      borderColor: theme.palette.common.white,
    },
    brandKey: {
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    formGroup: {
      position: 'relative',

      '& > svg': {
        cursor: 'pointer',
        left: '-35px',
        position: 'absolute',
        top: '18px',
      },
    },
  }),
);
