import {
  ArtTrack,
  CenterFocusStrong,
  Dashboard,
  DescriptionOutlined,
  DynamicFeed,
  FeaturedPlayListOutlined,
  OndemandVideo,
  SystemUpdate,
  Tv,
} from '@mui/icons-material';
import { DEFAULT_ALLOWED_FORMAT } from '../../pages/Pages/constants';

export interface BoneLink {
  href?: string;
  hrefWithoutBrand?: string;
  label: string;
  icon: any;
  openBlank?: boolean;
  onClick?: () => any;
}

export const menuLinks: BoneLink[] = [
  {
    label: 'News',
    href: '#/article/list?brand={BRAND}',
    hrefWithoutBrand: '#/article/list',
    icon: <FeaturedPlayListOutlined />,
    openBlank: false,
  },
  {
    label: 'Videos',
    href: '#/video/list?brand={BRAND}',
    hrefWithoutBrand: '#/video/list',
    icon: <OndemandVideo />,
    openBlank: false,
  },
  {
    label: 'Orpheline',
    href: `#/orphanpage/list?brand={BRAND}`,
    hrefWithoutBrand: `#/orphanpage/list`,
    icon: <DescriptionOutlined />,
  },
  {
    label: 'Encarts',
    href: '#/{BRAND}/card/list',
    icon: <ArtTrack />,
  },
  {
    label: 'Pages',
    href: `#/{BRAND}/page/${DEFAULT_ALLOWED_FORMAT.toLowerCase()}/list`,
    icon: <CenterFocusStrong />,
  },
  {
    label: 'Push',
    href: '#/mobilepush/list?brand={BRAND}',
    hrefWithoutBrand: '#/mobilepush/list',
    icon: <SystemUpdate />,
  },
  {
    label: 'Diaporama',
    href: '#/slideshow/list?brand={BRAND}',
    hrefWithoutBrand: '#/slideshow/list',
    icon: <DynamicFeed />,
  },
  {
    label: 'Dashboard',
    href: '#/dashboard?brand={BRAND}',
    hrefWithoutBrand: '#/dashboard',
    icon: <Dashboard />,
  },
  {
    href: 'https://epg.programme-tv.net/',
    label: 'Prog TV',
    icon: <Tv />,
  },
];
