import { Default } from '../../../../components/Tags/TagItems/Default';
import { CardState } from '../types';

export const getTagsConfiguration = ({
  cardTags,
}: Pick<CardState, 'cardTags'>) => [
  {
    component: Default,
    id: 'Default',
    props: {
      field: 'cardTags',
      isAllowedToCreateTag: true,
      tags: cardTags.map((cardTag) => cardTag.tag),
    },
  },
];
