import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: theme.palette.grey[100],
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    justifyContent: 'space-between',
  },
  wordCountMessage: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginRight: theme.spacing(4),
  },
  wordCount: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: 'gray',
    fontSize: '0.75rem',
    marginTop: '0.4rem',
  },
  globalCharCounter: {
    fontWeight: 500,
    marginLeft: theme.spacing(0.5),
  },
}));
