import BackupTableIcon from '@mui/icons-material/BackupTable';
import { AppBar, Button, Dialog, Toolbar, Typography } from '@mui/material';
import { EditorState } from 'draft-js';
import React, { FC } from 'react';
import { TableDraft } from '../TableDraft';
import { TablePluginData, TablePluginAction } from '../types';
import {
  ADD_TABLE_TITLE,
  UPDATE_TABLE_TITLE,
  VALIDATE_LABEL,
} from './constants';
import { DialogToolbar } from './DialogToolbar';
import { useStyles } from './styles';
import { useEditDialogTable } from './useEditDialogTable';

interface TableModalProps {
  action: TablePluginAction;
  isOpen: boolean;
  onCloseDialog: () => void;
  onValidTable: (data: TablePluginData<EditorState>) => void;
  tablePluginData: TablePluginData<EditorState>;
}

export const TableDialog: FC<TableModalProps> = ({
  action,
  isOpen = false,
  tablePluginData,
  onValidTable,
  onCloseDialog,
}) => {
  const classes = useStyles();

  const {
    handlers: {
      handleChangeTableData,
      handleChangeTableStructure,
      handleChangeAccentuation,
    },
    state: { dialogTableBlockData, tableDialogState, tableKey },
  } = useEditDialogTable(tablePluginData);

  return (
    <Dialog
      maxWidth="xl"
      onClose={() => {
        onCloseDialog();
      }}
      open={isOpen}
    >
      <>
        <AppBar position="static" color="inherit" className={classes.appBar}>
          <Toolbar>
            <Typography className={classes.title} color="inherit" variant="h6">
              {action === TablePluginAction.UPDATE_TABLE
                ? UPDATE_TABLE_TITLE
                : ADD_TABLE_TITLE}
            </Typography>

            <Button
              color="primary"
              disabled={
                !tableDialogState.cols.quantity ||
                !tableDialogState.rows.quantity
              }
              onClick={() => {
                onValidTable(dialogTableBlockData);
              }}
              variant="contained"
            >
              <Typography variant="button">{VALIDATE_LABEL}</Typography>
            </Button>
          </Toolbar>
        </AppBar>

        <div className={classes.innerWrapper}>
          {/* Left side */}
          <DialogToolbar
            dialogTableBlockData={dialogTableBlockData}
            onChangeStructure={handleChangeTableStructure}
            onChangeAccentuation={handleChangeAccentuation}
            tableDialogState={tableDialogState}
          />

          {/* Right side */}
          <section className={classes.wrapperPreview}>
            {!tableDialogState.cols.quantity ||
            !tableDialogState.rows.quantity ? (
              <div className={classes.wrapperEmptyPreview}>
                <BackupTableIcon />
                <Typography variant="h5">{ADD_TABLE_TITLE}</Typography>
              </div>
            ) : (
              <TableDraft
                key={tableKey}
                onChange={(tableContentState) => {
                  handleChangeTableData({ tableContentState });
                }}
                shape={tableDialogState.shape}
                tablePluginData={dialogTableBlockData}
              />
            )}
          </section>
        </div>
      </>
    </Dialog>
  );
};
