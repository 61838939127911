import { SearchParams } from '@prismamedia/one-components';
import { INITIAL_QUERY_PARAMS } from './components/Filter/constants';

export const getActiveFilterQuantity = (searchParams: SearchParams): number => {
  let quantity = 0;
  quantity +=
    searchParams.filterFavorites === INITIAL_QUERY_PARAMS.filterFavorites
      ? 0
      : 1;
  quantity += Array.isArray(searchParams?.filterStatus)
    ? Object.keys(searchParams?.filterStatus || {}).length ===
      INITIAL_QUERY_PARAMS.filterStatus.length
      ? 0
      : 1
    : 1;

  return quantity;
};
