export const THUMBNAIL_UPLOADED = 'La vignette a bien été uploadé !';
export const THUMBNAIL_ERROR = "Erreur lors de l'upload de la vignette";
export const THUMBNAIL_MAX_SIZE = (name: string) =>
  `La vignette ${name} est supérieure à 5MB`;
export const THUMBNAIL_MIN_WIDTH = (name: string) =>
  `La vignette ${name} doit avoir une largeur supérieure à 640px`;
export const THUMBNAIL_MIN_HEIGHT = (name: string) =>
  `La vignette ${name} doit avoir une hauteur supérieure à 360px`;
export const THUMBNAIL_MAX_WIDTH = (name: string) =>
  `La vignette ${name} doit avoir une largeur inférieure à 5000px`;
export const THUMBNAIL_MAX_HEIGHT = (name: string) =>
  `La vignette ${name} doit avoir une hauteur inférieure à 5000px`;
export const MIN_WIDTH = 640;
export const MAX_WIDTH = 5000;
export const MIN_HEIGHT = 360;
export const MAX_HEIGHT = 5000;
export const DEFAULT_MAX_IMAGE_SIZE = 5242880;
export const ARTICLE_FORMAT = 'Article';
export const FB_FORMAT = 'Facebook';
export const PINTEREST_FORMAT = 'Pinterest';
export const ILLUSTRATION_FORMAT = 'Illustration';
export const LOGO_FORMAT = 'Logo';
export const FLAG_FORMAT = 'Bannière';
export const BRAND_FORMAT = 'Marque';
export const EMPTY_FORMAT = '-';
export enum MEDIA_TYPES {
  IMAGE = 'IMAGE',
  EMBED = 'EMBED',
  SLIDESHOW = 'SLIDESHOW',
  FOTOWEB = 'FOTOWEB',
  ARTICLE_VIDEO = 'ARTICLE_VIDEO',
  PODCAST = 'PODCAST',
  DAILYMOTION = 'DAILYMOTION',
  THUMBNAIL = 'THUMBNAIL',
}
