import React, { cloneElement, FC, ReactElement } from 'react';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useStyles } from './styles';

export interface AdditionalSortableItemProps {
  attributes: {
    role: string;
    tabIndex: number;
    'aria-pressed': boolean | undefined;
    'aria-roledescription': string;
    'aria-describedby': string;
  };
  isDragging: boolean;
  isSortable: boolean;
  listeners: DraggableSyntheticListeners;
  setNodeRef: { (node: HTMLElement | null): void };
}

interface SortableItemProps {
  children: ReactElement;
  id: string;
}
export const SortableItem: FC<SortableItemProps> = ({ children, id }) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });
  const classes = useStyles({ isDragging });

  const style = {
    transform: CSS.Transform.toString(transform) ?? '',
    transition: transition ?? '',
  };

  const sortableProps = {
    isSortable: true,
    isDragging,
    ref: setNodeRef,
    attributes,
    listeners,
  };

  return (
    <div className={classes.wrapperSortable} style={style}>
      {cloneElement(children, sortableProps)}
    </div>
  );
};
