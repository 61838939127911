import React from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { BrandKey } from '../../../../__generated__/queries-web';
import { paths } from '../../../../routing/Router/paths';

interface CardSpeedDialProps {
  className: string;
}

export const CardSpeedDial = function ({ className }: CardSpeedDialProps) {
  const { brandKey } = useParams<{ brandKey: BrandKey }>();
  const history = useHistory();

  return (
    <div className={className}>
      <SpeedDial
        ariaLabel="create a card"
        icon={<SpeedDialIcon />}
        onClose={() => null}
        onOpen={() => null}
        open={false}
        onClick={() =>
          history.push(generatePath(paths.CARD_EDIT, { brandKey }))
        }
      />
    </div>
  );
};
