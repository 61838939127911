import { Add, ArrowBack } from '@mui/icons-material';
import { Fab, IconButton, Tooltip, Typography } from '@mui/material';
import { HomeAppBar, InfiniteTable } from '@prismamedia/one-components';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useAttributes } from '../../../apollo/queries/getAttributes.web.graphql';
import { BrandSwitch } from '../../../components/BrandSwitch';
import { replaceParams } from '../../../routing/Router';
import { paths } from '../../../routing/Router/paths';
import { auth } from '../../../utils/auth';
import { useGlobalBrandKey } from '../../../utils/globalState';
import { AttributeOrderByInput } from '../../../__generated__/queries-web';
import { useStyles } from './styles';

export const ATTRIBUTE_LIST_RESULT = 1000;

export const AttributesListPage: FC = () => {
  const history = useHistory();
  const [brandKey, setBrandKey] = useGlobalBrandKey();
  const classes = useStyles();

  const { data, loading, error } = useAttributes({
    first: ATTRIBUTE_LIST_RESULT,
    where: { brandKey },
    orderBy: [AttributeOrderByInput.createdAt_DESC],
  });

  return (
    <>
      <HomeAppBar
        appDrawerMenu={
          <IconButton
            className={classes.backIcon}
            onClick={() => history.push(paths.CRUD)}
          >
            <ArrowBack />
          </IconButton>
        }
        brandMenu={
          <>
            <Typography className={classes.title} variant="h6">
              Attributes
            </Typography>
            <BrandSwitch brandKey={brandKey} setBrandKey={setBrandKey} />
          </>
        }
        currentUser={auth.user}
        disconnectUser={auth.logout}
      />
      <div className={classes.listWrapper}>
        <InfiniteTable
          list={data?.attributes}
          loading={loading || !brandKey}
          error={error}
          columns={[
            {
              label: 'Titre',
              key: 'title',
              width: 200,
              flexGrow: 1,
            },
            {
              label: 'CreatedAt',
              key: 'createdAt',
              width: 140,
            },
            {
              label: 'UpdatedAt',
              key: 'updatedAt',
              width: 140,
            },
            { label: 'Marque', key: 'brandKey', width: 140 },
            { label: 'Slug', key: 'slug', width: 140 },
          ]}
        />
      </div>
      {brandKey && (
        <Tooltip title={`Créer un attribut ${brandKey}`}>
          <Fab
            className={classes.newButton}
            color="primary"
            onClick={() => {
              history.push(replaceParams(paths.ATTRIBUTE_NEW, { brandKey }));
            }}
          >
            <Add />
          </Fab>
        </Tooltip>
      )}
    </>
  );
};
