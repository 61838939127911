import { ApolloProvider } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  AdviceProvider,
  DialogDisplay,
  DialogProvider,
  ErrorBoundary,
  GoogleAuthProvider,
  MenuDisplay,
  MenuProvider,
  NotificationDisplay,
  NotificationProvider,
  TutorialProvider,
  UpdateNotification,
  useOnMount,
} from '@prismamedia/one-components';
import frLocale from 'date-fns/locale/fr';
import React from 'react';
import type { FC } from 'react';
import ReactGA from 'react-ga4';
import { Route, Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { QueryParamProvider } from 'use-query-params';
import { name } from '../package.json';
import { client } from './apollo';
import { history } from './history';
import { Router as AppRouter } from './routing/Router';
import { ThemeProvider } from './routing/Router/ThemeProvider';
import { getRemoteVersion } from './utils/version';
import { setDefaultOptions } from 'date-fns';

ReactGA.initialize(config.GA_ID, {
  gaOptions: {
    debug_mode: ['test', 'development'].includes(process.env.NODE_ENV),
  },
});

setDefaultOptions({ locale: frLocale });

export const App: FC = () => {
  useOnMount(() => {
    if (process.env.NODE_ENV !== 'development') {
      (async () => {
        const remoteVersion = await getRemoteVersion();

        datadogRum.init({
          applicationId: '3c911508-c03a-4837-a6d2-bf2d8fb854ee',
          clientToken: 'pubc80719df30e119fb5995056a66dd7055',
          defaultPrivacyLevel: 'allow',
          env: config.ENV,
          sampleRate: 5,
          replaySampleRate: 0,
          service: name,
          site: 'datadoghq.eu',
          trackInteractions: true,
          trackViewsManually: true,
          version: remoteVersion,
        });
      })();
    }
  });

  return (
    <ErrorBoundary>
      <GoogleAuthProvider>
        <RecoilRoot>
          <ApolloProvider client={client}>
            <Router history={history}>
              <ThemeProvider>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={frLocale}
                >
                  <NotificationProvider>
                    <DialogProvider>
                      <MenuProvider>
                        <TutorialProvider>
                          <AdviceProvider>
                            <QueryParamProvider ReactRouterRoute={Route}>
                              <AppRouter title={document.title} />
                              <DialogDisplay />
                              <MenuDisplay />
                              <NotificationDisplay />
                              <UpdateNotification />
                            </QueryParamProvider>
                          </AdviceProvider>
                        </TutorialProvider>
                      </MenuProvider>
                    </DialogProvider>
                  </NotificationProvider>
                </LocalizationProvider>
              </ThemeProvider>
            </Router>
          </ApolloProvider>
        </RecoilRoot>
      </GoogleAuthProvider>
    </ErrorBoundary>
  );
};
