import { gql, useApolloClient } from '@apollo/client';
import {
  ArticleSearchFieldsType,
  GetSuggestedCategory,
  GetSuggestedCategoryVariables,
} from '../../__generated__/queries-web';

const SUGGEST_CATEGORIES = gql`
  query GetSuggestedCategory(
    $categoryFormat: CategoryFormat
    $search: SimilarArticleArgsInputType!
    $exclude: [String]
  ) {
    suggestCategories(
      categoryFormat: $categoryFormat
      search: $search
      exclude: $exclude
    ) {
      itemCount
      items {
        id
        title
        slug
        format
        level
      }
    }
  }
`;

export const useSuggestedCategoriesGetter = () => {
  const client = useApolloClient();

  return ({ search, categoryFormat, exclude }: GetSuggestedCategoryVariables) =>
    client.query<GetSuggestedCategory, GetSuggestedCategoryVariables>({
      query: SUGGEST_CATEGORIES,
      variables: {
        categoryFormat,
        search: {
          ...search,
          article: {
            id: search.article?.id,
            brandKey: search.article?.brandKey,
            format: search.article?.format,
            title: search.article?.title,
            lead: search.article?.lead,
            body: search.article?.body,
          },
          fields: [
            ArticleSearchFieldsType.Title,
            ArticleSearchFieldsType.Lead,
            ArticleSearchFieldsType.Body,
          ],
        },
        exclude,
      },
    });
};
