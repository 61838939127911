import { gql, useApolloClient, useQuery } from '@apollo/client';
import {
  GetRawArticle,
  GetRawArticleVariables,
} from '../../../__generated__/queries-topic';
import {
  RAW_ARTICLE_VERSION_FRAGMENT,
  ASSIGMENT_FRAGMENT,
} from './articleEditFragments.topic.graphql';

export const GET_RAW_ARTICLE_QUERY = gql`
  query GetRawArticle($id: ID!, $versionId: ID!) {
    rawArticle(where: { id: $id }) {
      id
      assignment {
        ...AssignmentFragment
      }
      versions(first: 10, where: { id: $versionId }) {
        ...ArticleForm_RawArticleVersionFragment
      }
    }
  }
  ${RAW_ARTICLE_VERSION_FRAGMENT}
  ${ASSIGMENT_FRAGMENT}
`;

export const useGetRawArticle = (id: string, versionId: string) =>
  useQuery<GetRawArticle, GetRawArticleVariables>(GET_RAW_ARTICLE_QUERY, {
    variables: { id, versionId },
    skip: !id || !versionId,
  });

export const useRawArticleGetter = () => {
  const client = useApolloClient();

  return (id: string, versionId: string) =>
    client.query<GetRawArticle, GetRawArticleVariables>({
      query: GET_RAW_ARTICLE_QUERY,
      variables: {
        id,
        versionId,
      },
    });
};
