import React, { FC } from 'react';
import { Title, Notes, Copyright } from '@mui/icons-material';
import { Box } from '@mui/material';

interface GridTileIconsProps {
  title: boolean;
  text: boolean;
  copyright: boolean;
  className?: string;
}

export const GridTileIcons: FC<GridTileIconsProps> = ({
  title,
  text,
  copyright,
  className,
}) => {
  return (
    <Box display="flex" className={className}>
      {title && <Title fontSize="small" />}
      {text && <Notes fontSize="small" />}
      {copyright && <Copyright fontSize="small" />}
    </Box>
  );
};
