import React from 'react';
import { CardStatus } from '../../../../__generated__/queries-web';
import { StatusContainer } from '../../../../components/StatusSelector/StatusContainer';
import { CardState } from '../../CardEdit/types';

interface StatusSelectorProps {
  card: CardState;
  handleStatusChange(event: string): void;
  onSave(card: CardState): void;
}

const getLabel = (status: CardStatus) => {
  switch (status) {
    case CardStatus.Published:
      return `Publié`;
    case CardStatus.Archived:
      return `Archivé`;

    default:
      return `Statut inconnu`;
  }
};

export const StatusSelector: React.FC<StatusSelectorProps> = function ({
  handleStatusChange,
  card,
  onSave,
}) {
  const onChange = (value: CardStatus) => {
    handleStatusChange(value);
    onSave({ ...card, status: value });
  };
  return (
    <StatusContainer
      changeStatus={onChange}
      label={getLabel(card.status)}
      currentStatus={card.status}
      transitions={{
        [CardStatus.Published]: {
          from: [CardStatus.Archived],
          to: [CardStatus.Published],
          label: 'Publié',
          successMessage: 'Votre encart a bien été publiée',
        },
        [CardStatus.Archived]: {
          from: [CardStatus.Published],
          to: [CardStatus.Archived],
          label: 'Archivé',
          successMessage: 'Votre encart a bien été archivé',
        },
      }}
    />
  );
};
