import { gql } from '@apollo/client';
import { useFetch } from '../../../utils/useFetch';
import {
  GetDepartments,
  GetDepartmentsVariables,
} from '../../../__generated__/queries-auth';

export const GET_DEPARTMENTS = gql`
  query GetDepartments($first: Int!, $where: DepartmentWhereInput) {
    departments(first: $first, where: $where) {
      id
      name
      brand {
        key
      }
    }
  }
`;

export const useGetDepartments = () =>
  useFetch<GetDepartments, GetDepartmentsVariables>(GET_DEPARTMENTS);
