import { gql } from '@apollo/client';

export const ORPHANPAGE_FRAGMENT = gql`
  fragment OrphanPageFragment on OrphanPage {
    id
    publishedAt
    editedAt
    status
    title
    content
    metas
    isCMPEnabled
    isAdsEnabled
    isFullScreen
    brandKey
    uri
  }
`;
