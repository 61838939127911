import { MoreVert, Whatshot } from '@mui/icons-material';
import { IconButton, MenuItem } from '@mui/material';
import {
  NotificationTypeEnum,
  useMenu,
  useNotification,
} from '@prismamedia/one-components';
import { equals } from 'ramda';
import React, { Dispatch, FC, useState } from 'react';
import { formatDate } from '../../../utils/dateUtils';
import { LivePostFragment } from '../../../__generated__/queries-web';
import { DialogConfirm } from '../DialogConfirmation';
import { EditorContent } from '../EditorContent';
import { useLivePostDelete } from '../graphql/deleteLivePost.web.graphql';
import { useLivePostUpsert } from '../graphql/upsertLivePost.web.graphql';
import { actions, ActionType } from '../reducer';
import { deletePostLive, saveData } from '../sideEffects';
import { useStyles } from './styles';

interface LivePostItemProps {
  articleId: string;
  post: Partial<LivePostFragment>;
  dispatch: Dispatch<ActionType>;
}

export const LivePostItem: FC<LivePostItemProps> = ({
  articleId,
  post: { id = '', publishedAt = Date.now(), title = '', body = '' },
  dispatch,
}) => {
  const [deleteMutation] = useLivePostDelete(id);
  const [upsertMutation] = useLivePostUpsert({
    data: {
      title,
      body,
    },
    articleId,
    id,
  });
  const classes = useStyles();
  const { pushNotification } = useNotification();
  const [isWritable, setEditMode] = useState(false);
  const toggleEditMode = () => setEditMode(!isWritable);

  const [oldDraft, setOldDraft] = useState(body);
  const [toggleUpdate, setToggle] = useState(false);

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const onClose = () => setDeleteDialogOpen(false);
  const onResetDraft = () => {
    setToggle(!toggleUpdate);
    dispatch(actions.updateLivePostById({ body: oldDraft }, id));
  };
  const hasBeenModified = !equals(oldDraft, body);

  const onDialogConfirm = async () => {
    const result = await deletePostLive(deleteMutation);
    if (result) {
      pushNotification({
        message: 'Post supprimé',
        type: NotificationTypeEnum.success,
      });
      dispatch(actions.deleteLivePost(id));
    } else {
      pushNotification({
        message: 'Une erreur empêche la suppression du post',
        type: NotificationTypeEnum.error,
      });
      setDeleteDialogOpen(false);
    }
  };

  const handleDeleteOnClick = () => setDeleteDialogOpen(true);
  const { openMenu, closeMenu } = useMenu();

  const onFormSubmit = async () => {
    const result = await saveData(upsertMutation);
    if (result) {
      setEditMode(false);
      setOldDraft(result.body);
      pushNotification({
        message: 'Post modifié',
        type: NotificationTypeEnum.success,
      });
    } else {
      pushNotification({
        message: 'Une erreur empêche la mise à jour du post',
        type: NotificationTypeEnum.error,
      });
    }
  };

  const menuItems = [
    {
      value: 'Supprimer',
      onClick: () => handleDeleteOnClick(),
    },
    {
      value: isWritable ? 'Verrouiller' : 'Modifier',
      onClick: toggleEditMode,
    },
  ];

  return (
    <>
      <DialogConfirm
        isOpen={isDeleteDialogOpen}
        title="Êtes-vous sûrs de vouloir supprimer ce live ?"
        onDialogConfirm={onDialogConfirm}
        onClose={onClose}
        confirmMessage="Supprimer"
      />
      <div>
        <div className={classes.itemTools}>
          <div className={classes.dateField}>
            <Whatshot color="primary" />
            <b className={classes.itemDate}>
              {formatDate(publishedAt, 'EEEE dd MMMM à HH:mm')} :
            </b>
          </div>
          <IconButton
            data-testid="icon-menu"
            onClick={(e) =>
              openMenu(
                e.currentTarget,
                <>
                  {menuItems.map((item, i) => (
                    <MenuItem
                      key={i}
                      onClick={() => {
                        item.onClick?.();
                        closeMenu();
                      }}
                    >
                      {item.value}
                    </MenuItem>
                  ))}
                </>,
              )
            }
          >
            <MoreVert />
          </IconButton>
        </div>
        <EditorContent
          dispatch={dispatch}
          resetDraft={onResetDraft}
          isResetButtonEnabled={hasBeenModified}
          id={id}
          onFormSubmit={onFormSubmit}
          displaySubmitButton={isWritable}
          isWritable={isWritable}
          body={body}
          title={title}
        />
      </div>
    </>
  );
};
