import { Folder, FolderCopy } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React, { useMemo } from 'react';
import type { FC } from 'react';
import { Favorite } from './Favorite';
import { Card } from '../../../../components/Card';
import { DataTestIdListPage } from '../../../../tests/constants';
import type { Category } from '../../../../types';
import { getImageFromMedias } from '../../../../../../utils/media';
import { ActionButtons } from './ActionButtons';

interface CategoryCardProps {
  category: Category;
}
export const CategoryCard: FC<CategoryCardProps> = ({ category }) => {
  const categoryMedia = useMemo(
    () => getImageFromMedias(category.medias)?.mediaForDisplay || null,
    [category.medias],
  );

  return (
    <Card
      ActionsButtons={<ActionButtons category={category} />}
      ActionsIcons={<Favorite category={category} />}
      dataTestId={DataTestIdListPage.CARD_WRAPPER}
      format={category.format}
      id={category.id}
      media={categoryMedia}
      updatedAt={category.updatedAt}
      status={category.status}
      title={category.title}
      {...(category.children?.length && {
        MediaOverlay: (
          <>
            {category.children?.length === 1 ? (
              <Folder fontSize="medium" />
            ) : (
              <FolderCopy fontSize="medium" />
            )}
            <Typography component="p" noWrap variant="body2">
              {category.children.length}
            </Typography>
          </>
        ),
      })}
      {...(category.lockedBy && {
        lockedBy: category.lockedBy,
      })}
      category={category}
    />
  );
};
