import { RequiredFields } from '../types';

export const requiredFields: RequiredFields[] = [
  'brandKey',
  'label',
  'status',
  'title',
  'url',
];

export const handleErrorMessage = (
  fieldError: any,
  fieldLabel: string,
): string => {
  if (!fieldError.pristine && !fieldError.valid) {
    return `Le champ ${fieldLabel} est obligatoire`;
  }

  return '';
};
