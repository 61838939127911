import type { OneMedia } from '@prismamedia/one-components';
import {
  parseMedias as parseMedia,
  PreviewSize,
} from '@prismamedia/one-components';
import type { RawDraftContentBlock } from 'draft-js';
import { cloneDeep, set } from 'lodash';
import { path } from 'ramda';
import { resizer } from '../utils/resizer';
import { GetPhotos_photos } from '../__generated__/queries-photo';

export interface ArticleMedia {
  media?: OneMedia;
  mediaForDisplay?: string;
}

const PMD_IMAGE_TYPE = 'pmd_bone_image';
const YOUTUBE_TYPE = 'youtube';
const PLURIMEDIA_TYPE = 'plurimedia';
const DAILYMOTION_TYPE = 'dailymotion';
const INTERNAL_SOURCE = 'Brightcove';
export const DAILYMOTION_INTERNAL_SOURCE = 'Dailymotion';
export const SLIDESHOW_TYPE = 'PMD_Slideshow';
export const PODCAST_SOURCE = 'Prisma Media Player';

export enum MediaFormat {
  Image = 'Image',
  Video = 'Video',
}

export const parseMedias = (
  medias: string | OneMedia | null,
): OneMedia | undefined => {
  let finalMedia: OneMedia;
  if (typeof medias === 'string') {
    finalMedia = JSON.parse(medias);
  } else {
    finalMedia = medias as OneMedia;
  }

  if (!medias || !(Array.isArray(finalMedia) && finalMedia.length)) {
    return undefined;
  }
  return finalMedia;
};

export const getFirstMedia = (
  medias: OneMedia[] | OneMedia | undefined,
  type?: MediaFormat,
): OneMedia | undefined => {
  if (!medias) {
    return undefined;
  }

  if (Array.isArray(medias) && medias[0]) {
    if (type === MediaFormat.Video) {
      return getFirstVideo(medias) || (medias[0] as OneMedia);
    }
    return getFirstImage(medias) || (medias[0] as OneMedia);
  }
  return medias as OneMedia;
};

const getFocusPoints = (media: OneMedia) => {
  if (
    media.focusPoint &&
    media.focusPoint.relativeX &&
    media.focusPoint.relativeY
  ) {
    return {
      'focus-point': `${
        media.iframely.meta.width * media.focusPoint.relativeX
      },${media.iframely.meta.height * media.focusPoint.relativeY}`,
    };
  }

  return { 'crop-from': 'top' };
};

export const getImageFromMedia = (
  media: string | OneMedia | null,
): ArticleMedia => {
  const parsedMedia = parseMedia(media);
  const canAddMedia = Boolean(parsedMedia && !Array.isArray(parsedMedia));

  return {
    media: canAddMedia ? parsedMedia : undefined,
    mediaForDisplay: canAddMedia
      ? resizer.fit({
          origin: parsedMedia?.iframely.meta.url,
          width: 350,
          height: 150,
          ...(parsedMedia && { parameters: getFocusPoints(parsedMedia) }),
        })
      : undefined,
  };
};

export const getImageFromMedias = (
  medias: string | OneMedia | null,
): ArticleMedia => {
  const finalMedia = parseMedias(medias);

  if (!finalMedia) {
    return {
      media: undefined,
      mediaForDisplay: undefined,
    };
  }

  const firstMedia = getFirstMedia(finalMedia);
  const parameters = getFocusPoints(firstMedia ?? finalMedia);
  if (firstMedia) {
    return {
      media: firstMedia,
      mediaForDisplay: resizer.fit({
        origin: firstMedia?.iframely?.meta?.url,
        width: 350,
        height: 150,
        parameters,
      }),
    };
  }
  return {
    media: finalMedia,
    mediaForDisplay: resizer.fit({
      origin: finalMedia.iframely.meta.url,
      width: 350,
      height: 150,
      parameters,
    }),
  };
};

export const getThumbnailUrlFromPhoto = (
  item: GetPhotos_photos,
  size: PreviewSize = PreviewSize.xxl,
) => {
  return (
    item.previews?.filter((preview) => preview.size === size)?.[0]?.href || ''
  );
};

export const getVideoThumbnail = (
  medias: string | OneMedia | null,
): string | undefined => {
  const finalMedia = parseMedias(medias);
  if (!finalMedia) {
    return undefined;
  }
  const firstMedia = getFirstMedia(finalMedia, MediaFormat.Video);
  if (firstMedia) {
    return path(['iframely', 'links', 'thumbnail', '0', 'href'], firstMedia);
  }
  return path(['iframely', 'links', 'thumbnail', '0', 'href'], finalMedia);
};

const getMediaSite = (media: OneMedia) => {
  if (!media.iframely.meta.site) {
    return '';
  }

  return media.iframely.meta.site;
};

const isMediaSite = (media: OneMedia, site: string | string[]) => {
  if (Array.isArray(site)) {
    return site
      .map((s) => s.toLowerCase())
      .includes(getMediaSite(media).toLowerCase());
  }
  return getMediaSite(media).toLowerCase() === site.toLowerCase();
};

const isImage = (media: OneMedia) => {
  if (!media.iframely) {
    throw new Error('isImage only accept Media type');
  }
  return (
    isMediaSite(media, PMD_IMAGE_TYPE) ||
    (media.iframely.meta.canonical
      ? media.iframely.meta.canonical.match(/(jp(e)?g|png|bmp|gif)$/)
      : false)
  );
};
interface IsImageMediaWithHref {
  (contentBlock: Partial<RawDraftContentBlock & Record<'data', any>>): boolean;
}

export const isImageMediaWithHref: IsImageMediaWithHref = ({ type, data }) =>
  type === 'atomic' &&
  data?.iframely?.meta?.site === 'PMD_BONE_Image' &&
  data?.href;

export const getFirstImage = (medias: OneMedia[] | null) => {
  if (!medias) {
    return false;
  }
  return medias.find((media) => isImage(media));
};

const isVideo = (media: OneMedia) => {
  if (!media.iframely) {
    throw new Error('isVideo only accept Media type');
  }
  return isMediaSite(media, [
    YOUTUBE_TYPE,
    DAILYMOTION_INTERNAL_SOURCE,
    PLURIMEDIA_TYPE,
    INTERNAL_SOURCE,
  ]);
};

export const getFirstVideo = (medias: OneMedia[] | null) => {
  if (!medias) {
    return undefined;
  }
  return medias.find(isVideo);
};

export const getMediasSite = (medias: string | OneMedia | null) => {
  const finalMedias = parseMedias(medias) as any;
  const mediasSite = finalMedias?.map((finalMedia: OneMedia) => {
    return finalMedia.iframely.meta.site;
  });

  if (!mediasSite || !mediasSite.length) return undefined;

  return mediasSite.filter((mediaSite: string) => {
    return (
      Boolean(mediaSite === DAILYMOTION_INTERNAL_SOURCE) ||
      Boolean(mediaSite === SLIDESHOW_TYPE) ||
      Boolean(mediaSite === INTERNAL_SOURCE) ||
      Boolean(mediaSite === PODCAST_SOURCE)
    );
  });
};

const defaultMedia: OneMedia = {
  key: null,
  caption: null,
  format: null,
  iframely: {
    meta: {
      channel: 'creation',
      owner: 'x2k33dg',
      'owner.username': 'Prisma media',
      'owner.screenname': 'Prisma media',
      id: 'k5P8pPyqZUpwlwxDF27',
      private_id: 'k5P8pPyqZUpwlwxDF27',
      updated_time: 1645013359,
      title: 'traitement',
      width: 1920,
      height: 1080,
      thumbnail_url: 'https://s2.dmcdn.net/v/TeBN_1Y3EblqOxmdT',
      duration: 10,
      site: 'Dailymotion',
      public_id: 'x87xywv',
      embed_url: 'https://www.dailymotion.com/embed/video/k5P8pPyqZUpwlwxDF27',
      canonical: 'https://www.dailymotion.com/video/x87xywv',
      media: 'player',
    },
    links: {
      player: [
        {
          type: 'text/html',
          rel: ['player', 'html5', 'ssl', 'iframely'],
          href: 'https://www.dailymotion.com/embed/video/k5P8pPyqZUpwlwxDF27',
          media: {
            'aspect-ratio': 1.7777777777777777,
            scrolling: 'no',
          },
          html:
            '<div style="left: 0; width: 100%; height: 0; position: relative; padding-bottom: 56.0417%;"><iframe src="https://www.dailymotion.com/embed/video/k5P8pPyqZUpwlwxDF27" style="border: 0; top: 0; left: 0; width: 100%; height: 100%; position: absolute;" allowfullscreen scrolling="no" allow="encrypted-media; autoplay"></iframe></div>',
        },
      ],
      thumbnail: [
        {
          type: 'image/jpeg',
          rel: ['thumbnail', 'ssl'],
          media: {
            height: 1080,
            width: 1920,
          },
          href: 'https://s2.dmcdn.net/v/TeBN_1Y3EblqOxmdT',
        },
      ],
    },
    rel: ['player', 'html5', 'ssl', 'iframely'],
    html:
      '<div style="left: 0; width: 100%; height: 0; position: relative; padding-bottom: 56.0417%;"><iframe src="https://www.dailymotion.com/embed/video/k5P8pPyqZUpwlwxDF27" style="border: 0; top: 0; left: 0; width: 100%; height: 100%; position: absolute;" allowfullscreen scrolling="no" allow="encrypted-media; autoplay"></iframe></div>',
  },
  focusPoint: {
    x: null,
    y: null,
    relativeX: null,
    relativeY: null,
  },
  target: null,
  rel: null,
};

export const getDefaultDailymotionMedia = (
  name: string,
  uploadURL: string,
): OneMedia => ({
  ...set(cloneDeep(defaultMedia), ['iframely', 'meta', 'title'], name),
  format: DAILYMOTION_TYPE,
  target: uploadURL,
});
