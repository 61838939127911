import React, { SyntheticEvent, FC } from 'react';
import { Tabs, Tab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AuthorsQuery } from '../../../utils/statuses';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
}));

interface AuthorTabsProps {
  tabs: string[];
  handleChange: (
    event: SyntheticEvent<Element, Event>,
    newValue: AuthorsQuery,
  ) => void;
  value: string;
}
export const AuthorTabs: FC<AuthorTabsProps> = ({
  tabs,
  handleChange,
  value,
}) => {
  const classes = useStyles({});

  return (
    <Tabs
      classes={{ root: classes.root }}
      indicatorColor={'primary'}
      onChange={handleChange}
      scrollButtons={false}
      textColor={'primary'}
      value={value}
      variant="scrollable"
    >
      {tabs.map((tab, i) => (
        <Tab label={tab} value={tab} key={i} />
      ))}
    </Tabs>
  );
};
