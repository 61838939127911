import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { isArticleVideo } from '../../../../utils/url';
import {
  TagProviderName,
  TagType,
} from '../../../../__generated__/queries-web';

export const useAllowedTags = <T extends (TagProviderName | TagType | any)[]>(
  allowedTags: T,
) => {
  const { pathname } = useLocation();
  return useMemo(
    () =>
      allowedTags.filter((type) =>
        isArticleVideo(pathname)
          ? type === TagType.VideoTag
          : type !== TagType.VideoTag,
      ),
    [allowedTags, pathname],
  );
};
