import uuidv4 from 'uuid/v4';
import {
  ArticleFormat,
  ArticleStatus,
  BrandKey,
  GetArticle_article,
} from '../../../__generated__/queries-web';
import { getArticleMachineTag } from './TypeFactory';

const emptyConfig: any = {
  __typename: 'Article',
  id: '',
  title: '',
  secondaryTitle: '',
  partnerTitle: '',
  medias: '',
  lead: null,
  body: null,
  publishedAt: '',
  editedAt: '',
  slug: '',
  status: ArticleStatus.Draft,
  articleCategories: [],
  articleTags: [],
  articleAuthors: [],
  source: null,
  articleEvents: [],
  articleUrls: [],
  articleQualifiers: [],
  articleGuides: [],
  articleChannels: [],
  live: null,
  contextualizedUrl: null,
};

export const emptyArticle = (
  brandKey: BrandKey,
  format: string,
): GetArticle_article => ({
  ...emptyConfig,
  id: uuidv4(),
  brandKey,
  format: format === 'article' ? ArticleFormat.Rich : ArticleFormat.Video,
});

export const createArticleData = (
  assignmentId: string,
  format: string,
  data: Partial<GetArticle_article>,
): GetArticle_article => ({
  ...emptyConfig,
  id: uuidv4(),
  ...data,
  format: format === 'article' ? ArticleFormat.Rich : ArticleFormat.Video,
  articleMachineTags: [getArticleMachineTag({ assignmentId })],
});
