import { useQuery } from '@apollo/client';
import { InputLabel, Box } from '@mui/material';
import React from 'react';
import { Suggest } from '../../../../components/Suggest';
import {
  BrandKey,
  CategoryStatus,
  GetArticle_article_articleCategories,
  GetBrandCategories,
  GetBrandCategoriesVariables,
  GetBrandFilterCategories,
  GetBrandFilterCategoriesVariables,
} from '../../../../__generated__/queries-web';
import {
  GET_BRAND_CATEGORIES,
  GET_BRAND_FILTER_CATEGORIES,
} from '../../../SlideshowEdit/CategorySelector/getBrandCategories.web.graphql';
import { actions, ArticleEditAction } from '../../reducer';

interface CategoriesInputsProps {
  brandKey: BrandKey;
  categories: GetArticle_article_articleCategories[];
  dispatch: React.Dispatch<ArticleEditAction>;
  multiple?: boolean;
}

export const CategoriesInput: React.FC<CategoriesInputsProps> = ({
  brandKey,
  categories,
  dispatch,
  multiple,
}) => {
  const isFAC = brandKey === BrandKey.FAC;
  const { data } = useQuery<
    GetBrandCategories | GetBrandFilterCategories,
    GetBrandCategoriesVariables | GetBrandFilterCategoriesVariables
  >(isFAC ? GET_BRAND_FILTER_CATEGORIES : GET_BRAND_CATEGORIES, {
    variables: {
      brandKey,
      status: CategoryStatus.Published,
      title_not_in: isFAC ? ['Recettes'] : undefined,
    },
  });

  return (
    <Box sx={{ width: '100%' }}>
      <InputLabel shrink>Catégorie</InputLabel>
      <Suggest
        selectedItems={categories.map(({ category }) => category)}
        placeholder="Rechercher une catégorie"
        suggestions={data?.categories?.[0]?.children || []}
        multiple={multiple}
        maxNumberSuggest={2}
        isPrefetched
        onChange={(items) => {
          dispatch(
            actions.updateField({
              field: 'articleCategories',
              value: items.map(({ id, title }) => ({
                category: { id, title },
              })),
            }),
          );
        }}
      />
    </Box>
  );
};
