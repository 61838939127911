import { gql } from '@apollo/client';

export const ATTRIBUTE_FRAGMENT = gql`
  fragment AttributeFragment on Attribute {
    id
    title
    createdAt
    updatedAt
    brandKey
    slug
    authorAttributes(first: 100) {
      author {
        id
        name
      }
    }
  }
`;
