import { fetchData } from '@prismamedia/one-components';

interface RecommendData {
  results?: {
    recommend?: RecommendedItem[];
  };
}

interface RecommendedItem {
  title?: string;
  idone?: string;
  published_date?: string;
}

export const getRecommendedRecipes = async (
  search: string,
  brandKey: string | undefined | null,
  limit: number,
) => {
  const { results } = await fetchData<RecommendData>(
    `${config.API_RECOMMEND}/ContextualSearch`,
    {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'x-api-key': 'I1ysLsfNmzadHBvbhP8ydGzZ8Lj0Hxzj',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        brand: brandKey ? [brandKey] : ['ALL'],
        formats: ['RECIPE'],
        nmatchs: limit,
        text: search,
      }),
    },
  );

  return results?.recommend;
};
