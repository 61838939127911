import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    title: {
      color: theme.palette.text.primary,
      fontSize: '0.7em',
      lineHeight: '1.5em',
      textAlign: 'left',
    },
    titleBar: {
      background: theme.palette.background.paper,
      minHeight: theme.spacing(6),
      height: 'auto',
      backgroundColor: theme.palette.background.paper,
      width: 185,
    },
    listRoot: {
      cursor: 'pointer',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    brandkey: {
      position: 'absolute',
      color: 'white',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      padding: '0 5px',
      top: 5,
      right: 5,
    },
    image: {
      height: 160,
      width: 185,
      maxWidth: '100%',
      maxHeight: '100%',
    },
    tile: {
      width: 'auto !important',
      height: 'auto !important',
      padding: '16px !important',
    },
    item: {
      height: '100%',
      display: 'block',
      overflow: 'hidden',
      position: 'relative',
      borderRadius: '2px',
      boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24);',
    },
    border: {
      borderTop: '1px solid #979797',
    },
  }),
);
