import {
  AutocompleteItem,
  ChipsInput,
  isDefined,
} from '@prismamedia/one-components';
import { FC, useEffect, useState } from 'react';
import { useGetCategory } from '../../../apollo/sharedQueries/categories.query.web.graphql';
import { useGlobalBrandKey } from '../../../utils/globalState';
import { useFetchCategories } from '../../../utils/useFetchCategories';
import {
  CategoryFormat,
  CategoryStatus,
} from '../../../__generated__/queries-web';

interface CategoriesSelectProps {
  label: string;
  className?: string;
  value?: string[];
  onChange?: (value?: string[]) => void;
}

export const CategoriesSelect: FC<CategoriesSelectProps> = ({
  label,
  className,
  value,
  onChange,
}) => {
  const [brandKey] = useGlobalBrandKey();
  const [internalValue, setInternalValue] = useState<AutocompleteItem[]>([]);
  const getCategories = useFetchCategories({
    brandKey,
    format: CategoryFormat.Category,
    status: CategoryStatus.Published,
  });
  const getCategory = useGetCategory();

  useEffect(() => {
    if (!value) {
      setInternalValue([]);
      return;
    }
    Promise.all(value.map((id) => getCategory({ where: { id } }))).then((res) =>
      setInternalValue(
        res
          .map((data) => data.category)
          .filter(isDefined)
          .map((category) => ({
            id: category.id,
            label: category.title,
          })),
      ),
    );
  }, [value, getCategory]);

  return (
    <ChipsInput
      variant="outlined"
      disabled={!brandKey}
      label={label}
      value={internalValue}
      className={className}
      onChange={(val) =>
        onChange?.(val?.length ? val.map(({ id }) => id) : undefined)
      }
      autocomplete={{ fetchList: getCategories }}
    />
  );
};
