import {
  AppName,
  AutocompleteItem,
  HomeAppBar,
  SearchBar,
} from '@prismamedia/one-components';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useSearchOrphanPageGetter } from '../../../apollo/queries/searchOrphanPage.web.graphql';
import { OneDrawerMenu } from '../../../components/AppBar';
import { BrandSwitch } from '../../../components/BrandSwitch/MaterialBrandSwitch';
import { SpeedDialBrands } from '../../../components/SpeedDial';
import { paths } from '../../../routing/Router/paths';
import { auth } from '../../../utils/auth';
import { AdvancedSearchParams } from '../../../utils/formatArticleSearchInput';
import { useQueryParams } from '../../../utils/useUriHelpers';
import {
  ArticleFormat,
  BrandKey,
  GetOrphans_orphanPages,
  OrphanPageStatus,
} from '../../../__generated__/queries-web';
import { OrphanListContent } from './OrphanListContent';
import { useStyles } from './styles';

interface OrphanListQueryParams {
  brand?: BrandKey;
  searchParams?: AdvancedSearchParams;
  articleFormat?: ArticleFormat;
}

const availableStatus = {
  [OrphanPageStatus.Published]: 'En ligne',
  [OrphanPageStatus.Archived]: 'Archives',
  [OrphanPageStatus.Draft]: 'Brouillons',
};

export const OrphanList = () => {
  const classes = useStyles();
  const [page, setPageNumber] = useState(0);
  const [status, setStatus] = useState<OrphanPageStatus>(
    OrphanPageStatus.Published,
  );
  const { queryParams } = useQueryParams<OrphanListQueryParams>({
    brand: undefined,
  });
  const { brand: brandKey } = queryParams;
  const [searchParams, setSearchParams] = useState<AdvancedSearchParams>({
    search: '',
  });
  const [searchOrphans, setSearchOrphans] = useState<GetOrphans_orphanPages[]>(
    [],
  );
  const history = useHistory();
  const searchOrphanPages = useSearchOrphanPageGetter();

  const fetchAutocompleteList = async (
    search: string,
  ): Promise<AutocompleteItem[] | undefined> => {
    const { data, errors } = await searchOrphanPages({
      first: 10,
      skip: 0,
      where: {
        title_contains: search,
        status,
        brandKey,
      },
    });

    if (errors?.length) {
      throw errors[0];
    }

    return data?.orphanPages?.map(
      ({ id, title, publishedAt, brandKey: articleBrand }) => ({
        id: id as string,
        label: title || 'NO_TITLE',
        secondaryLabel: publishedAt
          ? format(new Date(publishedAt), 'd MMM')
          : undefined,
        additionnals: { brandKey: articleBrand },
      }),
    );
  };

  useEffect(() => {
    (async (search: string | undefined) => {
      const { data } = await searchOrphanPages({
        first: 1000,
        skip: 0,
        where: {
          title_contains: search,
          status,
          brandKey,
        },
      });

      setSearchOrphans(data?.orphanPages);
    })(searchParams.search);
    // eslint-disable-next-line
  }, [brandKey, status, searchParams.search]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.appBar}>
        <HomeAppBar
          currentUser={auth.user}
          disconnectUser={auth.logout}
          appDrawerMenu={
            <OneDrawerMenu
              currentBrand={brandKey}
              app={'Pages Indépendantes' as AppName}
            />
          }
          searchBar={
            <SearchBar
              placeholder="Rechercher dans One"
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              autocomplete={{
                fetchList: fetchAutocompleteList,
                onSelect: ({ id, additionnals }) => {
                  const orphanPageLink = generatePath(paths.ORPHAN_EDIT, {
                    id: id || '',
                    brandKey: additionnals?.brandKey,
                  });
                  orphanPageLink && history.push(orphanPageLink);
                },
              }}
            />
          }
          tabs={Object.entries(availableStatus).map(
            ([statusKey, statusName]) => ({
              id: statusKey,
              label: statusName,
              selected: status === statusKey,
            }),
          )}
          brandMenu={<BrandSwitch />}
          onTabSelect={(tab) => {
            setStatus(tab.id as OrphanPageStatus);
            setPageNumber(0);
          }}
        />
      </div>
      <div
        data-testid="orphan-pages--list-container"
        className={classes.container}
      >
        <OrphanListContent
          page={page}
          setPageNumber={setPageNumber}
          data={searchOrphans}
        />
        <SpeedDialBrands format={'Orphanpage'} brand={brandKey} />
      </div>
    </div>
  );
};
