import { OneMedia } from '@prismamedia/one-components';
import { getCard_card } from '../../../__generated__/queries-web';

export type CardState = Omit<getCard_card, 'media' | '__typename'> & {
  media: OneMedia | null;
};

export type RequiredFields = keyof Pick<
  CardState,
  'title' | 'url' | 'brandKey' | 'label' | 'status'
>;

export enum CardEditActionTypes {
  'UPDATE_FIELD' = 'UPDATE_FIELD',
  'UPDATE_DRAFT' = 'UPDATE_DRAFT',
  'UPDATE_CATEGORY' = 'UPDATE_CATEGORY',
  'UPDATE_TAG' = 'UPDATE_TAG',
  'VALIDATE_FIELD' = 'VALIDATE_FIELD',
}

export interface ValidationActionType {
  type: CardEditActionTypes.VALIDATE_FIELD;
  payload: { value: string; fieldName: string };
}

export type ErrorType = {
  [key in RequiredFields]: {
    pristine: boolean;
    valid: boolean;
  };
};
