import { AutocompleteItem, MediaTypesEnum } from '@prismamedia/one-components';
import { NO_SOURCE_ID } from '../pages/shared/ListPage/getListPageVariables';
import {
  ArticleFormat,
  ArticleStatus,
  BrandKey,
} from '../__generated__/queries-web';

export interface AdvancedSearchParams {
  search?: string;
  categories?: AutocompleteItem[];
  tags?: AutocompleteItem[];
  source?: string;
  qualifier?: string;
  creator?: AutocompleteItem;
  author?: AutocompleteItem;
  updatedAfter?: Date;
  updatedBefore?: Date;
  imageSource?: AutocompleteItem;
  imageCredit?: string;
  containPush?: boolean;
  containLive?: boolean;
  status_in?: ArticleStatus[];
  containVideo?: boolean;
  containDiapo?: boolean;
  containPodcast?: boolean;
}

export const formatArticleSearchBarInput = (
  articleFormat: ArticleFormat,
  searchParams: AdvancedSearchParams,
  status: ArticleStatus = ArticleStatus.Published,
  brandKey?: BrandKey,
) => {
  let editedAfterDate: Date | undefined;
  if (searchParams.updatedAfter) {
    editedAfterDate = new Date(searchParams.updatedAfter);
    editedAfterDate?.setHours(0);
    editedAfterDate?.setMinutes(0);
    editedAfterDate?.setSeconds(0);
  }

  let editedBeforeDate: Date | undefined;
  if (searchParams.updatedBefore) {
    editedBeforeDate = new Date(searchParams.updatedBefore);
    editedBeforeDate?.setHours(23);
    editedBeforeDate?.setMinutes(59);
    editedBeforeDate?.setSeconds(59);
  }
  return {
    title_contains: searchParams.search,
    format: articleFormat,
    status: searchParams.status_in ? undefined : status,
    brandKey,
    creatorId: searchParams.creator?.id,
    articleAuthors_some: searchParams.author
      ? { author: { id: searchParams.author.id } }
      : undefined,
    editedAt_gte: editedAfterDate?.toISOString(),
    editedAt_lte: editedBeforeDate?.toISOString(),
    // Query the articles that have a live that contain at least 1 post
    live: searchParams.containLive
      ? { posts_some: { publishedAt_gt: new Date(0).toISOString() } }
      : undefined,
    // Query the articles that contain at least 1 push
    pushes_some: searchParams.containPush
      ? { sentAt_is_null: false }
      : undefined,
    articleQualifiers_some: searchParams.qualifier
      ? { qualifier: { id: searchParams.qualifier } }
      : undefined,
    source:
      searchParams.source && searchParams.source !== NO_SOURCE_ID
        ? { id: searchParams.source }
        : undefined,
    source_is_null: searchParams?.source === NO_SOURCE_ID || undefined,
    [articleFormat === ArticleFormat.Video
      ? 'articleChannels_some'
      : 'articleCategories_some']: searchParams.categories
      ? {
          category: { id_in: searchParams.categories.map(({ id }) => id) },
        }
      : undefined,
    articleTags_some: searchParams.tags
      ? { tag: { id_in: searchParams.tags.map(({ id }) => id) } }
      : undefined,
    AND: [
      ...(searchParams.status_in
        ? [
            {
              status_in: searchParams.status_in,
            },
          ]
        : []),
      // medias_contains can only be searched by the content of its fields "source", "credit" and "site" indistinguishably
      ...(typeof searchParams.imageSource?.label === 'string'
        ? [
            {
              medias_contains: searchParams.imageSource.label,
            },
          ]
        : []),
      ...(searchParams.imageCredit
        ? [
            {
              medias_contains: searchParams.imageCredit,
            },
          ]
        : []),
      ...(searchParams.containDiapo
        ? [
            {
              medias_contains: MediaTypesEnum.SLIDESHOW_TYPE,
            },
          ]
        : []),
      ...(searchParams.containPodcast
        ? [
            {
              medias_contains: MediaTypesEnum.PODCAST_TYPE,
            },
          ]
        : []),
    ],
    OR: [
      ...(searchParams.containVideo
        ? [
            {
              medias_contains: MediaTypesEnum.BRIGHTCOVE_TYPE,
            },
            {
              medias_contains: MediaTypesEnum.DAILYMOTION_TYPE,
            },
            {
              medias_contains: MediaTypesEnum.YOUTUBE_TYPE,
            },
          ]
        : []),
    ],
  };
};
