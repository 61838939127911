import { gql, useMutation } from '@apollo/client';
import {
  UpsertLive,
  UpsertLiveVariables,
} from '../../../__generated__/queries-web';
import { LIVE_FRAGMENT } from './livePostFragment.web.graphql';

const UPSERT_LIVE = gql`
  mutation UpsertLive(
    $where: LiveWhereUniqueInput!
    $create: LiveCreateInput!
    $update: LiveUpdateInput!
  ) {
    upsertLive(where: $where, create: $create, update: $update) {
      article {
        id
      }
      ...LiveFragment
    }
  }
  ${LIVE_FRAGMENT}
`;

interface UpsertProps {
  articleId: string;
  onCompleted: (data: UpsertLive | undefined) => void;
}

export const useLiveUpsert = ({ articleId, onCompleted }: UpsertProps) =>
  useMutation<UpsertLive, UpsertLiveVariables>(UPSERT_LIVE, {
    onCompleted,
    variables: {
      update: {},
      create: {
        article: {
          connect: { id: articleId },
        },
      },
      where: {
        article: { id: articleId },
      },
    },
  });
