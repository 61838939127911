import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  tags: {
    marginTop: theme.spacing(1),
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
