import { gql } from '@apollo/client';
import { GET_QUALIFIERS } from '../../../pages/ArticleEdit/DrawerContent/getQualifers.web.graphql';
import { useCreate, useUpdate } from '../../../utils/useUpdateCreate';
import {
  QualifierUpdateInput,
  QualifierCreateInput,
  UpdateQualifier,
  CreateQualifier,
} from '../../../__generated__/queries-web';
import { GET_QUALIFIER } from './getQualifier.web.graphql';

const UPDATE_QUALIFIER = gql`
  mutation UpdateQualifier(
    $where: QualifierWhereUniqueInput!
    $data: QualifierUpdateInput!
  ) {
    updateQualifier(where: $where, data: $data) {
      id
    }
  }
`;

export const useUpdateQualifier = () =>
  useUpdate<UpdateQualifier, QualifierUpdateInput>(UPDATE_QUALIFIER, [
    GET_QUALIFIER,
  ]);

const CREATE_QUALIFIER = gql`
  mutation CreateQualifier($data: QualifierCreateInput!) {
    createQualifier(data: $data) {
      id
    }
  }
`;

export const useCreateQualifier = () =>
  useCreate<CreateQualifier, QualifierCreateInput>(CREATE_QUALIFIER, [
    GET_QUALIFIERS,
  ]);
