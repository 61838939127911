import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(2),
  },
  flexField: {
    display: 'flex',
  },
  dateField: {
    marginRight: theme.spacing(1),
  },
  refetchButton: {
    marginBottom: theme.spacing(3),
  },
  countWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
  },
  quickFilters: {
    margin: theme.spacing(2, 0, 2),
    display: 'flex',
    flexWrap: 'wrap',
  },
  quickFilterCard: {
    flexGrow: 0,
    flexBasis: `calc(50% - ${theme.spacing(0.5)})`,
    marginTop: theme.spacing(1),
    '&:nth-child(odd)': {
      marginRight: theme.spacing(1),
    },
  },
  quickFilterCardContent: {
    padding: theme.spacing(0.3, 1),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  quickFilterColor: {
    height: 10,
    width: 10,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
    marginTop: 4,
  },
}));
