import {
  EditAppBar,
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { paths } from '../../routing/Router/paths';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { BrandKey } from '../../__generated__/queries-web';
import { SlideshowGenerateForm } from './SlideshowGenerateForm';
import { SlideshowGenerateList } from './SlideshowGenerateList';
import { SlideshowCompleteForm } from './SlideshowCompleteForm';

export const SlideshowGenerate: FC<RouteComponentProps<{
  brandKey: BrandKey;
  slideshowId?: string;
  activeTab?: string;
}>> = ({ history, match }) => {
  const [activeTab, setActiveTab] = useState(0);
  const { pushNotification } = useNotification();

  if (!match.params.brandKey) {
    pushNotification({
      message: "Erreur lors de la récupération de la marque dans l'URL",
      type: NotificationTypeEnum.error,
    });
  }

  useEffect(() => {
    setActiveTab(
      (() => {
        const activeTabParam = match.params.activeTab;
        if (activeTabParam === 'generate') {
          return 0;
        }
        if (activeTabParam === 'complete') {
          return 1;
        }
        return 2;
      })(),
    );
  }, [setActiveTab, match.params.activeTab]);

  return (
    <Box
      sx={{
        backgroundColor: grey[200],
      }}
    >
      <EditAppBar
        onNavigateBack={() => {
          history.push(paths.SLIDESHOW_LIST);
        }}
        title={
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Typography variant="h6">I.A. Génération de diaporamas</Typography>
            <Box
              sx={{
                backgroundColor: '#f44a62',
                color: 'white',
                padding: '5px 12px 2px 12px',
                borderRadius: 1,
                ml: 3,
              }}
            >
              Bêta
            </Box>
          </Box>
        }
      />

      <Box
        sx={{
          padding: '80px 80px',
          width: '100%',
          minHeight: '900px',
        }}
      >
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => {
            setActiveTab(newValue);
          }}
          variant="fullWidth"
          sx={{
            backgroundColor: 'white',
          }}
        >
          <Tab label="Générer un nouveau diaporama" />
          <Tab label="Compléter un diaporama" />
          <Tab label="Mes diaporamas générés" />
        </Tabs>
        {(() => {
          if (activeTab === 0) {
            return (
              <SlideshowGenerateForm
                setActiveTab={setActiveTab}
                brandKey={match.params.brandKey}
              />
            );
          }
          if (activeTab === 1) {
            return (
              <SlideshowCompleteForm
                setActiveTab={setActiveTab}
                brandKey={match.params.brandKey}
                slideshowEditId={match.params.slideshowId}
              />
            );
          }
          return <SlideshowGenerateList />;
        })()}
      </Box>
    </Box>
  );
};
