import React, { useMemo } from 'react';
import type { FC } from 'react';
import { IconButton } from '@mui/material';
import {
  ArrowForwardIos,
  FolderOutlined,
  ModeEditOutlineOutlined,
} from '@mui/icons-material';
import { isTextMatchWithQuery } from '../../../../../../../../apollo/queries/getSearchEventsList';
import { DISPLAY_CATEGORY_TITLE, EDIT_CATEGORY_TITLE } from './constants';
import { useStyles } from './styles';

interface AutoCompleteItemLabelProps {
  hasChildren: boolean;
  search: string;
  titleList: string[];
}
const AutoCompleteItemLabel: FC<AutoCompleteItemLabelProps> = ({
  hasChildren,
  search,
  titleList,
}) => {
  const classes = useStyles();
  const iconTitle = useMemo(
    () => (hasChildren ? DISPLAY_CATEGORY_TITLE : EDIT_CATEGORY_TITLE),
    [hasChildren],
  );

  return (
    <span className={classes.wrapper}>
      <IconButton
        aria-label={iconTitle}
        disableRipple
        size="small"
        title={iconTitle}
      >
        {hasChildren ? (
          <FolderOutlined className={classes.icon} fontSize="small" />
        ) : (
          <ModeEditOutlineOutlined className={classes.icon} fontSize="small" />
        )}
      </IconButton>

      {titleList.map((title, index) => {
        const isLastTitle = index + 1 === titleList.length;
        return (
          <span key={`${title}-${index}`}>
            <span
              {...(isTextMatchWithQuery(search, title) && {
                className: classes.highLight,
              })}
            >
              {title}
            </span>

            {!isLastTitle && <ArrowForwardIos />}
          </span>
        );
      })}
    </span>
  );
};

export { AutoCompleteItemLabel };
