import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(4),
  },
  grey: {
    color: theme.palette.grey[600],
  },
  rolesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  roleCard: {
    marginTop: theme.spacing(1),
    alignSelf: 'stretch',
    display: 'flex',
    padding: theme.spacing(2),
    overflow: 'visible',
  },
  roleSelect: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  roleDeleteButton: {
    flexShrink: 0,
  },
  addRoleButton: {
    marginTop: theme.spacing(2),
  },
}));
