import { RecipeStatus } from '../../__generated__/queries-recipe';
import { ArticleStatus, BrandKey } from '../../__generated__/queries-web';
import { ArticleMedia } from '../../utils/media';

export enum InternalLinkType {
  RECIPE = 'RECIPE',
  ARTICLE = 'ARTICLE',
  VIDEO = 'VIDEO',
  PROGRAM = 'PROGRAM',
  BROADCAST = 'BROADCAST',
  PRODUCT = 'PRODUCT',
  PRODUCT_BRAND = 'PRODUCT_BRAND',
}

export interface InternalLink {
  id: string;
  type: InternalLinkType;
  imageSrc?: ArticleMedia;
  brandKey: BrandKey;
  title: string;
  status: ArticleStatus | RecipeStatus | null;
  publishedAt: string;
  url: string;
}

export interface LinkType {
  type: InternalLinkType;
  icon: JSX.Element;
  label: string;
  tabLabel: string;
  buttonLabel: string;
}
