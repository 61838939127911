import { useQuery } from '@apollo/client';
import { FC } from 'react';
import {
  BrandKey,
  GetSegmentsByBrandKey,
  GetSegmentsByBrandKeyVariables,
  SegmentOrderByInput,
  SegmentStatus,
} from '../../../__generated__/queries-web';
import { GET_SEGMENTS_BY_BRANDKEY } from './getSegments.web.graphql';
import { DecoratedSegment, SegmentList } from './segmentList';
import { useStyles } from './styles';

interface SegmentWrapperProps {
  onChange: (segments: DecoratedSegment[]) => void;
  brandKey: BrandKey;
  segments?: DecoratedSegment[];
}

export const SegmentWrapper: FC<SegmentWrapperProps> = ({
  onChange,
  brandKey,
  segments,
}) => {
  const classes = useStyles();
  const { loading, error, data } = useQuery<
    GetSegmentsByBrandKey,
    GetSegmentsByBrandKeyVariables
  >(GET_SEGMENTS_BY_BRANDKEY, {
    variables: {
      first: 100,
      where: { brandKey, status: SegmentStatus.Published },
      orderBy: [SegmentOrderByInput.title_ASC],
    },
    fetchPolicy: 'cache-first',
  });

  if (error) {
    console.error(error);
    return <p>something went wong </p>;
  }

  if (loading) {
    return <p>Loading ...</p>;
  }
  const decoratedSegments =
    segments ||
    (data
      ? data.segments.map((segment) => ({
          ...segment,
          checked: false,
        }))
      : []);

  return (
    <div className={classes.root}>
      <SegmentList
        segments={decoratedSegments.filter(({ active }) => active !== false)}
        onChange={onChange}
        brandKey={brandKey}
      />
    </div>
  );
};
