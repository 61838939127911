import {
  AutocompleteItem,
  AutocompleteSelect,
} from '@prismamedia/one-components';
import React, { FC } from 'react';
import { useGetUsersPromise } from '../../../../apollo/queries/users.auth.graphql';

interface CreatorSelectProps {
  value?: AutocompleteItem;
  onChange?: (value?: AutocompleteItem) => void;
  className?: string;
}

export const CreatorSelect: FC<CreatorSelectProps> = ({
  value,
  onChange,
  className,
}) => {
  const getUsers = useGetUsersPromise();

  const fetchList = (search: string) =>
    getUsers({
      where: { name_contains: search },
      first: 20,
      skip: 0,
    }).then(({ users }) =>
      users?.map(({ id, name }) => ({ id, label: name || '' })),
    );

  return (
    <AutocompleteSelect
      label="Créateur"
      value={value}
      className={className}
      fetchList={fetchList}
      onChange={onChange}
      mandatoryInput
    />
  );
};
