import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';

export const link = (enhanced: Record<string, any>) => (theme: Theme) =>
  createStyles({
    link: {
      textDecoration: 'none',
      color: theme.palette.action.active,
      ...enhanced,
    },
  });
