import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { AppName, HomeAppBar } from '@prismamedia/one-components';
import { parse } from 'query-string';
import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { OneDrawerMenu } from '../components/AppBar';
import { paths } from '../routing/Router/paths';
import { auth } from '../utils/auth';
import { useStyles } from './styles';

export const Crud: FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const parsedSearch = location.search && parse(location.search);

  return (
    <>
      <HomeAppBar
        appDrawerMenu={<OneDrawerMenu app={AppName.Crud} />}
        currentUser={auth.user}
        disconnectUser={auth.logout}
      />
      <div className={classes.root}>
        <Typography variant="h5">Utilisateurs :</Typography>
        <div className={classes.linkContainer}>
          <Link
            to={{
              pathname: paths.USER_LIST,
            }}
          >
            <Button variant="contained" color="primary">
              Gérer les utilisateurs
            </Button>
          </Link>
          <Link
            to={{
              pathname: paths.USER_ROLE_LIST,
            }}
          >
            <Button variant="contained" color="primary">
              Gérer les rôles utilisateurs
            </Button>
          </Link>
          <Link
            to={{
              pathname: paths.USER_GROUP_LIST,
            }}
          >
            <Button variant="contained" color="primary">
              Gérer les groupes utilisateurs
            </Button>
          </Link>
        </div>
        <Typography variant="h5">Segments :</Typography>
        <div className={classes.linkContainer}>
          <Link
            data-testid="segment-link"
            to={{
              pathname: paths.SEGMENT_LIST,
              search: `?brand=${(parsedSearch && parsedSearch.brand) || 'TEL'}`,
            }}
          >
            <Button variant="contained" color="primary">
              Gérer les segments
            </Button>
          </Link>
        </div>
        <Typography variant="h5">Qualifiers :</Typography>
        <div className={classes.linkContainer}>
          <Link
            to={{
              pathname: paths.QUALIFIER_LIST,
              search: `?brand=${(parsedSearch && parsedSearch.brand) || 'TEL'}`,
            }}
          >
            <Button variant="contained" color="primary">
              Gérer les qualifiers
            </Button>
          </Link>
        </div>
        <Typography variant="h5">Attributes :</Typography>
        <div className={classes.linkContainer}>
          <Link
            to={{
              pathname: paths.ATTRIBUTES_LIST,
              search: `?brand=${(parsedSearch && parsedSearch.brand) || 'TEL'}`,
            }}
          >
            <Button variant="contained" color="primary">
              Gérer les Attributes
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};
