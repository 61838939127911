import { getMediasWithoutErrors } from '@prismamedia/one-components';
import { omit } from 'lodash';
import type { Category, CategoryForm } from '../types';
import { getCategorySection } from './categorySection.model';

export const getCategory = <M extends CategoryForm>({
  description,
  descriptionSEO,
  medias,
  sections,
  subTitle,
  tags,
  ...restCategoryForm
}: M): Partial<Category> => ({
  ...omit(restCategoryForm, 'createdAt', 'editedAt'),
  description: descriptionSEO,
  bodyDraft: description ? JSON.stringify(description) : null,
  subtitle: subTitle,
  medias: medias ? JSON.stringify(getMediasWithoutErrors(medias)) : null,
  tabs: sections.map(getCategorySection),
  tags: tags ? JSON.stringify(tags) : null,
});
