import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  accordion: {
    border: 0,
    boxShadow: 'none',

    '&::before': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  accordionDetails: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  accordionSummary: {
    paddingLeft: 0,
    paddingRight: 0,

    '& h6': {
      paddingLeft: theme.spacing(1),
    },

    '& p': {
      whiteSpace: 'pre-line',
    },
  },
  badge: {
    alignItems: 'center',
    backgroundColor: theme.palette.grey[400],
    color: 'white',
    position: 'relative',
    top: '4px',
    transform: 'none',
    zoom: 0.75,
  },
  wrapperAccordions: {
    margin: theme.spacing(1, -1, -1, 0),
  },
  wrapperTitle: {
    borderBottom: `solid 1px ${theme.palette.grey[300]}`,
    margin: theme.spacing(-2.5, -3, 0, -2),
    padding: theme.spacing(1.8, 2, 1.25, 2),

    '& p': {
      fontWeight: '500',
    },
  },
}));
