import { PhotoProvider } from '../../../__generated__/queries-web';
import { PhotoCollapsesTypes } from './types';

export const PHOTOS_TECHNICAL_SOURCES = {
  ...(Object.keys(PhotoCollapsesTypes).reduce(
    (acc, key) => ({
      ...acc,
      [key]: key,
    }),
    {},
  ) as { [key in keyof typeof PhotoCollapsesTypes]: string }),
  ...(Object.keys(PhotoProvider).reduce(
    (acc, key) => ({
      ...acc,
      [key]: `API_${PhotoProvider[
        (key as unknown) as PhotoProvider
      ].toUpperCase()}`,
    }),
    {},
  ) as { [key in PhotoProvider]: string }),
};
