import { EntityInputs } from '@prismamedia/one-components';
import { getSelectionText } from 'draftjs-utils';
import type { FC } from 'react';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { assert } from '../../../utils/assert';
import { ArticleFormat, BrandKey } from '../../../__generated__/queries-web';
import {
  baseRecipesURLs,
  getArticleUrl,
} from '../../InternalLinkModal/useInternalLinksGetter';
import { LinkInput } from '../LinkInput';
import { MultimediaPluginLink } from './MultimediaPluginLink';

const InternalLinkWithRouter: FC<
  EntityInputs & { url: string; entity: any } & RouteComponentProps<{
      brandKey: BrandKey;
    }>
> = ({ url, removeEntity, cancelEntity, editorState, setEntity, match }) => {
  const selectedText = getSelectionText(editorState);

  return (
    <div>
      <LinkInput
        cancelEntity={cancelEntity}
        removeEntity={removeEntity}
        url={url}
      />
      {!url && (
        <MultimediaPluginLink
          searchText={selectedText}
          brandKey={match.params.brandKey}
          onLinkSelected={(link) => {
            const isRecipe = link.__typename === 'Recipe';
            const isSlideshow = link.format === ArticleFormat.Slideshow;
            const publicUrl = isRecipe
              ? baseRecipesURLs[match.params.brandKey] +
                assert(link, ({ recipeUrls }) => recipeUrls[0].url?.path)
              : isSlideshow
              ? link.contextualizedUrl
              : getArticleUrl(link.articleUrls);
            setEntity({ url: publicUrl });
            cancelEntity();
          }}
          onClose={() => {
            cancelEntity();
          }}
        />
      )}
    </div>
  );
};

export const InternalLink = withRouter(InternalLinkWithRouter);
