import { Fab, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { FC, useState } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { ArticleFormat, BrandKey } from '../../__generated__/queries-web';
import { useGetBrandKey } from '../../apollo/sharedQueries/getBrandKeys.web.graphql';
import { paths } from '../../routing/Router/paths';
import { brandsOptions } from '../../utils/brands';
import { SpeedDialStyleProps, useStyles } from './styles';

interface SpeedDialBrandsProps {
  brand?: BrandKey;
  format: ArticleFormat | 'Orphanpage';
}

export const SpeedDialBrands: FC<SpeedDialBrandsProps> = ({
  brand,
  format,
}) => {
  const classes = useStyles() as SpeedDialStyleProps;
  const [isOpen, setOpen] = useState<boolean>(false);

  const { data: brandKeys, loading } = useGetBrandKey();

  if (loading) {
    return null;
  }

  const getLink = (brandKey: string) => {
    switch (format) {
      case ArticleFormat.Slideshow:
        return generatePath(paths.SLIDESHOW_NEW, {
          brandKey,
        });
      case 'Orphanpage':
        return generatePath(paths.ORPHAN_NEW, {
          brandKey,
        });
      default:
        return generatePath(paths.ARTICLE_EDIT, {
          brandKey,
          articleType: format === ArticleFormat.Rich ? 'article' : 'video',
        });
    }
  };

  return brand ? (
    <Link to={getLink(brand)} data-testid="add-ressource">
      <Fab color="primary" className={classes.speedDial}>
        <SpeedDialIcon />
      </Fab>
    </Link>
  ) : (
    <SpeedDial
      FabProps={
        {
          'data-testid': 'add-ressource',
        } as any
      }
      ariaLabel="SpeedDial brands"
      className={classes.speedDial}
      icon={<SpeedDialIcon />}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      open={isOpen}
      direction="up"
      color="primary"
      classes={{
        actions: classes.actions,
        fab: classes.fab,
      }}
    >
      {brandKeys?.categories
        ?.filter(({ brandKey }) => !brandsOptions[brandKey].disabledForCerise)
        .map(({ id, brandKey, title }) => (
          <SpeedDialAction
            key={id}
            icon={
              <Link
                data-testid={`add-ressource-${brandKey}`}
                to={getLink(brandKey)}
                className={classes.link}
              >
                {brandKey}
              </Link>
            }
            tooltipTitle={title}
            onClick={() => setOpen(true)}
          />
        ))}
    </SpeedDial>
  );
};
