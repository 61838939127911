import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { getAppTheme } from '../../utils/getAppTheme';

export const ThemeProvider: FC = ({ children }) => {
  const location = useLocation();
  const theme = getAppTheme(location.pathname);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline>{children}</CssBaseline>
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};
