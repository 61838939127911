import {
  Button,
  Paper,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  AppName,
  AutocompleteItem,
  HomeAppBar,
  SearchBar,
  SearchParams,
} from '@prismamedia/one-components';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthorsGetter } from '../../apollo/queries/authors.web.graphql';
import { OneDrawerMenu } from '../../components/AppBar';
import { auth } from '../../utils/auth';
import { AuthorsQuery, authorsTabs } from '../../utils/statuses';
import {
  AuthorOrderByInput,
  GetAuthorsVariables,
  GetAuthors_authors,
} from '../../__generated__/queries-web';
import { AuthorList } from './AuthorList';
import { AuthorTabs } from './AuthorTabs';
import { FormDialog } from './FormDialog';
import { useStyles } from './styles';

const PAGE_SIZE = 100;

export const Authors: FC = () => {
  const theme = useTheme();
  const { id: searchId } = useParams();
  const getAuthors = useAuthorsGetter();
  const [isOpen, setOpen] = useState(Boolean(searchId));
  const [currentId, setCurrentId] = useState<string | undefined>(searchId);
  const [params, setSearchParams] = useState<SearchParams>({
    search: '',
  });
  const [authors, setAuthors] = useState<GetAuthors_authors[]>([]);
  const [tabValue, setTabValue] = useState(AuthorsQuery.MY_AUTHORS);
  const dialogFullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMyAuthors = tabValue === AuthorsQuery.MY_AUTHORS;
  const userId = auth.user?.id || '';
  const open = (value: boolean) => () => {
    setOpen(value);
  };
  const close = () => {
    setOpen(false);
    setCurrentId(undefined);
  };
  const handleChange = (
    _event: SyntheticEvent<Element, Event>,
    newValue: AuthorsQuery,
  ) => {
    setTabValue(newValue);
  };
  const classes = useStyles();

  const loadMore = async (skip: number) => {
    const where = isMyAuthors
      ? { userId, name_contains: params.search || '' }
      : { name_contains: params.search || '' };

    const fetchMoreResult = await getAuthors({
      where,
      first: PAGE_SIZE,
      skip,
      orderBy: [AuthorOrderByInput.name_ASC],
    });

    if (fetchMoreResult?.authors.length > 0) {
      setAuthors((prev) => [...prev, ...fetchMoreResult.authors]);
    }
    return fetchMoreResult?.authors;
  };

  const fetchAutocompleteList = async (
    search: string,
  ): Promise<AutocompleteItem[] | undefined> => {
    const autoCompleteVariables: GetAuthorsVariables = {
      first: 10,
      where: {
        name_contains: search,
      },
      skip: 0,
      orderBy: [AuthorOrderByInput.name_ASC],
    };

    const { authors: authorsFetched } = await getAuthors(autoCompleteVariables);

    return authorsFetched?.map(({ name, ...other }) => ({
      label: name,
      ...other,
    }));
  };

  useEffect(() => {
    (async (search: string | undefined, myAuthors: boolean) => {
      const whereInput = myAuthors
        ? { userId, name_contains: search || '' }
        : { name_contains: search || '' };

      const fetchVariables: GetAuthorsVariables = {
        first: PAGE_SIZE,
        where: whereInput,
        orderBy: [AuthorOrderByInput.name_ASC],
      };

      const { authors: authorsFetched } = await getAuthors(fetchVariables);

      setAuthors(authorsFetched);
    })(params.search, isMyAuthors);
    // eslint-disable-next-line
  }, [isMyAuthors, params.search, tabValue, userId]);

  return (
    <>
      <div className={classes.wrapper}>
        {auth.isAuthenticated() && (
          <HomeAppBar
            currentUser={auth.user}
            disconnectUser={auth.logout}
            appDrawerMenu={
              <>
                <OneDrawerMenu app={AppName.Web} />
                <Typography variant="h6" color="inherit">
                  Gestion des auteurs
                </Typography>
              </>
            }
            searchBar={
              <SearchBar
                autocomplete={{
                  fetchList: fetchAutocompleteList,
                  onSelect: ({ id }) => {
                    setCurrentId(id as string | undefined);
                    setOpen(true);
                  },
                }}
                placeholder="Rechercher un auteur"
                searchParams={params}
                setSearchParams={setSearchParams}
              />
            }
          />
        )}
      </div>
      <div className={classes.container}>
        <Paper square className={classes.root}>
          <Toolbar className={classes.toolbar}>
            <AuthorTabs
              tabs={authorsTabs}
              value={tabValue}
              {...{ handleChange }}
            />
            <div className={classes.button} onClick={open(true)}>
              <Button variant="contained" color="primary" aria-label="add">
                Ajouter un auteur
              </Button>
            </div>
          </Toolbar>
          {authors && (
            <AuthorList
              authors={authors}
              isFullScreen={dialogFullScreen}
              setAuthor={setAuthors}
              {...{
                tabValue,
                loadMore,
              }}
            />
          )}
        </Paper>
        {isOpen && (
          <FormDialog
            searchId={currentId}
            isFullScreen={dialogFullScreen}
            isOpen={isOpen}
            handleClose={close}
            title="Créer un auteur"
            setAuthor={setAuthors}
          />
        )}
      </div>
    </>
  );
};
