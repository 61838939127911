import { Theme } from '@mui/material';
import {
  diapoTheme,
  defaultTheme,
  videoTheme,
  cardTheme,
  pageTheme,
} from '../muiThemes';
import { isArticleVideo, isOneWeb, isPush, isPage, isCard } from './url';

export const getAppTheme = (pathname: string): Theme => {
  const currentApp = true;
  switch (currentApp) {
    case isArticleVideo(pathname):
      return videoTheme;
    case isOneWeb(pathname):
    case isPush(pathname):
    case pathname === '/dashboard':
      return defaultTheme;
    case isCard(pathname):
      return cardTheme;
    case isPage(pathname):
      return pageTheme;
    default:
      return diapoTheme;
  }
};
