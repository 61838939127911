import React, { FC, useRef } from 'react';
import type { TablePluginData, TableShape } from '../types';
import { tableBlockRenderer } from './tableBlock.renderer';
import type { EditorState } from 'draft-js';
import { Editor } from 'draft-js';

interface TableDraftProps {
  tablePluginData: TablePluginData<EditorState>;
  shape?: TableShape | null;
  onChange: (editorState: EditorState) => void;
}
export const TableDraft: FC<TableDraftProps> = ({
  onChange,
  shape,
  tablePluginData,
}) => {
  const wrapperEditorRef = useRef<any>();

  return (
    <div>
      <Editor
        ref={wrapperEditorRef}
        blockRendererFn={tableBlockRenderer({
          editor: wrapperEditorRef?.current,
          onChange,
          shape,
          ...tablePluginData,
        })}
        editorState={tablePluginData.tableContentState}
        onChange={onChange}
        readOnly={true}
      />
    </div>
  );
};
