import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    border: `solid 1px ${theme.palette.grey[300]}`,
    boxShadow: 'none',
    cursor: 'pointer',
    flexGrow: 1,
    height: '14rem',
    margin: 0,
  },
  cardContent: {
    minHeight: '95px',

    '& > p': {
      color: theme.palette.grey['500'],
      fontSize: '.85rem',
      paddingRight: theme.spacing(0.85),
    },

    '& > h2': {
      color: theme.palette.grey['800'],
      fontWeight: 500,
      fontSize: 20,
      marginTop: theme.spacing(0.25),
    },
  },
  cardMedia: {
    backgroundColor: theme.palette.grey[200],
    height: '8rem',
  },
  imageFallback: {
    color: theme.palette.grey[300],
    fontSize: '4rem',
  },
}));
