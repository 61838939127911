import {
  GetArticle_article_articleEvents_category,
  GetArticle_article_articleEvents,
} from '../../../../__generated__/queries-web';

export type GetArticleEvent = Omit<
  GetArticle_article_articleEvents_category,
  '__typename'
> & {
  order: number;
};
export const getArticleEvent = ({
  order,
  id,
  title,
}: GetArticleEvent): GetArticle_article_articleEvents => ({
  __typename: 'ArticleEvent',
  order,
  category: {
    __typename: 'Category',
    id,
    title,
  },
});
