import { gql } from '@apollo/client';

export const GET_BRANDS = gql`
  query GetBrands {
    categories(where: { level: 2, format: Category }, first: 25) {
      brandKey
      title
    }
  }
`;
