import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import TableChartSharpIcon from '@mui/icons-material/TableChartSharp';
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
} from '@mui/material';
import type { EditorState } from 'draft-js';
import React, { ChangeEvent, FC } from 'react';
import type { TablePluginData } from '../../types';
import { MIN_TABLE_COL, MIN_TABLE_ROW } from '../constants';
import type { TableDialogState } from '../useEditDialogTable';
import { TableDialogActionsTypes } from '../useEditDialogTable';
import { useStyles } from './styles';
import { ACCENTUATION_LABEL, COLUMNS_LABEL, ROWS_LABEL } from './constants';

interface DialogToolbarProps {
  dialogTableBlockData: TablePluginData<EditorState>;
  onChangeStructure: (action: TableDialogActionsTypes) => void;
  onChangeAccentuation: (event: ChangeEvent<HTMLInputElement>) => void;
  tableDialogState: TableDialogState;
}
export const DialogToolbar: FC<DialogToolbarProps> = React.memo(
  ({
    dialogTableBlockData,
    onChangeStructure,
    onChangeAccentuation,
    tableDialogState,
  }) => {
    const classes = useStyles();

    return (
      <aside className={classes.wrapperToolbar}>
        <div className={classes.wrapperCounter}>
          <InputLabel>{ROWS_LABEL}</InputLabel>
          <ButtonGroup
            aria-label="outlined button group"
            size="large"
            fullWidth
          >
            <Button
              disabled={tableDialogState.rows.quantity === MIN_TABLE_ROW}
              onClick={() =>
                onChangeStructure(TableDialogActionsTypes.DELETE_ROW)
              }
            >
              <RemoveIcon />
            </Button>
            <Button disabled>{tableDialogState.rows.quantity}</Button>
            <Button
              onClick={() => onChangeStructure(TableDialogActionsTypes.ADD_ROW)}
            >
              <AddIcon />
            </Button>
          </ButtonGroup>
        </div>

        <div className={classes.wrapperCounter}>
          <InputLabel>{COLUMNS_LABEL}</InputLabel>
          <ButtonGroup
            aria-label="outlined button group"
            size="large"
            fullWidth
          >
            <Button
              disabled={tableDialogState.cols.quantity === MIN_TABLE_COL}
              onClick={() =>
                onChangeStructure(TableDialogActionsTypes.DELETE_COL)
              }
            >
              <RemoveIcon />
            </Button>
            <Button disabled>{tableDialogState.cols.quantity}</Button>
            <Button
              onClick={() => onChangeStructure(TableDialogActionsTypes.ADD_COL)}
            >
              <AddIcon />
            </Button>
          </ButtonGroup>
        </div>

        <div className={classes.wrapperAccentuation}>
          <FormGroup row={true}>
            <InputLabel>{ACCENTUATION_LABEL}</InputLabel>
            <FormControlLabel
              control={
                <Checkbox
                  checked={dialogTableBlockData.accentuation.top}
                  name="top"
                  onChange={onChangeAccentuation}
                />
              }
              label={<TableChartSharpIcon className={classes.icon} />}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={dialogTableBlockData.accentuation.bottom}
                  name="bottom"
                  onChange={onChangeAccentuation}
                />
              }
              label={
                <TableChartSharpIcon
                  className={classes.icon}
                  classes={{ root: classes.bottomIcon }}
                />
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={dialogTableBlockData.accentuation.left}
                  name="left"
                  onChange={onChangeAccentuation}
                />
              }
              label={
                <TableChartSharpIcon
                  className={classes.icon}
                  classes={{ root: classes.leftIcon }}
                />
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={dialogTableBlockData.accentuation.right}
                  name="right"
                  onChange={onChangeAccentuation}
                />
              }
              label={
                <TableChartSharpIcon
                  className={classes.icon}
                  classes={{ root: classes.rightIcon }}
                />
              }
            />
          </FormGroup>
        </div>
      </aside>
    );
  },
);
