import React from 'react';
import { useParams } from 'react-router-dom';
import uuidv4 from 'uuid/v4';
import { OrphanEdit } from '.';
import { BrandKey, OrphanPageStatus } from '../../../__generated__/queries-web';

interface newPageParams {
  brandKey: BrandKey;
}
export const NewOrphanPage = () => {
  const { brandKey } = useParams<newPageParams>();
  return (
    <OrphanEdit
      defaultState={{
        brandKey,
        status: OrphanPageStatus.Draft,
        id: uuidv4(),
        title: '',
        url: '',
        metas: [],
        content: '',
        isCMPEnabled: true,
        isAdsEnabled: true,
        isFullScreen: true,
      }}
    />
  );
};
