import { Button } from '@mui/material';
import { EditAppBar } from '@prismamedia/one-components';
import React, { Dispatch, FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LiveFragment } from '../../../__generated__/queries-web';
import { DialogConfirm } from '../DialogConfirmation';
import { useLiveUpdateDates } from '../graphql/updateLiveDates.web.graphql';
import { actions, ActionType } from '../reducer';
import { updateLivePostDates } from '../sideEffects';
import { useStyles } from './styles';

interface LiveHeaderProps {
  live: (Pick<LiveFragment, 'id'> & Partial<LiveFragment>) | null;
  dispatch: Dispatch<ActionType>;
}

export const LiveHeader: FC<LiveHeaderProps> = ({ live, dispatch }) => {
  const history = useHistory();
  const classes = useStyles();
  const [displayConfirm, setConfirm] = useState(false);

  const isLiveClose = Boolean(live?.endedAt);
  const confirmAction = isLiveClose ? 'relancer' : 'clôturer';

  const [upsertMutation] = useLiveUpdateDates({
    articleId: live?.id || '',
    closeLive: !isLiveClose,
  });

  const handleLiveSwitch = async () => {
    const res = await updateLivePostDates(upsertMutation);

    if (res) {
      dispatch(actions.loadData({ ...res }));
    }
    setConfirm(false);
  };

  return (
    <>
      <EditAppBar
        onNavigateBack={history.goBack}
        title="Live"
        additionalElement={
          isLiveClose ? (
            <Button
              data-testid="liveBtn"
              onClick={() => setConfirm(true)}
              variant="outlined"
              className={classes.button}
            >
              Ouvrir le Live
            </Button>
          ) : (
            <Button
              data-testid="liveBtn"
              onClick={() => setConfirm(true)}
              variant="outlined"
              className={classes.button}
            >
              Cloturer le Live
            </Button>
          )
        }
      />
      <DialogConfirm
        onDialogConfirm={handleLiveSwitch}
        onClose={() => setConfirm(false)}
        title={`Souhaitez-vous ${confirmAction} le live ?`}
        isOpen={displayConfirm}
        confirmMessage={confirmAction}
      />
    </>
  );
};
