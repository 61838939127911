import { useQuery } from '@apollo/client';
import { InputLabel, Typography } from '@mui/material';
import React, { Dispatch } from 'react';
import { Suggest } from '../../../../components/Suggest';
import { getTitleFromBrandKey } from '../../../../utils/brands';
import {
  BrandKey,
  CategoryStatus,
  GetBrandCategories,
  GetBrandCategoriesVariables,
  GetBrandFilterCategories,
  GetBrandFilterCategoriesVariables,
} from '../../../../__generated__/queries-web';
import { GET_BRAND_CATEGORIES } from '../../../SlideshowEdit/CategorySelector/getBrandCategories.web.graphql';
import { CardState } from '../../CardEdit/types';
import {
  actions as cardActions,
  CardEditAction,
} from '../../CardEdit/CardForm/reducer/cardReducer';
import { CardTag } from '../../components/Tag';
import { useStyles } from './styles';

interface CardDrawerProps {
  brandKey: BrandKey;
  dispatch: Dispatch<CardEditAction>;
  state: CardState;
}

export const CardDrawer: React.FC<CardDrawerProps> = ({
  brandKey,
  dispatch,
  state,
}) => {
  const { data: cardCategories } = useQuery<
    GetBrandCategories | GetBrandFilterCategories,
    GetBrandCategoriesVariables | GetBrandFilterCategoriesVariables
  >(GET_BRAND_CATEGORIES, {
    variables: {
      brandKey,
      status: CategoryStatus.Published,
    },
  });

  const classes = useStyles();

  return (
    <div className={classes.grid}>
      <Typography variant="h6" gutterBottom>
        {getTitleFromBrandKey(brandKey)}
      </Typography>
      <div>
        <InputLabel shrink>Catégories :</InputLabel>
        <Suggest
          selectedItems={state?.cardCategories.map(({ category }) => category)}
          placeholder="Rechercher une catégorie"
          suggestions={cardCategories?.categories?.[0]?.children || []}
          multiple={true}
          maxNumberSuggest={200}
          isPrefetched
          onChange={(items) => {
            dispatch(
              cardActions.updateCategory({
                field: 'cardCategories',
                value: items,
              }),
            );
          }}
        />
      </div>

      <CardTag />
    </div>
  );
};
