import React from 'react';
import { AdvicePopper } from './AdvicePopper';

export const ArticleEditorTitlePopper = (
  <AdvicePopper
    accordions={[
      {
        details: `Pour optimiser l'affichage des titres d'article sur Google, ceux-ci doivent faire entre 60 et 70 caractères.\n
        Employez les mots-clés les plus importants en début de titre et suscitez l'envie de cliquer !`,
        summary: 'Titre supérieur à 60 signes',
      },
      {
        details:
          'Au-delà de 160 signes, les titres seront tronqués sur Google.',
        summary: 'Titre inférieur à 160 signes',
      },
    ]}
    title="Conseil de saisie"
  />
);

export const ArticleEditorLeadPopper = (
  <AdvicePopper
    accordions={[
      {
        details: `Google a pour habitude de reprendre le chapô des articles pour répondre aux requêtes des internautes.\n
        Pour optimiser la visibilité de vos articles, veillez à renseigner une description longue de 150 à 300 caractères.`,
        summary: 'Chapô supérieur à 150 signes',
      },
    ]}
    title="Conseil de saisie"
  />
);

export const ArticleEditorContentPopper = (
  <AdvicePopper
    accordions={[
      {
        details: `Plus les contenus sont longs, plus ils sont performants dans les SERPs de Google. 
        Le texte doit être structuré : présence de paragraphes tous les 800 à 1000 signes, au moins 1 intertitre, et au minimum 3 liens internes.\n
        Les illustrations dans le corps de l'article seront placées après les 800 premiers signes du contenu (hors titre et hors chapô).`,
        summary: 'Contenu supérieur à 2000 signes',
      },
    ]}
    title="Conseil de saisie"
  />
);

export const ArticleEditorTagPopper = (
  <AdvicePopper
    accordions={[
      {
        details: `Un tag est un mot clé décrivant un sujet, un thème (tag), une personne (person) ou une destination (destination). 
        Les tags ont pour objectif d'enrichir la sémantique des articles et d'accroître notre visibilité sur les mots-clés pertinents et générateurs de trafic. 
        Pour chaque article, choisissez 5 tags précis maximum et ordonnez-les du plus spécifique au plus générique. 
        Pour chaque tag, une page est créée : le tag devient le titre principal de cette page et la page est composée de tous les contenus ayant le même tag. Les tags pertinents sont spécifiques, différents des noms de rubrique, uniques, au singulier et pérennes et ont au moins 8 articles associés.`,
        summary: 'Choisissez 5 tags précis maximum',
      },
    ]}
    title="Conseil de saisie"
  />
);
