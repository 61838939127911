import React, { FC, useCallback, useContext, useState } from 'react';
import {
  NotificationTypeEnum,
  useNotification,
  useMountedState,
} from '@prismamedia/one-components';
import { CircularProgress } from '@mui/material';
import { Star, StarBorder } from '@mui/icons-material';
import { useStyles } from './styles';
import { useUpdatePageCategoryIsHeadline } from '../../../../../apollo/mutations/category.web.graphql';
import { CategoriesContext } from '../../../../../categories.context';
import { DataTestIdListPage } from '../../../../../tests/constants';
import type { Category } from '../../../../../types';

interface FavoriteProps {
  category: Category;
}

export const Favorite: FC<FavoriteProps> = ({
  category: { id, isHeadlined },
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles({
    isHeadlined,
    isLoading,
  });

  const { refetchCategories } = useContext(CategoriesContext);
  const isMounted = useMountedState();
  const { pushNotification } = useNotification();

  const updateIsHeadline = useUpdatePageCategoryIsHeadline();

  const handleToggleHeadline = useCallback(
    (event) => {
      event.stopPropagation();

      setIsLoading(true);

      updateIsHeadline(id, !isHeadlined)
        .then(() => {
          refetchCategories();
        })
        .catch((error) => {
          pushNotification({
            type: NotificationTypeEnum.error,
            message: `
            [Unable to update category headline state]
            ${error.message}
          `,
          });
        })
        .finally(() => {
          setTimeout(() => {
            isMounted() && setIsLoading(false);
          }, 500);
        });
    },
    [
      id,
      isHeadlined,
      isMounted,
      pushNotification,
      refetchCategories,
      updateIsHeadline,
    ],
  );

  return (
    <div
      className={classes.wrapper}
      data-testid={DataTestIdListPage.FAVORITES_ICON_WRAPPER}
      onClick={handleToggleHeadline}
    >
      {isLoading && (
        <CircularProgress
          className={classes.loader}
          color="primary"
          size={20}
        />
      )}

      {isHeadlined ? (
        <Star className={classes.starIcon} />
      ) : (
        <StarBorder className={classes.starIcon} />
      )}
    </div>
  );
};
