import { gql, useQuery } from '@apollo/client';
import {
  GetArticleHistoryCount,
  GetArticleHistoryCountVariables,
} from '../../__generated__/queries-web';

const GET_ARTICLE_HISTORY_COUNT = gql`
  query GetArticleHistoryCount($where: ArticleHistoryWhereInput) {
    articleHistoryCount(where: $where)
  }
`;

export const useGetArticleHistoryCount = (id: string) =>
  useQuery<GetArticleHistoryCount, GetArticleHistoryCountVariables>(
    GET_ARTICLE_HISTORY_COUNT,
    {
      variables: { where: { article: { id } } },
      skip: !id,
      fetchPolicy: 'network-only',
    },
  );
