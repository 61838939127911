import { gql, useQuery } from '@apollo/client';
import {
  BrandKey,
  GetPrompts,
  GetPromptsVariables,
} from '../../__generated__/queries-web';
import { PROMPT_FRAGMENT } from '../fragments/prompt.web.graphql';

const GET_PROMPTS = gql`
  query GetPrompts(
    $first: Int!
    $where: PromptWhereInput
    $skip: Int
    $orderBy: [PromptOrderByInput!]
  ) {
    prompts(first: $first, where: $where, skip: $skip, orderBy: $orderBy) {
      ...PromptFragment
    }
  }
  ${PROMPT_FRAGMENT}
`;

export const GET_PROMPT = gql`
  query GetPrompt($brandKey: BrandKey!, $field: PromptField!, $label: String!) {
    prompt(where: { brandKey: $brandKey, field: $field, label: $label }) {
      body
    }
  }
`;

export const useGetBrandPrompts = (brandKey: BrandKey) =>
  useQuery<GetPrompts, GetPromptsVariables>(GET_PROMPTS, {
    variables: {
      first: 50,
      where: { brandKey },
    },
  });
