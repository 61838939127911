import {
  Loader,
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  DialogActions,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { useMutation, useQuery } from '@apollo/client';
import {
  BrandKey,
  GenerateGoodDealArticle,
  GenerateGoodDealArticleVariables,
  GetGoodDealPrompts,
  GetPrompt,
  GetPromptVariables,
  PromptField,
  UpdateGoodDealPrompts,
  UpdateGoodDealPromptsVariables,
  UpdatePrompt,
  UpdatePromptVariables,
} from '../../__generated__/queries-web';
import { grey } from '@mui/material/colors';
import { GET_GOOD_DEAL_PROMPTS } from '../../apollo/queries/articleGoodDealAIPrompt.web.graphql';
import { UPDATE_GOOD_DEAL_PROMPT } from '../../apollo/mutations/articleGoodDealAIPrompt.web.graphql';
import CheckIcon from '@mui/icons-material/Check';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { GENERATE_GOOD_DEAL_ARTICLE_IA } from '../../apollo/mutations/generateGoodDealArticleIA.web.graphql';
import { client } from '../../apollo';
import { GET_ARTICLE_GOOD_DEAL_PROCESS } from '../../apollo/queries/getArticleGoodDealAiProcess.web.graphql';
import { auth } from '../../utils/auth';
import { GET_PROMPT } from '../../apollo/queries/prompts.web.graphql';
import { UPDATE_PROMPT } from '../../apollo/mutations/prompt.web.graphql';

const DisclosureText = ({ text }: { text: string }) => {
  const [disclosureText, setDisclosureText] = useState(text);
  const [successFullUpdate, setSuccessFullUpdate] = useState(false);
  const { pushNotification } = useNotification();

  const [updatePrompt, { loading }] = useMutation<
    UpdatePrompt,
    UpdatePromptVariables
  >(UPDATE_PROMPT, {
    refetchQueries: [GET_PROMPT],
    onError: (e) => {
      pushNotification({
        message: e.message || 'Une erreur est survenue',
        type: NotificationTypeEnum.error,
      });
    },
    onCompleted: () => {
      setSuccessFullUpdate(true);
    },
  });

  return (
    <Box
      sx={{
        width: '100%',
        mb: 3,
      }}
    >
      <InputLabel shrink>Clause de transparence</InputLabel>
      <TextField
        key={`disclosure`}
        id={`disclosure`}
        rows={6}
        multiline
        value={disclosureText}
        onChange={(e) => setDisclosureText(e.target.value)}
        style={{
          borderColor: grey[400],
          borderRadius: '8px',
          padding: '8px 12px',
          width: '100%',
        }}
        variant="outlined"
      />
      {(() => {
        if (disclosureText !== text) {
          if (loading) {
            return <Loader />;
          }
          return (
            <Button
              data-testid="confirmBtn"
              color="primary"
              onClick={() => {
                setSuccessFullUpdate(false);
                updatePrompt({
                  variables: {
                    where: {
                      brandKey: BrandKey.CAP,
                      field: PromptField.Body,
                      label: 'good-deal-disclosure-text',
                    },
                    data: {
                      body: disclosureText,
                    },
                  },
                });
              }}
              variant="contained"
            >
              {'Enregistrer'}
            </Button>
          );
        }
        if (successFullUpdate) {
          return (
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                color: 'green',
              }}
            >
              <CheckIcon /> <Box>Enregistré</Box>
            </Box>
          );
        }
      })()}
    </Box>
  );
};

const PromptInput = ({
  promptValue,
  promptOrder,
}: {
  promptValue: string;
  promptOrder: number;
}) => {
  const { pushNotification } = useNotification();
  const [successFullUpdate, setSuccessFullUpdate] = useState(false);
  const [prompt, setPrompt] = useState(promptValue);
  const [updatePrompt, { loading }] = useMutation<
    UpdateGoodDealPrompts,
    UpdateGoodDealPromptsVariables
  >(UPDATE_GOOD_DEAL_PROMPT, {
    refetchQueries: [GET_GOOD_DEAL_PROMPTS],
    onError: (e) => {
      pushNotification({
        message: e.message || 'Une erreur est survenue',
        type: NotificationTypeEnum.error,
      });
    },
    onCompleted: () => {
      setSuccessFullUpdate(true);
    },
  });

  return (
    <Box
      sx={{
        width: '100%',
        mb: 3,
      }}
    >
      <InputLabel shrink>Prompt {promptOrder}</InputLabel>
      <TextField
        key={`prompt${promptOrder}`}
        id={`prompt${promptOrder}`}
        rows={6}
        multiline
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        style={{
          borderColor: grey[400],
          borderRadius: '8px',
          padding: '8px 12px',
          width: '100%',
        }}
        variant="outlined"
      />
      {(() => {
        if (promptValue !== prompt) {
          if (loading) {
            return <Loader />;
          }
          return (
            <Button
              data-testid="confirmBtn"
              color="primary"
              onClick={() => {
                setSuccessFullUpdate(false);
                updatePrompt({
                  variables: {
                    where: {
                      promptOrder,
                    },
                    data: {
                      prompt,
                    },
                  },
                });
              }}
              variant="contained"
            >
              {'Enregistrer'}
            </Button>
          );
        }
        if (successFullUpdate) {
          return (
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                color: 'green',
              }}
            >
              <CheckIcon /> <Box>Enregistré</Box>
            </Box>
          );
        }
      })()}
    </Box>
  );
};

export const ArticleGenerateForm = ({
  setActiveTab,
}: {
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [amazonUrl, setAmazonUrl] = useState('');
  const [editPrompts, setEditPrompts] = useState(false);

  const { pushNotification } = useNotification();
  const [generateGoodDealArticleIA] = useMutation<
    GenerateGoodDealArticle,
    GenerateGoodDealArticleVariables
  >(GENERATE_GOOD_DEAL_ARTICLE_IA, {
    onCompleted: () => {
      setActiveTab(1);
      client.refetchQueries({
        include: [GET_ARTICLE_GOOD_DEAL_PROCESS],
      });
    },
    onError: (error) => {
      pushNotification({
        type: NotificationTypeEnum.error,
        message: error.message || 'Une erreur est survenue',
      });
    },
  });

  const { data: disclosureText } = useQuery<GetPrompt, GetPromptVariables>(
    GET_PROMPT,
    {
      variables: {
        brandKey: BrandKey.CAP,
        label: 'good-deal-disclosure-text',
        field: PromptField.Body,
      },
    },
  );

  const { loading, data } = useQuery<GetGoodDealPrompts>(GET_GOOD_DEAL_PROMPTS);

  const canModifyPrompts =
    auth &&
    auth.user &&
    (auth.user.isSuperAdmin() ||
      ['abillaul@prismamedia.com', 'arobert@prismamedia.com'].includes(
        auth.user.email,
      ));

  return (
    <form
      onSubmit={async (formEvent) => {
        formEvent.preventDefault();
        generateGoodDealArticleIA({
          variables: {
            amazonProductUrl: amazonUrl,
          },
        });
      }}
    >
      <Card
        variant="outlined"
        sx={{
          padding: '40px',
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <AutoFixHighIcon
            fontSize="small"
            sx={{ marginRight: '2px', color: 'text.secondary' }}
          />
          <Typography
            sx={{ fontSize: 14, paddingTop: '1.5px' }}
            color="text.secondary"
            gutterBottom
          >
            Paramètres de l'article
          </Typography>
        </Box>
        <Box>
          <TextField
            sx={{
              width: '100%',
              mb: 3,
            }}
            id="outlined-error"
            label="Url de l'article"
            value={amazonUrl}
            onChange={(e) => setAmazonUrl(e.target.value)}
            required
          />
        </Box>
        {canModifyPrompts ? (
          <Card
            sx={{
              padding: 2,
            }}
          >
            <Button
              variant="text"
              onClick={() => {
                setEditPrompts(!editPrompts);
              }}
              sx={{
                color: grey[700],
              }}
              startIcon={editPrompts ? <ExpandLess /> : <ExpandMore />}
            >
              {editPrompts ? 'Masquer les prompts' : 'Modifier les prompts'}
            </Button>

            {data && editPrompts && (
              <Box
                sx={{
                  mt: 3,
                }}
              >
                {data.articleGoodDealAIPrompts.map(
                  ({ prompt, promptOrder }) => (
                    <PromptInput
                      key={promptOrder}
                      promptValue={prompt}
                      promptOrder={promptOrder}
                    />
                  ),
                )}
                {disclosureText?.prompt?.body && (
                  <DisclosureText text={disclosureText.prompt.body} />
                )}
              </Box>
            )}
          </Card>
        ) : null}

        <DialogActions>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {loading ? (
              <Loader />
            ) : (
              <Button
                data-testid="confirmBtn"
                color="primary"
                variant="contained"
                type="submit"
              >
                {"Générer l'article"}
              </Button>
            )}
          </Box>
        </DialogActions>
      </Card>
    </form>
  );
};
