import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Tooltip,
} from '@mui/material';
import { AdvancedSearchProps } from '@prismamedia/one-components';
import React, { ChangeEvent, FC } from 'react';
import { getCheckedTypesList } from '../';
import { TagSearchTypes } from '../../../../../__generated__/queries-web';
import {
  ALLOWED_TVBY_TYPES,
  CINEMA_PROGRAM_LABEL,
  PROVIDER_TOOLTIP_TEXT,
  TV_PROGRAM_LABEL,
  VOD_PROGRAM_LABEL,
} from '../constants';
import { useStyles } from './styles';

export const AdvancedSearch: FC<AdvancedSearchProps> = ({
  searchParams,
  updateSearchParam,
}) => {
  const classes = useStyles();
  const canUncheck = getCheckedTypesList(searchParams).length > 1;

  const getFormControlLabel = (typeName: TagSearchTypes) => {
    switch (typeName) {
      case TagSearchTypes.Cinema:
        return CINEMA_PROGRAM_LABEL;
      case TagSearchTypes.Broadcast:
        return TV_PROGRAM_LABEL;
      default:
        return VOD_PROGRAM_LABEL;
    }
  };

  return (
    <>
      <div className={classes.wrapper}>
        <FormGroup>
          {ALLOWED_TVBY_TYPES.map((typeName) => {
            const isChecked = !!searchParams[TagSearchTypes[typeName]];
            const isDisabled = isChecked && !canUncheck;
            return (
              <Tooltip
                arrow
                disableHoverListener={!isDisabled}
                key={typeName}
                placement="left"
                title={PROVIDER_TOOLTIP_TEXT}
              >
                <FormControlLabel
                  label={getFormControlLabel(typeName)}
                  control={
                    <Checkbox
                      checked={isChecked}
                      className={classes.checkbox}
                      disabled={isDisabled}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        updateSearchParam(TagSearchTypes[typeName])(
                          event.target.checked,
                        );
                      }}
                    />
                  }
                  key={typeName}
                />
              </Tooltip>
            );
          })}
        </FormGroup>
      </div>
      <Divider />
    </>
  );
};
