import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  UpdateArticle,
  UpdateArticleVariables,
  UpsertArticle,
  UpsertArticleVariables,
} from '../../__generated__/queries-web';
import { ARTICLE_DETAILS_FRAGMENT } from '../fragments/article.web.graphql';
import { GET_ARTICLE_QUERY } from '../queries/articles.web.graphql';

const UPSERT_ARTICLE = gql`
  mutation UpsertArticle(
    $create: ArticleCreateInput!
    $update: ArticleUpdateInput!
    $where: ArticleWhereUniqueInput!
  ) {
    upsertArticle(create: $create, update: $update, where: $where) {
      ...ArticleDetailsFragment
    }
  }
  ${ARTICLE_DETAILS_FRAGMENT}
`;

const UPDATE_ARTICLE = gql`
  mutation UpdateArticle(
    $where: ArticleWhereUniqueInput!
    $data: ArticleUpdateInput!
  ) {
    updateArticle(where: $where, data: $data) {
      ...ArticleDetailsFragment
    }
  }
  ${ARTICLE_DETAILS_FRAGMENT}
`;

export const useArticleUpsert = () =>
  useMutation<UpsertArticle, UpsertArticleVariables>(UPSERT_ARTICLE, {
    refetchQueries: [GET_ARTICLE_QUERY],
  });

export const useArticleUpdater = () =>
  useMutation<UpdateArticle, UpdateArticleVariables>(UPDATE_ARTICLE, {
    refetchQueries: [GET_ARTICLE_QUERY],
  });

export const useUpdateArticleLockerId = () => {
  const [updateArticle] = useMutation<UpdateArticle, UpdateArticleVariables>(
    UPDATE_ARTICLE,
  );

  return useCallback(
    (id: string, lockerId: string | null, noCacheUpdate?: boolean) =>
      updateArticle({
        variables: {
          where: { id },
          data: {
            articleExtension: {
              upsert: {
                where: { article: { id } },
                create: {
                  lockerId,
                },
                update: {
                  lockerId,
                },
              },
            },
          },
        },
        fetchPolicy: noCacheUpdate ? 'no-cache' : undefined,
      }),
    [updateArticle],
  );
};
