import { useOnMount } from '@prismamedia/one-components';
import { EditorState, genKey } from 'draft-js';
import { ChangeEvent, useMemo, useState } from 'react';
import type { TablePluginData, TableShape, TableShapeCol } from '../types';
import { MIN_TABLE_COL, MIN_TABLE_ROW } from './constants';
import {
  buildShape,
  getColsSelection,
  getColsRowsQuantity,
  getRowsSelection,
  getEditorStateWithNewStructure,
} from './utils';

export enum TableDialogActionsTypes {
  ADD_COL = 'ADD_COL',
  ADD_ROW = 'ADD_ROW',
  DELETE_COL = 'DELETE_COL',
  DELETE_ROW = 'DELETE_ROW',
  INIT = 'INIT',
}

export interface TableDialogState {
  cols: {
    quantity: number;
    selection: TableShapeCol[];
  };
  rows: {
    quantity: number;
    selection: TableShapeCol[];
  };
  shape: TableShape | null;
}

const initialTableDialogState: TableDialogState = {
  cols: {
    quantity: MIN_TABLE_COL,
    selection: [],
  },
  rows: {
    quantity: MIN_TABLE_ROW,
    selection: [],
  },
  shape: null,
};

interface Handlers {
  handleChangeAccentuation: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeTableStructure: (action: TableDialogActionsTypes) => void;
  handleChangeTableData: (data: Partial<TablePluginData<EditorState>>) => void;
}

export const useEditDialogTable = (
  tablePluginData: TablePluginData<EditorState>,
) => {
  const [tableKey, setTableKey] = useState(genKey());
  const [tableDialogState, setTableDialogState] = useState(
    initialTableDialogState,
  );

  // Isolate state used temporarily during the dialog edition until the validation
  const [dialogTableBlockData, setDialogTableBlockData] = useState(
    tablePluginData,
  );

  const handlers = useMemo(
    (): Handlers => ({
      handleChangeAccentuation: (event) => {
        const { checked, name } = event.target;

        setDialogTableBlockData((currDialogTableBlockData) => ({
          ...currDialogTableBlockData,
          accentuation: {
            ...currDialogTableBlockData.accentuation,
            [name]: checked,
          },
        }));

        setTableKey(genKey());
      },

      handleChangeTableData: (partialData) => {
        setDialogTableBlockData((currDialogTableBlockData) => ({
          ...currDialogTableBlockData,
          ...partialData,
        }));
      },

      handleChangeTableStructure: (action) => {
        const newTablePluginState = getEditorStateWithNewStructure(
          dialogTableBlockData,
          tableDialogState,
          action,
        );
        const newShape = buildShape(newTablePluginState.getCurrentContent());
        const newRowsSelection = getRowsSelection(newShape);
        const newColsSelection = getColsSelection(newShape);
        const newQuantity = getColsRowsQuantity(action, tableDialogState);

        setTableDialogState({
          ...tableDialogState,
          cols: {
            quantity:
              action === TableDialogActionsTypes.ADD_COL ||
              action === TableDialogActionsTypes.DELETE_COL
                ? newQuantity
                : tableDialogState.cols.quantity,
            selection: newColsSelection,
          },
          rows: {
            quantity:
              action === TableDialogActionsTypes.ADD_ROW ||
              action === TableDialogActionsTypes.DELETE_ROW
                ? newQuantity
                : tableDialogState.rows.quantity,
            selection: newRowsSelection,
          },
          shape: newShape,
        });

        handlers.handleChangeTableData({
          tableContentState: newTablePluginState,
        });
      },
    }),
    [dialogTableBlockData, tableDialogState],
  );

  useOnMount(() => {
    const newShape = buildShape(
      dialogTableBlockData.tableContentState.getCurrentContent(),
    );

    setTableDialogState((currentTableDialog) => ({
      ...currentTableDialog,
      cols: {
        quantity: newShape[0].length,
        // Default selection corresponding on last column of the table (Right)
        selection: newShape.reduce(
          (acc, curr) => [...acc, curr[curr.length - 1]],
          [],
        ),
      },
      rows: {
        quantity: newShape.length,
        // Default selection corresponding on last row of the table (Bottom)
        selection: newShape[newShape.length - 1],
      },
      shape: newShape,
    }));
  });

  return {
    handlers,
    state: { dialogTableBlockData, tableDialogState, tableKey },
  };
};
