import {
  gql,
  QueryHookOptions,
  useApolloClient,
  useQuery,
} from '@apollo/client';
import type {
  GetPageCategories,
  GetPageCategoriesVariables,
  GetPageCategoryHistory,
  GetPageCategoryHistoryVariables,
} from '../../../../__generated__/queries-web';
import { PAGE_CATEGORY_FRAGMENT } from '../fragments/category.web.graphql';

/// GET CATEGORIES ////////////////////////////////////////////
const GET_PAGE_CATEGORIES = gql`
  query GetPageCategories($first: Int!, $where: CategoryWhereInput!) {
    categories(first: $first, where: $where, orderBy: title_ASC) {
      ...CategoryFragment
      children(first: $first, where: $where, orderBy: title_ASC) {
        ...CategoryFragment
        children(first: $first) {
          ...CategoryFragment
          children(first: $first) {
            ...CategoryFragment
            children(first: $first) {
              ...CategoryFragment
            }
          }
        }
      }
    }
  }
  ${PAGE_CATEGORY_FRAGMENT}
`;

export const useSearchPageCategories = () => {
  const client = useApolloClient();

  return (variables: GetPageCategoriesVariables) =>
    client.query<GetPageCategories, GetPageCategoriesVariables>({
      query: GET_PAGE_CATEGORIES,
      variables,
    });
};

export const usePageCategories = <
  T extends GetPageCategories,
  K extends GetPageCategoriesVariables
>(
  options: QueryHookOptions<T, K>,
) => useQuery<T, K>(GET_PAGE_CATEGORIES, options);

/// CATEGORY HISTORY ////////////////////////////////////////////
const GET_PAGE_CATEGORY_HISTORY = gql`
  query GetPageCategoryHistory(
    $where: CategoryHistoryWhereInput
    $orderBy: [CategoryHistoryOrderByInput!]
  ) {
    categoryHistories(where: $where, orderBy: $orderBy, first: 999) {
      category {
        ...CategoryFragment
      }
      createdAt
      id
      status
      updatedAt
      user {
        avatarUrl
        email
        id
        name
      }
    }
  }
  ${PAGE_CATEGORY_FRAGMENT}
`;

export const usePageCategoryHistory = () => {
  const client = useApolloClient();

  return (variables: GetPageCategoryHistoryVariables) =>
    client.query<GetPageCategoryHistory, GetPageCategoryHistoryVariables>({
      query: GET_PAGE_CATEGORY_HISTORY,
      variables,
    });
};
