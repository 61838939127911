import { gql, useQuery } from '@apollo/client';
import { useFetch } from '../../../utils/useFetch';
import {
  GetQualifiers,
  GetQualifiersVariables,
} from '../../../__generated__/queries-web';

export const GET_QUALIFIERS = gql`
  query GetQualifiers(
    $first: Int!
    $where: QualifierWhereInput
    $orderBy: [QualifierOrderByInput!]
  ) {
    qualifiers(first: $first, where: $where, orderBy: $orderBy) {
      id
      title
      status
      brandKey
      format
    }
  }
`;

export const useQualifiers = (
  variables: GetQualifiersVariables,
  skip?: boolean,
) => {
  return useQuery<GetQualifiers, GetQualifiersVariables>(GET_QUALIFIERS, {
    variables,
    skip,
  });
};

export const useQualifiersGetter = () =>
  useFetch<GetQualifiers, GetQualifiersVariables>(GET_QUALIFIERS);
