import { TableBlock } from './TableBlock';
import { TableButton } from './TableButton';
import { TABLE_PLUGIN_DATA_TYPE } from './constants';

export const TablePlugin = {
  type: TABLE_PLUGIN_DATA_TYPE,
  buttonComponent: TableButton,
  blockComponent: TableBlock,
  options: {
    displayOptions: [],
    defaultDisplay: null,
  },
};
