import React, { FC } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useStyles } from './styles';

interface AdvicePopperProps {
  accordions: { details: string; summary: string }[];
  title: string;
}
export const AdvicePopper: FC<AdvicePopperProps> = ({ accordions, title }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.wrapperTitle}>
        <Typography>{title}</Typography>
      </div>

      <div className={classes.wrapperAccordions}>
        {accordions.map((accordion, i) => (
          <Accordion
            classes={{ root: classes.accordion }}
            key={accordion.summary}
          >
            <AccordionSummary
              classes={{ root: classes.accordionSummary }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Badge badgeContent={i + 1} classes={{ badge: classes.badge }} />
              <Typography variant="subtitle2">{accordion.summary}</Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionSummary }}>
              <Typography variant="body2">{accordion.details}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};
