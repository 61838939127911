import { isUndefined } from 'lodash';
import type {
  GetArticle_article_articleTags,
  GetArticle_article_articleTags_tag,
} from '../../../../__generated__/queries-web';

export type GetArticleTag = Omit<
  GetArticle_article_articleTags_tag,
  '__typename'
> & {
  isActive?: boolean;
  order: number;
};
export const getArticleTag = ({
  id,
  isActive,
  order,
  relationId,
  title,
  type,
}: GetArticleTag): GetArticle_article_articleTags => ({
  __typename: 'ArticleTag',
  order,
  tag: {
    __typename: 'Tag',
    ...(!isUndefined(isActive) && { isActive }),
    id,
    relationId,
    title,
    type,
    ...(!isUndefined(isActive) && { isActive }),
  },
});
