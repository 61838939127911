import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  wrapper: {
    width: '100%',
  },
  title: {
    paddingBottom: theme.spacing(2),
  },
  tabs: {
    margin: theme.spacing(2, -3, 0),
  },
  tabContent: {
    paddingTop: theme.spacing(1),
    width: '100%',
  },
  draftWrapper: {
    border: '1px solid rgba(0, 0, 0, 0.54)',
    padding: 5,
  },
  logo: {
    width: '50%',
    padding: theme.spacing(2),
  },
  selectedImagesTitle: {
    marginBottom: theme.spacing(2),
  },
}));
