import { gql } from '@apollo/client';
import { client } from '..';
import {
  ArticleFormat,
  ArticleSearchFieldsType,
  SuggestArticles,
  SuggestArticlesVariables,
} from '../../__generated__/queries-web';
import { ARTICLE_FRAGMENT } from '../fragments/article.web.graphql';
import { useGetArticle } from './articles.web.graphql';
import { articlesWithSlideShowCount } from '../../components/Draft/plugins/shared/articlesWithSlideshowCount';

const SUGGEST_ARTICLE_QUERY = gql`
  query SuggestArticles($search: SimilarArticleArgsInputType!) {
    suggestArticles(search: $search) {
      items {
        ...ArticleFragment
      }
    }
  }
  ${ARTICLE_FRAGMENT}
`;

export const useSuggestArticleQuery = (
  data: NonNullable<ReturnType<typeof useGetArticle>>['data'] | undefined,
) => ({
  suggestQuery:
    data &&
    (async (format: ArticleFormat) => {
      const isSlideShowOrVideo = [
        ArticleFormat.Slideshow,
        ArticleFormat.Video,
      ].includes(format);

      const suggestQueryItems =
        (
          await client.query<SuggestArticles, SuggestArticlesVariables>({
            fetchPolicy: 'no-cache',
            query: SUGGEST_ARTICLE_QUERY,
            variables: {
              search: {
                first: 2,
                article: {
                  title: data.article?.title,
                  body: isSlideShowOrVideo ? undefined : data.article?.body,
                  brandKey: data.article?.brandKey,
                  lead: isSlideShowOrVideo ? undefined : data.article?.lead,
                  medias: isSlideShowOrVideo ? undefined : data.article?.medias,
                  format,
                  id: data.article?.id,
                },
                fields: isSlideShowOrVideo
                  ? [ArticleSearchFieldsType.Title]
                  : undefined,
              },
            },
          })
        ).data.suggestArticles?.items || [];

      if (suggestQueryItems.length) {
        if (format === ArticleFormat.Slideshow) {
          return articlesWithSlideShowCount(suggestQueryItems);
        }
      }
      return suggestQueryItems;
    }),
});
