import { gql, useApolloClient } from '@apollo/client';
import type {
  GetPeoples,
  GetPeoplesVariables,
} from '../../../../__generated__/queries-people';
import { DEFAULT_VOID_APOLLO_RESULT } from '../../../../apollo/constants';
import { PEOPLE_FRAGMENT } from '../fragments/people.people.graphql';

/// GET PEOPLES ////////////////////////////////////////////
export const GET_PEOPLES = gql`
  query GetPeoples(
    $where: PersonFilterInput
    $orderBy: [PersonOrderingInput!] = fullName_ASC
    $first: UnsignedInt = 50
    $skip: UnsignedInt
  ) {
    people(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      ...PeopleFragment
    }
  }
  ${PEOPLE_FRAGMENT}
`;

export const usePeoplesGetter = () => {
  const client = useApolloClient();

  return (variables: GetPeoplesVariables, skip?: boolean) =>
    skip
      ? Promise.resolve(DEFAULT_VOID_APOLLO_RESULT)
      : client.query<GetPeoples, GetPeoplesVariables>({
          query: GET_PEOPLES,
          variables,
        });
};
