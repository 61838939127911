import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

interface GeneralConfigurationStyledProps {
  isSelected: boolean;
}
export const useStyles = makeStyles<Theme, GeneralConfigurationStyledProps>(
  (theme) => ({
    header: {
      alignItems: 'center',
      display: 'flex',
      padding: theme.spacing(2.5, 3, 2, 3),

      '& > svg': {
        marginRight: theme.spacing(1),
      },
    },
    innerWrapper: {
      padding: theme.spacing(0, 8.5, 4, 8.5),
    },
    wrapper: ({ isSelected }) => ({
      backgroundColor: theme.palette.common.white,
      borderRadius: 2,
      boxShadow: `inset 0 0 0 1px ${
        isSelected ? theme.palette.primary.main : theme.palette.grey[200]
      }`,
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(2),
      scrollMarginTop: theme.spacing(6),
    }),
    wrapperTags: {
      marginBottom: theme.spacing(2),
    },
  }),
);
