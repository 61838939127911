import { gql } from '@apollo/client';

export const PHOTO_FRAGMENT = gql`
  fragment Photo on Photo {
    id
    archive {
      id
    }
    createdAt
    isUploaded
    isLegacy
    originalName
    downloadUrl
    photoMetadatas(first: 1000) {
      id
      value
      metadata {
        id
        name
      }
    }
    previews {
      size
      href
    }
  }
`;
