import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material';

const draggingAnimationFn = () => ({
  from: {
    boxShadow: '0 0 0 0 rgba(63, 63, 68, 0.05)',
  },
  to: {
    boxShadow: '0px 20px 20px -5px rgba(34, 33, 81, 0.2)',
  },
});

interface SortableItemStyledProps {
  isDragging?: boolean;
}

export const useStyles = makeStyles<Theme, SortableItemStyledProps>(() => ({
  '@keyframes draggingAnimation': {
    ...draggingAnimationFn(),
  },
  wrapperOverlay: {
    animation: `$draggingAnimation 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22) both`,
    cursor: 'pointer',
  },
  wrapperSortable: {
    filter: ({ isDragging }) => `grayscale(${isDragging ? 1 : 0})`,
    opacity: ({ isDragging }) => (isDragging ? 0.5 : 1),

    '& [aria-roledescription]': {
      cursor: 'move',
    },
  },
}));
