import { Description } from '@mui/icons-material';
import React from 'react';

export const DraftInternalLinkIcon = () => {
  return (
    <div title="Lien interne">
      <Description />
    </div>
  );
};
