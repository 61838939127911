/* eslint-disable  */
import { QueryResult } from '@apollo/client';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  AppName,
  countSearchParams,
  InfiniteList,
} from '@prismamedia/one-components';
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  useArticleCountGetter,
  useGetArticles,
} from '../../../apollo/queries/articles.web.graphql';
import {
  useSearchArticleCountGetter,
  useSearchArticles,
} from '../../../apollo/queries/searchArticles.web.graphql';
import { AppBarOne } from '../../../components/AppBar';
import { ArticleItem } from '../../MobilePush/MobilePushForm/ArticleSearch/ArticleItem';
import { SpeedDialBrands } from '../../../components/SpeedDial';
import { getAppName } from '../../../utils/getAppName';
import {
  useGlobalBrandKey,
  useGlobalSearchParams,
  useGlobalStatus,
} from '../../../utils/globalState';
import { pluralizeText } from '../../../utils/pluralizeText';
import { getAllowedStatus, statusToLabel } from '../../../utils/statuses';
import { useQueryParams } from '../../../utils/useQueryParams';
import {
  ArticleFormat,
  ArticleStatus,
  GetArticlesVariables,
  GetArticles_articles,
  SearchArticles,
  SearchArticlesVariables,
  SearchArticles_searchArticles,
} from '../../../__generated__/queries-web';
import { getListPagesVariables } from './getListPageVariables';
import { CustomStyleProps, SlideshowListStyleProps, useStyles } from './styles';
import { SpeedDialSlideshowGeneration } from '../../../components/SpeedDialSlideshowIaGeneration';
import { BrandKey } from '@prismamedia/one-brandkey';
import { SpeedDialGoodDealArticleIA } from '../../../components/SpeedDialGoodDealArticleIA';

export const PAGINATION = 30;

interface ListPageProps {
  path: string;
  articleFormat: ArticleFormat;
}

export const ListPage: FC<ListPageProps> = ({ articleFormat }) => {
  const location = useLocation();
  const [brandKey, setBrandKey] = useGlobalBrandKey();
  const [status, setStatus] = useGlobalStatus();
  const [searchParams, setSearchParams] = useGlobalSearchParams();
  const getArticleCount = useArticleCountGetter();
  const getSearchArticleCount = useSearchArticleCountGetter();
  const [tabs, setTabs] = useState<{ label: string; status: ArticleStatus }[]>(
    [],
  );
  useQueryParams();
  const history = useHistory();

  const variables = getListPagesVariables(
    articleFormat,
    searchParams,
    status,
    brandKey,
  );

  const searchQuery = !!(
    searchParams.search ||
    searchParams.imageSource ||
    searchParams.imageCredit ||
    searchParams.containDiapo ||
    searchParams.containVideo ||
    searchParams.containPodcast
  );

  const { data, fetchMore, loading, error } = searchQuery
    ? useSearchArticles(variables as SearchArticlesVariables, !searchQuery)
    : useGetArticles(variables as GetArticlesVariables, searchQuery);

  const theme = useTheme();
  const isMediumDevice = useMediaQuery(theme.breakpoints.up('lg'));
  const styleProps: CustomStyleProps = {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.grey[100],
    isMediumDevice,
  };
  const classes = useStyles(styleProps) as SlideshowListStyleProps;

  useEffect(() => {
    const loadTabs = async () => {
      const statusLoadingState = await Promise.all(
        getAllowedStatus(brandKey, articleFormat).map(async (tabStatus) => {
          const countVariables = {
            where: { ...variables.where, status: tabStatus },
          };

          const {
            data: getCountData,
            loading: getCountLoading,
          } = await getArticleCount(
            countVariables,
            !countSearchParams(searchParams) || searchQuery,
          );

          const {
            data: searchCountData,
            loading: searchCountLoading,
          } = await getSearchArticleCount(
            countVariables,
            !countSearchParams(searchParams) || !searchQuery,
          );

          const countLoading = searchQuery
            ? searchCountLoading
            : getCountLoading;
          const count = searchQuery
            ? searchCountData?.searchArticleCount
            : getCountData?.articleCount;

          return {
            tabStatus,
            count: countLoading ? undefined : count,
          };
        }),
      );

      setTabs(
        statusLoadingState.map(({ tabStatus, count }) => ({
          label: `${pluralizeText(
            tabStatus === ArticleStatus.Deleted ||
              tabStatus === ArticleStatus.Pending
              ? 1
              : 2,
            statusToLabel(articleFormat)[tabStatus],
          )}${count !== undefined ? ` (${count})` : ''}`,
          status: tabStatus,
        })),
      );
    };

    loadTabs();
    // eslint-disable-next-line
  }, [brandKey, articleFormat, searchParams, searchQuery]);

  const currentApp: AppName = getAppName(location.pathname);

  return (
    <>
      <AppBarOne
        app={currentApp}
        articleFormat={articleFormat}
        brandKey={brandKey}
        searchParams={searchParams}
        setBrandKey={setBrandKey}
        setSearchParams={setSearchParams}
        setStatus={setStatus}
        status={status}
        tabs={tabs}
      />
      <div className={classes.content}>
        <InfiniteList
          className={classes.infiniteList}
          fetchMore={({ startIndex }) =>
            (fetchMore as QueryResult<
              SearchArticles,
              SearchArticlesVariables
            >['fetchMore'])({
              variables: {
                skip: startIndex,
              },
            })
          }
          error={error}
          list={data?.articles?.filter((article) => article.status === status)}
          loading={loading}
          rowHeight={160}
          rowRenderer={(
            article: SearchArticles_searchArticles | GetArticles_articles,
          ) => <ArticleItem {...article} brand={brandKey} />}
          threshold={PAGINATION / 2}
        />
      </div>
      {AppName.Diapo === currentApp &&
      brandKey && [BrandKey.CAC, BrandKey.FAC] ? (
        <SpeedDialSlideshowGeneration brand={brandKey} />
      ) : null}
      {/* Temporary hide */}
      {false && AppName.Web === currentApp && brandKey === BrandKey.CAP ? (
        <SpeedDialGoodDealArticleIA />
      ) : null}
      <SpeedDialBrands brand={brandKey} format={articleFormat} />
    </>
  );
};
