import { Backdrop } from '@mui/material';
import { PageWithDrawer } from '@prismamedia/one-components';
import React, { FC, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { UpsertLive } from '../../__generated__/queries-web';
import { useLiveUpsert } from './graphql/upsertLive.web.graphql';
import { LiveHeader } from './LiveHeader';
import { LivePostEditor } from './LivePostEditor';
import { LivePostItem } from './LivePostItem';
import { actions, initialState, reducer } from './reducer';
import { useStyles } from './styles';

export const LiveEdit: FC = () => {
  const params: any = useParams();
  const classes = useStyles();

  const [state, dispatch] = useReducer(reducer, initialState);
  const { id: articleId } = params;

  const onCompleted = async (data: UpsertLive | undefined) => {
    if (data && data.upsertLive) {
      dispatch(actions.loadData({ ...data.upsertLive }));
    }
  };
  const [getLive, { loading, error }] = useLiveUpsert({
    articleId,
    onCompleted,
  });

  useEffect(() => {
    getLive();
  }, [getLive]);

  const sharedProps = {
    articleId,
    dispatch,
  };
  const isLiveOpen = !state.live?.endedAt;
  return (
    <>
      <LiveHeader live={state.live} dispatch={dispatch} />
      <PageWithDrawer loading={loading} error={error}>
        <Backdrop className={classes.backdrop} open={!isLiveOpen}></Backdrop>
        <LivePostEditor {...sharedProps} {...state.currentData} />
        {state.posts.map((post) => (
          <LivePostItem {...sharedProps} key={post.id} post={post} />
        ))}
      </PageWithDrawer>
    </>
  );
};
