import { AutocompleteItem } from '@prismamedia/one-components';
import { trim } from 'ramda';
import { ReactNode } from 'react';

const checkSeparator = (
  valueToSelectFrom: string,
  searchedTitle: ReactNode,
  separator: string,
): boolean => {
  if (typeof searchedTitle !== 'string') return false;
  const regex = new RegExp(searchedTitle, 'i');
  let result = Boolean(valueToSelectFrom.match(regex));
  if (result && valueToSelectFrom.includes(separator)) {
    result = valueToSelectFrom
      .split(separator)
      .map(trim)
      .some((part) => part.match(regex));
  }
  return result;
};

export const getCreditInList = (
  list: AutocompleteItem[] = [],
  valueToSelectFrom?: string,
) => {
  const separators = [':', '/', '|'];

  return list.find(
    ({ label }) =>
      valueToSelectFrom &&
      label &&
      separators.some((separator) =>
        checkSeparator(valueToSelectFrom, label, separator),
      ),
  );
};
