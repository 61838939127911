import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { auth } from '../../utils/auth';

export function useLocationChange() {
  const { pathname, search } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);

    if (auth.isAuthenticated()) {
      const userId = auth.user.id;
      ReactGA.set({ userId });
    }

    ReactGA.send({
      hitType: 'pageview',
      page: `${pathname}${search}`,
      title: 'Page',
    });
  }, [pathname, search]);
}
