import { gql } from '@apollo/client';

export const SEGMENT_FRAGMENT = gql`
  fragment SegmentFragment on Segment {
    id
    createdAt
    updatedAt
    title
    active
    device
    brandKey
    ios
    android
    editorialAlert
    status
    pushSegments(first: 10) {
      segment {
        title
        id
      }
    }
  }
`;
