import { BrandMenu } from '@prismamedia/one-components';
import React, { FC, useMemo } from 'react';
import { CategoryFormat } from '../../../../../../../__generated__/queries-web';
import { ALLOWED_FORMATS } from '../../../../../constants';

interface FormatSwitchProps {
  format: CategoryFormat;
  setFormat: (value: CategoryFormat) => void;
}

export const FormatSwitch: FC<FormatSwitchProps> = ({ format, setFormat }) => {
  const itemList = useMemo(
    () =>
      ALLOWED_FORMATS.map((allowedFormat) => ({
        label: allowedFormat.label,
        id: allowedFormat.value,
        selected: allowedFormat.value === format,
      })),
    [format],
  );

  return (
    <BrandMenu
      defaultLabel="Formats"
      items={itemList}
      onItemSelect={({ id }) => setFormat(id as CategoryFormat)}
    />
  );
};
