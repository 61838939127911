import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  wrapper: {
    maxWidth: '24rem',
    width: '100%',
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 12px 10px 24px',
  },
  footer: {
    padding: '8px 16px',
  },
  content: {
    display: 'flex',
  },
  leftContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '60px',
    '& > *': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '12px',
      paddingTop: '12px',
      height: '3rem',
      width: '3rem',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  input: {
    root: {
      marginRight: '1.5rem',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
  inputRoot: {
    marginRight: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  rightContent: {
    display: 'flex',
    flexDirection: 'column',
    '&>*': {
      marginTop: '12px',
    },
  },
  switchLabel: {
    display: 'inline-block',
    width: '10rem',
  },
  upload: {
    display: 'none',
  },
}));
