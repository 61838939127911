import { gql } from '@apollo/client';

export const PUSH_HISTORY = gql`
  query getPush($first: Int!, $where: PushWhereInput) {
    pushes(first: $first, where: $where) {
      id
      title
      body
      targetDevice
      article {
        id
      }
      user {
        id
        name
      }
      scheduledAt
      sentAt
      isCancelled
      pushSegments(first: 100) {
        segment {
          title
        }
      }
    }
  }
`;
