import type { FC } from 'react';
import React from 'react';
import { isPageCreateView, isPageEditView } from '../../utils';
import { PageEditAppBar } from './Edit';
import { PageDefaultAppBar } from './Default';

export const PageAppBar: FC = () => {
  if (isPageEditView() || isPageCreateView()) {
    return <PageEditAppBar />;
  }

  return <PageDefaultAppBar />;
};
