import { gql } from '@apollo/client';

export const UPDATE_PROMPT = gql`
  mutation UpdatePrompt(
    $where: PromptWhereUniqueInput!
    $data: PromptUpdateInput!
  ) {
    updatePrompt(where: $where, data: $data) {
      body
    }
  }
`;
