import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  root: { margin: '5rem 1rem' },
  linkContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > a': { textDecoration: 'none', marginRight: '2rem' },
    '&:not(:last-child)': { borderBottom: '1px solid lightgrey' },
    padding: '1rem 0',
    marginBottom: '1rem',
  },
});
