import { Add, ArrowBack } from '@mui/icons-material';
import { Fab, IconButton, Tooltip, Typography } from '@mui/material';
import {
  HomeAppBar,
  InfiniteTable,
  SearchBar,
  SearchParams,
} from '@prismamedia/one-components';
import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GetUsers_users } from '../../../__generated__/queries-auth';
import { useGetUsers } from '../../../apollo/queries/users.auth.graphql';
import { replaceParams } from '../../../routing/Router';
import { paths } from '../../../routing/Router/paths';
import { auth } from '../../../utils/auth';
import { formatDate } from '../../../utils/dateUtils';
import { useStyles } from './styles';

const PAGINATION = 100;

export const UserListPage: FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const [searchParams, setSearchParams] = useState<SearchParams>({});

  const { data, loading, error, fetchMore } = useGetUsers({
    first: PAGINATION,
    where: {
      OR: [
        {
          name_contains: searchParams.search,
        },
        {
          email_contains: searchParams.search,
        },
      ],
    },
  });

  return (
    <>
      <HomeAppBar
        appDrawerMenu={
          <IconButton
            className={classes.backIcon}
            onClick={() => history.push(paths.CRUD)}
            size="large"
          >
            <ArrowBack />
          </IconButton>
        }
        brandMenu={
          <Typography className={classes.title} variant="h6">
            Utilisateurs
          </Typography>
        }
        searchBar={
          <SearchBar
            placeholder="Rechercher un utilisateur"
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        }
        currentUser={auth.user}
        disconnectUser={auth.logout}
      />
      <div className={classes.listWrapper}>
        <InfiniteTable
          list={data?.users}
          loading={loading}
          error={error}
          threshold={PAGINATION / 2}
          fetchMore={({ startIndex }) =>
            fetchMore({ variables: { skip: startIndex } })
          }
          columns={[
            {
              label: 'Nom',
              key: 'name',
              width: 200,
              flexGrow: 1,
            },
            {
              label: 'Email',
              key: 'email',
              width: 300,
            },
            {
              label: 'Départements rattachés',
              key: 'departments',
              cellDataGetter: ({ rowData }: { rowData: GetUsers_users }) =>
                rowData.departments
                  .map(({ department }) => department.name)
                  .join(', '),
              width: 200,
            },
            {
              label: 'Roles',
              key: 'userRoles',
              cellDataGetter: ({ rowData }: { rowData: GetUsers_users }) =>
                rowData.userRoles
                  .map(
                    ({ brand, role }) =>
                      `${role.slug}${brand ? ` (${brand.key})` : ''}`,
                  )
                  .join(', '),
              width: 340,
            },
            {
              label: 'Date de création',
              key: 'createdAt',
              cellDataGetter: ({ rowData }: { rowData: GetUsers_users }) =>
                formatDate(rowData.createdAt, 'dd/MM/yyyy à HH:mm'),
              width: 180,
            },
          ]}
          onRowClick={(e, category) => {
            e.preventDefault();
            history.push(
              replaceParams(paths.USER_EDIT, {
                id: category.id,
              }),
            );
          }}
        />
      </div>
      <Tooltip title={`Créer un utilisateur`}>
        <Fab
          className={classes.newButton}
          color="primary"
          onClick={() => {
            history.push(paths.USER_NEW);
          }}
        >
          <Add />
        </Fab>
      </Tooltip>
    </>
  );
};
