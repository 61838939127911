import React, { FC } from 'react';
import { useStyles } from './styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

interface DialogConfirmProps {
  isOpen: boolean;
  onDialogConfirm: () => void;
  onClose: () => void;
  message?: string;
  title: string;
  confirmMessage?: string;
}

export const DialogConfirm: FC<DialogConfirmProps> = ({
  onDialogConfirm,
  onClose,
  message,
  title,
  isOpen,
  confirmMessage,
}) => {
  const classes = useStyles();

  return (
    <Dialog fullWidth={false} maxWidth="md" open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonContainer}>
          <Button
            data-testid="cancelBtn"
            className={classes.button}
            onClick={onClose}
          >
            Annuler
          </Button>
          <Button
            data-testid="confirmBtn"
            className={classes.button}
            color="primary"
            onClick={onDialogConfirm}
            variant="contained"
          >
            {confirmMessage}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
