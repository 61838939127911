import { countSearchParams, useOnMount } from '@prismamedia/one-components';
import { useEffect } from 'react';
import { JsonParam, StringParam, useQueryParam } from 'use-query-params';
import { ArticleStatus, BrandKey } from '../__generated__/queries-web';
import {
  useGlobalBrandKey,
  useGlobalSearchParams,
  useGlobalStatus,
} from './globalState';

export const useQueryParams = (brandKeyOnly?: boolean) => {
  const [brandKey, setBrandKey] = useGlobalBrandKey();
  const [status, setStatus] = useGlobalStatus();
  const [searchParams, setSearchParams] = useGlobalSearchParams();

  const [statusUrl, setStatusUrl] = useQueryParam('status', StringParam);
  const [searchUrl, setSearchUrl] = useQueryParam('search', JsonParam);
  const [brandKeyUrl, setBrandKeyUrl] = useQueryParam('brand', StringParam);

  useOnMount(() => {
    if (!brandKeyOnly) {
      statusUrl && setStatus(statusUrl as ArticleStatus);
      searchUrl && setSearchParams(searchUrl);
    }
    brandKeyUrl && setBrandKey(brandKeyUrl as BrandKey);
  });

  useEffect(() => {
    setBrandKeyUrl(brandKey, 'replaceIn');
  }, [brandKey, setBrandKeyUrl]);

  useEffect(() => {
    !brandKeyOnly && setStatusUrl(status, 'replaceIn');
  }, [status, setStatusUrl, brandKeyOnly]);

  useEffect(() => {
    if (!brandKeyOnly) {
      if (countSearchParams(searchParams)) {
        setSearchUrl(searchParams, 'replaceIn');
      } else {
        setSearchUrl(undefined);
      }
    }
  }, [searchParams, setSearchUrl, brandKeyOnly]);
};
