import React, { FC } from 'react';

import { SuggestItemType } from './types';
import { useStyles } from './styles';

interface SuggestItemProps {
  item: SuggestItemType;
  isClickable: boolean;
  onClick: (item: SuggestItemType) => void;
}

export const SuggestItem: FC<SuggestItemProps> = ({
  item,
  onClick,
  isClickable,
}) => {
  const classes = useStyles();
  return (
    <div
      key={item.id}
      data-testid="suggest-item"
      className={classes.suggestion}
      style={{
        paddingLeft: 5 + (item.level ? item.level * 10 : 0),
      }}
      onMouseDown={(event) => {
        if (isClickable) {
          onClick(item);
        } else {
          event.preventDefault();
        }
      }}
    >
      {item.title}
    </div>
  );
};
