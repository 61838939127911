import { genKey } from 'draft-js';

export const TABLE_CONTENT_STATE_DATA_TYPE = 'table-cell';
export const TABLE_PLUGIN_DATA_TYPE = 'table-plugin';

export const DEFAULT_CELL_CONTENT_STATE = {
  blocks: [
    {
      depth: 0,
      entityRanges: [],
      inlineStyleRanges: [],
      key: genKey(),
      text: '',
      type: 'unstyled',
    },
  ],
  entityMap: {},
};

const DEFAULT_TABLE_CONTENT_STATE_RAW = {
  entityMap: {},
  blocks: [
    {
      data: {
        cellContentState: DEFAULT_CELL_CONTENT_STATE,
        position: [0, 0],
      },
      depth: 0,
      entityRanges: [],
      inlineStyleRanges: [],
      key: genKey(),
      text: '',
      type: TABLE_CONTENT_STATE_DATA_TYPE,
    },
  ],
};

export const DEFAULT_TABLE_BLOCK_DATA = {
  accentuation: {
    bottom: false,
    left: false,
    right: false,
    top: true,
  },
  source: '',
  tableContentState: DEFAULT_TABLE_CONTENT_STATE_RAW,
  title: '',
  type: TABLE_PLUGIN_DATA_TYPE,
};
