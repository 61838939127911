import {
  CERISE_INVALID_URL_ERROR_MESSAGE,
  mediaFromIframely,
  NotificationTypeEnum,
  OneMedia,
  useNotification,
} from '@prismamedia/one-components';
import { EditorState, Modifier } from 'draft-js';
import { editorStateToJSON, insertDataBlock } from 'megadraft';
import type { Dispatch } from 'react';
import { useArticleGetter } from '../../../apollo/queries/articles.web.graphql';
import { buildDraftLink } from '../../../components/Draft/plugins/internalLink/InternalLinkButton';
import { embedImage } from '../../../utils/upload';
import { CERISE_ENDPOINT } from '../../../utils/cerise';
import { extractIdFromArticleEdit } from '../../../utils/url';
import type { ArticleEditAction } from '../../ArticleEdit/reducer';
import { actions as reducerActions } from '../../ArticleEdit/reducer';
import { INVALID_URL_ERROR_MESSAGE } from './constants';

export const useGetHandlePastedFn = ({
  dispatch,
  field,
}: {
  dispatch: Dispatch<ArticleEditAction>;
  field: string;
}) => {
  const getArticle = useArticleGetter();
  const { pushNotification } = useNotification();
  return (
    _text: string | null,
    _html: string,
    editorState: EditorState,
  ): boolean => {
    const text = _text || _html || '';
    const handleChangeField = (state: EditorState): void => {
      dispatch(
        reducerActions.updateField({
          field,
          value: JSON.parse(editorStateToJSON(state)),
        }),
      );
    };
    const handleErrorArticleLink = (
      state: EditorState,
      errorMessage: string = INVALID_URL_ERROR_MESSAGE,
    ): void => {
      handleChangeField(
        EditorState.acceptSelection(
          state,
          Modifier.removeRange(
            state.getCurrentContent(),
            state.getSelection(),
            'backward',
          ).getSelectionAfter(),
        ),
      );
      pushNotification({
        type: NotificationTypeEnum.error,
        message: errorMessage,
      });
    };

    let preventDefaultPastBehavior = false;
    const URLPattern = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/;
    const isValidURL = text.match(URLPattern);
    const isInternalEditionURL = isValidURL && text.includes('/edit');
    const isCeriseURL = isValidURL && text.includes(CERISE_ENDPOINT);

    if (isCeriseURL) {
      handleErrorArticleLink(editorState, CERISE_INVALID_URL_ERROR_MESSAGE);
      preventDefaultPastBehavior = true;
    } else if (isInternalEditionURL) {
      const articleId = extractIdFromArticleEdit(text);

      if (!articleId) {
        handleErrorArticleLink(editorState);
      } else {
        getArticle(articleId)
          .then(({ data: { article } }) => {
            if (article?.contextualizedUrl) {
              buildDraftLink(
                editorState,
                { ...article, url: article.contextualizedUrl },
                handleChangeField,
              );
              preventDefaultPastBehavior = true;
            }
          })
          .finally(() => {
            !preventDefaultPastBehavior && handleErrorArticleLink(editorState);
          });
      }
    } else if (isValidURL) {
      embedImage(text).then((iframely) => {
        const media = mediaFromIframely(iframely);
        const isImage = Boolean(iframely.html);

        if (isImage) {
          const data: Record<'type', string> & OneMedia = {
            type: 'media',
            ...media,
          };
          const newState = insertDataBlock(editorState, data);
          handleChangeField(newState);
        }
      });
    }

    return preventDefaultPastBehavior;
  };
};
