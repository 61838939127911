import {
  getImageFromMedias,
  isDefined,
  SearchParams,
} from '@prismamedia/one-components';
import { formatISO, set } from 'date-fns';
import { generatePath } from 'react-router-dom';
import {
  RecipeStatus,
  RecipeWhereInput,
} from '../../__generated__/queries-recipe';
import {
  ArticleFormat,
  ArticleWhereInput,
  BrandKey,
} from '../../__generated__/queries-web';
import { paths } from '../../routing/Router/paths';
import { getImageFromArticleBody } from '../../utils/getImageFromArticleBody';
import { Filters, Format } from './FiltersDrawer/utils';

export const PAID_ARTICLE = 'Article payant';

const formatToArticleFormatMap: Record<Format, ArticleFormat | undefined> = {
  [Format.Article]: ArticleFormat.Rich,
  [Format.Slideshow]: ArticleFormat.Slideshow,
  [Format.Recipe]: undefined,
};

export const articleFormatToFormatMap: Record<
  ArticleFormat,
  Format | undefined
> = {
  [ArticleFormat.Rich]: Format.Article,
  [ArticleFormat.Slideshow]: Format.Slideshow,
  [ArticleFormat.Video]: undefined,
};

export const ignoreArticleQuery = (filters: Filters) =>
  !filters.format?.includes(Format.Article) &&
  !filters.format?.includes(Format.Slideshow);

export const ignoreRecipeQuery = (
  filters: Filters,
  brandKey: BrandKey | undefined,
) =>
  !filters.format?.includes(Format.Recipe) ||
  !!filters.sourceIds?.length ||
  !!filters.qualifierIds?.length ||
  (brandKey && ![BrandKey.CAC, BrandKey.FAC].includes(brandKey));

export const getWhere = (
  filters: Filters,
  searchParams: SearchParams,
  brandKey: BrandKey | undefined,
) => {
  const startDate =
    filters.startDate &&
    set(new Date(filters.startDate), { hours: 0, minutes: 0, seconds: 0 });

  const endDate =
    filters.endDate &&
    set(new Date(filters.endDate), {
      hours: 23,
      minutes: 59,
      seconds: 59,
    });

  const articleWhere: ArticleWhereInput = {
    publishedAt_gte: startDate && formatISO(startDate),
    publishedAt_lte: endDate && formatISO(endDate),
    format_in: filters.format
      ?.map((format) => formatToArticleFormatMap[format])
      .filter(isDefined),
    brandKey,
    status_in: filters.status,
    articleAuthors_some: { author: { id_in: filters.authorIds } },
    articleTags_some: { tag: { id_in: filters.tagIds } },
    source: { id_in: filters.sourceIds },
    articleCategories_some: { category: { id_in: filters.categoryIds } },
    articleQualifiers_some: { qualifier: { id_in: filters.qualifierIds } },
    OR: [
      { id: searchParams.search },
      { title_contains: searchParams.search },
      { articleUrls_some: { url: { path: searchParams.search } } },
    ],
  };

  const recipeWhere: RecipeWhereInput = {
    publishedAt_gte: startDate && formatISO(startDate),
    publishedAt_lte: endDate && formatISO(endDate),
    brandKey,
    status_in: (filters.status as unknown) as RecipeStatus[],
    author_in: filters.authorIds,
    recipeTags_some: { tag: { id_in: filters.tagIds } },
    recipeCategories_some: { category: { id_in: filters.categoryIds } },
    OR: [
      { id: searchParams.search },
      { title_contains: searchParams.search },
      { recipeUrls_some: { url: { path: searchParams.search } } },
    ],
  };

  return { articleWhere, recipeWhere };
};

export const getItemPath = (
  format: Format | undefined,
  brandKey: string,
  id: string,
) => {
  if (!format) return undefined;

  if (format === Format.Recipe) {
    return `${config.FRONT_ONE_RECIPE}/${brandKey}/edit/${id}`;
  }

  if (format === Format.Slideshow) {
    return generatePath(paths.SLIDESHOW_EDIT, { id });
  }

  return generatePath(paths.ARTICLE_EDIT, {
    brandKey,
    articleType: 'article',
    id,
  });
};

export const getItemImage = (
  format: Format | undefined,
  medias: string | null,
  body: string | null | undefined,
) => {
  switch (format) {
    case Format.Slideshow:
      return getImageFromArticleBody(body || null, true);
    default:
      return getImageFromMedias(medias).mediaForDisplay;
  }
};
