import { gql, useQuery } from '@apollo/client';
import {
  GetSegment,
  GetSegmentVariables,
} from '../../../__generated__/queries-web';

export const GET_SEGMENT = gql`
  query GetSegment($where: SegmentWhereUniqueInput!) {
    segment(where: $where) {
      active
      id
      title
      ios
      android
      brandKey
      status
      editorialAlert
    }
  }
`;

export const useGetSegment = (id?: string, skip?: boolean) =>
  useQuery<GetSegment, GetSegmentVariables>(GET_SEGMENT, {
    variables: { where: { id } },
    skip: !id || skip,
  });
