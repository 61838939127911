import { gql, useQuery } from '@apollo/client';
import {
  GetGoogleGroups,
  GetGoogleGroupsVariables,
} from '../../../__generated__/queries-auth';

export const GET_GOOGLE_GROUPS = gql`
  query GetGoogleGroups(
    $first: Int!
    $skip: Int
    $where: GoogleGroupWhereInput
  ) {
    googleGroups(first: $first, skip: $skip, where: $where) {
      id
      groupTitle
      role {
        id
        slug
      }
    }
  }
`;

export const useGoogleGroups = (
  variables: GetGoogleGroupsVariables,
  skip?: boolean,
) =>
  useQuery<GetGoogleGroups, GetGoogleGroupsVariables>(GET_GOOGLE_GROUPS, {
    variables,
    skip,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
