import React, { FC } from 'react';
import { Stack, Tooltip } from '@mui/material';
import { MediasContentType } from '../../utils/useMediasIcons';
import SlideshowIcon from '@mui/icons-material/BurstMode';
import VideoIcon from '@mui/icons-material/OndemandVideo';
import LiveIcon from '@mui/icons-material/Whatshot';
import PodcastIcon from '@mui/icons-material/Headset';
import { grey } from '@mui/material/colors';

interface ListMediasIconsProps {
  listMedias: MediasContentType;
}

export const ListMediasIcons: FC<ListMediasIconsProps> = ({ listMedias }) => {
  const { hasVideo, hasSlideshow, hasPodcast, hasLive, liveCount } = listMedias;

  return (
    <Stack direction="row" spacing={1}>
      {hasVideo && <VideoIcon sx={{ color: grey[500] }} />}
      {hasSlideshow && <SlideshowIcon sx={{ color: grey[500] }} />}
      {hasPodcast && <PodcastIcon sx={{ color: grey[500] }} />}
      {hasLive && (
        <Tooltip title={liveCount}>
          <LiveIcon sx={{ color: grey[500], fontSize: 23 }} />
        </Tooltip>
      )}
    </Stack>
  );
};
