import { useQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import { AppName, HomeAppBar } from '@prismamedia/one-components';
import { parse } from 'query-string';
import { useEffect, useState } from 'react';
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import {
  BrandKey,
  CardOrderByInput,
  CardStatus,
  getCards,
  getCardsVariables,
} from '../../../__generated__/queries-web';
import { OneDrawerMenu } from '../../../components/AppBar';
import { BrandSwitch } from '../../../components/BrandSwitch';
import { Loader } from '../../../components/Loader';
import { paths } from '../../../routing/Router/paths';
import { auth } from '../../../utils/auth';
import { GET_CARDS } from '../Queries/cards.web.graphql';
import { CardSpeedDial } from '../components/CardSpeedDial';
import { MediaCard } from '../components/MediaCard';
import {
  CARD_LIST_TABS,
  ENCARDS_LOADING_TEXT,
  ENCARDS_NO_RESULT_TEXT,
} from './constants';
import { useStyles } from './styles';

export const CardList = () => {
  const history = useHistory();
  const location = useLocation();
  const { status: urlStatus }: { status?: CardStatus } = parse(location.search);
  const { brandKey: brand } = useParams<{ brandKey: BrandKey }>();
  const classes = useStyles();
  const [brandKey, setBrandKey] = useState<BrandKey | undefined>(brand);
  const [status, setStatus] = useState<CardStatus>(
    urlStatus || CardStatus.Published,
  );

  const { data, loading, refetch } = useQuery<getCards, getCardsVariables>(
    GET_CARDS,
    {
      variables: {
        first: 10000,
        where: {
          brandKey: brand,
          status,
        },
        orderBy: [CardOrderByInput.createdAt_DESC],
      },
    },
  );

  useEffect(() => {
    brandKey &&
      history.push({
        pathname: generatePath(paths.CARD_LIST, {
          brandKey,
        }),
        search: `status=${status}`,
      });
  }, [brandKey, history, status]);

  useEffect(() => {
    refetch();
  }, [status, refetch]);

  return (
    <div className={classes.root}>
      <HomeAppBar
        disconnectUser={auth.logout}
        currentUser={auth.user}
        appDrawerMenu={
          <OneDrawerMenu app={AppName.Card} currentBrand={brandKey} />
        }
        brandMenu={
          <BrandSwitch brandKey={brandKey} setBrandKey={setBrandKey} />
        }
        tabs={CARD_LIST_TABS?.map(({ status: tabStatus, label }) => ({
          id: tabStatus,
          label,
          selected: status === tabStatus,
        }))}
        onTabSelect={(tab) => {
          setStatus?.((tab?.id as unknown) as CardStatus);
        }}
      />

      {loading ? (
        <div className={classes.innerWrapper}>
          <Loader message={ENCARDS_LOADING_TEXT} />
        </div>
      ) : !data?.cards?.length ? (
        <div className={classes.innerWrapper}>
          <Typography variant="h4" color="primary">
            {ENCARDS_NO_RESULT_TEXT}
          </Typography>
        </div>
      ) : (
        <div className={classes.cards}>
          {data?.cards.map((card, idx) => (
            <MediaCard key={idx} card={card} />
          ))}
        </div>
      )}

      <CardSpeedDial className={classes.speedDial} />
    </div>
  );
};
