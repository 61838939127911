import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
export const useStyles = makeStyles(
  createStyles({
    container: {
      marginTop: '0.5rem',
    },
    card: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginTop: '0.5rem',
    },
    media: {
      height: 150,
      minWidth: 150,
    },
  }),
);
