import React, { FC } from 'react';
import { paths } from '../../routing/Router/paths';
import { ArticleFormat } from '../../__generated__/queries-web';
import { ListPage } from '../shared/ListPage';

export const ArticleList: FC = () => {
  return (
    <ListPage path={paths.ARTICLE_LIST} articleFormat={ArticleFormat.Rich} />
  );
};

export const ArticleVideoList: FC = () => {
  return (
    <ListPage path={paths.ARTICLE_VIDEO} articleFormat={ArticleFormat.Video} />
  );
};
