import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  card: {
    width: 220,

    '& > h6': {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: '18px',
      margin: theme.spacing(2, 2),
    },
  },
  filterButton: {
    position: 'relative',
    top: '-3px',
    width: '65px',
  },
  formControl: {
    display: 'flex',
    height: 35,
  },
  popper: {
    zIndex: theme.zIndex.modal,
  },
  resetButton: {
    margin: theme.spacing(3, 2, 2),
    width: `calc(100% - ${theme.spacing(4)})`,
  },
  section: {
    margin: theme.spacing(1, 2),
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    minWidth: '50px',
  },
}));
