import { gql, useMutation } from '@apollo/client';
import uuid from 'uuid';
import {
  UpsertLivePost,
  UpsertLivePostVariables,
} from '../../../__generated__/queries-web';
import { CurrentFieldData } from '../reducer';
import { LIVE_POST_FRAGMENT } from './livePostFragment.web.graphql';

const UPSERT_LIVE_POST = gql`
  mutation UpsertLivePost(
    $create: LivePostCreateInput!
    $where: LivePostWhereUniqueInput!
    $update: LivePostUpdateInput!
  ) {
    upsertLivePost(create: $create, where: $where, update: $update) {
      ...LivePostFragment
    }
  }
  ${LIVE_POST_FRAGMENT}
`;

interface LivePostUpsertProps {
  data: CurrentFieldData;
  articleId: string;
  id?: string;
}
export const useLivePostUpsert = ({
  id,
  articleId,
  data,
}: LivePostUpsertProps) => {
  const livePostId = id || uuid();

  return useMutation<UpsertLivePost, UpsertLivePostVariables>(
    UPSERT_LIVE_POST,
    {
      variables: {
        create: {
          live: {
            connect: {
              article: {
                id: articleId,
              },
            },
          },
          ...data,
        },
        where: {
          id: livePostId,
        },
        update: {
          ...data,
        },
      },
    },
  );
};
