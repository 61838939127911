import {
  HistoryDialog,
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import type { FC } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { useMountedState } from 'react-use';
import { CategoryHistoryOrderByInput } from '../../../../../../../__generated__/queries-web';
import { usePageCategoryHistory } from '../../../../../apollo/queries/categories.web.graphql';
import { Category } from '../../../../../types';
import { MODIFICATION_QUANTITY_LABEL, PAGE_HISTORY_TITLE } from './constants';
import { getHistoryInfos } from './utils';

interface PageHistoryProps {
  activeCategory: Category | null;
  onCloseHistory: () => void;
}

export const PageHistory: FC<PageHistoryProps> = ({
  activeCategory,
  onCloseHistory,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageHistoryList, setPageHistoryList] = useState<any[]>([]);
  const searchActiveCategoryHistory = usePageCategoryHistory();

  const { pushNotification } = useNotification();
  const isMounted = useMountedState();

  const searchActiveCategoryHistoryList = useCallback(
    async (activeCategoryId: string) => {
      if (isMounted()) {
        setIsLoading(true);

        try {
          const {
            data: { categoryHistories },
          } = await searchActiveCategoryHistory({
            where: { category: { id: activeCategoryId } },
            orderBy: [CategoryHistoryOrderByInput.updatedAt_DESC],
          });

          setTimeout(() => {
            if (isMounted()) {
              setIsLoading(false);
              setPageHistoryList(categoryHistories);
            }
          }, 1500);
        } catch (e) {
          if (isMounted()) {
            setIsLoading(false);
            onCloseHistory();

            pushNotification({
              type: NotificationTypeEnum.error,
              message: `
              [Unable to fetch the page history]
              ${(e as Error).message}
            `,
            });
          }
        }
      }
    },
    [isMounted, pushNotification, onCloseHistory, searchActiveCategoryHistory],
  );

  useEffect(() => {
    if (activeCategory?.id) {
      searchActiveCategoryHistoryList(activeCategory.id);
    }
    // eslint-disable-next-line
  }, [activeCategory?.id]);

  return (
    <HistoryDialog
      histories={pageHistoryList}
      historyCount={activeCategory?.categoryHistoryCount}
      loading={isLoading}
      title={PAGE_HISTORY_TITLE}
      historyLabel={MODIFICATION_QUANTITY_LABEL}
      historyInfosFn={getHistoryInfos}
    />
  );
};
