import {
  amber,
  blue,
  blueGrey,
  cyan,
  green,
  indigo,
  orange,
  purple,
  red,
  teal,
} from '@mui/material/colors';
import type {
  FieldValidator,
  FieldValidatorValidation,
  OneMedia,
} from '@prismamedia/one-components';
import {
  doMediasHaveCredits,
  doMediasHaveFormats,
  doMediasHaveSources,
  getIsValidURLFormat,
  isImage,
} from '@prismamedia/one-components';
import type { RawDraftContentBlock } from 'draft-js';
import {
  ArticleFormat,
  ArticleStatus,
  BrandKey,
  CardStatus,
  OrphanPageStatus,
} from '../../__generated__/queries-web';
import { ReactComponent as draftIcon } from '../../assets/svg/draft.svg';
import { ReactComponent as frozenIcon } from '../../assets/svg/frozen.svg';
import { ReactComponent as privateIcon } from '../../assets/svg/private.svg';
import { ReactComponent as publishedIcon } from '../../assets/svg/published.svg';
import { ReactComponent as readyIcon } from '../../assets/svg/ready.svg';
import { ReactComponent as scheduledIcon } from '../../assets/svg/scheduled.svg';
import type { ArticleState } from '../../pages/ArticleEdit/reducer';
import { KeyOfEnum } from '../../utils/typescriptUtils';
import { brandsOptions } from '../brands';
import { isImageMediaWithHref } from '../media';

enum DailymotionVideoTransition {
  UNPUBLISH = 'unpublish',
  PRIVATE = 'private',
  PUBLISH = 'publish',
  MOVE_TO_TRASH = 'move2trash',
}

enum ArticleTransition {
  REPUBLISH = 'republish',
  PUBLISH = 'publish',
  UNPUBLISH = 'unpublish',
  SCHEDULE = 'schedule',
  PROOF_READING = 'proofreading',
  FROZE = 'froze',
  BACK_TO_DRAFT = 'back2draft',
  MOVE_TO_TRASH = 'move2trash',
}

enum OrphanPageTransition {
  PUBLISH = 'publish',
  ARCHIVE = 'archive',
  BACK_TO_DRAFT = 'back2draft',
}

export enum AuthorsQuery {
  MY_AUTHORS = 'Mes auteurs',
  ALL_AUTHORS = 'Tous les auteurs',
}
export interface Transition {
  from: (ArticleStatus | OrphanPageStatus | CardStatus)[];
  to: (ArticleStatus | OrphanPageStatus | CardStatus)[];
  label: string;
  patternFields?: FieldValidator<ArticleState, FieldValidatorValidation>[];
  successMessage: string;
  mandatoryFields?: FieldValidator<ArticleState>[];
}

export const orphanPageStatues: KeyOfEnum<OrphanPageStatus> = {
  [OrphanPageStatus.Archived]: {
    label: 'Archivé',
  },
  [OrphanPageStatus.Draft]: {
    label: 'Brouillon',
  },
  [OrphanPageStatus.Published]: {
    label: 'Publié',
  },
};

export const orphanPageStatusTransitions: Record<
  OrphanPageTransition,
  Transition
> = {
  [OrphanPageTransition.PUBLISH]: {
    from: [OrphanPageStatus.Draft, OrphanPageStatus.Archived],
    to: [OrphanPageStatus.Published],
    label: 'Publier',
    successMessage: 'Votre page a bien été publiée',
  },
  [OrphanPageTransition.ARCHIVE]: {
    from: [OrphanPageStatus.Draft, OrphanPageStatus.Published],
    to: [OrphanPageStatus.Archived],
    label: 'Archiver',
    successMessage: 'Votre page a bien été archivée',
  },
  [OrphanPageTransition.BACK_TO_DRAFT]: {
    from: [OrphanPageStatus.Published, OrphanPageStatus.Archived],
    to: [OrphanPageStatus.Draft],
    label: 'Brouillon',
    successMessage: 'Votre page a bien été sauvegardée',
  },
};

export const statuses = (format?: ArticleFormat): KeyOfEnum<ArticleStatus> => {
  const isVideo = format === ArticleFormat.Video;
  return {
    [ArticleStatus.Published]: {
      icon: publishedIcon,
      label: isVideo ? 'Publique' : 'Publié',
      color: green[600],
    },
    [ArticleStatus.Scheduled]: {
      label: 'Programmé',
      icon: scheduledIcon,
      color: orange[600],
    },
    [ArticleStatus.Ready]: {
      label: 'Prêt',
      icon: readyIcon,
      color: purple[600],
    },
    [ArticleStatus.Frozen]: {
      label: 'Gelé',
      icon: frozenIcon,
      color: blueGrey[600],
    },
    [ArticleStatus.Draft]: {
      label: 'Brouillon',
      icon: draftIcon,
      color: blue[600],
    },
    [ArticleStatus.Deleted]: {
      label: 'Corbeille',
      icon: draftIcon,
      color: red[600],
    },
    [ArticleStatus.Private]: {
      label: 'Privé',
      icon: privateIcon,
      color: teal[600],
    },
    [ArticleStatus.Pending]: {
      label: 'En traitement',
      icon: privateIcon,
      color: amber[600],
    },
    [ArticleStatus.Proposal]: {
      label: 'Proposition',
      icon: privateIcon,
      color: cyan[600],
    },
    [ArticleStatus.Todo]: {
      label: 'À faire',
      icon: privateIcon,
      color: indigo[600],
    },
  };
};
const isOneMedia = (
  contentBlockData: RawDraftContentBlock['data'],
): contentBlockData is OneMedia => contentBlockData?.iframely;

const pickOnlyOneMediaImages = (blocks: RawDraftContentBlock[]) => {
  return blocks.reduce<OneMedia[]>((acc, block) => {
    if (isOneMedia(block.data) && isImage(block.data)) {
      return [...acc, block.data];
    }
    return acc;
  }, []);
};

const publishMandatoryFields: FieldValidator<ArticleState>[] = [
  {
    label: 'Auteur',
    validate: (state) => {
      if (
        brandsOptions[state.brandKey].isCerise &&
        state.format !== ArticleFormat.Video
      ) {
        return !!state.articleAuthors.length;
      }
      return true;
    },
  },
  {
    label: 'Catégorie',
    validate: (state) =>
      state.format === ArticleFormat.Video || !!state.articleCategories.length,
  },
  {
    label: "Crédit de l'image",
    validate: (state) => {
      if (!state.medias?.length || state.format === ArticleFormat.Video) {
        return true;
      }

      const isMainImageHasCredit = doMediasHaveCredits(
        state.medias.filter(isImage),
      );

      if (!state.body?.blocks) {
        return isMainImageHasCredit;
      }

      const isBodyImagesHasCredits = doMediasHaveCredits(
        pickOnlyOneMediaImages(state.body.blocks),
      );

      return isMainImageHasCredit && isBodyImagesHasCredits;
    },
  },
  {
    label: 'Image',
    validate: (state) => !!state.medias?.length,
  },
  {
    label: "Format de l'image",
    validate: (state) => {
      const { isCerise } = brandsOptions[state.brandKey];

      if (!state.medias?.length || !isCerise) {
        return true;
      }

      const isMainImageHasFormat = doMediasHaveFormats(
        state.medias.filter(isImage),
      );

      return isMainImageHasFormat;
    },
  },
  {
    label: "Source de l'image",
    validate: (state) => {
      if (!state.medias?.length || state.format === ArticleFormat.Video) {
        return true;
      }

      const isMainImageHasSource = doMediasHaveSources(
        state.medias.filter(isImage),
      );

      if (!state.body?.blocks) {
        return isMainImageHasSource;
      }

      const isBodyImagesHasSources = doMediasHaveSources(
        pickOnlyOneMediaImages(state.body.blocks),
      );

      return isMainImageHasSource && isBodyImagesHasSources;
    },
  },
  {
    label: 'Tag',
    validate: (state) =>
      state.format === ArticleFormat.Video || !!state.articleTags.length,
  },
  {
    label: 'Titre',
    validate: (state) => !!state.title,
  },
  {
    label: 'Source',
    validate: (state) => {
      if (
        state.brandKey === BrandKey.CAP &&
        state.format !== ArticleFormat.Video
      ) {
        return !!state.source;
      }
      return true;
    },
  },
];

const patternFields: FieldValidator<
  ArticleState,
  FieldValidatorValidation
>[] = [
  {
    label: "Lien de l'image",
    validate: (articleState) => {
      if (!articleState.medias?.length) {
        return {
          count: 0,
          isValid: true,
        };
      }

      const mediaLinkFieldsWithPatternError =
        articleState.body?.blocks
          ?.filter(isImageMediaWithHref)
          .filter(
            ({
              data: { href },
            }: Partial<RawDraftContentBlock & Record<'data', any>>) =>
              !getIsValidURLFormat(href),
          ) || [];

      const mediaLinkFieldsWithPatternErrorLength =
        mediaLinkFieldsWithPatternError.length;

      return {
        count: mediaLinkFieldsWithPatternErrorLength,
        isValid: !mediaLinkFieldsWithPatternErrorLength,
      };
    },
  },
];

const scheduleMandatoryFields: FieldValidator<ArticleState>[] = [
  ...publishMandatoryFields,
  {
    label: 'Date de programmation',
    validate: (state) => !!state.publishedAt,
  },
];

const statusDailymotionVideoTransitions = (): Record<
  DailymotionVideoTransition,
  Transition
> => ({
  [DailymotionVideoTransition.UNPUBLISH]: {
    from: [ArticleStatus.Published],
    to: [ArticleStatus.Private],
    label: 'Mettre en privé',
    patternFields,
    successMessage: 'La vidéo a bien été dépubliée',
    mandatoryFields: publishMandatoryFields,
  },
  [DailymotionVideoTransition.PRIVATE]: {
    from: [ArticleStatus.Ready],
    to: [ArticleStatus.Private],
    label: 'Priver',
    patternFields,
    successMessage: 'La vidéo est bien passée en Privé',
    mandatoryFields: publishMandatoryFields,
  },
  [DailymotionVideoTransition.PUBLISH]: {
    from: [ArticleStatus.Private],
    to: [ArticleStatus.Ready],
    label: 'Mettre en prêt pour la publication',
    patternFields,
    successMessage: 'La vidéo est prête pour la publication automatique',
    mandatoryFields: publishMandatoryFields,
  },
  [ArticleTransition.MOVE_TO_TRASH]: {
    from: [ArticleStatus.Private, ArticleStatus.Ready, ArticleStatus.Published],
    to: [ArticleStatus.Deleted],
    label: 'Mettre en corbeille',
    successMessage: 'La vidéo a bien été mise en corbeille',
  },
});

export const statusTransitions = (
  brandKey?: BrandKey,
  format?: ArticleFormat,
): { [key: string]: Transition } =>
  format === ArticleFormat.Video
    ? statusDailymotionVideoTransitions()
    : statusRichTransitions(format);

const statusRichTransitions = (
  format?: ArticleFormat,
): Record<ArticleTransition, Transition> => ({
  [ArticleTransition.REPUBLISH]: {
    from: [ArticleStatus.Published],
    to: [ArticleStatus.Published],
    label: 'Re-publier',
    patternFields,
    successMessage: 'Votre article a bien été re-publié',
    mandatoryFields: publishMandatoryFields,
  },
  [ArticleTransition.PUBLISH]: {
    from: [ArticleStatus.Draft, ArticleStatus.Ready],
    to: [ArticleStatus.Published],
    label: 'Publier',
    patternFields,
    successMessage: 'Votre article a bien été publié',
    mandatoryFields: publishMandatoryFields,
  },
  [ArticleTransition.SCHEDULE]: {
    from: [ArticleStatus.Draft, ArticleStatus.Ready, ArticleStatus.Published],
    to: [ArticleStatus.Scheduled],
    label: 'Programmer',
    patternFields,
    successMessage: 'Votre publication a bien été programmée',
    mandatoryFields: scheduleMandatoryFields,
  },
  [ArticleTransition.PROOF_READING]: {
    from:
      format === ArticleFormat.Video
        ? [ArticleStatus.Draft, ArticleStatus.Frozen, ArticleStatus.Scheduled]
        : [
            ArticleStatus.Draft,
            ArticleStatus.Frozen,
            ArticleStatus.Scheduled,
            ArticleStatus.Deleted,
          ],
    to: [ArticleStatus.Ready],
    label: 'Mettre en prêt pour relecture',
    successMessage: 'Votre article a bien été sauvegardé',
  },
  [ArticleTransition.FROZE]: {
    from:
      format === ArticleFormat.Video
        ? [ArticleStatus.Draft, ArticleStatus.Ready]
        : [ArticleStatus.Draft, ArticleStatus.Ready, ArticleStatus.Deleted],
    to: [ArticleStatus.Frozen],
    label: 'Geler',
    successMessage: 'Votre article a bien été sauvegardé',
  },
  [ArticleTransition.UNPUBLISH]: {
    from: [ArticleStatus.Published],
    to: [ArticleStatus.Draft],
    label: 'Mettre en brouillon',
    successMessage: 'Votre article a bien été sauvegardé',
  },
  [ArticleTransition.BACK_TO_DRAFT]: {
    from: [
      ArticleStatus.Scheduled,
      ArticleStatus.Frozen,
      ArticleStatus.Ready,
      ArticleStatus.Deleted,
    ],
    to: [ArticleStatus.Draft],
    label: 'Mettre en brouillon',
    successMessage: 'Votre article a bien été sauvegardé',
  },
  [ArticleTransition.MOVE_TO_TRASH]: {
    from: [
      ArticleStatus.Frozen,
      ArticleStatus.Draft,
      ArticleStatus.Published,
      ArticleStatus.Scheduled,
      ArticleStatus.Ready,
    ],
    to: [ArticleStatus.Deleted],
    label: 'Mettre en corbeille',
    successMessage: 'Votre article a bien été mis en corbeille',
  },
});

export const statusToLabel = (
  format: ArticleFormat,
): Record<ArticleStatus, string> => {
  const videoStatus = format === ArticleFormat.Video;
  return {
    [ArticleStatus.Published]: videoStatus ? 'Vidéo Publique' : 'Publié',
    [ArticleStatus.Scheduled]: 'Programmé',
    [ArticleStatus.Draft]: 'Brouillon',
    [ArticleStatus.Frozen]: 'Gelé',
    [ArticleStatus.Ready]: videoStatus ? 'Vidéo Prête' : 'Prêt',
    [ArticleStatus.Deleted]: 'Corbeille',
    [ArticleStatus.Pending]: 'En cours',
    [ArticleStatus.Proposal]: 'En proposition',
    [ArticleStatus.Todo]: 'A faire',
    [ArticleStatus.Private]: videoStatus ? 'Vidéo Privée' : 'Privé',
  };
};

const allowedStatus = [
  ArticleStatus.Published,
  ArticleStatus.Scheduled,
  ArticleStatus.Ready,
  ArticleStatus.Frozen,
  ArticleStatus.Draft,
  ArticleStatus.Deleted,
];

const allowedStatusDailymotionVideo = [
  ArticleStatus.Published,
  ArticleStatus.Private,
  ArticleStatus.Ready,
  ArticleStatus.Pending,
  ArticleStatus.Deleted,
];

export const getAllowedStatus = (brandKey?: BrandKey, format?: ArticleFormat) =>
  format === ArticleFormat.Video
    ? allowedStatusDailymotionVideo
    : allowedStatus;

export const authorsTabs = [AuthorsQuery.MY_AUTHORS, AuthorsQuery.ALL_AUTHORS];
