import { Box, InputLabel, SxProps } from '@mui/material';
import React from 'react';
import {
  BrandKey,
  GetArticle_article_source,
} from '../../../__generated__/queries-web';
import { Suggest } from '../../../components/Suggest';
import { SOURCE_LABEL } from '../../ArticleEdit/ArticleEditor/constants';
import { useGetSources } from '../../ArticleEdit/DrawerContent/getSources.web.graphql';
import { SlideshowEditAction, actions } from '../reducer';

interface SourceInputsProps {
  brandKey: BrandKey;
  source: GetArticle_article_source | null;
  dispatch: React.Dispatch<SlideshowEditAction>;
  multiple?: boolean;
  sx?: SxProps;
}

export const SourceInput: React.FC<SourceInputsProps> = ({
  brandKey,
  source,
  dispatch,
  sx,
}) => {
  const { data } = useGetSources(brandKey);

  return (
    <Box sx={sx}>
      <InputLabel shrink>Source</InputLabel>
      <Suggest
        selectedItems={source ? [source] : []}
        placeholder={SOURCE_LABEL}
        suggestions={data?.sources || []}
        multiple={false}
        maxNumberSuggest={2}
        isPrefetched
        onChange={(items) => {
          dispatch(
            actions.setSource({
              source: items[0]
                ? { id: items[0].id, title: items[0].title }
                : null,
            }),
          );
        }}
      />
    </Box>
  );
};
