import { gql } from '@apollo/client';

export const PROMPT_FRAGMENT = gql`
  fragment PromptFragment on Prompt {
    field
    label
    body
    brandKey
  }
`;
