import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  textField: {
    '& > div': {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  paper: {
    position: 'absolute',
    zIndex: 10,
    width: '100%',
    maxHeight: 400,
    overflowY: 'scroll',
    top: '48px',
  },
  suggestion: {
    margin: '5px 0',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
}));
