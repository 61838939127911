import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

interface ErrorIconStyledProps {
  isTouched?: boolean;
}
export const useStyles = makeStyles<Theme, ErrorIconStyledProps>((theme) => ({
  errorIcon: {
    zoom: `${1} !important`,
  },
  listWrapper: {
    margin: 0,
    padding: theme.spacing(0, 0, 0, 1.5),
    listStyleType: 'circle',
  },
  wrapper: {
    backgroundColor: ({ isTouched }) =>
      isTouched ? theme.palette.error.main : theme.palette.primary.main,
    fontSize: '.8rem',
    fontWeight: theme.typography.fontWeightLight,
    padding: theme.spacing(1, 2, 1, 2.5),

    '& span': {
      fontWeight: theme.typography.fontWeightMedium,
      display: 'block',
      marginBottom: theme.spacing(1),
    },
  },
}));
