import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  button: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(1),
  },
  inputElement: {
    display: 'none',
  },
}));
