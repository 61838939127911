import React, { FC } from 'react';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import { useStyles } from './styles';

interface LoaderProps {
  size?: number;
  thickness?: number;
  fullscreen?: boolean;
  message?: string;
}

export const Loader: FC<LoaderProps> = ({
  size = 30,
  thickness = 5,
  fullscreen = false,
  message = '',
}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.loader, fullscreen && classes.fullscreen)}>
      <CircularProgress size={size} thickness={thickness} />
      {message && <p className={classes.message}>{message}</p>}
    </div>
  );
};
