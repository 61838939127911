import EventNoteIcon from '@mui/icons-material/EventNote';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import StoreIcon from '@mui/icons-material/Store';
import TvOutlinedIcon from '@mui/icons-material/TvOutlined';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { ElementType } from 'react';
import { TagType } from '../../../../__generated__/queries-web';

type TagTypeConfig = Partial<Record<TagType | 'Event' | 'Guide', ElementType>>;

export const TagIcons: TagTypeConfig = {
  [TagType.Company]: BusinessOutlinedIcon,
  [TagType.Destination]: ExploreOutlinedIcon,
  [TagType.Tag]: LocalOfferOutlinedIcon,
  [TagType.Person]: PeopleAltOutlinedIcon,
  [TagType.TvProgram]: TvOutlinedIcon,
  [TagType.TvCollection]: StoreIcon,
  [TagType.VideoTag]: VideoLibraryIcon,
  Event: EventNoteIcon,
  Guide: LocalLibraryIcon,
};
