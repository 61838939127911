import makeStyles from '@mui/styles/makeStyles';
const APP_BAR_HEIGHT = 64;
export const useStyles = makeStyles((theme) => ({
  activeFilterBadge: {
    marginTop: theme.spacing(1),
  },
  actionsMenu: {
    paddingBottom: theme.spacing(1.25),
    paddingTop: theme.spacing(1.25),
  },
  innerWrapper: {
    padding: theme.spacing(5, 0),
  },
  wrapper: {
    maxWidth: `calc(1200px + ${theme.spacing(9)})`,
    margin: '0 auto',
    paddingTop: `calc(${theme.spacing(5)} + ${APP_BAR_HEIGHT}px)`,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(4),
  },
  wrapperCenter: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    left: '50%',
    position: 'absolute',
    textAlign: 'center',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  wrapperFeedback: {
    alignItems: 'center',
    display: 'flex',
    left: '50%',
    position: 'absolute',
    textAlign: 'center',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  wrapperTopList: {
    alignItems: 'center',
    borderBottom: `solid 1px ${theme.palette.grey[200]}`,
    display: 'flex',
    width: '100%',
  },
}));
