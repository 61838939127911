import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import React, { FC } from 'react';
import { brandTitleByKey } from '@prismamedia/one-brandkey';
import { formatDate } from '../../../utils/dateUtils';
import { BrandKey } from '../../../__generated__/queries-web';
import { useStyles } from './styles';

interface SearchCardProps {
  image: string | undefined;
  title: string;
  brandKey?: BrandKey;
  publishDate?: string | null;
}

export const SearchCard: FC<SearchCardProps> = ({
  image,
  title,
  brandKey,
  publishDate,
}) => {
  const classes = useStyles();
  return (
    <Card data-testid="searchItem" className={classes.card}>
      {brandKey && (
        <Typography className={classes.brandkey}>
          {brandTitleByKey[brandKey]}
        </Typography>
      )}
      {image ? (
        <CardMedia image={image} className={classes.media} />
      ) : (
        <div className={classes.media} />
      )}
      <CardContent className={classes.content}>
        <Typography>{title}</Typography>
        {publishDate && (
          <Typography variant="caption">
            Publié le {formatDate(new Date(publishDate), 'dd/MM/yyyy à HH:mm')}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};
