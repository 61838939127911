import { gql, useApolloClient } from '@apollo/client';
import {
  ArticleFormat,
  ArticleSearchFieldsType,
  GetSuggestedTagsList,
  GetSuggestedTagsListVariables,
} from '../../__generated__/queries-web';
import { SEARCH_TAG_FRAGMENT } from './getEnabledTags.web.graphql';

const GET_SUGGESTED_TAGS_QUERY = gql`
  query GetSuggestedTagsList(
    $brandKey: BrandKey!
    $exclude: [String]
    $search: SimilarArticleArgsInputType!
    $providers: [TagProviderName!]!
  ) {
    suggestTags(
      brandKey: $brandKey
      exclude: $exclude
      search: $search
      providers: $providers
    ) {
      items {
        ...SearchTagFragment
      }
    }
  }
  ${SEARCH_TAG_FRAGMENT}
`;

const getQueryBody = ({
  article,
  brandKey,
  providers,
}: SearchSuggestedTagsParams) => ({
  query: GET_SUGGESTED_TAGS_QUERY,
  variables: {
    brandKey,
    providers,
    search: {
      article: {
        brandKey: article?.brandKey,
        id: article?.id,
        format: article?.format,
        title: article?.title,
        lead: article?.lead,
        body: article?.body,
      },
      fields: [
        ArticleSearchFieldsType.Title,
        ArticleSearchFieldsType.Lead,
        ArticleSearchFieldsType.Body,
      ],
      first: 15,
      threshold: 0.6,
    },
  },
});

const getArticleVideoQueryBody = ({
  article,
  brandKey,
  providers,
}: SearchSuggestedTagsParams) => ({
  query: GET_SUGGESTED_TAGS_QUERY,
  variables: {
    brandKey,
    providers,
    search: {
      article: {
        brandKey: article?.brandKey,
        id: article?.id,
        format: article?.format,
        title: article?.title,
      },
      fields: [ArticleSearchFieldsType.Title],
      first: 15,
      threshold: 0.6,
    },
  },
});

interface SearchSuggestedTagsParams {
  brandKey: GetSuggestedTagsListVariables['brandKey'];
  article: GetSuggestedTagsListVariables['search']['article'];
  providers: GetSuggestedTagsListVariables['providers'];
}

export const useSearchSuggestedTagsGetterPromise = () => {
  const client = useApolloClient();

  return ({ article, brandKey, providers }: SearchSuggestedTagsParams) =>
    client.query<GetSuggestedTagsList, GetSuggestedTagsListVariables>(
      getQueryBody({ article, brandKey, providers }),
    );
};

export const useSearchSuggestedTagsGetter = () => {
  const client = useApolloClient();

  return ({ article, brandKey, providers }: SearchSuggestedTagsParams) => {
    return client
      .query<GetSuggestedTagsList, GetSuggestedTagsListVariables>(
        article?.format === ArticleFormat.Video
          ? getArticleVideoQueryBody({ article, brandKey, providers })
          : getQueryBody({ article, brandKey, providers }),
      )
      .then(({ data, errors }) => {
        if (errors?.length) {
          throw errors[0];
        }

        return data?.suggestTags;
      });
  };
};
