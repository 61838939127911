import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  icon: {
    marginTop: theme.spacing(0.5),
    fill: theme.palette.grey[800],
    fontSize: '20px',
  },
  iconWrapper: {
    alignItems: 'center',
    backgroundColor: (props: { isDragging?: boolean }) =>
      props.isDragging ? theme.palette.grey[400] : theme.palette.grey[200],
    borderRadius: '50%',
    display: 'flex',
    height: '35px',
    justifyContent: 'center',
    marginBottom: 0,
    overflow: 'hidden',
    width: '35px',
  },
}));
