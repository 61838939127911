import { Card, TextField } from '@mui/material';
import { SimpleCheckBox } from '@prismamedia/one-components';
import React, { Dispatch, FC, useCallback } from 'react';
import { Draft } from '../../../components/Draft';
import { actions as DraftActions } from '../../../components/Draft/DraftActions';
import { MediaBlock } from '../../../types/draft';
import { ButtonForm } from '../ButtonForm';
import { actions, SlideshowEditAction } from '../reducer';
import { useStyles } from './styles';

interface SlideSpecificInformationProps {
  selectedSlide: MediaBlock;
  dispatch: Dispatch<SlideshowEditAction>;
}

export const SlideSpecificInformation: FC<SlideSpecificInformationProps> = ({
  selectedSlide,
  dispatch,
}) => {
  const classes = useStyles();
  const descriptionChange = useCallback(
    (editorState) => {
      dispatch(
        actions.setSlideDescription({
          slideKey: selectedSlide.key,
          description: editorState,
        }),
      );
    },
    [selectedSlide, dispatch],
  );

  return (
    <>
      <TextField
        fullWidth
        label="Titre"
        multiline
        onChange={(event) => {
          dispatch(
            actions.setSlideCaption({
              slideKey: selectedSlide.key,
              caption: event.target.value,
            }),
          );
        }}
        value={selectedSlide.data.caption}
        variant="standard"
      />
      <Draft
        className={classes.draft}
        placeholder="Description"
        rawDraft={selectedSlide.data.slideshow?.description || null}
        onChange={descriptionChange}
        actions={[DraftActions.BOLD, DraftActions.ITALIC, DraftActions.LINK]}
      />
      <Card className={classes.buttonCard}>
        <SimpleCheckBox
          label="Afficher le bouton"
          checked={selectedSlide.data.slideshow?.hasButton}
          onChange={(checked) =>
            dispatch(
              actions.setSlideHasButton({
                slideKey: selectedSlide.key,
                hasButton: checked,
              }),
            )
          }
        />
        {selectedSlide.data.slideshow &&
          selectedSlide.data.slideshow.hasButton && (
            <ButtonForm dispatch={dispatch} selectedSlide={selectedSlide} />
          )}
      </Card>
    </>
  );
};
