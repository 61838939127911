import { Error } from '@mui/icons-material';
import { Tooltip, useTheme } from '@mui/material';
import type { FC } from 'react';
import React from 'react';
import { useStyles } from './styles';
import { PLURAL_CORRECTION_TEXT, SINGULAR_CORRECTION_TEXT } from './constants';

interface ErrorIconProps {
  errorMessages: string | string[];
  isTouched?: boolean;
}
export const ErrorIcon: FC<ErrorIconProps> = ({ errorMessages, isTouched }) => {
  const classes = useStyles({ isTouched });
  const theme = useTheme();

  return (
    <Tooltip
      classes={{ tooltip: classes.wrapper }}
      placement="left"
      title={
        !Array.isArray(errorMessages) || errorMessages.length === 1 ? (
          <>
            <span>{SINGULAR_CORRECTION_TEXT} :</span>
            {errorMessages.length === 1 ? errorMessages[0] : errorMessages}
          </>
        ) : (
          <>
            <span>{PLURAL_CORRECTION_TEXT} :</span>
            <ul className={classes.listWrapper}>
              {errorMessages.map((errorMessage, i) => (
                <li key={errorMessage + i}>{errorMessage}</li>
              ))}
            </ul>
          </>
        )
      }
    >
      <Error
        classes={{ root: classes.errorIcon }}
        color={isTouched ? 'error' : 'primary'}
        fontSize="small"
        {...(isTouched && {
          style: { fill: theme.palette.error.main },
        })}
      />
    </Tooltip>
  );
};
