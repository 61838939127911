import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  listWrapper: {
    background: '#fafafa',
    minHeight: '40vh',
  },
  wrapperTitle: {
    position: 'static',
    zIndex: 2,
    '& h6': {
      marginLeft: theme.spacing(1),
    },
  },
  card: {
    margin: '4px',
  },
  boxCard: {
    display: 'flex',
  },
  linkBoxCard: {
    flex: '1',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dateBoxCard: {
    fontSize: 'medium',
  },
  titleDialogBox: {
    margin: '20px',
    padding: '25px',
    background: '#eed8e1',
    '& span': {
      fontWeight: '500',
      fontSize: '1rem',
    },
  },
  contentDialogBox: {
    margin: '0 0 20px 20px',
    '& span': {
      fontWeight: '500',
      fontSize: '1rem',
    },
  },
  dialogActions: {
    padding: '20px',
    justifyContent: 'center',
  },
}));
