import { gql, useQuery } from '@apollo/client';
import {
  getPushesByBrandKey,
  getPushesByBrandKeyVariables,
} from '../../../__generated__/queries-web';

export const GET_PUSH_BY_BRANDKEY = gql`
  query getPushesByBrandKey(
    $first: Int!
    $skip: Int
    $where: PushWhereInput
    $orderBy: [PushOrderByInput!]
  ) {
    pushCount(where: $where)
    pushes(first: $first, skip: $skip, where: $where, orderBy: $orderBy) {
      id
      title
      article {
        id
        title
        brandKey
        format
      }
      user {
        name
        id
      }
      sentAt
      targetDevice
      scheduledAt
      pushSegments(first: 10) {
        segment {
          title
          id
        }
      }
    }
  }
`;

export const useGetPushCount = (id: string) => {
  const { data } = useQuery<getPushesByBrandKey, getPushesByBrandKeyVariables>(
    GET_PUSH_BY_BRANDKEY,
    {
      variables: {
        first: 25,
        where: {
          article: {
            id,
          },
        },
      },
    },
  );

  return data?.pushCount;
};
