import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  wrapper: ({ withMargin }: { withMargin?: boolean }) => ({
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(2, 3),

    ...(withMargin && {
      marginTop: theme.spacing(7),
    }),
  }),
  mainTitle: {
    fontSize: '1.3rem',
    fontWeight: 500,
  },
}));
