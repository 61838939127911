import { makeStyles } from '@mui/styles';
import { TablePluginData } from '../../types';

export const useStyles = makeStyles((theme) => ({
  row: {
    minHeight: 30,
    padding: theme.spacing(1, 2),
  },
  table: ({ accentuation }: Pick<TablePluginData, 'accentuation'>) => ({
    backgroundColor: theme.palette.grey[200],
    borderSpacing: '0',
    borderCollapse: 'collapse',
    tableLayout: 'fixed',
    width: '100%',

    '& td, & th': {
      borderBottom: 'solid 1px white',
      borderLeft: 'solid 1px white',
      borderRight: 'solid 1px white',
      fontWeight: 'normal',
      minHeight: 30,
      padding: theme.spacing(0.75, 1.25),
      textAlign: 'left',
    },

    // TOP
    '& thead tr th': {
      backgroundColor: accentuation?.top
        ? `${theme.palette.grey[300]} !important`
        : 'inherit',
    },

    // BOTTOM
    '& tbody tr:last-child td': {
      backgroundColor: accentuation?.bottom
        ? `${theme.palette.grey[300]} !important`
        : 'inherit',
    },

    // RIGHT
    '& thead tr th:last-child, & tbody tr td:last-child': {
      backgroundColor: accentuation?.right
        ? `${theme.palette.grey[300]} !important`
        : 'inherit',
    },

    // LEFT
    '& thead tr th:first-child, & tbody tr td:first-child': {
      backgroundColor: accentuation?.left
        ? `${theme.palette.grey[300]} !important`
        : 'inherit',
    },
  }),
  text: {
    height: '30px',
    width: '100%',
  },
  tbody: {
    '& tr td:first-child': {
      borderLeft: 'none',
    },
    '& tr td:last-child': {
      borderRight: 'none',
    },
  },
  thead: {
    '& tr:first-child th': {
      borderBottomWidth: '2px',
    },
    '& tr th:first-child': {
      borderLeft: 'none',
    },
    '& tr th:last-child': {
      borderRight: 'none',
    },
  },
}));
