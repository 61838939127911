import { gql } from '@apollo/client';

export const CARD_FRAGMENT = gql`
  fragment cardFragment on Card {
    id
    title
    secondaryTitle
    description
    url
    label
    brandKey
    status
    media
    body
    cardCategories(first: 100) {
      order
      category {
        id
        level
        title
      }
    }
    cardTags(first: 100) {
      order
      tag {
        id
        title
        type
      }
    }
  }
`;

export const GET_CARD = gql`
  ${CARD_FRAGMENT}
  query getCard($where: CardWhereUniqueInput!) {
    card(where: $where) {
      ...cardFragment
    }
  }
`;
