import { MenuItem as MUIMenuItem } from '@mui/material';
import React, { FC } from 'react';
import type { Transition } from '../../../../utils/statuses';
export interface MenuItemProps {
  changeStatus: { (value: any): void };
  disabled?: boolean;
  transition: Transition;
}
const MenuItem: FC<MenuItemProps> = ({
  changeStatus,
  transition,
  disabled,
}): JSX.Element => (
  <MUIMenuItem
    data-testid={`action-${transition.label}`}
    disabled={disabled}
    onClick={() => {
      changeStatus(transition.to[0]);
    }}
  >
    {transition.label}
  </MUIMenuItem>
);

export { MenuItem };
