import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import {
  MULTIMEDIA_MANAGER_TABS_WIDTH,
  MULTIMEDIA_MANAGER_SEARCH_BLOCK_HEIGHT,
} from '@prismamedia/one-components';

interface OnePhotoStyledProps {
  isOpen: boolean;
}
export const useStyles = makeStyles<Theme, OnePhotoStyledProps>((theme) => ({
  multimediaSearch: {
    width: '100%',
  },
  topInnerWrapper: ({ isOpen }) => ({
    height: isOpen ? `${MULTIMEDIA_MANAGER_SEARCH_BLOCK_HEIGHT}px` : '0',
    overflow: 'hidden',
    position: 'absolute',
    width: `calc(100% - (${MULTIMEDIA_MANAGER_TABS_WIDTH}px + ${theme.spacing(
      2.25,
    )}))`,
  }),
  headerText: {
    display: 'block',
    left: 0,
    position: 'relative',
    paddingLeft: theme.spacing(3),
    top: theme.spacing(12),
    zIndex: 2,
  },
  wrapperList: {
    paddingTop: `calc(${MULTIMEDIA_MANAGER_SEARCH_BLOCK_HEIGHT}px - ${theme.spacing(
      2,
    )} + 1px)`,
    height: '100%',
  },
}));
