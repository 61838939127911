import { ImageSearchOutlined } from '@mui/icons-material';
import {
  Card as MUICard,
  Chip,
  Box,
  MenuItem,
  Typography,
} from '@mui/material';
import { ImageLoader, LockButton, useMenu } from '@prismamedia/one-components';
import { format as formatDate } from 'date-fns';
import fr from 'date-fns/locale/fr';
import React, { FC, ReactNode, useMemo } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { paths } from '../../../../routing/Router/paths';
import { auth } from '../../../../utils/auth';
import { CategoryStatus } from '../../../../__generated__/queries-web';
import { useUpdatePageCategoryLockerId } from '../../apollo/mutations/category.web.graphql';
import { Category } from '../../types';
import { getCategoryStatusLabel, navigateTo } from '../../utils';
// import { Favorite } from './components/Favorite';
import { CONTENT_ADDED_AT, EMPTY_TITLE, END_EDITION_TEXT } from './constants';
import { useStyles } from './styles';

export enum CardSize {
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
}

interface CardProps {
  ActionsMenu?: ReactNode;
  ActionsIcons?: ReactNode;
  ActionsButtons?: ReactNode;
  children?: ReactNode;
  createdAt?: GraphQL$DateTime | null;
  format?: string;
  dataTestId?: string;
  id: string;
  media: string | null;
  MediaOverlay?: ReactNode;
  onCardClick?: (id: string) => () => void;
  lockedBy?: {
    avatarUrl: string | null;
    id: string;
    name: string | null;
  };
  size?: CardSize;
  status?: CategoryStatus;
  title: string | null;
  topTitle?: string;
  updatedAt?: GraphQL$DateTime | null;
  category?: Category;
}
export const Card: FC<CardProps> = ({
  ActionsButtons,
  ActionsIcons,
  ActionsMenu,
  children,
  createdAt,
  dataTestId,
  id,
  media,
  MediaOverlay,
  onCardClick,
  lockedBy,
  size = CardSize.LARGE,
  status,
  title,
  topTitle,
  category,
}) => {
  const { openMenu, closeMenu } = useMenu();
  const routeParams = useParams();
  const updateLockerId = useUpdatePageCategoryLockerId();

  const topTitleDisplayed = useMemo(() => {
    if (topTitle) {
      return topTitle;
    }

    const createdAtText = createdAt
      ? `${CONTENT_ADDED_AT} ${formatDate(
          new Date(createdAt),
          'dd/MM/yyyy à HH:mm',
          {
            locale: fr,
          },
        )}`
      : null;

    const isTopTitleEmpty = !createdAtText?.length;

    return isTopTitleEmpty ? '' : <>{`${createdAtText}.`}</>;
  }, [createdAt, topTitle]);

  const classes = useStyles({
    size,
    status,
    withTopTitle: Boolean(topTitleDisplayed),
  });

  const statusTitle = useMemo(() => getCategoryStatusLabel(status), [status]);

  const handleModify = () => {
    const { brandKey, format } = routeParams;
    const search = new URLSearchParams({ from: 'Card' }).toString();

    const pathname = `${generatePath(paths.PAGE_EDIT, {
      brandKey,
      format,
    })}${category?.path}`;

    navigateTo({ pathname, opts: { search } });
  };

  return (
    <MUICard
      className={classes.wrapper}
      onClick={onCardClick?.(id)}
      variant="outlined"
      {...(dataTestId && { 'data-testid': dataTestId })}
    >
      <div className={classes.innerWrapper}>
        {/* Card media thumbnail */}
        <div className={classes.wrapperLeftSide}>
          {MediaOverlay && (
            <Box className={classes.mediaOverlay} component="span">
              {MediaOverlay}
            </Box>
          )}

          <ImageLoader
            className={classes.media}
            fitting="cover"
            image={<ImageSearchOutlined className={classes.imageFallback} />}
            loaderSize={35}
            src={media}
            timeout={3000}
          />
        </div>

        {/* Card Title & description */}
        <div className={classes.wrapperCenter}>
          {!!topTitleDisplayed && (
            <Typography className={classes.topTitle} variant="body2">
              {topTitleDisplayed}
            </Typography>
          )}

          <Typography variant="h5" component="h2" className={classes.mainTitle}>
            {title || <em className={classes.empty}>{EMPTY_TITLE}</em>}
          </Typography>

          {statusTitle && (
            <Chip
              className={classes.status}
              label={statusTitle}
              variant="outlined"
              size="small"
            />
          )}

          {/* Action Icons/Menu (top/right) */}
          <div className={classes.wrapperActionsIcons}>
            {ActionsIcons} {ActionsMenu}
          </div>
        </div>

        {/* Card right side */}
        <div className={classes.wrapperRightSide}>
          {ActionsButtons || children}

          {lockedBy && (
            <LockButton
              className={classes.lockButton}
              disableLockForV0
              lockedBy={lockedBy}
              onClick={(e) => {
                e.stopPropagation();
                if (lockedBy?.id === auth.user?.id) {
                  openMenu(
                    e.currentTarget,
                    <MenuItem
                      onClick={() => {
                        updateLockerId(id, null);
                        closeMenu();
                      }}
                    >
                      {END_EDITION_TEXT}
                    </MenuItem>,
                  );
                } else {
                  handleModify();
                }
              }}
            />
          )}
        </div>
      </div>
    </MUICard>
  );
};
