import { ImageList } from '@mui/material';
import { ImageGalleryItem } from '@prismamedia/one-components';
import React, { FC } from 'react';
import { MediaBlock } from '../../../types/draft';
import { getSrcFromMedia } from '../Grid/utils';
import { useStyles } from './styles';

interface DrawerSmallGridProps {
  selectedSlides: MediaBlock[];
}

export const DrawerSmallGrid: FC<DrawerSmallGridProps> = ({
  selectedSlides,
}) => {
  const classes = useStyles();

  return (
    <ImageList cols={2} gap={3} rowHeight={50} className={classes.grid}>
      {selectedSlides.map((block: MediaBlock) => {
        return (
          <ImageGalleryItem
            key={block.key}
            className={classes.item}
            src={getSrcFromMedia(block.data)}
          />
        );
      })}
    </ImageList>
  );
};
