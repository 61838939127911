/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRecipes
// ====================================================

export interface GetRecipes_recipes_recipeUrls_url {
  __typename: "Url";
  path: string;
}

export interface GetRecipes_recipes_recipeUrls {
  __typename: "RecipeUrl";
  url: GetRecipes_recipes_recipeUrls_url | null;
}

export interface GetRecipes_recipes_recipeCategories_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface GetRecipes_recipes_recipeCategories {
  __typename: "RecipeCategory";
  category: GetRecipes_recipes_recipeCategories_category | null;
}

export interface GetRecipes_recipes {
  __typename: "Recipe";
  brandKey: string;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  medias: string | null;
  /**
   * "RecipeUrl.recipe"'s inverse relation
   */
  recipeUrls: GetRecipes_recipes_recipeUrls[];
  /**
   * The date of the document's publication
   */
  publishedAt: GraphQL$DateTime | null;
  status: RecipeStatus | null;
  title: string;
  /**
   * "RecipeCategory.recipe"'s inverse relation
   */
  recipeCategories: GetRecipes_recipes_recipeCategories[];
  authorName: string | null;
}

export interface GetRecipes {
  /**
   * Retrieve a list of "Recipe" nodes.
   */
  recipes: GetRecipes_recipes[];
}

export interface GetRecipesVariables {
  where?: RecipeWhereInput | null;
  orderBy?: RecipeOrderByInput[] | null;
  first: number;
  skip?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecipeCount
// ====================================================

export interface RecipeCount {
  /**
   * Retrieve the number of "Recipe" nodes.
   */
  recipeCount: number;
}

export interface RecipeCountVariables {
  where?: RecipeWhereInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum RecipeOrderByInput {
  _id_ASC = "_id_ASC",
  _id_DESC = "_id_DESC",
  advice_ASC = "advice_ASC",
  advice_DESC = "advice_DESC",
  author_ASC = "author_ASC",
  author_DESC = "author_DESC",
  brandKey_ASC = "brandKey_ASC",
  brandKey_DESC = "brandKey_DESC",
  calorieLevel_ASC = "calorieLevel_ASC",
  calorieLevel_DESC = "calorieLevel_DESC",
  cookTime_ASC = "cookTime_ASC",
  cookTime_DESC = "cookTime_DESC",
  cookingMethod_ASC = "cookingMethod_ASC",
  cookingMethod_DESC = "cookingMethod_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  creatorId_ASC = "creatorId_ASC",
  creatorId_DESC = "creatorId_DESC",
  difficulty_ASC = "difficulty_ASC",
  difficulty_DESC = "difficulty_DESC",
  editedAt_ASC = "editedAt_ASC",
  editedAt_DESC = "editedAt_DESC",
  estimatedCost_ASC = "estimatedCost_ASC",
  estimatedCost_DESC = "estimatedCost_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  instructions_ASC = "instructions_ASC",
  instructions_DESC = "instructions_DESC",
  lead_ASC = "lead_ASC",
  lead_DESC = "lead_DESC",
  legacyId_ASC = "legacyId_ASC",
  legacyId_DESC = "legacyId_DESC",
  lockedAt_ASC = "lockedAt_ASC",
  lockedAt_DESC = "lockedAt_DESC",
  lockerId_ASC = "lockerId_ASC",
  lockerId_DESC = "lockerId_DESC",
  medias_ASC = "medias_ASC",
  medias_DESC = "medias_DESC",
  parsedIngredients_ASC = "parsedIngredients_ASC",
  parsedIngredients_DESC = "parsedIngredients_DESC",
  prepTime_ASC = "prepTime_ASC",
  prepTime_DESC = "prepTime_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  rawIngredients_ASC = "rawIngredients_ASC",
  rawIngredients_DESC = "rawIngredients_DESC",
  slug_ASC = "slug_ASC",
  slug_DESC = "slug_DESC",
  source_ASC = "source_ASC",
  source_DESC = "source_DESC",
  status_ASC = "status_ASC",
  status_DESC = "status_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  totalTime_ASC = "totalTime_ASC",
  totalTime_DESC = "totalTime_DESC",
  unparsedIngredients_ASC = "unparsedIngredients_ASC",
  unparsedIngredients_DESC = "unparsedIngredients_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
  waitTime_ASC = "waitTime_ASC",
  waitTime_DESC = "waitTime_DESC",
  yield_ASC = "yield_ASC",
  yield_DESC = "yield_DESC",
}

export enum RecipeStatus {
  Deleted = "Deleted",
  Draft = "Draft",
  Frozen = "Frozen",
  Published = "Published",
  Ready = "Ready",
  Scheduled = "Scheduled",
}

/**
 * Filters the "CategoryUrl" nodes by specifying some conditions
 */
export interface CategoryUrlWhereInput {
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  position?: number | null;
  position_not?: number | null;
  position_in?: number[] | null;
  position_not_in?: number[] | null;
  position_lt?: number | null;
  position_lte?: number | null;
  position_gt?: number | null;
  position_gte?: number | null;
  url?: UrlWhereInput | null;
  url_is_null?: boolean | null;
  category?: CategoryWhereInput | null;
  category_is_null?: boolean | null;
  AND?: CategoryUrlWhereInput[] | null;
  OR?: CategoryUrlWhereInput[] | null;
  NOT?: CategoryUrlWhereInput | null;
}

/**
 * Filters the "Category" nodes by specifying some conditions
 */
export interface CategoryWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  title_starts_with?: string | null;
  title_not_starts_with?: string | null;
  title_ends_with?: string | null;
  title_not_ends_with?: string | null;
  title_lt?: string | null;
  title_lte?: string | null;
  title_gt?: string | null;
  title_gte?: string | null;
  subtitle?: string | null;
  subtitle_not?: string | null;
  subtitle_is_null?: boolean | null;
  subtitle_in?: (string | null)[] | null;
  subtitle_not_in?: (string | null)[] | null;
  subtitle_contains?: string | null;
  subtitle_not_contains?: string | null;
  subtitle_starts_with?: string | null;
  subtitle_not_starts_with?: string | null;
  subtitle_ends_with?: string | null;
  subtitle_not_ends_with?: string | null;
  subtitle_lt?: string | null;
  subtitle_lte?: string | null;
  subtitle_gt?: string | null;
  subtitle_gte?: string | null;
  slug?: string | null;
  slug_not?: string | null;
  slug_is_null?: boolean | null;
  slug_in?: (string | null)[] | null;
  slug_not_in?: (string | null)[] | null;
  slug_contains?: string | null;
  slug_not_contains?: string | null;
  slug_starts_with?: string | null;
  slug_not_starts_with?: string | null;
  slug_ends_with?: string | null;
  slug_not_ends_with?: string | null;
  slug_lt?: string | null;
  slug_lte?: string | null;
  slug_gt?: string | null;
  slug_gte?: string | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  body?: string | null;
  body_not?: string | null;
  body_is_null?: boolean | null;
  body_in?: (string | null)[] | null;
  body_not_in?: (string | null)[] | null;
  body_contains?: string | null;
  body_not_contains?: string | null;
  body_starts_with?: string | null;
  body_not_starts_with?: string | null;
  body_ends_with?: string | null;
  body_not_ends_with?: string | null;
  body_lt?: string | null;
  body_lte?: string | null;
  body_gt?: string | null;
  body_gte?: string | null;
  medias?: string | null;
  medias_not?: string | null;
  medias_is_null?: boolean | null;
  medias_in?: (string | null)[] | null;
  medias_not_in?: (string | null)[] | null;
  medias_contains?: string | null;
  medias_not_contains?: string | null;
  medias_starts_with?: string | null;
  medias_not_starts_with?: string | null;
  medias_ends_with?: string | null;
  medias_not_ends_with?: string | null;
  medias_lt?: string | null;
  medias_lte?: string | null;
  medias_gt?: string | null;
  medias_gte?: string | null;
  left?: number | null;
  left_not?: number | null;
  left_in?: number[] | null;
  left_not_in?: number[] | null;
  left_lt?: number | null;
  left_lte?: number | null;
  left_gt?: number | null;
  left_gte?: number | null;
  right?: number | null;
  right_not?: number | null;
  right_in?: number[] | null;
  right_not_in?: number[] | null;
  right_lt?: number | null;
  right_lte?: number | null;
  right_gt?: number | null;
  right_gte?: number | null;
  level?: number | null;
  level_not?: number | null;
  level_in?: number[] | null;
  level_not_in?: number[] | null;
  level_lt?: number | null;
  level_lte?: number | null;
  level_gt?: number | null;
  level_gte?: number | null;
  brandKey?: string | null;
  brandKey_not?: string | null;
  brandKey_in?: string[] | null;
  brandKey_not_in?: string[] | null;
  brandKey_contains?: string | null;
  brandKey_not_contains?: string | null;
  brandKey_starts_with?: string | null;
  brandKey_not_starts_with?: string | null;
  brandKey_ends_with?: string | null;
  brandKey_not_ends_with?: string | null;
  brandKey_lt?: string | null;
  brandKey_lte?: string | null;
  brandKey_gt?: string | null;
  brandKey_gte?: string | null;
  rootId?: string | null;
  rootId_not?: string | null;
  rootId_is_null?: boolean | null;
  rootId_in?: (string | null)[] | null;
  rootId_not_in?: (string | null)[] | null;
  status?: string | null;
  status_not?: string | null;
  status_is_null?: boolean | null;
  status_in?: (string | null)[] | null;
  status_not_in?: (string | null)[] | null;
  status_contains?: string | null;
  status_not_contains?: string | null;
  status_starts_with?: string | null;
  status_not_starts_with?: string | null;
  status_ends_with?: string | null;
  status_not_ends_with?: string | null;
  status_lt?: string | null;
  status_lte?: string | null;
  status_gt?: string | null;
  status_gte?: string | null;
  parent?: CategoryWhereInput | null;
  parent_is_null?: boolean | null;
  children_some?: CategoryWhereInput | null;
  categoryUrls_some?: CategoryUrlWhereInput | null;
  recipeCategories_some?: RecipeCategoryWhereInput | null;
  AND?: CategoryWhereInput[] | null;
  OR?: CategoryWhereInput[] | null;
  NOT?: CategoryWhereInput | null;
}

/**
 * Filters the "RecipeCategory" nodes by specifying some conditions
 */
export interface RecipeCategoryWhereInput {
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  position?: number | null;
  position_not?: number | null;
  position_in?: number[] | null;
  position_not_in?: number[] | null;
  position_lt?: number | null;
  position_lte?: number | null;
  position_gt?: number | null;
  position_gte?: number | null;
  recipe?: RecipeWhereInput | null;
  recipe_is_null?: boolean | null;
  category?: CategoryWhereInput | null;
  category_is_null?: boolean | null;
  AND?: RecipeCategoryWhereInput[] | null;
  OR?: RecipeCategoryWhereInput[] | null;
  NOT?: RecipeCategoryWhereInput | null;
}

/**
 * Filters the "RecipeGuide" nodes by specifying some conditions
 */
export interface RecipeGuideWhereInput {
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  position?: number | null;
  position_not?: number | null;
  position_in?: number[] | null;
  position_not_in?: number[] | null;
  position_lt?: number | null;
  position_lte?: number | null;
  position_gt?: number | null;
  position_gte?: number | null;
  guideId?: string | null;
  guideId_not?: string | null;
  guideId_is_null?: boolean | null;
  guideId_in?: (string | null)[] | null;
  guideId_not_in?: (string | null)[] | null;
  recipe?: RecipeWhereInput | null;
  recipe_is_null?: boolean | null;
  AND?: RecipeGuideWhereInput[] | null;
  OR?: RecipeGuideWhereInput[] | null;
  NOT?: RecipeGuideWhereInput | null;
}

/**
 * Filters the "RecipeHistory" nodes by specifying some conditions
 */
export interface RecipeHistoryWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  userId?: string | null;
  userId_not?: string | null;
  userId_in?: string[] | null;
  userId_not_in?: string[] | null;
  status?: RecipeStatus | null;
  status_not?: RecipeStatus | null;
  status_is_null?: boolean | null;
  status_in?: (RecipeStatus | null)[] | null;
  status_not_in?: (RecipeStatus | null)[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  recipe?: RecipeWhereInput | null;
  AND?: RecipeHistoryWhereInput[] | null;
  OR?: RecipeHistoryWhereInput[] | null;
  NOT?: RecipeHistoryWhereInput | null;
}

/**
 * Filters the "RecipeTag" nodes by specifying some conditions
 */
export interface RecipeTagWhereInput {
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  recipe?: RecipeWhereInput | null;
  recipe_is_null?: boolean | null;
  tag?: TagWhereInput | null;
  tag_is_null?: boolean | null;
  AND?: RecipeTagWhereInput[] | null;
  OR?: RecipeTagWhereInput[] | null;
  NOT?: RecipeTagWhereInput | null;
}

/**
 * Filters the "RecipeUrl" nodes by specifying some conditions
 */
export interface RecipeUrlWhereInput {
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  position?: number | null;
  position_not?: number | null;
  position_in?: number[] | null;
  position_not_in?: number[] | null;
  position_lt?: number | null;
  position_lte?: number | null;
  position_gt?: number | null;
  position_gte?: number | null;
  recipe?: RecipeWhereInput | null;
  recipe_is_null?: boolean | null;
  url?: UrlWhereInput | null;
  url_is_null?: boolean | null;
  AND?: RecipeUrlWhereInput[] | null;
  OR?: RecipeUrlWhereInput[] | null;
  NOT?: RecipeUrlWhereInput | null;
}

/**
 * Filters the "Recipe" nodes by specifying some conditions
 */
export interface RecipeWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  title_starts_with?: string | null;
  title_not_starts_with?: string | null;
  title_ends_with?: string | null;
  title_not_ends_with?: string | null;
  title_lt?: string | null;
  title_lte?: string | null;
  title_gt?: string | null;
  title_gte?: string | null;
  lead?: string | null;
  lead_not?: string | null;
  lead_is_null?: boolean | null;
  lead_in?: (string | null)[] | null;
  lead_not_in?: (string | null)[] | null;
  lead_contains?: string | null;
  lead_not_contains?: string | null;
  lead_starts_with?: string | null;
  lead_not_starts_with?: string | null;
  lead_ends_with?: string | null;
  lead_not_ends_with?: string | null;
  lead_lt?: string | null;
  lead_lte?: string | null;
  lead_gt?: string | null;
  lead_gte?: string | null;
  unparsedIngredients?: boolean | null;
  unparsedIngredients_not?: boolean | null;
  unparsedIngredients_in?: boolean[] | null;
  unparsedIngredients_not_in?: boolean[] | null;
  slug?: string | null;
  slug_not?: string | null;
  slug_is_null?: boolean | null;
  slug_in?: (string | null)[] | null;
  slug_not_in?: (string | null)[] | null;
  slug_contains?: string | null;
  slug_not_contains?: string | null;
  slug_starts_with?: string | null;
  slug_not_starts_with?: string | null;
  slug_ends_with?: string | null;
  slug_not_ends_with?: string | null;
  slug_lt?: string | null;
  slug_lte?: string | null;
  slug_gt?: string | null;
  slug_gte?: string | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  publishedAt?: GraphQL$DateTime | null;
  publishedAt_not?: GraphQL$DateTime | null;
  publishedAt_is_null?: boolean | null;
  publishedAt_in?: (GraphQL$DateTime | null)[] | null;
  publishedAt_not_in?: (GraphQL$DateTime | null)[] | null;
  publishedAt_lt?: GraphQL$DateTime | null;
  publishedAt_lte?: GraphQL$DateTime | null;
  publishedAt_gt?: GraphQL$DateTime | null;
  publishedAt_gte?: GraphQL$DateTime | null;
  editedAt?: GraphQL$DateTime | null;
  editedAt_not?: GraphQL$DateTime | null;
  editedAt_is_null?: boolean | null;
  editedAt_in?: (GraphQL$DateTime | null)[] | null;
  editedAt_not_in?: (GraphQL$DateTime | null)[] | null;
  editedAt_lt?: GraphQL$DateTime | null;
  editedAt_lte?: GraphQL$DateTime | null;
  editedAt_gt?: GraphQL$DateTime | null;
  editedAt_gte?: GraphQL$DateTime | null;
  cookTime?: number | null;
  cookTime_not?: number | null;
  cookTime_is_null?: boolean | null;
  cookTime_in?: (number | null)[] | null;
  cookTime_not_in?: (number | null)[] | null;
  cookTime_lt?: number | null;
  cookTime_lte?: number | null;
  cookTime_gt?: number | null;
  cookTime_gte?: number | null;
  prepTime?: number | null;
  prepTime_not?: number | null;
  prepTime_is_null?: boolean | null;
  prepTime_in?: (number | null)[] | null;
  prepTime_not_in?: (number | null)[] | null;
  prepTime_lt?: number | null;
  prepTime_lte?: number | null;
  prepTime_gt?: number | null;
  prepTime_gte?: number | null;
  waitTime?: number | null;
  waitTime_not?: number | null;
  waitTime_is_null?: boolean | null;
  waitTime_in?: (number | null)[] | null;
  waitTime_not_in?: (number | null)[] | null;
  waitTime_lt?: number | null;
  waitTime_lte?: number | null;
  waitTime_gt?: number | null;
  waitTime_gte?: number | null;
  totalTime?: number | null;
  totalTime_not?: number | null;
  totalTime_is_null?: boolean | null;
  totalTime_in?: (number | null)[] | null;
  totalTime_not_in?: (number | null)[] | null;
  totalTime_lt?: number | null;
  totalTime_lte?: number | null;
  totalTime_gt?: number | null;
  totalTime_gte?: number | null;
  cookingMethod?: string | null;
  cookingMethod_not?: string | null;
  cookingMethod_is_null?: boolean | null;
  cookingMethod_in?: (string | null)[] | null;
  cookingMethod_not_in?: (string | null)[] | null;
  cookingMethod_contains?: string | null;
  cookingMethod_not_contains?: string | null;
  cookingMethod_starts_with?: string | null;
  cookingMethod_not_starts_with?: string | null;
  cookingMethod_ends_with?: string | null;
  cookingMethod_not_ends_with?: string | null;
  cookingMethod_lt?: string | null;
  cookingMethod_lte?: string | null;
  cookingMethod_gt?: string | null;
  cookingMethod_gte?: string | null;
  instructions?: string | null;
  instructions_not?: string | null;
  instructions_is_null?: boolean | null;
  instructions_in?: (string | null)[] | null;
  instructions_not_in?: (string | null)[] | null;
  instructions_contains?: string | null;
  instructions_not_contains?: string | null;
  instructions_starts_with?: string | null;
  instructions_not_starts_with?: string | null;
  instructions_ends_with?: string | null;
  instructions_not_ends_with?: string | null;
  instructions_lt?: string | null;
  instructions_lte?: string | null;
  instructions_gt?: string | null;
  instructions_gte?: string | null;
  advice?: string | null;
  advice_not?: string | null;
  advice_is_null?: boolean | null;
  advice_in?: (string | null)[] | null;
  advice_not_in?: (string | null)[] | null;
  advice_contains?: string | null;
  advice_not_contains?: string | null;
  advice_starts_with?: string | null;
  advice_not_starts_with?: string | null;
  advice_ends_with?: string | null;
  advice_not_ends_with?: string | null;
  advice_lt?: string | null;
  advice_lte?: string | null;
  advice_gt?: string | null;
  advice_gte?: string | null;
  estimatedCost?: string | null;
  estimatedCost_not?: string | null;
  estimatedCost_is_null?: boolean | null;
  estimatedCost_in?: (string | null)[] | null;
  estimatedCost_not_in?: (string | null)[] | null;
  estimatedCost_contains?: string | null;
  estimatedCost_not_contains?: string | null;
  estimatedCost_starts_with?: string | null;
  estimatedCost_not_starts_with?: string | null;
  estimatedCost_ends_with?: string | null;
  estimatedCost_not_ends_with?: string | null;
  estimatedCost_lt?: string | null;
  estimatedCost_lte?: string | null;
  estimatedCost_gt?: string | null;
  estimatedCost_gte?: string | null;
  medias?: string | null;
  medias_not?: string | null;
  medias_is_null?: boolean | null;
  medias_in?: (string | null)[] | null;
  medias_not_in?: (string | null)[] | null;
  medias_contains?: string | null;
  medias_not_contains?: string | null;
  medias_starts_with?: string | null;
  medias_not_starts_with?: string | null;
  medias_ends_with?: string | null;
  medias_not_ends_with?: string | null;
  medias_lt?: string | null;
  medias_lte?: string | null;
  medias_gt?: string | null;
  medias_gte?: string | null;
  difficulty?: string | null;
  difficulty_not?: string | null;
  difficulty_is_null?: boolean | null;
  difficulty_in?: (string | null)[] | null;
  difficulty_not_in?: (string | null)[] | null;
  difficulty_contains?: string | null;
  difficulty_not_contains?: string | null;
  difficulty_starts_with?: string | null;
  difficulty_not_starts_with?: string | null;
  difficulty_ends_with?: string | null;
  difficulty_not_ends_with?: string | null;
  difficulty_lt?: string | null;
  difficulty_lte?: string | null;
  difficulty_gt?: string | null;
  difficulty_gte?: string | null;
  yield?: number | null;
  yield_not?: number | null;
  yield_is_null?: boolean | null;
  yield_in?: (number | null)[] | null;
  yield_not_in?: (number | null)[] | null;
  yield_lt?: number | null;
  yield_lte?: number | null;
  yield_gt?: number | null;
  yield_gte?: number | null;
  rawIngredients?: string | null;
  rawIngredients_not?: string | null;
  rawIngredients_in?: string[] | null;
  rawIngredients_not_in?: string[] | null;
  rawIngredients_contains?: string | null;
  rawIngredients_not_contains?: string | null;
  rawIngredients_starts_with?: string | null;
  rawIngredients_not_starts_with?: string | null;
  rawIngredients_ends_with?: string | null;
  rawIngredients_not_ends_with?: string | null;
  rawIngredients_lt?: string | null;
  rawIngredients_lte?: string | null;
  rawIngredients_gt?: string | null;
  rawIngredients_gte?: string | null;
  parsedIngredients?: string | null;
  parsedIngredients_not?: string | null;
  parsedIngredients_in?: string[] | null;
  parsedIngredients_not_in?: string[] | null;
  parsedIngredients_contains?: string | null;
  parsedIngredients_not_contains?: string | null;
  parsedIngredients_starts_with?: string | null;
  parsedIngredients_not_starts_with?: string | null;
  parsedIngredients_ends_with?: string | null;
  parsedIngredients_not_ends_with?: string | null;
  parsedIngredients_lt?: string | null;
  parsedIngredients_lte?: string | null;
  parsedIngredients_gt?: string | null;
  parsedIngredients_gte?: string | null;
  brandKey?: string | null;
  brandKey_not?: string | null;
  brandKey_in?: string[] | null;
  brandKey_not_in?: string[] | null;
  brandKey_contains?: string | null;
  brandKey_not_contains?: string | null;
  brandKey_starts_with?: string | null;
  brandKey_not_starts_with?: string | null;
  brandKey_ends_with?: string | null;
  brandKey_not_ends_with?: string | null;
  brandKey_lt?: string | null;
  brandKey_lte?: string | null;
  brandKey_gt?: string | null;
  brandKey_gte?: string | null;
  status?: RecipeStatus | null;
  status_not?: RecipeStatus | null;
  status_is_null?: boolean | null;
  status_in?: (RecipeStatus | null)[] | null;
  status_not_in?: (RecipeStatus | null)[] | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_is_null?: boolean | null;
  creatorId_in?: (string | null)[] | null;
  creatorId_not_in?: (string | null)[] | null;
  author?: string | null;
  author_not?: string | null;
  author_is_null?: boolean | null;
  author_in?: (string | null)[] | null;
  author_not_in?: (string | null)[] | null;
  legacyId?: number | null;
  legacyId_not?: number | null;
  legacyId_is_null?: boolean | null;
  legacyId_in?: (number | null)[] | null;
  legacyId_not_in?: (number | null)[] | null;
  legacyId_lt?: number | null;
  legacyId_lte?: number | null;
  legacyId_gt?: number | null;
  legacyId_gte?: number | null;
  calorieLevel?: string | null;
  calorieLevel_not?: string | null;
  calorieLevel_is_null?: boolean | null;
  calorieLevel_in?: (string | null)[] | null;
  calorieLevel_not_in?: (string | null)[] | null;
  calorieLevel_contains?: string | null;
  calorieLevel_not_contains?: string | null;
  calorieLevel_starts_with?: string | null;
  calorieLevel_not_starts_with?: string | null;
  calorieLevel_ends_with?: string | null;
  calorieLevel_not_ends_with?: string | null;
  calorieLevel_lt?: string | null;
  calorieLevel_lte?: string | null;
  calorieLevel_gt?: string | null;
  calorieLevel_gte?: string | null;
  source?: string | null;
  source_not?: string | null;
  source_is_null?: boolean | null;
  source_in?: (string | null)[] | null;
  source_not_in?: (string | null)[] | null;
  source_contains?: string | null;
  source_not_contains?: string | null;
  source_starts_with?: string | null;
  source_not_starts_with?: string | null;
  source_ends_with?: string | null;
  source_not_ends_with?: string | null;
  source_lt?: string | null;
  source_lte?: string | null;
  source_gt?: string | null;
  source_gte?: string | null;
  lockerId?: string | null;
  lockerId_not?: string | null;
  lockerId_is_null?: boolean | null;
  lockerId_in?: (string | null)[] | null;
  lockerId_not_in?: (string | null)[] | null;
  lockedAt?: GraphQL$DateTime | null;
  lockedAt_not?: GraphQL$DateTime | null;
  lockedAt_is_null?: boolean | null;
  lockedAt_in?: (GraphQL$DateTime | null)[] | null;
  lockedAt_not_in?: (GraphQL$DateTime | null)[] | null;
  lockedAt_lt?: GraphQL$DateTime | null;
  lockedAt_lte?: GraphQL$DateTime | null;
  lockedAt_gt?: GraphQL$DateTime | null;
  lockedAt_gte?: GraphQL$DateTime | null;
  recipeCategories_some?: RecipeCategoryWhereInput | null;
  recipeGuides_some?: RecipeGuideWhereInput | null;
  recipeHistories_some?: RecipeHistoryWhereInput | null;
  recipeTags_some?: RecipeTagWhereInput | null;
  recipeUrls_some?: RecipeUrlWhereInput | null;
  AND?: RecipeWhereInput[] | null;
  OR?: RecipeWhereInput[] | null;
  NOT?: RecipeWhereInput | null;
}

/**
 * Filters the "Tag" nodes by specifying some conditions
 */
export interface TagWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  title_starts_with?: string | null;
  title_not_starts_with?: string | null;
  title_ends_with?: string | null;
  title_not_ends_with?: string | null;
  title_lt?: string | null;
  title_lte?: string | null;
  title_gt?: string | null;
  title_gte?: string | null;
  slug?: string | null;
  slug_not?: string | null;
  slug_is_null?: boolean | null;
  slug_in?: (string | null)[] | null;
  slug_not_in?: (string | null)[] | null;
  slug_contains?: string | null;
  slug_not_contains?: string | null;
  slug_starts_with?: string | null;
  slug_not_starts_with?: string | null;
  slug_ends_with?: string | null;
  slug_not_ends_with?: string | null;
  slug_lt?: string | null;
  slug_lte?: string | null;
  slug_gt?: string | null;
  slug_gte?: string | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  recipeTags_some?: RecipeTagWhereInput | null;
  AND?: TagWhereInput[] | null;
  OR?: TagWhereInput[] | null;
  NOT?: TagWhereInput | null;
}

/**
 * Filters the "Url" nodes by specifying some conditions
 */
export interface UrlWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  path?: string | null;
  path_not?: string | null;
  path_in?: string[] | null;
  path_not_in?: string[] | null;
  path_contains?: string | null;
  path_not_contains?: string | null;
  path_starts_with?: string | null;
  path_not_starts_with?: string | null;
  path_ends_with?: string | null;
  path_not_ends_with?: string | null;
  path_lt?: string | null;
  path_lte?: string | null;
  path_gt?: string | null;
  path_gte?: string | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  brandKey?: string | null;
  brandKey_not?: string | null;
  brandKey_is_null?: boolean | null;
  brandKey_in?: (string | null)[] | null;
  brandKey_not_in?: (string | null)[] | null;
  brandKey_contains?: string | null;
  brandKey_not_contains?: string | null;
  brandKey_starts_with?: string | null;
  brandKey_not_starts_with?: string | null;
  brandKey_ends_with?: string | null;
  brandKey_not_ends_with?: string | null;
  brandKey_lt?: string | null;
  brandKey_lte?: string | null;
  brandKey_gt?: string | null;
  brandKey_gte?: string | null;
  publicId?: string | null;
  publicId_not?: string | null;
  publicId_is_null?: boolean | null;
  publicId_in?: (string | null)[] | null;
  publicId_not_in?: (string | null)[] | null;
  publicId_contains?: string | null;
  publicId_not_contains?: string | null;
  publicId_starts_with?: string | null;
  publicId_not_starts_with?: string | null;
  publicId_ends_with?: string | null;
  publicId_not_ends_with?: string | null;
  publicId_lt?: string | null;
  publicId_lte?: string | null;
  publicId_gt?: string | null;
  publicId_gte?: string | null;
  categoryUrls_some?: CategoryUrlWhereInput | null;
  recipeUrls_some?: RecipeUrlWhereInput | null;
  AND?: UrlWhereInput[] | null;
  OR?: UrlWhereInput[] | null;
  NOT?: UrlWhereInput | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
