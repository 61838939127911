import { gql } from '@apollo/client';

export const GET_VERTEX_AI_RECIPES = gql`
  query GetVertexAIRecipes(
    $excludedIngredients: [String!]
    $recipeNumber: Int!
    $slideshowSubject: String!
    $brandKey: BrandKey!
  ) {
    getVertexAIRecipes(
      excludedIngredients: $excludedIngredients
      recipeNumber: $recipeNumber
      slideshowSubject: $slideshowSubject
      brandKey: $brandKey
    ) {
      recipesIds
      ingredients {
        name
        count
      }
    }
  }
`;
