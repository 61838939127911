import { ALLOWED_CATEGORY_STATUES } from '../../../../constants';

export const INITIAL_QUERY_PARAMS = {
  filterFavorites: 'false',
  filterStatus: ALLOWED_CATEGORY_STATUES.map((status) => status.id),
};

export const FILTER_FAVORITE_TITLE = 'Afficher les favoris';
export const FILTER_TITLE = 'Filtrer la liste';
export const RESET_FILTER_TITLE = 'Réinitialiser';
