import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(8),
    },
    title: {
      margin: theme.spacing(0, 4, 0, 2),
    },
    tableWrapper: {
      width: '100vw',
      height: `calc(100vh - ${theme.spacing(8)})`,
    },
    fab: {
      alignSelf: 'flex-end',
    },
    actions: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
  }),
);
