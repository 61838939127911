import {
  GetArticle_article_articleEvents,
  GetArticle_article_articleGuides,
} from '../../../__generated__/queries-web';
import { Default } from '../../../components/Tags/TagItems/Default';
import { Events } from '../../../components/Tags/TagItems/Events';
import { Guides } from '../../../components/Tags/TagItems/Guides';
import { Programs } from '../../../components/Tags/TagItems/Programs';
import { TVCollections } from '../../../components/Tags/TagItems/TVCollections';
import { brandsOptions } from '../../../utils/brands';
import { ArticleState } from '../reducer';

interface GetTagsConfiguration
  extends Pick<ArticleState, 'articleTags' | 'brandKey'> {
  articleEvents?: GetArticle_article_articleEvents[];
  articleGuides?: GetArticle_article_articleGuides[];
}

export const getTagsConfiguration = ({
  articleEvents,
  articleTags,
  articleGuides,
  brandKey,
}: GetTagsConfiguration) => {
  const eventsTags = articleEvents?.map((articleEvent) => {
    return { ...articleEvent.category, type: 'Event' };
  });
  const otherTags = articleTags.map((articleTag) => articleTag.tag);
  const guidesTag = articleGuides?.map(
    (articleGuide) => articleGuide.category || articleGuide,
  );

  return [
    {
      component: Default,
      id: 'Default',
      props: {
        field: 'articleTags',
        isAllowedToCreateTag: true,
        tags: otherTags,
      },
    },
    ...(!brandsOptions[brandKey].isCerise && eventsTags
      ? [
          {
            component: Events,
            id: 'Events',
            props: { field: 'articleEvents', tags: eventsTags },
          },
        ]
      : []),
    ...(guidesTag
      ? [
          {
            component: Guides,
            id: 'Guides',
            props: { field: 'articleGuides', tags: guidesTag },
          },
        ]
      : []),
    ...(brandsOptions[brandKey].mediaProgramsAllowed
      ? [
          {
            component: Programs,
            id: 'Programs',
            props: { field: 'articleTags', tags: otherTags },
          },
          {
            component: TVCollections,
            id: 'TVCollections',
            props: { field: 'articleTags', tags: otherTags },
          },
        ]
      : []),
  ];
};
