import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { ErrorTypeEnum } from '@prismamedia/draftjs-prolexis';

export const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      marginTop: `calc(8 * ${theme.spacing(1)})`,
      overflow: 'hidden',
    },
    main: { display: 'flex', flexDirection: 'column', flexGrow: 1 },
    correctionAside: {
      display: 'flex',
      height: `calc(25 * ${theme.spacing(1)})`,
      minHeight: `calc(25 * ${theme.spacing(1)})`,
      justifyContent: 'space-around',
      padding: `calc(2 * ${theme.spacing(1)})`,
      margin: `calc(2 * ${theme.spacing(1)})`,
      '& div:first-child': {
        border: 'none',
      },
    },
    correctionList: {
      overflowY: 'scroll',
      width: `calc(30 * ${theme.spacing(1)})`,
    },
    title: { flexGrow: 1 },
    actionsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    caseWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      borderLeft: `2px ${theme.palette.grey['300']} solid`,
      flex: 1,
      alignItems: 'center',
    },
    correctionInputWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: `calc(30 * ${theme.spacing(1)})`,
    },
    draftWrapper: {
      height: `calc(100vh - (32 * ${theme.spacing(1)}px))`,
      padding: `calc(5 * ${theme.spacing(1)})`,
      marginLeft: `calc(2 * ${theme.spacing(1)})`,
      marginRight: `calc(2 * ${theme.spacing(1)})`,
      overflowY: 'scroll',
    },
    drawerPaper: {
      position: 'relative',
      minWidth: `calc(30 * ${theme.spacing(1)})`,
      width: `calc(30 * ${theme.spacing(1)})`,
      height: `calc(100vh - (8 * ${theme.spacing(1)}))`,
    },
    inputProps: {
      color: 'green',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    articlePartSelector: {
      float: 'right',
      zIndex: theme.zIndex.drawer + 1,
    },
    [ErrorTypeEnum.orthographe]: {
      color: 'red',
    },
    [ErrorTypeEnum.contexte]: {
      color: 'cyan',
    },
    [ErrorTypeEnum.frequence]: {
      color: 'magenta',
    },
    [ErrorTypeEnum.grammaire]: {
      color: 'green',
    },
    [ErrorTypeEnum.typography]: {
      color: 'violet',
    },
    [ErrorTypeEnum.presse]: {
      color: 'grey',
    },
  });
