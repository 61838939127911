import {
  InnerMediaBlock,
  InnerMediaBlockProps,
  PluginButtonProps,
} from '@prismamedia/one-components';
import { FetchSources } from '@prismamedia/one-components/build/types/MultimediaManager/MultimediaManager/SourceSelect';
import { FC } from 'react';
import { MultimediaPluginButton } from './MultimediaPluginButton';

interface MultimediaPluginProps {
  fetchSources?: FetchSources;
  withErrors?: boolean;
}

interface MultimediaPlugin {
  (props: MultimediaPluginProps): {
    type: 'media';
    buttonComponent: FC<PluginButtonProps>;
    blockComponent: FC<InnerMediaBlockProps>;
  };
}

export const MultimediaPlugin: MultimediaPlugin = (props) => ({
  type: 'media',
  buttonComponent: MultimediaPluginButton,
  blockComponent: InnerMediaBlock,
  options: {
    fetchSources: props.fetchSources,
    fieldsRequired: true,
    withErrors: props.withErrors,
  },
});
