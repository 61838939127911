import { gql, useApolloClient } from '@apollo/client';
import {
  GetBrandCategories,
  GetBrandCategoriesVariables,
} from '../../../__generated__/queries-web';

export const BRAND_CATEGORY_FRAGMENT = gql`
  fragment BrandCategoryFragment on Category {
    id
    title
    level
    status
  }
`;

export const GET_BRAND_CATEGORIES = gql`
  query GetBrandCategories(
    $brandKey: BrandKey!
    $format: CategoryFormat = Category
    $status: CategoryStatus
  ) {
    categories(
      where: { brandKey: $brandKey, level: 2, format: $format, status: $status }
      first: 150
    ) {
      ...BrandCategoryFragment
      children(first: 150, where: { status: $status }) {
        ...BrandCategoryFragment
        children(first: 150, where: { status: $status }) {
          ...BrandCategoryFragment
          children(first: 150, where: { status: $status }) {
            ...BrandCategoryFragment
          }
        }
      }
    }
  }
  ${BRAND_CATEGORY_FRAGMENT}
`;

export const GET_BRAND_FILTER_CATEGORIES = gql`
  query GetBrandFilterCategories(
    $brandKey: BrandKey!
    $format: CategoryFormat = Category
    $status: CategoryStatus
    $title_not_in: [String!]
  ) {
    categories(
      where: { brandKey: $brandKey, level: 2, format: $format, status: $status }
      first: 150
    ) {
      ...BrandCategoryFragment
      children(first: 150, where: { status: $status }) {
        ...BrandCategoryFragment
        children(
          first: 150
          where: { status: $status, title_not_in: $title_not_in }
        ) {
          ...BrandCategoryFragment
          children(first: 150, where: { status: $status }) {
            ...BrandCategoryFragment
          }
        }
      }
    }
  }
  ${BRAND_CATEGORY_FRAGMENT}
`;

export const useBrandCategoriesGetter = () => {
  const client = useApolloClient();

  return (variables: GetBrandCategoriesVariables) =>
    client.query<GetBrandCategories, GetBrandCategoriesVariables>({
      query: GET_BRAND_FILTER_CATEGORIES,
      variables,
    });
};
