import {
  AutocompleteItem,
  ChipsInput,
  isDefined,
} from '@prismamedia/one-components';
import { FC, useEffect, useState } from 'react';
import {
  useSearchEnabledTagsGetter,
  useTagGetter,
} from '../../../apollo/queries/getEnabledTags.web.graphql';
import { ALLOWED_TAG_PROVIDERS } from '../../../components/Tags/TagItems/Default/constants';
import { useAllowedTags } from '../../../components/Tags/TagItems/Default/hooks';
import { isBrandkeyCanHavePersonTag } from '../../../components/Tags/TagItems/Default/utils';
import { getTagsAutoCompleteList } from '../../../components/Tags/utils';
import { useGlobalBrandKey } from '../../../utils/globalState';

interface TagsSelectProps {
  label: string;
  className?: string;
  value?: string[];
  onChange?: (value?: string[]) => void;
}

export const TagsSelect: FC<TagsSelectProps> = ({
  label,
  className,
  value,
  onChange,
}) => {
  const [brandKey] = useGlobalBrandKey();
  const [internalValue, setInternalValue] = useState<AutocompleteItem[]>([]);
  const searchTags = useSearchEnabledTagsGetter();
  const getTag = useTagGetter();
  const newAllowedTagProviders = useAllowedTags(ALLOWED_TAG_PROVIDERS);

  const fetchList = async (search: string) =>
    brandKey &&
    searchTags({
      providers: newAllowedTagProviders.filter((tagProviderName) =>
        isBrandkeyCanHavePersonTag(tagProviderName, brandKey),
      ),
      brandKey,
      search,
    }).then((sortedTagList = []) => getTagsAutoCompleteList(sortedTagList, []));

  useEffect(() => {
    if (!value) {
      setInternalValue([]);
      return;
    }
    Promise.all(value.map((id) => getTag({ where: { id } }))).then((res) =>
      setInternalValue(
        res
          .map((data) => data.tag)
          .filter(isDefined)
          .map((tag) => ({
            id: tag.id,
            label: tag.title,
          })),
      ),
    );
  }, [value, getTag]);

  return (
    <ChipsInput
      variant="outlined"
      disabled={!brandKey}
      label={label}
      value={internalValue}
      className={className}
      onChange={(val) =>
        onChange?.(val?.length ? val.map(({ id }) => id) : undefined)
      }
      autocomplete={{ fetchList, mandatoryInput: true }} // mandatoryInput because API fails if search is ''
    />
  );
};
