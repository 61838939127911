import { EditAppBarTitle } from '@prismamedia/one-components';
import { format as formatDate } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { useContext, useMemo } from 'react';
import { useGlobalBrandKey } from '../../../../../../../utils/globalState';
import { CategoriesContext } from '../../../../../categories.context';
import { CREATED_AT, UPDATED_AT } from '../../../../../constants';
import { FormContext } from '../../../../../form.context';
import { isPageEditView } from '../../../../../utils';

export const Title = () => {
  const [brandKey] = useGlobalBrandKey();
  const { isCategoriesLoading } = useContext(CategoriesContext);
  const { form } = useContext(FormContext);

  const subTitle = useMemo(() => {
    const createdDate = form?.createdAt
      ? `${CREATED_AT} ${formatDate(
          new Date(form.createdAt),
          'dd/MM/yyyy à HH:mm',
          { locale: fr },
        )}`
      : null;

    const updatedAt = form?.editedAt
      ? `${UPDATED_AT} ${formatDate(
          new Date(form.editedAt),
          'dd/MM/yyyy à HH:mm',
          { locale: fr },
        )}`
      : null;

    if (!updatedAt && !createdDate) {
      return null;
    }

    return `${updatedAt} ${createdDate ? `- ${createdDate}` : ''}.`;
  }, [form]);

  return (
    <EditAppBarTitle
      title={form.title}
      brandKey={brandKey}
      subTitle={subTitle}
      loading={isCategoriesLoading && isPageEditView()}
    />
  );
};
