import { SearchParams } from '@prismamedia/one-components';
import {
  add,
  differenceInDays,
  differenceInMonths,
  format,
  formatISO,
  isSameDay,
  lastDayOfMonth,
  set,
  setHours,
} from 'date-fns';
import {
  ArticleFormat,
  ArticleStatus,
  BrandKey,
} from '../../../__generated__/queries-web';
import { getAllowedStatus, statuses } from '../../../utils/statuses';
import { Filters } from '../FiltersDrawer/utils';
import { getWhere } from '../utils';

export enum IntervalId {
  HOUR = 'HOUR',
  DAY = 'DAY',
  MONTH = 'MONTH',
}

interface Interval {
  id: IntervalId;
  format: string;
}

export const intervals: Record<IntervalId, Interval> = {
  [IntervalId.HOUR]: { id: IntervalId.HOUR, format: "H'h'" },
  [IntervalId.DAY]: { id: IntervalId.DAY, format: 'dd/MM' },
  [IntervalId.MONTH]: { id: IntervalId.MONTH, format: 'MMM yyyy' },
};

const fetchCount = async (
  status: ArticleStatus,
  date: Date,
  interval: Interval,
  brandKey: BrandKey | undefined,
  filters: Filters,
  searchParams: SearchParams,
  getArticleCount: any,
  getRecipeCount: any,
) => {
  const { articleWhere, recipeWhere } = getWhere(
    {
      ...filters,
      status: [status],
      startDate: date,
      endDate: date,
    },
    searchParams,
    brandKey,
  );

  let adjustedDates = {};

  if (interval.id === IntervalId.HOUR) {
    const startDate = set(date, { minutes: 0, seconds: 0 });
    const endDate = set(date, { minutes: 59, seconds: 59 });

    adjustedDates = {
      publishedAt_gte: startDate && formatISO(startDate),
      publishedAt_lte: endDate && formatISO(endDate),
    };
  }

  if (interval.id === IntervalId.MONTH) {
    const startDate = set(date, { date: 0, hours: 0, minutes: 0, seconds: 0 });
    const endDate = set(lastDayOfMonth(date), {
      hours: 23,
      minutes: 59,
      seconds: 59,
    });

    adjustedDates = {
      publishedAt_gte: startDate && formatISO(startDate),
      publishedAt_lte: endDate && formatISO(endDate),
    };
  }

  const [{ data: articleData }, { data: recipeData }] = await Promise.all([
    getArticleCount(
      { where: { ...articleWhere, ...adjustedDates } },
      false,
      'cache-first',
    ),
    getRecipeCount(
      { where: { ...recipeWhere, ...adjustedDates } },
      false,
      'cache-first',
    ),
  ]);

  return (
    (articleData?.searchArticleCount || 0) + (recipeData?.recipeCount || 0)
  );
};

export const getSelectedStatus = (filters: Filters) =>
  (filters.status?.length ? filters.status : getAllowedStatus()).map(
    (status) => ({
      id: status,
      label: statuses(ArticleFormat.Rich)[status].label,
      color: statuses(ArticleFormat.Rich)[status].color,
    }),
  );

const getGraphDates = (startDate: Date, endDate: Date, interval: Interval) => {
  if (interval.id === IntervalId.HOUR) {
    return Array.from(Array(24)).map((_, i) =>
      add(setHours(startDate, 0), { hours: i }),
    );
  }

  if (interval.id === IntervalId.MONTH) {
    return Array.from(
      Array(differenceInMonths(endDate, startDate) + 1),
    ).map((_, i) => add(startDate, { months: i }));
  }

  return Array.from(
    Array(differenceInDays(endDate, startDate) + 1),
  ).map((_, i) => add(startDate, { days: i }));
};

export const fetchGraphData = async (
  brandKey: BrandKey | undefined,
  filters: Filters,
  searchParams: SearchParams,
  getArticleCount: any,
  getRecipeCount: any,
) => {
  const startDate =
    filters.startDate ||
    (filters.endDate
      ? add(filters.endDate, { days: -10 })
      : add(new Date(), { days: -5 }));
  const endDate = filters.endDate || add(startDate, { days: 10 });

  let interval = intervals[IntervalId.DAY];

  if (isSameDay(startDate, endDate)) {
    interval = intervals[IntervalId.HOUR];
  }

  if (differenceInMonths(endDate, startDate) >= 5) {
    interval = intervals[IntervalId.MONTH];
  }

  const selectedStatus = getSelectedStatus(filters);

  return Promise.all(
    getGraphDates(startDate, endDate, interval).map((date) =>
      Promise.all(
        selectedStatus.map((status) =>
          fetchCount(
            status.id,
            date,
            interval,
            brandKey,
            filters,
            searchParams,
            getArticleCount,
            getRecipeCount,
          ),
        ),
      ).then((counts) =>
        counts.reduce(
          (prev, count, i) => ({ ...prev, [selectedStatus[i].label]: count }),
          {
            name: format(date, interval.format),
          },
        ),
      ),
    ),
  );
};
