import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  section: {
    padding: theme.spacing(2, 3, 0),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  input: {
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  flexInput: {
    width: `calc(50% - ${theme.spacing(1.5)})`,
    minWidth: 200,
  },
}));
