import { gql, useQuery } from '@apollo/client';
import {
  GetAssignmentsLinkToTopic,
  GetAssignmentsLinkToTopicVariables,
} from '../../../../__generated__/queries-topic';

export const GET_ASSIGNMENTS_LINK_TO_TOPIC = gql`
  query GetAssignmentsLinkToTopic(
    $where: AssignmentFilterInput
    $orderBy: [AssignmentOrderingInput!]
    $offset: UnsignedInt
    $limit: UnsignedInt!
  ) {
    assignments(
      where: $where
      orderBy: $orderBy
      skip: $offset
      first: $limit
    ) {
      id
      rawArticle {
        id
        lastVersion {
          title
        }
      }
      printHeading {
        title
        printIssue {
          title
          printPublication {
            title
          }
        }
      }
    }
  }
`;

export const useGetAssignments = (
  variables: GetAssignmentsLinkToTopicVariables,
  skip?: boolean,
) =>
  useQuery<GetAssignmentsLinkToTopic, GetAssignmentsLinkToTopicVariables>(
    GET_ASSIGNMENTS_LINK_TO_TOPIC,
    {
      variables,
      skip,
    },
  );
