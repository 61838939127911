import { EntityInputs } from '@prismamedia/one-components';
import icons from 'megadraft/lib/icons';
import React, { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const ActionBtn = ({
  onclick,
  actionIcon,
}: {
  onclick: () => void;
  actionIcon: JSX.Element;
}) => (
  <span className="toolbar__item" style={{ verticalAlign: 'bottom' }}>
    <button
      onClick={onclick}
      type="button"
      className="toolbar__button toolbar__input-button"
    >
      {actionIcon}
    </button>
  </span>
);

const LinkInputWithRouter: FC<
  { url: string } & Pick<EntityInputs, 'cancelEntity' | 'removeEntity'> &
    RouteComponentProps
> = ({ url, removeEntity, cancelEntity }) => {
  return (
    <div>
      <div style={{ whiteSpace: 'nowrap' }}>
        <input
          type="text"
          className="toolbar__input"
          value={url || ''}
          disabled
        />
        <ActionBtn onclick={removeEntity} actionIcon={<icons.UnlinkIcon />} />
        <ActionBtn onclick={cancelEntity} actionIcon={<icons.CloseIcon />} />
      </div>
    </div>
  );
};

export const LinkInput = withRouter(LinkInputWithRouter);
