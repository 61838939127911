import {
  AppName,
  HomeAppBar,
  PageWithDrawer,
  SearchBar,
  SearchParams,
  useOnMount,
  useOnScreen,
} from '@prismamedia/one-components';
import { isValid, parseISO } from 'date-fns';
import { FC, useEffect, useRef, useState } from 'react';
import { JsonParam, StringParam, useQueryParam } from 'use-query-params';
import { OneDrawerMenu } from '../../components/AppBar';
import { BrandSwitch } from '../../components/BrandSwitch';
import { auth } from '../../utils/auth';
import { useGlobalBrandKey } from '../../utils/globalState';
import { BrandKey } from '../../__generated__/queries-web';
import { FiltersDrawer } from './FiltersDrawer';
import {
  Filters,
  filtersAreDefault,
  getDefaultFilters,
} from './FiltersDrawer/utils';
import { Graph } from './Graph';
import { useStyles } from './styles';
import { Table } from './Table';

export const DashboardPage: FC = () => {
  const [brandKey, setBrandKey] = useGlobalBrandKey();
  const [searchParams, setSearchParams] = useState<SearchParams>({});
  const [filters, setFilters] = useState<Filters>(getDefaultFilters());
  const [filtersUrl, setFiltersUrl] = useQueryParam('filters', JsonParam);
  const [brandKeyUrl, setBrandKeyUrl] = useQueryParam('brand', StringParam);
  const [searchUrl, setSearchUrl] = useQueryParam('search', StringParam);
  const graphRef = useRef(null);
  const graphIsOnScreen = useOnScreen(graphRef);
  const classes = useStyles({ disableScroll: !graphIsOnScreen });
  const [loadingUrlParams, setLoadingUrlParams] = useState(true);

  useOnMount(() => {
    const startDate = parseISO(filtersUrl?.startDate);
    const endDate = parseISO(filtersUrl?.endDate);

    filtersUrl &&
      setFilters({
        ...filtersUrl,
        startDate: isValid(startDate) ? startDate : undefined,
        endDate: isValid(endDate) ? endDate : undefined,
      } as Filters);
    brandKeyUrl && setBrandKey(brandKeyUrl as BrandKey);
    searchUrl && setSearchParams((prev) => ({ ...prev, search: searchUrl }));
    setTimeout(() => setLoadingUrlParams(false));
  });

  useEffect(() => {
    setBrandKeyUrl(brandKey, 'replaceIn');
  }, [brandKey, setBrandKeyUrl]);

  useEffect(() => {
    setSearchUrl(searchParams.search, 'replaceIn');
  }, [searchParams.search, setSearchUrl]);

  useEffect(() => {
    setFiltersUrl(
      filtersAreDefault(filters) ? undefined : filters,
      'replaceIn',
    );
  }, [filters, setFiltersUrl]);

  return (
    <>
      <HomeAppBar
        appDrawerMenu={
          <div className={classes.menuWrapper}>
            <OneDrawerMenu app={AppName.Dashboard} />
          </div>
        }
        brandMenu={
          <BrandSwitch
            brandKey={brandKey}
            setBrandKey={(brand) => {
              setBrandKey(brand);
              setFilters((prev) => ({
                ...prev,
                tagIds: undefined,
                sourceIds: undefined,
                categoryIds: undefined,
                qualifierIds: undefined,
              }));
            }}
          />
        }
        currentUser={auth.user}
        disconnectUser={auth.logout}
        searchBar={
          <SearchBar
            placeholder="Rechercher"
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        }
      />
      <PageWithDrawer
        fullWidth
        leftDrawer={
          <FiltersDrawer
            filters={filters}
            setFilters={setFilters}
            searchParams={searchParams}
          />
        }
        className={classes.wrapper}
      >
        <Graph
          ref={graphRef}
          filters={filters}
          searchParams={searchParams}
          loadingUrlParams={loadingUrlParams}
        />
        {!loadingUrlParams && (
          <Table
            disableScroll={graphIsOnScreen}
            filters={filters}
            searchParams={searchParams}
          />
        )}
      </PageWithDrawer>
    </>
  );
};
