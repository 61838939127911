import { TextField } from '@mui/material';
import React, { ChangeEvent, FC } from 'react';
import { useStyles } from './styles';
import { AuthorContext } from './types';

interface InputFieldProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  property: keyof AuthorContext;
  label: string;
  defaultValue: string;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
}

export const InputField: FC<InputFieldProps> = ({
  property,
  onChange,
  label,
  defaultValue,
  disabled = false,
  multiline,
  rows,
}) => {
  const classes = useStyles();
  return (
    <TextField
      classes={{ root: classes.inputRoot }}
      data-testid={`input-${property}`}
      defaultValue={defaultValue}
      disabled={disabled}
      label={label}
      onChange={onChange}
      variant="standard"
      multiline={multiline}
      rows={rows}
    />
  );
};
