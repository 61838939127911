import {
  areImagesValid,
  doMediasHaveSources,
  isImage,
  OneMedia,
} from '@prismamedia/one-components';
import { isValid, differenceInMonths } from 'date-fns';
import { isEmpty } from 'lodash';
import * as R from 'ramda';
import { getFirstImage, getFirstVideo } from '../../../utils/media';
import { isArticleVideo } from '../../../utils/url';
import {
  ArticleFormat,
  ArticleStatus,
  GetArticle_article,
} from '../../../__generated__/queries-web';
import { ArticleState } from '../reducer';

interface ValidateArticleType {
  isValid: boolean;
  errorMessage: string;
}

const isVideo = R.propEq('format', ArticleFormat.Video);
const isDraft = R.propEq('status', ArticleStatus.Draft);
const isReady = R.propEq('status', ArticleStatus.Ready);
const isDeleted = R.propEq('status', ArticleStatus.Deleted);
const isOptional = (article: ArticleState) =>
  [
    isVideo(article),
    isDraft(article),
    isReady(article),
    isDeleted(article),
  ].some(Boolean);

const isOlder = (article: ArticleState) => {
  if (!article.publishedAt) {
    return false;
  }
  const today = new Date();
  const articleDate = Date.parse(article.publishedAt);
  const result = differenceInMonths(today, articleDate);
  return result >= 6;
};

const isImageMedia = ({ type, data }: any) =>
  type === 'atomic' && data?.iframely?.meta?.site === 'PMD_BONE_Image';

export const validateArticle = (
  article: ArticleState,
  prevArticleVersion: GetArticle_article | null,
): ValidateArticleType => {
  const mediaInsideDraftBody =
    (!isEmpty(article.body) &&
      article.body?.blocks
        .filter(isImageMedia)
        .map(({ data }) => data as OneMedia)) ||
    [];
  const allMedias = [...(article.medias || []), ...mediaInsideDraftBody];
  const allImageMedias = allMedias.filter(isImage);

  if (article.title === null || article.title === '') {
    return {
      isValid: false,
      errorMessage:
        'Un article doit comporter un titre pour pouvoir être enregistré',
    };
  }

  if (article.publishedAt) {
    if (!isValid(new Date(article.publishedAt))) {
      return {
        isValid: false,
        errorMessage: 'La date de publication est incorrecte',
      };
    }
  }
  if (
    [ArticleStatus.Published, ArticleStatus.Scheduled].includes(article.status)
  ) {
    if (!isArticleVideo(window.location.href) && !article.articleTags.length) {
      return {
        isValid: false,
        errorMessage:
          "Veuillez renseigner au moins un tag pour pouvoir publier ou programmer l'article",
      };
    }

    if (article.articleTags.length > 20) {
      return {
        isValid: false,
        errorMessage:
          'Veuillez renseigner un nombre de tags inférieur ou égal à 20',
      };
    }

    if (
      !getFirstImage(article.medias) &&
      article.format === ArticleFormat.Rich
    ) {
      return {
        isValid: false,
        errorMessage: 'Un article doit avoir une image pour être publié',
      };
    }
    if (
      article.format === ArticleFormat.Rich &&
      article.articleCategories.length === 0
    ) {
      return {
        isValid: false,
        errorMessage: 'Un article doit avoir une catégorie pour être publié',
      };
    }
    if (
      article.format === ArticleFormat.Video &&
      article.articleChannels.length === 0 &&
      article.brandKey === 'TEL'
    ) {
      return {
        isValid: false,
        errorMessage:
          'Une vidéo doit ête liée à un évènement pour être publiée',
      };
    }
  }

  if (article.format === ArticleFormat.Video) {
    if (!getFirstVideo(article.medias)) {
      return {
        isValid: false,
        errorMessage: 'Un article vidéo doit avoir une vidéo',
      };
    }
  }

  if (!isOlder(article)) {
    if (!isOptional(article) && !areImagesValid(allImageMedias)) {
      return {
        isValid: false,
        errorMessage: 'Chaque image doit avoir un crédit renseigné',
      };
    }

    if (!isOptional(article) && !doMediasHaveSources(allImageMedias)) {
      return {
        isValid: false,
        errorMessage: 'Chaque image doit avoir une source renseignée',
      };
    }
  }

  if (prevArticleVersion) {
    // if slug contains any character than 0..9 , a..z, -
    const slugRegex = /^([a-z0-9]+(-[a-z0-9]+)*)$/;

    const originalSlugIsMalformed = !slugRegex.test(
      prevArticleVersion.slug || '',
    );
    const slugIsMalformed = !slugRegex.test(article.slug || '');
    const slugHasBeenModified =
      Boolean(prevArticleVersion.slug) !== Boolean(article.slug);

    if (slugHasBeenModified && originalSlugIsMalformed) {
      return {
        isValid: false,
        errorMessage: 'Le slug de cet article ne peut pas être modifié.',
      };
    }

    if (article.slug && slugHasBeenModified && slugIsMalformed) {
      return {
        isValid: false,
        errorMessage:
          'Un slug ne peut contenir que des aphabets en minuscule , - et chiffres',
      };
    }
  }

  return {
    isValid: true,
    errorMessage: '',
  };
};
