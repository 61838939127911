import { gql } from '@apollo/client';

export const SEARCH_MEDIA_ARTICLE_COUNT = gql`
  query SearchMediaArticleCount(
    $mediaElements: [String]!
    $mediaSite: String!
  ) {
    searchMediaArticleCount(
      mediaElements: $mediaElements
      mediaSite: $mediaSite
    ) {
      mediaElement
      count
    }
  }
`;
