import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    wrapperTags: {
      width: '100%',
    },
    content: {
      display: 'grid',
      gridRowGap: 36,
      gridTemplateColumns: '100%',
    },
    textField: {
      position: 'relative',
    },
    formGroup: {
      position: 'relative',
    },
    formControl: {
      alignSelf: 'flex-start',
    },
    mediaInput: {
      marginBottom: theme.spacing(3),
      width: '100%',
    },
    draft: {
      width: '100%',
    },
    divider: {
      backgroundColor: 'rgba(0, 0, 0, 0.42)',
      marginBottom: '3rem',
      width: '100%',
    },
  }),
);
