import { gql, useQuery } from '@apollo/client';
import { useFetch } from '../../../utils/useFetch';
import {
  BrandKey,
  GetSource,
  GetSourceCount,
  GetSourceCountVariables,
  GetSources,
  GetSourcesVariables,
  GetSourceVariables,
  SourceOrderByInput,
} from '../../../__generated__/queries-web';

const GET_SOURCES = gql`
  query GetSources(
    $first: Int!
    $where: SourceWhereInput
    $orderBy: [SourceOrderByInput!]
  ) {
    sources(first: $first, where: $where, orderBy: $orderBy) {
      id
      title
      brandKey
      status
    }
  }
`;

export const useGetSources = (brand: BrandKey | null, skip?: boolean) =>
  useQuery<GetSources, GetSourcesVariables>(GET_SOURCES, {
    variables: {
      orderBy: [SourceOrderByInput.title_ASC],
      first: 100,
      where: {
        brandKey: brand,
      },
    },
    skip,
  });

export const useSourcesGetter = () =>
  useFetch<GetSources, GetSourcesVariables>(GET_SOURCES);

const GET_SOURCE = gql`
  query GetSource($where: SourceWhereUniqueInput!) {
    source(where: $where) {
      id
      title
    }
  }
`;

export const useSourceGetter = () =>
  useFetch<GetSource, GetSourceVariables>(GET_SOURCE);

const GET_SOURCE_COUNT = gql`
  query GetSourceCount($where: SourceWhereInput) {
    sourceCount(where: $where)
  }
`;

export const useGetSourceCount = (
  variables: GetSourceCountVariables,
  skip?: boolean,
) =>
  useQuery<GetSourceCount, GetSourceCountVariables>(GET_SOURCE_COUNT, {
    variables,
    skip,
  });
