import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  highLight: {
    fontWeight: 500,
  },
  icon: {
    fill: theme.palette.grey[500],
    marginRight: theme.spacing(2),
  },
  wrapper: {
    '& > button': {
      paddingBottom: 0,
      paddingTop: 0,
    },

    '& > span > svg': {
      fill: theme.palette.grey[400],
      margin: theme.spacing(0, 1),
      position: 'relative',
      top: theme.spacing(0.5),
      zoom: 0.5,
    },
  },
}));
