import type { FC } from 'react';
import React, { Fragment } from 'react';
import { TabType } from '../../../../../../__generated__/queries-web';
import type { SectionForm } from './types';
import { Board } from './Board';
import { People } from './People';
import { Text } from './Text';
import { Url } from './Url';

export const SectionFormRenderer: FC<SectionForm> = (sectionProps) => {
  const renderSwitch = () => {
    switch (sectionProps.type) {
      case TabType.Board: {
        return <Board {...sectionProps} />;
      }

      case TabType.People: {
        return <People {...sectionProps} />;
      }

      case TabType.Url: {
        return <Url {...sectionProps} />;
      }

      case TabType.Text: {
        return <Text {...sectionProps} />;
      }

      default:
        return <Fragment />;
    }
  };

  return renderSwitch();
};
