import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  itemDate: {
    color: 'rgba(0,0,0,0.72)',
    opacity: 0.7,
  },
  itemTools: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '4px',
  },
  dateField: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '10px',
    },
  },
}));
