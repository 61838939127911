import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import {
  CreateSubjectForDuplication,
  CreateSubjectForDuplicationVariables,
  GetAssignmentsForDuplication,
  GetAssignmentsForDuplicationVariables,
  GetPrintHeadingsForDuplication,
  GetPrintHeadingsForDuplicationVariables,
  GetPrintIssuesForDuplication,
  GetPrintIssuesForDuplicationVariables,
  GetPrintPublicationsForDuplication,
  GetPrintPublicationsForDuplicationVariables,
  GetUnitsForDuplication,
  GetUnitsForDuplicationVariables,
  UpdateAssignmentForDuplication,
  UpdateAssignmentForDuplicationVariables,
} from '../../../__generated__/queries-topic';
import { useGetter } from '../../../apollo/useGetter';

export const GET_ASSIGNMENTS_FOR_DUPLICATION = gql`
  query GetAssignmentsForDuplication(
    $where: AssignmentFilterInput
    $orderBy: [AssignmentOrderingInput!]
    $skip: UnsignedInt
    $first: UnsignedInt!
  ) {
    assignments(where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
      id
      subject {
        id
        title
      }
      rawArticle {
        id
        lastVersion {
          id
        }
      }
      printHeading {
        title
        printIssue {
          title
          printPublication {
            title
          }
        }
      }
    }
  }
`;

export const useAssignmentsGetter = () =>
  useGetter<
    GetAssignmentsForDuplication,
    GetAssignmentsForDuplicationVariables
  >(GET_ASSIGNMENTS_FOR_DUPLICATION);

export const GET_PRINT_PUBLICATIONS_FOR_DUPLICATION = gql`
  query GetPrintPublicationsForDuplication(
    $where: PrintPublicationFilterInput
    $orderBy: [PrintPublicationOrderingInput!]
    $skip: UnsignedInt
    $first: UnsignedInt!
  ) {
    printPublications(
      where: $where
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      id
      title
    }
  }
`;

export const useGetPrintPublications = (
  variables: GetPrintPublicationsForDuplicationVariables,
) =>
  useQuery<
    GetPrintPublicationsForDuplication,
    GetPrintPublicationsForDuplicationVariables
  >(GET_PRINT_PUBLICATIONS_FOR_DUPLICATION, { variables });

export const GET_PRINT_ISSUES_FOR_DUPLICATION = gql`
  query GetPrintIssuesForDuplication(
    $where: PrintIssueFilterInput
    $orderBy: [PrintIssueOrderingInput!]
    $skip: UnsignedInt
    $first: UnsignedInt!
  ) {
    printIssues(where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
      id
      title
    }
  }
`;

export const useGetPrintIssues = (
  variables: GetPrintIssuesForDuplicationVariables,
) =>
  useQuery<GetPrintIssuesForDuplication, GetPrintIssuesForDuplicationVariables>(
    GET_PRINT_ISSUES_FOR_DUPLICATION,
    { variables },
  );

export const GET_PRINT_HEADINGS_FOR_DUPLICATION = gql`
  query GetPrintHeadingsForDuplication(
    $where: PrintHeadingFilterInput
    $orderBy: [PrintHeadingOrderingInput!]
    $skip: UnsignedInt
    $first: UnsignedInt!
  ) {
    printHeadings(
      where: $where
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      id
      title
    }
  }
`;

export const useGetPrintHeadings = (
  variables: GetPrintHeadingsForDuplicationVariables,
) =>
  useQuery<
    GetPrintHeadingsForDuplication,
    GetPrintHeadingsForDuplicationVariables
  >(GET_PRINT_HEADINGS_FOR_DUPLICATION, { variables });

export const GET_UNITS_FOR_DUPLICATION = gql`
  query GetUnitsForDuplication(
    $where: UnitFilterInput
    $orderBy: [UnitOrderingInput!]
    $skip: UnsignedInt
    $first: UnsignedInt!
  ) {
    units(where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
      id
    }
  }
`;

export const useUnitsGetter = () =>
  useGetter<GetUnitsForDuplication, GetUnitsForDuplicationVariables>(
    GET_UNITS_FOR_DUPLICATION,
  );

export const CREATE_SUBJECT_FOR_DUPLICATION = gql`
  mutation CreateSubjectForDuplication($data: SubjectCreationInput!) {
    createSubject(data: $data) {
      assignments(first: 1) {
        id
      }
    }
  }
`;

export const useCreateSubject = () => {
  const [createSubject] = useMutation<
    CreateSubjectForDuplication,
    CreateSubjectForDuplicationVariables
  >(CREATE_SUBJECT_FOR_DUPLICATION, {
    refetchQueries: [GET_ASSIGNMENTS_FOR_DUPLICATION],
  });

  return useCallback(
    (variables: CreateSubjectForDuplicationVariables) =>
      createSubject({ variables }),
    [createSubject],
  );
};

export const UPDATE_ASSIGNMENT_FOR_DUPLICATION = gql`
  mutation UpdateAssignmentForDuplication(
    $data: AssignmentUpdateInput!
    $where: AssignmentUniqueFilterInput!
  ) {
    updateAssignment(data: $data, where: $where) {
      id
    }
  }
`;

export const useUpdateAssignment = () => {
  const [updateAssignment] = useMutation<
    UpdateAssignmentForDuplication,
    UpdateAssignmentForDuplicationVariables
  >(UPDATE_ASSIGNMENT_FOR_DUPLICATION);

  return useCallback(
    (variables: UpdateAssignmentForDuplicationVariables) =>
      updateAssignment({ variables }),
    [updateAssignment],
  );
};
