import type { OneMedia } from '@prismamedia/one-components';
import type { RawDraftContentState } from 'draft-js';
import {
  ArticleStatus,
  GetArticle_article,
  GetArticle_article_articleTags,
  GetArticle_article_articleEvents,
  ArticleFormat,
} from '../../__generated__/queries-web';

interface ArticleUrl {
  path: string;
  domain: string;
}

export interface ArticleState
  extends Omit<GetArticle_article, 'lead' | 'body' | 'medias'> {
  lead: RawDraftContentState | null;
  body: RawDraftContentState | null;
  medias: OneMedia[] | null;
  articleChanged: boolean;
  newStatus: ArticleStatus | null;
  articleUrl: ArticleUrl | null;
  contextualizedUrl: string | null;
}

enum ArticleEditActionTypeEnum {
  CHANGE_STATUS = 'CHANGE_STATUS',
  INIT_ARTICLE_DATA = 'INIT_ARTICLE_DATA',
  INIT_BY_LOCALSTORAGE = 'INIT_BY_LOCALSTORAGE',
  SET_ARTICLE_TAGS = 'SET_ARTICLE_TAGS',
  UPDATE_FIELD = 'UPDATE_FIELD',
  UPDATE_PUBLISH_DATE_AND_STATUS = 'UPDATE_PUBLISH_DATE_AND_STATUS',
}

export interface ArticleEditAction {
  type: ArticleEditActionTypeEnum;
  data: any;
}

type ArticleEditActionsCreators = Record<
  string,
  { (payload: any): ArticleEditAction }
>;

export const initState = (articleData: GetArticle_article): ArticleState => ({
  ...articleData,
  lead: articleData.lead ? JSON.parse(articleData.lead) : null,
  body: articleData.body ? JSON.parse(articleData.body) : null,
  medias: articleData.medias ? JSON.parse(articleData.medias) : null,
  articleChanged: false,
  newStatus: null,
  articleUrl:
    articleData.articleUrls.length > 0 ? articleData.articleUrls[0].url : null,
  contextualizedUrl: articleData.contextualizedUrl,
  articleAuthors:
    articleData.format !== ArticleFormat.Video &&
    articleData.articleAuthors[0]?.author
      ? articleData.articleAuthors
      : [],
});

export interface SetArticleTagsActionPayload {
  field: 'articleEvents' | 'articleTags';
  value: (GetArticle_article_articleEvents | GetArticle_article_articleTags)[];
}

export const actions: ArticleEditActionsCreators = {
  changeStatus: ({ newStatus }: { newStatus: ArticleStatus }) => ({
    type: ArticleEditActionTypeEnum.CHANGE_STATUS,
    data: { newStatus },
  }),
  initArticleData: ({ articleData }: { articleData: GetArticle_article }) => ({
    type: ArticleEditActionTypeEnum.INIT_ARTICLE_DATA,
    data: { articleData },
  }),
  setArticleTags: ({ field, value }: SetArticleTagsActionPayload) => ({
    type: ArticleEditActionTypeEnum.SET_ARTICLE_TAGS,
    data: {
      field,
      value,
    },
  }),
  updateField: ({ field, value }: { field: string; value: any }) => ({
    type: ArticleEditActionTypeEnum.UPDATE_FIELD,
    data: {
      field,
      value,
      articleChanged: !(
        field === 'medias' ||
        field === 'lead' ||
        field === 'body'
      ),
    },
  }),
  updatePublishDateAndStatus: ({
    publishDate,
    status,
  }: {
    publishDate?: string;
    status: ArticleStatus;
  }) => ({
    type: ArticleEditActionTypeEnum.UPDATE_PUBLISH_DATE_AND_STATUS,
    data: { publishDate, status },
  }),
};

export const reducer = (
  state: ArticleState,
  action: ArticleEditAction,
): ArticleState => {
  switch (action.type) {
    case ArticleEditActionTypeEnum.INIT_ARTICLE_DATA: {
      return initState(action.data.articleData);
    }

    case ArticleEditActionTypeEnum.SET_ARTICLE_TAGS:
    case ArticleEditActionTypeEnum.UPDATE_FIELD:
      return {
        ...state,
        [action.data.field]: action.data.value,
        articleChanged: true,
      };

    case ArticleEditActionTypeEnum.CHANGE_STATUS: {
      return {
        ...state,
        newStatus: action.data.newStatus,
      };
    }

    case ArticleEditActionTypeEnum.UPDATE_PUBLISH_DATE_AND_STATUS: {
      return {
        ...state,
        status: action.data.status,
        publishedAt: action.data.publishDate,
        newStatus: null,
      };
    }

    default:
      return state;
  }
};
