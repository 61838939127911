import { gql } from '@apollo/client';

export const ORPHAN_PAGE_FRAGMENT = gql`
  fragment OrphanPageFragment on OrphanPage {
    id
    publishedAt
    editedAt
    status
    title
    metas
    isCMPEnabled
    isFullScreen
    brandKey
    uri
    __typename
  }
`;
