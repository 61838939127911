import type { TablePluginData, TableShape } from '../types';
import { TABLE_CONTENT_STATE_DATA_TYPE } from '../constants';
import { TableView } from './TableView';
import type { ContentBlock } from 'draft-js';
import { EditorState } from 'draft-js';

export interface TableBlockRendererProps extends TablePluginData<EditorState> {
  editor: HTMLDivElement | undefined;
  onChange: (editorState: EditorState) => void;
  shape: TableShape | null | undefined;
}
export const tableBlockRenderer = (props: TableBlockRendererProps) => (
  contentBlock: ContentBlock,
) => {
  const type = contentBlock.getType();

  if (type === TABLE_CONTENT_STATE_DATA_TYPE) {
    return {
      component: TableView,
      editable: false,
      props,
    };
  }

  return null;
};
