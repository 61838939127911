import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const PEOPLE_TAG_HEIGHT = 80;
export const PEOPLE_TAG_WIDTH = 325;

export const useStyles = makeStyles((theme) => ({
  leftDrawer: {
    paddingRight: 0,
  },
  speedDial: {
    bottom: theme.spacing(3),
    position: 'fixed',
    right: theme.spacing(4),
  },
  wrapper: {
    backgroundColor: '#fafafa',
  },
  wrapperField: {
    marginBottom: theme.spacing(3),
  },
  wrapperMedias: {
    margin: theme.spacing(2, 0, 2, 0),

    '& > div': {
      boxShadow: 'none',
      border: `solid 1px ${theme.palette.grey[100]}`,
      backgroundColor: theme.palette.grey[100],
      overflow: 'hidden',
      padding: 0,
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },

    '& > div > div > p': {
      fontSize: 18,
    },

    '& figcaption': {
      backgroundColor: theme.palette.grey[100],
      padding: theme.spacing(3, 4, 5, 4),
    },
  },
}));

interface PeopleStyleProps {
  hasTagsLength: boolean;
}
export const useTagsStyles = makeStyles<Theme, PeopleStyleProps>((theme) => ({
  searchBar: {
    flexBasis: '66%',
    backgroundColor: theme.palette.grey[100],
  },
  wrapperTags: ({ hasTagsLength }) => ({
    // Tags grid wrapper
    '& > section': {
      minHeight: PEOPLE_TAG_HEIGHT,
      padding: 0,

      ...(!hasTagsLength && {
        background: theme.palette.grey[100],
      }),
    },
  }),
}));
