import { Button, CircularProgress } from '@mui/material';
import {
  EditAppBar,
  NotificationTypeEnum,
  useNotification,
  EditAppBarTitle,
} from '@prismamedia/one-components';
import React, { FC, useState } from 'react';
import { OrphanPageSelector } from '../../../components/StatusSelector';
import { formatDate } from '../../../utils/dateUtils';
import { BrandKey, OrphanPageStatus } from '../../../__generated__/queries-web';
import {
  prepareUpsertVariable,
  useUpsertOrphanPage,
} from '../graphql/upsertOrphanPage.web.graphql';
import { OrphanEditor } from './OrphanEditor';
import { useStyles } from './styles';

interface OrphanEditProps {
  defaultState: EditorState;
}

export interface EditorState {
  id: string;
  status: OrphanPageStatus;
  title: string;
  url: string;
  metas: string[];
  content: string;
  isFullScreen: boolean;
  isCMPEnabled: boolean;
  isAdsEnabled: boolean;
  brandKey: BrandKey;
  publishedAt?: string | null;
}

export const OrphanEdit: FC<OrphanEditProps> = ({ defaultState }) => {
  const classes = useStyles();
  const [mutation, { loading }] = useUpsertOrphanPage();
  const { pushNotification } = useNotification();
  const [state, setState] = useState<EditorState>(defaultState);

  const doUpsert = () => {
    mutation({
      variables: prepareUpsertVariable({
        brandKey: state.brandKey,
        id: state.id,
        isCMPEnabled: state.isCMPEnabled,
        isAdsEnabled: state.isAdsEnabled,
        isFullScreen: state.isFullScreen,
        metas: JSON.stringify(state.metas),
        content: state.content,
        title: state.title,
        uri: state.url,
        status: state.status,
      }),
    })
      .then(() => {
        pushNotification({
          message: 'Votre page a bien été sauvegardée',
          type: NotificationTypeEnum.success,
        });
      })
      .catch((err) => {
        const isUriError = err.message.includes(state.url);
        pushNotification({
          message: isUriError
            ? 'L’URL saisie est déjà utilisée dans une autre page orpheline'
            : 'Erreur durant la sauvegarde de la page',
          type: NotificationTypeEnum.error,
        });
      });
  };

  const additional = (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Button
            onClick={doUpsert}
            data-testid="save-btn"
            variant="outlined"
            className={classes.button}
          >
            Sauvegarder
          </Button>
          <OrphanPageSelector
            currentStatus={state.status}
            changeStatus={(newStatus) => {
              setState({ ...state, status: newStatus });
            }}
          />
        </>
      )}
    </>
  );

  return (
    <>
      <div className={classes.appBar}>
        <EditAppBar
          additionalElement={additional}
          onNavigateBack={() => window.history.back()}
          title={
            <EditAppBarTitle
              title={state.title}
              brandKey={state.brandKey}
              subTitle={
                state.publishedAt
                  ? `Publié le ${formatDate(
                      new Date(state.publishedAt),
                      'dd/MM/yyy à HH:mm',
                    )}`
                  : ''
              }
            />
          }
        />
      </div>
      <div
        data-testid="orphan-pages-edit--container"
        className={classes.container}
      >
        <OrphanEditor setState={setState} state={state} />
      </div>
    </>
  );
};
