import { alpha } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  infoWrapper: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  texts: {
    flexShrink: 1,
    overflow: 'hidden',
  },
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginRight: theme.spacing(1),
    fontSize: 14,
    lineHeight: '16px',
  },
  index: {
    fontSize: 12,
    lineHeight: '14px',
    color: alpha(theme.palette.common.black, 0.5),
  },
  icons: {
    marginLeft: 'auto',
  },
}));
