import { ExpandMore } from '@mui/icons-material';
import { Box, Menu, MenuItem, useTheme } from '@mui/material';
import { LoadingButton } from '@prismamedia/one-components';
import React, { FC, useCallback, useState, MouseEvent } from 'react';
import { ArticleState } from '../../pages/ArticleEdit/reducer';
import { SAVE, SAVE_WITHOUT_EDITED } from './constants';
import { useStyles } from './styles';

interface SaveButtonProps {
  disabled?: boolean;
  loading?: boolean;
  onClick?: (forceArticle?: ArticleState, edited?: boolean) => void;
}

export const SaveButton: FC<SaveButtonProps> = ({
  disabled,
  loading,
  onClick,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = useCallback((): void => {
    setAnchorEl(null);
  }, []);

  const handleBtnClick = useCallback((): void => {
    onClick?.();
  }, [onClick]);

  const handleIconBtnClick = useCallback(
    (event: MouseEvent<HTMLElement>): void => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  return (
    <>
      <LoadingButton
        loaderColor={theme.palette.common.white}
        onClick={handleBtnClick}
        data-testid="save-button"
        className={classes.saveButton}
        classes={{ root: classes.button, disabled: classes.buttonDisabled }}
        variant="contained"
        endIcon={
          <Box onClick={handleIconBtnClick} sx={{ display: 'inherit' }}>
            <ExpandMore fontSize="small" />
          </Box>
        }
        disabled={disabled}
        loading={loading}
      >
        {SAVE}
      </LoadingButton>
      <Menu
        anchorEl={anchorEl}
        data-testid="statusOverlay"
        open={Boolean(anchorEl)}
        onBlur={handleClose}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            onClick?.(undefined, true);
          }}
        >
          {SAVE_WITHOUT_EDITED}
        </MenuItem>
      </Menu>
    </>
  );
};
