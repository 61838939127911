import { isUndefined } from 'lodash';
import { DEFAULT_CATEGORY_FORM_SECTION } from '../constants';
import type {
  Section,
  SectionForm,
} from '../views/Edit/components/Section/types';
import { getSectionFormContent } from '../views/Edit/components/Section/utils';

export const getCategoryFormSection = (
  {
    content,
    createdAt,
    description,
    id,
    isOnline,
    order,
    title,
    type,
    metas,
  }: Section & { createdAt?: GraphQL$DateTime },
  index?: number,
): SectionForm => {
  let parsedContent;

  try {
    parsedContent = content ? JSON.parse(content) : getSectionFormContent(type);
  } catch (e) {
    parsedContent = getSectionFormContent(type);
  }

  return {
    content: parsedContent,
    ...(createdAt && { createdAt }),
    description: description || DEFAULT_CATEGORY_FORM_SECTION.description,
    id,
    isActive: isOnline || DEFAULT_CATEGORY_FORM_SECTION.isActive,
    order: isUndefined(index) ? order : order === index ? order : index,
    title,
    type,
    metas: metas || DEFAULT_CATEGORY_FORM_SECTION.metas,
  };
};
