import { resizer } from '@prismamedia/one-components';
import { MediaBlock, MediaBlockData } from '../../../types/draft';
import { getCropZoneParam } from '../../../utils/resizer';

const DEFAULT_WIDTH = 1280;
const DEFAULT_HEIGHT = 720;

export const getSrcFromMedia = (data: MediaBlockData) => {
  const { iframely, crop } = data;
  const url =
    iframely.meta?.url ||
    iframely.meta?.thumbnail_url ||
    iframely.meta?.canonical;
  const width = iframely.meta?.width || DEFAULT_WIDTH;
  const height = iframely.meta?.height || DEFAULT_HEIGHT;

  return resizer.pad({
    width: 600,
    height: Math.round(
      600 / (crop ? crop.width / crop.height : width / height),
    ),
    origin: url,
    parameters: getCropZoneParam(crop),
  });
};

export const mapSlideshowToGalleryItem = (
  key: string,
  mediaBlock: MediaBlock,
) => {
  const { iframely, caption, slideshow, credit } = mediaBlock.data;
  const { credit: iframelyCredit } = iframely.meta;

  return {
    id: key,
    src: getSrcFromMedia(mediaBlock.data),
    title: caption,
    slideshow,
    credit: credit || iframelyCredit,
  };
};
