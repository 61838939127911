import { useQuery } from '@apollo/client';
import { Box, InputLabel, SxProps } from '@mui/material';
import { flatten, map } from 'ramda';
import { Dispatch, FC, useMemo } from 'react';
import {
  BrandCategoryFragment,
  BrandKey,
  CategoryStatus,
  GetBrandCategories,
  GetBrandCategoriesVariables,
} from '../../../__generated__/queries-web';
import { Suggest } from '../../../components/Suggest';
import { SlideshowEditAction, actions } from '../reducer';
import { GET_BRAND_CATEGORIES } from './getBrandCategories.web.graphql';

interface CategorySelectorProps {
  sx?: SxProps;
  brandKey: BrandKey;
  categoryId: string;
  dispatch: Dispatch<SlideshowEditAction>;
}

type CategoryWithChildren = BrandCategoryFragment & {
  children?: CategoryWithChildren[];
};

// flatten the category and category.children tree into a list
const reduceCategory = (
  category: CategoryWithChildren,
): CategoryWithChildren[] => [
  category,
  ...flatten(map(reduceCategory, category?.children || [])),
];

export const CategorySelector: FC<CategorySelectorProps> = ({
  brandKey,
  categoryId,
  dispatch,
  sx,
}) => {
  const { data } = useQuery<GetBrandCategories, GetBrandCategoriesVariables>(
    GET_BRAND_CATEGORIES,
    {
      variables: { brandKey, status: CategoryStatus.Published },
    },
  );

  const categories = useMemo(
    () => data?.categories[0] && reduceCategory(data?.categories[0]),
    [data?.categories],
  );

  const categorySelected = categories?.find(({ id }) => id === categoryId);

  return (
    <Box sx={sx}>
      <InputLabel shrink> Catégorie : </InputLabel>
      <Suggest
        selectedItems={categorySelected ? [categorySelected] : []}
        placeholder="Rechercher une catégorie"
        suggestions={data?.categories?.[0]?.children || []}
        multiple={true}
        maxNumberSuggest={2}
        isPrefetched
        onChange={(items) => {
          dispatch(actions.setCategory({ categoryId: items[0]?.id }));
        }}
      />
    </Box>
  );
};
