import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';

export const useStyles = makeStyles((theme) => ({
  scheduleDate: {
    marginLeft: '1.5rem',
    marginBottom: '1.5rem',
    display: 'flex',
    flexWrap: 'wrap',
    '& .schedule-date-header': {
      alignItems: 'center',
      display: 'flex',
      '& p': {
        fontWeight: 500,
        marginLeft: '0.5rem',
      },
    },
    '& .schedule-date-content': {
      marginTop: '1rem',
      marginLeft: '2rem',
    },
  },
  checkbox: {
    padding: theme.spacing(0.85),
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridRowGap: 36,
  },
  textLink: {
    marginTop: theme.spacing(2),
  },
  formSuggest: {
    '& > div:first-of-type': {
      marginTop: theme.spacing(2),
    },
    display: 'flex',
    flexDirection: 'column',
  },
  add: {
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      fontSize: '0.75rem',
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  link: { textDecoration: 'none' },
  addBtn: {
    width: 'fit-content',
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: theme.palette.common.white,
    borderRadius: 18,
    padding: 4,
    color: grey[600],
    fontWeight: 500,
    boxShadow: theme.shadows[2],
    marginTop: theme.spacing(1),
    '& span': {
      marginRight: 4,
    },
  },
  tags: {
    marginTop: theme.spacing(-3.5),
  },
  subTitle: {
    fontSize: '1rem',
    fontWeight: 500,

    '& > span': {
      fontWeight: 200,
    },
  },
}));
