import type { ContentState } from 'draft-js';
import { convertFromRaw, EditorState } from 'draft-js';
import { MegadraftEditor } from 'megadraft';
import React, { createElement, FC, useState } from 'react';
import { CELL_EDITOR_ACTIONS } from './constants';
import { useStyles } from './styles';
import { cellBlockStyleFn } from './utils';

interface TableCellProps {
  cellKey: string;
  contentState: ContentState;
  onChange: (editorState: EditorState, cellKey: string) => void;
  element: 'th' | 'td';
}
const TableCell: FC<TableCellProps> = ({
  cellKey,
  contentState,
  element,
  onChange,
}: TableCellProps) => {
  const classes = useStyles();
  const contentBlock = contentState.getBlockForKey(cellKey);
  const cellRawContentState = contentBlock.getData().get('cellContentState');

  const [cellEditorState, setCellEditorState] = useState(
    EditorState.createWithContent(convertFromRaw(cellRawContentState)),
  );

  const handleDraftChange = (newEditorState: EditorState) => {
    setCellEditorState(newEditorState);

    const currentContentState = cellEditorState.getCurrentContent();
    const newContentState = newEditorState.getCurrentContent();

    // Draft text selection
    if (currentContentState !== newContentState) {
      onChange(newEditorState, cellKey);
    }
  };

  return createElement(
    element,
    {
      'data-key': cellKey,
    },
    <div className={classes.wrapper}>
      <MegadraftEditor
        actions={CELL_EDITOR_ACTIONS}
        blockStyleFn={cellBlockStyleFn}
        editorState={cellEditorState}
        id={cellKey}
        onChange={handleDraftChange}
        sidebarRendererFn={() => false}
      />
    </div>,
  );
};

export { TableCell };
