import { gql } from '@apollo/client';
import {
  GetResolvedImage,
  GetResolvedImageVariables,
} from '../../__generated__/queries-image';
import { useGetter } from '../useGetter';

export const GET_RESOLVED_IMAGE = gql`
  query GetResolvedImage($key: String!) {
    getResolvedImage(key: $key) {
      url
    }
  }
`;

export const useResolvedImageGetter = () =>
  useGetter<GetResolvedImage, GetResolvedImageVariables>(GET_RESOLVED_IMAGE);
