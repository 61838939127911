import { gql, useQuery } from '@apollo/client';
import {
  GetAttributes,
  GetAttributesVariables,
} from '../../__generated__/queries-web';
import { ATTRIBUTE_FRAGMENT } from '../fragments/attribute.web.graphql';

export const GET_ATTRIBUTES = gql`
  query GetAttributes(
    $first: Int!
    $where: AttributeWhereInput
    $skip: Int
    $orderBy: [AttributeOrderByInput!]
  ) {
    attributes(first: $first, where: $where, skip: $skip, orderBy: $orderBy) {
      ...AttributeFragment
    }
  }
  ${ATTRIBUTE_FRAGMENT}
`;

export const useAttributes = (variables: GetAttributesVariables) => {
  return useQuery<GetAttributes, GetAttributesVariables>(GET_ATTRIBUTES, {
    variables,
    fetchPolicy: 'network-only',
  });
};
