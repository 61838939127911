import { gql } from '@apollo/client';
import {
  GetCategories,
  GetCategoriesVariables,
  GetCategory,
  GetCategoryVariables,
} from '../../__generated__/queries-web';
import { useFetch } from '../../utils/useFetch';

const GET_CATEGORIES = gql`
  query GetCategories($where: CategoryWhereInput!) {
    categories(first: 1000, where: $where, orderBy: title_ASC) {
      id
      title
      level
      parent {
        id
      }
    }
  }
`;

export const useGetCategories = () =>
  useFetch<GetCategories, GetCategoriesVariables>(GET_CATEGORIES);

const GET_CATEGORY = gql`
  query GetCategory($where: CategoryWhereUniqueInput!) {
    category(where: $where) {
      id
      title
    }
  }
`;

export const useGetCategory = () =>
  useFetch<GetCategory, GetCategoryVariables>(GET_CATEGORY);
