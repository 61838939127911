import { Divider, Typography } from '@mui/material';
import { RawDraftContentState } from 'draft-js';
import React, { FC } from 'react';
import { useStyles } from './styles';
import { CountType, getCount } from './utils';

interface CharCounterProps {
  content: string | RawDraftContentState | null;
  type?: 'Draft';
}

export const CharCounter: FC<CharCounterProps> = ({ content, type }) => {
  const count: CountType = getCount(content, type);
  const classes = useStyles();

  return (
    <>
      {type && <Divider variant="fullWidth" />}
      <Typography
        className={classes.counter}
        variant="caption"
        component="span"
      >
        {`${count.charCount} caractère${count.charCount > 1 ? 's' : ''} ${
          count.wordCount
        } mot${count.wordCount > 1 ? 's' : ''}`}
      </Typography>
    </>
  );
};
