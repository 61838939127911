import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  customGridSpacing: {
    margin: 0,
  },
  list: {
    overflow: 'hidden',
    scrollbarWidth: 'none',
    backgroundColor: theme.palette.background.paper,
    maxWidth: '100%',
    position: 'relative',
    '@media print': {
      display: 'block',
    },
  },
}));
