import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  loader: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullscreen: {
    height: '100vh',
  },
  message: {
    fontSize: '1.5rem',
  },
}));
