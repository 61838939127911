import { gql } from '@apollo/client';
import { client } from '..';
import {
  TranslateArticle,
  TranslateArticleVariables,
} from '../../__generated__/queries-web';

const TRANSLATE_ARTICLE_QUERY = gql`
  query TranslateArticle(
    $id: String!
    $source_lang: String!
    $target_lang: String!
  ) {
    translateArticle(
      id: $id
      source_lang: $source_lang
      target_lang: $target_lang
    ) {
      title
      body
      lead
    }
  }
`;

export const translateArticleQuery = async (
  id: string,
  source_lang: string,
  target_lang: string,
) => {
  return await client.query<TranslateArticle, TranslateArticleVariables>({
    fetchPolicy: 'no-cache',
    query: TRANSLATE_ARTICLE_QUERY,
    variables: { id, source_lang, target_lang },
  });
};
