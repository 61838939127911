import { parse } from 'query-string';
import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useGetArticle } from '../../apollo/queries/articles.web.graphql';
import { MobilePushForm } from './MobilePushForm';

export const MobilePushWrapper: FC<RouteComponentProps> = ({ location }) => {
  const { id } = parse(location.search);
  const { loading, error, data } = useGetArticle(id as string);

  return (
    <MobilePushForm article={data?.article} loading={loading} error={error} />
  );
};
