import type { RawDraftContentState } from 'draft-js';
import uuidv4 from 'uuid/v4';
import { SuggestItemType } from '../../../../../components/Suggest/types';
import {
  BrandKey,
  CardStatus,
  getCard_card,
  getCard_card_cardCategories,
  getCard_card_cardTags,
} from '../../../../../__generated__/queries-web';
import { CardEditActionTypes, CardState } from '../../types';

export interface CardEditAction {
  type: CardEditActionTypes;
  payload: any;
}

type CardEditActionsCreators = Record<
  string,
  { (payload: any): CardEditAction }
>;

interface SetCardTagsActionPayload {
  field: 'cardTags';
  value: getCard_card_cardTags[];
}

export const getInitialCardState = (
  brandKey: BrandKey,
  card: getCard_card | undefined,
): CardState => ({
  id: card?.id || uuidv4(),
  title: card?.title || '',
  secondaryTitle: card?.secondaryTitle || '',
  description: card?.description || '',
  url: card?.url || '',
  label: card?.label || '',
  status: card?.status || CardStatus.Published,
  media: card?.media ? JSON.parse(card.media) : null,
  body: card?.body ? JSON.parse(card.body) : null,
  brandKey,
  cardCategories: card?.cardCategories || [],
  cardTags: card?.cardTags || [],
});

export const actions: CardEditActionsCreators = {
  setCardTags: ({ field, value }: SetCardTagsActionPayload) => ({
    type: CardEditActionTypes.UPDATE_TAG,
    payload: {
      fieldName: field,
      value,
    },
  }),
  updateCategory: ({
    field,
    value,
  }: {
    field: string;
    value: SuggestItemType[];
  }) => ({
    type: CardEditActionTypes.UPDATE_CATEGORY,
    payload: {
      fieldName: field,
      value,
    },
  }),
  updateDraft: ({
    field,
    value,
  }: {
    field: string;
    value: RawDraftContentState;
  }) => ({
    type: CardEditActionTypes.UPDATE_DRAFT,
    payload: {
      fieldName: field,
      value,
    },
  }),
  updateField: ({ field, value }: { field: string; value: any }) => ({
    type: CardEditActionTypes.UPDATE_FIELD,
    payload: {
      fieldName: field,
      value,
    },
  }),
  validateField: ({ field, value }: { field: string; value: string }) => ({
    type: CardEditActionTypes.VALIDATE_FIELD,
    payload: {
      fieldName: field,
      value,
    },
  }),
};

export const cardReducer = (state: CardState, action: CardEditAction) => {
  const {
    payload: { value, fieldName },
    type,
  } = action;
  switch (type) {
    case CardEditActionTypes.UPDATE_FIELD: {
      return { ...state, [fieldName]: value };
    }

    case CardEditActionTypes.UPDATE_CATEGORY: {
      const cardCategories = ((value as unknown) as getCard_card_cardCategories[]).reduce(
        (acc: { category: getCard_card_cardCategories }[], val) =>
          acc.concat({ category: val }),
        [],
      );

      return { ...state, [fieldName]: cardCategories };
    }

    case CardEditActionTypes.UPDATE_TAG: {
      return { ...state, [fieldName]: value };
    }

    default:
      return state;
  }
};
