import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  toolbar: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  container: {
    marginTop: '90px',
    display: 'flex',
    justifyContent: 'center',
  },
  root: {
    width: '70%',
    overflowX: 'auto',
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '32px',
  },
  button: {
    padding: theme.spacing(1),
  },
}));
