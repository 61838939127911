import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  appBar: {
    marginTop: '120px',
  },
  container: {
    margin: 'auto',
    width: '80%',
  },
  orphanContent: {
    width: ' 100%',
  },
  inputText: {
    marginTop: '1rem',
  },
  headerDate: {
    margin: 0,
  },
  button: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    marginRight: '1rem',
  },
  wrapperMetaButtons: {
    margin: '1rem 0 .5rem',
  },
}));
