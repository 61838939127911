import { gql, useQuery } from '@apollo/client';
import { useFetch } from '../../../utils/useFetch';
import {
  GetQualifier,
  GetQualifierVariables,
} from '../../../__generated__/queries-web';

export const GET_QUALIFIER = gql`
  query GetQualifier($where: QualifierWhereUniqueInput!) {
    qualifier(where: $where) {
      id
      title
      status
      brandKey
      format
    }
  }
`;

export const useGetQualifier = (id?: string, skip?: boolean) =>
  useQuery<GetQualifier, GetQualifierVariables>(GET_QUALIFIER, {
    variables: { where: { id } },
    skip: !id || skip,
  });

export const useQualifierGetter = () =>
  useFetch<GetQualifier, GetQualifierVariables>(GET_QUALIFIER);
