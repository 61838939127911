import { client } from '../../../../apollo';
import {
  SearchArticles_searchArticles,
  SearchMediaArticleCount,
  SearchMediaArticleCountVariables,
  SuggestArticles_suggestArticles_items,
} from '../../../../__generated__/queries-web';
import { SEARCH_MEDIA_ARTICLE_COUNT } from '../../../../apollo/queries/searchMediaArticleCount.web.graphql';

export const articlesWithSlideShowCount = async (
  searchSuggestArticles:
    | SuggestArticles_suggestArticles_items[]
    | SearchArticles_searchArticles[],
) => {
  const articles = searchSuggestArticles
    .filter((article) => article.contextualizedUrl)
    .map((article) => {
      return {
        ...article,
        contextualizedUrl: (article.contextualizedUrl as string)
          .replace('http:', 'https:')
          .replace('rec1-', '')
          .replace('rec2-', '')
          .replace('rec3-', ''),
      };
    });

  const { data: searchSlideShowArticleCount } = await client.query<
    SearchMediaArticleCount,
    SearchMediaArticleCountVariables
  >({
    query: SEARCH_MEDIA_ARTICLE_COUNT,
    variables: {
      mediaElements: articles.map(
        (article) => article.contextualizedUrl,
      ) as string[],
      mediaSite: 'PMD_Slideshow',
    },
  });

  if (searchSlideShowArticleCount) {
    const searchArticleWithSlideShowCount = articles.map((article) => {
      return {
        ...article,
        slideShowArticleCount: searchSlideShowArticleCount.searchMediaArticleCount.find(
          (slideShowCount) =>
            slideShowCount?.mediaElement === article.contextualizedUrl,
        )?.count,
      };
    });

    return searchArticleWithSlideShowCount;
  }
  return searchSuggestArticles;
};
