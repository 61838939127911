import { closestCorners } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Add } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import type { FC } from 'react';
import React, { memo, useState } from 'react';
import { DragAndDrop } from '../../../../../../../components/DragAndDrop';
import { Skeletons } from '../../../../../../../components/Skeletons';
import { TabType } from '../../../../../../../__generated__/queries-web';
import { MEDIUM_CARD_ITEM_HEIGHT } from '../../../../../components/Card/styles';
import type { CategoryFormSectionBoardContent, SectionForm } from '../types';
import {
  CATEGORY_FORM_ADD_BUTTON_TEXT,
  CATEGORY_FORM_LINKED_CONTENT_TEXT,
  MAX_CONTENT_LENGTH,
  NO_CONTENT_ADDED_TITLE,
} from './constants';
import { ContentCard } from './ContentCard';
import { ContentMultimedia } from './ContentMultimedia';
import { useStyles } from './styles';
import { useEditBoard } from './useEditBoard';

export type BoardProps = SectionForm<
  CategoryFormSectionBoardContent[],
  TabType.Board
>;

export const Board: FC<BoardProps> = memo((sectionProps) => {
  const {
    content: boardContent,
    handlers: boardHandlers,
    isLoading: isContentLoading,
  } = useEditBoard(sectionProps);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const classes = useStyles({ hasContentLength: !!boardContent.length });

  return (
    <>
      <Box className={classes.wrapper}>
        <Box className={classes.wrapperToolbar}>
          <Typography className={classes.title} component="h3">
            {CATEGORY_FORM_LINKED_CONTENT_TEXT}{' '}
            <span>
              ({boardContent.length}/{MAX_CONTENT_LENGTH})
            </span>
          </Typography>

          <Button
            className={classes.addButton}
            disabled={
              isContentLoading || boardContent.length >= MAX_CONTENT_LENGTH
            }
            onClick={() => {
              setIsModalOpen(true);
            }}
            startIcon={<Add />}
            variant="outlined"
          >
            {CATEGORY_FORM_ADD_BUTTON_TEXT}
          </Button>
        </Box>

        {isContentLoading ? (
          <Box className={classes.wrapperSkeletons}>
            <Skeletons height={MEDIUM_CARD_ITEM_HEIGHT} quantity={3} />
          </Box>
        ) : (
          <Stack className={classes.wrapperCards}>
            <DragAndDrop
              dndContextProps={{
                collisionDetection: closestCorners,
                modifiers: [restrictToVerticalAxis],
              }}
              itemList={boardContent}
              renderItem={(itemProps) => (
                <ContentCard
                  {...itemProps}
                  onDeleteCard={boardHandlers.handleDeleteContent}
                />
              )}
              onDragEnd={boardHandlers.handleReorderContent}
              sortableContextProps={{
                startegy: verticalListSortingStrategy,
              }}
            />

            {!boardContent.length && (
              <Typography component="p">{NO_CONTENT_ADDED_TITLE}</Typography>
            )}
          </Stack>
        )}
      </Box>

      <ContentMultimedia
        isOpen={isModalOpen}
        onContentSelected={boardHandlers.handleAddContent}
        setIsOpen={setIsModalOpen}
      />
    </>
  );
});
