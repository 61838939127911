import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { combineStyles } from '../../../utils/styles/combineStyles';
import { link } from '../../../utils/styles/link';

const slideshowListStyle = (theme: Theme) => ({
  search: {
    margin: 0,
    backgroundColor: theme.palette.primary.dark,
  },
  input: {
    alignItems: 'center',
    borderRadius: 4,
  },
  infiniteList: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
  },
  textFieldInput: {
    fontSize: 16,
    marginLeft: '8px',
    lineHeight: 1.7,
    color: theme.palette.grey[100],
  },
  searchBtnContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  content: {
    marginTop: 64 + 48,
    height: `calc(100vh - (64px + 48px))`,
    position: 'relative',
  },
  actions: {
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  drawer: {
    paddingTop: theme.spacing(2),
    marginTop: 64 + 48,
    width: theme.spacing(38),
    zIndex: 1000,
  },
  removeFiltersButton: {
    marginTop: theme.spacing(2),
  },
  padding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
});

type withStyleProps =
  | keyof ReturnType<typeof slideshowListStyle>
  | keyof ReturnType<ReturnType<typeof link>>;

export type SlideshowListStyleProps = Record<withStyleProps, string>;
export interface CustomStyleProps {
  color: string;
  backgroundColor: string;
  isMediumDevice: boolean;
}
const combinedStyles = combineStyles(slideshowListStyle, link({}));
export const useStyles = makeStyles(combinedStyles);
