/** pattern1RecipeLinkRegex: validating domain names:
// https://www.femmeactuelle.fr/cuisine/recettes/
// https://rec2.femmeactuelle.fr/cuisine/recettes/
// https://rec3.femmeactuelle.fr/cuisine/recettes/
 */
const pattern1RecipeLinkRegex =
  '^(https?:\\/\\/)?(www|rec1|rec2|rec3)\\.femmeactuelle\\.fr\\/cuisine\\/recettes\\/';

/** pattern2RecipeLinkRegex: validating domain names:
 // https://www.cuisineactuelle.fr/recettes/
 // https://rec2.cuisineactuelle.fr/recettes/
 // https://rec3.cuisineactuelle.fr/recettes/
 */
const pattern2RecipeLinkRegex = '^(https?:\\/\\/)?(www|rec1|rec2|rec3)\\.cuisineactuelle\\.fr\\/recettes\\/' as const;

export const patternRecipeLinkRegex = `(${pattern1RecipeLinkRegex}|${pattern2RecipeLinkRegex})` as const;

/** patternSlideShowRegex: validating domain names:
 // https://photo.
 // https://rec1-photo.
 // https://rec2-photo.
 // https://rec3-photo.
 // https://de-photo.
 // https://rec1-de-photo.
 // https://rec2-de-photo.
 // https://rec3-de-photo.
 */
export const patternSlideShowRegex = '^(https?:\\/\\/)?(rec[123]-)?(de-)?photo\\.' as const;

/** pattern1PeopleLinkRegex: validating domain names:
 // https://www.programme-tv.net/biographie
 // https://rec1.programme-tv.net/biographie
 // https://rec2.programme-tv.net/biographie
 // https://rec3.programme-tv.net/biographie
 */
const pattern1PeopleLinkRegex = '^(https?:\\/\\/)?(www|rec1|rec2|rec3)\\.programme-tv\\.net\\/biographie\\/' as const;

/** pattern2PeopleLinkRegex: validating domain names:
 // https://www.voici.fr/bios-people
 // https://rec1.voici.fr/bios-people
 // https://rec2.voici.fr/bios-people
 // https://rec3.voici.fr/bios-people
 */
const pattern2PeopleLinkRegex = '^(https?:\\/\\/)?(www|rec1|rec2|rec3)\\.voici\\.fr\\/bios-people\\/' as const;

/** pattern3PeopleLinkRegex: validating domain names:
 // https://www.femmeactuelle.fr/bio
 // https://rec1.femmeactuelle.fr/bio
 // https://rec2.femmeactuelle.fr/bio
 // https://rec3.femmeactuelle.fr/bio
 */
const pattern3PeopleLinkRegex = '^(https?:\\/\\/)?(www|rec1|rec2|rec3)\\.femmeactuelle\\.fr\\/bio\\/' as const;

/** pattern4PeopleLinkRegex: validating domain names:
 // https://www.gala.fr/stars_et_gotha
 // https://rec1.gala.fr/stars_et_gotha
 // https://rec2.gala.fr/stars_et_gotha
 // https://rec3.gala.fr/stars_et_gotha
 */
const pattern4PeopleLinkRegex = '^(https?:\\/\\/)?(www|rec1|rec2|rec3)\\.gala\\.fr\\/stars_et_gotha\\/' as const;

export const patternPeopleLinkRegex = `(${pattern1PeopleLinkRegex}|${pattern2PeopleLinkRegex}|${pattern3PeopleLinkRegex}|${pattern4PeopleLinkRegex})` as const;
