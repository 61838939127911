import {
  TagProviderName,
  TagType,
} from '../../../../__generated__/queries-web';

export const ALLOWED_COLLECTION_PROVIDERS = [TagProviderName.TvCollection];
export const ALLOWED_COLLECTION_TAG_TYPES = [TagType.TvCollection];
export const ARTICLE_ENHANCEMENT_COLLECTION_TITLE = 'Collection TV';
export const NO_COLLECTION_SELECTED_TITLE =
  'Aucune collection TV de selectionné';
export const COLLECTION_SEARCH_BAR_PLACEHOLDER = 'Rechercher une collection TV';
export const TAGS_COLLECTION_SECTION_DATA_TESTID = 'collection-tv-tags-wrapper';
