import { useMemo } from 'react';
import {
  DAILYMOTION_INTERNAL_SOURCE,
  getMediasSite,
  PODCAST_SOURCE,
  SLIDESHOW_TYPE,
} from './media';

export interface MediasContentType {
  hasVideo: boolean;
  hasSlideshow: boolean;
  hasPodcast: boolean;
  hasLive: boolean;
  hasPush: boolean;
  liveCount: number;
  pushCount: number;
}

export const useMediasIcons = (
  medias: string | null,
  live: number,
  push: number,
): MediasContentType => {
  const sites = getMediasSite(medias);
  const mediasContent: MediasContentType = useMemo(
    () => ({
      hasVideo: Boolean(sites?.includes(DAILYMOTION_INTERNAL_SOURCE)),
      hasSlideshow: Boolean(sites?.includes(SLIDESHOW_TYPE)),
      hasPodcast: Boolean(sites?.includes(PODCAST_SOURCE)),
      hasLive: Boolean(live),
      liveCount: live,
      hasPush: Boolean(push),
      pushCount: push,
    }),
    [live, push, sites],
  );

  return mediasContent;
};
