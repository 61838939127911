import React from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { useStyles } from './style';
import { MobilePushFormState } from '../types';

interface Props {
  pushFormState: MobilePushFormState;
  isIosAllowed: boolean;
  isAndroiddAllowed: boolean;
  isEditorialAlertAllowed: boolean;
  changeHandler: (
    name: string,
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const MobilePushConfiguration: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    changeHandler,
    pushFormState,
    isAndroiddAllowed,
    isEditorialAlertAllowed,
    isIosAllowed,
  } = props;

  return (
    <Box className={classes.configurationWrapper} component="section">
      <Typography className={classes.configurationMainTitle} component="h2">
        Configuration
      </Typography>

      <Box className={classes.configurationItem}>
        <Typography className={classes.configurationSubTitle}>
          Envoyer à
        </Typography>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={pushFormState.android}
                onChange={changeHandler('android')}
                value={pushFormState.android}
                disabled={!isAndroiddAllowed}
              />
            }
            label="android"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={pushFormState.ios}
                onChange={changeHandler('ios')}
                value={pushFormState.ios}
                disabled={!isIosAllowed}
              />
            }
            label="ios"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={pushFormState.editorialAlert}
                onChange={changeHandler('editorialAlert')}
                value={pushFormState.editorialAlert}
                disabled={!isEditorialAlertAllowed}
              />
            }
            label="alerte web"
          />
        </FormGroup>
      </Box>
      <Box className={classes.configurationItem}>
        <Typography className={classes.configurationSubTitle}>
          Smart Segments
        </Typography>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={pushFormState.smartSegmentNew}
                onChange={changeHandler('smartSegmentNew')}
                value={pushFormState.smartSegmentNew}
              />
            }
            label="NEW"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={pushFormState.smartSegmentOneTime}
                onChange={changeHandler('smartSegmentOneTime')}
                value={pushFormState.smartSegmentOneTime}
              />
            }
            label="ONE_TIME"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={pushFormState.smartSegmentEngaged}
                onChange={changeHandler('smartSegmentEngaged')}
                value={pushFormState.smartSegmentEngaged}
              />
            }
            label="ENGAGED"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={pushFormState.smartSegmentDormant}
                onChange={changeHandler('smartSegmentDormant')}
                value={pushFormState.smartSegmentDormant}
              />
            }
            label="DORMANT"
          />
        </FormGroup>
      </Box>
    </Box>
  );
};
