import React, { FC } from 'react';
import { SvgIcon } from '@mui/material';
import { PhotoProvider } from '../../../../__generated__/queries-web';
import { useStyles } from './styles';

const GettyIcon = () => (
  <SvgIcon width="32px" height="32px" viewBox="0 0 512.000000 512.000000">
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      stroke="none"
    >
      <path
        d="M2325 5099 c-503 -51 -971 -240 -1360 -549 -103 -81 -314 -292 -395
      -395 -156 -197 -321 -488 -404 -716 -51 -139 -103 -344 -128 -504 -31 -200
      -31 -550 0 -750 43 -273 114 -500 237 -750 129 -263 274 -466 484 -676 210
      -210 413 -355 676 -484 250 -123 477 -194 750 -237 200 -31 550 -31 750 0 273
      43 500 114 750 237 263 129 466 274 676 484 210 210 355 413 484 676 88 179
      132 295 180 476 60 230 79 379 79 649 0 270 -19 419 -79 649 -48 181 -92 297
      -180 476 -129 263 -274 466 -484 676 -210 210 -413 355 -676 484 -249 122
      -482 196 -747 236 -154 23 -474 33 -613 18z m404 -1269 c46 -13 130 -46 187
      -75 l103 -52 31 32 c64 66 189 111 343 122 l87 6 0 -155 0 -156 -122 -4 c-68
      -3 -135 -10 -150 -16 l-27 -11 35 -68 c46 -92 64 -170 64 -278 -1 -259 -126
      -443 -368 -541 -102 -41 -196 -56 -417 -64 -115 -5 -227 -14 -249 -20 -55 -17
      -86 -51 -86 -95 0 -102 72 -126 458 -150 449 -28 622 -74 748 -200 118 -119
      148 -322 70 -480 -42 -84 -135 -178 -235 -236 -87 -51 -255 -105 -391 -125
      -144 -21 -486 -15 -623 10 -375 71 -569 246 -528 478 27 153 135 262 291 293
      l54 11 -76 37 c-101 49 -170 105 -197 159 -28 60 -28 143 1 206 31 67 130 161
      213 202 39 19 72 36 74 37 2 1 -14 16 -35 34 -200 168 -279 337 -253 542 28
      221 154 394 364 500 62 31 171 64 250 77 102 15 289 6 384 -20z"
      />
      <path
        d="M2419 3507 c-293 -83 -328 -439 -55 -562 217 -98 466 46 466 270 0
      159 -130 290 -298 301 -39 2 -86 -2 -113 -9z"
      />
      <path
        d="M2295 1950 c-118 -24 -199 -93 -206 -175 -5 -68 1 -95 33 -131 63
      -71 198 -104 443 -106 220 -2 334 29 416 114 124 128 0 248 -302 292 -112 17
      -316 20 -384 6z"
      />
    </g>
  </SvgIcon>
);

export const PixabayIcon = () => (
  <SvgIcon width="32px" height="32px" viewBox="0 0 512.000000 512.000000">
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      stroke="none"
    >
      <path
        d="M2315 5109 c-800 -83 -1501 -518 -1927 -1196 -487 -774 -516 -1777
      -75 -2578 200 -364 487 -676 841 -913 238 -159 600 -311 876 -366 214 -43 295
      -51 530 -51 236 0 333 10 532 51 880 184 1615 842 1898 1702 92 278 125 491
      125 802 0 301 -32 513 -116 775 -124 390 -330 728 -622 1024 -399 404 -893
      653 -1462 737 -123 18 -478 26 -600 13z m-340 -1314 c80 -22 208 -83 286 -136
      183 -124 326 -337 376 -562 22 -97 22 -316 0 -415 -43 -190 -120 -328 -261
      -467 -151 -150 -303 -227 -500 -254 -42 -6 -210 -11 -372 -11 l-294 0 -2 -372
      -3 -373 -177 -3 -177 -2 -6 42 c-3 24 -4 435 -2 913 4 956 1 913 67 1083 113
      295 365 506 675 568 90 18 307 11 390 -11z m1195 -289 c115 -173 213 -317 217
      -319 5 -3 105 139 223 314 l214 319 219 0 c172 0 217 -3 211 -12 -4 -7 -138
      -209 -298 -448 -159 -239 -289 -439 -289 -445 0 -5 147 -217 328 -470 180
      -253 330 -465 332 -472 4 -11 -37 -13 -213 -11 l-217 3 -251 343 -251 342 -27
      -32 c-15 -18 -90 -118 -165 -222 -76 -104 -178 -243 -228 -310 l-90 -121 -217
      -3 c-120 -1 -218 0 -218 3 0 3 151 217 335 476 184 259 335 473 335 475 0 2
      -123 187 -272 411 -150 224 -285 427 -301 450 l-27 43 220 0 220 0 210 -314z"
      />
      <path
        d="M1616 3435 c-193 -53 -356 -219 -395 -402 -17 -80 -25 -338 -16 -534
      l7 -176 317 3 c285 2 322 5 382 23 167 52 306 197 361 376 31 98 31 234 1 330
      -57 182 -215 332 -399 380 -70 18 -191 18 -258 0z"
      />
    </g>
  </SvgIcon>
);

export const UnsplashIcon = () => (
  <SvgIcon width="32px" height="32px" viewBox="0 0 512.000000 512.000000">
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      stroke="none"
    >
      <path
        d="M2315 5109 c-800 -83 -1501 -518 -1927 -1196 -487 -774 -516 -1777
      -75 -2578 200 -364 487 -676 841 -913 238 -159 600 -311 876 -366 214 -43 295
      -51 530 -51 236 0 333 10 532 51 880 184 1615 842 1898 1702 92 278 125 491
      125 802 0 301 -32 513 -116 775 -124 390 -330 728 -622 1024 -399 404 -893
      653 -1462 737 -123 18 -478 26 -600 13z m795 -1469 l0 -420 -550 0 -550 0 0
      420 0 420 550 0 550 0 0 -420z m-1100 -1325 l0 -415 550 0 550 0 0 415 0 415
      475 0 475 0 0 -835 0 -835 -1500 0 -1500 0 0 835 0 835 475 0 475 0 0 -415z"
      />
    </g>
  </SvgIcon>
);

interface ProviderIconProps {
  provider: PhotoProvider;
  withLabel?: boolean;
}
export const ProviderIcon: FC<ProviderIconProps> = ({
  provider,
  withLabel = true,
}) => {
  const classes = useStyles({});

  return (
    <span className={classes.providerBadge}>
      {withLabel && `${provider} `}
      {provider === PhotoProvider.Getty ? (
        <GettyIcon />
      ) : provider === PhotoProvider.Unsplash ? (
        <UnsplashIcon />
      ) : (
        <PixabayIcon />
      )}
    </span>
  );
};
