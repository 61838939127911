import { gql } from '@apollo/client';

export const UPSERT_TAG = gql`
  mutation UpsertTag(
    $create: TagCreateInput!
    $update: TagUpdateInput!
    $where: TagWhereUniqueInput!
  ) {
    upsertTag(create: $create, update: $update, where: $where) {
      id
      title
      slug
      type
      relationId
    }
  }
`;
