import { ArticleFormat } from '../__generated__/queries-web';

enum ArticleFormatMapper {
  Rich = 'Article',
  Slideshow = 'Diaporama',
  Video = 'Vidéo',
}

export const gertArticleFormatToDisplay = (articleFormat: ArticleFormat) => {
  return ArticleFormatMapper[articleFormat];
};
