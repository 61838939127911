import { useMutation } from '@apollo/client';
import {
  NotificationTypeEnum,
  PushNotificationFn,
  useNotification,
} from '@prismamedia/one-components';
import { generatePath, useHistory } from 'react-router-dom';
import { paths } from '../../../routing/Router/paths';
import {
  getCard_card_cardCategories,
  getCard_card_cardTags,
  upsertCard,
  upsertCardVariables,
} from '../../../__generated__/queries-web';
import {
  getCreateForResource,
  mapDeleteRelationship,
} from '../../ArticleEdit/utils/articleMutationVariables';
import { UPSERT_CARD } from '../Mutations/upsertCard.web.graphql';
import { CardState } from './types';

export enum SaveCardActions {
  CREATE,
  UPDATE,
  DELETE,
}

const showSaveCardError = (
  error: any,
  pushNotification: PushNotificationFn,
) => {
  console.error('[SAVE CARD ERROR] ', error);

  pushNotification({
    message: "Erreur lors de l'enregistrement",
    type: NotificationTypeEnum.error,
  });
};

export const useSaveCard = () => {
  const history = useHistory();
  const { pushNotification } = useNotification();
  const [upsert] = useMutation<upsertCard, upsertCardVariables>(UPSERT_CARD);

  const saveCard = async (
    card: CardState,
    deprecatedCategories: getCard_card_cardCategories[],
    deprecatedTags: getCard_card_cardTags[],
    actionType: SaveCardActions,
  ) => {
    const serializedCard = {
      ...card,
      body: card.body ? JSON.stringify(card.body) : null,
      media: card.media ? JSON.stringify(card.media) : null,
      cardCategories: {
        create: getCreateForResource(card.cardCategories, 'category'),
      },
      cardTags: {
        create: getCreateForResource(card.cardTags, 'tag'),
      },
    };

    const variables = {
      where: { id: serializedCard.id },
      create: { ...serializedCard },
      update: {
        ...(actionType === SaveCardActions.UPDATE && {
          ...serializedCard,
          cardCategories: {
            ...serializedCard.cardCategories,
            delete: mapDeleteRelationship(deprecatedCategories),
          },
          cardTags: {
            ...serializedCard.cardTags,
            delete: mapDeleteRelationship(deprecatedTags),
          },
        }),
      },
    };

    try {
      const result = await upsert({ variables });

      if (!result?.data?.upsertCard.id || !result.data?.upsertCard.status) {
        showSaveCardError(result, pushNotification);
        return;
      }

      const {
        data: {
          upsertCard: { brandKey, id, status },
        },
      } = result;

      pushNotification({
        message: 'Encart enregistré',
        type: NotificationTypeEnum.success,
      });

      history.push({
        pathname: generatePath(paths.CARD_EDIT, { brandKey, id }),
        search: `status=${status}`,
      });
    } catch (e) {
      showSaveCardError(e, pushNotification);
    }
  };

  return { saveCard };
};
