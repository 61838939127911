import { DeleteOutline, ExpandMore } from '@mui/icons-material';
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Menu,
  Tooltip,
} from '@mui/material';
import {
  Loader,
  getPatternErrorMessage,
  getRequiredErrorMessage,
} from '@prismamedia/one-components';
import React, { FC, MouseEvent, useCallback, useState } from 'react';
import type { ArticleState } from '../../../pages/ArticleEdit/reducer';
import type { Transition } from '../../../utils/statuses';
import { statusToLabel } from '../../../utils/statuses';
import {
  ArticleFormat,
  ArticleStatus,
  CardStatus,
  OrphanPageStatus,
} from '../../../__generated__/queries-web';
import type { MenuItemProps } from './MenuItem';
import { MenuItem } from './MenuItem';
import { useStyles } from './styles';

const errorMessageRenderer = (
  errorMessagesNodeList: JSX.Element[],
): JSX.Element => (
  <List dense>
    {errorMessagesNodeList.map((errorMessage, i) => (
      <ListItem key={`error-message-${i}`}>
        <ListItemText>{errorMessage}</ListItemText>
      </ListItem>
    ))}
  </List>
);
interface StatusContainerProps extends Pick<MenuItemProps, 'changeStatus'> {
  articleState?: ArticleState;
  currentStatus: OrphanPageStatus | ArticleStatus | CardStatus;
  label: string;
  transitions: { [key: string]: Transition };
  loading?: boolean;
}

const StatusContainer: FC<StatusContainerProps> = ({
  articleState,
  changeStatus,
  currentStatus,
  label,
  transitions,
  loading,
}): JSX.Element | null => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = useCallback((): void => {
    setAnchorEl(null);
  }, []);

  const handlePublishBtnClick = useCallback(
    (event: MouseEvent<HTMLElement>): void => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleChangeStatus = useCallback(
    (newStatus: OrphanPageStatus | ArticleStatus): void => {
      changeStatus(newStatus);
      handleClose();
    },
    [changeStatus, handleClose],
  );

  if (!label) {
    return null;
  }

  const possibleTransitions = Object.values(transitions).filter((transition) =>
    transition.from.includes(currentStatus),
  );

  return (
    <>
      <Button
        data-testid="publishBtn"
        onClick={handlePublishBtnClick}
        className={classes.button}
        variant="outlined"
        endIcon={possibleTransitions.length > 0 && <ExpandMore />}
      >
        {loading ? (
          <Loader color="inherit" thickness={2} size={25} />
        ) : (
          <>
            {label ===
              statusToLabel(ArticleFormat.Rich)[ArticleStatus.Deleted] && (
              <DeleteOutline />
            )}
            {label}
          </>
        )}
      </Button>
      {!loading && possibleTransitions.length > 0 && (
        <Menu
          anchorEl={anchorEl}
          data-testid="statusOverlay"
          open={Boolean(anchorEl)}
          onBlur={handleClose}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {possibleTransitions.map((transition) => {
            const patternErrorMessage = getPatternErrorMessage<ArticleState>(
              articleState,
              transition.patternFields,
            );
            const requiredErrorMessage = getRequiredErrorMessage<ArticleState>(
              articleState,
              transition.mandatoryFields,
            );

            const errorMessagesNodeList = [
              patternErrorMessage,
              requiredErrorMessage,
            ].filter(Boolean);

            if (errorMessagesNodeList.length) {
              return (
                <Tooltip
                  key={transition.label}
                  title={errorMessageRenderer(
                    errorMessagesNodeList as JSX.Element[],
                  )}
                >
                  <div>
                    <MenuItem
                      changeStatus={handleChangeStatus}
                      disabled
                      transition={transition}
                    />
                  </div>
                </Tooltip>
              );
            }
            return (
              <div key={transition.label}>
                <MenuItem
                  changeStatus={handleChangeStatus}
                  transition={transition}
                />
              </div>
            );
          })}
        </Menu>
      )}
    </>
  );
};

export { StatusContainer };
