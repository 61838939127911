import { TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { ArticleEditAction, actions } from '../../reducer';
import { SLUG_LABEL } from '../constants';

interface SlugInputProps {
  dispatch: React.Dispatch<ArticleEditAction>;
  slug: string | null;
}

export const SlugInput: React.FC<SlugInputProps> = ({ dispatch, slug }) => {
  return (
    <TextField
      InputLabelProps={{
        shrink: true,
      }}
      label={SLUG_LABEL}
      multiline
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        dispatch(
          actions.updateField({
            field: 'slug',
            value: e.currentTarget.value,
          }),
        );
      }}
      value={slug || ''}
      variant="standard"
    />
  );
};
