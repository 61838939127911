import {
  FieldValidator,
  getRequiredErrorMessage,
} from '@prismamedia/one-components';
import { BrandKey } from '../../__generated__/queries-web';
import { ArticleState } from './reducer';

const saveVideoMandatoryFields: FieldValidator<ArticleState>[] = [
  {
    label: 'Titre',
    validate: ({ title }) => !!title,
  },
  {
    label: 'Chaîne vidéo',
    validate: ({ brandKey, articleChannels }) =>
      brandKey !== BrandKey.TEL || !!articleChannels.length,
  },
];

export const requiredVideoFields = (article: ArticleState) =>
  getRequiredErrorMessage<ArticleState>(article, saveVideoMandatoryFields);
