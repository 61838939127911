import { BrandKey } from '../__generated__/queries-web';
import { brandsOptions } from './brands';

export const isSlideshow = (pathname: string): boolean =>
  Boolean(pathname.match(/slideshow\//));

export const isOneWeb = (pathname: string): boolean => {
  const regex = new RegExp('/(article|video|orphanpage)/', 'g');
  const regexAuthor = new RegExp(/\/authors/, 'g');
  return Boolean(pathname.match(regex)) || Boolean(pathname.match(regexAuthor));
};

export const isArticleVideo = (pathname: string): boolean =>
  Boolean(pathname.match(/\/video\//));

export const isPush = (pathname: string): boolean =>
  Boolean(pathname.match(/mobilepush\//));
export const isCard = (pathname: string): boolean =>
  Boolean(pathname.match(/card\//));
export const isPage = (pathname: string): boolean =>
  Boolean(pathname.match(/page\//));

export const extractIdFromArticleEdit = (url: string): string | null => {
  const editIndex = url.indexOf('/edit/');
  if (editIndex === -1) return null;
  const idStart = editIndex + 6;
  const idEnd = editIndex + 6 + 36;
  const id = url.slice(idStart, idEnd);
  return id.length < 36 ? null : id;
};

export const standardizeArticleUrl = (url: string | null): string | null => {
  if (!url) return null;
  return !url.startsWith('http') ? `https://${url}` : url;
};

export const formatPreviewUrl = (
  url: string | null,
  brandKey: BrandKey | undefined,
): string | null => {
  if (!url) return null;
  let result = !url.startsWith('http') ? `https://${url}` : url;
  if (brandKey && brandsOptions[brandKey].isCerise) {
    const urlPattern = /^((http|https):\/\/www)/;
    if (urlPattern.test(result)) {
      result = result.replace('https://www.', 'https://news.');
      result = result.replace('http://www.', 'http://news.');
    } else {
      result = result.replace('https://', 'https://news.');
      result = result.replace('http://', 'http://news.');
    }
  }
  return result;
};
