import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  configurationWrapper: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(2, 3),
    marginTop: theme.spacing(7),
  },
  configurationItem: {
    marginTop: theme.spacing(3),
  },
  configurationMainTitle: {
    fontSize: '1.3rem',
    fontWeight: 500,
  },
  configurationSubTitle: {
    fontSize: '1rem',
    fontWeight: 500,

    '& > span': {
      fontWeight: 200,
    },
  },
}));
