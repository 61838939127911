import { formatDate } from '../../../utils/dateUtils';

export const confirmRepublishMessage = `
  Vous allez changer la date de publication de votre article.
  <br />
  Confirmer ?
`;

export const confirmPublishAfterMessage = (publishedAtDate: Date) => `
  Vous allez publier (maintenant) un article dont la date de publication est
  dans le futur.
  <br />
  Etes-vous sûr de ne pas vouloir le programmer pour la date du 
  ${formatDate(publishedAtDate, 'd MMMM yyyy à HH:mm')}
`;

export const confirmScheduleBeforeMessage = (publishedAtDate: Date) => `
  Vous souhaitez Programmer un article dont la date de publication est dans le passé.
  <br />
  Confirmez-vous l'antidatation pour la date du 
  ${formatDate(publishedAtDate, 'd MMMM yyyy à HH:mm')}
`;

export const confirmPublishBeforeMessage = (publishedAtDate: Date) => `
  Vous allez publier (maintenant) un article dont la date de publication est dans le passé.
  <br />
  Etes-vous sur de ne pas vouloir le programmer pour la date du 
  ${formatDate(publishedAtDate, 'd MMMM yyyy à HH:mm')}
`;

export const confirmScheduleAfterMessage = (publishedAtDate: Date) => `
  Vous souhaitez Programmer un article dont la date de publication est dans le futur.
  <br />
  Confirmez-vous la programmation pour la date du
  ${formatDate(publishedAtDate, 'd MMMM yyyy à HH:mm')}
`;

export const confirmScheduleWithoutDateMessage = () => `
  Vous souhaitez Programmer mais vous n’avez pas renseigné la date et l’heure
  de programmation dans le champs prévu à cet effet.
  <br />
  Cliquez sur Annuler pour renseigner cette information ou sur Publier
  maintenant.
`;
