import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    rightContent: {
      display: 'flex',
      alignItems: 'center',

      '& > a': {
        marginRight: theme.spacing(2),
      },
    },
    backArrow: {
      color: 'white',
    },
    leftContent: {
      display: 'flex',
      alignItems: 'center',
    },

    saveButton: {
      border: '1px solid white',
      marginRight: '1rem',
      padding: '5px 15px',
    },
    'MuiOutlinedInput-input': {
      padding: '10px 16px 12px 17px',
    },
    mobileButton: {
      color: theme.palette.common.white,
    },
  }),
);
