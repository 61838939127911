import {
  Loader,
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  DialogActions,
  TextField,
  Typography,
} from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { useMutation, useQuery } from '@apollo/client';
import {
  ArticleStatus,
  BrandKey,
  CompleteSlideshowIA,
  CompleteSlideshowIAVariables,
  GetArticle,
  GetArticleVariables,
} from '../../__generated__/queries-web';
import { SlideshowSelection } from './SlideshowSelection';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import { COMPLETE_SLIDESHOW_IA } from '../../apollo/mutations/completeSlideshow.web.graphql';
import { GET_USER_OPEN_AI_PROCESSES } from '../../apollo/queries/getOpenAiProcess.web.graphql';
import { GET_ARTICLE_QUERY } from '../../apollo/queries/articles.web.graphql';
import { client } from '../../apollo';

export interface SlideshowEditInformations {
  body: string;
  id: string;
  title: string;
}

export const SlideshowCompleteForm = ({
  setActiveTab,
  brandKey,
  slideshowEditId,
}: {
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  brandKey: BrandKey;
  slideshowEditId?: string;
}) => {
  const [slideshowLength, setDiapoLength] = useState<number>(0);
  const [slideshowSubject, setSlideshowSubject] = useState<string>('');

  const { pushNotification } = useNotification();
  const [
    selectedSlideshow,
    setSelectedSlideshow,
  ] = useState<SlideshowEditInformations | null>(null);

  const [completeSlideshowIA] = useMutation<
    CompleteSlideshowIA,
    CompleteSlideshowIAVariables
  >(COMPLETE_SLIDESHOW_IA, {
    onCompleted: () => {
      setActiveTab(2);
      client.refetchQueries({
        include: [GET_USER_OPEN_AI_PROCESSES],
      });
    },
    onError: () => {
      pushNotification({
        message: 'Une erreur est survenue lors de la complétion du diaporama',
        type: NotificationTypeEnum.error,
      });
    },
  });

  const { loading: loadingSlideshowToEdit } = useQuery<
    GetArticle,
    GetArticleVariables
  >(GET_ARTICLE_QUERY, {
    skip: !slideshowEditId,
    variables: {
      where: {
        id: slideshowEditId,
      },
    },
    onCompleted: ({ article }) => {
      if (article) {
        const { title, body, id, status } = article;
        if (status !== ArticleStatus.Draft) {
          return pushNotification({
            message: `Le diaporama doit être en statut "Brouillon" pour être édité, status actuel : ${status}`,
            type: NotificationTypeEnum.error,
          });
        }
        if (!title || !body || !id) {
          return pushNotification({
            message:
              'Le diaporama à éditer ne contient pas de titre ou de body',
            type: NotificationTypeEnum.error,
          });
        }
        setSlideshowSubject(title);
        return setSelectedSlideshow({ title, body, id });
      }
      return pushNotification({
        message: 'Aucun diaporama correspondant à la recherche',
        type: NotificationTypeEnum.error,
      });
    },
    onError: () => {
      pushNotification({
        message:
          'Une erreur est survenue lors de la récupération du diaporama à éditer',
        type: NotificationTypeEnum.error,
      });
    },
  });

  return (
    <form
      onSubmit={(formEvent) => {
        formEvent.preventDefault();
        if (!selectedSlideshow || !slideshowLength || !slideshowSubject) {
          return pushNotification({
            type: NotificationTypeEnum.error,
            message:
              "Merci d'indiquer un diaporama et un nombre de recette à ajouter.",
          });
        }
        try {
          completeSlideshowIA({
            variables: {
              slideshowEditionId: selectedSlideshow.id,
              brandKey,
              recipeNumber: slideshowLength,
              slideshowSubject,
            },
          });
        } catch (e) {
          pushNotification({
            type: NotificationTypeEnum.error,
            message: 'Erreur lors de la génération du diaporama',
          });
        }
      }}
    >
      <Card
        variant="outlined"
        sx={{
          padding: '40px',
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
              mb: '32px',
            }}
          >
            <SlideshowIcon
              fontSize="small"
              sx={{ marginRight: '2px', color: 'text.secondary' }}
            />
            <Typography
              sx={{ fontSize: 14, paddingTop: '1.5px' }}
              color="text.secondary"
              gutterBottom
            >
              Diaporama à compléter
            </Typography>
          </Box>
          <Box
            sx={{
              mb: '24px',
            }}
          >
            {loadingSlideshowToEdit ? (
              <Loader />
            ) : (
              <SlideshowSelection
                brandKey={brandKey}
                selectedSlideshow={selectedSlideshow}
                setSelectedSlideshow={setSelectedSlideshow}
              />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <AutoFixHighIcon
            fontSize="small"
            sx={{ marginRight: '2px', color: 'text.secondary' }}
          />
          <Typography
            sx={{ fontSize: 14, paddingTop: '1.5px' }}
            color="text.secondary"
            gutterBottom
          >
            Options de complétion du diaporama
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            // flexDirection: 'column',
            justifyContent: 'space-between',
            gap: '16px',
          }}
        >
          <TextField
            sx={{
              width: '45%',
            }}
            id="outlined-error"
            type="number"
            label="Nombre de recettes à ajouter"
            value={slideshowLength}
            onChange={(e) => setDiapoLength(parseInt(e.target.value, 10))}
            required
          />
          <TextField
            sx={{
              width: '45%',
            }}
            id="outlined-error-helper-text"
            label="Modifier le texte de la recherche"
            placeholder=""
            required
            value={slideshowSubject}
            onChange={(e) => setSlideshowSubject(e.target.value)}
          />
        </Box>

        <DialogActions>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              data-testid="confirmBtn"
              color="primary"
              onClick={() => null}
              variant="contained"
              type="submit"
            >
              {'Compléter le diaporama'}
            </Button>
          </Box>
        </DialogActions>
      </Card>
    </form>
  );
};
