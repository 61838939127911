import { Button, Grid } from '@mui/material';
import React, { useCallback } from 'react';
import type { FC } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { paths } from '../../../../../../../routing/Router/paths';
import type { Category } from '../../../../../types';
import { navigateTo } from '../../../../../utils';
import {
  CategoryCardActionTypes,
  MAX_CATEGORY_DEPTH_LEVEL,
  MENU_ITEMS,
} from './constants';

interface ActionButtonsProps {
  category: Category;
}

export const ActionButtons: FC<ActionButtonsProps> = ({ category }) => {
  const routeParams = useParams();

  const handleActionClick = useCallback(
    (id: CategoryCardActionTypes) => () => {
      const { brandKey, format } = routeParams;
      const search = new URLSearchParams({ from: 'Card' }).toString();

      switch (id) {
        case CategoryCardActionTypes.MODIFY: {
          const pathname = `${generatePath(paths.PAGE_EDIT, {
            brandKey,
            format,
          })}${category.path}`;

          navigateTo({ pathname, opts: { search } });
          break;
        }

        case CategoryCardActionTypes.CREATE_CHILD: {
          const pathname = `${generatePath(paths.PAGE_CREATE, {
            brandKey,
            format,
          })}${category.path}`;

          navigateTo({ pathname, opts: { search } });
          break;
        }

        case CategoryCardActionTypes.OPEN: {
          const pathname = `${generatePath(paths.PAGE_LIST, {
            brandKey: routeParams.brandKey,
            format: routeParams.format,
          })}${category.path}`;

          navigateTo({ pathname });
          break;
        }
      }
    },
    [category.path, routeParams],
  );

  return (
    <Grid alignItems="flex-end" container spacing={2}>
      {!!category.children?.length && (
        <Grid item>
          <Button
            onClick={handleActionClick(MENU_ITEMS[0].id)}
            startIcon={MENU_ITEMS[0].icon}
            variant="text"
          >
            {MENU_ITEMS[0].label} ({category.children?.length})
          </Button>
        </Grid>
      )}

      {category.level < MAX_CATEGORY_DEPTH_LEVEL && (
        <Grid item>
          <Button
            onClick={handleActionClick(MENU_ITEMS[1].id)}
            variant="text"
            startIcon={MENU_ITEMS[1].icon}
          >
            {MENU_ITEMS[1].label}
          </Button>
        </Grid>
      )}

      {!category.lockedBy && (
        <Grid item>
          <Button
            onClick={handleActionClick(MENU_ITEMS[2].id)}
            startIcon={MENU_ITEMS[2].icon}
            style={{ boxShadow: 'none' }}
            variant="contained"
          >
            {MENU_ITEMS[2].label}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
