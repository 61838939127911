import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: 300,
    flexShrink: 0,
    padding: theme.spacing(2, 4, 1, 0),
    marginLeft: theme.spacing(-1),
    position: 'relative',
  },
  centered: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
  },
  errorIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
}));
