import { TagSearchTypes } from '../../../../__generated__/queries-web';

export const ARTICLE_ENHANCEMENT_PROGRAMS_TITLE = 'Programmes TV/VOD/Ciné';
export const PROVIDER_LABEL = 'provider';
export const CINEMA_PROGRAM_LABEL = 'Programmes Cinéma';
export const TV_PROGRAM_LABEL = 'Programmes TV';
export const VOD_PROGRAM_LABEL = 'Programmes VOD';
export const NO_PROGRAM_SELECTED_TITLE =
  'Aucun programme TV/VOD/Cinéma de selectionné';

export const ALLOWED_TVBY_TYPES: TagSearchTypes[] = [
  TagSearchTypes.Broadcast,
  TagSearchTypes.VOD,
  TagSearchTypes.Cinema,
];

export const PROGRAM_SEARCH_BAR_PLACEHOLDER =
  'Rechercher un programme TV/VOD/Cinéma';
export const PROVIDER_TOOLTIP_TEXT =
  'Au moins un provider est nécessaire pour effectuer une recherche pertinente.';
export const SEARCH_BY_PROVIDER = 'Rechercher par provider';
