/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAssignmentsLinkToTopic
// ====================================================

export interface GetAssignmentsLinkToTopic_assignments_rawArticle_lastVersion {
  __typename: "RawArticleVersion";
  title: string | null;
}

export interface GetAssignmentsLinkToTopic_assignments_rawArticle {
  __typename: "RawArticle";
  /**
   * The public ID
   */
  id: string;
  lastVersion: GetAssignmentsLinkToTopic_assignments_rawArticle_lastVersion | null;
}

export interface GetAssignmentsLinkToTopic_assignments_printHeading_printIssue_printPublication {
  __typename: "PrintPublication";
  title: string;
}

export interface GetAssignmentsLinkToTopic_assignments_printHeading_printIssue {
  __typename: "PrintIssue";
  title: string;
  printPublication: GetAssignmentsLinkToTopic_assignments_printHeading_printIssue_printPublication;
}

export interface GetAssignmentsLinkToTopic_assignments_printHeading {
  __typename: "PrintHeading";
  title: string;
  printIssue: GetAssignmentsLinkToTopic_assignments_printHeading_printIssue;
}

export interface GetAssignmentsLinkToTopic_assignments {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
  rawArticle: GetAssignmentsLinkToTopic_assignments_rawArticle | null;
  printHeading: GetAssignmentsLinkToTopic_assignments_printHeading | null;
}

export interface GetAssignmentsLinkToTopic {
  /**
   * Retrieves a list of "Assignments"
   */
  assignments: GetAssignmentsLinkToTopic_assignments[];
}

export interface GetAssignmentsLinkToTopicVariables {
  where?: AssignmentFilterInput | null;
  orderBy?: AssignmentOrderingInput[] | null;
  offset?: GraphQL$UnsignedInt | null;
  limit: GraphQL$UnsignedInt;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAssignmentsForDuplication
// ====================================================

export interface GetAssignmentsForDuplication_assignments_subject {
  __typename: "Subject";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetAssignmentsForDuplication_assignments_rawArticle_lastVersion {
  __typename: "RawArticleVersion";
  /**
   * The public ID
   */
  id: string;
}

export interface GetAssignmentsForDuplication_assignments_rawArticle {
  __typename: "RawArticle";
  /**
   * The public ID
   */
  id: string;
  lastVersion: GetAssignmentsForDuplication_assignments_rawArticle_lastVersion | null;
}

export interface GetAssignmentsForDuplication_assignments_printHeading_printIssue_printPublication {
  __typename: "PrintPublication";
  title: string;
}

export interface GetAssignmentsForDuplication_assignments_printHeading_printIssue {
  __typename: "PrintIssue";
  title: string;
  printPublication: GetAssignmentsForDuplication_assignments_printHeading_printIssue_printPublication;
}

export interface GetAssignmentsForDuplication_assignments_printHeading {
  __typename: "PrintHeading";
  title: string;
  printIssue: GetAssignmentsForDuplication_assignments_printHeading_printIssue;
}

export interface GetAssignmentsForDuplication_assignments {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
  subject: GetAssignmentsForDuplication_assignments_subject;
  rawArticle: GetAssignmentsForDuplication_assignments_rawArticle | null;
  printHeading: GetAssignmentsForDuplication_assignments_printHeading | null;
}

export interface GetAssignmentsForDuplication {
  /**
   * Retrieves a list of "Assignments"
   */
  assignments: GetAssignmentsForDuplication_assignments[];
}

export interface GetAssignmentsForDuplicationVariables {
  where?: AssignmentFilterInput | null;
  orderBy?: AssignmentOrderingInput[] | null;
  skip?: GraphQL$UnsignedInt | null;
  first: GraphQL$UnsignedInt;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPrintPublicationsForDuplication
// ====================================================

export interface GetPrintPublicationsForDuplication_printPublications {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetPrintPublicationsForDuplication {
  /**
   * Retrieves a list of "PrintPublications"
   */
  printPublications: GetPrintPublicationsForDuplication_printPublications[];
}

export interface GetPrintPublicationsForDuplicationVariables {
  where?: PrintPublicationFilterInput | null;
  orderBy?: PrintPublicationOrderingInput[] | null;
  skip?: GraphQL$UnsignedInt | null;
  first: GraphQL$UnsignedInt;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPrintIssuesForDuplication
// ====================================================

export interface GetPrintIssuesForDuplication_printIssues {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetPrintIssuesForDuplication {
  /**
   * Retrieves a list of "PrintIssues"
   */
  printIssues: GetPrintIssuesForDuplication_printIssues[];
}

export interface GetPrintIssuesForDuplicationVariables {
  where?: PrintIssueFilterInput | null;
  orderBy?: PrintIssueOrderingInput[] | null;
  skip?: GraphQL$UnsignedInt | null;
  first: GraphQL$UnsignedInt;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPrintHeadingsForDuplication
// ====================================================

export interface GetPrintHeadingsForDuplication_printHeadings {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetPrintHeadingsForDuplication {
  /**
   * Retrieves a list of "PrintHeadings"
   */
  printHeadings: GetPrintHeadingsForDuplication_printHeadings[];
}

export interface GetPrintHeadingsForDuplicationVariables {
  where?: PrintHeadingFilterInput | null;
  orderBy?: PrintHeadingOrderingInput[] | null;
  skip?: GraphQL$UnsignedInt | null;
  first: GraphQL$UnsignedInt;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUnitsForDuplication
// ====================================================

export interface GetUnitsForDuplication_units {
  __typename: "Unit";
  /**
   * The public ID
   */
  id: string;
}

export interface GetUnitsForDuplication {
  /**
   * Retrieves a list of "Units"
   */
  units: GetUnitsForDuplication_units[];
}

export interface GetUnitsForDuplicationVariables {
  where?: UnitFilterInput | null;
  orderBy?: UnitOrderingInput[] | null;
  skip?: GraphQL$UnsignedInt | null;
  first: GraphQL$UnsignedInt;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateSubjectForDuplication
// ====================================================

export interface CreateSubjectForDuplication_createSubject_assignments {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
}

export interface CreateSubjectForDuplication_createSubject {
  __typename: "Subject";
  assignments: CreateSubjectForDuplication_createSubject_assignments[];
}

export interface CreateSubjectForDuplication {
  /**
   * Creates one "Subject", throws an error if it already exists
   */
  createSubject: CreateSubjectForDuplication_createSubject;
}

export interface CreateSubjectForDuplicationVariables {
  data: SubjectCreationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAssignmentForDuplication
// ====================================================

export interface UpdateAssignmentForDuplication_updateAssignment {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
}

export interface UpdateAssignmentForDuplication {
  /**
   * Updates one "Assignment", throws an error if it does not exists
   */
  updateAssignment: UpdateAssignmentForDuplication_updateAssignment;
}

export interface UpdateAssignmentForDuplicationVariables {
  data: AssignmentUpdateInput;
  where: AssignmentUniqueFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAssignment
// ====================================================

export interface GetAssignment_assignment_subject {
  __typename: "Subject";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetAssignment_assignment {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
  subject: GetAssignment_assignment_subject;
}

export interface GetAssignment {
  /**
   * Retrieves one "Assignment", throws an error if it does not exist
   */
  assignment: GetAssignment_assignment;
}

export interface GetAssignmentVariables {
  id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRawArticle
// ====================================================

export interface GetRawArticle_rawArticle_assignment_subject {
  __typename: "Subject";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetRawArticle_rawArticle_assignment {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
  subject: GetRawArticle_rawArticle_assignment_subject;
}

export interface GetRawArticle_rawArticle_versions_editor {
  __typename: "User";
  id: string;
  name: string;
  avatarUrl: string | null;
}

export interface GetRawArticle_rawArticle_versions {
  __typename: "RawArticleVersion";
  allowedStatuses: RawArticleStatus[];
  /**
   * The public ID
   */
  id: string;
  title: string | null;
  lead: string | null;
  body: string | null;
  signature: string | null;
  status: RawArticleStatus;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  editor: GetRawArticle_rawArticle_versions_editor | null;
  textIsReadOnly: boolean;
}

export interface GetRawArticle_rawArticle {
  __typename: "RawArticle";
  /**
   * The public ID
   */
  id: string;
  assignment: GetRawArticle_rawArticle_assignment | null;
  versions: GetRawArticle_rawArticle_versions[];
}

export interface GetRawArticle {
  /**
   * Retrieves one "RawArticle", throws an error if it does not exist
   */
  rawArticle: GetRawArticle_rawArticle;
}

export interface GetRawArticleVariables {
  id: string;
  versionId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AssignmentFragment
// ====================================================

export interface AssignmentFragment_subject {
  __typename: "Subject";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface AssignmentFragment {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
  subject: AssignmentFragment_subject;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ArticleForm_RawArticleVersionFragment
// ====================================================

export interface ArticleForm_RawArticleVersionFragment_editor {
  __typename: "User";
  id: string;
  name: string;
  avatarUrl: string | null;
}

export interface ArticleForm_RawArticleVersionFragment {
  __typename: "RawArticleVersion";
  allowedStatuses: RawArticleStatus[];
  /**
   * The public ID
   */
  id: string;
  title: string | null;
  lead: string | null;
  body: string | null;
  signature: string | null;
  status: RawArticleStatus;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  editor: ArticleForm_RawArticleVersionFragment_editor | null;
  textIsReadOnly: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Order the "Assignment" nodes
 */
export enum AssignmentOrderingInput {
  _id_ASC = "_id_ASC",
  _id_DESC = "_id_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  legacyId_ASC = "legacyId_ASC",
  legacyId_DESC = "legacyId_DESC",
  pageNumber_ASC = "pageNumber_ASC",
  pageNumber_DESC = "pageNumber_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum MediaSearchStatus {
  Ready = "Ready",
  Sent = "Sent",
  Started = "Started",
  Waiting = "Waiting",
}

/**
 * Order the "PrintHeading" nodes
 */
export enum PrintHeadingOrderingInput {
  _id_ASC = "_id_ASC",
  _id_DESC = "_id_DESC",
  assignmentCount_ASC = "assignmentCount_ASC",
  assignmentCount_DESC = "assignmentCount_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  flatAssignmentCount_ASC = "flatAssignmentCount_ASC",
  flatAssignmentCount_DESC = "flatAssignmentCount_DESC",
  legacyId_ASC = "legacyId_ASC",
  legacyId_DESC = "legacyId_DESC",
  order_ASC = "order_ASC",
  order_DESC = "order_DESC",
  printTemplateCount_ASC = "printTemplateCount_ASC",
  printTemplateCount_DESC = "printTemplateCount_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/**
 * Order the "PrintIssue" nodes
 */
export enum PrintIssueOrderingInput {
  _id_ASC = "_id_ASC",
  _id_DESC = "_id_DESC",
  assignmentCount_ASC = "assignmentCount_ASC",
  assignmentCount_DESC = "assignmentCount_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  flatAssignmentCount_ASC = "flatAssignmentCount_ASC",
  flatAssignmentCount_DESC = "flatAssignmentCount_DESC",
  legacyId_ASC = "legacyId_ASC",
  legacyId_DESC = "legacyId_DESC",
  printHeadingCount_ASC = "printHeadingCount_ASC",
  printHeadingCount_DESC = "printHeadingCount_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/**
 * Order the "PrintPublication" nodes
 */
export enum PrintPublicationOrderingInput {
  _id_ASC = "_id_ASC",
  _id_DESC = "_id_DESC",
  assignmentCount_ASC = "assignmentCount_ASC",
  assignmentCount_DESC = "assignmentCount_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  flatAssignmentCount_ASC = "flatAssignmentCount_ASC",
  flatAssignmentCount_DESC = "flatAssignmentCount_DESC",
  legacyId_ASC = "legacyId_ASC",
  legacyId_DESC = "legacyId_DESC",
  printIssueCount_ASC = "printIssueCount_ASC",
  printIssueCount_DESC = "printIssueCount_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum RawArticleStatus {
  Correcting = "Correcting",
  Correction = "Correction",
  Editing = "Editing",
  Mockup = "Mockup",
  Validating = "Validating",
  Validation = "Validation",
}

export enum TemplateType {
  ARTICLE_TEMPLATE = "ARTICLE_TEMPLATE",
  GAME_TEMPLATE = "GAME_TEMPLATE",
  MULTIPLE_ARTICLE_TEMPLATE = "MULTIPLE_ARTICLE_TEMPLATE",
}

/**
 * Order the "Unit" nodes
 */
export enum UnitOrderingInput {
  _id_ASC = "_id_ASC",
  _id_DESC = "_id_DESC",
  brandCount_ASC = "brandCount_ASC",
  brandCount_DESC = "brandCount_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  subjectCategoryCount_ASC = "subjectCategoryCount_ASC",
  subjectCategoryCount_DESC = "subjectCategoryCount_DESC",
  subjectCount_ASC = "subjectCount_ASC",
  subjectCount_DESC = "subjectCount_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export interface ArticleTemplateCreationAssignmentsInput {
  create?: AssignmentCreationWithoutArticleTemplateInput[] | null;
}

/**
 * The "ArticleTemplate" node's creation
 */
export interface ArticleTemplateCreationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  printTemplate: ArticleTemplateCreationPrintTemplateInput;
  label?: string | null;
  block?: string | null;
  assignments?: ArticleTemplateCreationAssignmentsInput | null;
}

export interface ArticleTemplateCreationPrintTemplateCreateIfNotExistsInput {
  where: PrintTemplateUniqueFilterInput;
  data: PrintTemplateCreationInput;
}

export interface ArticleTemplateCreationPrintTemplateInput {
  connect?: PrintTemplateUniqueFilterInput | null;
  create?: PrintTemplateCreationInput | null;
  createIfNotExists?: ArticleTemplateCreationPrintTemplateCreateIfNotExistsInput | null;
}

/**
 * The "ArticleTemplate" node's creation
 */
export interface ArticleTemplateCreationWithoutPrintTemplateInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  label?: string | null;
  block?: string | null;
  assignments?: ArticleTemplateCreationAssignmentsInput | null;
}

/**
 * The "ArticleTemplate" nodes' filter
 */
export interface ArticleTemplateFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  printTemplate?: PrintTemplateFilterInput | null;
  printTemplate_not?: PrintTemplateFilterInput | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  label?: string | null;
  label_not?: string | null;
  label_in?: (string | null)[] | null;
  label_not_in?: (string | null)[] | null;
  label_is_null?: boolean | null;
  label_contains?: GraphQL$NonEmptyString | null;
  label_not_contains?: GraphQL$NonEmptyString | null;
  label_starts_with?: GraphQL$NonEmptyString | null;
  label_not_starts_with?: GraphQL$NonEmptyString | null;
  label_ends_with?: GraphQL$NonEmptyString | null;
  label_not_ends_with?: GraphQL$NonEmptyString | null;
  block?: string | null;
  block_not?: string | null;
  block_in?: (string | null)[] | null;
  block_not_in?: (string | null)[] | null;
  block_is_null?: boolean | null;
  block_contains?: GraphQL$NonEmptyString | null;
  block_not_contains?: GraphQL$NonEmptyString | null;
  block_starts_with?: GraphQL$NonEmptyString | null;
  block_not_starts_with?: GraphQL$NonEmptyString | null;
  block_ends_with?: GraphQL$NonEmptyString | null;
  block_not_ends_with?: GraphQL$NonEmptyString | null;
  assignments_every?: AssignmentFilterInput | null;
  assignments_some?: AssignmentFilterInput | null;
  assignments_none?: AssignmentFilterInput | null;
  assignmentCount?: GraphQL$UnsignedInt | null;
  assignmentCount_not?: GraphQL$UnsignedInt | null;
  assignmentCount_gt?: GraphQL$UnsignedInt | null;
  assignmentCount_gte?: GraphQL$UnsignedInt | null;
  assignmentCount_lt?: GraphQL$UnsignedInt | null;
  assignmentCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (ArticleTemplateFilterInput | null)[] | null;
  OR?: (ArticleTemplateFilterInput | null)[] | null;
  NOT?: ArticleTemplateFilterInput | null;
}

/**
 * Identifies exactly one "ArticleTemplate" with one of the following combination of components' value:
 * - _id
 * - id
 */
export interface ArticleTemplateUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
}

export interface ArticleTemplateUpdateAssignmentsInput {
  deleteAll?: boolean | null;
  deleteMany?: AssignmentFilterInput | null;
  create?: AssignmentCreationWithoutArticleTemplateInput[] | null;
  updateAll?: AssignmentUpdateWithoutArticleTemplateInput | null;
  updateMany?: ArticleTemplateUpdateAssignmentsUpdateManyInput | null;
}

export interface ArticleTemplateUpdateAssignmentsUpdateManyInput {
  where?: AssignmentFilterInput | null;
  data?: AssignmentUpdateWithoutArticleTemplateInput | null;
}

/**
 * The "ArticleTemplate" node's update
 */
export interface ArticleTemplateUpdateInput {
  printTemplate?: ArticleTemplateUpdatePrintTemplateInput | null;
  label?: string | null;
  block?: string | null;
  assignments?: ArticleTemplateUpdateAssignmentsInput | null;
}

export interface ArticleTemplateUpdatePrintTemplateCreateIfNotExistsInput {
  where: PrintTemplateUniqueFilterInput;
  data: PrintTemplateCreationInput;
}

export interface ArticleTemplateUpdatePrintTemplateInput {
  connect?: PrintTemplateUniqueFilterInput | null;
  create?: PrintTemplateCreationInput | null;
  createIfNotExists?: ArticleTemplateUpdatePrintTemplateCreateIfNotExistsInput | null;
  update?: PrintTemplateUpdateInput | null;
}

/**
 * The "ArticleTemplate" node's update
 */
export interface ArticleTemplateUpdateWithoutPrintTemplateInput {
  label?: string | null;
  block?: string | null;
  assignments?: ArticleTemplateUpdateAssignmentsInput | null;
}

export interface AssignmentCreationArticleTemplateCreateIfNotExistsInput {
  where: ArticleTemplateUniqueFilterInput;
  data: ArticleTemplateCreationInput;
}

export interface AssignmentCreationArticleTemplateInput {
  connect?: ArticleTemplateUniqueFilterInput | null;
  connectIfExists?: ArticleTemplateUniqueFilterInput | null;
  create?: ArticleTemplateCreationInput | null;
  createIfNotExists?: AssignmentCreationArticleTemplateCreateIfNotExistsInput | null;
}

export interface AssignmentCreationPrintHeadingCreateIfNotExistsInput {
  where: PrintHeadingUniqueFilterInput;
  data: PrintHeadingCreationInput;
}

export interface AssignmentCreationPrintHeadingInput {
  connect?: PrintHeadingUniqueFilterInput | null;
  connectIfExists?: PrintHeadingUniqueFilterInput | null;
  create?: PrintHeadingCreationInput | null;
  createIfNotExists?: AssignmentCreationPrintHeadingCreateIfNotExistsInput | null;
}

export interface AssignmentCreationPrintIssueCreateIfNotExistsInput {
  where: PrintIssueUniqueFilterInput;
  data: PrintIssueCreationInput;
}

export interface AssignmentCreationPrintIssueInput {
  connect?: PrintIssueUniqueFilterInput | null;
  connectIfExists?: PrintIssueUniqueFilterInput | null;
  create?: PrintIssueCreationInput | null;
  createIfNotExists?: AssignmentCreationPrintIssueCreateIfNotExistsInput | null;
}

export interface AssignmentCreationPrintPublicationCreateIfNotExistsInput {
  where: PrintPublicationUniqueFilterInput;
  data: PrintPublicationCreationInput;
}

export interface AssignmentCreationPrintPublicationInput {
  connect?: PrintPublicationUniqueFilterInput | null;
  connectIfExists?: PrintPublicationUniqueFilterInput | null;
  create?: PrintPublicationCreationInput | null;
  createIfNotExists?: AssignmentCreationPrintPublicationCreateIfNotExistsInput | null;
}

export interface AssignmentCreationPrintTemplateCreateIfNotExistsInput {
  where: PrintTemplateUniqueFilterInput;
  data: PrintTemplateCreationInput;
}

export interface AssignmentCreationPrintTemplateInput {
  connect?: PrintTemplateUniqueFilterInput | null;
  connectIfExists?: PrintTemplateUniqueFilterInput | null;
  create?: PrintTemplateCreationInput | null;
  createIfNotExists?: AssignmentCreationPrintTemplateCreateIfNotExistsInput | null;
}

export interface AssignmentCreationRawArticleCreateIfNotExistsInput {
  where: RawArticleUniqueFilterInput;
  data: RawArticleCreationInput;
}

export interface AssignmentCreationRawArticleInput {
  connect?: RawArticleUniqueFilterInput | null;
  connectIfExists?: RawArticleUniqueFilterInput | null;
  create?: RawArticleCreationInput | null;
  createIfNotExists?: AssignmentCreationRawArticleCreateIfNotExistsInput | null;
}

export interface AssignmentCreationSubjectCreateIfNotExistsInput {
  where: SubjectUniqueFilterInput;
  data: SubjectCreationInput;
}

export interface AssignmentCreationSubjectInput {
  connect?: SubjectUniqueFilterInput | null;
  create?: SubjectCreationInput | null;
  createIfNotExists?: AssignmentCreationSubjectCreateIfNotExistsInput | null;
}

export interface AssignmentCreationWebsiteCreateIfNotExistsInput {
  where: WebsiteUniqueFilterInput;
  data: WebsiteCreationInput;
}

export interface AssignmentCreationWebsiteInput {
  connect?: WebsiteUniqueFilterInput | null;
  connectIfExists?: WebsiteUniqueFilterInput | null;
  create?: WebsiteCreationInput | null;
  createIfNotExists?: AssignmentCreationWebsiteCreateIfNotExistsInput | null;
}

/**
 * The "Assignment" node's creation
 */
export interface AssignmentCreationWithoutArticleTemplateInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentCreationPrintPublicationInput | null;
  printIssue?: AssignmentCreationPrintIssueInput | null;
  printHeading?: AssignmentCreationPrintHeadingInput | null;
  website?: AssignmentCreationWebsiteInput | null;
  rawArticle?: AssignmentCreationRawArticleInput | null;
  subject: AssignmentCreationSubjectInput;
  printTemplate?: AssignmentCreationPrintTemplateInput | null;
}

/**
 * The "Assignment" node's creation
 */
export interface AssignmentCreationWithoutPrintHeadingInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentCreationPrintPublicationInput | null;
  printIssue?: AssignmentCreationPrintIssueInput | null;
  website?: AssignmentCreationWebsiteInput | null;
  rawArticle?: AssignmentCreationRawArticleInput | null;
  subject: AssignmentCreationSubjectInput;
  articleTemplate?: AssignmentCreationArticleTemplateInput | null;
  printTemplate?: AssignmentCreationPrintTemplateInput | null;
}

/**
 * The "Assignment" node's creation
 */
export interface AssignmentCreationWithoutPrintIssueInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentCreationPrintPublicationInput | null;
  printHeading?: AssignmentCreationPrintHeadingInput | null;
  website?: AssignmentCreationWebsiteInput | null;
  rawArticle?: AssignmentCreationRawArticleInput | null;
  subject: AssignmentCreationSubjectInput;
  articleTemplate?: AssignmentCreationArticleTemplateInput | null;
  printTemplate?: AssignmentCreationPrintTemplateInput | null;
}

/**
 * The "Assignment" node's creation
 */
export interface AssignmentCreationWithoutPrintPublicationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printIssue?: AssignmentCreationPrintIssueInput | null;
  printHeading?: AssignmentCreationPrintHeadingInput | null;
  website?: AssignmentCreationWebsiteInput | null;
  rawArticle?: AssignmentCreationRawArticleInput | null;
  subject: AssignmentCreationSubjectInput;
  articleTemplate?: AssignmentCreationArticleTemplateInput | null;
  printTemplate?: AssignmentCreationPrintTemplateInput | null;
}

/**
 * The "Assignment" node's creation
 */
export interface AssignmentCreationWithoutPrintTemplateInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentCreationPrintPublicationInput | null;
  printIssue?: AssignmentCreationPrintIssueInput | null;
  printHeading?: AssignmentCreationPrintHeadingInput | null;
  website?: AssignmentCreationWebsiteInput | null;
  rawArticle?: AssignmentCreationRawArticleInput | null;
  subject: AssignmentCreationSubjectInput;
  articleTemplate?: AssignmentCreationArticleTemplateInput | null;
}

/**
 * The "Assignment" node's creation
 */
export interface AssignmentCreationWithoutRawArticleInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentCreationPrintPublicationInput | null;
  printIssue?: AssignmentCreationPrintIssueInput | null;
  printHeading?: AssignmentCreationPrintHeadingInput | null;
  website?: AssignmentCreationWebsiteInput | null;
  subject: AssignmentCreationSubjectInput;
  articleTemplate?: AssignmentCreationArticleTemplateInput | null;
  printTemplate?: AssignmentCreationPrintTemplateInput | null;
}

/**
 * The "Assignment" node's creation
 */
export interface AssignmentCreationWithoutSubjectInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentCreationPrintPublicationInput | null;
  printIssue?: AssignmentCreationPrintIssueInput | null;
  printHeading?: AssignmentCreationPrintHeadingInput | null;
  website?: AssignmentCreationWebsiteInput | null;
  rawArticle?: AssignmentCreationRawArticleInput | null;
  articleTemplate?: AssignmentCreationArticleTemplateInput | null;
  printTemplate?: AssignmentCreationPrintTemplateInput | null;
}

/**
 * The "Assignment" node's creation
 */
export interface AssignmentCreationWithoutWebsiteInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentCreationPrintPublicationInput | null;
  printIssue?: AssignmentCreationPrintIssueInput | null;
  printHeading?: AssignmentCreationPrintHeadingInput | null;
  rawArticle?: AssignmentCreationRawArticleInput | null;
  subject: AssignmentCreationSubjectInput;
  articleTemplate?: AssignmentCreationArticleTemplateInput | null;
  printTemplate?: AssignmentCreationPrintTemplateInput | null;
}

/**
 * The "Assignment" nodes' filter
 */
export interface AssignmentFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  mediaSearchStatus?: MediaSearchStatus | null;
  mediaSearchStatus_not?: MediaSearchStatus | null;
  mediaSearchStatus_in?: MediaSearchStatus[] | null;
  mediaSearchStatus_not_in?: MediaSearchStatus[] | null;
  legacyId?: number | null;
  legacyId_not?: number | null;
  legacyId_in?: (number | null)[] | null;
  legacyId_not_in?: (number | null)[] | null;
  legacyId_gt?: number | null;
  legacyId_gte?: number | null;
  legacyId_lt?: number | null;
  legacyId_lte?: number | null;
  legacyId_is_null?: boolean | null;
  pageNumber?: number | null;
  pageNumber_not?: number | null;
  pageNumber_in?: (number | null)[] | null;
  pageNumber_not_in?: (number | null)[] | null;
  pageNumber_gt?: number | null;
  pageNumber_gte?: number | null;
  pageNumber_lt?: number | null;
  pageNumber_lte?: number | null;
  pageNumber_is_null?: boolean | null;
  printPublication?: PrintPublicationFilterInput | null;
  printPublication_not?: PrintPublicationFilterInput | null;
  printPublication_is_null?: boolean | null;
  printIssue?: PrintIssueFilterInput | null;
  printIssue_not?: PrintIssueFilterInput | null;
  printIssue_is_null?: boolean | null;
  printHeading?: PrintHeadingFilterInput | null;
  printHeading_not?: PrintHeadingFilterInput | null;
  printHeading_is_null?: boolean | null;
  website?: WebsiteFilterInput | null;
  website_not?: WebsiteFilterInput | null;
  website_is_null?: boolean | null;
  rawArticle?: RawArticleFilterInput | null;
  rawArticle_not?: RawArticleFilterInput | null;
  rawArticle_is_null?: boolean | null;
  subject?: SubjectFilterInput | null;
  subject_not?: SubjectFilterInput | null;
  articleTemplate?: ArticleTemplateFilterInput | null;
  articleTemplate_not?: ArticleTemplateFilterInput | null;
  articleTemplate_is_null?: boolean | null;
  printTemplate?: PrintTemplateFilterInput | null;
  printTemplate_not?: PrintTemplateFilterInput | null;
  printTemplate_is_null?: boolean | null;
  flatAssignment?: FlatAssignmentFilterInput | null;
  flatAssignment_not?: FlatAssignmentFilterInput | null;
  flatAssignment_is_null?: boolean | null;
  AND?: (AssignmentFilterInput | null)[] | null;
  OR?: (AssignmentFilterInput | null)[] | null;
  NOT?: AssignmentFilterInput | null;
}

/**
 * Identifies exactly one "Assignment" with one of the following combination of components' value:
 * - _id
 * - id
 * - subject / legacyId
 * - rawArticle
 */
export interface AssignmentUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  subject?: SubjectUniqueFilterInput | null;
  legacyId?: number | null;
  rawArticle?: RawArticleUniqueFilterInput | null;
}

/**
 * Given a "subject", identifies exactly one "Assignment" with one of the following combination of components' value:
 * - (subject) / legacyId
 */
export interface AssignmentUniqueFilterWithoutSubjectInput {
  legacyId?: number | null;
}

export interface AssignmentUpdateArticleTemplateCreateIfNotExistsInput {
  where: ArticleTemplateUniqueFilterInput;
  data: ArticleTemplateCreationInput;
}

export interface AssignmentUpdateArticleTemplateInput {
  connect?: ArticleTemplateUniqueFilterInput | null;
  connectIfExists?: ArticleTemplateUniqueFilterInput | null;
  disconnect?: boolean | null;
  disconnectIfExists?: boolean | null;
  create?: ArticleTemplateCreationInput | null;
  createIfNotExists?: AssignmentUpdateArticleTemplateCreateIfNotExistsInput | null;
  update?: ArticleTemplateUpdateInput | null;
  updateIfExists?: ArticleTemplateUpdateInput | null;
}

/**
 * The "Assignment" node's update
 */
export interface AssignmentUpdateInput {
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentUpdatePrintPublicationInput | null;
  printIssue?: AssignmentUpdatePrintIssueInput | null;
  printHeading?: AssignmentUpdatePrintHeadingInput | null;
  website?: AssignmentUpdateWebsiteInput | null;
  rawArticle?: AssignmentUpdateRawArticleInput | null;
  subject?: AssignmentUpdateSubjectInput | null;
  articleTemplate?: AssignmentUpdateArticleTemplateInput | null;
  printTemplate?: AssignmentUpdatePrintTemplateInput | null;
}

export interface AssignmentUpdatePrintHeadingCreateIfNotExistsInput {
  where: PrintHeadingUniqueFilterInput;
  data: PrintHeadingCreationInput;
}

export interface AssignmentUpdatePrintHeadingInput {
  connect?: PrintHeadingUniqueFilterInput | null;
  connectIfExists?: PrintHeadingUniqueFilterInput | null;
  disconnect?: boolean | null;
  disconnectIfExists?: boolean | null;
  create?: PrintHeadingCreationInput | null;
  createIfNotExists?: AssignmentUpdatePrintHeadingCreateIfNotExistsInput | null;
  update?: PrintHeadingUpdateInput | null;
  updateIfExists?: PrintHeadingUpdateInput | null;
}

export interface AssignmentUpdatePrintIssueCreateIfNotExistsInput {
  where: PrintIssueUniqueFilterInput;
  data: PrintIssueCreationInput;
}

export interface AssignmentUpdatePrintIssueInput {
  connect?: PrintIssueUniqueFilterInput | null;
  connectIfExists?: PrintIssueUniqueFilterInput | null;
  disconnect?: boolean | null;
  disconnectIfExists?: boolean | null;
  create?: PrintIssueCreationInput | null;
  createIfNotExists?: AssignmentUpdatePrintIssueCreateIfNotExistsInput | null;
  update?: PrintIssueUpdateInput | null;
  updateIfExists?: PrintIssueUpdateInput | null;
}

export interface AssignmentUpdatePrintPublicationCreateIfNotExistsInput {
  where: PrintPublicationUniqueFilterInput;
  data: PrintPublicationCreationInput;
}

export interface AssignmentUpdatePrintPublicationInput {
  connect?: PrintPublicationUniqueFilterInput | null;
  connectIfExists?: PrintPublicationUniqueFilterInput | null;
  disconnect?: boolean | null;
  disconnectIfExists?: boolean | null;
  create?: PrintPublicationCreationInput | null;
  createIfNotExists?: AssignmentUpdatePrintPublicationCreateIfNotExistsInput | null;
  update?: PrintPublicationUpdateInput | null;
  updateIfExists?: PrintPublicationUpdateInput | null;
}

export interface AssignmentUpdatePrintTemplateCreateIfNotExistsInput {
  where: PrintTemplateUniqueFilterInput;
  data: PrintTemplateCreationInput;
}

export interface AssignmentUpdatePrintTemplateInput {
  connect?: PrintTemplateUniqueFilterInput | null;
  connectIfExists?: PrintTemplateUniqueFilterInput | null;
  disconnect?: boolean | null;
  disconnectIfExists?: boolean | null;
  create?: PrintTemplateCreationInput | null;
  createIfNotExists?: AssignmentUpdatePrintTemplateCreateIfNotExistsInput | null;
  update?: PrintTemplateUpdateInput | null;
  updateIfExists?: PrintTemplateUpdateInput | null;
}

export interface AssignmentUpdateRawArticleCreateIfNotExistsInput {
  where: RawArticleUniqueFilterInput;
  data: RawArticleCreationInput;
}

export interface AssignmentUpdateRawArticleInput {
  connect?: RawArticleUniqueFilterInput | null;
  connectIfExists?: RawArticleUniqueFilterInput | null;
  disconnect?: boolean | null;
  disconnectIfExists?: boolean | null;
  create?: RawArticleCreationInput | null;
  createIfNotExists?: AssignmentUpdateRawArticleCreateIfNotExistsInput | null;
  update?: RawArticleUpdateInput | null;
  updateIfExists?: RawArticleUpdateInput | null;
}

export interface AssignmentUpdateSubjectCreateIfNotExistsInput {
  where: SubjectUniqueFilterInput;
  data: SubjectCreationInput;
}

export interface AssignmentUpdateSubjectInput {
  connect?: SubjectUniqueFilterInput | null;
  create?: SubjectCreationInput | null;
  createIfNotExists?: AssignmentUpdateSubjectCreateIfNotExistsInput | null;
  update?: SubjectUpdateInput | null;
}

export interface AssignmentUpdateWebsiteCreateIfNotExistsInput {
  where: WebsiteUniqueFilterInput;
  data: WebsiteCreationInput;
}

export interface AssignmentUpdateWebsiteInput {
  connect?: WebsiteUniqueFilterInput | null;
  connectIfExists?: WebsiteUniqueFilterInput | null;
  disconnect?: boolean | null;
  disconnectIfExists?: boolean | null;
  create?: WebsiteCreationInput | null;
  createIfNotExists?: AssignmentUpdateWebsiteCreateIfNotExistsInput | null;
  update?: WebsiteUpdateInput | null;
  updateIfExists?: WebsiteUpdateInput | null;
}

/**
 * The "Assignment" node's update
 */
export interface AssignmentUpdateWithoutArticleTemplateInput {
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentUpdatePrintPublicationInput | null;
  printIssue?: AssignmentUpdatePrintIssueInput | null;
  printHeading?: AssignmentUpdatePrintHeadingInput | null;
  website?: AssignmentUpdateWebsiteInput | null;
  rawArticle?: AssignmentUpdateRawArticleInput | null;
  subject?: AssignmentUpdateSubjectInput | null;
  printTemplate?: AssignmentUpdatePrintTemplateInput | null;
}

/**
 * The "Assignment" node's update
 */
export interface AssignmentUpdateWithoutPrintHeadingInput {
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentUpdatePrintPublicationInput | null;
  printIssue?: AssignmentUpdatePrintIssueInput | null;
  website?: AssignmentUpdateWebsiteInput | null;
  rawArticle?: AssignmentUpdateRawArticleInput | null;
  subject?: AssignmentUpdateSubjectInput | null;
  articleTemplate?: AssignmentUpdateArticleTemplateInput | null;
  printTemplate?: AssignmentUpdatePrintTemplateInput | null;
}

/**
 * The "Assignment" node's update
 */
export interface AssignmentUpdateWithoutPrintIssueInput {
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentUpdatePrintPublicationInput | null;
  printHeading?: AssignmentUpdatePrintHeadingInput | null;
  website?: AssignmentUpdateWebsiteInput | null;
  rawArticle?: AssignmentUpdateRawArticleInput | null;
  subject?: AssignmentUpdateSubjectInput | null;
  articleTemplate?: AssignmentUpdateArticleTemplateInput | null;
  printTemplate?: AssignmentUpdatePrintTemplateInput | null;
}

/**
 * The "Assignment" node's update
 */
export interface AssignmentUpdateWithoutPrintPublicationInput {
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printIssue?: AssignmentUpdatePrintIssueInput | null;
  printHeading?: AssignmentUpdatePrintHeadingInput | null;
  website?: AssignmentUpdateWebsiteInput | null;
  rawArticle?: AssignmentUpdateRawArticleInput | null;
  subject?: AssignmentUpdateSubjectInput | null;
  articleTemplate?: AssignmentUpdateArticleTemplateInput | null;
  printTemplate?: AssignmentUpdatePrintTemplateInput | null;
}

/**
 * The "Assignment" node's update
 */
export interface AssignmentUpdateWithoutPrintTemplateInput {
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentUpdatePrintPublicationInput | null;
  printIssue?: AssignmentUpdatePrintIssueInput | null;
  printHeading?: AssignmentUpdatePrintHeadingInput | null;
  website?: AssignmentUpdateWebsiteInput | null;
  rawArticle?: AssignmentUpdateRawArticleInput | null;
  subject?: AssignmentUpdateSubjectInput | null;
  articleTemplate?: AssignmentUpdateArticleTemplateInput | null;
}

/**
 * The "Assignment" node's update
 */
export interface AssignmentUpdateWithoutRawArticleInput {
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentUpdatePrintPublicationInput | null;
  printIssue?: AssignmentUpdatePrintIssueInput | null;
  printHeading?: AssignmentUpdatePrintHeadingInput | null;
  website?: AssignmentUpdateWebsiteInput | null;
  subject?: AssignmentUpdateSubjectInput | null;
  articleTemplate?: AssignmentUpdateArticleTemplateInput | null;
  printTemplate?: AssignmentUpdatePrintTemplateInput | null;
}

/**
 * The "Assignment" node's update
 */
export interface AssignmentUpdateWithoutSubjectInput {
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentUpdatePrintPublicationInput | null;
  printIssue?: AssignmentUpdatePrintIssueInput | null;
  printHeading?: AssignmentUpdatePrintHeadingInput | null;
  website?: AssignmentUpdateWebsiteInput | null;
  rawArticle?: AssignmentUpdateRawArticleInput | null;
  articleTemplate?: AssignmentUpdateArticleTemplateInput | null;
  printTemplate?: AssignmentUpdatePrintTemplateInput | null;
}

/**
 * The "Assignment" node's update
 */
export interface AssignmentUpdateWithoutWebsiteInput {
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentUpdatePrintPublicationInput | null;
  printIssue?: AssignmentUpdatePrintIssueInput | null;
  printHeading?: AssignmentUpdatePrintHeadingInput | null;
  rawArticle?: AssignmentUpdateRawArticleInput | null;
  subject?: AssignmentUpdateSubjectInput | null;
  articleTemplate?: AssignmentUpdateArticleTemplateInput | null;
  printTemplate?: AssignmentUpdatePrintTemplateInput | null;
}

/**
 * The "Attachment" node's creation
 */
export interface AttachmentCreationWithoutSubjectInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  filename: string;
  filesize?: number | null;
  versionId?: string | null;
  contentType?: string | null;
  etag?: string | null;
  isUploaded?: boolean | null;
}

/**
 * The "Attachment" nodes' filter
 */
export interface AttachmentFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  filename?: string | null;
  filename_not?: string | null;
  filename_in?: string[] | null;
  filename_not_in?: string[] | null;
  filename_contains?: GraphQL$NonEmptyString | null;
  filename_not_contains?: GraphQL$NonEmptyString | null;
  filename_starts_with?: GraphQL$NonEmptyString | null;
  filename_not_starts_with?: GraphQL$NonEmptyString | null;
  filename_ends_with?: GraphQL$NonEmptyString | null;
  filename_not_ends_with?: GraphQL$NonEmptyString | null;
  filesize?: number | null;
  filesize_not?: number | null;
  filesize_in?: (number | null)[] | null;
  filesize_not_in?: (number | null)[] | null;
  filesize_gt?: number | null;
  filesize_gte?: number | null;
  filesize_lt?: number | null;
  filesize_lte?: number | null;
  filesize_is_null?: boolean | null;
  versionId?: string | null;
  versionId_not?: string | null;
  versionId_in?: (string | null)[] | null;
  versionId_not_in?: (string | null)[] | null;
  versionId_is_null?: boolean | null;
  versionId_contains?: GraphQL$NonEmptyString | null;
  versionId_not_contains?: GraphQL$NonEmptyString | null;
  versionId_starts_with?: GraphQL$NonEmptyString | null;
  versionId_not_starts_with?: GraphQL$NonEmptyString | null;
  versionId_ends_with?: GraphQL$NonEmptyString | null;
  versionId_not_ends_with?: GraphQL$NonEmptyString | null;
  contentType?: string | null;
  contentType_not?: string | null;
  contentType_in?: (string | null)[] | null;
  contentType_not_in?: (string | null)[] | null;
  contentType_is_null?: boolean | null;
  contentType_contains?: GraphQL$NonEmptyString | null;
  contentType_not_contains?: GraphQL$NonEmptyString | null;
  contentType_starts_with?: GraphQL$NonEmptyString | null;
  contentType_not_starts_with?: GraphQL$NonEmptyString | null;
  contentType_ends_with?: GraphQL$NonEmptyString | null;
  contentType_not_ends_with?: GraphQL$NonEmptyString | null;
  etag?: string | null;
  etag_not?: string | null;
  etag_in?: (string | null)[] | null;
  etag_not_in?: (string | null)[] | null;
  etag_is_null?: boolean | null;
  etag_contains?: GraphQL$NonEmptyString | null;
  etag_not_contains?: GraphQL$NonEmptyString | null;
  etag_starts_with?: GraphQL$NonEmptyString | null;
  etag_not_starts_with?: GraphQL$NonEmptyString | null;
  etag_ends_with?: GraphQL$NonEmptyString | null;
  etag_not_ends_with?: GraphQL$NonEmptyString | null;
  isUploaded?: boolean | null;
  isUploaded_not?: boolean | null;
  isUploaded_in?: (boolean | null)[] | null;
  isUploaded_not_in?: (boolean | null)[] | null;
  isUploaded_is_null?: boolean | null;
  subject?: SubjectFilterInput | null;
  subject_not?: SubjectFilterInput | null;
  AND?: (AttachmentFilterInput | null)[] | null;
  OR?: (AttachmentFilterInput | null)[] | null;
  NOT?: AttachmentFilterInput | null;
}

/**
 * The "Attachment" node's update
 */
export interface AttachmentUpdateWithoutSubjectInput {
  filename?: string | null;
  filesize?: number | null;
  versionId?: string | null;
  contentType?: string | null;
  etag?: string | null;
  isUploaded?: boolean | null;
}

/**
 * The "Brand" node's creation
 */
export interface BrandCreationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title: string;
  unit: BrandCreationUnitInput;
  printPublications?: BrandCreationPrintPublicationsInput | null;
  websites?: BrandCreationWebsitesInput | null;
}

export interface BrandCreationPrintPublicationsInput {
  create?: PrintPublicationCreationWithoutBrandInput[] | null;
}

export interface BrandCreationUnitCreateIfNotExistsInput {
  where: UnitUniqueFilterInput;
  data: UnitCreationInput;
}

export interface BrandCreationUnitInput {
  connect?: UnitUniqueFilterInput | null;
  create?: UnitCreationInput | null;
  createIfNotExists?: BrandCreationUnitCreateIfNotExistsInput | null;
}

export interface BrandCreationWebsitesInput {
  create?: WebsiteCreationWithoutBrandInput[] | null;
}

/**
 * The "Brand" node's creation
 */
export interface BrandCreationWithoutUnitInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title: string;
  printPublications?: BrandCreationPrintPublicationsInput | null;
  websites?: BrandCreationWebsitesInput | null;
}

/**
 * The "Brand" nodes' filter
 */
export interface BrandFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_gt?: string | null;
  title_gte?: string | null;
  title_lt?: string | null;
  title_lte?: string | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  unit?: UnitFilterInput | null;
  unit_not?: UnitFilterInput | null;
  printPublications_every?: PrintPublicationFilterInput | null;
  printPublications_some?: PrintPublicationFilterInput | null;
  printPublications_none?: PrintPublicationFilterInput | null;
  printPublicationCount?: GraphQL$UnsignedInt | null;
  printPublicationCount_not?: GraphQL$UnsignedInt | null;
  printPublicationCount_gt?: GraphQL$UnsignedInt | null;
  printPublicationCount_gte?: GraphQL$UnsignedInt | null;
  printPublicationCount_lt?: GraphQL$UnsignedInt | null;
  printPublicationCount_lte?: GraphQL$UnsignedInt | null;
  websites_every?: WebsiteFilterInput | null;
  websites_some?: WebsiteFilterInput | null;
  websites_none?: WebsiteFilterInput | null;
  websiteCount?: GraphQL$UnsignedInt | null;
  websiteCount_not?: GraphQL$UnsignedInt | null;
  websiteCount_gt?: GraphQL$UnsignedInt | null;
  websiteCount_gte?: GraphQL$UnsignedInt | null;
  websiteCount_lt?: GraphQL$UnsignedInt | null;
  websiteCount_lte?: GraphQL$UnsignedInt | null;
  flatAssignments_every?: FlatAssignmentFilterInput | null;
  flatAssignments_some?: FlatAssignmentFilterInput | null;
  flatAssignments_none?: FlatAssignmentFilterInput | null;
  flatAssignmentCount?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_not?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gte?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (BrandFilterInput | null)[] | null;
  OR?: (BrandFilterInput | null)[] | null;
  NOT?: BrandFilterInput | null;
}

/**
 * Identifies exactly one "Brand" with one of the following combination of components' value:
 * - _id
 * - id
 * - title
 */
export interface BrandUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title?: string | null;
}

/**
 * The "Brand" node's update
 */
export interface BrandUpdateInput {
  title?: string | null;
  unit?: BrandUpdateUnitInput | null;
  printPublications?: BrandUpdatePrintPublicationsInput | null;
  websites?: BrandUpdateWebsitesInput | null;
}

export interface BrandUpdatePrintPublicationsCreateIfNotExistsInput {
  where: PrintPublicationUniqueFilterWithoutBrandInput;
  data: PrintPublicationCreationWithoutBrandInput;
}

export interface BrandUpdatePrintPublicationsInput {
  deleteAll?: boolean | null;
  deleteMany?: PrintPublicationFilterInput | null;
  delete?: PrintPublicationUniqueFilterWithoutBrandInput[] | null;
  deleteIfExists?: PrintPublicationUniqueFilterWithoutBrandInput[] | null;
  create?: PrintPublicationCreationWithoutBrandInput[] | null;
  createIfNotExists?: BrandUpdatePrintPublicationsCreateIfNotExistsInput[] | null;
  updateAll?: PrintPublicationUpdateWithoutBrandInput | null;
  updateMany?: BrandUpdatePrintPublicationsUpdateManyInput | null;
  update?: BrandUpdatePrintPublicationsUpdateInput[] | null;
  updateIfExists?: BrandUpdatePrintPublicationsUpdateIfExistsInput[] | null;
  upsert?: BrandUpdatePrintPublicationsUpsertInput[] | null;
}

export interface BrandUpdatePrintPublicationsUpdateIfExistsInput {
  where: PrintPublicationUniqueFilterWithoutBrandInput;
  data?: PrintPublicationUpdateWithoutBrandInput | null;
}

export interface BrandUpdatePrintPublicationsUpdateInput {
  where: PrintPublicationUniqueFilterWithoutBrandInput;
  data?: PrintPublicationUpdateWithoutBrandInput | null;
}

export interface BrandUpdatePrintPublicationsUpdateManyInput {
  where?: PrintPublicationFilterInput | null;
  data?: PrintPublicationUpdateWithoutBrandInput | null;
}

export interface BrandUpdatePrintPublicationsUpsertInput {
  where: PrintPublicationUniqueFilterWithoutBrandInput;
  create: PrintPublicationCreationWithoutBrandInput;
  update?: PrintPublicationUpdateWithoutBrandInput | null;
}

export interface BrandUpdateUnitCreateIfNotExistsInput {
  where: UnitUniqueFilterInput;
  data: UnitCreationInput;
}

export interface BrandUpdateUnitInput {
  connect?: UnitUniqueFilterInput | null;
  create?: UnitCreationInput | null;
  createIfNotExists?: BrandUpdateUnitCreateIfNotExistsInput | null;
  update?: UnitUpdateInput | null;
}

export interface BrandUpdateWebsitesCreateIfNotExistsInput {
  where: WebsiteUniqueFilterWithoutBrandInput;
  data: WebsiteCreationWithoutBrandInput;
}

export interface BrandUpdateWebsitesInput {
  deleteAll?: boolean | null;
  deleteMany?: WebsiteFilterInput | null;
  delete?: WebsiteUniqueFilterWithoutBrandInput[] | null;
  deleteIfExists?: WebsiteUniqueFilterWithoutBrandInput[] | null;
  create?: WebsiteCreationWithoutBrandInput[] | null;
  createIfNotExists?: BrandUpdateWebsitesCreateIfNotExistsInput[] | null;
  updateAll?: WebsiteUpdateWithoutBrandInput | null;
  updateMany?: BrandUpdateWebsitesUpdateManyInput | null;
  update?: BrandUpdateWebsitesUpdateInput[] | null;
  updateIfExists?: BrandUpdateWebsitesUpdateIfExistsInput[] | null;
  upsert?: BrandUpdateWebsitesUpsertInput[] | null;
}

export interface BrandUpdateWebsitesUpdateIfExistsInput {
  where: WebsiteUniqueFilterWithoutBrandInput;
  data?: WebsiteUpdateWithoutBrandInput | null;
}

export interface BrandUpdateWebsitesUpdateInput {
  where: WebsiteUniqueFilterWithoutBrandInput;
  data?: WebsiteUpdateWithoutBrandInput | null;
}

export interface BrandUpdateWebsitesUpdateManyInput {
  where?: WebsiteFilterInput | null;
  data?: WebsiteUpdateWithoutBrandInput | null;
}

export interface BrandUpdateWebsitesUpsertInput {
  where: WebsiteUniqueFilterWithoutBrandInput;
  create: WebsiteCreationWithoutBrandInput;
  update?: WebsiteUpdateWithoutBrandInput | null;
}

/**
 * The "Brand" node's update
 */
export interface BrandUpdateWithoutUnitInput {
  title?: string | null;
  printPublications?: BrandUpdatePrintPublicationsInput | null;
  websites?: BrandUpdateWebsitesInput | null;
}

/**
 * The "FlatAssignment" nodes' filter
 */
export interface FlatAssignmentFilterInput {
  location?: string | null;
  location_not?: string | null;
  location_in?: string[] | null;
  location_not_in?: string[] | null;
  location_contains?: GraphQL$NonEmptyString | null;
  location_not_contains?: GraphQL$NonEmptyString | null;
  location_starts_with?: GraphQL$NonEmptyString | null;
  location_not_starts_with?: GraphQL$NonEmptyString | null;
  location_ends_with?: GraphQL$NonEmptyString | null;
  location_not_ends_with?: GraphQL$NonEmptyString | null;
  assignmentPageNumber?: number | null;
  assignmentPageNumber_not?: number | null;
  assignmentPageNumber_in?: (number | null)[] | null;
  assignmentPageNumber_not_in?: (number | null)[] | null;
  assignmentPageNumber_gt?: number | null;
  assignmentPageNumber_gte?: number | null;
  assignmentPageNumber_lt?: number | null;
  assignmentPageNumber_lte?: number | null;
  assignmentPageNumber_is_null?: boolean | null;
  assignmentMediaSearchStatus?: MediaSearchStatus | null;
  assignmentMediaSearchStatus_not?: MediaSearchStatus | null;
  assignmentMediaSearchStatus_in?: MediaSearchStatus[] | null;
  assignmentMediaSearchStatus_not_in?: MediaSearchStatus[] | null;
  assignment?: AssignmentFilterInput | null;
  assignment_not?: AssignmentFilterInput | null;
  subject?: SubjectFilterInput | null;
  subject_not?: SubjectFilterInput | null;
  subject_is_null?: boolean | null;
  rawArticle?: RawArticleFilterInput | null;
  rawArticle_not?: RawArticleFilterInput | null;
  rawArticle_is_null?: boolean | null;
  printHeading?: PrintHeadingFilterInput | null;
  printHeading_not?: PrintHeadingFilterInput | null;
  printHeading_is_null?: boolean | null;
  printIssue?: PrintIssueFilterInput | null;
  printIssue_not?: PrintIssueFilterInput | null;
  printIssue_is_null?: boolean | null;
  printPublication?: PrintPublicationFilterInput | null;
  printPublication_not?: PrintPublicationFilterInput | null;
  printPublication_is_null?: boolean | null;
  printTemplate?: PrintTemplateFilterInput | null;
  printTemplate_not?: PrintTemplateFilterInput | null;
  printTemplate_is_null?: boolean | null;
  website?: WebsiteFilterInput | null;
  website_not?: WebsiteFilterInput | null;
  website_is_null?: boolean | null;
  brand?: BrandFilterInput | null;
  brand_not?: BrandFilterInput | null;
  brand_is_null?: boolean | null;
  AND?: (FlatAssignmentFilterInput | null)[] | null;
  OR?: (FlatAssignmentFilterInput | null)[] | null;
  NOT?: FlatAssignmentFilterInput | null;
}

export interface PrintHeadingCreationAssignmentsInput {
  create?: AssignmentCreationWithoutPrintHeadingInput[] | null;
}

/**
 * The "PrintHeading" node's creation
 */
export interface PrintHeadingCreationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title: string;
  order: number;
  legacyId?: number | null;
  hasAutomaticSubject: boolean;
  autoPrototype?: boolean | null;
  printIssue: PrintHeadingCreationPrintIssueInput;
  printTemplates?: PrintHeadingCreationPrintTemplatesInput | null;
  assignments?: PrintHeadingCreationAssignmentsInput | null;
}

export interface PrintHeadingCreationPrintIssueCreateIfNotExistsInput {
  where: PrintIssueUniqueFilterInput;
  data: PrintIssueCreationInput;
}

export interface PrintHeadingCreationPrintIssueInput {
  connect?: PrintIssueUniqueFilterInput | null;
  create?: PrintIssueCreationInput | null;
  createIfNotExists?: PrintHeadingCreationPrintIssueCreateIfNotExistsInput | null;
}

export interface PrintHeadingCreationPrintTemplatesInput {
  create?: PrintHeadingPrintTemplateCreationWithoutPrintHeadingInput[] | null;
}

/**
 * The "PrintHeading" node's creation
 */
export interface PrintHeadingCreationWithoutPrintIssueInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title: string;
  order: number;
  legacyId?: number | null;
  hasAutomaticSubject: boolean;
  autoPrototype?: boolean | null;
  printTemplates?: PrintHeadingCreationPrintTemplatesInput | null;
  assignments?: PrintHeadingCreationAssignmentsInput | null;
}

/**
 * The "PrintHeading" nodes' filter
 */
export interface PrintHeadingFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_gt?: number | null;
  order_gte?: number | null;
  order_lt?: number | null;
  order_lte?: number | null;
  legacyId?: number | null;
  legacyId_not?: number | null;
  legacyId_in?: (number | null)[] | null;
  legacyId_not_in?: (number | null)[] | null;
  legacyId_gt?: number | null;
  legacyId_gte?: number | null;
  legacyId_lt?: number | null;
  legacyId_lte?: number | null;
  legacyId_is_null?: boolean | null;
  hasAutomaticSubject?: boolean | null;
  hasAutomaticSubject_not?: boolean | null;
  autoPrototype?: boolean | null;
  autoPrototype_not?: boolean | null;
  autoPrototype_in?: (boolean | null)[] | null;
  autoPrototype_not_in?: (boolean | null)[] | null;
  autoPrototype_is_null?: boolean | null;
  printIssue?: PrintIssueFilterInput | null;
  printIssue_not?: PrintIssueFilterInput | null;
  printTemplates_every?: PrintHeadingPrintTemplateFilterInput | null;
  printTemplates_some?: PrintHeadingPrintTemplateFilterInput | null;
  printTemplates_none?: PrintHeadingPrintTemplateFilterInput | null;
  printTemplateCount?: GraphQL$UnsignedInt | null;
  printTemplateCount_not?: GraphQL$UnsignedInt | null;
  printTemplateCount_gt?: GraphQL$UnsignedInt | null;
  printTemplateCount_gte?: GraphQL$UnsignedInt | null;
  printTemplateCount_lt?: GraphQL$UnsignedInt | null;
  printTemplateCount_lte?: GraphQL$UnsignedInt | null;
  assignments_every?: AssignmentFilterInput | null;
  assignments_some?: AssignmentFilterInput | null;
  assignments_none?: AssignmentFilterInput | null;
  assignmentCount?: GraphQL$UnsignedInt | null;
  assignmentCount_not?: GraphQL$UnsignedInt | null;
  assignmentCount_gt?: GraphQL$UnsignedInt | null;
  assignmentCount_gte?: GraphQL$UnsignedInt | null;
  assignmentCount_lt?: GraphQL$UnsignedInt | null;
  assignmentCount_lte?: GraphQL$UnsignedInt | null;
  flatAssignments_every?: FlatAssignmentFilterInput | null;
  flatAssignments_some?: FlatAssignmentFilterInput | null;
  flatAssignments_none?: FlatAssignmentFilterInput | null;
  flatAssignmentCount?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_not?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gte?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (PrintHeadingFilterInput | null)[] | null;
  OR?: (PrintHeadingFilterInput | null)[] | null;
  NOT?: PrintHeadingFilterInput | null;
}

export interface PrintHeadingPrintTemplateCreationPrintHeadingCreateIfNotExistsInput {
  where: PrintHeadingUniqueFilterInput;
  data: PrintHeadingCreationInput;
}

export interface PrintHeadingPrintTemplateCreationPrintHeadingInput {
  connect?: PrintHeadingUniqueFilterInput | null;
  create?: PrintHeadingCreationInput | null;
  createIfNotExists?: PrintHeadingPrintTemplateCreationPrintHeadingCreateIfNotExistsInput | null;
}

export interface PrintHeadingPrintTemplateCreationPrintTemplateCreateIfNotExistsInput {
  where: PrintTemplateUniqueFilterInput;
  data: PrintTemplateCreationInput;
}

export interface PrintHeadingPrintTemplateCreationPrintTemplateInput {
  connect?: PrintTemplateUniqueFilterInput | null;
  create?: PrintTemplateCreationInput | null;
  createIfNotExists?: PrintHeadingPrintTemplateCreationPrintTemplateCreateIfNotExistsInput | null;
}

/**
 * The "PrintHeadingPrintTemplate" node's creation
 */
export interface PrintHeadingPrintTemplateCreationWithoutPrintHeadingInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  active: boolean;
  printTemplate: PrintHeadingPrintTemplateCreationPrintTemplateInput;
}

/**
 * The "PrintHeadingPrintTemplate" node's creation
 */
export interface PrintHeadingPrintTemplateCreationWithoutPrintTemplateInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  active: boolean;
  printHeading: PrintHeadingPrintTemplateCreationPrintHeadingInput;
}

/**
 * The "PrintHeadingPrintTemplate" nodes' filter
 */
export interface PrintHeadingPrintTemplateFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  active?: boolean | null;
  active_not?: boolean | null;
  printHeading?: PrintHeadingFilterInput | null;
  printHeading_not?: PrintHeadingFilterInput | null;
  printTemplate?: PrintTemplateFilterInput | null;
  printTemplate_not?: PrintTemplateFilterInput | null;
  AND?: (PrintHeadingPrintTemplateFilterInput | null)[] | null;
  OR?: (PrintHeadingPrintTemplateFilterInput | null)[] | null;
  NOT?: PrintHeadingPrintTemplateFilterInput | null;
}

/**
 * Given a "printHeading", identifies exactly one "PrintHeadingPrintTemplate" with
 * one of the following combination of components' value:
 * - (printHeading) / printTemplate
 */
export interface PrintHeadingPrintTemplateUniqueFilterWithoutPrintHeadingInput {
  printTemplate: PrintTemplateUniqueFilterInput;
}

/**
 * Given a "printTemplate", identifies exactly one "PrintHeadingPrintTemplate" with
 * one of the following combination of components' value:
 * - printHeading / (printTemplate)
 */
export interface PrintHeadingPrintTemplateUniqueFilterWithoutPrintTemplateInput {
  printHeading: PrintHeadingUniqueFilterInput;
}

export interface PrintHeadingPrintTemplateUpdatePrintHeadingCreateIfNotExistsInput {
  where: PrintHeadingUniqueFilterInput;
  data: PrintHeadingCreationInput;
}

export interface PrintHeadingPrintTemplateUpdatePrintHeadingInput {
  connect?: PrintHeadingUniqueFilterInput | null;
  create?: PrintHeadingCreationInput | null;
  createIfNotExists?: PrintHeadingPrintTemplateUpdatePrintHeadingCreateIfNotExistsInput | null;
  update?: PrintHeadingUpdateInput | null;
}

export interface PrintHeadingPrintTemplateUpdatePrintTemplateCreateIfNotExistsInput {
  where: PrintTemplateUniqueFilterInput;
  data: PrintTemplateCreationInput;
}

export interface PrintHeadingPrintTemplateUpdatePrintTemplateInput {
  connect?: PrintTemplateUniqueFilterInput | null;
  create?: PrintTemplateCreationInput | null;
  createIfNotExists?: PrintHeadingPrintTemplateUpdatePrintTemplateCreateIfNotExistsInput | null;
  update?: PrintTemplateUpdateInput | null;
}

/**
 * The "PrintHeadingPrintTemplate" node's update
 */
export interface PrintHeadingPrintTemplateUpdateWithoutPrintHeadingInput {
  active?: boolean | null;
  printTemplate?: PrintHeadingPrintTemplateUpdatePrintTemplateInput | null;
}

/**
 * The "PrintHeadingPrintTemplate" node's update
 */
export interface PrintHeadingPrintTemplateUpdateWithoutPrintTemplateInput {
  active?: boolean | null;
  printHeading?: PrintHeadingPrintTemplateUpdatePrintHeadingInput | null;
}

/**
 * Identifies exactly one "PrintHeading" with one of the following combination of components' value:
 * - _id
 * - id
 * - legacyId / printIssue
 * - title / printIssue
 */
export interface PrintHeadingUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  legacyId?: number | null;
  printIssue?: PrintIssueUniqueFilterInput | null;
  title?: string | null;
}

/**
 * Given a "printIssue", identifies exactly one "PrintHeading" with one of the following combination of components' value:
 * - legacyId / (printIssue)
 * - title / (printIssue)
 */
export interface PrintHeadingUniqueFilterWithoutPrintIssueInput {
  legacyId?: number | null;
  title?: string | null;
}

export interface PrintHeadingUpdateAssignmentsInput {
  deleteAll?: boolean | null;
  deleteMany?: AssignmentFilterInput | null;
  create?: AssignmentCreationWithoutPrintHeadingInput[] | null;
  updateAll?: AssignmentUpdateWithoutPrintHeadingInput | null;
  updateMany?: PrintHeadingUpdateAssignmentsUpdateManyInput | null;
}

export interface PrintHeadingUpdateAssignmentsUpdateManyInput {
  where?: AssignmentFilterInput | null;
  data?: AssignmentUpdateWithoutPrintHeadingInput | null;
}

/**
 * The "PrintHeading" node's update
 */
export interface PrintHeadingUpdateInput {
  title?: string | null;
  order?: number | null;
  legacyId?: number | null;
  hasAutomaticSubject?: boolean | null;
  autoPrototype?: boolean | null;
  printIssue?: PrintHeadingUpdatePrintIssueInput | null;
  printTemplates?: PrintHeadingUpdatePrintTemplatesInput | null;
  assignments?: PrintHeadingUpdateAssignmentsInput | null;
}

export interface PrintHeadingUpdatePrintIssueCreateIfNotExistsInput {
  where: PrintIssueUniqueFilterInput;
  data: PrintIssueCreationInput;
}

export interface PrintHeadingUpdatePrintIssueInput {
  connect?: PrintIssueUniqueFilterInput | null;
  create?: PrintIssueCreationInput | null;
  createIfNotExists?: PrintHeadingUpdatePrintIssueCreateIfNotExistsInput | null;
  update?: PrintIssueUpdateInput | null;
}

export interface PrintHeadingUpdatePrintTemplatesCreateIfNotExistsInput {
  where: PrintHeadingPrintTemplateUniqueFilterWithoutPrintHeadingInput;
  data: PrintHeadingPrintTemplateCreationWithoutPrintHeadingInput;
}

export interface PrintHeadingUpdatePrintTemplatesInput {
  deleteAll?: boolean | null;
  deleteMany?: PrintHeadingPrintTemplateFilterInput | null;
  delete?: PrintHeadingPrintTemplateUniqueFilterWithoutPrintHeadingInput[] | null;
  deleteIfExists?: PrintHeadingPrintTemplateUniqueFilterWithoutPrintHeadingInput[] | null;
  create?: PrintHeadingPrintTemplateCreationWithoutPrintHeadingInput[] | null;
  createIfNotExists?: PrintHeadingUpdatePrintTemplatesCreateIfNotExistsInput[] | null;
  updateAll?: PrintHeadingPrintTemplateUpdateWithoutPrintHeadingInput | null;
  updateMany?: PrintHeadingUpdatePrintTemplatesUpdateManyInput | null;
  update?: PrintHeadingUpdatePrintTemplatesUpdateInput[] | null;
  updateIfExists?: PrintHeadingUpdatePrintTemplatesUpdateIfExistsInput[] | null;
  upsert?: PrintHeadingUpdatePrintTemplatesUpsertInput[] | null;
}

export interface PrintHeadingUpdatePrintTemplatesUpdateIfExistsInput {
  where: PrintHeadingPrintTemplateUniqueFilterWithoutPrintHeadingInput;
  data?: PrintHeadingPrintTemplateUpdateWithoutPrintHeadingInput | null;
}

export interface PrintHeadingUpdatePrintTemplatesUpdateInput {
  where: PrintHeadingPrintTemplateUniqueFilterWithoutPrintHeadingInput;
  data?: PrintHeadingPrintTemplateUpdateWithoutPrintHeadingInput | null;
}

export interface PrintHeadingUpdatePrintTemplatesUpdateManyInput {
  where?: PrintHeadingPrintTemplateFilterInput | null;
  data?: PrintHeadingPrintTemplateUpdateWithoutPrintHeadingInput | null;
}

export interface PrintHeadingUpdatePrintTemplatesUpsertInput {
  where: PrintHeadingPrintTemplateUniqueFilterWithoutPrintHeadingInput;
  create: PrintHeadingPrintTemplateCreationWithoutPrintHeadingInput;
  update?: PrintHeadingPrintTemplateUpdateWithoutPrintHeadingInput | null;
}

/**
 * The "PrintHeading" node's update
 */
export interface PrintHeadingUpdateWithoutPrintIssueInput {
  title?: string | null;
  order?: number | null;
  legacyId?: number | null;
  hasAutomaticSubject?: boolean | null;
  autoPrototype?: boolean | null;
  printTemplates?: PrintHeadingUpdatePrintTemplatesInput | null;
  assignments?: PrintHeadingUpdateAssignmentsInput | null;
}

export interface PrintIssueCreationAssignmentsInput {
  create?: AssignmentCreationWithoutPrintIssueInput[] | null;
}

/**
 * The "PrintIssue" node's creation
 */
export interface PrintIssueCreationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title: string;
  legacyId?: number | null;
  printPublication: PrintIssueCreationPrintPublicationInput;
  printHeadings?: PrintIssueCreationPrintHeadingsInput | null;
  assignments?: PrintIssueCreationAssignmentsInput | null;
}

export interface PrintIssueCreationPrintHeadingsInput {
  create?: PrintHeadingCreationWithoutPrintIssueInput[] | null;
}

export interface PrintIssueCreationPrintPublicationCreateIfNotExistsInput {
  where: PrintPublicationUniqueFilterInput;
  data: PrintPublicationCreationInput;
}

export interface PrintIssueCreationPrintPublicationInput {
  connect?: PrintPublicationUniqueFilterInput | null;
  create?: PrintPublicationCreationInput | null;
  createIfNotExists?: PrintIssueCreationPrintPublicationCreateIfNotExistsInput | null;
}

/**
 * The "PrintIssue" node's creation
 */
export interface PrintIssueCreationWithoutPrintPublicationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title: string;
  legacyId?: number | null;
  printHeadings?: PrintIssueCreationPrintHeadingsInput | null;
  assignments?: PrintIssueCreationAssignmentsInput | null;
}

/**
 * The "PrintIssue" nodes' filter
 */
export interface PrintIssueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_gt?: string | null;
  title_gte?: string | null;
  title_lt?: string | null;
  title_lte?: string | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  legacyId?: number | null;
  legacyId_not?: number | null;
  legacyId_in?: (number | null)[] | null;
  legacyId_not_in?: (number | null)[] | null;
  legacyId_gt?: number | null;
  legacyId_gte?: number | null;
  legacyId_lt?: number | null;
  legacyId_lte?: number | null;
  legacyId_is_null?: boolean | null;
  printPublication?: PrintPublicationFilterInput | null;
  printPublication_not?: PrintPublicationFilterInput | null;
  printHeadings_every?: PrintHeadingFilterInput | null;
  printHeadings_some?: PrintHeadingFilterInput | null;
  printHeadings_none?: PrintHeadingFilterInput | null;
  printHeadingCount?: GraphQL$UnsignedInt | null;
  printHeadingCount_not?: GraphQL$UnsignedInt | null;
  printHeadingCount_gt?: GraphQL$UnsignedInt | null;
  printHeadingCount_gte?: GraphQL$UnsignedInt | null;
  printHeadingCount_lt?: GraphQL$UnsignedInt | null;
  printHeadingCount_lte?: GraphQL$UnsignedInt | null;
  assignments_every?: AssignmentFilterInput | null;
  assignments_some?: AssignmentFilterInput | null;
  assignments_none?: AssignmentFilterInput | null;
  assignmentCount?: GraphQL$UnsignedInt | null;
  assignmentCount_not?: GraphQL$UnsignedInt | null;
  assignmentCount_gt?: GraphQL$UnsignedInt | null;
  assignmentCount_gte?: GraphQL$UnsignedInt | null;
  assignmentCount_lt?: GraphQL$UnsignedInt | null;
  assignmentCount_lte?: GraphQL$UnsignedInt | null;
  flatAssignments_every?: FlatAssignmentFilterInput | null;
  flatAssignments_some?: FlatAssignmentFilterInput | null;
  flatAssignments_none?: FlatAssignmentFilterInput | null;
  flatAssignmentCount?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_not?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gte?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (PrintIssueFilterInput | null)[] | null;
  OR?: (PrintIssueFilterInput | null)[] | null;
  NOT?: PrintIssueFilterInput | null;
}

/**
 * Identifies exactly one "PrintIssue" with one of the following combination of components' value:
 * - _id
 * - id
 * - legacyId / printPublication
 * - title / printPublication
 */
export interface PrintIssueUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  legacyId?: number | null;
  printPublication?: PrintPublicationUniqueFilterInput | null;
  title?: string | null;
}

/**
 * Given a "printPublication", identifies exactly one "PrintIssue" with one of the following combination of components' value:
 * - legacyId / (printPublication)
 * - title / (printPublication)
 */
export interface PrintIssueUniqueFilterWithoutPrintPublicationInput {
  legacyId?: number | null;
  title?: string | null;
}

export interface PrintIssueUpdateAssignmentsInput {
  deleteAll?: boolean | null;
  deleteMany?: AssignmentFilterInput | null;
  create?: AssignmentCreationWithoutPrintIssueInput[] | null;
  updateAll?: AssignmentUpdateWithoutPrintIssueInput | null;
  updateMany?: PrintIssueUpdateAssignmentsUpdateManyInput | null;
}

export interface PrintIssueUpdateAssignmentsUpdateManyInput {
  where?: AssignmentFilterInput | null;
  data?: AssignmentUpdateWithoutPrintIssueInput | null;
}

/**
 * The "PrintIssue" node's update
 */
export interface PrintIssueUpdateInput {
  title?: string | null;
  legacyId?: number | null;
  printPublication?: PrintIssueUpdatePrintPublicationInput | null;
  printHeadings?: PrintIssueUpdatePrintHeadingsInput | null;
  assignments?: PrintIssueUpdateAssignmentsInput | null;
}

export interface PrintIssueUpdatePrintHeadingsCreateIfNotExistsInput {
  where: PrintHeadingUniqueFilterWithoutPrintIssueInput;
  data: PrintHeadingCreationWithoutPrintIssueInput;
}

export interface PrintIssueUpdatePrintHeadingsInput {
  deleteAll?: boolean | null;
  deleteMany?: PrintHeadingFilterInput | null;
  delete?: PrintHeadingUniqueFilterWithoutPrintIssueInput[] | null;
  deleteIfExists?: PrintHeadingUniqueFilterWithoutPrintIssueInput[] | null;
  create?: PrintHeadingCreationWithoutPrintIssueInput[] | null;
  createIfNotExists?: PrintIssueUpdatePrintHeadingsCreateIfNotExistsInput[] | null;
  updateAll?: PrintHeadingUpdateWithoutPrintIssueInput | null;
  updateMany?: PrintIssueUpdatePrintHeadingsUpdateManyInput | null;
  update?: PrintIssueUpdatePrintHeadingsUpdateInput[] | null;
  updateIfExists?: PrintIssueUpdatePrintHeadingsUpdateIfExistsInput[] | null;
  upsert?: PrintIssueUpdatePrintHeadingsUpsertInput[] | null;
}

export interface PrintIssueUpdatePrintHeadingsUpdateIfExistsInput {
  where: PrintHeadingUniqueFilterWithoutPrintIssueInput;
  data?: PrintHeadingUpdateWithoutPrintIssueInput | null;
}

export interface PrintIssueUpdatePrintHeadingsUpdateInput {
  where: PrintHeadingUniqueFilterWithoutPrintIssueInput;
  data?: PrintHeadingUpdateWithoutPrintIssueInput | null;
}

export interface PrintIssueUpdatePrintHeadingsUpdateManyInput {
  where?: PrintHeadingFilterInput | null;
  data?: PrintHeadingUpdateWithoutPrintIssueInput | null;
}

export interface PrintIssueUpdatePrintHeadingsUpsertInput {
  where: PrintHeadingUniqueFilterWithoutPrintIssueInput;
  create: PrintHeadingCreationWithoutPrintIssueInput;
  update?: PrintHeadingUpdateWithoutPrintIssueInput | null;
}

export interface PrintIssueUpdatePrintPublicationCreateIfNotExistsInput {
  where: PrintPublicationUniqueFilterInput;
  data: PrintPublicationCreationInput;
}

export interface PrintIssueUpdatePrintPublicationInput {
  connect?: PrintPublicationUniqueFilterInput | null;
  create?: PrintPublicationCreationInput | null;
  createIfNotExists?: PrintIssueUpdatePrintPublicationCreateIfNotExistsInput | null;
  update?: PrintPublicationUpdateInput | null;
}

/**
 * The "PrintIssue" node's update
 */
export interface PrintIssueUpdateWithoutPrintPublicationInput {
  title?: string | null;
  legacyId?: number | null;
  printHeadings?: PrintIssueUpdatePrintHeadingsInput | null;
  assignments?: PrintIssueUpdateAssignmentsInput | null;
}

export interface PrintPublicationCreationAssignmentsInput {
  create?: AssignmentCreationWithoutPrintPublicationInput[] | null;
}

export interface PrintPublicationCreationBrandCreateIfNotExistsInput {
  where: BrandUniqueFilterInput;
  data: BrandCreationInput;
}

export interface PrintPublicationCreationBrandInput {
  connect?: BrandUniqueFilterInput | null;
  create?: BrandCreationInput | null;
  createIfNotExists?: PrintPublicationCreationBrandCreateIfNotExistsInput | null;
}

/**
 * The "PrintPublication" node's creation
 */
export interface PrintPublicationCreationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title: string;
  key: string;
  legacyId?: number | null;
  brand: PrintPublicationCreationBrandInput;
  printIssues?: PrintPublicationCreationPrintIssuesInput | null;
  assignments?: PrintPublicationCreationAssignmentsInput | null;
}

export interface PrintPublicationCreationPrintIssuesInput {
  create?: PrintIssueCreationWithoutPrintPublicationInput[] | null;
}

/**
 * The "PrintPublication" node's creation
 */
export interface PrintPublicationCreationWithoutBrandInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title: string;
  key: string;
  legacyId?: number | null;
  printIssues?: PrintPublicationCreationPrintIssuesInput | null;
  assignments?: PrintPublicationCreationAssignmentsInput | null;
}

/**
 * The "PrintPublication" nodes' filter
 */
export interface PrintPublicationFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  key?: string | null;
  key_not?: string | null;
  key_in?: string[] | null;
  key_not_in?: string[] | null;
  key_contains?: GraphQL$NonEmptyString | null;
  key_not_contains?: GraphQL$NonEmptyString | null;
  key_starts_with?: GraphQL$NonEmptyString | null;
  key_not_starts_with?: GraphQL$NonEmptyString | null;
  key_ends_with?: GraphQL$NonEmptyString | null;
  key_not_ends_with?: GraphQL$NonEmptyString | null;
  legacyId?: number | null;
  legacyId_not?: number | null;
  legacyId_in?: (number | null)[] | null;
  legacyId_not_in?: (number | null)[] | null;
  legacyId_gt?: number | null;
  legacyId_gte?: number | null;
  legacyId_lt?: number | null;
  legacyId_lte?: number | null;
  legacyId_is_null?: boolean | null;
  brand?: BrandFilterInput | null;
  brand_not?: BrandFilterInput | null;
  printIssues_every?: PrintIssueFilterInput | null;
  printIssues_some?: PrintIssueFilterInput | null;
  printIssues_none?: PrintIssueFilterInput | null;
  printIssueCount?: GraphQL$UnsignedInt | null;
  printIssueCount_not?: GraphQL$UnsignedInt | null;
  printIssueCount_gt?: GraphQL$UnsignedInt | null;
  printIssueCount_gte?: GraphQL$UnsignedInt | null;
  printIssueCount_lt?: GraphQL$UnsignedInt | null;
  printIssueCount_lte?: GraphQL$UnsignedInt | null;
  assignments_every?: AssignmentFilterInput | null;
  assignments_some?: AssignmentFilterInput | null;
  assignments_none?: AssignmentFilterInput | null;
  assignmentCount?: GraphQL$UnsignedInt | null;
  assignmentCount_not?: GraphQL$UnsignedInt | null;
  assignmentCount_gt?: GraphQL$UnsignedInt | null;
  assignmentCount_gte?: GraphQL$UnsignedInt | null;
  assignmentCount_lt?: GraphQL$UnsignedInt | null;
  assignmentCount_lte?: GraphQL$UnsignedInt | null;
  flatAssignments_every?: FlatAssignmentFilterInput | null;
  flatAssignments_some?: FlatAssignmentFilterInput | null;
  flatAssignments_none?: FlatAssignmentFilterInput | null;
  flatAssignmentCount?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_not?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gte?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (PrintPublicationFilterInput | null)[] | null;
  OR?: (PrintPublicationFilterInput | null)[] | null;
  NOT?: PrintPublicationFilterInput | null;
}

/**
 * Identifies exactly one "PrintPublication" with one of the following combination of components' value:
 * - _id
 * - id
 * - key
 * - legacyId / brand
 * - title / brand
 */
export interface PrintPublicationUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  key?: string | null;
  legacyId?: number | null;
  brand?: BrandUniqueFilterInput | null;
  title?: string | null;
}

/**
 * Given a "brand", identifies exactly one "PrintPublication" with one of the following combination of components' value:
 * - legacyId / (brand)
 * - title / (brand)
 */
export interface PrintPublicationUniqueFilterWithoutBrandInput {
  legacyId?: number | null;
  title?: string | null;
}

export interface PrintPublicationUpdateAssignmentsInput {
  deleteAll?: boolean | null;
  deleteMany?: AssignmentFilterInput | null;
  create?: AssignmentCreationWithoutPrintPublicationInput[] | null;
  updateAll?: AssignmentUpdateWithoutPrintPublicationInput | null;
  updateMany?: PrintPublicationUpdateAssignmentsUpdateManyInput | null;
}

export interface PrintPublicationUpdateAssignmentsUpdateManyInput {
  where?: AssignmentFilterInput | null;
  data?: AssignmentUpdateWithoutPrintPublicationInput | null;
}

export interface PrintPublicationUpdateBrandCreateIfNotExistsInput {
  where: BrandUniqueFilterInput;
  data: BrandCreationInput;
}

export interface PrintPublicationUpdateBrandInput {
  connect?: BrandUniqueFilterInput | null;
  create?: BrandCreationInput | null;
  createIfNotExists?: PrintPublicationUpdateBrandCreateIfNotExistsInput | null;
  update?: BrandUpdateInput | null;
}

/**
 * The "PrintPublication" node's update
 */
export interface PrintPublicationUpdateInput {
  title?: string | null;
  key?: string | null;
  legacyId?: number | null;
  brand?: PrintPublicationUpdateBrandInput | null;
  printIssues?: PrintPublicationUpdatePrintIssuesInput | null;
  assignments?: PrintPublicationUpdateAssignmentsInput | null;
}

export interface PrintPublicationUpdatePrintIssuesCreateIfNotExistsInput {
  where: PrintIssueUniqueFilterWithoutPrintPublicationInput;
  data: PrintIssueCreationWithoutPrintPublicationInput;
}

export interface PrintPublicationUpdatePrintIssuesInput {
  deleteAll?: boolean | null;
  deleteMany?: PrintIssueFilterInput | null;
  delete?: PrintIssueUniqueFilterWithoutPrintPublicationInput[] | null;
  deleteIfExists?: PrintIssueUniqueFilterWithoutPrintPublicationInput[] | null;
  create?: PrintIssueCreationWithoutPrintPublicationInput[] | null;
  createIfNotExists?: PrintPublicationUpdatePrintIssuesCreateIfNotExistsInput[] | null;
  updateAll?: PrintIssueUpdateWithoutPrintPublicationInput | null;
  updateMany?: PrintPublicationUpdatePrintIssuesUpdateManyInput | null;
  update?: PrintPublicationUpdatePrintIssuesUpdateInput[] | null;
  updateIfExists?: PrintPublicationUpdatePrintIssuesUpdateIfExistsInput[] | null;
  upsert?: PrintPublicationUpdatePrintIssuesUpsertInput[] | null;
}

export interface PrintPublicationUpdatePrintIssuesUpdateIfExistsInput {
  where: PrintIssueUniqueFilterWithoutPrintPublicationInput;
  data?: PrintIssueUpdateWithoutPrintPublicationInput | null;
}

export interface PrintPublicationUpdatePrintIssuesUpdateInput {
  where: PrintIssueUniqueFilterWithoutPrintPublicationInput;
  data?: PrintIssueUpdateWithoutPrintPublicationInput | null;
}

export interface PrintPublicationUpdatePrintIssuesUpdateManyInput {
  where?: PrintIssueFilterInput | null;
  data?: PrintIssueUpdateWithoutPrintPublicationInput | null;
}

export interface PrintPublicationUpdatePrintIssuesUpsertInput {
  where: PrintIssueUniqueFilterWithoutPrintPublicationInput;
  create: PrintIssueCreationWithoutPrintPublicationInput;
  update?: PrintIssueUpdateWithoutPrintPublicationInput | null;
}

/**
 * The "PrintPublication" node's update
 */
export interface PrintPublicationUpdateWithoutBrandInput {
  title?: string | null;
  key?: string | null;
  legacyId?: number | null;
  printIssues?: PrintPublicationUpdatePrintIssuesInput | null;
  assignments?: PrintPublicationUpdateAssignmentsInput | null;
}

export interface PrintTemplateCreationArticleTemplatesInput {
  create?: ArticleTemplateCreationWithoutPrintTemplateInput[] | null;
}

export interface PrintTemplateCreationAssignmentsInput {
  create?: AssignmentCreationWithoutPrintTemplateInput[] | null;
}

/**
 * The "PrintTemplate" node's creation
 */
export interface PrintTemplateCreationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  S3ObjectId: string;
  label: string;
  filename: string;
  filesize?: number | null;
  type?: TemplateType | null;
  pageCount?: number | null;
  extVersion?: string | null;
  data?: string | null;
  articleTemplates?: PrintTemplateCreationArticleTemplatesInput | null;
  printHeadings?: PrintTemplateCreationPrintHeadingsInput | null;
  assignments?: PrintTemplateCreationAssignmentsInput | null;
}

export interface PrintTemplateCreationPrintHeadingsInput {
  create?: PrintHeadingPrintTemplateCreationWithoutPrintTemplateInput[] | null;
}

/**
 * The "PrintTemplate" nodes' filter
 */
export interface PrintTemplateFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  S3ObjectId?: string | null;
  S3ObjectId_not?: string | null;
  S3ObjectId_in?: string[] | null;
  S3ObjectId_not_in?: string[] | null;
  S3ObjectId_contains?: GraphQL$NonEmptyString | null;
  S3ObjectId_not_contains?: GraphQL$NonEmptyString | null;
  S3ObjectId_starts_with?: GraphQL$NonEmptyString | null;
  S3ObjectId_not_starts_with?: GraphQL$NonEmptyString | null;
  S3ObjectId_ends_with?: GraphQL$NonEmptyString | null;
  S3ObjectId_not_ends_with?: GraphQL$NonEmptyString | null;
  label?: string | null;
  label_not?: string | null;
  label_in?: string[] | null;
  label_not_in?: string[] | null;
  label_contains?: GraphQL$NonEmptyString | null;
  label_not_contains?: GraphQL$NonEmptyString | null;
  label_starts_with?: GraphQL$NonEmptyString | null;
  label_not_starts_with?: GraphQL$NonEmptyString | null;
  label_ends_with?: GraphQL$NonEmptyString | null;
  label_not_ends_with?: GraphQL$NonEmptyString | null;
  filename?: string | null;
  filename_not?: string | null;
  filename_in?: string[] | null;
  filename_not_in?: string[] | null;
  filename_contains?: GraphQL$NonEmptyString | null;
  filename_not_contains?: GraphQL$NonEmptyString | null;
  filename_starts_with?: GraphQL$NonEmptyString | null;
  filename_not_starts_with?: GraphQL$NonEmptyString | null;
  filename_ends_with?: GraphQL$NonEmptyString | null;
  filename_not_ends_with?: GraphQL$NonEmptyString | null;
  filesize?: number | null;
  filesize_not?: number | null;
  filesize_in?: (number | null)[] | null;
  filesize_not_in?: (number | null)[] | null;
  filesize_gt?: number | null;
  filesize_gte?: number | null;
  filesize_lt?: number | null;
  filesize_lte?: number | null;
  filesize_is_null?: boolean | null;
  type?: TemplateType | null;
  type_not?: TemplateType | null;
  type_in?: (TemplateType | null)[] | null;
  type_not_in?: (TemplateType | null)[] | null;
  type_is_null?: boolean | null;
  pageCount?: number | null;
  pageCount_not?: number | null;
  pageCount_in?: (number | null)[] | null;
  pageCount_not_in?: (number | null)[] | null;
  pageCount_gt?: number | null;
  pageCount_gte?: number | null;
  pageCount_lt?: number | null;
  pageCount_lte?: number | null;
  pageCount_is_null?: boolean | null;
  extVersion?: string | null;
  extVersion_not?: string | null;
  extVersion_in?: (string | null)[] | null;
  extVersion_not_in?: (string | null)[] | null;
  extVersion_is_null?: boolean | null;
  extVersion_contains?: GraphQL$NonEmptyString | null;
  extVersion_not_contains?: GraphQL$NonEmptyString | null;
  extVersion_starts_with?: GraphQL$NonEmptyString | null;
  extVersion_not_starts_with?: GraphQL$NonEmptyString | null;
  extVersion_ends_with?: GraphQL$NonEmptyString | null;
  extVersion_not_ends_with?: GraphQL$NonEmptyString | null;
  data?: string | null;
  data_not?: string | null;
  data_in?: string[] | null;
  data_not_in?: string[] | null;
  data_contains?: GraphQL$NonEmptyString | null;
  data_not_contains?: GraphQL$NonEmptyString | null;
  data_starts_with?: GraphQL$NonEmptyString | null;
  data_not_starts_with?: GraphQL$NonEmptyString | null;
  data_ends_with?: GraphQL$NonEmptyString | null;
  data_not_ends_with?: GraphQL$NonEmptyString | null;
  articleTemplates_every?: ArticleTemplateFilterInput | null;
  articleTemplates_some?: ArticleTemplateFilterInput | null;
  articleTemplates_none?: ArticleTemplateFilterInput | null;
  articleTemplateCount?: GraphQL$UnsignedInt | null;
  articleTemplateCount_not?: GraphQL$UnsignedInt | null;
  articleTemplateCount_gt?: GraphQL$UnsignedInt | null;
  articleTemplateCount_gte?: GraphQL$UnsignedInt | null;
  articleTemplateCount_lt?: GraphQL$UnsignedInt | null;
  articleTemplateCount_lte?: GraphQL$UnsignedInt | null;
  printHeadings_every?: PrintHeadingPrintTemplateFilterInput | null;
  printHeadings_some?: PrintHeadingPrintTemplateFilterInput | null;
  printHeadings_none?: PrintHeadingPrintTemplateFilterInput | null;
  printHeadingCount?: GraphQL$UnsignedInt | null;
  printHeadingCount_not?: GraphQL$UnsignedInt | null;
  printHeadingCount_gt?: GraphQL$UnsignedInt | null;
  printHeadingCount_gte?: GraphQL$UnsignedInt | null;
  printHeadingCount_lt?: GraphQL$UnsignedInt | null;
  printHeadingCount_lte?: GraphQL$UnsignedInt | null;
  assignments_every?: AssignmentFilterInput | null;
  assignments_some?: AssignmentFilterInput | null;
  assignments_none?: AssignmentFilterInput | null;
  assignmentCount?: GraphQL$UnsignedInt | null;
  assignmentCount_not?: GraphQL$UnsignedInt | null;
  assignmentCount_gt?: GraphQL$UnsignedInt | null;
  assignmentCount_gte?: GraphQL$UnsignedInt | null;
  assignmentCount_lt?: GraphQL$UnsignedInt | null;
  assignmentCount_lte?: GraphQL$UnsignedInt | null;
  flatAssignments_every?: FlatAssignmentFilterInput | null;
  flatAssignments_some?: FlatAssignmentFilterInput | null;
  flatAssignments_none?: FlatAssignmentFilterInput | null;
  flatAssignmentCount?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_not?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gte?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (PrintTemplateFilterInput | null)[] | null;
  OR?: (PrintTemplateFilterInput | null)[] | null;
  NOT?: PrintTemplateFilterInput | null;
}

/**
 * Identifies exactly one "PrintTemplate" with one of the following combination of components' value:
 * - _id
 * - id
 * - S3ObjectId
 */
export interface PrintTemplateUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  S3ObjectId?: string | null;
}

export interface PrintTemplateUpdateArticleTemplatesInput {
  deleteAll?: boolean | null;
  deleteMany?: ArticleTemplateFilterInput | null;
  create?: ArticleTemplateCreationWithoutPrintTemplateInput[] | null;
  updateAll?: ArticleTemplateUpdateWithoutPrintTemplateInput | null;
  updateMany?: PrintTemplateUpdateArticleTemplatesUpdateManyInput | null;
}

export interface PrintTemplateUpdateArticleTemplatesUpdateManyInput {
  where?: ArticleTemplateFilterInput | null;
  data?: ArticleTemplateUpdateWithoutPrintTemplateInput | null;
}

export interface PrintTemplateUpdateAssignmentsInput {
  deleteAll?: boolean | null;
  deleteMany?: AssignmentFilterInput | null;
  create?: AssignmentCreationWithoutPrintTemplateInput[] | null;
  updateAll?: AssignmentUpdateWithoutPrintTemplateInput | null;
  updateMany?: PrintTemplateUpdateAssignmentsUpdateManyInput | null;
}

export interface PrintTemplateUpdateAssignmentsUpdateManyInput {
  where?: AssignmentFilterInput | null;
  data?: AssignmentUpdateWithoutPrintTemplateInput | null;
}

/**
 * The "PrintTemplate" node's update
 */
export interface PrintTemplateUpdateInput {
  S3ObjectId?: string | null;
  label?: string | null;
  filename?: string | null;
  filesize?: number | null;
  type?: TemplateType | null;
  articleTemplates?: PrintTemplateUpdateArticleTemplatesInput | null;
  printHeadings?: PrintTemplateUpdatePrintHeadingsInput | null;
  assignments?: PrintTemplateUpdateAssignmentsInput | null;
}

export interface PrintTemplateUpdatePrintHeadingsCreateIfNotExistsInput {
  where: PrintHeadingPrintTemplateUniqueFilterWithoutPrintTemplateInput;
  data: PrintHeadingPrintTemplateCreationWithoutPrintTemplateInput;
}

export interface PrintTemplateUpdatePrintHeadingsInput {
  deleteAll?: boolean | null;
  deleteMany?: PrintHeadingPrintTemplateFilterInput | null;
  delete?: PrintHeadingPrintTemplateUniqueFilterWithoutPrintTemplateInput[] | null;
  deleteIfExists?: PrintHeadingPrintTemplateUniqueFilterWithoutPrintTemplateInput[] | null;
  create?: PrintHeadingPrintTemplateCreationWithoutPrintTemplateInput[] | null;
  createIfNotExists?: PrintTemplateUpdatePrintHeadingsCreateIfNotExistsInput[] | null;
  updateAll?: PrintHeadingPrintTemplateUpdateWithoutPrintTemplateInput | null;
  updateMany?: PrintTemplateUpdatePrintHeadingsUpdateManyInput | null;
  update?: PrintTemplateUpdatePrintHeadingsUpdateInput[] | null;
  updateIfExists?: PrintTemplateUpdatePrintHeadingsUpdateIfExistsInput[] | null;
  upsert?: PrintTemplateUpdatePrintHeadingsUpsertInput[] | null;
}

export interface PrintTemplateUpdatePrintHeadingsUpdateIfExistsInput {
  where: PrintHeadingPrintTemplateUniqueFilterWithoutPrintTemplateInput;
  data?: PrintHeadingPrintTemplateUpdateWithoutPrintTemplateInput | null;
}

export interface PrintTemplateUpdatePrintHeadingsUpdateInput {
  where: PrintHeadingPrintTemplateUniqueFilterWithoutPrintTemplateInput;
  data?: PrintHeadingPrintTemplateUpdateWithoutPrintTemplateInput | null;
}

export interface PrintTemplateUpdatePrintHeadingsUpdateManyInput {
  where?: PrintHeadingPrintTemplateFilterInput | null;
  data?: PrintHeadingPrintTemplateUpdateWithoutPrintTemplateInput | null;
}

export interface PrintTemplateUpdatePrintHeadingsUpsertInput {
  where: PrintHeadingPrintTemplateUniqueFilterWithoutPrintTemplateInput;
  create: PrintHeadingPrintTemplateCreationWithoutPrintTemplateInput;
  update?: PrintHeadingPrintTemplateUpdateWithoutPrintTemplateInput | null;
}

export interface RawArticleCreationAssignmentInput {
  create?: AssignmentCreationWithoutRawArticleInput | null;
}

/**
 * The "RawArticle" node's creation
 */
export interface RawArticleCreationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  legacyId?: number | null;
  versions?: RawArticleCreationVersionsInput | null;
  assignment?: RawArticleCreationAssignmentInput | null;
}

export interface RawArticleCreationVersionsInput {
  create?: RawArticleVersionCreationWithoutRawArticleInput[] | null;
}

/**
 * The "RawArticle" nodes' filter
 */
export interface RawArticleFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  legacyId?: number | null;
  legacyId_not?: number | null;
  legacyId_in?: (number | null)[] | null;
  legacyId_not_in?: (number | null)[] | null;
  legacyId_gt?: number | null;
  legacyId_gte?: number | null;
  legacyId_lt?: number | null;
  legacyId_lte?: number | null;
  legacyId_is_null?: boolean | null;
  versions_every?: RawArticleVersionFilterInput | null;
  versions_some?: RawArticleVersionFilterInput | null;
  versions_none?: RawArticleVersionFilterInput | null;
  versionCount?: GraphQL$UnsignedInt | null;
  versionCount_not?: GraphQL$UnsignedInt | null;
  versionCount_gt?: GraphQL$UnsignedInt | null;
  versionCount_gte?: GraphQL$UnsignedInt | null;
  versionCount_lt?: GraphQL$UnsignedInt | null;
  versionCount_lte?: GraphQL$UnsignedInt | null;
  assignment?: AssignmentFilterInput | null;
  assignment_not?: AssignmentFilterInput | null;
  assignment_is_null?: boolean | null;
  flatAssignments_every?: FlatAssignmentFilterInput | null;
  flatAssignments_some?: FlatAssignmentFilterInput | null;
  flatAssignments_none?: FlatAssignmentFilterInput | null;
  flatAssignmentCount?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_not?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gte?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (RawArticleFilterInput | null)[] | null;
  OR?: (RawArticleFilterInput | null)[] | null;
  NOT?: RawArticleFilterInput | null;
}

/**
 * Identifies exactly one "RawArticle" with one of the following combination of components' value:
 * - _id
 * - id
 */
export interface RawArticleUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
}

export interface RawArticleUpdateAssignmentInput {
  delete?: boolean | null;
  deleteIfExists?: boolean | null;
  create?: AssignmentCreationWithoutRawArticleInput | null;
  createIfNotExists?: AssignmentCreationWithoutRawArticleInput | null;
  update?: AssignmentUpdateWithoutRawArticleInput | null;
  updateIfExists?: AssignmentUpdateWithoutRawArticleInput | null;
  upsert?: RawArticleUpdateAssignmentUpsertInput | null;
}

export interface RawArticleUpdateAssignmentUpsertInput {
  create: AssignmentCreationWithoutRawArticleInput;
  update?: AssignmentUpdateWithoutRawArticleInput | null;
}

/**
 * The "RawArticle" node's update
 */
export interface RawArticleUpdateInput {
  legacyId?: number | null;
  versions?: RawArticleUpdateVersionsInput | null;
  assignment?: RawArticleUpdateAssignmentInput | null;
}

export interface RawArticleUpdateVersionsInput {
  deleteAll?: boolean | null;
  deleteMany?: RawArticleVersionFilterInput | null;
  create?: RawArticleVersionCreationWithoutRawArticleInput[] | null;
  updateAll?: RawArticleVersionUpdateWithoutRawArticleInput | null;
  updateMany?: RawArticleUpdateVersionsUpdateManyInput | null;
}

export interface RawArticleUpdateVersionsUpdateManyInput {
  where?: RawArticleVersionFilterInput | null;
  data?: RawArticleVersionUpdateWithoutRawArticleInput | null;
}

export interface RawArticleVersionCreationForkChilrensInput {
  create?: RawArticleVersionCreationWithoutForkOfInput[] | null;
}

export interface RawArticleVersionCreationForkOfCreateIfNotExistsInput {
  where: RawArticleVersionUniqueFilterInput;
  data: RawArticleVersionCreationInput;
}

export interface RawArticleVersionCreationForkOfInput {
  connect?: RawArticleVersionUniqueFilterInput | null;
  connectIfExists?: RawArticleVersionUniqueFilterInput | null;
  create?: RawArticleVersionCreationInput | null;
  createIfNotExists?: RawArticleVersionCreationForkOfCreateIfNotExistsInput | null;
}

/**
 * The "RawArticleVersion" node's creation
 */
export interface RawArticleVersionCreationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  editorId?: string | null;
  title?: string | null;
  lead?: string | null;
  body?: string | null;
  signature?: string | null;
  status?: RawArticleStatus | null;
  comment?: string | null;
  leftOver?: string | null;
  rawArticle: RawArticleVersionCreationRawArticleInput;
  previousVersion?: RawArticleVersionCreationPreviousVersionInput | null;
  forkOf?: RawArticleVersionCreationForkOfInput | null;
  nextVersion?: RawArticleVersionCreationNextVersionInput | null;
  forkChilrens?: RawArticleVersionCreationForkChilrensInput | null;
}

export interface RawArticleVersionCreationNextVersionInput {
  create?: RawArticleVersionCreationWithoutPreviousVersionInput | null;
}

export interface RawArticleVersionCreationPreviousVersionCreateIfNotExistsInput {
  where: RawArticleVersionUniqueFilterInput;
  data: RawArticleVersionCreationInput;
}

export interface RawArticleVersionCreationPreviousVersionInput {
  connect?: RawArticleVersionUniqueFilterInput | null;
  connectIfExists?: RawArticleVersionUniqueFilterInput | null;
  create?: RawArticleVersionCreationInput | null;
  createIfNotExists?: RawArticleVersionCreationPreviousVersionCreateIfNotExistsInput | null;
}

export interface RawArticleVersionCreationRawArticleCreateIfNotExistsInput {
  where: RawArticleUniqueFilterInput;
  data: RawArticleCreationInput;
}

export interface RawArticleVersionCreationRawArticleInput {
  connect?: RawArticleUniqueFilterInput | null;
  create?: RawArticleCreationInput | null;
  createIfNotExists?: RawArticleVersionCreationRawArticleCreateIfNotExistsInput | null;
}

/**
 * The "RawArticleVersion" node's creation
 */
export interface RawArticleVersionCreationWithoutForkOfInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  editorId?: string | null;
  title?: string | null;
  lead?: string | null;
  body?: string | null;
  signature?: string | null;
  status?: RawArticleStatus | null;
  comment?: string | null;
  leftOver?: string | null;
  rawArticle: RawArticleVersionCreationRawArticleInput;
  previousVersion?: RawArticleVersionCreationPreviousVersionInput | null;
  nextVersion?: RawArticleVersionCreationNextVersionInput | null;
  forkChilrens?: RawArticleVersionCreationForkChilrensInput | null;
}

/**
 * The "RawArticleVersion" node's creation
 */
export interface RawArticleVersionCreationWithoutPreviousVersionInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  editorId?: string | null;
  title?: string | null;
  lead?: string | null;
  body?: string | null;
  signature?: string | null;
  status?: RawArticleStatus | null;
  comment?: string | null;
  leftOver?: string | null;
  rawArticle: RawArticleVersionCreationRawArticleInput;
  forkOf?: RawArticleVersionCreationForkOfInput | null;
  nextVersion?: RawArticleVersionCreationNextVersionInput | null;
  forkChilrens?: RawArticleVersionCreationForkChilrensInput | null;
}

/**
 * The "RawArticleVersion" node's creation
 */
export interface RawArticleVersionCreationWithoutRawArticleInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  editorId?: string | null;
  title?: string | null;
  lead?: string | null;
  body?: string | null;
  signature?: string | null;
  status?: RawArticleStatus | null;
  comment?: string | null;
  leftOver?: string | null;
  previousVersion?: RawArticleVersionCreationPreviousVersionInput | null;
  forkOf?: RawArticleVersionCreationForkOfInput | null;
  nextVersion?: RawArticleVersionCreationNextVersionInput | null;
  forkChilrens?: RawArticleVersionCreationForkChilrensInput | null;
}

/**
 * The "RawArticleVersion" nodes' filter
 */
export interface RawArticleVersionFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  editorId?: string | null;
  editorId_not?: string | null;
  editorId_in?: string[] | null;
  editorId_not_in?: string[] | null;
  editorId_contains?: GraphQL$NonEmptyString | null;
  editorId_not_contains?: GraphQL$NonEmptyString | null;
  editorId_starts_with?: GraphQL$NonEmptyString | null;
  editorId_not_starts_with?: GraphQL$NonEmptyString | null;
  editorId_ends_with?: GraphQL$NonEmptyString | null;
  editorId_not_ends_with?: GraphQL$NonEmptyString | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: (string | null)[] | null;
  title_not_in?: (string | null)[] | null;
  title_is_null?: boolean | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  lead?: string | null;
  lead_not?: string | null;
  lead_in?: (string | null)[] | null;
  lead_not_in?: (string | null)[] | null;
  lead_is_null?: boolean | null;
  lead_contains?: GraphQL$NonEmptyString | null;
  lead_not_contains?: GraphQL$NonEmptyString | null;
  lead_starts_with?: GraphQL$NonEmptyString | null;
  lead_not_starts_with?: GraphQL$NonEmptyString | null;
  lead_ends_with?: GraphQL$NonEmptyString | null;
  lead_not_ends_with?: GraphQL$NonEmptyString | null;
  body?: string | null;
  body_not?: string | null;
  body_in?: (string | null)[] | null;
  body_not_in?: (string | null)[] | null;
  body_is_null?: boolean | null;
  body_contains?: GraphQL$NonEmptyString | null;
  body_not_contains?: GraphQL$NonEmptyString | null;
  body_starts_with?: GraphQL$NonEmptyString | null;
  body_not_starts_with?: GraphQL$NonEmptyString | null;
  body_ends_with?: GraphQL$NonEmptyString | null;
  body_not_ends_with?: GraphQL$NonEmptyString | null;
  signature?: string | null;
  signature_not?: string | null;
  signature_in?: (string | null)[] | null;
  signature_not_in?: (string | null)[] | null;
  signature_is_null?: boolean | null;
  signature_contains?: GraphQL$NonEmptyString | null;
  signature_not_contains?: GraphQL$NonEmptyString | null;
  signature_starts_with?: GraphQL$NonEmptyString | null;
  signature_not_starts_with?: GraphQL$NonEmptyString | null;
  signature_ends_with?: GraphQL$NonEmptyString | null;
  signature_not_ends_with?: GraphQL$NonEmptyString | null;
  status?: RawArticleStatus | null;
  status_not?: RawArticleStatus | null;
  status_in?: RawArticleStatus[] | null;
  status_not_in?: RawArticleStatus[] | null;
  comment?: string | null;
  comment_not?: string | null;
  comment_in?: (string | null)[] | null;
  comment_not_in?: (string | null)[] | null;
  comment_is_null?: boolean | null;
  comment_contains?: GraphQL$NonEmptyString | null;
  comment_not_contains?: GraphQL$NonEmptyString | null;
  comment_starts_with?: GraphQL$NonEmptyString | null;
  comment_not_starts_with?: GraphQL$NonEmptyString | null;
  comment_ends_with?: GraphQL$NonEmptyString | null;
  comment_not_ends_with?: GraphQL$NonEmptyString | null;
  leftOver?: string | null;
  leftOver_not?: string | null;
  leftOver_in?: (string | null)[] | null;
  leftOver_not_in?: (string | null)[] | null;
  leftOver_is_null?: boolean | null;
  leftOver_contains?: GraphQL$NonEmptyString | null;
  leftOver_not_contains?: GraphQL$NonEmptyString | null;
  leftOver_starts_with?: GraphQL$NonEmptyString | null;
  leftOver_not_starts_with?: GraphQL$NonEmptyString | null;
  leftOver_ends_with?: GraphQL$NonEmptyString | null;
  leftOver_not_ends_with?: GraphQL$NonEmptyString | null;
  rawArticle?: RawArticleFilterInput | null;
  rawArticle_not?: RawArticleFilterInput | null;
  previousVersion?: RawArticleVersionFilterInput | null;
  previousVersion_not?: RawArticleVersionFilterInput | null;
  previousVersion_is_null?: boolean | null;
  forkOf?: RawArticleVersionFilterInput | null;
  forkOf_not?: RawArticleVersionFilterInput | null;
  forkOf_is_null?: boolean | null;
  nextVersion?: RawArticleVersionFilterInput | null;
  nextVersion_not?: RawArticleVersionFilterInput | null;
  nextVersion_is_null?: boolean | null;
  forkChilrens_every?: RawArticleVersionFilterInput | null;
  forkChilrens_some?: RawArticleVersionFilterInput | null;
  forkChilrens_none?: RawArticleVersionFilterInput | null;
  forkChilrenCount?: GraphQL$UnsignedInt | null;
  forkChilrenCount_not?: GraphQL$UnsignedInt | null;
  forkChilrenCount_gt?: GraphQL$UnsignedInt | null;
  forkChilrenCount_gte?: GraphQL$UnsignedInt | null;
  forkChilrenCount_lt?: GraphQL$UnsignedInt | null;
  forkChilrenCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (RawArticleVersionFilterInput | null)[] | null;
  OR?: (RawArticleVersionFilterInput | null)[] | null;
  NOT?: RawArticleVersionFilterInput | null;
}

/**
 * Identifies exactly one "RawArticleVersion" with one of the following combination of components' value:
 * - _id
 * - id
 * - previousVersion
 */
export interface RawArticleVersionUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  previousVersion?: RawArticleVersionUniqueFilterInput | null;
}

export interface RawArticleVersionUpdateForkChilrensInput {
  deleteAll?: boolean | null;
  deleteMany?: RawArticleVersionFilterInput | null;
  create?: RawArticleVersionCreationWithoutForkOfInput[] | null;
  updateAll?: RawArticleVersionUpdateWithoutForkOfInput | null;
  updateMany?: RawArticleVersionUpdateForkChilrensUpdateManyInput | null;
}

export interface RawArticleVersionUpdateForkChilrensUpdateManyInput {
  where?: RawArticleVersionFilterInput | null;
  data?: RawArticleVersionUpdateWithoutForkOfInput | null;
}

export interface RawArticleVersionUpdateForkOfCreateIfNotExistsInput {
  where: RawArticleVersionUniqueFilterInput;
  data: RawArticleVersionCreationInput;
}

export interface RawArticleVersionUpdateForkOfInput {
  connect?: RawArticleVersionUniqueFilterInput | null;
  connectIfExists?: RawArticleVersionUniqueFilterInput | null;
  disconnect?: boolean | null;
  disconnectIfExists?: boolean | null;
  create?: RawArticleVersionCreationInput | null;
  createIfNotExists?: RawArticleVersionUpdateForkOfCreateIfNotExistsInput | null;
  update?: RawArticleVersionUpdateInput | null;
  updateIfExists?: RawArticleVersionUpdateInput | null;
}

/**
 * The "RawArticleVersion" node's update
 */
export interface RawArticleVersionUpdateInput {
  editorId?: string | null;
  title?: string | null;
  lead?: string | null;
  body?: string | null;
  signature?: string | null;
  status?: RawArticleStatus | null;
  comment?: string | null;
  leftOver?: string | null;
  rawArticle?: RawArticleVersionUpdateRawArticleInput | null;
  previousVersion?: RawArticleVersionUpdatePreviousVersionInput | null;
  forkOf?: RawArticleVersionUpdateForkOfInput | null;
  nextVersion?: RawArticleVersionUpdateNextVersionInput | null;
  forkChilrens?: RawArticleVersionUpdateForkChilrensInput | null;
}

export interface RawArticleVersionUpdateNextVersionInput {
  delete?: boolean | null;
  deleteIfExists?: boolean | null;
  create?: RawArticleVersionCreationWithoutPreviousVersionInput | null;
  createIfNotExists?: RawArticleVersionCreationWithoutPreviousVersionInput | null;
  update?: RawArticleVersionUpdateWithoutPreviousVersionInput | null;
  updateIfExists?: RawArticleVersionUpdateWithoutPreviousVersionInput | null;
  upsert?: RawArticleVersionUpdateNextVersionUpsertInput | null;
}

export interface RawArticleVersionUpdateNextVersionUpsertInput {
  create: RawArticleVersionCreationWithoutPreviousVersionInput;
  update?: RawArticleVersionUpdateWithoutPreviousVersionInput | null;
}

export interface RawArticleVersionUpdatePreviousVersionCreateIfNotExistsInput {
  where: RawArticleVersionUniqueFilterInput;
  data: RawArticleVersionCreationInput;
}

export interface RawArticleVersionUpdatePreviousVersionInput {
  connect?: RawArticleVersionUniqueFilterInput | null;
  connectIfExists?: RawArticleVersionUniqueFilterInput | null;
  disconnect?: boolean | null;
  disconnectIfExists?: boolean | null;
  create?: RawArticleVersionCreationInput | null;
  createIfNotExists?: RawArticleVersionUpdatePreviousVersionCreateIfNotExistsInput | null;
  update?: RawArticleVersionUpdateInput | null;
  updateIfExists?: RawArticleVersionUpdateInput | null;
}

export interface RawArticleVersionUpdateRawArticleCreateIfNotExistsInput {
  where: RawArticleUniqueFilterInput;
  data: RawArticleCreationInput;
}

export interface RawArticleVersionUpdateRawArticleInput {
  connect?: RawArticleUniqueFilterInput | null;
  create?: RawArticleCreationInput | null;
  createIfNotExists?: RawArticleVersionUpdateRawArticleCreateIfNotExistsInput | null;
  update?: RawArticleUpdateInput | null;
}

/**
 * The "RawArticleVersion" node's update
 */
export interface RawArticleVersionUpdateWithoutForkOfInput {
  editorId?: string | null;
  title?: string | null;
  lead?: string | null;
  body?: string | null;
  signature?: string | null;
  status?: RawArticleStatus | null;
  comment?: string | null;
  leftOver?: string | null;
  rawArticle?: RawArticleVersionUpdateRawArticleInput | null;
  previousVersion?: RawArticleVersionUpdatePreviousVersionInput | null;
  nextVersion?: RawArticleVersionUpdateNextVersionInput | null;
  forkChilrens?: RawArticleVersionUpdateForkChilrensInput | null;
}

/**
 * The "RawArticleVersion" node's update
 */
export interface RawArticleVersionUpdateWithoutPreviousVersionInput {
  editorId?: string | null;
  title?: string | null;
  lead?: string | null;
  body?: string | null;
  signature?: string | null;
  status?: RawArticleStatus | null;
  comment?: string | null;
  leftOver?: string | null;
  rawArticle?: RawArticleVersionUpdateRawArticleInput | null;
  forkOf?: RawArticleVersionUpdateForkOfInput | null;
  nextVersion?: RawArticleVersionUpdateNextVersionInput | null;
  forkChilrens?: RawArticleVersionUpdateForkChilrensInput | null;
}

/**
 * The "RawArticleVersion" node's update
 */
export interface RawArticleVersionUpdateWithoutRawArticleInput {
  editorId?: string | null;
  title?: string | null;
  lead?: string | null;
  body?: string | null;
  signature?: string | null;
  status?: RawArticleStatus | null;
  comment?: string | null;
  leftOver?: string | null;
  previousVersion?: RawArticleVersionUpdatePreviousVersionInput | null;
  forkOf?: RawArticleVersionUpdateForkOfInput | null;
  nextVersion?: RawArticleVersionUpdateNextVersionInput | null;
  forkChilrens?: RawArticleVersionUpdateForkChilrensInput | null;
}

/**
 * The "SubSubject" node's creation
 */
export interface SubSubjectCreationWithoutSubjectInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title: string;
}

/**
 * The "SubSubject" nodes' filter
 */
export interface SubSubjectFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  subject?: SubjectFilterInput | null;
  subject_not?: SubjectFilterInput | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  AND?: (SubSubjectFilterInput | null)[] | null;
  OR?: (SubSubjectFilterInput | null)[] | null;
  NOT?: SubSubjectFilterInput | null;
}

/**
 * Given a "subject", identifies exactly one "SubSubject" with one of the following combination of components' value:
 * - title / (subject)
 */
export interface SubSubjectUniqueFilterWithoutSubjectInput {
  title: string;
}

/**
 * The "SubSubject" node's update
 */
export interface SubSubjectUpdateWithoutSubjectInput {
  title?: string | null;
}

export interface SubjectCategoryCreationChildrenInput {
  create?: SubjectCategoryCreationWithoutParentInput[] | null;
}

/**
 * The "SubjectCategory" node's creation
 */
export interface SubjectCategoryCreationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  unit: SubjectCategoryCreationUnitInput;
  legacyId?: number | null;
  title: string;
  parent?: SubjectCategoryCreationParentInput | null;
  children?: SubjectCategoryCreationChildrenInput | null;
  subjects?: SubjectCategoryCreationSubjectsInput | null;
}

export interface SubjectCategoryCreationParentCreateIfNotExistsInput {
  where: SubjectCategoryUniqueFilterInput;
  data: SubjectCategoryCreationInput;
}

export interface SubjectCategoryCreationParentInput {
  connect?: SubjectCategoryUniqueFilterInput | null;
  connectIfExists?: SubjectCategoryUniqueFilterInput | null;
  create?: SubjectCategoryCreationInput | null;
  createIfNotExists?: SubjectCategoryCreationParentCreateIfNotExistsInput | null;
}

export interface SubjectCategoryCreationSubjectsInput {
  create?: SubjectCreationWithoutSubjectCategoryInput[] | null;
}

export interface SubjectCategoryCreationUnitCreateIfNotExistsInput {
  where: UnitUniqueFilterInput;
  data: UnitCreationInput;
}

export interface SubjectCategoryCreationUnitInput {
  connect?: UnitUniqueFilterInput | null;
  create?: UnitCreationInput | null;
  createIfNotExists?: SubjectCategoryCreationUnitCreateIfNotExistsInput | null;
}

/**
 * The "SubjectCategory" node's creation
 */
export interface SubjectCategoryCreationWithoutParentInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  unit: SubjectCategoryCreationUnitInput;
  legacyId?: number | null;
  title: string;
  children?: SubjectCategoryCreationChildrenInput | null;
  subjects?: SubjectCategoryCreationSubjectsInput | null;
}

/**
 * The "SubjectCategory" node's creation
 */
export interface SubjectCategoryCreationWithoutUnitInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  legacyId?: number | null;
  title: string;
  parent?: SubjectCategoryCreationParentInput | null;
  children?: SubjectCategoryCreationChildrenInput | null;
  subjects?: SubjectCategoryCreationSubjectsInput | null;
}

/**
 * The "SubjectCategory" nodes' filter
 */
export interface SubjectCategoryFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  unit?: UnitFilterInput | null;
  unit_not?: UnitFilterInput | null;
  legacyId?: number | null;
  legacyId_not?: number | null;
  legacyId_in?: (number | null)[] | null;
  legacyId_not_in?: (number | null)[] | null;
  legacyId_gt?: number | null;
  legacyId_gte?: number | null;
  legacyId_lt?: number | null;
  legacyId_lte?: number | null;
  legacyId_is_null?: boolean | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  parent?: SubjectCategoryFilterInput | null;
  parent_not?: SubjectCategoryFilterInput | null;
  parent_is_null?: boolean | null;
  children_every?: SubjectCategoryFilterInput | null;
  children_some?: SubjectCategoryFilterInput | null;
  children_none?: SubjectCategoryFilterInput | null;
  childCount?: GraphQL$UnsignedInt | null;
  childCount_not?: GraphQL$UnsignedInt | null;
  childCount_gt?: GraphQL$UnsignedInt | null;
  childCount_gte?: GraphQL$UnsignedInt | null;
  childCount_lt?: GraphQL$UnsignedInt | null;
  childCount_lte?: GraphQL$UnsignedInt | null;
  subjects_every?: SubjectFilterInput | null;
  subjects_some?: SubjectFilterInput | null;
  subjects_none?: SubjectFilterInput | null;
  subjectCount?: GraphQL$UnsignedInt | null;
  subjectCount_not?: GraphQL$UnsignedInt | null;
  subjectCount_gt?: GraphQL$UnsignedInt | null;
  subjectCount_gte?: GraphQL$UnsignedInt | null;
  subjectCount_lt?: GraphQL$UnsignedInt | null;
  subjectCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (SubjectCategoryFilterInput | null)[] | null;
  OR?: (SubjectCategoryFilterInput | null)[] | null;
  NOT?: SubjectCategoryFilterInput | null;
}

/**
 * Identifies exactly one "SubjectCategory" with one of the following combination of components' value:
 * - _id
 * - id
 */
export interface SubjectCategoryUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
}

export interface SubjectCategoryUpdateChildrenInput {
  deleteAll?: boolean | null;
  deleteMany?: SubjectCategoryFilterInput | null;
  create?: SubjectCategoryCreationWithoutParentInput[] | null;
  updateAll?: SubjectCategoryUpdateWithoutParentInput | null;
  updateMany?: SubjectCategoryUpdateChildrenUpdateManyInput | null;
}

export interface SubjectCategoryUpdateChildrenUpdateManyInput {
  where?: SubjectCategoryFilterInput | null;
  data?: SubjectCategoryUpdateWithoutParentInput | null;
}

/**
 * The "SubjectCategory" node's update
 */
export interface SubjectCategoryUpdateInput {
  unit?: SubjectCategoryUpdateUnitInput | null;
  legacyId?: number | null;
  title?: string | null;
  parent?: SubjectCategoryUpdateParentInput | null;
  children?: SubjectCategoryUpdateChildrenInput | null;
  subjects?: SubjectCategoryUpdateSubjectsInput | null;
}

export interface SubjectCategoryUpdateParentCreateIfNotExistsInput {
  where: SubjectCategoryUniqueFilterInput;
  data: SubjectCategoryCreationInput;
}

export interface SubjectCategoryUpdateParentInput {
  connect?: SubjectCategoryUniqueFilterInput | null;
  connectIfExists?: SubjectCategoryUniqueFilterInput | null;
  disconnect?: boolean | null;
  disconnectIfExists?: boolean | null;
  create?: SubjectCategoryCreationInput | null;
  createIfNotExists?: SubjectCategoryUpdateParentCreateIfNotExistsInput | null;
  update?: SubjectCategoryUpdateInput | null;
  updateIfExists?: SubjectCategoryUpdateInput | null;
}

export interface SubjectCategoryUpdateSubjectsInput {
  deleteAll?: boolean | null;
  deleteMany?: SubjectFilterInput | null;
  create?: SubjectCreationWithoutSubjectCategoryInput[] | null;
  updateAll?: SubjectUpdateWithoutSubjectCategoryInput | null;
  updateMany?: SubjectCategoryUpdateSubjectsUpdateManyInput | null;
}

export interface SubjectCategoryUpdateSubjectsUpdateManyInput {
  where?: SubjectFilterInput | null;
  data?: SubjectUpdateWithoutSubjectCategoryInput | null;
}

export interface SubjectCategoryUpdateUnitCreateIfNotExistsInput {
  where: UnitUniqueFilterInput;
  data: UnitCreationInput;
}

export interface SubjectCategoryUpdateUnitInput {
  connect?: UnitUniqueFilterInput | null;
  create?: UnitCreationInput | null;
  createIfNotExists?: SubjectCategoryUpdateUnitCreateIfNotExistsInput | null;
  update?: UnitUpdateInput | null;
}

/**
 * The "SubjectCategory" node's update
 */
export interface SubjectCategoryUpdateWithoutParentInput {
  unit?: SubjectCategoryUpdateUnitInput | null;
  legacyId?: number | null;
  title?: string | null;
  children?: SubjectCategoryUpdateChildrenInput | null;
  subjects?: SubjectCategoryUpdateSubjectsInput | null;
}

/**
 * The "SubjectCategory" node's update
 */
export interface SubjectCategoryUpdateWithoutUnitInput {
  legacyId?: number | null;
  title?: string | null;
  parent?: SubjectCategoryUpdateParentInput | null;
  children?: SubjectCategoryUpdateChildrenInput | null;
  subjects?: SubjectCategoryUpdateSubjectsInput | null;
}

export interface SubjectCreationAssignmentsInput {
  create?: AssignmentCreationWithoutSubjectInput[] | null;
}

export interface SubjectCreationAttachmentsInput {
  create?: AttachmentCreationWithoutSubjectInput[] | null;
}

/**
 * The "Subject" node's creation
 */
export interface SubjectCreationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  unit: SubjectCreationUnitInput;
  comment?: string | null;
  synopsis?: string | null;
  legacyId?: number | null;
  mediaRequest?: string | null;
  isClassified?: boolean | null;
  isAuto?: boolean | null;
  subjectCategory?: SubjectCreationSubjectCategoryInput | null;
  title: string;
  subjectUsers?: SubjectCreationSubjectUsersInput | null;
  subSubjects?: SubjectCreationSubSubjectsInput | null;
  assignments?: SubjectCreationAssignmentsInput | null;
  attachments?: SubjectCreationAttachmentsInput | null;
}

export interface SubjectCreationSubSubjectsInput {
  create?: SubSubjectCreationWithoutSubjectInput[] | null;
}

export interface SubjectCreationSubjectCategoryCreateIfNotExistsInput {
  where: SubjectCategoryUniqueFilterInput;
  data: SubjectCategoryCreationInput;
}

export interface SubjectCreationSubjectCategoryInput {
  connect?: SubjectCategoryUniqueFilterInput | null;
  connectIfExists?: SubjectCategoryUniqueFilterInput | null;
  create?: SubjectCategoryCreationInput | null;
  createIfNotExists?: SubjectCreationSubjectCategoryCreateIfNotExistsInput | null;
}

export interface SubjectCreationSubjectUsersInput {
  create?: SubjectUserCreationWithoutSubjectInput[] | null;
}

export interface SubjectCreationUnitCreateIfNotExistsInput {
  where: UnitUniqueFilterInput;
  data: UnitCreationInput;
}

export interface SubjectCreationUnitInput {
  connect?: UnitUniqueFilterInput | null;
  create?: UnitCreationInput | null;
  createIfNotExists?: SubjectCreationUnitCreateIfNotExistsInput | null;
}

/**
 * The "Subject" node's creation
 */
export interface SubjectCreationWithoutSubjectCategoryInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  unit: SubjectCreationUnitInput;
  comment?: string | null;
  synopsis?: string | null;
  legacyId?: number | null;
  mediaRequest?: string | null;
  isClassified?: boolean | null;
  isAuto?: boolean | null;
  title: string;
  subjectUsers?: SubjectCreationSubjectUsersInput | null;
  subSubjects?: SubjectCreationSubSubjectsInput | null;
  assignments?: SubjectCreationAssignmentsInput | null;
  attachments?: SubjectCreationAttachmentsInput | null;
}

/**
 * The "Subject" node's creation
 */
export interface SubjectCreationWithoutUnitInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  comment?: string | null;
  synopsis?: string | null;
  legacyId?: number | null;
  mediaRequest?: string | null;
  isClassified?: boolean | null;
  isAuto?: boolean | null;
  subjectCategory?: SubjectCreationSubjectCategoryInput | null;
  title: string;
  subjectUsers?: SubjectCreationSubjectUsersInput | null;
  subSubjects?: SubjectCreationSubSubjectsInput | null;
  assignments?: SubjectCreationAssignmentsInput | null;
  attachments?: SubjectCreationAttachmentsInput | null;
}

/**
 * The "Subject" nodes' filter
 */
export interface SubjectFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  unit?: UnitFilterInput | null;
  unit_not?: UnitFilterInput | null;
  comment?: string | null;
  comment_not?: string | null;
  comment_in?: (string | null)[] | null;
  comment_not_in?: (string | null)[] | null;
  comment_is_null?: boolean | null;
  comment_contains?: GraphQL$NonEmptyString | null;
  comment_not_contains?: GraphQL$NonEmptyString | null;
  comment_starts_with?: GraphQL$NonEmptyString | null;
  comment_not_starts_with?: GraphQL$NonEmptyString | null;
  comment_ends_with?: GraphQL$NonEmptyString | null;
  comment_not_ends_with?: GraphQL$NonEmptyString | null;
  synopsis?: string | null;
  synopsis_not?: string | null;
  synopsis_in?: (string | null)[] | null;
  synopsis_not_in?: (string | null)[] | null;
  synopsis_is_null?: boolean | null;
  synopsis_contains?: GraphQL$NonEmptyString | null;
  synopsis_not_contains?: GraphQL$NonEmptyString | null;
  synopsis_starts_with?: GraphQL$NonEmptyString | null;
  synopsis_not_starts_with?: GraphQL$NonEmptyString | null;
  synopsis_ends_with?: GraphQL$NonEmptyString | null;
  synopsis_not_ends_with?: GraphQL$NonEmptyString | null;
  legacyId?: number | null;
  legacyId_not?: number | null;
  legacyId_in?: (number | null)[] | null;
  legacyId_not_in?: (number | null)[] | null;
  legacyId_gt?: number | null;
  legacyId_gte?: number | null;
  legacyId_lt?: number | null;
  legacyId_lte?: number | null;
  legacyId_is_null?: boolean | null;
  mediaRequest?: string | null;
  mediaRequest_not?: string | null;
  mediaRequest_in?: (string | null)[] | null;
  mediaRequest_not_in?: (string | null)[] | null;
  mediaRequest_is_null?: boolean | null;
  mediaRequest_contains?: GraphQL$NonEmptyString | null;
  mediaRequest_not_contains?: GraphQL$NonEmptyString | null;
  mediaRequest_starts_with?: GraphQL$NonEmptyString | null;
  mediaRequest_not_starts_with?: GraphQL$NonEmptyString | null;
  mediaRequest_ends_with?: GraphQL$NonEmptyString | null;
  mediaRequest_not_ends_with?: GraphQL$NonEmptyString | null;
  isClassified?: boolean | null;
  isClassified_not?: boolean | null;
  isAuto?: boolean | null;
  isAuto_not?: boolean | null;
  subjectCategory?: SubjectCategoryFilterInput | null;
  subjectCategory_not?: SubjectCategoryFilterInput | null;
  subjectCategory_is_null?: boolean | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  subjectUsers_every?: SubjectUserFilterInput | null;
  subjectUsers_some?: SubjectUserFilterInput | null;
  subjectUsers_none?: SubjectUserFilterInput | null;
  subjectUserCount?: GraphQL$UnsignedInt | null;
  subjectUserCount_not?: GraphQL$UnsignedInt | null;
  subjectUserCount_gt?: GraphQL$UnsignedInt | null;
  subjectUserCount_gte?: GraphQL$UnsignedInt | null;
  subjectUserCount_lt?: GraphQL$UnsignedInt | null;
  subjectUserCount_lte?: GraphQL$UnsignedInt | null;
  subSubjects_every?: SubSubjectFilterInput | null;
  subSubjects_some?: SubSubjectFilterInput | null;
  subSubjects_none?: SubSubjectFilterInput | null;
  subSubjectCount?: GraphQL$UnsignedInt | null;
  subSubjectCount_not?: GraphQL$UnsignedInt | null;
  subSubjectCount_gt?: GraphQL$UnsignedInt | null;
  subSubjectCount_gte?: GraphQL$UnsignedInt | null;
  subSubjectCount_lt?: GraphQL$UnsignedInt | null;
  subSubjectCount_lte?: GraphQL$UnsignedInt | null;
  assignments_every?: AssignmentFilterInput | null;
  assignments_some?: AssignmentFilterInput | null;
  assignments_none?: AssignmentFilterInput | null;
  assignmentCount?: GraphQL$UnsignedInt | null;
  assignmentCount_not?: GraphQL$UnsignedInt | null;
  assignmentCount_gt?: GraphQL$UnsignedInt | null;
  assignmentCount_gte?: GraphQL$UnsignedInt | null;
  assignmentCount_lt?: GraphQL$UnsignedInt | null;
  assignmentCount_lte?: GraphQL$UnsignedInt | null;
  attachments_every?: AttachmentFilterInput | null;
  attachments_some?: AttachmentFilterInput | null;
  attachments_none?: AttachmentFilterInput | null;
  attachmentCount?: GraphQL$UnsignedInt | null;
  attachmentCount_not?: GraphQL$UnsignedInt | null;
  attachmentCount_gt?: GraphQL$UnsignedInt | null;
  attachmentCount_gte?: GraphQL$UnsignedInt | null;
  attachmentCount_lt?: GraphQL$UnsignedInt | null;
  attachmentCount_lte?: GraphQL$UnsignedInt | null;
  flatAssignments_every?: FlatAssignmentFilterInput | null;
  flatAssignments_some?: FlatAssignmentFilterInput | null;
  flatAssignments_none?: FlatAssignmentFilterInput | null;
  flatAssignmentCount?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_not?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gte?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (SubjectFilterInput | null)[] | null;
  OR?: (SubjectFilterInput | null)[] | null;
  NOT?: SubjectFilterInput | null;
}

/**
 * Identifies exactly one "Subject" with one of the following combination of components' value:
 * - _id
 * - id
 * - title / unit
 * - legacyId / unit
 */
export interface SubjectUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title?: string | null;
  unit?: UnitUniqueFilterInput | null;
  legacyId?: number | null;
}

/**
 * Given a "unit", identifies exactly one "Subject" with one of the following combination of components' value:
 * - title / (unit)
 * - legacyId / (unit)
 */
export interface SubjectUniqueFilterWithoutUnitInput {
  title?: string | null;
  legacyId?: number | null;
}

export interface SubjectUpdateAssignmentsCreateIfNotExistsInput {
  where: AssignmentUniqueFilterWithoutSubjectInput;
  data: AssignmentCreationWithoutSubjectInput;
}

export interface SubjectUpdateAssignmentsInput {
  deleteAll?: boolean | null;
  deleteMany?: AssignmentFilterInput | null;
  delete?: AssignmentUniqueFilterWithoutSubjectInput[] | null;
  deleteIfExists?: AssignmentUniqueFilterWithoutSubjectInput[] | null;
  create?: AssignmentCreationWithoutSubjectInput[] | null;
  createIfNotExists?: SubjectUpdateAssignmentsCreateIfNotExistsInput[] | null;
  updateAll?: AssignmentUpdateWithoutSubjectInput | null;
  updateMany?: SubjectUpdateAssignmentsUpdateManyInput | null;
  update?: SubjectUpdateAssignmentsUpdateInput[] | null;
  updateIfExists?: SubjectUpdateAssignmentsUpdateIfExistsInput[] | null;
  upsert?: SubjectUpdateAssignmentsUpsertInput[] | null;
}

export interface SubjectUpdateAssignmentsUpdateIfExistsInput {
  where: AssignmentUniqueFilterWithoutSubjectInput;
  data?: AssignmentUpdateWithoutSubjectInput | null;
}

export interface SubjectUpdateAssignmentsUpdateInput {
  where: AssignmentUniqueFilterWithoutSubjectInput;
  data?: AssignmentUpdateWithoutSubjectInput | null;
}

export interface SubjectUpdateAssignmentsUpdateManyInput {
  where?: AssignmentFilterInput | null;
  data?: AssignmentUpdateWithoutSubjectInput | null;
}

export interface SubjectUpdateAssignmentsUpsertInput {
  where: AssignmentUniqueFilterWithoutSubjectInput;
  create: AssignmentCreationWithoutSubjectInput;
  update?: AssignmentUpdateWithoutSubjectInput | null;
}

export interface SubjectUpdateAttachmentsInput {
  deleteAll?: boolean | null;
  deleteMany?: AttachmentFilterInput | null;
  create?: AttachmentCreationWithoutSubjectInput[] | null;
  updateAll?: AttachmentUpdateWithoutSubjectInput | null;
  updateMany?: SubjectUpdateAttachmentsUpdateManyInput | null;
}

export interface SubjectUpdateAttachmentsUpdateManyInput {
  where?: AttachmentFilterInput | null;
  data?: AttachmentUpdateWithoutSubjectInput | null;
}

/**
 * The "Subject" node's update
 */
export interface SubjectUpdateInput {
  unit?: SubjectUpdateUnitInput | null;
  comment?: string | null;
  synopsis?: string | null;
  legacyId?: number | null;
  mediaRequest?: string | null;
  isClassified?: boolean | null;
  isAuto?: boolean | null;
  subjectCategory?: SubjectUpdateSubjectCategoryInput | null;
  title?: string | null;
  subjectUsers?: SubjectUpdateSubjectUsersInput | null;
  subSubjects?: SubjectUpdateSubSubjectsInput | null;
  assignments?: SubjectUpdateAssignmentsInput | null;
  attachments?: SubjectUpdateAttachmentsInput | null;
}

export interface SubjectUpdateSubSubjectsCreateIfNotExistsInput {
  where: SubSubjectUniqueFilterWithoutSubjectInput;
  data: SubSubjectCreationWithoutSubjectInput;
}

export interface SubjectUpdateSubSubjectsInput {
  deleteAll?: boolean | null;
  deleteMany?: SubSubjectFilterInput | null;
  delete?: SubSubjectUniqueFilterWithoutSubjectInput[] | null;
  deleteIfExists?: SubSubjectUniqueFilterWithoutSubjectInput[] | null;
  create?: SubSubjectCreationWithoutSubjectInput[] | null;
  createIfNotExists?: SubjectUpdateSubSubjectsCreateIfNotExistsInput[] | null;
  updateAll?: SubSubjectUpdateWithoutSubjectInput | null;
  updateMany?: SubjectUpdateSubSubjectsUpdateManyInput | null;
  update?: SubjectUpdateSubSubjectsUpdateInput[] | null;
  updateIfExists?: SubjectUpdateSubSubjectsUpdateIfExistsInput[] | null;
  upsert?: SubjectUpdateSubSubjectsUpsertInput[] | null;
}

export interface SubjectUpdateSubSubjectsUpdateIfExistsInput {
  where: SubSubjectUniqueFilterWithoutSubjectInput;
  data?: SubSubjectUpdateWithoutSubjectInput | null;
}

export interface SubjectUpdateSubSubjectsUpdateInput {
  where: SubSubjectUniqueFilterWithoutSubjectInput;
  data?: SubSubjectUpdateWithoutSubjectInput | null;
}

export interface SubjectUpdateSubSubjectsUpdateManyInput {
  where?: SubSubjectFilterInput | null;
  data?: SubSubjectUpdateWithoutSubjectInput | null;
}

export interface SubjectUpdateSubSubjectsUpsertInput {
  where: SubSubjectUniqueFilterWithoutSubjectInput;
  create: SubSubjectCreationWithoutSubjectInput;
  update?: SubSubjectUpdateWithoutSubjectInput | null;
}

export interface SubjectUpdateSubjectCategoryCreateIfNotExistsInput {
  where: SubjectCategoryUniqueFilterInput;
  data: SubjectCategoryCreationInput;
}

export interface SubjectUpdateSubjectCategoryInput {
  connect?: SubjectCategoryUniqueFilterInput | null;
  connectIfExists?: SubjectCategoryUniqueFilterInput | null;
  disconnect?: boolean | null;
  disconnectIfExists?: boolean | null;
  create?: SubjectCategoryCreationInput | null;
  createIfNotExists?: SubjectUpdateSubjectCategoryCreateIfNotExistsInput | null;
  update?: SubjectCategoryUpdateInput | null;
  updateIfExists?: SubjectCategoryUpdateInput | null;
}

export interface SubjectUpdateSubjectUsersCreateIfNotExistsInput {
  where: SubjectUserUniqueFilterWithoutSubjectInput;
  data: SubjectUserCreationWithoutSubjectInput;
}

export interface SubjectUpdateSubjectUsersInput {
  deleteAll?: boolean | null;
  deleteMany?: SubjectUserFilterInput | null;
  delete?: SubjectUserUniqueFilterWithoutSubjectInput[] | null;
  deleteIfExists?: SubjectUserUniqueFilterWithoutSubjectInput[] | null;
  create?: SubjectUserCreationWithoutSubjectInput[] | null;
  createIfNotExists?: SubjectUpdateSubjectUsersCreateIfNotExistsInput[] | null;
}

export interface SubjectUpdateUnitCreateIfNotExistsInput {
  where: UnitUniqueFilterInput;
  data: UnitCreationInput;
}

export interface SubjectUpdateUnitInput {
  connect?: UnitUniqueFilterInput | null;
  create?: UnitCreationInput | null;
  createIfNotExists?: SubjectUpdateUnitCreateIfNotExistsInput | null;
  update?: UnitUpdateInput | null;
}

/**
 * The "Subject" node's update
 */
export interface SubjectUpdateWithoutSubjectCategoryInput {
  unit?: SubjectUpdateUnitInput | null;
  comment?: string | null;
  synopsis?: string | null;
  legacyId?: number | null;
  mediaRequest?: string | null;
  isClassified?: boolean | null;
  isAuto?: boolean | null;
  title?: string | null;
  subjectUsers?: SubjectUpdateSubjectUsersInput | null;
  subSubjects?: SubjectUpdateSubSubjectsInput | null;
  assignments?: SubjectUpdateAssignmentsInput | null;
  attachments?: SubjectUpdateAttachmentsInput | null;
}

/**
 * The "Subject" node's update
 */
export interface SubjectUpdateWithoutUnitInput {
  comment?: string | null;
  synopsis?: string | null;
  legacyId?: number | null;
  mediaRequest?: string | null;
  isClassified?: boolean | null;
  isAuto?: boolean | null;
  subjectCategory?: SubjectUpdateSubjectCategoryInput | null;
  title?: string | null;
  subjectUsers?: SubjectUpdateSubjectUsersInput | null;
  subSubjects?: SubjectUpdateSubSubjectsInput | null;
  assignments?: SubjectUpdateAssignmentsInput | null;
  attachments?: SubjectUpdateAttachmentsInput | null;
}

/**
 * The "SubjectUser" node's creation
 */
export interface SubjectUserCreationWithoutSubjectInput {
  userId: string;
}

/**
 * The "SubjectUser" nodes' filter
 */
export interface SubjectUserFilterInput {
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  userId?: string | null;
  userId_not?: string | null;
  userId_in?: string[] | null;
  userId_not_in?: string[] | null;
  userId_contains?: GraphQL$NonEmptyString | null;
  userId_not_contains?: GraphQL$NonEmptyString | null;
  userId_starts_with?: GraphQL$NonEmptyString | null;
  userId_not_starts_with?: GraphQL$NonEmptyString | null;
  userId_ends_with?: GraphQL$NonEmptyString | null;
  userId_not_ends_with?: GraphQL$NonEmptyString | null;
  subject?: SubjectFilterInput | null;
  subject_not?: SubjectFilterInput | null;
  AND?: (SubjectUserFilterInput | null)[] | null;
  OR?: (SubjectUserFilterInput | null)[] | null;
  NOT?: SubjectUserFilterInput | null;
}

/**
 * Given a "subject", identifies exactly one "SubjectUser" with one of the following combination of components' value:
 * - userId / (subject)
 */
export interface SubjectUserUniqueFilterWithoutSubjectInput {
  userId: string;
}

export interface UnitCreationBrandsInput {
  create?: BrandCreationWithoutUnitInput[] | null;
}

/**
 * The "Unit" node's creation
 */
export interface UnitCreationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title: string;
  subjects?: UnitCreationSubjectsInput | null;
  subjectCategories?: UnitCreationSubjectCategoriesInput | null;
  brands?: UnitCreationBrandsInput | null;
}

export interface UnitCreationSubjectCategoriesInput {
  create?: SubjectCategoryCreationWithoutUnitInput[] | null;
}

export interface UnitCreationSubjectsInput {
  create?: SubjectCreationWithoutUnitInput[] | null;
}

/**
 * The "Unit" nodes' filter
 */
export interface UnitFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_gt?: string | null;
  title_gte?: string | null;
  title_lt?: string | null;
  title_lte?: string | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  subjects_every?: SubjectFilterInput | null;
  subjects_some?: SubjectFilterInput | null;
  subjects_none?: SubjectFilterInput | null;
  subjectCount?: GraphQL$UnsignedInt | null;
  subjectCount_not?: GraphQL$UnsignedInt | null;
  subjectCount_gt?: GraphQL$UnsignedInt | null;
  subjectCount_gte?: GraphQL$UnsignedInt | null;
  subjectCount_lt?: GraphQL$UnsignedInt | null;
  subjectCount_lte?: GraphQL$UnsignedInt | null;
  subjectCategories_every?: SubjectCategoryFilterInput | null;
  subjectCategories_some?: SubjectCategoryFilterInput | null;
  subjectCategories_none?: SubjectCategoryFilterInput | null;
  subjectCategoryCount?: GraphQL$UnsignedInt | null;
  subjectCategoryCount_not?: GraphQL$UnsignedInt | null;
  subjectCategoryCount_gt?: GraphQL$UnsignedInt | null;
  subjectCategoryCount_gte?: GraphQL$UnsignedInt | null;
  subjectCategoryCount_lt?: GraphQL$UnsignedInt | null;
  subjectCategoryCount_lte?: GraphQL$UnsignedInt | null;
  brands_every?: BrandFilterInput | null;
  brands_some?: BrandFilterInput | null;
  brands_none?: BrandFilterInput | null;
  brandCount?: GraphQL$UnsignedInt | null;
  brandCount_not?: GraphQL$UnsignedInt | null;
  brandCount_gt?: GraphQL$UnsignedInt | null;
  brandCount_gte?: GraphQL$UnsignedInt | null;
  brandCount_lt?: GraphQL$UnsignedInt | null;
  brandCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (UnitFilterInput | null)[] | null;
  OR?: (UnitFilterInput | null)[] | null;
  NOT?: UnitFilterInput | null;
}

/**
 * Identifies exactly one "Unit" with one of the following combination of components' value:
 * - _id
 * - id
 */
export interface UnitUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
}

export interface UnitUpdateBrandsInput {
  deleteAll?: boolean | null;
  deleteMany?: BrandFilterInput | null;
  create?: BrandCreationWithoutUnitInput[] | null;
  updateAll?: BrandUpdateWithoutUnitInput | null;
  updateMany?: UnitUpdateBrandsUpdateManyInput | null;
}

export interface UnitUpdateBrandsUpdateManyInput {
  where?: BrandFilterInput | null;
  data?: BrandUpdateWithoutUnitInput | null;
}

/**
 * The "Unit" node's update
 */
export interface UnitUpdateInput {
  title?: string | null;
  subjects?: UnitUpdateSubjectsInput | null;
  subjectCategories?: UnitUpdateSubjectCategoriesInput | null;
  brands?: UnitUpdateBrandsInput | null;
}

export interface UnitUpdateSubjectCategoriesInput {
  deleteAll?: boolean | null;
  deleteMany?: SubjectCategoryFilterInput | null;
  create?: SubjectCategoryCreationWithoutUnitInput[] | null;
  updateAll?: SubjectCategoryUpdateWithoutUnitInput | null;
  updateMany?: UnitUpdateSubjectCategoriesUpdateManyInput | null;
}

export interface UnitUpdateSubjectCategoriesUpdateManyInput {
  where?: SubjectCategoryFilterInput | null;
  data?: SubjectCategoryUpdateWithoutUnitInput | null;
}

export interface UnitUpdateSubjectsCreateIfNotExistsInput {
  where: SubjectUniqueFilterWithoutUnitInput;
  data: SubjectCreationWithoutUnitInput;
}

export interface UnitUpdateSubjectsInput {
  deleteAll?: boolean | null;
  deleteMany?: SubjectFilterInput | null;
  delete?: SubjectUniqueFilterWithoutUnitInput[] | null;
  deleteIfExists?: SubjectUniqueFilterWithoutUnitInput[] | null;
  create?: SubjectCreationWithoutUnitInput[] | null;
  createIfNotExists?: UnitUpdateSubjectsCreateIfNotExistsInput[] | null;
  updateAll?: SubjectUpdateWithoutUnitInput | null;
  updateMany?: UnitUpdateSubjectsUpdateManyInput | null;
  update?: UnitUpdateSubjectsUpdateInput[] | null;
  updateIfExists?: UnitUpdateSubjectsUpdateIfExistsInput[] | null;
  upsert?: UnitUpdateSubjectsUpsertInput[] | null;
}

export interface UnitUpdateSubjectsUpdateIfExistsInput {
  where: SubjectUniqueFilterWithoutUnitInput;
  data?: SubjectUpdateWithoutUnitInput | null;
}

export interface UnitUpdateSubjectsUpdateInput {
  where: SubjectUniqueFilterWithoutUnitInput;
  data?: SubjectUpdateWithoutUnitInput | null;
}

export interface UnitUpdateSubjectsUpdateManyInput {
  where?: SubjectFilterInput | null;
  data?: SubjectUpdateWithoutUnitInput | null;
}

export interface UnitUpdateSubjectsUpsertInput {
  where: SubjectUniqueFilterWithoutUnitInput;
  create: SubjectCreationWithoutUnitInput;
  update?: SubjectUpdateWithoutUnitInput | null;
}

export interface WebsiteCreationAssignmentsInput {
  create?: AssignmentCreationWithoutWebsiteInput[] | null;
}

export interface WebsiteCreationBrandCreateIfNotExistsInput {
  where: BrandUniqueFilterInput;
  data: BrandCreationInput;
}

export interface WebsiteCreationBrandInput {
  connect?: BrandUniqueFilterInput | null;
  create?: BrandCreationInput | null;
  createIfNotExists?: WebsiteCreationBrandCreateIfNotExistsInput | null;
}

/**
 * The "Website" node's creation
 */
export interface WebsiteCreationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title: string;
  key: string;
  legacyId?: number | null;
  brand: WebsiteCreationBrandInput;
  assignments?: WebsiteCreationAssignmentsInput | null;
}

/**
 * The "Website" node's creation
 */
export interface WebsiteCreationWithoutBrandInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title: string;
  key: string;
  legacyId?: number | null;
  assignments?: WebsiteCreationAssignmentsInput | null;
}

/**
 * The "Website" nodes' filter
 */
export interface WebsiteFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  key?: string | null;
  key_not?: string | null;
  key_in?: string[] | null;
  key_not_in?: string[] | null;
  key_gt?: string | null;
  key_gte?: string | null;
  key_lt?: string | null;
  key_lte?: string | null;
  key_contains?: GraphQL$NonEmptyString | null;
  key_not_contains?: GraphQL$NonEmptyString | null;
  key_starts_with?: GraphQL$NonEmptyString | null;
  key_not_starts_with?: GraphQL$NonEmptyString | null;
  key_ends_with?: GraphQL$NonEmptyString | null;
  key_not_ends_with?: GraphQL$NonEmptyString | null;
  legacyId?: number | null;
  legacyId_not?: number | null;
  legacyId_in?: (number | null)[] | null;
  legacyId_not_in?: (number | null)[] | null;
  legacyId_gt?: number | null;
  legacyId_gte?: number | null;
  legacyId_lt?: number | null;
  legacyId_lte?: number | null;
  legacyId_is_null?: boolean | null;
  brand?: BrandFilterInput | null;
  brand_not?: BrandFilterInput | null;
  assignments_every?: AssignmentFilterInput | null;
  assignments_some?: AssignmentFilterInput | null;
  assignments_none?: AssignmentFilterInput | null;
  assignmentCount?: GraphQL$UnsignedInt | null;
  assignmentCount_not?: GraphQL$UnsignedInt | null;
  assignmentCount_gt?: GraphQL$UnsignedInt | null;
  assignmentCount_gte?: GraphQL$UnsignedInt | null;
  assignmentCount_lt?: GraphQL$UnsignedInt | null;
  assignmentCount_lte?: GraphQL$UnsignedInt | null;
  flatAssignments_every?: FlatAssignmentFilterInput | null;
  flatAssignments_some?: FlatAssignmentFilterInput | null;
  flatAssignments_none?: FlatAssignmentFilterInput | null;
  flatAssignmentCount?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_not?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gte?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (WebsiteFilterInput | null)[] | null;
  OR?: (WebsiteFilterInput | null)[] | null;
  NOT?: WebsiteFilterInput | null;
}

/**
 * Identifies exactly one "Website" with one of the following combination of components' value:
 * - _id
 * - id
 * - key
 * - legacyId / brand
 * - title / brand
 */
export interface WebsiteUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  key?: string | null;
  legacyId?: number | null;
  brand?: BrandUniqueFilterInput | null;
  title?: string | null;
}

/**
 * Given a "brand", identifies exactly one "Website" with one of the following combination of components' value:
 * - legacyId / (brand)
 * - title / (brand)
 */
export interface WebsiteUniqueFilterWithoutBrandInput {
  legacyId?: number | null;
  title?: string | null;
}

export interface WebsiteUpdateAssignmentsInput {
  deleteAll?: boolean | null;
  deleteMany?: AssignmentFilterInput | null;
  create?: AssignmentCreationWithoutWebsiteInput[] | null;
  updateAll?: AssignmentUpdateWithoutWebsiteInput | null;
  updateMany?: WebsiteUpdateAssignmentsUpdateManyInput | null;
}

export interface WebsiteUpdateAssignmentsUpdateManyInput {
  where?: AssignmentFilterInput | null;
  data?: AssignmentUpdateWithoutWebsiteInput | null;
}

export interface WebsiteUpdateBrandCreateIfNotExistsInput {
  where: BrandUniqueFilterInput;
  data: BrandCreationInput;
}

export interface WebsiteUpdateBrandInput {
  connect?: BrandUniqueFilterInput | null;
  create?: BrandCreationInput | null;
  createIfNotExists?: WebsiteUpdateBrandCreateIfNotExistsInput | null;
  update?: BrandUpdateInput | null;
}

/**
 * The "Website" node's update
 */
export interface WebsiteUpdateInput {
  title?: string | null;
  key?: string | null;
  legacyId?: number | null;
  brand?: WebsiteUpdateBrandInput | null;
  assignments?: WebsiteUpdateAssignmentsInput | null;
}

/**
 * The "Website" node's update
 */
export interface WebsiteUpdateWithoutBrandInput {
  title?: string | null;
  key?: string | null;
  legacyId?: number | null;
  assignments?: WebsiteUpdateAssignmentsInput | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
