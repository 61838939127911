import { gql } from '@apollo/client';

export const GET_GOOD_DEAL_PROMPTS = gql`
  query GetGoodDealPrompts {
    articleGoodDealAIPrompts(first: 10) {
      promptOrder
      prompt
    }
  }
`;
