import { client } from '../../../../apollo';
import {
  GetPhotos,
  GetPhotosVariables,
  MetadataNameEnumType,
  PhotoOrderByInput,
} from '../../../../__generated__/queries-photo';
import { GET_PHOTOS } from '../../../../apollo/queries/photos.photo.graphql';

export const loadStorySubjectPhoto = async (subjectId: string) => {
  try {
    const { data } = await client.query<GetPhotos, GetPhotosVariables>({
      query: GET_PHOTOS,
      variables: {
        first: 50,
        where: {
          AND: [
            {
              photoMetadatas_some: {
                metadata: { name: MetadataNameEnumType.ID_subject },
                value: subjectId,
              },
            },
            {
              photoMetadatas_some: {
                value: 'WEB',
                metadata: { name: MetadataNameEnumType.UserDefined239 },
              },
            },
          ],
        },
        orderBy: [PhotoOrderByInput.createdAt_DESC],
      },
    });
    return data.photos;
  } catch (error) {
    console.error(error);
  }
  return [];
};
