import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { DefaultProps } from './';

const DEFAULT_TAG_WIDTH = 230;

interface DefaultStyledProps extends Pick<DefaultProps, 'height' | 'width'> {
  isDisabled: boolean;
}
export const useStyles = makeStyles<Theme, DefaultStyledProps>((theme) => ({
  dragAndDropWrapper: ({ height, isDisabled, width }) => ({
    display: 'grid',
    gap: theme.spacing(1.5),
    gridTemplateColumns: `repeat(auto-fill, ${width || DEFAULT_TAG_WIDTH}px)`,

    '& > div': {
      height: height || DEFAULT_TAG_WIDTH * 0.33,

      ...(isDisabled && {
        filter: 'grayscale(100%)',
        opacity: 0.6,
        pointerEvents: 'none',
      }),
    },
  }),
  listHeaderButton: {
    position: 'fixed',
    right: theme.spacing(2),
    top: theme.spacing(1.65),
  },
  searchBarNoResultPlaceholder: {
    display: 'block',

    '& + button': {
      marginTop: theme.spacing(1.5),
    },
  },
  wrapperSkeletons: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
}));
