import { AutocompleteItem } from '@prismamedia/one-components';
import { useGetCategories } from '../apollo/sharedQueries/categories.query.web.graphql';
import {
  CategoryWhereInput,
  GetCategories_categories,
} from '../__generated__/queries-web';

const spreadCategoryChildren = (
  categories: GetCategories_categories[],
  disableCategories: string[],
  parents: GetCategories_categories[],
  level = -1,
): AutocompleteItem[] =>
  parents
    .reduce(
      (prev, category) => [
        ...prev,
        {
          id: category.id,
          label: category.title,
          level,
          disabled: disableCategories?.includes(category.id),
          additionnals: { category },
        },
        ...spreadCategoryChildren(
          categories,
          disableCategories,
          categories.filter(({ parent }) => parent?.id === category.id),
          level + 1,
        ),
      ],
      [] as AutocompleteItem[],
    )
    .filter((parent) => parent.level !== -1);

export const useFetchCategories = (
  where: CategoryWhereInput = {},
  disableCategories: string[] = [],
  minLevel = 2,
) => {
  const getCategories = useGetCategories();

  return async (search: string) => {
    const data = await getCategories({
      where: {
        title_contains: search,
        level_gte: minLevel,
        ...where,
      },
    });

    if (search) {
      return data?.categories
        ?.filter(({ level }) => level > minLevel)
        .map((category) => ({
          id: category.id,
          label: category.title,
          disabled: disableCategories?.includes(category.id),
          additionnals: { category },
        }));
    }

    const categories = data?.categories || [];
    return spreadCategoryChildren(
      categories,
      disableCategories,
      categories.filter(({ level }) => level === minLevel),
    );
  };
};
