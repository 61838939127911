import { omit } from 'lodash';
import { TagType } from '../../../__generated__/queries-web';
import { DEFAULT_CATEGORY_FORM } from '../constants';
import type { Category, CategoryForm, CategoryTag } from '../types';
import { ALLOWED_CATEGORY_FORM_SECTIONS } from '../views/Edit/constants';
import { getCategoryFormSection } from './categoryFormSection.model';

export const getCategoryForm = ({
  bodyDraft,
  brandKey,
  createdAt,
  description,
  format,
  isHeadlined,
  id,
  level,
  medias,
  parent,
  publishedAt,
  slug,
  status,
  subtitle,
  tags,
  title,
  tabs,
  editedAt,
}: Partial<Category>): CategoryForm => {
  let parsedTags = DEFAULT_CATEGORY_FORM.tags;
  let parsedMedias = DEFAULT_CATEGORY_FORM.medias;
  let parsedBodyDraft = DEFAULT_CATEGORY_FORM.description;

  if (tags) {
    try {
      parsedTags = JSON.parse(tags);
    } catch (e) {}
  }

  if (medias?.length) {
    try {
      parsedMedias = JSON.parse(medias).filter(Boolean);
    } catch (e) {}
  }

  if (bodyDraft?.length) {
    try {
      parsedBodyDraft = JSON.parse(bodyDraft);
    } catch (e) {}
  }

  return {
    ...(id && { id }),
    ...(createdAt && { createdAt }),
    ...(editedAt && { editedAt }),
    brandKey: brandKey || DEFAULT_CATEGORY_FORM.brandKey,
    description: parsedBodyDraft,
    descriptionSEO: description || DEFAULT_CATEGORY_FORM.descriptionSEO,
    format: format || DEFAULT_CATEGORY_FORM.format,
    isHeadlined: isHeadlined || DEFAULT_CATEGORY_FORM.isHeadlined,
    level: level || DEFAULT_CATEGORY_FORM.level,
    medias: parsedMedias,
    parent: omit(parent, '__typename') || DEFAULT_CATEGORY_FORM.parent,
    publishedAt: publishedAt || DEFAULT_CATEGORY_FORM.publishedAt,
    sections:
      tabs
        ?.filter(
          (tab) =>
            ALLOWED_CATEGORY_FORM_SECTIONS.findIndex(
              (allowedSection) => allowedSection.type === tab.type,
            ) > -1,
        )
        .map(getCategoryFormSection) || DEFAULT_CATEGORY_FORM.sections,
    slug: slug || DEFAULT_CATEGORY_FORM.slug,
    subTitle: subtitle || DEFAULT_CATEGORY_FORM.subTitle,
    status: status || DEFAULT_CATEGORY_FORM.status,
    tags: parsedTags.map((tag: CategoryTag) => ({ ...tag, type: TagType.Tag })),
    title: title || DEFAULT_CATEGORY_FORM.title,
  };
};
