import { Button, TextField } from '@mui/material';
import { UnregisterCallback } from 'history';
import { Dispatch, FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  ArticleFormat,
  GetArticle_article,
} from '../../../__generated__/queries-web';
import { Qualifiers } from '../../ArticleEdit/DrawerContent/Qualifiers';
import { renderInputFromTemplate } from '../../ArticleEdit/utils/renderFromTemplate';
import { CategorySelector } from '../CategorySelector';
import { SourceInput } from '../SourceInput';
import { SlideshowEditAction, actions } from '../reducer';
import { TagsDialog } from './TagsDialog';
import {
  ADD_TAGS_BUTTON_TEXT,
  PUBLICATION_DATE_FIELD_LABEL,
  ROUTER_HASH_TAGS_ENHANCEMENT,
} from './constants';
import { useStyles } from './styles';

let unregisterHistoryListener: UnregisterCallback;

export interface GeneralInformationProps {
  categoryId: string;
  dispatch: Dispatch<SlideshowEditAction>;
  publishedAt: string | null;
  article: GetArticle_article;
}

export const GeneralInformation: FC<GeneralInformationProps> = ({
  categoryId,
  dispatch,
  publishedAt,
  article,
}) => {
  const classes = useStyles();
  const [isTagsDialogOpen, setIsTagsDialogOpen] = useState<boolean>(false);
  const history = useHistory();
  const params = useParams();
  const hasId = !!params.id;

  const handleOpenTagsDialog = () => {
    setIsTagsDialogOpen(true);
  };

  const handleCloseTagsDialog = () => {
    setIsTagsDialogOpen(false);
  };

  useEffect(() => {
    const {
      location: { hash },
    } = history;

    if (article.brandKey && hash.includes(ROUTER_HASH_TAGS_ENHANCEMENT)) {
      handleOpenTagsDialog();
    }

    // Each time the history path changes
    unregisterHistoryListener = history.listen(
      ({ hash: currentHash }: Record<'hash', string>) => {
        article.brandKey && currentHash.includes(ROUTER_HASH_TAGS_ENHANCEMENT)
          ? handleOpenTagsDialog()
          : handleCloseTagsDialog();
      },
    );

    return () => {
      unregisterHistoryListener?.();
    };
  }, [article.brandKey, history]);

  return (
    <>
      <TextField
        sx={{ mt: 2 }}
        InputLabelProps={{ shrink: true }}
        fullWidth
        label="Titre"
        multiline
        onChange={(event) => {
          dispatch(actions.setTitle({ title: event.target.value }));
        }}
        value={article.title || ''}
        variant="standard"
      />
      {article.brandKey && (
        <>
          <CategorySelector
            brandKey={article.brandKey}
            categoryId={categoryId}
            dispatch={dispatch}
            sx={{ mt: 2 }}
          />
          {renderInputFromTemplate(
            article.format,
            article.brandKey,
          )('source') && (
            <SourceInput
              brandKey={article.brandKey}
              source={article.source}
              dispatch={dispatch}
              sx={{ mt: 2 }}
            />
          )}
        </>
      )}
      <TextField
        InputLabelProps={{ shrink: true }}
        fullWidth
        label={PUBLICATION_DATE_FIELD_LABEL}
        onChange={(event) => {
          dispatch(
            actions.setPublishedAt({
              publishedAt: event.target.value,
            }),
          );
        }}
        type="datetime-local"
        value={publishedAt || ''}
        variant="standard"
        sx={{ mt: 2 }}
      />
      {article.brandKey && (
        <>
          <Button
            className={classes.addTagsButton}
            sx={{ mb: 2 }}
            color="primary"
            fullWidth
            onClick={() => {
              if (hasId) {
                history.push({ hash: ROUTER_HASH_TAGS_ENHANCEMENT });
              }
            }}
            variant="contained"
          >
            {ADD_TAGS_BUTTON_TEXT}
          </Button>
          <Qualifiers
            brandKey={article.brandKey}
            articleFormat={ArticleFormat.Slideshow}
            articleQualifiers={article.articleQualifiers}
            onChange={(articleQualifiers) => {
              dispatch(
                actions.setQualifiers({
                  articleQualifiers,
                }),
              );
            }}
          />
        </>
      )}

      <TagsDialog
        dispatch={dispatch}
        isTagsDialogOpen={isTagsDialogOpen}
        article={article}
      />
    </>
  );
};
