import {
  GetArticleEvent,
  GetArticleTag,
} from '../../pages/ArticleEdit/utils/models';
import { GetCardTag } from '../../pages/Card/CardEdit/utils/models';

export const isCardTag = (
  item: Partial<GetArticleEvent | GetArticleTag | GetCardTag> & {
    type?: string;
  },
): item is GetCardTag => item.type === 'CardTag';

export const isEventTag = (
  item: Partial<GetArticleEvent | GetArticleTag | GetCardTag> & {
    type?: string;
  },
): item is GetArticleEvent => item.type === 'Event' || !item.type;
