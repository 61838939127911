import { gql } from '@apollo/client';

export const UPDATE_GOOD_DEAL_PROMPT = gql`
  mutation UpdateGoodDealPrompts(
    $where: ArticleGoodDealAIPromptWhereUniqueInput!
    $data: ArticleGoodDealAIPromptUpdateInput!
  ) {
    updateArticleGoodDealAIPrompt(where: $where, data: $data) {
      id
    }
  }
`;
