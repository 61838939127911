import { Category } from '../pages/Pages/types';
import { BrandKey, CategoryFormat } from '../__generated__/queries-web';

export const getRandomBrandKey = (index: number) =>
  !index
    ? BrandKey.TEL
    : index === 1
    ? BrandKey.BIN
    : index === 2
    ? BrandKey.CAP
    : BrandKey.FAC;

export const getRandomFormat = (index: number) =>
  !index
    ? CategoryFormat.Event
    : index === 1
    ? CategoryFormat.Channel
    : index === 2
    ? CategoryFormat.Guide
    : CategoryFormat.Category;

export const getCategoryMock = (
  categoryMock: Category,
  {
    brandKey,
    format,
    level,
    index,
  }: {
    brandKey: BrandKey;
    format: CategoryFormat;
    level: number;
    index: number;
  },
) => ({
  ...categoryMock,
  brandKey,
  format,
  level,
  id: `ID_${index + 1}`,
  parent: null,
  slug: '',
  tabs: [],
  tags: '[]',
  title: `TITLE ${index + 1}`,
  children: categoryMock.children.map(({ id, ...rest }, indexBis) => ({
    ...rest,
    brandKey,
    format,
    id: `ID_${index + 1}_${indexBis + 1}`,
    level: level + 1,
    title: `TITLE CHILD ${index + 1}-${indexBis + 1}`,
  })),
});

// Use this fn to temporary pause the unit test, usefull for async React renders
export const pause = (time = 1000): Promise<unknown> =>
  new Promise((r) => {
    setTimeout(() => r(''), time);
  });
