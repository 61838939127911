import { Box, Typography } from '@mui/material';
import React, { FC, Fragment } from 'react';
import {
  BrandKey,
  GetArticle_article_articleEvents,
  GetArticle_article_articleEvents_category,
  GetArticle_article_articleGuides,
  GetArticle_article_articleTags,
  GetArticle_article_articleTags_tag,
  getCard_card_cardTags,
  getCard_card_cardTags_tag,
  SimilarArticleInputType,
} from '../../__generated__/queries-web';
import { TAGS_SECTION_ID_SELECTOR } from './constants';
import { useStyles } from './styles';

export interface TagsProps {
  article?: SimilarArticleInputType;
  brandKey: BrandKey;
  configuration: {
    component: FC<any>;
    id: string;
    props: {
      field: string;
      tags: (
        | GetArticle_article_articleEvents_category
        | GetArticle_article_articleTags_tag
        | getCard_card_cardTags_tag
        | GetArticle_article_articleGuides
      )[];
      [key: string]: unknown;
    };
  }[];
  onTagsChange: {
    (
      tags: (
        | GetArticle_article_articleEvents
        | GetArticle_article_articleTags
        | getCard_card_cardTags
        | GetArticle_article_articleGuides
      )[],
      field?: string,
    ): void;
  };
  title?: string;
  QualifierComponent?: React.ReactNode;
}

export const Tags: FC<TagsProps> = ({
  configuration,
  title,
  QualifierComponent = null,
  ...tagsProps
}) => {
  const classes = useStyles({ withMargin: !!configuration.length });

  if (!configuration.length) {
    return <Fragment />;
  }

  return (
    <Box
      className={classes.wrapper}
      component="section"
      id={TAGS_SECTION_ID_SELECTOR}
    >
      {title && (
        <Typography className={classes.mainTitle} component="h2">
          {title}
        </Typography>
      )}

      {configuration.map(
        ({ component: Tag, id, props: configurationProps }) => (
          <Tag
            key={id}
            suggestedTagList={[]}
            {...tagsProps}
            {...configurationProps}
          />
        ),
      )}
      <br />
      {QualifierComponent}
    </Box>
  );
};
