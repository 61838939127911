import {
  AutocompleteItem,
  ChipsInput,
  isDefined,
} from '@prismamedia/one-components';
import { FC, useEffect, useState } from 'react';
import { useQualifierGetter } from '../../../crud/qualifier/qualifierEdit/getQualifier.web.graphql';
import { useGlobalBrandKey } from '../../../utils/globalState';
import {
  ArticleFormat,
  QualifierOrderByInput,
  QualifierStatus,
} from '../../../__generated__/queries-web';
import { useQualifiersGetter } from '../../ArticleEdit/DrawerContent/getQualifers.web.graphql';

interface QualifiersSelectProps {
  label: string;
  className?: string;
  value?: string[];
  onChange?: (value?: string[]) => void;
}

export const QualifiersSelect: FC<QualifiersSelectProps> = ({
  label,
  className,
  value,
  onChange,
}) => {
  const [brandKey] = useGlobalBrandKey();
  const [internalValue, setInternalValue] = useState<AutocompleteItem[]>([]);
  const getQualifiers = useQualifiersGetter();
  const getQualifier = useQualifierGetter();

  const fetchList = (search: string) =>
    getQualifiers({
      first: 100,
      where: {
        title_contains: search,
        brandKey,
        format: ArticleFormat.Rich,
        status: QualifierStatus.Published,
      },
      orderBy: [QualifierOrderByInput.createdAt_DESC],
    }).then((data) =>
      data?.qualifiers
        .filter(({ title }) => !!title)
        .map(({ id, title }) => ({ id, label: title })),
    );

  useEffect(() => {
    if (!value) {
      setInternalValue([]);
      return;
    }
    Promise.all(value.map((id) => getQualifier({ where: { id } }))).then(
      (res) =>
        setInternalValue(
          res
            .map((data) => data.qualifier)
            .filter(isDefined)
            .map((qualifier) => ({
              id: qualifier.id,
              label: qualifier.title,
            })),
        ),
    );
  }, [value, getQualifier]);

  return (
    <ChipsInput
      variant="outlined"
      disabled={!brandKey}
      label={label}
      value={internalValue}
      className={className}
      onChange={(val) =>
        onChange?.(val?.length ? val.map(({ id }) => id) : undefined)
      }
      autocomplete={{ fetchList }}
    />
  );
};
