import { Button, InputLabel } from '@mui/material';
import React from 'react';
import { TAGS_SECTION_ID_SELECTOR } from '../../../../components/Tags/constants';
import { useScrollToHash } from '../../../../utils/useScrollToHash';
import { CARD_ENHANCEMENT_MAIN_TITLE } from '../../CardEdit/CardForm/constants';
import { useStyles } from './styles';

export const CardTag: React.FC<Record<string, never>> = () => {
  const { scrollTo } = useScrollToHash({ withHash: true });
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <InputLabel shrink>Tags :</InputLabel>
      <Button
        color="primary"
        className={classes.tags}
        onClick={() => {
          scrollTo(TAGS_SECTION_ID_SELECTOR);
        }}
        variant="outlined"
      >
        {CARD_ENHANCEMENT_MAIN_TITLE}
      </Button>
    </div>
  );
};
