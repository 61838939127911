import type { ContentBlock, ContentState } from 'draft-js';
import { EditorState, Modifier, SelectionState } from 'draft-js';
import { Map } from 'immutable';
import React, { FC } from 'react';
import { buildShape } from '../../TableDialog/utils';
import type { TableShapeCol, TableShapeRow } from '../../types';
import { convertEditorStateToRaw } from '../../utils';
import type { TableBlockRendererProps } from '../tableBlock.renderer';
import { useStyles } from './styles';
import { TableCell } from './TableCell';

interface TableViewProps {
  block: ContentBlock;
  blockProps: TableBlockRendererProps;
  contentState: ContentState;
}
const TableView: FC<TableViewProps> = ({ block, blockProps, contentState }) => {
  const classes = useStyles(blockProps);
  const firstBlockKey = contentState.getFirstBlock().getKey();
  const currentBlockKey = block.getKey();
  const isFirstBlock = firstBlockKey === currentBlockKey;

  /* If the current block is the first one :
   * - We generate the full table DOM structure
   * - Each table cell includes an isolate MegadraftEditor with its own editorState.
   * - Each time a changes is made, the "cell" editorState is merged in the current block data
   *   and then merged too in ancestor editorState through the onChange function
   * */
  if (isFirstBlock) {
    const shape = blockProps.shape || buildShape(contentState);
    const [theadList] = shape;
    const tbodyList = shape.filter((_: any, i: number) => !!i);

    const handleChangeTableCellDraft = (
      editorState: EditorState,
      cellKey: string,
    ) => {
      const newSelection = new SelectionState({
        anchorKey: cellKey,
        anchorOffset: 0,
        focusKey: cellKey,
        focusOffset: 0,
      });

      const currentBlockData = contentState
        .getBlockForKey(cellKey)
        .getData()
        .toJS();

      // We merge the current block data with the updated cellContentState
      const newData = Map({
        ...currentBlockData,
        cellContentState: convertEditorStateToRaw(editorState),
      });

      const newContentState = Modifier.mergeBlockData(
        contentState,
        newSelection,
        newData,
      );

      const newTablePluginState = EditorState.push(
        blockProps.tableContentState,
        newContentState,
        'change-block-data',
      );

      // We trigger TableDraft onChange
      blockProps.onChange(newTablePluginState);
    };

    return (
      <table className={classes.table} id={currentBlockKey}>
        <thead className={classes.thead}>
          <tr className={classes.row}>
            {theadList.map(({ key }: TableShapeCol, i: number) => (
              <TableCell
                cellKey={key}
                contentState={contentState}
                element="th"
                key={`cell-${key}-${i}`}
                onChange={handleChangeTableCellDraft}
              />
            ))}
          </tr>
        </thead>
        <tbody className={classes.tbody}>
          {tbodyList.map((row: TableShapeRow, i: number) => (
            <tr className={classes.row} key={`row-${i}`}>
              {row.map(({ key }: TableShapeCol, j: number) => (
                <TableCell
                  cellKey={key}
                  contentState={contentState}
                  element="td"
                  key={`cell-${key}-${j}`}
                  onChange={handleChangeTableCellDraft}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  // We need computing only once
  return null;
};

export { TableView };
