import type { FC } from 'react';
import React from 'react';
import clsx from 'clsx';
import { Box, Skeleton, Stack } from '@mui/material';
import { DataTestIdListPage } from '../../../../../tests/constants';
import { PEOPLE_TAG_HEIGHT } from '../../../styles';
import { useStyles, TEXTFIELD_LABEL_HEIGHT, TEXTFIELD_HEIGHT } from './styles';

export const Skeletons: FC = () => {
  const classes = useStyles();
  return (
    <div
      className={classes.wrapper}
      data-testid={DataTestIdListPage.SKELETONS_WRAPPER}
    >
      <Stack spacing={1.5}>
        <Box>
          <Skeleton
            className={clsx(classes.skeleton, classes.tagsSkeleton)}
            height={TEXTFIELD_HEIGHT}
            width="442px"
          />
          <Skeleton className={classes.skeleton} height={PEOPLE_TAG_HEIGHT} />
        </Box>
        <Box>
          <Skeleton
            className={classes.skeleton}
            height={TEXTFIELD_LABEL_HEIGHT}
            width="15%"
          />
          <Skeleton className={classes.skeleton} height={TEXTFIELD_HEIGHT} />
        </Box>
        <Box>
          <Skeleton
            className={classes.skeleton}
            height={TEXTFIELD_LABEL_HEIGHT}
            width="15%"
          />
          <Skeleton className={classes.skeleton} height={TEXTFIELD_HEIGHT} />
        </Box>
        <Box>
          <Skeleton
            className={classes.skeleton}
            height={TEXTFIELD_LABEL_HEIGHT}
            width="15%"
          />
          <Skeleton className={classes.skeleton} height={TEXTFIELD_HEIGHT} />
        </Box>
        <Box>
          <Skeleton
            className={classes.skeleton}
            height={TEXTFIELD_LABEL_HEIGHT}
            width="15%"
          />
          <Skeleton className={classes.skeleton} height={TEXTFIELD_HEIGHT} />
        </Box>
        <Box>
          <Skeleton
            className={classes.skeleton}
            height={TEXTFIELD_LABEL_HEIGHT}
            width="20%"
          />
          <Skeleton
            className={classes.skeleton}
            height={TEXTFIELD_HEIGHT * 1.25}
          />
        </Box>
      </Stack>
      <Box className={classes.wrapperBottomSkeletons}>
        <Skeleton className={classes.skeleton} height={TEXTFIELD_HEIGHT * 3} />
      </Box>
    </div>
  );
};
