import { useQuery } from '@apollo/client';
import { ErrorBoundary } from '@prismamedia/one-components';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Loader } from '../../../components/Loader';
import { getCard, getCardVariables } from '../../../__generated__/queries-web';
import { CardForm } from './CardForm';
import { GET_CARD } from '../Queries/card.web.graphql';
import { CARD_LOADING } from './constants';

export const CardEdit = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { data, loading, error } = useQuery<getCard, getCardVariables>(
    GET_CARD,
    {
      variables: { where: { id: id || '' } },
    },
  );

  if (loading) {
    return <Loader message={CARD_LOADING} fullscreen={true} />;
  }

  if (error) {
    const ComponentWithError = () => <>{error}</>;

    return (
      <ErrorBoundary
        onBackBtnClick={() => {
          history.goBack();
        }}
      >
        <ComponentWithError />
      </ErrorBoundary>
    );
  }

  // Create new card
  if (!data?.card) {
    return <CardForm />;
  }

  // Update existing card
  return <CardForm card={data.card} />;
};
