import { FC } from 'react';
import { ArchiveKeyType } from '../../../../__generated__/queries-photo';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { PhotoIcon } from '../Icon';
import { Box, useTheme } from '@mui/material';
import { ARCHIVES_LABEL } from './constants';

interface ArchiveIconProps {
  archive: ArchiveKeyType.PECO_WEB | ArchiveKeyType.TRV_WEB_GRAPHIX;
  enabled: boolean;
}
export const ArchiveIcon: FC<ArchiveIconProps> = ({ archive, enabled }) => {
  const theme = useTheme();
  const bgColor = enabled ? '#ffffff' : '#000000';
  const iconColor = enabled ? '#f44336' : '#ffffff';

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',

        '& > svg': {
          height: '16px',
          marginLeft: theme.spacing(0.25),
          marginRight: theme.spacing(-0.25),
        },
      }}
    >
      {ARCHIVES_LABEL[archive]}
      {archive === ArchiveKeyType.TRV_WEB_GRAPHIX ? (
        <LeaderboardIcon
          style={{
            backgroundColor: bgColor,
            color: iconColor,
            borderRadius: '50%',
            width: '16px',
            padding: '2px',
            margin: '0 2px 0 6px',
          }}
        />
      ) : (
        <PhotoIcon fill={bgColor} stroke={iconColor} />
      )}
    </Box>
  );
};
