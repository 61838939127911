import { alpha, darken } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: darken(theme.palette.common.white, 0.1),
    },
  },
  buttonDisabled: {
    backgroundColor: `${alpha(theme.palette.common.white, 0.12)} !important`,
    color: `${alpha(theme.palette.common.white, 0.3)} !important`,
  },
  saveButton: {
    marginRight: theme.spacing(1),
    flexShrink: 0,
  },
}));
