import {
  AutocompleteItem,
  ChipsInput,
  isDefined,
} from '@prismamedia/one-components';
import { FC, useEffect, useState } from 'react';
import { useGlobalBrandKey } from '../../../utils/globalState';
import { SourceOrderByInput } from '../../../__generated__/queries-web';
import {
  useGetSourceCount,
  useSourceGetter,
  useSourcesGetter,
} from '../../ArticleEdit/DrawerContent/getSources.web.graphql';

interface SourcesSelectProps {
  label: string;
  className?: string;
  value?: string[];
  onChange?: (value?: string[]) => void;
}

export const SourcesSelect: FC<SourcesSelectProps> = ({
  label,
  className,
  value,
  onChange,
}) => {
  const [brandKey] = useGlobalBrandKey();
  const [internalValue, setInternalValue] = useState<AutocompleteItem[]>([]);
  const getSources = useSourcesGetter();
  const getSource = useSourceGetter();

  const { data: sourceCountData } = useGetSourceCount(
    { where: { brandKey } },
    !brandKey,
  );

  const fetchList = (search: string) =>
    getSources({
      first: 100,
      where: { title_contains: search, brandKey },
      orderBy: [SourceOrderByInput.title_ASC],
    }).then((data) =>
      data?.sources
        .filter(({ title }) => !!title)
        .map(({ id, title }) => ({ id, label: title })),
    );

  useEffect(() => {
    if (!value) {
      setInternalValue([]);
      return;
    }
    Promise.all(value.map((id) => getSource({ where: { id } }))).then((res) =>
      setInternalValue(
        res
          .map((data) => data.source)
          .filter(isDefined)
          .map((source) => ({
            id: source.id,
            label: source.title,
          })),
      ),
    );
  }, [value, getSource]);

  if (!brandKey || !sourceCountData?.sourceCount) return null;

  return (
    <ChipsInput
      variant="outlined"
      label={label}
      value={internalValue}
      className={className}
      onChange={(val) =>
        onChange?.(val?.length ? val.map(({ id }) => id) : undefined)
      }
      autocomplete={{ fetchList }}
    />
  );
};
