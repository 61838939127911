import { gql } from '@apollo/client';
import {
  getDailymotionUploadURL,
  getDailymotionUploadURLVariables,
} from '../../__generated__/queries-web';
import { useGetter } from '../useGetter';

const GET_DAILYMOTION_UPLOAD_URL = gql`
  query getDailymotionUploadURL($brandKey: BrandKey!, $count: Int!) {
    dailymotionUploadURL(brandKey: $brandKey, count: $count) {
      uploadURL
      progressURL
    }
  }
`;

export const useDailymotionUploadURLGetter = () =>
  useGetter<getDailymotionUploadURL, getDailymotionUploadURLVariables>(
    GET_DAILYMOTION_UPLOAD_URL,
  );
