import React, { FC } from 'react';
import { useStyles } from './styles';
import { assert } from '../../../utils/assert';
import { RawDraft, SlideshowData } from '../../../types/draft';
import { Typography } from '@mui/material';
import { GridTileIcons } from '../GridTileIcons';

interface ImageInfosProps {
  item: {
    title?: string;
    slideshow?: SlideshowData;
    credit?: string;
  };
  index?: number;
}

export const ImageInfos: FC<ImageInfosProps> = ({ item, index }) => {
  const classes = useStyles();

  const hasDescription = !!assert(item.slideshow, ({ description }) =>
    ((description as unknown) as RawDraft).blocks.find(
      (block) => block.text.length > 0,
    ),
  );

  return (
    <div className={classes.infoWrapper}>
      <div className={classes.texts}>
        {index !== undefined && (
          <Typography className={classes.index}>{index + 1}</Typography>
        )}
        <Typography className={classes.title}>{item.title}</Typography>
      </div>
      <GridTileIcons
        className={classes.icons}
        title={!!item.title}
        text={hasDescription}
        copyright={!!item.credit}
      />
    </div>
  );
};
