import { Refresh } from '@mui/icons-material';
import { Fab, Tooltip } from '@mui/material';
import {
  NotificationTypeEnum,
  OneMedia,
  isDailyMotionVideo,
  mediaFromIframely,
  useNotification,
} from '@prismamedia/one-components';
import { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { embedImage } from '../../utils/upload';

export const AdditionnalMediaActions: FC<{
  media: OneMedia;
  setMedia: Dispatch<SetStateAction<OneMedia>>;
  setLoading: (loading: boolean) => void;
}> = ({ media, setMedia, setLoading }) => {
  const { pushNotification } = useNotification();

  const reloadMedia = useCallback(async () => {
    setLoading(true);
    try {
      const iframely = await embedImage(media.iframely.meta.canonical);
      const newMedia = mediaFromIframely(iframely);
      setMedia(newMedia);
    } catch (error) {
      pushNotification({
        message: (error as Error).message,
        type: NotificationTypeEnum.error,
      });
    }
    setLoading(false);
  }, [media.iframely.meta.canonical, pushNotification, setLoading, setMedia]);

  return (
    <>
      {isDailyMotionVideo(media) && (
        <Tooltip title="Actualiser">
          <Fab
            size="small"
            variant="circular"
            color="primary"
            onClick={reloadMedia}
          >
            <Refresh />
          </Fab>
        </Tooltip>
      )}
    </>
  );
};
