import {
  Code,
  Description,
  Restaurant,
  Upload,
  Videocam,
} from '@mui/icons-material';
import { BrandKey } from '@prismamedia/one-brandkey';
import {
  EmbedDialog,
  MEDIA_TYPES_LABEL,
  OpenDialog,
} from '@prismamedia/one-components';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { editorStateToJSON } from 'megadraft';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import {
  InternalLink,
  LinkType,
} from '../../../components/InternalLinkModal/InternalLink';
import { embedImage, extractImgSrc } from '../../../utils/upload';
import { SlideshowEditAction, actions } from '../reducer';

interface CallbackOptions {
  dispatch: (value: SlideshowEditAction) => void;
  inputRef: MutableRefObject<HTMLInputElement | null>;
  setInternalLinkModalIsOpen: (internalLinkModalIsOpen: boolean) => void;
  setMultimediaInitialType: Dispatch<SetStateAction<MEDIA_TYPES_LABEL>>;
  dialog: {
    openDialog: OpenDialog;
    closeDialog: () => void;
  };
}

export const getUploadActions = (brandKey: BrandKey | null) => [
  {
    icon: <Upload />,
    name: 'Importer',
    onClick: ({ inputRef }: CallbackOptions) => {
      if (inputRef.current) {
        inputRef.current.click();
      }
    },
  },
  ...(brandKey === BrandKey.FAC || brandKey === BrandKey.CAC
    ? [
        {
          icon: <Restaurant />,
          name: 'Recette',
          onClick: ({
            setInternalLinkModalIsOpen,
            setMultimediaInitialType,
          }: CallbackOptions) => {
            setMultimediaInitialType(MEDIA_TYPES_LABEL.RECIPES);
            setInternalLinkModalIsOpen(true);
          },
        },
      ]
    : []),
  {
    icon: <Videocam />,
    name: 'Vidéo',
    onClick: ({
      setInternalLinkModalIsOpen,
      setMultimediaInitialType,
    }: CallbackOptions) => {
      setMultimediaInitialType(MEDIA_TYPES_LABEL.VIDEOS);
      setInternalLinkModalIsOpen(true);
    },
  },
  {
    icon: <Description />,
    name: 'Article',
    onClick: ({
      setInternalLinkModalIsOpen,
      setMultimediaInitialType,
    }: CallbackOptions) => {
      setMultimediaInitialType(MEDIA_TYPES_LABEL.ARTICLES);
      setInternalLinkModalIsOpen(true);
    },
  },
  {
    icon: <Code />,
    name: 'Lien',
    onClick: ({ dialog, dispatch }: CallbackOptions) => {
      dialog.openDialog(
        <EmbedDialog
          fetcher={embedImage}
          onMediaLoaded={({ iframely }) => {
            dispatch(
              actions.addSlide({
                iframely: {
                  ...iframely,
                  meta: {
                    ...iframely.meta,
                    url: extractImgSrc(iframely),
                  },
                },
              }),
            );
            dialog.closeDialog();
          }}
        />,
        { fullWidth: true },
      );
    },
  },
];

export const buildDescription = (link: InternalLink, linkType: LinkType) => {
  try {
    return JSON.parse(
      editorStateToJSON(
        EditorState.createWithContent(
          stateFromHTML(
            `${linkType.buttonLabel}: <a href="${link.url}">${link.title}</a>`,
          ),
        ),
      ),
    );
  } catch {
    return null;
  }
};
