import {
  CloudOff,
  MobileScreenShare,
  MoreVert,
  RemoveRedEye,
} from '@mui/icons-material';
import { IconButton, ListItemIcon, MenuItem, Typography } from '@mui/material';
import {
  NotificationTypeEnum,
  useMenu,
  useNotification,
} from '@prismamedia/one-components';
import React, { FC, MouseEvent, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useArticleUpdater } from '../../apollo/mutations/article.web.graphql';
import { ArticleStatus } from '../../__generated__/queries-web';
import {
  ERROR_MODIFY_STATUS,
  SEE_PUBLIC_SITE,
  SEND_PUSH,
  STATUS_TO_DRAFT,
  UNPUBLISH,
} from './constants';
import { useStyles } from './styles';

interface ArticleMenuProps {
  articleId: string;
  publicUrl: string;
  brandKey: string;
  displayUnPublish: boolean;
  displaySeeArticle: boolean;
  displayPushNotification: boolean;
}

export const ArticleMenu: FC<ArticleMenuProps> = ({
  articleId,
  brandKey,
  displayUnPublish,
  displaySeeArticle,
  displayPushNotification,
  publicUrl,
}) => {
  const { openMenu, closeMenu } = useMenu();
  const [upsert] = useArticleUpdater();
  const { pushNotification } = useNotification();
  const history = useHistory();

  const classes = useStyles();

  const handleMutation = useCallback(
    async (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      closeMenu();

      const props = {
        variables: {
          where: { id: articleId },
          data: { status: ArticleStatus.Draft },
        },
      };
      const result = await upsert(props).catch(() => {
        pushNotification({
          message: ERROR_MODIFY_STATUS,
          type: NotificationTypeEnum.error,
        });
      });
      if (result && result.data && result.data.updateArticle) {
        pushNotification({
          message: STATUS_TO_DRAFT,
          type: NotificationTypeEnum.success,
        });
      }
    },
    [articleId, closeMenu, pushNotification, upsert],
  );

  const viewFront = () => {
    closeMenu();
    window.open(publicUrl);
  };

  const handleMobilePush = () => {
    closeMenu();
    history.push(`/${brandKey}/mobilepush/add?id=${articleId}`);
  };

  const UnPublishItem = () => {
    return (
      <MenuItem
        classes={{ root: classes.actionsMenu }}
        data-testid="unPublish"
        onClick={handleMutation}
      >
        <ListItemIcon>
          <CloudOff fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">{UNPUBLISH}</Typography>
      </MenuItem>
    );
  };

  const DisplayItem = () => {
    return (
      <MenuItem
        classes={{ root: classes.actionsMenu }}
        data-testid="seeArticle"
        onClick={viewFront}
      >
        <ListItemIcon>
          <RemoveRedEye fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">{SEE_PUBLIC_SITE}</Typography>
      </MenuItem>
    );
  };

  const SendPushItem = () => {
    return (
      <MenuItem
        classes={{ root: classes.actionsMenu }}
        data-testid="pushButton"
        onClick={handleMobilePush}
      >
        <ListItemIcon>
          <MobileScreenShare fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">{SEND_PUSH}</Typography>
      </MenuItem>
    );
  };

  return (
    <IconButton
      arial-label="more"
      data-testid="menuBtn"
      onClick={(event) => {
        event.preventDefault();
        openMenu(
          event.currentTarget,
          <div>
            {displayUnPublish && <UnPublishItem />}
            {displaySeeArticle && <DisplayItem />}
            {displayPushNotification && <SendPushItem />}
          </div>,
        );
      }}
    >
      <MoreVert />
    </IconButton>
  );
};
