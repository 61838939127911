import { AutocompleteItem } from '@prismamedia/one-components';
import {
  BrandKey,
  GetArticle_article_articleTags_tag,
  TagProviderName,
  TagType,
} from '../../../../__generated__/queries-web';
import { brandsOptions } from '../../../../utils/brands';
import { isArticleVideo } from '../../../../utils/url';

export const isBrandkeyCanHavePersonTag = (
  tagProviderName: TagProviderName,
  brandKey: BrandKey,
) =>
  tagProviderName === TagProviderName.Person
    ? brandsOptions[brandKey].personTagsAllowed
    : true;

export const isTagAlreadyCreated = (
  selectedTagList: GetArticle_article_articleTags_tag[],
  search?: string,
) =>
  !!(
    selectedTagList.length &&
    selectedTagList.find(
      ({ title }) =>
        title.toLowerCase().trim() === search?.toLowerCase().trim(),
    )
  );

export const isTagAlreadyListed = (
  autocompleteList: AutocompleteItem[],
  search?: string,
) =>
  !!(
    autocompleteList.length &&
    autocompleteList.find(
      ({ additionnals }) =>
        additionnals?.title.toLowerCase().trim() ===
        search?.toLowerCase().trim(),
    )
  );

export const getTagTypeFromPathname = (pathname: string): TagType =>
  isArticleVideo(pathname) ? TagType.VideoTag : TagType.Tag;
