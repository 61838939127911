/* eslint-disable  */
import React, { FC, useEffect, useRef, useState } from 'react';
import { insertDataBlock } from 'megadraft';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { Close } from '@mui/icons-material';
import {
  PluginButtonProps,
  IconLabelButton,
  useNotification,
  NotificationTypeEnum,
} from '@prismamedia/one-components';
import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { BrandKey, ArticleFormat } from '../../../../__generated__/queries-web';
import { ArticleEditAction, actions } from '../../reducer';
import { useGetArticle } from '../../../../apollo/queries/articles.web.graphql';
import { useQualifiers } from '../../DrawerContent/getQualifers.web.graphql';
import { useParams } from 'react-router-dom';
import { auth } from '../../../../utils/auth';

interface AffiliationPluginProps {
  brandKey: BrandKey;
  dispach: React.Dispatch<ArticleEditAction>;
  format: ArticleFormat;
}

export const AffiliateMediaButton = ({
  brandKey,
  dispach,
  format,
}: AffiliationPluginProps): FC<PluginButtonProps> => ({
  editorState,
  onChange,
}) => {
  const { pushNotification } = useNotification();

  // La props articleId que l'on reçoit dans ce composant
  // est auto-générée pour un nouvel article, ce qui peut fausser le cache
  // pour cette query, on s'assure donc que l'article existe en récupérant l'id dans l'url
  const { id } = useParams();
  const { data: dataArticle } = useGetArticle(id);

  const { data: qualifiers } = useQualifiers({
    first: 100,
    where: {
      format,
      brandKey,
    },
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [iframeDivLoaded, setIframeDivLoaded] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setIframeDivLoaded(false);
  };

  useEffect(() => {
    if (isOpen && !iframeDivLoaded) {
      setIframeDivLoaded(true);
    }
  }, [isOpen, iframeDivLoaded]);

  useEffect(() => {
    if (iframeDivLoaded && dataArticle?.article) {
      const iframe = document.createElement('iframe');
      const diviframe = document.getElementById('diviframe');

      iframe.width = '800px';
      iframe.height = '650px';
      iframe.src = config.IFRAME_CERISE_AFFILIATION_URL;

      iframe.onload = async () => {
        iframe.contentWindow?.postMessage(
          {
            uuid: dataArticle?.article?.id,
            brand: dataArticle?.article?.brandKey,
            jwt: await auth.getJwt(),
          },
          '*',
        );
      };

      iframe.onerror = (e) => {
        pushNotification({
          type: NotificationTypeEnum.error,
          message: "Le contenu de l'iframe n'a pas pu être chargé",
        });
      };

      const handleMessage = (event: MessageEvent<any>) => {
        const { status, datas, message } = event.data;

        if (status === false) {
          return message === 'Action canceled'
            ? handleClose()
            : pushNotification({
                type: NotificationTypeEnum.error,
                message:
                  "Authentication error - Le contenu de l'iframe n'a pas pu être chargé",
              });
        }

        if (
          status &&
          datas.html &&
          datas.author &&
          datas.template &&
          datas.type &&
          datas.url
        ) {
          onChange(
            insertDataBlock(editorState, {
              type: 'media_affiliate',
              iframely: {
                meta: {
                  author: datas.author,
                  site: 'CeriseAffiliation',
                  template: datas.template,
                  type: datas.type,
                  url: datas.url,
                },
                html: datas.html,
              },
            }),
          );
          if (qualifiers?.qualifiers) {
            const affiliationQualifier = qualifiers.qualifiers.find(
              (qualifier) => {
                return qualifier.title === 'Affiliation';
              },
            );

            if (
              affiliationQualifier &&
              !dataArticle?.article?.articleQualifiers.find(
                (qualifier) => qualifier.qualifier.title === 'Affiliation',
              )
            ) {
              dispach(
                actions.updateField({
                  field: 'articleQualifiers',
                  value: [
                    ...(dataArticle?.article?.articleQualifiers || []),
                    {
                      qualifier: {
                        id: affiliationQualifier.id,
                        title: affiliationQualifier.title,
                      },
                    },
                  ],
                }),
              );
            }
          }
          return handleClose();
        }
      };

      diviframe?.appendChild(iframe);
      if (window) {
        window.addEventListener('message', handleMessage);
      }
      return () => {
        if (window) {
          window.removeEventListener('message', handleMessage);
        }
      };
    }
  }, [iframeDivLoaded, dataArticle?.article]);

  return (
    <>
      <Dialog
        open={isOpen}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '800px',
            },
          },
        }}
      >
        <AppBar position="relative" color="inherit">
          <Toolbar>
            <Typography
              variant="h6"
              color="inherit"
              sx={{ marginLeft: '2', flex: 1 }}
            >
              Ajouter un bloc affiliation
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="Close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div id="diviframe"></div>
      </Dialog>

      <IconLabelButton
        data-testid="megadraft-media-button-plugin"
        label={'Affiliation'}
        onClick={() => setIsOpen(true)}
      >
        <AddBusinessIcon />
      </IconLabelButton>
    </>
  );
};
