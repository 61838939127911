export const assert = <T, R>(
  object: T,
  pathFunction: (object: NonNullable<T>) => R,
): R | undefined => {
  try {
    return pathFunction(object as NonNullable<T>);
  } catch {
    return undefined;
  }
};
