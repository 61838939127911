import { gql, useQuery } from '@apollo/client';
import { ORPHANPAGE_FRAGMENT } from './orphanPageFragments.web.graphql';
import {
  getOrphanPage,
  getOrphanPageVariables,
} from '../../../__generated__/queries-web';

const GET_ORPHANPAGE_QUERY = gql`
  query getOrphanPage($id: ID) {
    orphanPage(where: { id: $id }) {
      ...OrphanPageFragment
    }
  }
  ${ORPHANPAGE_FRAGMENT}
`;

export const useGetOrphanById = (id: string) =>
  useQuery<getOrphanPage, getOrphanPageVariables>(GET_ORPHANPAGE_QUERY, {
    variables: {
      id,
    },
  });
