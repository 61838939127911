import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    '& .cellBlockQuote': {
      borderLeftColor: theme.palette.grey['400'],
      color: theme.palette.grey['600'],
      marginTop: 0,
    },
    '& .cellListItem': {
      marginLeft: `-${theme.spacing(2)}`,
      lineHeight: 1.5,

      '& > li': {
        marginLeft: 0,
      },

      '&:first-child': {
        marginTop: `-${theme.spacing(2)}`,
      },
    },
  },
}));
