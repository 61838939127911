import { gql, useApolloClient, useQuery } from '@apollo/client';
import { GetUsers, GetUsersVariables } from '../../__generated__/queries-auth';
import { useFetch } from '../../utils/useFetch';

export const GET_USERS = gql`
  query GetUsers(
    $first: Int = 1000
    $skip: Int = 0
    $where: UserWhereInput
    $orderBy: [UserOrderByInput!]
  ) {
    users(first: $first, skip: $skip, where: $where, orderBy: $orderBy) {
      id
      name
      createdAt
      email
      roles
      googleId
      departments(first: 100) {
        department {
          name
        }
      }
      userRoles(first: 100) {
        role {
          slug
        }
        brand {
          key
        }
      }
    }
    userCount(where: $where)
  }
`;

export const useGetUsers = (variables: GetUsersVariables, skip?: boolean) =>
  useQuery<GetUsers, GetUsersVariables>(GET_USERS, {
    variables,
    skip,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

export const useGetUsersPromise = () =>
  useFetch<GetUsers, GetUsersVariables>(GET_USERS);

export const useUsersGetter = () => {
  const apolloClient = useApolloClient();

  return async (name_contains: string) => {
    const { data, errors } = await apolloClient.query<
      GetUsers,
      GetUsersVariables
    >({
      query: GET_USERS,
      variables: { where: { name_contains } },
    });

    if (errors?.length) {
      throw errors[0];
    }
    return data?.users;
  };
};
