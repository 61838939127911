import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '1rem',
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    table: {
      width: '100%',
    },
    deleteIcon: {
      cursor: 'pointer',
    },
  }),
);
