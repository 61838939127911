import { Box, InputLabel } from '@mui/material';
import React from 'react';
import {
  BrandKey,
  GetArticle_article_source,
} from '../../../../__generated__/queries-web';
import { useGetSources } from '../../DrawerContent/getSources.web.graphql';
import { actions, ArticleEditAction } from '../../reducer';
import { Suggest } from '../../../../components/Suggest';
import { SOURCE_LABEL } from '../constants';

interface SourceInputsProps {
  brandKey: BrandKey;
  source: GetArticle_article_source | null;
  dispatch: React.Dispatch<ArticleEditAction>;
  multiple?: boolean;
}

export const SourceInput: React.FC<SourceInputsProps> = ({
  brandKey,
  source,
  dispatch,
}) => {
  const { data } = useGetSources(brandKey);

  return (
    <Box sx={{ width: '100%' }}>
      <InputLabel shrink>Source</InputLabel>
      <Suggest
        selectedItems={source ? [source] : []}
        placeholder={SOURCE_LABEL}
        suggestions={data?.sources || []}
        multiple={false}
        maxNumberSuggest={2}
        isPrefetched
        onChange={(items) => {
          dispatch(
            actions.updateField({
              field: 'source',
              value: items[0]
                ? { id: items[0].id, title: items[0].title }
                : null,
            }),
          );
        }}
      />
    </Box>
  );
};
