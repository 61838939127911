import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  tagsDialogContent: {
    padding: 0,

    '&:first-child': {
      padding: 0,
    },
  },
  tagsDialogFooter: {
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: '#3f51b5',
    bottom: 0,
    display: 'flex',
    left: 0,
    padding: theme.spacing(2),
    position: 'sticky',
    right: 0,
    textAlign: 'center',
    width: '100%',
  },
  tagsDialogCaption: {
    color: 'white',
    marginRight: theme.spacing(1.5),
    textAlign: 'right',
    width: '50%',
  },
  validateButton: {
    backgroundColor: 'white',
    color: '#3f51b5',
  },
}));
