import { gql } from '@apollo/client';
import { useCreate, useUpdate } from '../../../utils/useUpdateCreate';
import {
  CreateSegment,
  SegmentCreateInput,
  SegmentUpdateInput,
  UpdateSegment,
} from '../../../__generated__/queries-web';
import { GET_SEGMENTS } from '../segmentList/getSegments.web.graphql';
import { GET_SEGMENT } from './getSegment.web.graphql';

const UPDATE_SEGMENT = gql`
  mutation UpdateSegment(
    $where: SegmentWhereUniqueInput!
    $data: SegmentUpdateInput!
  ) {
    updateSegment(where: $where, data: $data) {
      id
    }
  }
`;

export const useUpdateSegment = (message?: string) =>
  useUpdate<UpdateSegment, SegmentUpdateInput>(
    UPDATE_SEGMENT,
    [GET_SEGMENT, GET_SEGMENTS],
    message,
  );

const CREATE_SEGMENT = gql`
  mutation CreateSegment($data: SegmentCreateInput!) {
    createSegment(data: $data) {
      id
    }
  }
`;

export const useCreateSegment = () =>
  useCreate<CreateSegment, SegmentCreateInput>(CREATE_SEGMENT, [GET_SEGMENTS]);
