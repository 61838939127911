import { FetchResult } from '@apollo/client';
import { AutocompleteItem, FieldValidator } from '@prismamedia/one-components';
import { Dispatch, SetStateAction } from 'react';
import { replaceParams } from '../../../routing/Router';
import { paths } from '../../../routing/Router/paths';
import {
  CreateUser,
  GetUser_user,
  GetUser_user_userRoles,
  UpdateUser,
  UserCreateInput,
  UserUpdateInput,
} from '../../../__generated__/queries-auth';
import uuidv4 from 'uuid/v4';

const NEW_USERROLE_ID = 'NEW_USERROLE_ID';

export const emptyItem: GetUser_user = {
  __typename: 'User',
  id: '',
  name: null,
  createdAt: '',
  email: null,
  avatarUrl: null,
  departments: [],
  userRoles: [],
};

const getEmptyRole = (): GetUser_user_userRoles => ({
  __typename: 'UserRole',
  id: `${NEW_USERROLE_ID}-${uuidv4()}`,
  brand: null,
  role: { __typename: 'Role', id: '', slug: '' },
});

export const saveMandatoryFields: FieldValidator<GetUser_user>[] = [
  {
    label: 'Nom',
    validate: ({ name }) => !!name,
  },
  {
    label: 'Email',
    validate: ({ email }) => !!email,
  },
  {
    label: "Rôle de l'utilisateur",
    validate: ({ userRoles }) =>
      userRoles
        .filter((userRole) => !(userRole as any).toDelete)
        .every(({ role }) => !!role?.id),
  },
];

export const onDepartmentsChange = (
  value: AutocompleteItem[],
  setItem: Dispatch<SetStateAction<GetUser_user | undefined>>,
) => {
  setItem((prev) =>
    prev
      ? {
          ...prev,
          departments: [
            // flag UserDepartments that need deletion
            ...prev.departments.map((dep) =>
              value.map((val) => val.id).includes(dep.department.id)
                ? dep
                : { ...dep, toDelete: true },
            ),
            // add new UserDepartments and flag them for creation
            ...value
              .filter(
                (val) =>
                  !prev.departments
                    .map((userDep) => userDep.department.id)
                    .includes(val.id),
              )
              .map(({ additionnals }) => ({
                __typename: 'UserDepartment' as const,
                department: additionnals?.department,
                toCreate: true,
              })),
            // filter out UserDepartments which are both toCreate && toDelete
            // happens when you add and delete a department without saving
          ].filter((dep) => !((dep as any).toCreate && (dep as any).toDelete)),
        }
      : prev,
  );
};

export const onUserRoleEdition = (
  value: Record<string, any>,
  userRole: GetUser_user_userRoles,
  setItem: Dispatch<SetStateAction<GetUser_user | undefined>>,
) => {
  setItem((prev) =>
    prev
      ? {
          ...prev,
          userRoles: prev.userRoles.map((role) =>
            role.id === userRole.id
              ? {
                  ...role,
                  ...value,
                }
              : role,
          ),
        }
      : prev,
  );
};

export const onUserRoleDeletion = (
  userRole: GetUser_user_userRoles,
  setItem: Dispatch<SetStateAction<GetUser_user | undefined>>,
) => {
  if (userRole.id.startsWith(NEW_USERROLE_ID)) {
    setItem((prev) =>
      prev
        ? {
            ...prev,
            userRoles: prev.userRoles.filter((role) => role.id !== userRole.id),
          }
        : prev,
    );
  } else {
    setItem((prev) =>
      prev
        ? {
            ...prev,
            userRoles: prev.userRoles.map((role) =>
              role.id === userRole.id
                ? {
                    ...role,
                    toDelete: true,
                  }
                : role,
            ),
          }
        : prev,
    );
  }
};

export const onUserRoleAddition = (
  setItem: Dispatch<SetStateAction<GetUser_user | undefined>>,
) => {
  setItem((prev) =>
    prev
      ? {
          ...prev,
          userRoles: [...prev.userRoles, getEmptyRole()],
        }
      : prev,
  );
};

export const onSave = async (
  item: GetUser_user | undefined,
  isCreate: boolean,
  history: any,
  setSaveLoading: (loading: boolean) => void,
  createItem: (
    data: UserCreateInput,
  ) => Promise<
    FetchResult<CreateUser, Record<string, any>, Record<string, any>>
  >,
  updateItem: (
    id: string,
    data: UserUpdateInput,
  ) => Promise<
    FetchResult<UpdateUser, Record<string, any>, Record<string, any>>
  >,
) => {
  if (item) {
    setSaveLoading(true);
    const res = isCreate
      ? await createItem({
          name: item.name,
          email: item.email,
          type: '',
          departments: {
            create: item.departments.map(({ department }) => ({
              department: { connect: { id: department.id } },
            })),
          },
          userRoles: {
            create: item.userRoles.map(({ role, brand }) => ({
              role: { connect: { id: role.id } },
              brand: brand && { connect: { id: brand.id } },
            })),
          },
        })
      : await updateItem(item.id, {
          name: item.name,
          email: item.email,
          departments: {
            create: item.departments
              .filter((dep) => (dep as any).toCreate)
              .map(({ department }) => ({
                department: { connect: { id: department.id } },
              })),
            delete: item.departments
              .filter((dep) => (dep as any).toDelete)
              .map(({ department }) => ({
                department: { id: department.id },
              })),
          },
          userRoles: {
            create: item.userRoles
              .filter(({ id }) => id.startsWith(NEW_USERROLE_ID))
              .map(({ role, brand }) => ({
                role: { connect: { id: role.id } },
                brand: brand && { connect: { id: brand.id } },
              })),
            delete: item.userRoles
              .filter((userRole) => (userRole as any).toDelete)
              .map(({ id }) => ({ id })),
            update: item.userRoles
              .filter(
                (userRole) =>
                  !userRole.id.startsWith(NEW_USERROLE_ID) &&
                  !(userRole as any).toDelete,
              )
              .map(({ id, brand, role }) => ({
                where: { id },
                data: {
                  role: { connect: { id: role.id } },
                  brand: brand
                    ? { connect: { id: brand.id } }
                    : { disconnect: true },
                },
              })),
          },
        });
    setSaveLoading(false);
    if (res && isCreate) {
      history.replace(
        replaceParams(paths.USER_EDIT, {
          id: (res.data as CreateUser).createUser.id,
        }),
      );
    }
  }
};
