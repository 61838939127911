import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

interface OnePhotoStyledProps {
  isOpen?: boolean;
}
export const useStyles = makeStyles<Theme, OnePhotoStyledProps>((theme) => ({
  multimediaSearch: {
    width: '100%',
  },
  topInnerWrapper: () => ({
    overflow: 'hidden',
    backgroundColor: theme.palette.grey[100],
  }),
  headerText: {
    display: 'block',
    left: 0,
    position: 'relative',
    paddingLeft: theme.spacing(3),
    zIndex: 2,
    marginTop: '20px',
    marginBottom: '20px',
  },
  providerBadge: {
    alignItems: 'center',
    display: 'flex',

    '& > svg': {
      height: '16px',
      marginLeft: theme.spacing(0.25),
      marginRight: theme.spacing(-0.25),
    },
  },
  wrapperProvidersFilter: {
    display: 'block',
    left: 0,
    position: 'relative',
    paddingLeft: theme.spacing(3),
    zIndex: 2,

    '& > div': {
      marginRight: theme.spacing(1),

      '& > span': {
        marginRight: theme.spacing(-0.5),
      },
    },
  },
  wrapperList: {
    height: '100%',
    '&::after': {
      bottom: '0',
      boxShadow: 'inset 0px -15px 15px -11px rgb(0 0 0 / 5%)',
      content: '""',
      display: 'block',
      height: '50px',
      left: '0',
      position: 'absolute',
      pointerEvent: 'none',
      right: '0',
    },
  },
}));
