import makeStyles from '@mui/styles/makeStyles';

export const TEXTFIELD_LABEL_HEIGHT = 14;
export const TEXTFIELD_HEIGHT = 35;

export const useStyles = makeStyles((theme) => ({
  skeleton: {
    marginTop: theme.spacing(1),
    opacity: 0.5,
    transform: 'scale(1)',
  },
  tagsSkeleton: {
    marginLeft: `calc(100% - 442px)`,
    marginBottom: theme.spacing(2),
  },
  wrapper: {
    marginTop: theme.spacing(3.25),

    '& > div:last-child': {
      marginTop: theme.spacing(1.5),
    },
  },
  wrapperBottomSkeletons: {
    marginTop: `${theme.spacing(7)} !important`,
  },
}));
