import { BrandKey } from '@prismamedia/one-brandkey';
import {
  DraftPluginC2c,
  FramePlugin,
  FrameType,
  ImagePlugin,
  mediaFromIframely,
  MediaPlugin,
  PastePlugin,
} from '@prismamedia/one-components';
import { searchImageSourceCategories } from '../../../apollo/sharedQueries/getSourcesCategories.web.graphql';
import { MultimediaPlugin } from '../../../components/Draft/plugins/multimedia-plugin';
import { CERISE_ENDPOINT } from '../../../utils/cerise';
import { embedImage, uploadAndEmbedImage } from '../../../utils/upload';

export enum PluginType {
  Image = 'Image',
  Media = 'Media',
  C2C = 'C2C',
  Frame = 'Frame',
  Multimedia = 'Multimedia',
  PageBreak = 'PageBreak',
  Table = 'Table',
}

export const imagePlugin = (brandKey: BrandKey) =>
  ImagePlugin({
    uploadImage: async (file: File) => {
      const iframely = await uploadAndEmbedImage(file, brandKey, {
        withFileName: true,
      });
      return mediaFromIframely(iframely);
    },
    fetchSources: searchImageSourceCategories,
  });

export const multimediaPlugin = () =>
  MultimediaPlugin({
    fetchSources: searchImageSourceCategories,
  });

const forbiddenURLMediaList = [CERISE_ENDPOINT];
export const mediaPlugin = MediaPlugin({
  handleEmbed: embedImage,
  forbiddenURLMediaList,
});

export const c2cPlugin = () => {
  return DraftPluginC2c({
    initialForm: {
      renderingOptions: [
        {
          id: 'lien-c2c',
          value: 'lien-c2c',
          label: 'Lien C2C',
        },
        {
          id: 'lien-interne',
          value: 'lien-interne',
          label: 'Lien interne',
        },
      ],
      rendering: 'lien-c2c',
      buttonLabel: '',
      tagId: '',
      url: '',
      noFollow: false,
      newTab: false,
      sponsored: true,
    },
  });
};

export const framePlugin = ({
  brandKey,
  frameOptions = [
    FrameType.NOTICE,
    FrameType.DEFAULT,
    FrameType.DEONTOLOGY,
    FrameType.SUMMARY,
    FrameType.SOURCE,
    FrameType.REBOUND_TEXT,
    FrameType.BULLET_POINT,
  ],
  defaultOption = FrameType.DEFAULT,
}: {
  brandKey: BrandKey;
  frameOptions?: FrameType[];
  defaultOption?: FrameType;
}) => {
  return FramePlugin({
    frameOptions,
    defaultOption,
    plugins: [
      mediaPlugin,
      imagePlugin(brandKey),
      PastePlugin(),
      multimediaPlugin(),
    ],
  });
};
