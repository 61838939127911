import makeStyles from '@mui/styles/makeStyles';

const appBarHeight = 64;

export const useStyles = makeStyles((theme) => ({
  backIcon: {
    color: theme.palette.common.white,
    marginRight: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(4),
  },
  listWrapper: {
    height: `calc(100vh - ${appBarHeight}px)`,
    marginTop: appBarHeight,
  },
  newButton: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
}));
