import React from 'react';
import { AdvicePopper } from '../../ArticleEdit/ArticleEditor/AdvicePopper';

export const MobilePushTitlePopper = (
  <AdvicePopper
    accordions={[
      {
        details: `Pour optimiser l’affichage des titres sur mobile, ceux-ci doivent faire 40 caractères maximum (espaces inclus).`,
        summary: 'Titre inférieur à 40 signes',
      },
    ]}
    title="Conseil de saisie"
  />
);

export const MobilePushMessagePopper = (
  <AdvicePopper
    accordions={[
      {
        details: `Pour optimiser l’affichage des messages sur mobile, ceux-ci doivent faire 50 caractères maximum (espaces inclus).`,
        summary: 'Message inférieur à 50 signes',
      },
    ]}
    title="Conseil de saisie"
  />
);
