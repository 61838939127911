import { Box } from '@mui/material';
import { SearchBarVariant } from '@prismamedia/one-components';
import type { FC } from 'react';
import React, { memo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Default as DefaultTags } from '../../../../../../../components/Tags/TagItems/Default';
import {
  TabType,
  TagProviderName,
  TagType,
} from '../../../../../../../__generated__/queries-web';
import {
  PEOPLE_TAG_HEIGHT,
  PEOPLE_TAG_WIDTH,
  useTagsStyles,
} from '../../../styles';
import type { CategoryFormSectionPeopleContent, SectionForm } from '../types';
import {
  CATEGORY_FORM_ADD_PEOPLE_SEARCHBAR_PLACEHOLDER,
  CATEGORY_FORM_ADD_PEOPLE_TAGS_MAX_QUANTITY,
  CATEGORY_FORM_ADD_PEOPLE_TEXT,
} from './constants';
import { useStyles } from './styles';
import { useEditPeople } from './useEditPeople';

export type PeopleProps = SectionForm<
  CategoryFormSectionPeopleContent[],
  TabType.People
>;

export const People: FC<PeopleProps> = memo((sectionProps) => {
  const { brandKey } = useParams();
  const classes = useStyles();

  const {
    handlers: peopleHandlers,
    content: peopleContent,
    isLoading: isContentLoading,
  } = useEditPeople(sectionProps);

  const tagsClasses = useTagsStyles({ hasTagsLength: !!peopleContent.length });

  const handleTagsChange = useCallback(
    (newTags) => {
      peopleHandlers.handleContentChange(newTags);
    },
    [peopleHandlers],
  );

  return (
    <Box className={classes.wrapper}>
      <DefaultTags
        allowedTagProviders={[TagProviderName.Person]}
        allowedTagTypes={[TagType.Person]}
        brandKey={brandKey}
        className={tagsClasses.wrapperTags}
        controls={{ activation: true }}
        maxQuantity={CATEGORY_FORM_ADD_PEOPLE_TAGS_MAX_QUANTITY}
        height={PEOPLE_TAG_HEIGHT}
        isAllowedToCreateTag={false}
        isLoading={isContentLoading}
        onTagsChange={handleTagsChange}
        SearchBarProps={{
          className: tagsClasses.searchBar,
          placeholder: CATEGORY_FORM_ADD_PEOPLE_SEARCHBAR_PLACEHOLDER,
          variant: SearchBarVariant.TERTIARY,
        }}
        SkeletonsProps={{
          direction: 'row',
          quantity: 2,
          justifyContent: 'space-between',
          width: PEOPLE_TAG_WIDTH,
        }}
        tags={peopleContent}
        title={CATEGORY_FORM_ADD_PEOPLE_TEXT}
        width={PEOPLE_TAG_WIDTH}
      />
    </Box>
  );
});
