import { useApolloClient } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { useCallback } from 'react';

export const useFetch = <T, Var>(query: DocumentNode) => {
  const client = useApolloClient();

  return useCallback(
    (variables: Var) =>
      client
        .query<T, Var>({ query, variables })
        .then(({ data, errors }) => {
          if (errors?.length) {
            throw errors[0];
          }
          return data;
        }),
    [client, query],
  );
};
