import { ItemSerialized } from '@prismamedia/one-components';

export interface FotoItemSerialized extends ItemSerialized {
  from?: PhotoCollapsesTypes;
}

export enum PhotoCollapsesTypes {
  ONE_PHOTOS = 'ONE-PHOTOS',
  ONE_STORY = 'ONE-STORY',
  PROVIDERS = "BANQUES D'IMAGES EXTERNES",
}
