import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  wrapper: {
    height: '100vh',
    overflow: 'hidden',
  },
  appBar: {
    marginTop: '120px',
  },
  container: {
    width: '80%',
    margin: 'auto',
  },
  orphanContent: {
    width: ' 100%',
  },
  tableWrapper: {
    height: 'calc(80vh - 32px)',
  },
  smallTableWrapper: {
    height: '50vh',
  },
  iconMargin: {
    padding: '4px 16px',
  },
});
