import { gql, useQuery } from '@apollo/client';
import {
  BrandKey,
  GetSearchPeopleBio,
  GetSearchPeopleBioVariables,
} from '../../__generated__/queries-people';

export const GET_PEOPLE_BIO_QUERY = gql`
  query GetSearchPeopleBio(
    $where: PersonFilterInput!
    $whereBiographies: PersonBiographyFilterInput
    $first: UnsignedInt!
    $firstBiographies: UnsignedInt!
  ) {
    people(where: $where, first: $first) {
      biographies(where: $whereBiographies, first: $firstBiographies) {
        media
      }
    }
  }
`;

export const useSearchPeopleBio = (
  relationId: string | null | undefined,
  brandKey: BrandKey | null | undefined,
  first = 1,
  firstBiographies = 1,
) =>
  useQuery<GetSearchPeopleBio, GetSearchPeopleBioVariables>(
    GET_PEOPLE_BIO_QUERY,
    {
      variables: {
        where: { id: relationId },
        whereBiographies: { brandKey, media_is_null: false },
        first,
        firstBiographies,
      },
      skip: !relationId,
    },
  );
