import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.grey[600],
  },
  smallIcon: {
    color: theme.palette.grey[600],
    fontSize: 'medium',
  },
  wrapper: {
    display: 'flex',
    marginTop: '-36px',
  },
  helper: {
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.grey[200],
    borderBottomLeftRadius: '3px',
    borderBottomRightRadius: '3px',
  },
}));
