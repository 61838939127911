import { SvgIcon } from '@mui/material';

interface PhotoIconProps {
  fill: string;
  stroke?: string;
}

export const PhotoIcon = ({ fill, stroke = '#ffffff' }: PhotoIconProps) => (
  <SvgIcon width="32px" height="32px" viewBox="0 0 32 32">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="FavIcon_One_Amber">
        <g id="Oval">
          <g id="path-1-link" fill={fill}>
            <circle id="path-1" cx="16" cy="16" r="16"></circle>
          </g>
        </g>
        <g id="Group" transform="translate(7.529412, 6.588235)" stroke={stroke}>
          <polygon
            id="Path-2"
            strokeWidth="1.87076921"
            points="8.28325506 0.084596064 0 5.08883646 0 14.4018558 8.28390769 19.3312856 16.6555668 14.4265954 16.6555668 5.09040863"
          ></polygon>
          <polygon
            id="Rectangle"
            strokeWidth="0.935384604"
            fillRule="nonzero"
            points="7.96518444 11.211911 8.4790673 11.211911 8.4790673 18.5126903 7.96518444 18.5126903"
          ></polygon>
        </g>
      </g>
    </g>
  </SvgIcon>
);
