import type { FC } from 'react';
import React from 'react';
import { Edit as EditPage } from '../Edit';
import { DataTestIdListPage } from '../../tests/constants';

export const Create: FC = () => (
  <div data-testid={DataTestIdListPage.PAGE_CREATE_WRAPPER}>
    <EditPage />
  </div>
);
