import makeStyles from '@mui/styles/makeStyles';

interface StyleProps {
  fullScreen: boolean;
}

export const useStyles = makeStyles((theme) => ({
  autosizer: {
    height: 'calc(100vh - 96px - 16px) !important',
  },
  inputInput: {
    padding: '8px 8px 8px 50px',
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('md')]: {
      width: 350,
    },
    '&::placeholder': {
      color: 'black',
    },
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  wrapper: ({ fullScreen }: StyleProps) => ({
    position: fullScreen ? 'fixed' : 'relative',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[600],
  }),
  search: {
    boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24);',
    position: 'relative',
    borderRadius: 4,
    margin: '28px 12px',
    width: '98%',
  },
  searchIcon: {
    width: 50,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  listMessage: {
    position: 'absolute',
    top: '5%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 30,
    textAlign: 'center',
  },
  list: {
    paddingRight: 18,
    '&:focus': {
      outline: 'none',
    },
  },
  pageLoader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  root: {
    display: 'flex',
    overflow: 'hidden',
    minHeight: 72,
  },
}));
