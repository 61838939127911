import {
  ValidationActionType,
  ErrorType,
  CardEditActionTypes,
} from '../../types';
import { validateField } from '../validator';

export const getInitialErrorState = () => {
  const initalErrors: ErrorType = {
    title: {
      valid: false,
      pristine: true,
    },
    url: {
      valid: false,
      pristine: true,
    },
    brandKey: {
      valid: false,
      pristine: true,
    },
    label: {
      valid: false,
      pristine: true,
    },
    status: {
      valid: false,
      pristine: true,
    },
  };
  return initalErrors;
};

export const errorReducer = function (
  errors: ErrorType,
  action: ValidationActionType,
) {
  const {
    payload: { value, fieldName },
    type,
  } = action;
  switch (type) {
    case CardEditActionTypes.VALIDATE_FIELD: {
      return {
        ...errors,
        [fieldName]: { valid: validateField(value), pristine: false },
      };
    }
    default:
      return errors;
  }
};
