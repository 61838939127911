import { gql } from '@apollo/client';

export const COMPLETE_SLIDESHOW_IA = gql`
  mutation CompleteSlideshowIA(
    $recipeNumber: Int!
    $slideshowSubject: String!
    $slideshowEditionId: String!
    $brandKey: BrandKey!
  ) {
    completeSlideshowIA(
      brandKey: $brandKey
      recipeNumber: $recipeNumber
      slideshowEditionId: $slideshowEditionId
      slideshowSubject: $slideshowSubject
    ) {
      processId
    }
  }
`;
