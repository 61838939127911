import { gql } from '@apollo/client';
import {
  GetFocusPoint,
  GetFocusPointVariables,
} from '../../__generated__/queries-image';
import { useGetter } from '../useGetter';

export const GET_FOCUS_POINT = gql`
  query GetFocusPoint(
    $imageUrl: String!
    $imageWidth: Int!
    $imageHeight: Int!
  ) {
    getFocusPoint(
      imageUrl: $imageUrl
      imageWidth: $imageWidth
      imageHeight: $imageHeight
    ) {
      relativeX
      relativeY
      x
      y
    }
  }
`;

export const useFocusPointGetter = () =>
  useGetter<GetFocusPoint, GetFocusPointVariables>(GET_FOCUS_POINT);
