import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  backToButton: {
    position: 'relative',
    right: '-110px',
    top: '-25px',
  },
  centered: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  wrapper: {
    backgroundColor: theme.palette.background.paper,
  },
}));
