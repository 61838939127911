import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  content: {
    margin: '0 auto',
    maxWidth: '800px',
    flexGrow: 1,
    marginTop: theme.spacing(8),
    padding: theme.spacing(4),
    paddingTop: theme.spacing(6),
    minHeight: '90vh',
  },
  button: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
  },
  backdrop: {
    marginTop: theme.spacing(8),
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
