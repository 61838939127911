import { useOnMount, UserDialog } from '@prismamedia/one-components';
import { datadogRum } from '@datadog/browser-rum';
import React, { ComponentType, useEffect } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom';
import { getViewPageLabel } from '../../tracking/utils';
import { useUsersGetter } from '../../apollo/queries/users.auth.graphql';
import { auth } from '../../utils/auth';

type PrivateRouteProps = {
  component: ComponentType<RouteComponentProps<any>>;
} & RouteProps;

export const PrivateRoute = ({
  component: Component,
  ...rest
}: PrivateRouteProps) => {
  const getUsers = useUsersGetter();

  useOnMount(() => {
    if (auth?.user) {
      datadogRum.setUser({
        id: auth.user.id,
        name: auth.user.name || 'Inconnu',
        email: auth.user.email,
        roles: auth.user.roles,
      });
    }
  });

  useEffect(() => {
    datadogRum.startView(getViewPageLabel(rest.path as string));
  }, [rest.path]);

  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isAuthenticated() ? (
          <>
            <Component {...props} />
            <UserDialog auth={auth} searchUsers={getUsers} />
          </>
        ) : (
          <Redirect to={{ pathname: '/login' }} />
        )
      }
    />
  );
};
