import { AutocompleteItem } from '@prismamedia/one-components';
import { GetCategories_categories } from '../../__generated__/queries-web';

export const spreadCategoryChildren = (
  categories: GetCategories_categories[],
  parents: GetCategories_categories[],
  level = 0,
): AutocompleteItem[] =>
  parents.reduce(
    (prev, parentCategory) => [
      ...prev,
      {
        id: parentCategory.id,
        label: parentCategory.title,
        level,
      },
      ...spreadCategoryChildren(
        categories,
        categories.filter(({ parent }) => parent?.id === parentCategory.id),
        level + 1,
      ),
    ],
    [] as AutocompleteItem[],
  );
