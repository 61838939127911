import { TextField } from '@mui/material';
import {
  NotificationTypeEnum,
  OneMedia,
  PageWithDrawer,
  useNotification,
} from '@prismamedia/one-components';
import type { RawDraftContentState } from 'draft-js';
import {
  ChangeEvent,
  SetStateAction,
  memo,
  useCallback,
  useMemo,
  useReducer,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  BrandKey,
  SimilarArticleInputType,
  getCard_card,
} from '../../../../__generated__/queries-web';
import { Draft } from '../../../../components/Draft';
import { CharCounter } from '../../../../components/Draft/CharCounter';
import { actions as draftActions } from '../../../../components/Draft/DraftActions';
import { MediaInput } from '../../../../components/MediaInput';
import { MEDIA_TYPES } from '../../../../components/MediaInput/constants';
import { Tags } from '../../../../components/Tags';
import { CardAppBar } from '../../components/CardAppBar';
import { CardDrawer } from '../../components/Drawer';
import { CardEditActionTypes, CardState } from '../types';
import { SaveCardActions, useSaveCard } from '../useSaveCard';
import { handleErrorMessage, requiredFields } from '../utils/form';
import { getTagsConfiguration } from '../utils/tagsConfiguration';
import {
  CARD_ENHANCEMENT_MAIN_TITLE,
  CARD_FORM_ADD_MEDIA_LABEL,
  CARD_FORM_BODY_PLACEHOLDER,
  CARD_FORM_DESCRIPTION_LABEL,
  CARD_FORM_LOCATION_LABEL,
  CARD_FORM_SECONDARY_TITLE_LABEL,
  CARD_FORM_TITLE_LABEL,
  CARD_FORM_URL_LABEL,
} from './constants';
import {
  actions as cardActions,
  cardReducer,
  getInitialCardState,
} from './reducer/cardReducer';
import { errorReducer, getInitialErrorState } from './reducer/errorReducer';
import { useStyles } from './styles';
import {
  dispatchAllFieldsValidation,
  dispatchFieldValidation,
} from './validator';
import { useValidateCard } from './validator/useValidateCard';

interface CardFormProps {
  card?: getCard_card;
}

export const CardForm = memo(({ card }: CardFormProps) => {
  const classes = useStyles();
  const { pushNotification } = useNotification();
  const { saveCard } = useSaveCard();
  const { isCardValid } = useValidateCard();
  const { brandKey } = useParams<{ brandKey: BrandKey }>();

  const initalCard: CardState = getInitialCardState(brandKey, card);
  const initialErrors = getInitialErrorState();

  const [errors, dispatchValidation] = useReducer(errorReducer, initialErrors);
  const [state, dispatch] = useReducer(cardReducer, initalCard);

  const tagsConfiguration = useMemo(
    () =>
      getTagsConfiguration({
        cardTags: state.cardTags,
      }),
    [state.cardTags],
  );

  const setMedias = (updater: SetStateAction<OneMedia[]>) => {
    const newMedias =
      typeof updater === 'function'
        ? updater(state?.media ? [state.media] : [])
        : updater;

    if (newMedias.length > 1) {
      pushNotification({
        message: "Supprimez l'image avant d'en importer une nouvelle",
        type: NotificationTypeEnum.error,
      });
      return;
    }

    dispatch({
      type: CardEditActionTypes.UPDATE_FIELD,
      payload: {
        fieldName: 'media',
        value: newMedias[0],
      },
    });
  };

  const handleFieldChange = useCallback(
    (field: string, withValidation?: boolean) => (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string,
    ) => {
      const value = typeof event === 'string' ? event : event.target.value;

      dispatch(cardActions.updateField({ field, value }));

      if (withValidation) {
        dispatchFieldValidation(dispatchValidation, {
          fieldName: field,
          value,
        });
      }
    },
    [dispatch],
  );

  const handleTagsChange = useCallback(
    (cardTags, field) => {
      dispatch(
        cardActions.setCardTags({
          field,
          value: cardTags,
        }),
      );
    },
    [dispatch],
  );

  const handleCardSave = useCallback(
    (data: CardState) => {
      dispatchAllFieldsValidation(data, requiredFields, dispatchValidation);

      if (isCardValid(data)) {
        const cardSaveActionType = card
          ? SaveCardActions.UPDATE
          : SaveCardActions.CREATE;

        saveCard(
          data,
          card?.cardCategories || [],
          card?.cardTags || [],
          cardSaveActionType,
        );
      }
    },
    [isCardValid, card, saveCard],
  );

  return (
    <>
      <CardAppBar
        card={state}
        handleStatusChange={handleFieldChange('status', true)}
        onSave={handleCardSave}
      />

      <PageWithDrawer
        rightDrawer={
          <CardDrawer brandKey={brandKey} dispatch={dispatch} state={state} />
        }
      >
        <div className={classes.content}>
          <div className={classes.formGroup}>
            <TextField
              className={classes.textField}
              error={errors.title.pristine ? false : !errors.title.valid}
              fullWidth
              helperText={handleErrorMessage(
                errors.title,
                CARD_FORM_TITLE_LABEL,
              )}
              label={CARD_FORM_TITLE_LABEL}
              onChange={handleFieldChange('title', true)}
              required
              value={state.title}
              variant="standard"
              inputProps={{
                'data-testid': 'card-form-title',
              }}
            />
            <CharCounter content={state.title} />
          </div>

          <TextField
            className={classes.textField}
            fullWidth
            label={CARD_FORM_SECONDARY_TITLE_LABEL}
            onChange={handleFieldChange('secondaryTitle')}
            value={state.secondaryTitle}
            variant="standard"
          />
          <div className={classes.mediaInput}>
            <MediaInput
              allowedMedias={[MEDIA_TYPES.IMAGE]}
              brandKey={brandKey}
              disableFields
              medias={state?.media ? [state.media] : []}
              title={CARD_FORM_ADD_MEDIA_LABEL}
              setMedias={setMedias}
            />
          </div>
          <TextField
            className={classes.textField}
            fullWidth
            label={CARD_FORM_DESCRIPTION_LABEL}
            onChange={handleFieldChange('description')}
            value={state.description}
            variant="standard"
          />
          <TextField
            className={classes.textField}
            error={errors.url.pristine ? false : !errors.url.valid}
            fullWidth
            helperText={handleErrorMessage(errors.url, CARD_FORM_URL_LABEL)}
            label={CARD_FORM_URL_LABEL}
            onChange={handleFieldChange('url', true)}
            required
            value={state.url}
            variant="standard"
            inputProps={{
              'data-testid': 'card-form-url',
            }}
          />

          <TextField
            className={classes.textField}
            error={errors.label.pristine ? false : !errors.label.valid}
            fullWidth
            helperText={handleErrorMessage(
              errors.label,
              CARD_FORM_LOCATION_LABEL,
            )}
            label={CARD_FORM_LOCATION_LABEL}
            onChange={handleFieldChange('label', true)}
            required
            value={state.label}
            variant="standard"
            inputProps={{
              'data-testid': 'card-form-location',
            }}
          />
          <div className={classes.draft}>
            <Draft
              actions={[
                draftActions.BOLD,
                draftActions.ITALIC,
                draftActions.LINK,
                draftActions.INTERNAL_LINK,
              ]}
              onChange={(value) => {
                dispatch(
                  cardActions.updateField({
                    field: 'body',
                    value,
                  }),
                );
              }}
              rawDraft={(state.body as unknown) as RawDraftContentState}
              placeholder={CARD_FORM_BODY_PLACEHOLDER}
            />
          </div>

          <div className={classes.wrapperTags}>
            <Tags
              article={
                {
                  body: state.body,
                  title: state.title,
                } as SimilarArticleInputType
              }
              brandKey={state.brandKey}
              configuration={tagsConfiguration}
              onTagsChange={handleTagsChange}
              title={CARD_ENHANCEMENT_MAIN_TITLE}
            />
          </div>
        </div>
      </PageWithDrawer>
    </>
  );
});
