import { Fab, useTheme } from '@mui/material';
import { generatePath, Link } from 'react-router-dom';
import { paths } from '../../routing/Router/paths';
import { BrandKey } from '../../__generated__/queries-web';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

export const SpeedDialSlideshowGeneration = ({
  brand,
}: {
  brand?: BrandKey;
}) => {
  const theme = useTheme();

  return brand ? (
    <Link
      to={generatePath(paths.SLIDESHOW_IA, {
        brandKey: brand,
        activeTab: 'generate',
      })}
    >
      <Fab
        color="primary"
        sx={{
          position: 'fixed',
          bottom: theme.spacing(12),
          right: theme.spacing(3),
          zIndex: 1,
          bgcolor: '#4a62f4',
        }}
      >
        <AutoFixHighIcon />
      </Fab>
    </Link>
  ) : null;
};
