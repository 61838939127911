import { gql } from '@apollo/client';
import { isMobile } from 'react-device-detect';

const CONTEXTUALIZED_URL_FRAGMENT = isMobile
  ? gql`
      fragment ContextualizedUrlFragment on Article {
        contextualizedUrl(device: Mobile)
      }
    `
  : gql`
      fragment ContextualizedUrlFragment on Article {
        contextualizedUrl(device: Desktop)
      }
    `;

const COMMON_ARTICLE_FRAGMENT = gql`
  fragment CommonArticleFragment on Article {
    id
    title
    status
    body
    createdAt
    publishedAt
    editedAt
    brandKey
    format
    medias
    pushCount
    live {
      postCount
    }
    articleCategories(first: 2, orderBy: order_ASC) {
      order
      category {
        id
        title
      }
    }
    articleChannels(first: 1) {
      order
      category {
        id
        title
      }
    }
    articleCreatorData {
      name
    }
    articleUrls(first: 1, orderBy: order_ASC) {
      url {
        path
        domain
      }
    }
    articleQualifiers(first: 100) {
      order
      qualifier {
        id
        title
      }
    }
    articleExtension {
      lockedAt
      lockedBy {
        id
        name
        email
        avatarUrl
      }
    }
    ...ContextualizedUrlFragment
  }
  ${CONTEXTUALIZED_URL_FRAGMENT}
`;

export const ARTICLE_FRAGMENT = gql`
  fragment ArticleFragment on Article {
    ...CommonArticleFragment
    articleEvents(first: 10) {
      category {
        id
        title
      }
      order
    }
    articleQualifiers(first: 100) {
      qualifier {
        id
        title
      }
    }
    articleAuthors(first: 1) {
      author {
        id
        name
        avatarUrl
      }
    }
  }
  ${COMMON_ARTICLE_FRAGMENT}
`;

export const ARTICLE_DETAILS_FRAGMENT = gql`
  fragment ArticleDetailsFragment on Article {
    ...CommonArticleFragment
    secondaryTitle
    partnerTitle
    lead
    slug
    live {
      postCount
      endedAt
    }
    articleTags(first: 20, orderBy: order_ASC) {
      order
      tag {
        id
        relationId
        title
        type
      }
    }
    articleAuthors(first: 10) {
      order
      author {
        id
        name
        user {
          id
          name
          avatarUrl
        }
      }
    }
    source {
      id
      title
      status
    }
    articleEvents(first: 100) {
      order
      category {
        id
        title
      }
    }
    articleQualifiers(first: 100) {
      order
      qualifier {
        id
        title
      }
    }
    articleGuides(first: 1) {
      order
      category {
        id
        title
      }
    }
    articleMachineTags(first: 100) {
      order
      machineTag {
        id
        tag
      }
    }
    parentArticle(first: 1) {
      article {
        id
        brandKey
      }
    }
    articleHistories(first: 100, orderBy: createdAt_DESC) {
      id
      createdAt
      updatedAt
      user {
        id
        name
        avatarUrl
      }
      status
      article {
        id
        isClassified
      }
      duplicatedArticle {
        id
        brandKey
      }
    }
    platformId
  }
  ${COMMON_ARTICLE_FRAGMENT}
`;
