import React, { FC } from 'react';
import { ArticleState } from '../../pages/ArticleEdit/reducer';
import {
  orphanPageStatues,
  orphanPageStatusTransitions,
  statuses,
  statusTransitions,
} from '../../utils/statuses';
import {
  ArticleStatus,
  OrphanPageStatus,
} from '../../__generated__/queries-web';
import { StatusContainer } from './StatusContainer';

interface StatusSelectorProps {
  articleState?: ArticleState;
  changeStatus: { (newStatus: ArticleStatus): void };
  currentStatus: ArticleStatus | null;
  loading?: boolean;
}
export const StatusSelector: FC<StatusSelectorProps> = ({
  articleState,
  changeStatus,
  currentStatus,
  loading,
}): JSX.Element | null => {
  if (!currentStatus) {
    return null;
  }

  const { label } = statuses(articleState?.format)[currentStatus];
  return (
    <StatusContainer
      articleState={articleState}
      changeStatus={changeStatus}
      currentStatus={currentStatus}
      label={label}
      loading={loading}
      transitions={statusTransitions(
        articleState?.brandKey,
        articleState?.format,
      )}
    />
  );
};

interface OrphanPageSelectorProps {
  changeStatus: { (newStatus: OrphanPageStatus): void };
  currentStatus: OrphanPageStatus;
}
export const OrphanPageSelector: FC<OrphanPageSelectorProps> = ({
  changeStatus,
  currentStatus,
}): JSX.Element | null => {
  if (!currentStatus) {
    return null;
  }

  const { label } = orphanPageStatues[currentStatus];
  return (
    <StatusContainer
      changeStatus={changeStatus}
      currentStatus={currentStatus}
      label={label}
      transitions={orphanPageStatusTransitions}
    />
  );
};
