import { gql, useQuery } from '@apollo/client';
import {
  GetSegments,
  GetSegmentsVariables,
} from '../../../__generated__/queries-web';

export const GET_SEGMENTS = gql`
  query GetSegments(
    $first: Int!
    $where: SegmentWhereInput
    $orderBy: [SegmentOrderByInput!]
  ) {
    segments(first: $first, where: $where, orderBy: $orderBy) {
      active
      id
      title
      brandKey
      status
    }
    segmentCount
  }
`;

export const useSegments = (variables: GetSegmentsVariables, skip?: boolean) =>
  useQuery<GetSegments, GetSegmentsVariables>(GET_SEGMENTS, {
    variables,
    skip,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
