import { Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { FC } from 'react';
import { getCount } from '../../../components/Draft/CharCounter/utils';
import { ArticleState } from '../reducer';
import { useStyles } from './styles';

type ArticleToolBarProps = Pick<ArticleState, 'title' | 'lead' | 'body'> & {
  brandLabel?: string;
};

function getTotalTextCount(params: ArticleToolBarProps) {
  const { title, lead, body } = params;
  const titleCount = getCount(title);
  const leadCount = getCount(lead, 'Draft');
  const bodyCount = getCount(body, 'Draft');

  const totalChars =
    titleCount.charCount + leadCount.charCount + bodyCount.charCount;
  const totalWords =
    titleCount.wordCount + leadCount.wordCount + bodyCount.wordCount;
  return `${totalChars} caractère${
    totalChars > 1 ? 's' : ''
  } - ${totalWords} mot${totalWords > 1 ? 's' : ''}`;
}

export const ArticleToolBar: FC<ArticleToolBarProps> = ({
  title,
  lead,
  body,
  brandLabel,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const totalTextCount = getTotalTextCount({ title, lead, body });

  return (
    <Toolbar className={classes.toolbar} variant="dense">
      <div className={classes.wordCountMessage}>
        {!smallScreen && <Typography>Total de l'article :</Typography>}
        <Typography className={classes.globalCharCounter}>
          {totalTextCount}
        </Typography>
      </div>
      {brandLabel && <Typography variant="h6">{brandLabel}</Typography>}
    </Toolbar>
  );
};
