import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  dialog: {
    backgroundColor: 'grey',
  },
  dialogWrapper: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgb(238, 238, 238)',
  },
  dialogContainer: {
    width: '80%',
    margin: '1% 10% 0% 10%',
  },
  appBar: {
    backgroundColor: 'rgb(238, 238, 238)',
    boxShadow: 'none',
  },
  pickButton: {
    width: '30%',
  },
  dialogContent: {
    flexGrow: 1,
    width: '100%',
    marginTop: '5rem',
  },
  infiniteListWrapper: {
    backgroundColor: 'white',
    minHeight: '1000px',
  },
});
