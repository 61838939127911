import { makeStyles } from '@mui/styles';

const SIDEBAR_WIDTH = 180;

export const useStyles = makeStyles((theme) => ({
  leftIcon: {
    transform: 'rotate(-90deg)',
  },
  rightIcon: {
    transform: 'rotate(90deg)',
  },
  bottomIcon: {
    transform: 'rotate(180deg)',
  },
  wrapperAccentuation: {
    marginTop: theme.spacing(3),
  },
  icon: {
    position: 'relative',
    top: '3px',
    left: '-5px',
    fill: theme.palette.grey[400],
  },
  wrapperToolbar: {
    borderRight: `solid 1px ${theme.palette.grey[200]}`,
    marginLeft: theme.spacing(4),
    padding: theme.spacing(2, 4, 2, 0),
    position: 'relative',
    zIndex: 3,
    width: `${SIDEBAR_WIDTH}px`,
  },
  wrapperCounter: {
    marginTop: theme.spacing(2),

    '& label': {
      marginBottom: theme.spacing(1),
    },

    '& button[disabled]': {
      borderColor: theme.palette.grey[300],
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.grey[800],
    },
  },
}));
