import { gql, useApolloClient, useQuery } from '@apollo/client';
import {
  GetArticle,
  GetArticleCount,
  GetArticleCountVariables,
  GetArticles,
  GetArticlesVariables,
  GetArticleVariables,
} from '../../__generated__/queries-web';
import {
  ARTICLE_DETAILS_FRAGMENT,
  ARTICLE_FRAGMENT,
} from '../fragments/article.web.graphql';
import { DEFAULT_VOID_APOLLO_RESULT } from '../constants';
import { useCallback } from 'react';

const GET_ARTICLES = gql`
  query GetArticles(
    $first: Int!
    $where: ArticleWhereInput
    $skip: Int
    $orderBy: [ArticleOrderByInput!]
  ) {
    articles(first: $first, where: $where, skip: $skip, orderBy: $orderBy) {
      ...ArticleFragment
    }
  }
  ${ARTICLE_FRAGMENT}
`;

const GET_ARTICLE_COUNT = gql`
  query GetArticleCount($where: ArticleWhereInput) {
    articleCount(where: $where)
  }
`;

export const GET_ARTICLE_QUERY = gql`
  query GetArticle($where: ArticleWhereUniqueInput!) {
    article(where: $where) {
      ...ArticleDetailsFragment
    }
  }
  ${ARTICLE_DETAILS_FRAGMENT}
`;

export const useGetArticles = (
  variables: GetArticlesVariables,
  skip?: boolean,
) =>
  useQuery<GetArticles, GetArticlesVariables>(GET_ARTICLES, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    skip,
    variables,
  });

export const useArticlesGetter = () => {
  const client = useApolloClient();

  return (variables: GetArticlesVariables, skip?: boolean) =>
    skip
      ? Promise.resolve(DEFAULT_VOID_APOLLO_RESULT)
      : client.query<GetArticles, GetArticlesVariables>({
          query: GET_ARTICLES,
          variables,
          fetchPolicy: 'network-only',
        });
};

export const useArticleCountGetter = () => {
  const client = useApolloClient();

  return useCallback(
    (variables: GetArticleCountVariables, skip?: boolean) =>
      skip
        ? Promise.resolve(DEFAULT_VOID_APOLLO_RESULT)
        : client.query<GetArticleCount, GetArticleCountVariables>({
            query: GET_ARTICLE_COUNT,
            variables,
            fetchPolicy: 'network-only',
          }),
    [client],
  );
};

export const useGetArticle = (id: string) =>
  useQuery<GetArticle, GetArticleVariables>(GET_ARTICLE_QUERY, {
    variables: { where: { id } },
    skip: !id,
  });

export const useArticleGetter = () => {
  const client = useApolloClient();

  return (id: string) =>
    client.query<GetArticle, GetArticleVariables>({
      query: GET_ARTICLE_QUERY,
      variables: { where: { id } },
    });
};
