import { GetArticle_article_articleMachineTags } from '../../../__generated__/queries-web';

interface ArticleMachineTagsProps {
  assignmentId: string;
  order?: number;
}

export const getArticleMachineTag = ({
  assignmentId,
  order = 0,
}: ArticleMachineTagsProps): GetArticle_article_articleMachineTags => ({
  order,
  __typename: 'ArticleMachineTag',
  machineTag: {
    tag: `topicassignment:id=${assignmentId}`,
    id: assignmentId,
    __typename: 'MachineTag',
  },
});
