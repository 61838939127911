import { gql, useQuery } from '@apollo/client';
import {
  GetAssignmentVariables,
  GetAssignment,
} from '../../../__generated__/queries-topic';
import { ASSIGMENT_FRAGMENT } from './articleEditFragments.topic.graphql';
export const GET_ASSIGMENT_QUERY = gql`
  query GetAssignment($id: ID) {
    assignment(where: { id: $id }) {
      ...AssignmentFragment
    }
  }

  ${ASSIGMENT_FRAGMENT}
`;

export const useGetAssigment = (id: string | undefined) =>
  useQuery<GetAssignment, GetAssignmentVariables>(GET_ASSIGMENT_QUERY, {
    variables: {
      id,
    },
    skip: !id,
  });
