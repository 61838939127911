import { gql, useMutation } from '@apollo/client';
import { ORPHANPAGE_FRAGMENT } from './orphanPageFragments.web.graphql';
import {
  BrandKey,
  OrphanPageStatus,
  upsertOrphanPage,
  upsertOrphanPageVariables,
} from '../../../__generated__/queries-web';

const UPSERT_ORPHANPAGE = gql`
  mutation upsertOrphanPage(
    $where: OrphanPageWhereUniqueInput!
    $update: OrphanPageUpdateInput!
    $create: OrphanPageCreateInput!
  ) {
    upsertOrphanPage(where: $where, update: $update, create: $create) {
      ...OrphanPageFragment
    }
  }
  ${ORPHANPAGE_FRAGMENT}
`;

interface upsertOrphanPageProps {
  id: string;
  metas: string;
  brandKey: BrandKey;
  title: string;
  content: string;
  isCMPEnabled: boolean;
  isAdsEnabled: boolean;
  isFullScreen: boolean;
  uri: string;
  status: OrphanPageStatus;
}

export const prepareUpsertVariable = (
  props: upsertOrphanPageProps,
): upsertOrphanPageVariables => {
  const { id, ...variables } = props;
  return {
    create: {
      id,
      ...variables,
    },
    update: { ...variables },
    where: { id },
  };
};

export const useUpsertOrphanPage = () =>
  useMutation<upsertOrphanPage, upsertOrphanPageVariables>(UPSERT_ORPHANPAGE);
