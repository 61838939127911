import type { FormError } from '@prismamedia/one-components';
import { ErrorMessages, DEFAULT_ERROR } from '@prismamedia/one-components';
import { EMPTY_RAW_DRAFT_CONTENT_STATE } from '../../constants';
import type {
  CategoryFormSectionBoardContent,
  CategoryFormSectionTextContent,
  CategoryFormSectionPeopleContent,
  CategoryFormSectionUrlContent,
} from './types';

export const CATEGORY_FORM_SECTION_DESCRIPTION_LABEL =
  'Description de la section';
export const CATEGORY_FORM_SECTION_TITLE_LABEL = 'Titre de la section';
export const CATEGORY_FORM_SECTION_IS_ACTIVE_LABEL = 'Section active';
export const CATEGORY_FORM_SECTION_IS_INACTIVE_LABEL = 'Section inactive';

/// CATEGORY FORM SECTIONS ////////////////////////////////////////////
export const DEFAULT_URL_SECTION_FORM_CONTENT: CategoryFormSectionUrlContent = [
  '',
];
export const DEFAULT_BOARD_SECTION_FORM_CONTENT: CategoryFormSectionBoardContent[] = [];
export const DEFAULT_TEXT_SECTION_FORM_CONTENT: CategoryFormSectionTextContent = {
  body: EMPTY_RAW_DRAFT_CONTENT_STATE,
  contentTitle: '',
};
export const DEFAULT_PEOPLE_SECTION_FORM_CONTENT: CategoryFormSectionPeopleContent[] = [];

/// CATEGORY FORM SCTIONS ERRORS MANAGEMENT ////////////////////////////////////////////
export const CATEGORY_SECTION_CONTENT_URL_PATTERN_ERROR_MESSAGE = `Le format d'url saisi est invalide`;
export const CATEGORY_SECTION_TITLE_REQUIRED_ERROR_MESSAGE = `Le champ "${CATEGORY_FORM_SECTION_TITLE_LABEL}" est requis`;

export const DEFAULT_CATEGORY_SECTION_ERROR = {
  title: {
    ...DEFAULT_ERROR,
    errors: [ErrorMessages.requiredRule],
    errorMessages: {
      [ErrorMessages.requiredRule]: CATEGORY_SECTION_TITLE_REQUIRED_ERROR_MESSAGE,
    },
  } as FormError,
};
