import { gql } from '@apollo/client';

export const UPDATE_RECIPE_DESCRIPTIONS = gql`
  mutation recipeDescriptions(
    $slideshowEditionId: String!
    $recipesUrls: [String]!
  ) {
    updateSlideshowDescriptionsIA(
      slideshowEditionId: $slideshowEditionId
      recipesUrls: $recipesUrls
    ) {
      processId
    }
  }
`;
