import { PageBreakPlugin, Plugin } from '@prismamedia/one-components';
import { ArticleFormat, BrandKey } from '../../../__generated__/queries-web';
import { TablePlugin } from '../../../components/Draft/plugins/table';
import { MEDIA_TYPES } from '../../../components/MediaInput/constants';
import { brandsOptions } from '../../../utils/brands';
import {
  PluginType,
  c2cPlugin,
  framePlugin,
  imagePlugin,
  mediaPlugin,
  multimediaPlugin,
} from '../templates/utils';

const VIDEO_FIELDS = [
  'title',
  'medias',
  'tags',
  'event',
  'channel',
  'source',
  'visibility',
];

export const renderPluginsFromTemplate = (
  brandKey: BrandKey,
  additionnalPlugins?: PluginType[],
): Plugin[] => {
  const uniqPlugins = [
    ...new Set([
      ...(brandsOptions[brandKey]?.draft?.plugins || []),
      ...(additionnalPlugins || []),
    ]),
  ];

  return uniqPlugins.map((plugin) => {
    switch (plugin) {
      case PluginType.Image:
        return imagePlugin(brandKey);
      case PluginType.Media:
        return mediaPlugin;
      case PluginType.C2C:
        return c2cPlugin();
      case PluginType.Frame:
        return framePlugin({ brandKey });
      case PluginType.Multimedia:
        return multimediaPlugin();
      case PluginType.PageBreak:
        return PageBreakPlugin;
      case PluginType.Table:
        return TablePlugin;
    }
  });
};

export const renderInputFromTemplate = (
  format: ArticleFormat,
  brandKey: BrandKey,
) => (name: string): boolean => {
  if (!brandsOptions[brandKey]) {
    return false;
  }
  const fields =
    format === ArticleFormat.Video
      ? VIDEO_FIELDS
      : brandsOptions[brandKey].draft?.richFields || [];
  return !!fields.includes(name);
};

const VIDEO_MEDIA_TYPES = [MEDIA_TYPES.DAILYMOTION, MEDIA_TYPES.THUMBNAIL];

export const renderMediaFromTemplate = (
  format: ArticleFormat,
  brandKey: BrandKey,
) => {
  if (!brandsOptions[brandKey] || format === ArticleFormat.Slideshow) {
    return [];
  }

  return format === ArticleFormat.Video
    ? VIDEO_MEDIA_TYPES
    : brandsOptions[brandKey].medias?.richTypes || [];
};
