import { Videocam } from '@mui/icons-material';
import {
  mediaFromIframely,
  PluginButtonProps,
} from '@prismamedia/one-components';
import { insertDataBlock } from 'megadraft';
import { FC } from 'react';
import {
  ArticleFormat,
  SearchArticles_searchArticles,
} from '../../../../__generated__/queries-web';
import {
  getFirstMedia,
  getVideoThumbnail,
  parseMedias,
} from '../../../../utils/media';
import { embedImage } from '../../../../utils/upload';
import { SearchCard } from '../../../MediaInput/SearchCard';
import { DialogSearch } from '../shared/DialogSearch';
import { searchArticles } from '../shared/searchArticleForMedia';

export const ArticleVideoButton: FC<PluginButtonProps> = ({
  editorState,
  onChange,
}) => {
  const searchFn = (search: string) =>
    searchArticles({ search }, ArticleFormat.Video, { first: 100, skip: 0 });

  return (
    <DialogSearch
      title="Insertion d'une vidéo"
      tooltipText="Vidéo"
      searchFn={searchFn}
      onItemClick={async (item: SearchArticles_searchArticles) => {
        const media = getFirstMedia(parseMedias(item.medias));
        if (media) {
          const iframely = await embedImage(media.iframely.meta.canonical);
          const data = { type: 'media', ...mediaFromIframely(iframely) };
          onChange(insertDataBlock(editorState, data));
        }
      }}
      Icon={Videocam}
      renderItem={(item: SearchArticles_searchArticles) => (
        <SearchCard
          image={getVideoThumbnail(item.medias)}
          title={item.title || ''}
        />
      )}
    />
  );
};
