import { gql } from '@apollo/client';

export const CREATE_PUSH = gql`
  mutation CreatePush($data: PushCreateInput!) {
    createPush(data: $data) {
      id
      title
      body
      targetDevice
      article {
        id
      }
      user {
        id
        name
      }
      scheduledAt
      isCancelled
      sentAt
      pushSegments(first: 100) {
        segment {
          title
        }
      }
    }
  }
`;
