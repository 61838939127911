import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import { TabType } from '../../../../../../__generated__/queries-web';

interface GeneralConfigurationStyledProps {
  isSelected?: boolean;
  sectionIconType?: TabType;
}
export const useStyles = makeStyles<Theme, GeneralConfigurationStyledProps>(
  (theme) => ({
    activationField: {
      '& > span:last-child': {
        fontWeight: theme.typography.fontWeightMedium,
        marginLeft: theme.spacing(0.5),
      },
    },
    header: {
      alignItems: 'center',
      display: 'flex',
      padding: theme.spacing(2, 3, 2, 3.5),
      position: 'relative',
      width: '100%',

      '& > button': {
        boxShadow: 'none',
      },

      '& > div': {
        flex: '1 auto',
      },
    },
    innerWrapper: {
      backgroundColor: theme.palette.common.white,
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      borderTopLeftRadius: `calc(${theme.shape.borderRadius}px * 1.5)`,
      borderTopRightRadius: `calc(${theme.shape.borderRadius}px * 1.5)`,
      padding: theme.spacing(4, 8.5, 4, 8.5),
      position: 'relative',
      zIndex: 1,
    },
    sectionBgIcon: ({ sectionIconType }) => ({
      overflow: 'hidden',
      position: 'absolute',

      '& > svg': {
        position: 'relative',
        zoom: sectionIconType === TabType.Url ? 6.5 : 5,
        opacity: 0.05,
        top:
          sectionIconType === TabType.Url
            ? theme.spacing(-0.67)
            : sectionIconType === TabType.People
            ? theme.spacing(-0.35)
            : sectionIconType === TabType.Text
            ? theme.spacing(-0.25)
            : sectionIconType === TabType.Broadcast
            ? theme.spacing(-0.15)
            : theme.spacing(0),
        zIndex: 0,
        left:
          sectionIconType === TabType.Url
            ? theme.spacing(-1)
            : sectionIconType === TabType.People
            ? theme.spacing(-0.65)
            : sectionIconType === TabType.Text
            ? theme.spacing(-0.85)
            : theme.spacing(-0.5),
      },
    }),
    wrapper: {
      backgroundColor: theme.palette.grey[200],
      borderRadius: theme.shape.borderRadius,
      boxShadow: ({ isSelected }) =>
        `inset 0 0 0 1px ${
          isSelected ? theme.palette.primary.main : theme.palette.grey[200]
        }`,
      marginBottom: theme.spacing(2),
      scrollMarginTop: theme.spacing(4),

      '& > div': {
        padding: '1px',
        position: 'relative',
      },
    },
  }),
);
