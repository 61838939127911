import { convertFromRaw, EditorState, genKey } from 'draft-js';
import { useMemo, useState } from 'react';
import type { TablePluginData } from './types';
import type {
  PluginBlockProps,
  PluginButtonProps,
} from '@prismamedia/one-components';
import {
  deleteTableDraft,
  updateTableDraftData,
  createTableDraft,
} from './utils';
import { TablePluginAction } from './types';

interface Handlers {
  handleCancelTableDialog: (props: PluginBlockProps) => void;
  handleChangeTableData: (
    data: Partial<TablePluginData<EditorState>>,
    action?: TablePluginAction,
  ) => void;
  handleDeleteTablePlugin: () => void;
  handleValidTableDialog: (
    data: Partial<TablePluginData<EditorState>>,
    action?: TablePluginAction,
    props?: PluginBlockProps,
  ) => Promise<void>;
}

export const useEditTable = (
  data: TablePluginData,
  dataPluginBlockProps: PluginBlockProps | PluginButtonProps,
) => {
  const [tableKey, setTableKey] = useState(genKey());
  const [tablePluginData, setTablePluginData] = useState({
    ...data,
    tableContentState: EditorState.createWithContent(
      convertFromRaw(data.tableContentState),
    ),
  });

  const handlers = useMemo(
    (): Handlers => ({
      handleCancelTableDialog: (blockComponentProps) => {
        // Fix main megadraft bug selection after closing dialog
        blockComponentProps.blockProps.setReadOnly(false);

        return handlers.handleChangeTableData(tablePluginData);
      },

      handleChangeTableData: (
        partialData,
        action = TablePluginAction.UPDATE_TABLE,
      ) => {
        const newData = { ...tablePluginData, ...partialData };

        if (action === TablePluginAction.UPDATE_TABLE) {
          updateTableDraftData(
            dataPluginBlockProps as PluginBlockProps,
            newData,
          );
        } else if (action === TablePluginAction.CREATE_TABLE) {
          createTableDraft(dataPluginBlockProps as PluginButtonProps, newData);
        }

        setTablePluginData((currentTablePluginData) => ({
          ...currentTablePluginData,
          ...partialData,
        }));
      },
      handleDeleteTablePlugin: () => {
        deleteTableDraft({ ...dataPluginBlockProps } as PluginBlockProps);
      },
      handleValidTableDialog: (partialData, action, blockComponentProps) => {
        setTableKey(genKey);

        // Fix main megadraft bug selection after closing dialog
        blockComponentProps?.blockProps.setReadOnly(false);

        return new Promise((resolve) => {
          handlers.handleChangeTableData(partialData, action);
          resolve();
        });
      },
    }),
    [dataPluginBlockProps, tablePluginData],
  );

  return { handlers, states: { tablePluginData, tableKey } };
};
