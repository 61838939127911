import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material';

export const useStyles = makeStyles<Theme>((theme) => ({
  dragIconButton: {
    opacity: 0.5,

    '&:hover': {
      opacity: 1,
    },

    '& > svg': {
      zoom: `${1} !important`,
    },
  },
  editIconButton: {
    '& svg': {
      display: 'none',
      opacity: 0.25,
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.short,
      }),

      '&:hover': {
        opacity: 1,
      },
    },
  },
  errorIcon: {
    fill: `${theme.palette.error.dark} !important`,
    zoom: `${1} !important`,
  },
  errorTooltip: {
    backgroundColor: theme.palette.error.main,
  },
  noTitle: {
    color: theme.palette.grey[400],
    fontStyle: 'italic',
  },
  sectionItem: {
    '&:hover svg': {
      display: 'block',
    },
  },
  textField: {
    position: 'relative',
    top: '2px',
  },
  wrapper: {
    backgroundColor: theme.palette.common.white,
  },
}));
