import { Box, capitalize } from '@mui/material';
import { useOnMount } from '@prismamedia/one-components';
import React, { Fragment, useEffect } from 'react';
import type { FC } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { usePrevious } from 'react-use';
import { paths } from '../../routing/Router/paths';
import { useGlobalBrandKey, useGlobalFormat } from '../../utils/globalState';
import type { BrandKey, CategoryFormat } from '../../__generated__/queries-web';
import { CategoriesProvider } from './categories.context';
import { PageAppBar } from './components/AppBar';
import { Create as PageCreate } from './views/Create';
import { Edit as PageEdit } from './views/Edit';
import { List as PageList } from './views/List';
import { navigateTo } from './utils';
import { FormProvider } from './form.context';

export const Pages: FC = () => {
  const routeParams = useParams();
  const [brandKey, setBrandKey] = useGlobalBrandKey();
  const [format, setFormat] = useGlobalFormat();
  const previousState = usePrevious({ brandKey, format });

  // We updating the pathname when brandKey or format changes
  useEffect(() => {
    const canUpdateTheRoute =
      brandKey &&
      format &&
      previousState?.format &&
      previousState?.brandKey &&
      (previousState?.brandKey !== brandKey ||
        previousState?.format !== format);

    if (canUpdateTheRoute) {
      const newRouteParams = {
        brandKey,
        format: format.toLowerCase(),
      };

      navigateTo({ routeParams: newRouteParams });
    }
    // eslint-disable-next-line
  }, [brandKey, format]);

  // We first extracting data from pathname and filling fields
  useOnMount(() => {
    if (routeParams.format) {
      setBrandKey(routeParams.brandKey as BrandKey);
      setFormat(capitalize(routeParams.format) as CategoryFormat);
    }
  });

  if (!brandKey || !format) {
    return <Fragment />;
  }

  return (
    <CategoriesProvider brandKey={brandKey} format={format}>
      <FormProvider>
        <PageAppBar />

        <Box component="section">
          <Switch>
            <Route path={paths.PAGE_CREATE}>
              <PageCreate />
            </Route>
            <Route path={paths.PAGE_EDIT}>
              <PageEdit />
            </Route>
            <Route path={paths.PAGE_LIST}>
              <PageList />
            </Route>
          </Switch>
        </Box>
      </FormProvider>
    </CategoriesProvider>
  );
};
