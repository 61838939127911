import React, { ChangeEvent, FC, useState } from 'react';
import { Fab, Paper, TextField } from '@mui/material';
import { Delete, ModeEdit } from '@mui/icons-material';
import type { PluginBlockProps } from '@prismamedia/one-components';
import { TableDraft } from '../TableDraft';
import { TableDialog } from '../TableDialog';
import { useEditTable } from '../useEditTable';
import { useStyles } from './styles';
import { TablePluginAction } from '../types';
import type { TablePluginData } from '../types';

type TableBlockProps = PluginBlockProps & {
  data: TablePluginData;
};
export const TableBlock: FC<TableBlockProps> = (props) => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const {
    handlers: {
      handleCancelTableDialog,
      handleChangeTableData,
      handleDeleteTablePlugin,
      handleValidTableDialog,
    },
    states: { tablePluginData, tableKey },
  } = useEditTable(props.data, props);

  return (
    <>
      <figure className={classes.figure}>
        <Paper>
          {/* Table actions buttons */}
          <div className={classes.tableActions}>
            <Fab
              color="primary"
              onClick={() => {
                setIsDialogOpen(true);
              }}
              size="small"
            >
              <ModeEdit />
            </Fab>
            <Fab color="primary" onClick={handleDeleteTablePlugin} size="small">
              <Delete />
            </Fab>
          </div>

          {/* Table rendered */}
          <TableDraft
            key={tableKey}
            onChange={(tableContentState) => {
              handleChangeTableData({ tableContentState });
            }}
            tablePluginData={tablePluginData}
          />

          {/* Table metadata form */}
          <figcaption
            data-testid="mediaBlock"
            className={classes.metadataWrapper}
          >
            <TextField
              className={classes.textField}
              data-testid="table-title-input"
              fullWidth
              label="Titre"
              onChange={({
                target: { value },
              }: ChangeEvent<HTMLInputElement>) => {
                handleChangeTableData({ title: value });
              }}
              variant="standard"
              value={tablePluginData.title || ''}
            />

            <TextField
              className={classes.textField}
              data-testid="table-source-input"
              fullWidth
              label="Source"
              onChange={({
                target: { value },
              }: ChangeEvent<HTMLInputElement>) => {
                handleChangeTableData({ source: value });
              }}
              variant="standard"
              value={tablePluginData.source || ''}
            />
          </figcaption>
        </Paper>
      </figure>

      {/* Modal */}
      {isDialogOpen && (
        <TableDialog
          action={TablePluginAction.UPDATE_TABLE}
          isOpen={isDialogOpen}
          onCloseDialog={() => {
            setIsDialogOpen(false);
            handleCancelTableDialog(props);
          }}
          onValidTable={(data) => {
            handleValidTableDialog(
              data,
              TablePluginAction.UPDATE_TABLE,
              props,
            ).then(() => {
              setIsDialogOpen(false);
            });
          }}
          tablePluginData={tablePluginData}
        />
      )}
    </>
  );
};
