import React, { Dispatch, useState } from 'react';
import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';

import { actions, ActionType } from '../reducer';
import { saveData } from '../sideEffects';
import { DialogConfirm } from '../DialogConfirmation';
import { EditorContent } from '../EditorContent';
import { useLivePostUpsert } from '../graphql/upsertLivePost.web.graphql';

interface LivePostEditorPost {
  articleId: string;
  dispatch: Dispatch<ActionType>;
  title: string | null;
  body: string | null;
  creatorId?: string;
}

export const LivePostEditor = ({
  articleId,
  title,
  body,
  dispatch,
}: LivePostEditorPost) => {
  const { pushNotification } = useNotification();
  const [upsertMutation] = useLivePostUpsert({
    data: {
      title,
      body,
    },
    articleId,
  });

  const [isDialogOpen, setOpen] = useState(false);

  const onDialogConfirm = async () => {
    setOpen(false);
    const newData = await saveData(upsertMutation);
    if (newData) {
      dispatch(actions.addLivePost([newData]));
      pushNotification({
        message: 'Post ajouté',
        type: NotificationTypeEnum.success,
      });
    } else {
      pushNotification({
        message: 'Une erreur empêche la création du post',
        type: NotificationTypeEnum.error,
      });
    }
  };

  return (
    <>
      <DialogConfirm
        isOpen={isDialogOpen}
        confirmMessage="Poster"
        title="Êtes-vous sûrs de vouloir poster ce live ?"
        onDialogConfirm={onDialogConfirm}
        onClose={() => setOpen(false)}
      />
      <EditorContent
        dispatch={dispatch}
        body={body}
        title={title}
        isWritable={true}
        displaySubmitButton={true}
        onFormSubmit={() => setOpen(true)}
      />
    </>
  );
};
