import { gql } from '@apollo/client';

export const PAGE_CATEGORY_FRAGMENT = gql`
  fragment CategoryFragment on Category {
    brandKey
    bodyDraft
    categoryHistoryCount
    createdAt
    format
    description
    isHeadlined
    id
    level
    lockedAt
    lockedBy {
      avatarUrl
      email
      id
      name
    }
    medias
    parent {
      id
    }
    publishedAt
    slug
    status
    subtitle
    tabs(first: 50, orderBy: order_ASC) {
      content
      createdAt
      description
      id
      isOnline
      order
      title
      type
      updatedAt
      metas
    }
    tags
    title
    updatedAt
    editedAt
    urls
  }
`;
