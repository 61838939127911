import { AppName } from '@prismamedia/one-components';
import { isArticleVideo, isOneWeb } from './url';

export const getAppName = (pathname: string): AppName => {
  const currentApp = true;
  switch (currentApp) {
    case isArticleVideo(pathname):
      return AppName.Video;
    case isOneWeb(pathname):
      return AppName.Web;
    default:
      return AppName.Diapo;
  }
};
