import { insertDataBlock } from 'megadraft';
import type {
  PluginBlockProps,
  PluginButtonProps,
} from '@prismamedia/one-components';
import type { TablePluginData } from './types';
import { EditorState, convertToRaw } from 'draft-js';

const createTableDraft = (
  { editorState, onChange }: PluginButtonProps,
  tablePluginData: TablePluginData<EditorState>,
) => {
  const candidateData = {
    ...tablePluginData,
    tableContentState: convertEditorStateToRaw(
      tablePluginData.tableContentState,
    ),
  };

  return new Promise((resolve, reject) => {
    try {
      const newEditorState = insertDataBlock(editorState, candidateData);
      onChange(newEditorState);
      resolve(candidateData);
    } catch (error) {
      reject({ error, data: candidateData });
    }
  });
};

const deleteTableDraft = ({ blockProps, container }: PluginBlockProps) => {
  container.remove();
  blockProps.setReadOnly(false);
};

const updateTableDraftData = (
  { container }: PluginBlockProps,
  tablePluginData: TablePluginData<EditorState>,
) => {
  const candidateData = {
    ...tablePluginData,
    tableContentState: convertEditorStateToRaw(
      tablePluginData.tableContentState,
    ),
  };

  return new Promise((resolve, reject) => {
    try {
      container.updateData(candidateData);
      resolve(candidateData);
    } catch (error) {
      reject({ error, data: candidateData });
    }
  });
};

const convertEditorStateToRaw = (editorState: EditorState) =>
  convertToRaw(editorState.getCurrentContent());

export {
  createTableDraft,
  convertEditorStateToRaw,
  deleteTableDraft,
  updateTableDraftData,
};
