import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  metadataWrapper: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2, 2, 3, 2),
  },
  figure: {
    position: 'relative',
    maxWidth: '800px',
    margin: 0,

    '&:hover $tableActions > button': {
      display: 'flex',
    },
  },
  tableActions: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: 5,
    right: 5,
    zIndex: theme.zIndex.drawer + 1,

    '& > button': {
      display: 'none',
      marginBottom: theme.spacing(0.5),
    },
  },
  textField: {
    padding: '0',
    marginBottom: theme.spacing(4),

    '&:last-child': {
      marginBottom: '0',
    },
  },
}));
