import { TextField } from '@mui/material';
import { SimpleCheckBox } from '@prismamedia/one-components';
import React, { ChangeEvent, Dispatch, FC } from 'react';
import { MediaBlock } from '../../../types/draft';
import { actions, SlideshowEditAction } from '../reducer';
import { useStyles } from './styles';

interface ButtonFormProps {
  dispatch: Dispatch<SlideshowEditAction>;
  selectedSlide: MediaBlock;
}

export const ButtonForm: FC<ButtonFormProps> = ({
  dispatch,
  selectedSlide,
}) => {
  const classes = useStyles();

  return (
    <>
      <TextField
        className={classes.field}
        fullWidth
        label="Titre du bouton"
        multiline
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          dispatch(
            actions.setSlideButtonTitle({
              slideKey: selectedSlide.key,
              title: event.target.value,
            }),
          );
        }}
        value={
          (selectedSlide.data.slideshow &&
            selectedSlide.data.slideshow.buttonTitle) ||
          ''
        }
        variant="standard"
      />
      <TextField
        className={classes.field}
        fullWidth
        label="Lien du bouton"
        multiline
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          dispatch(
            actions.setSlideButtonUrl({
              slideKey: selectedSlide.key,
              url: event.target.value,
            }),
          );
        }}
        value={
          (selectedSlide.data.slideshow &&
            selectedSlide.data.slideshow.buttonUrl) ||
          ''
        }
        variant="standard"
      />
      <SimpleCheckBox
        label="NoFollow"
        checked={selectedSlide.data.slideshow?.buttonRel === 'nofollow'}
        onChange={(checked) =>
          dispatch(
            actions.setSlideButtonRel({
              slideKey: selectedSlide.key,
              nofollow: checked,
            }),
          )
        }
      />
    </>
  );
};
