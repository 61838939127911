export const pluralizeText = (
  count: number,
  text: string,
  withCount?: boolean,
) => {
  const suffix = text == text.toUpperCase() ? 'S' : 's';
  const splitText = text.split(' ');
  if (count > 1) {
    const plural = splitText.map((pluralText) => `${pluralText}${suffix}`);
    return withCount ? `${count} ${plural.join(' ')}` : plural.join(' ');
  }
  return withCount ? `${count} ${text}` : text;
};
