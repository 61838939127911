import { TextField } from '@mui/material';
import {
  EditAppBar,
  NonEditableField,
  PageWithDrawer,
} from '@prismamedia/one-components';
import React, { FC, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useCreateAttribute } from '../../../apollo/queries/createAttribute.web.graphql';
import { paths } from '../../../routing/Router/paths';
import { AttributeCreateInput } from '../../../__generated__/queries-web';
import { useStyles } from './styles';

export const AttributeEditPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const createAttribute = useCreateAttribute();
  const [loading, setLoading] = useState(false);
  const { brandKey } = useParams();
  const [attribute, setAttribute] = useState<AttributeCreateInput>({
    brandKey,
    title: '',
  });

  const onSave = async () => {
    setLoading(true);
    const res = await createAttribute(attribute);
    setLoading(false);
    if (res) {
      history.push(paths.ATTRIBUTES_LIST);
    }
  };

  return (
    <>
      <EditAppBar
        title={'Nouveau attribute'}
        onNavigateBack={() => history.goBack()}
        save={{
          disabled: !attribute.title.length,
          onClick: onSave,
          loading,
        }}
      />
      <PageWithDrawer
        rightDrawer={
          <NonEditableField
            className={classes.field}
            label="Marque"
            value={attribute?.brandKey}
          />
        }
      >
        <TextField
          className={classes.field}
          label="Titre"
          onChange={({ target: { value } }) => {
            setAttribute((prev) => ({ ...prev, title: value }));
          }}
          variant="standard"
          value={attribute.title}
        />
      </PageWithDrawer>
    </>
  );
};
