import React, { FC } from 'react';
import { paths } from '../../routing/Router/paths';
import { ArticleFormat } from '../../__generated__/queries-web';
import { ListPage } from '../shared/ListPage';

export const SlideshowList: FC = () => (
  <ListPage
    path={paths.SLIDESHOW_LIST}
    articleFormat={ArticleFormat.Slideshow}
  />
);
