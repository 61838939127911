import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ArticleFormat, BrandKey } from '../../../__generated__/queries-web';
import { ArticleEdit } from '../ArticleEdit';
import { SuspendTask } from '../../../components/SuspendTask';
import { Loader } from '../../../components/Loader';
import { useDuplicateArticle } from './useDuplicateArticle';

interface ArticleRouteProps {
  articleType: ArticleFormat;
  brandKey: BrandKey;
  versionId: string;
  rawArticleId: string;
}

export const EditDuplicatedArticle: FC<RouteComponentProps<
  ArticleRouteProps
>> = ({ match }) => {
  const duplicateArticleTask = useDuplicateArticle(match.params);

  return (
    <SuspendTask
      task={duplicateArticleTask}
      fallback={
        <Loader
          fullscreen
          message="Chargement des données article en cours..."
        />
      }
    >
      {({ sourceArticle, webArticle }) => (
        <ArticleEdit
          data={webArticle}
          subjectId={sourceArticle.assignment.subject.id}
          subjectTitle={sourceArticle.assignment.subject.title}
        />
      )}
    </SuspendTask>
  );
};
