import { MultimediaListProps } from '@prismamedia/one-components';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BrandKey } from '../../../__generated__/queries-web';
import { brandsOptions } from '../../../utils/brands';
import { OnePhoto } from './OnePhoto';
import { OneStory } from './OneStory';
import { ProvidersPhoto } from './ProvidersPhoto';
import type { FotoItemSerialized } from './types';
import { PhotoCollapsesTypes } from './types';

export interface FotoProps<Item = FotoItemSerialized>
  extends MultimediaListProps<FotoItemSerialized> {
  setItems: (items: Item[]) => void;
  topicId?: string;
}

export const FotoSearch: FC<FotoProps> = ({
  items,
  setItems,
  topicId,
  ...restFotoSearchProps
}) => {
  const { brandKey } = useParams();
  const { useFotoweb } = brandsOptions[brandKey as BrandKey];
  const [collapseOpen, setCollapseOpen] = useState<PhotoCollapsesTypes | null>(
    useFotoweb
      ? PhotoCollapsesTypes.ONE_PHOTOS
      : topicId
      ? PhotoCollapsesTypes.ONE_STORY
      : PhotoCollapsesTypes.PROVIDERS,
  );

  useEffect(
    () => () => {
      // We emptying the rendered list when the component is unmounted
      setItems([]);
    },
    // eslint-disable-next-line
    [],
  );

  return (
    <>
      <ProvidersPhoto
        brandKey={brandKey}
        isCollapseOpen={collapseOpen === PhotoCollapsesTypes.PROVIDERS}
        items={items.filter(
          (item) => item.from === PhotoCollapsesTypes.PROVIDERS,
        )}
        onCollapseClick={setCollapseOpen}
        setItems={setItems}
        topicId={topicId}
        {...restFotoSearchProps}
      />

      {topicId && (
        <OneStory
          brandKey={brandKey}
          isCollapseOpen={collapseOpen === PhotoCollapsesTypes.ONE_STORY}
          items={items.filter(
            (item) => item.from === PhotoCollapsesTypes.ONE_STORY,
          )}
          onCollapseClick={setCollapseOpen}
          setItems={setItems}
          topicId={topicId}
          {...restFotoSearchProps}
        />
      )}

      {useFotoweb && (
        <OnePhoto
          brandKey={brandKey}
          items={items.filter(
            (item) => item.from === PhotoCollapsesTypes.ONE_PHOTOS,
          )}
          isCollapseOpen={collapseOpen === PhotoCollapsesTypes.ONE_PHOTOS}
          onCollapseClick={setCollapseOpen}
          setItems={setItems}
          {...restFotoSearchProps}
        />
      )}
    </>
  );
};
