import {
  InnerMediaBlock,
  InnerMediaBlockProps,
  PluginButtonProps,
} from '@prismamedia/one-components';
import { FC } from 'react';
import { ArticleFormat, BrandKey } from '../../../../__generated__/queries-web';
import { brandsOptions } from '../../../../utils/brands';
import { ArticleEditAction } from '../../reducer';
import { AffiliateMediaButton } from './AffiliateMediaButton';

export const AffiliateMediaPlugin = (
  brandKey: BrandKey,
  articleId: string,
  format: ArticleFormat,
  dispach: React.Dispatch<ArticleEditAction>,
): {
  type: string;
  buttonComponent: FC<PluginButtonProps>;
  blockComponent: FC<InnerMediaBlockProps>;
} | null => {
  if (!brandsOptions[brandKey].affiliate || !articleId) {
    return null;
  }
  return {
    type: 'media_affiliate',
    buttonComponent: AffiliateMediaButton({
      brandKey,
      dispach,
      format,
    }),
    blockComponent: InnerMediaBlock,
  };
};
