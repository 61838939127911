import { Error } from '@mui/icons-material';
import { CircularProgress, Typography, useTheme } from '@mui/material';
import { SearchParams } from '@prismamedia/one-components';
import { format } from 'date-fns';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useRecipeCountGetter } from '../../../apollo/queries/recipes.recipe.graphql';
import { useSearchArticleCountGetter } from '../../../apollo/queries/searchArticles.web.graphql';
import { useGlobalBrandKey } from '../../../utils/globalState';
import { Filters } from '../FiltersDrawer/utils';
import { GraphTooltip } from './GraphTooltip';
import { useStyles } from './styles';
import {
  IntervalId,
  fetchGraphData,
  getSelectedStatus,
  intervals,
} from './utils';

interface GraphProps {
  filters: Filters;
  searchParams: SearchParams;
  loadingUrlParams: boolean;
}

export const Graph = forwardRef<HTMLDivElement, GraphProps>(
  ({ filters, searchParams, loadingUrlParams }, ref) => {
    const classes = useStyles();
    const theme = useTheme();
    const [brandKey] = useGlobalBrandKey();
    const getArticleCount = useSearchArticleCountGetter();
    const getRecipeCount = useRecipeCountGetter();
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error>();

    const drawedStatus = useMemo(
      () =>
        getSelectedStatus(filters).filter(({ label }) =>
          data.reduce((prev, point) => prev + point[label], 0),
        ),
      [data, filters],
    );

    useEffect(() => {
      if (loadingUrlParams) return;
      setLoading(true);
      setError(undefined);
      fetchGraphData(
        brandKey,
        filters,
        searchParams,
        getArticleCount,
        getRecipeCount,
      )
        .then(setData)
        .catch((e) => {
          setError(e);
          setData([]);
        })
        .finally(() => setLoading(false));
    }, [
      brandKey,
      filters,
      getArticleCount,
      getRecipeCount,
      loadingUrlParams,
      searchParams,
    ]);

    return (
      <div ref={ref} className={classes.wrapper}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={data}>
            <Legend verticalAlign="top" height={36} />
            <XAxis dataKey="name" />
            <YAxis allowDecimals={false} />
            <CartesianGrid vertical={false} strokeOpacity={0.4} />
            <ReferenceLine
              x={format(new Date(), intervals[IntervalId.MONTH].format)}
              stroke={theme.palette.primary.main}
            />
            <ReferenceLine
              x={format(new Date(), intervals[IntervalId.DAY].format)}
              stroke={theme.palette.primary.main}
            />
            <ReferenceLine
              x={format(new Date(), intervals[IntervalId.HOUR].format)}
              stroke={theme.palette.primary.main}
            />
            <Tooltip
              wrapperStyle={{ outline: 0 }}
              content={(props: any) => <GraphTooltip {...props} />}
            />
            {drawedStatus.map((status) => (
              <Area
                key={status.id}
                type="linear"
                strokeWidth={2}
                dataKey={status.label}
                stroke={status.color}
                fill={status.color}
                fillOpacity={0.1}
              />
            ))}
          </AreaChart>
        </ResponsiveContainer>

        {(loading || error) && (
          <div className={classes.centered}>
            {error ? (
              <>
                <Error className={classes.errorIcon} />
                <Typography variant="h6" color="primary">
                  {error.message}
                </Typography>
              </>
            ) : (
              <CircularProgress size={80} />
            )}
          </div>
        )}
      </div>
    );
  },
);
