import {
  DEFAULT_ERROR,
  EditableFieldsErrors,
} from '@prismamedia/one-components';
import {
  BrandKey,
  CategoryFormat,
  CategoryStatus,
} from '../../__generated__/queries-web';
import { ReactComponent as draftIcon } from '../../assets/svg/draft.svg';
import { ReactComponent as frozenIcon } from '../../assets/svg/frozen.svg';
import { ReactComponent as publishedIcon } from '../../assets/svg/published.svg';
import { ReactComponent as scheduledIcon } from '../../assets/svg/scheduled.svg';
import { CATEGORY_FORM_TITLE_LABEL } from './views/Edit/components/GeneralConfiguration/constants';
import { SectionErrors } from './views/Edit/components/Section/types';

export const CREATED_AT = 'Créé le';
export const UPDATED_AT = 'Modifié le';

export const FETCH_CATEGORIES_ERROR_MESSAGE =
  'Unable to fetch brand categories';

export const SAVE_CATEGORY_ERROR_MESSAGE =
  "Erreur lors de l'enregistrement de la page";

export const SAVE_CATEGORY_SUCCESS_MESSAGE =
  'La page a correctement été sauvegardée';

export const ALLOWED_CATEGORY_STATUES = [
  {
    id: CategoryStatus.Published,
    label: 'Publiée',
    icon: publishedIcon,
    color: '#02a8f3',
  },
  {
    id: CategoryStatus.Scheduled,
    label: 'Programmée',
    icon: scheduledIcon,
    color: '#02a8f3',
  },
  {
    id: CategoryStatus.Archived,
    label: 'Archivée',
    icon: frozenIcon,
    color: '#02a8f3',
  },
  {
    id: CategoryStatus.Draft,
    label: 'Brouillon',
    icon: draftIcon,
    color: '#02a8f3',
  },
];

export const ALLOWED_FORMATS = [
  {
    label: 'Évènements',
    value: CategoryFormat.Event,
  },
  {
    label: 'Chaînes vidéos',
    value: CategoryFormat.Channel,
  },
  {
    label: 'Guides',
    value: CategoryFormat.Guide,
  },
  {
    label: 'Catégories',
    value: CategoryFormat.Category,
  },
];

export const DEFAULT_ALLOWED_FORMAT = CategoryFormat.Event;

/// CATEGORY FORM ////////////////////////////////////////////
export const DEFAULT_CATEGORY_FORM = {
  brandKey: BrandKey.TEL,
  description: null,
  descriptionSEO: '',
  format: CategoryFormat.Event,
  isHeadlined: false,
  level: 3,
  medias: null,
  parent: null,
  publishedAt: null,
  sections: [],
  slug: '',
  status: CategoryStatus.Draft,
  subTitle: '',
  tags: [],
  title: '',
};

export const DEFAULT_CATEGORY_FORM_SECTION = {
  content: [],
  description: '',
  isActive: false,
  order: 0,
  title: '',
  metas: '',
};

export const DEFAULT_ROOT_CATEGORY = {
  brandKey: BrandKey.BONE,
  level: 1,
};

/// CATEGORY FORM ERRORS MANAGEMENT ////////////////////////////////////////////
export const CATEGORY_TITLE_REQUIRED_ERROR_MESSAGE = `Le champ "${CATEGORY_FORM_TITLE_LABEL}" est requis`;

export const DEFAULT_CATEGORY_ERRORS = {
  medias: [] as EditableFieldsErrors[],
  sections: [] as SectionErrors[],
  title: DEFAULT_ERROR,
};
