import { ExpandMore, ImportContacts } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import { GetArticle_article_articleMachineTags } from '../../../../__generated__/queries-web';
import { DUPLICATE_TO_TOPIC_MACHINE_TAG } from '../../DuplicatePrintDialog/duplicatePrint.web.graphql';
import { useGetAssignments } from './linksToTopic.topic.graphql';

export const LinksToTopic: FC<{
  articleMachineTags: GetArticle_article_articleMachineTags[] | undefined;
}> = ({ articleMachineTags }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  const linksToTopic = articleMachineTags
    ?.filter(({ machineTag }) =>
      machineTag.tag.startsWith(DUPLICATE_TO_TOPIC_MACHINE_TAG),
    )
    .map(
      ({ machineTag }) =>
        machineTag.tag.split(`${DUPLICATE_TO_TOPIC_MACHINE_TAG}=`)[1],
    );

  const { data } = useGetAssignments(
    {
      limit: linksToTopic?.length || 0,
      where: {
        id_in: linksToTopic,
      },
    },
    !linksToTopic?.length,
  );

  const assignments = data?.assignments.filter(
    ({ rawArticle }) => !!rawArticle,
  );

  if (!assignments?.length) return null;

  return (
    <Accordion
      sx={{
        width: '100%',
        boxShadow: 'none',
        border: 'solid',
        borderWidth: '1px 0 1px 0',
        borderColor: 'divider',
        '&:not(:last-child)': {
          borderBottom: 0,
        },
        '&:before': {
          display: 'none',
        },
        marginTop: '0 !important',
      }}
      expanded={accordionOpen}
      onChange={(_, open) => setAccordionOpen(open)}
    >
      <AccordionSummary
        sx={{
          py: 2,
          '& .icon': {
            display: 'flex',
            '& p': {
              paddingLeft: '1rem',
              fontWeight: 500,
            },
          },
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(180deg)',
          },
          minHeight: 'auto !important',
          '& .MuiAccordionSummary-content': {
            marginLeft: '1px',
            marginTop: '0 !important',
            marginBottom: '0 !important',
          },
        }}
        expandIcon={<ExpandMore />}
      >
        <div className="icon">
          <ImportContacts />
          <Typography>{`Reprise magazine (${assignments.length})`}</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: '0.5px',
          borderTop: '1px solid rgba(0, 0, 0, .125)',
        }}
      >
        {assignments.map((assignment, index) => (
          <>
            {index > 0 && <Divider />}
            <Box key={assignment.id} sx={{ p: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 1,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 14,
                    whiteSpace: 'nowrap',
                    mr: 3,
                    flexBasis: 70,
                    flexShrink: 0,
                  }}
                >
                  Magazine :
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                  {assignment.printHeading?.printIssue.printPublication?.title}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 1,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 14,
                    whiteSpace: 'nowrap',
                    mr: 3,
                    flexBasis: 70,
                    flexShrink: 0,
                  }}
                >
                  Parution :
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                  {assignment.printHeading?.printIssue?.title}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 1,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 14,
                    whiteSpace: 'nowrap',
                    mr: 3,
                    flexBasis: 70,
                    flexShrink: 0,
                  }}
                >
                  Têtière :
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                  {assignment.printHeading?.title}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 14,
                    whiteSpace: 'nowrap',
                    mr: 3,
                    flexBasis: 70,
                    flexShrink: 0,
                  }}
                >
                  Lien :
                </Typography>
                <Link
                  href={`${config.FRONT_ONE_TOPIC}/article/edit/${assignment.rawArticle?.id}`}
                  target="_blank"
                  sx={{
                    fontSize: 14,
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                  color="rgb(0, 0, 0)"
                >
                  {assignment.rawArticle?.lastVersion?.title}
                </Link>
              </Box>
            </Box>
          </>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
