import { gql, useQuery } from '@apollo/client';
import {
  getOpenAiProcesses,
  getOpenAiProcessesVariables,
} from '../../__generated__/queries-web';

export const GET_USER_OPEN_AI_PROCESSES = gql`
  query getOpenAiProcesses($userId: ID!) {
    openAiProcesses(first: 100, orderBy: _id_DESC, where: { userId: $userId }) {
      article {
        id
      }
      id
      slideshowSubject
      recipeNumber
      status
      errorMsg
      createdAt
      updatedAt
      generatedTitle
    }
  }
`;

export const useGetUserOpenAiProcesses = (userId: string) =>
  useQuery<getOpenAiProcesses, getOpenAiProcessesVariables>(
    GET_USER_OPEN_AI_PROCESSES,
    {
      variables: {
        userId,
      },
      skip: !userId,
    },
  );
