import { gql, useMutation } from '@apollo/client';
import {
  CreateAuthor,
  CreateAuthorVariables,
  UpdateAuthor,
  UpdateAuthorVariables,
} from '../../__generated__/queries-web';
import { AUTHOR_FRAGMENT } from '../fragments/author.web.graphql';

const UPDATE_AUTHOR = gql`
  mutation UpdateAuthor(
    $where: AuthorWhereUniqueInput!
    $data: AuthorUpdateInput!
  ) {
    updateAuthor(where: $where, data: $data) {
      ...AuthorFragment
    }
  }
  ${AUTHOR_FRAGMENT}
`;

export const useAuthorUpdater = () =>
  useMutation<UpdateAuthor, UpdateAuthorVariables>(UPDATE_AUTHOR);

const CREATE_AUTHOR = gql`
  mutation CreateAuthor($data: AuthorCreateInput!) {
    createAuthor(data: $data) {
      ...AuthorFragment
    }
  }
  ${AUTHOR_FRAGMENT}
`;

export const useAuthorCreate = () =>
  useMutation<CreateAuthor, CreateAuthorVariables>(CREATE_AUTHOR);
