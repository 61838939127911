import { Add, ArrowBack } from '@mui/icons-material';
import { Fab, IconButton, Tooltip, Typography } from '@mui/material';
import { HomeAppBar, InfiniteTable } from '@prismamedia/one-components';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { replaceParams } from '../../../routing/Router';
import { paths } from '../../../routing/Router/paths';
import { auth } from '../../../utils/auth';
import { useRoles } from './roles.auth.graphql';
import { useStyles } from './styles';

export const UserRoleListPage: FC = () => {
  const history = useHistory();
  const classes = useStyles();

  const { data, loading, error } = useRoles({
    first: 1000,
  });

  return (
    <>
      <HomeAppBar
        appDrawerMenu={
          <IconButton
            className={classes.backIcon}
            onClick={() => history.push(paths.CRUD)}
            size="large"
          >
            <ArrowBack />
          </IconButton>
        }
        brandMenu={
          <>
            <Typography className={classes.title} variant="h6">
              Rôles utilisateurs
            </Typography>
          </>
        }
        currentUser={auth.user}
        disconnectUser={auth.logout}
      />
      <div className={classes.listWrapper}>
        <InfiniteTable
          list={data?.roles}
          loading={loading}
          error={error}
          columns={[
            {
              label: 'Slug',
              key: 'slug',
              width: 200,
              flexGrow: 1,
            },
            {
              label: "Nbr d'utilisateurs",
              key: 'userCount',
              width: 200,
            },
          ]}
          onRowClick={(e, category) => {
            e.preventDefault();
            history.push(
              replaceParams(paths.USER_ROLE_EDIT, {
                id: category.id,
              }),
            );
          }}
        />
      </div>
      <Tooltip title="Créer un rôle utilisateur">
        <Fab
          className={classes.newButton}
          color="primary"
          onClick={() => {
            history.push(paths.USER_ROLE_NEW);
          }}
        >
          <Add />
        </Fab>
      </Tooltip>
    </>
  );
};
