import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

interface BoardStyledProps {
  hasContentLength: boolean;
}
export const useStyles = makeStyles<Theme, BoardStyledProps>((theme) => ({
  addButton: {
    '& svg': {
      position: 'relative',
      top: '-1px',
    },
  },
  title: {
    fontSize: '1rem',
    fontWeight: 500,

    '& > span': {
      fontWeight: 200,
    },
  },
  wrapper: {
    margin: theme.spacing(2, 0, 1, 0),
  },
  wrapperCards: ({ hasContentLength }) => ({
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(-2),

    ...(!hasContentLength && {
      alignItems: 'center',
      backgroundColor: theme.palette.grey[100],
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.grey[400],
      display: 'flex',
      justifyContent: 'center',
      marginLeft: theme.spacing(0),
      minHeight: 110,
    }),
  }),
  wrapperSkeletons: {
    marginTop: theme.spacing(2),
  },
  wrapperToolbar: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'space-between',

    '& > span': {
      flex: '1 auto',
      marginBottom: theme.spacing(-0.9),
    },
  },
}));
