import { gql } from '@apollo/client';
import { useFetch } from '../../../utils/useFetch';
import {
  GetBrands,
  GetBrandsVariables,
} from '../../../__generated__/queries-auth';

const GET_BRANDS = gql`
  query GetBrands($first: Int!, $skip: Int, $where: BrandWhereInput) {
    brands(first: $first, skip: $skip, where: $where) {
      id
      key
    }
  }
`;

export const useGetBrands = () =>
  useFetch<GetBrands, GetBrandsVariables>(GET_BRANDS);
