import { gql } from '@apollo/client';

export const GENERATE_SLIDESHOW_IA = gql`
  mutation GenerateSlideshowIA(
    $recipeNumber: Int!
    $slideshowSubject: String!
    $brandKey: BrandKey!
    $recipeIds: [String!]!
  ) {
    generateSlideshowIA(
      brandKey: $brandKey
      recipeNumber: $recipeNumber
      slideshowSubject: $slideshowSubject
      recipeIds: $recipeIds
    ) {
      processId
    }
  }
`;
