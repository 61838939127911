import { Button, Card, IconButton, TextField, Typography } from '@mui/material';
import {
  PageWithDrawer,
  NonEditableField,
  getRequiredErrorMessage,
  objectsAreDifferents,
  EditAppBar,
  ChipsInput,
  AutocompleteSelect,
} from '@prismamedia/one-components';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { paths } from '../../../routing/Router/paths';
import { GetUser_user } from '../../../__generated__/queries-auth';
import { useStyles } from './styles';
import { useGetUser, useUpdateUser, useCreateUser } from './user.auth.graphql';
import { formatDate } from '../../../utils/dateUtils';
import { useGetDepartments } from './departments.auth.graphql';
import { Add, Delete } from '@mui/icons-material';
import clsx from 'clsx';
import { useGetRoles } from '../../userRole/userRoleList/roles.auth.graphql';
import { useGetBrands } from './brands.auth.graphql';
import {
  emptyItem,
  onDepartmentsChange,
  onSave,
  onUserRoleAddition,
  onUserRoleDeletion,
  onUserRoleEdition,
  saveMandatoryFields,
} from './utils';

export const UserEditPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [item, setItem] = useState<GetUser_user | undefined>(undefined);
  const { id } = useParams();
  const isCreate = id === 'new';
  const { data, loading, error } = useGetUser(id, isCreate);
  const [saveLoading, setSaveLoading] = useState(false);
  const updateItem = useUpdateUser();
  const createItem = useCreateUser();
  const getDepartments = useGetDepartments();
  const getRoles = useGetRoles();
  const getBrands = useGetBrands();
  const filteredUserRoles = item?.userRoles.filter(
    (userRole) => !(userRole as any).toDelete,
  );
  const itemDiff = objectsAreDifferents(
    item,
    isCreate ? emptyItem : data?.user,
  );
  const requiredFields = getRequiredErrorMessage<GetUser_user>(
    item,
    saveMandatoryFields,
  );

  const fetchDepartments = (search: string) =>
    getDepartments({
      first: 100,
      where: { name_contains: search },
    }).then((res) =>
      res.departments.map((department) => ({
        id: department.id,
        label: department.name || '',
        secondaryLabel: department.brand.key,
        additionnals: { department },
      })),
    );

  const fetchRoles = (search: string) =>
    getRoles({
      first: 1000,
      where: { slug_contains: search },
    }).then((res) =>
      res.roles.map((role) => ({
        id: role.id,
        label: role.slug || '',
        additionnals: { role },
      })),
    );

  const fetchBrands = (search: string) =>
    getBrands({
      first: 1000,
      where: { key_contains: search },
    }).then((res) =>
      res.brands.map((brand) => ({
        id: brand.id,
        label: brand.key || '',
        additionnals: { brand },
      })),
    );

  useEffect(() => {
    setItem(isCreate ? emptyItem : data?.user || undefined);
  }, [isCreate, data, setItem]);

  return (
    <>
      <EditAppBar
        title={
          isCreate ? item?.name || 'Nouvel utilisateur' : data?.user?.name || ''
        }
        onNavigateBack={() => history.push(paths.USER_LIST)}
        save={{
          disabled: !itemDiff && !isCreate,
          loading: saveLoading,
          onClick: () => {
            onSave(
              item,
              isCreate,
              history,
              setSaveLoading,
              createItem,
              updateItem,
            );
          },
          requiredFields,
        }}
      />
      <PageWithDrawer
        loading={loading}
        error={
          error ||
          (!item && !data?.user
            ? Error("Cet utilisateur n'existe pas")
            : undefined)
        }
        rightDrawer={
          <>
            <NonEditableField
              className={classes.field}
              label="Id"
              value={item?.id}
            />
            <NonEditableField
              className={classes.field}
              label="Date de création"
              value={formatDate(item?.createdAt, 'dd/MM/yyyy à HH:mm')}
            />
          </>
        }
      >
        {item && (
          <>
            <TextField
              className={classes.field}
              label="Nom"
              onChange={({ target: { value } }) => {
                setItem((prev) => (prev ? { ...prev, name: value } : prev));
              }}
              value={item.name}
              variant="standard"
            />
            <TextField
              className={classes.field}
              label="Email"
              onChange={({ target: { value } }) => {
                setItem((prev) => (prev ? { ...prev, email: value } : prev));
              }}
              value={item.email}
              variant="standard"
            />
            <ChipsInput
              label="Départements rattachés"
              className={classes.field}
              value={item.departments
                .filter((dep) => !(dep as any).toDelete)
                .map(({ department }) => ({
                  id: department.id,
                  label: `${department.name} - ${department.brand.key}`,
                  additionnals: { department },
                }))}
              onChange={(value) => onDepartmentsChange(value, setItem)}
              autocomplete={{
                fetchList: fetchDepartments,
              }}
            />
            <div className={clsx(classes.field, classes.rolesWrapper)}>
              <Typography variant="subtitle1">
                Rôles de l'utilisateur
              </Typography>
              {!filteredUserRoles?.length && (
                <Typography variant="caption" className={classes.grey}>
                  Aucun
                </Typography>
              )}
              {filteredUserRoles?.map((userRole) => (
                <Card key={userRole.id} className={classes.roleCard}>
                  <AutocompleteSelect
                    label="Rôle"
                    className={classes.roleSelect}
                    value={
                      userRole.role
                        ? { id: userRole.role.id, label: userRole.role.slug }
                        : undefined
                    }
                    fetchList={fetchRoles}
                    onChange={(value) =>
                      onUserRoleEdition(
                        { role: value?.additionnals?.role },
                        userRole,
                        setItem,
                      )
                    }
                  />
                  <AutocompleteSelect
                    label="Marque"
                    className={classes.roleSelect}
                    value={
                      userRole.brand
                        ? {
                            id: userRole.brand.id,
                            label: userRole.brand.key,
                          }
                        : undefined
                    }
                    fetchList={fetchBrands}
                    onChange={(value) =>
                      onUserRoleEdition(
                        { brand: value?.additionnals?.brand },
                        userRole,
                        setItem,
                      )
                    }
                  />
                  <IconButton
                    color="primary"
                    className={classes.roleDeleteButton}
                    onClick={() => onUserRoleDeletion(userRole, setItem)}
                    size="large"
                  >
                    <Delete />
                  </IconButton>
                </Card>
              ))}
              <Button
                className={classes.addRoleButton}
                color="primary"
                variant="outlined"
                size="small"
                startIcon={<Add />}
                onClick={() => onUserRoleAddition(setItem)}
              >
                Ajouter un rôle
              </Button>
            </div>
          </>
        )}
      </PageWithDrawer>
    </>
  );
};
