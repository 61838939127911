import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { useDebounce, IconLabelButton } from '@prismamedia/one-components';
import React, {
  ChangeEvent,
  ComponentType,
  FC,
  ReactNode,
  useEffect,
  useState,
} from 'react';

interface DialogSearchProps {
  title?: string;
  additionalText?: string;
  searchFn: (search: string, offset: number) => Promise<any>;
  renderItem: (item: any) => ReactNode;
  Icon: ComponentType;
  tooltipText: string;
  onItemClick: (item: any) => void;
}

export const DialogSearch: FC<DialogSearchProps> = ({
  title,
  additionalText,
  tooltipText,
  renderItem,
  Icon,
  searchFn,
  onItemClick,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [items, setItems] = useState<[]>([]);
  const [value, setValue] = useState<string>('');
  const [noResults, setNoResults] = useState<boolean>(false);

  const debouncedSearch = useDebounce<string>(value, 500);

  useEffect(() => {
    if (!debouncedSearch) {
      return;
    }
    (async () => {
      setLoading(true);
      setNoResults(false);
      const result = await searchFn(debouncedSearch, 0);
      if (!result.length) {
        setNoResults(true);
      }
      setItems(result);
      setLoading(false);
    })();
  }, [debouncedSearch, searchFn]);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        fullWidth
        maxWidth="md"
      >
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            onChange={(ev: ChangeEvent<HTMLInputElement>) => {
              setValue(ev.currentTarget.value);
            }}
            placeholder="Rechercher"
            value={value}
            variant="standard"
          />
          {additionalText && (
            <DialogContentText>{additionalText}</DialogContentText>
          )}
          {loading ? (
            <CircularProgress />
          ) : noResults ? (
            <Typography>Aucun résultat</Typography>
          ) : (
            items.map((item, i) => {
              return (
                <div
                  key={i}
                  onClick={() => onItemClick(item)}
                  style={{ cursor: 'pointer' }}
                >
                  {renderItem(item)}
                </div>
              );
            })
          )}
        </DialogContent>
      </Dialog>
      <IconLabelButton label={tooltipText} onClick={() => setIsOpen(true)}>
        <Icon />
      </IconLabelButton>
    </>
  );
};
