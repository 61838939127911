import { Box, FormControl, TextField } from '@mui/material';
import { OneMedia } from '@prismamedia/one-components';
import type { FC, SetStateAction } from 'react';
import { memo } from 'react';
import { useParams } from 'react-router-dom';
import { TabType } from '../../../../../../../__generated__/queries-web';
import { MediaInput } from '../../../../../../../components/MediaInput';
import { MEDIA_TYPES } from '../../../../../../../components/MediaInput/constants';
import { useStyles as useSharedStyles } from '../../../styles';
import type { CategoryFormSectionUrlContent, SectionForm } from '../types';
import { BOARD_URL_FORM_EXTERNAL_LINK_LABEL } from './constants';
import { useEditUrl } from './useEditUrl';

export type UrlProps = SectionForm<CategoryFormSectionUrlContent, TabType.Url>;

export const Url: FC<UrlProps> = memo((sectionProps) => {
  const { brandKey } = useParams();
  const sharedClasses = useSharedStyles();

  const {
    content: urlContent,
    errors: urlErrors,
    handlers: urlHandlers,
  } = useEditUrl(sectionProps);

  const medias = urlContent[1] ? [urlContent[1]] : [];

  const setMedias = (updater: SetStateAction<OneMedia[]>) => {
    const newMedias = typeof updater === 'function' ? updater(medias) : updater;

    urlHandlers.handleContentMediaChange(newMedias);
  };

  return (
    <Box>
      <FormControl className={sharedClasses.wrapperField} fullWidth>
        <TextField
          {...(urlErrors?.[0]?.isTouched && {
            helperText: urlErrors[0].errorMessages[urlErrors[0].errors[0]],
          })}
          error={urlErrors?.[0]?.isTouched && urlErrors[0].hasError}
          label={BOARD_URL_FORM_EXTERNAL_LINK_LABEL}
          onChange={(event) => {
            urlHandlers.handleContentUrlChange(event.target.value);
          }}
          value={urlContent[0]}
          variant="standard"
        />
      </FormControl>

      <FormControl className={sharedClasses.wrapperMedias} fullWidth>
        <MediaInput
          medias={medias}
          setMedias={setMedias}
          brandKey={brandKey}
          allowedMedias={[MEDIA_TYPES.IMAGE]}
        />
      </FormControl>
    </Box>
  );
});
