import { pluralize } from '@prismamedia/one-components';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { pick } from 'lodash';
import type { GetPageCategoryHistory_categoryHistories } from '../../../../../../../__generated__/queries-web';
import { ALLOWED_CATEGORY_STATUES } from '../../../../../constants';

export const getHistoryInfos = (
  history: GetPageCategoryHistory_categoryHistories,
  nextHistory: GetPageCategoryHistory_categoryHistories | undefined,
): { label?: string; value?: string } => {
  if (!nextHistory) {
    return {
      label: 'Création de la page',
      value: format(new Date(history.createdAt), 'dd/MM/yyyy à HH:mm', {
        locale: fr,
      }),
    };
  } else if (history.status !== nextHistory.status) {
    return {
      label: 'Modification du status',
      value: ALLOWED_CATEGORY_STATUES.find(
        (category) => category.id === history.status,
      )?.label,
    };
  } else if (
    history.category.tabs?.length !== nextHistory.category.tabs?.length
  ) {
    if (history.category.tabs.length < nextHistory.category.tabs?.length) {
      const nbDeletedSections =
        history.category.tabs.length - nextHistory.category.tabs?.length;
      return {
        label: "Suppression d'un ou de plusieurs section(s)",
        value: `${nbDeletedSections} ${pluralize(
          'contenu',
          nbDeletedSections,
        )} ${pluralize('suprimé', nbDeletedSections)}`,
      };
    }

    const nbAddedSections =
      history.category.tabs.length - nextHistory.category.tabs?.length;
    return {
      label: "Ajout d'un ou de plusieurs contenu(s)",
      value: `${nbAddedSections} ${pluralize(
        'contenu',
        nbAddedSections,
      )} ${pluralize('ajouté', nbAddedSections)}`,
    };
  }

  const generalConfigurationKeyList = [
    'title',
    'tags',
    'urls',
    'description',
    'medias',
  ];
  const historyGeneralConfiguration = pick(
    history.category,
    generalConfigurationKeyList,
  );
  const nextHistoryGeneralConfiguration = pick(
    nextHistory.category,
    generalConfigurationKeyList,
  );

  if (
    JSON.stringify(historyGeneralConfiguration) !==
    JSON.stringify(nextHistoryGeneralConfiguration)
  ) {
    return { label: 'Modification de la configuration générale' };
  }

  const sectionsKeyList = ['tabs'];
  const historySections = pick(history.category, sectionsKeyList);
  const nextHistorySections = pick(nextHistory.category, sectionsKeyList);

  if (JSON.stringify(historySections) !== JSON.stringify(nextHistorySections)) {
    return { label: 'Modification des contenus' };
  }

  return {};
};
