import {
  GetPhotos_photos,
  MetadataNameEnumType,
} from '../../__generated__/queries-photo';

export const getMetadata = (
  metadataKey: MetadataNameEnumType,
  photo: Pick<GetPhotos_photos, 'photoMetadatas'> | undefined,
) => {
  return photo?.photoMetadatas?.find(
    ({ metadata }) => metadata.name === metadataKey,
  )?.value;
};
