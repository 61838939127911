export enum paths {
  ARTICLE_DUPLICATE = '/article/dup/:brandKey/:id',
  ARTICLE_EDIT = '/:brandKey/:articleType(article|video)/edit/:id?/:search?',
  ARTICLE_IA = '/ia/article',
  ARTICLE_LIST = '/article/list',
  ARTICLE_LIVE = '/:brandKey/:articleType(article|video)/live/:id?',
  ARTICLE_VIDEO = '/video/list',
  ATTRIBUTES_LIST = '/crud/attributes/list',
  ATTRIBUTE_NEW = '/crud/attribute/new/:brandKey',
  AUTHORS = '/authors/list',
  AUTHORS_EDIT = '/authors/edit/:id',
  CARD_EDIT = '/:brandKey/card/edit/:id?',
  CARD_LIST = '/:brandKey/card/list',
  CRUD = '/crud',
  DUPLICATE_FROM_STORY = '/:brandKey/:articleType(article)/:rawArticleId/version/:versionId',
  ERROR = '/error',
  LOGIN = '/login',
  MOBILE_PUSH = '/:brandKey/mobilepush/add',
  MOBILE_PUSH_LIST = '/mobilepush/list',
  ORPHAN_EDIT = '/orphanpage/edit/:brandKey/:id',
  ORPHAN_LIST = '/orphanpage/(list)?',
  ORPHAN_NEW = '/orphanpage/new/:brandKey',
  PAGE_CREATE = '/:brandKey/page/:format/create/:lvl1?/:lvl2?/:lvl3?/:lvl4?/:lvl5?',
  PAGE_EDIT = '/:brandKey/page/:format/edit/:lvl1?/:lvl2?/:lvl3?/:lvl4?/:lvl5?',
  PAGE_LIST = '/:brandKey/page/:format/list/:lvl1?/:lvl2?/:lvl3?/:lvl4?/:lvl5?',
  QUALIFIER_EDIT = '/crud/qualifier/:id',
  QUALIFIER_LIST = '/crud/qualifier',
  QUALIFIER_NEW = '/crud/qualifier/new',
  SEGMENT_EDIT = '/crud/segment/:id',
  SEGMENT_LIST = '/crud/segment',
  SEGMENT_NEW = '/crud/segment/new',
  SLIDESHOW_DUPLICATE = '/slideshow/dup/:brandKey/:id',
  SLIDESHOW_EDIT = '/slideshow/edit/:id',
  SLIDESHOW_LIST = '/slideshow/list',
  SLIDESHOW_NEW = '/slideshow/new/:brandKey',
  SLIDESHOW_IA = '/slideshow/ia/:activeTab(generate|complete|list)/:brandKey/:slideshowId?',
  USER_EDIT = '/crud/user/:id',
  USER_GROUP_EDIT = '/crud/googleGroup/:id',
  USER_GROUP_LIST = '/crud/googleGroup',
  USER_GROUP_NEW = '/crud/googleGroup/new',
  USER_LIST = '/crud/user',
  USER_NEW = '/crud/user/new',
  USER_ROLE_EDIT = '/crud/role/:id',
  USER_ROLE_LIST = '/crud/role',
  USER_ROLE_NEW = '/crud/role/new',
  DASHBOARD = '/dashboard',
}
