import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,

    '&:hover': {
      borderColor: theme.palette.common.white,
    },
  },
}));
