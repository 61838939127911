import { gql } from '@apollo/client';

export const ASSIGMENT_FRAGMENT = gql`
  fragment AssignmentFragment on Assignment {
    id
    subject {
      id
      title
    }
  }
`;

export const RAW_ARTICLE_VERSION_FRAGMENT = gql`
  fragment ArticleForm_RawArticleVersionFragment on RawArticleVersion {
    allowedStatuses
    id
    title
    lead
    body
    signature
    status
    createdAt
    editor {
      id
      name
      avatarUrl
    }
    textIsReadOnly
  }
`;
