import { useMediaQuery, useTheme } from '@mui/material';
import { GridSize } from '@mui/material/Grid';

export const useGetItemPerRow = () => {
  const theme = useTheme();
  const mdSize = useMediaQuery(theme.breakpoints.down('lg'));
  const smSize = useMediaQuery(theme.breakpoints.down('md'));

  const itemPerRow: GridSize = smSize ? 2 : mdSize ? 3 : 4;

  return itemPerRow;
};
