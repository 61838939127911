import { Collections, Description, Restaurant } from '@mui/icons-material';
import { MEDIA_TYPES_LABEL } from '@prismamedia/one-components';
import { BrandKey } from '../../../../../../../../__generated__/queries-web';

export const getAllowedMedias = (brandKey: BrandKey) => {
  const canAddRecipe = brandKey === BrandKey.FAC || brandKey === BrandKey.CAC;
  return [
    {
      Icon: Description,
      label: 'Article',
      type: MEDIA_TYPES_LABEL.ARTICLES,
    },
    ...(canAddRecipe
      ? [
          {
            Icon: Restaurant,
            label: 'Recette',
            type: MEDIA_TYPES_LABEL.RECIPES,
          },
        ]
      : []),
    {
      Icon: Collections,
      label: 'Diapo',
      type: MEDIA_TYPES_LABEL.SLIDESHOWS,
    },
  ];
};
