import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from '@mui/material';
import React, { ChangeEvent, FC } from 'react';
import {
  BrandKey,
  GetSegmentsByBrandKey_segments,
} from '../../../__generated__/queries-web';

export interface DecoratedSegment extends GetSegmentsByBrandKey_segments {
  checked: boolean;
}

interface SegmentListProps {
  segments: DecoratedSegment[];
  onChange: (segments: DecoratedSegment[]) => void;
  brandKey: BrandKey;
}

export const SegmentList: FC<SegmentListProps> = ({
  segments,
  brandKey,
  onChange,
}) => {
  const handleChange = (index: number) => (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const updatedSegment = {
      ...segments[index],
      checked: event.target.checked,
    };
    const newSegments = [
      ...segments.slice(0, index),
      updatedSegment,
      ...segments.slice(index + 1),
    ];
    onChange(newSegments);
  };

  return segments.length === 0 ? (
    <p>Pas de segments pour la marque {brandKey}</p>
  ) : (
    <FormControl variant="standard">
      <FormLabel component="legend">Segments</FormLabel>
      <FormGroup>
        {segments.map(({ id, title, checked }, index) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleChange(index)}
                value={title}
              />
            }
            label={title || ''}
            key={id}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};
