import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  imageFallback: {
    height: 210,
    maxWidth: '100%',
    maxHeight: '100%',
  },
  rootTileBar: {
    background: theme.palette.background.paper,
    minHeight: theme.spacing(9),
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: 0,
    whiteSpace: 'inherit',
  },
  gridItem: {
    listStyleType: 'none',
    position: 'relative',
    '&:after': {
      content: '""',
      display: 'block',
      paddingBottom: '100%',
    },
  },
  gridItemWrapper: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    'page-break-inside': 'avoid',
    position: 'relative',
    '@media print': {
      float: 'left',
      width: '100%',
    },
  },
  tile: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '3px',
    border: '2px solid transparent',
  },
  gridXs5: {
    maxWidth: '20%',
  },
  isFocus: {
    border: '2px solid red',
  },
  brandkey: {
    position: 'absolute',
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: '0 5px',
    top: 5,
    right: 5,
  },
  container: {
    width: '100%',
    boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24);',
    borderRadius: 2,
  },
}));
