import {
  FetchPolicy,
  gql,
  NetworkStatus,
  useApolloClient,
  useQuery,
} from '@apollo/client';
import { useCallback } from 'react';
import {
  SearchArticleCount,
  SearchArticleCountVariables,
  SearchArticles,
  SearchArticlesVariables,
} from '../../__generated__/queries-web';
import { ARTICLE_FRAGMENT } from '../fragments/article.web.graphql';

export const SEARCH_ARTICLES = gql`
  query SearchArticles(
    $where: ArticleSearchWhereInput
    $first: Int!
    $skip: Int!
    $orderBy: [ArticleSearchOrderByInput!]
  ) {
    searchArticles(
      first: $first
      where: $where
      skip: $skip
      orderBy: $orderBy
    ) {
      ...ArticleFragment
    }
  }
  ${ARTICLE_FRAGMENT}
`;

const SEARCH_ARTICLE_COUNT = gql`
  query SearchArticleCount($where: ArticleSearchWhereInput) {
    searchArticleCount(where: $where)
  }
`;

export const useSearchArticleCount = (
  variables: SearchArticleCountVariables,
  skip?: boolean,
) =>
  useQuery<SearchArticleCount, SearchArticleCountVariables>(
    SEARCH_ARTICLE_COUNT,
    { variables, skip },
  );

export const useSearchArticlesGetter = () => {
  const client = useApolloClient();

  return (variables: SearchArticlesVariables) =>
    client.query<SearchArticles, SearchArticlesVariables>({
      query: SEARCH_ARTICLES,
      variables,
    });
};

export const useSearchArticles = (
  variables: SearchArticlesVariables,
  skip?: boolean,
) => {
  const { data, ...rest } = useQuery<SearchArticles, SearchArticlesVariables>(
    SEARCH_ARTICLES,
    {
      notifyOnNetworkStatusChange: true,
      skip,
      variables,
    },
  );

  return {
    data: data?.searchArticles && { articles: data.searchArticles },
    ...rest,
  };
};

export const initialApolloState = {
  data: null,
  loading: false,
  stale: false,
  networkStatus: NetworkStatus.ready,
};

export const useSearchArticleCountGetter = () => {
  const client = useApolloClient();
  return useCallback(
    (
      variables: SearchArticleCountVariables,
      skip?: boolean,
      fetchPolicy: FetchPolicy = 'network-only',
    ) =>
      skip
        ? Promise.resolve(initialApolloState)
        : client.query<SearchArticleCount, SearchArticleCountVariables>({
            query: SEARCH_ARTICLE_COUNT,
            variables,
            fetchPolicy,
          }),
    [client],
  );
};
