import React, { FC, useState } from 'react';
import {
  TextField,
  Paper,
  List,
  Chip,
  Typography,
  InputAdornment,
} from '@mui/material';
import { match, trim } from 'ramda';
import { useStyles } from './styles';
import { SuggestItemType, AsyncSuggestion } from './types';
import { AsyncSuggest } from './AsyncSuggest';
import { SuggestItem } from './SuggestItem';
import { HighlightOffOutlined } from '@mui/icons-material';
import { flatten } from './utils';

interface SuggestProps {
  selectedItems: SuggestItemType[];
  suggestions: AsyncSuggestion;
  placeholder?: string;
  label?: string;
  multiple?: boolean;
  displayFirstLevel?: boolean;
  maxNumberSuggest?: number;
  enableFirstLevelClick?: boolean;
  defaultValue?: string;
  onChange: (items: SuggestItemType[]) => void;
  clearSelection?: () => void;
  sourceRequired?: boolean;
  credit?: string;
}

const _checkSeparator = (
  credit: string,
  searchedTitle: string,
  separator: string,
): boolean => {
  const regex = new RegExp(searchedTitle, 'i');
  let result = Boolean(credit.match(regex));
  if (result && credit.includes(separator)) {
    result = credit
      .split(separator)
      .map(trim)
      .some((part) => part.match(regex));
  }
  return result;
};

const getCreditInList = (
  list: SuggestItemType[],
  credit: string,
): SuggestItemType | undefined => {
  const separators = [':', '/', '|'];

  return list.find(({ title }: SuggestItemType) =>
    separators.some((separator) => _checkSeparator(credit, title, separator)),
  );
};

export const Suggest: FC<SuggestProps> = ({
  selectedItems,
  placeholder = '',
  label = '',
  multiple = false,
  suggestions,
  maxNumberSuggest = Infinity,
  onChange,
  displayFirstLevel = true,
  enableFirstLevelClick = true,
  clearSelection,
  defaultValue = '',
  credit = '',
  sourceRequired,
}) => {
  const classes = useStyles();
  const [isMenuOpen, setOpenMenu] = useState<boolean>(false);
  const [textInputValue, setTextInputValue] = useState<string>(defaultValue);

  React.useEffect(() => {
    if (defaultValue !== '' && defaultValue !== textInputValue) {
      setTextInputValue(defaultValue);
    } else if (defaultValue === '' && credit.length >= 3) {
      suggestions(defaultValue).then((data) => {
        const sourceItem = getCreditInList(data, credit);
        if (sourceItem) {
          handleChange(sourceItem);
        }
      });
    }
    // eslint-disable-next-line
  }, [defaultValue, credit]);

  const handleChange = (selectedItem: SuggestItemType) => {
    if (multiple && selectedItems.length + 1 > maxNumberSuggest) {
      return;
    }

    const newSelectedItems = multiple
      ? [...selectedItems, selectedItem]
      : [selectedItem];
    setTextInputValue(selectedItem.title);
    onChange(newSelectedItems);
  };

  const handleDelete = (itemToDelete?: SuggestItemType) => () => {
    setTextInputValue('');
    if (itemToDelete) {
      const newSelectedItems = selectedItems.filter(
        (item: SuggestItemType) => item.id !== itemToDelete.id,
      );
      onChange(newSelectedItems);
    }
  };

  return (
    <div>
      {multiple &&
        selectedItems.map((item: SuggestItemType) => (
          <Chip
            key={item.id}
            tabIndex={-1}
            label={item.title}
            className={classes.chip}
            onDelete={handleDelete(item)}
          />
        ))}
      <div style={{ position: 'relative' }}>
        <TextField
          data-testid="multimedia-source"
          fullWidth
          InputProps={{
            onBlur: () => {
              // We need the setTimeout in order to avoid warning
              setTimeout(() => setOpenMenu(false));
            },
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
              setTextInputValue(event.target.value);
            },
            onFocus: () => {
              setOpenMenu(true);
            },
            endAdornment: (
              <InputAdornment position="end">
                {clearSelection && textInputValue ? (
                  <HighlightOffOutlined
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setTextInputValue('');
                      clearSelection();
                    }}
                  />
                ) : (
                  <React.Fragment />
                )}
              </InputAdornment>
            ),
            placeholder,
          }}
          label={label}
          required={sourceRequired}
          value={textInputValue}
          variant="standard"
        />
        {isMenuOpen ? (
          <Paper square className={classes.paper}>
            <List>
              <AsyncSuggest
                suggestions={suggestions}
                searchValue={textInputValue}
              >
                {({ items, isLoading }) =>
                  isLoading ? (
                    <Typography data-testid="loading">Loading</Typography>
                  ) : (
                    <div data-testid="data-container">
                      {flatten(items)
                        .filter((item) => {
                          if (!item.level && displayFirstLevel) {
                            return true;
                          }
                          const result = match(
                            new RegExp(textInputValue, 'i'),
                            item.title,
                          );
                          return result.length;
                        })
                        .map((item: SuggestItemType) => (
                          <SuggestItem
                            isClickable={Boolean(
                              enableFirstLevelClick ||
                                (item.level && item.level > 1),
                            )}
                            key={item.id}
                            item={item}
                            onClick={handleChange}
                          />
                        ))}
                    </div>
                  )
                }
              </AsyncSuggest>
            </List>
          </Paper>
        ) : null}
      </div>
    </div>
  );
};
