import { Tab, Tabs, TextField, Typography } from '@mui/material';
import { NonEditableField, pluralize } from '@prismamedia/one-components';
import { Dispatch, FC, useCallback, useEffect, useState } from 'react';
import { GetArticle_article } from '../../../__generated__/queries-web';
import {
  mapImageSourceCategoriesToSuggestItems,
  useGetImageSourceCategories,
} from '../../../apollo/sharedQueries/getSourcesCategories.web.graphql';
import { SuggestItemType } from '../../../components/Suggest/types';
import { MediaBlock } from '../../../types/draft';
import { DrawerSmallGrid } from '../DrawerSmallGrid';
import { GeneralInformation } from '../GeneralInformation';
import { SlideSpecificInformation } from '../SlideSpecificationInformation';
import { SlideshowEditAction, actions } from '../reducer';
import { Suggest } from './Suggest';
import { useStyles } from './styles';

interface DrawerContentProps {
  categoryId: string;
  dispatch: Dispatch<SlideshowEditAction>;
  publishedAt: string;
  selectedSlides: MediaBlock[];
  article: GetArticle_article;
}

export const DrawerContent: FC<DrawerContentProps> = ({
  categoryId,
  dispatch,
  publishedAt,
  selectedSlides,
  article,
}) => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [currentItems, setItems] = useState<SuggestItemType[]>([]);
  const selectedSlidesLength = selectedSlides.length;
  const { data } = useGetImageSourceCategories();
  let sourceCallback;
  if (data?.imageSourceCategories?.length) {
    const result = mapImageSourceCategoriesToSuggestItems(
      data.imageSourceCategories,
    );
    sourceCallback = async () => result;
  }

  useEffect(() => {
    if (selectedSlidesLength > 0) {
      setTabIndex(1);
    } else {
      setTabIndex(0);
    }
  }, [selectedSlidesLength]);

  const setSlideSource = useCallback(
    (source: string | undefined) => {
      dispatch(
        actions.setSlidesSource({
          slideKeys: selectedSlides.map((slide) => slide.key),
          source,
        }),
      );
    },
    [selectedSlides, dispatch],
  );

  return (
    <div className={classes.wrapper}>
      <Typography variant="h6" className={classes.title}>
        Informations
      </Typography>
      <NonEditableField label="Marque" value={article.brandKey} />
      <Tabs
        value={tabIndex}
        onChange={(_, idx) => setTabIndex(idx)}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        className={classes.tabs}
      >
        <Tab label="Diaporama" />
        {!!selectedSlides.length && <Tab label="Sélection" />}
      </Tabs>
      <div className={classes.tabContent}>
        {tabIndex === 0 && (
          <GeneralInformation
            categoryId={categoryId}
            dispatch={dispatch}
            publishedAt={publishedAt}
            article={article}
          />
        )}
        {tabIndex === 1 && (
          <>
            <div>
              <Typography
                color="primary"
                className={classes.selectedImagesTitle}
              >
                {selectedSlides.length}{' '}
                {pluralize('image', selectedSlides.length)}{' '}
                {pluralize('sélectionnée', selectedSlides.length)}
              </Typography>
              <DrawerSmallGrid selectedSlides={selectedSlides} />
            </div>
            {selectedSlides.length === 1 && (
              <SlideSpecificInformation
                selectedSlide={selectedSlides[0]}
                dispatch={dispatch}
              />
            )}
            <TextField
              fullWidth
              label="Crédit"
              multiline
              key={selectedSlides.map((slide) => slide.key).join('-')}
              onChange={(event) => {
                dispatch(
                  actions.setSlidesCredit({
                    slideKeys: selectedSlides.map((slide) => slide.key),
                    credit: event.target.value,
                  }),
                );
              }}
              value={
                selectedSlides.length === 1
                  ? selectedSlides[0].data.credit ||
                    selectedSlides[0].data.iframely.meta?.credit
                  : undefined
              }
              variant="standard"
            />
            {sourceCallback && (
              <Suggest
                key={selectedSlides
                  .map((slide) => slide.data.iframely.meta.id)
                  .join('-')}
                defaultValue={
                  selectedSlides.length === 1
                    ? selectedSlides[0].data.iframely.meta?.source
                    : undefined
                }
                selectedItems={currentItems}
                suggestions={sourceCallback}
                label="Sources"
                multiple={false}
                enableFirstLevelClick={false}
                clearSelection={() => {
                  setItems([]);
                  setSlideSource(undefined);
                }}
                onChange={(items: SuggestItemType[]) => {
                  let current: SuggestItemType[] = [];
                  if (items.length) {
                    current = [items[0]];
                    setSlideSource(current[0].title);
                  }
                  setItems(current);
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
