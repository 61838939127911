import { gql, useQuery } from '@apollo/client';
import type {
  GetProvidersPhotos,
  GetProvidersPhotosVariables,
} from '../../__generated__/queries-web';

const GET_PROVIDERS_PHOTOS = gql`
  query GetProvidersPhotos(
    $query: String!
    $perPage: Int!
    $providers: [PhotoProvider!]!
    $currentPage: Int
  ) {
    searchPhotosByProviders(
      query: $query
      perPage: $perPage
      currentPage: $currentPage
      providers: $providers
    ) {
      name
      items {
        id
        medium
        large
        preview {
          height
          url
          width
        }
        small
      }
    }
  }
`;

export const useGetProvidersPhotos = (
  variables: GetProvidersPhotosVariables,
  skip = false,
) =>
  useQuery<GetProvidersPhotos, GetProvidersPhotosVariables>(
    GET_PROVIDERS_PHOTOS,
    {
      notifyOnNetworkStatusChange: true,
      skip,
      variables,
    },
  );
