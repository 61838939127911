import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import { MULTIMEDIA_MANAGER_TABS_WIDTH } from '@prismamedia/one-components';

const MULTIMEDIA_MANAGER_SEARCH_BLOCK_HEIGHT = 57;

interface OneStoryStyledProps {
  isOpen: boolean;
}
export const useStyles = makeStyles<Theme, OneStoryStyledProps>((theme) => ({
  topInnerWrapper: ({ isOpen }) => ({
    backgroundColor: theme.palette.grey[100],
    height: isOpen ? `${MULTIMEDIA_MANAGER_SEARCH_BLOCK_HEIGHT}px` : '0',
    overflow: 'hidden',
    position: 'absolute',
    width: `calc(100% - (${MULTIMEDIA_MANAGER_TABS_WIDTH}px + ${theme.spacing(
      2.25,
    )}))`,
    zIndex: 2,
  }),
  headerText: {
    display: 'block',
    left: 0,
    position: 'relative',
    paddingLeft: theme.spacing(3),
    top: theme.spacing(3),
  },
  wrapperList: {
    paddingTop: `calc(${MULTIMEDIA_MANAGER_SEARCH_BLOCK_HEIGHT}px - ${theme.spacing(
      2,
    )} + 1px)`,
    height: '100%',

    '&::after': {
      bottom: '0',
      boxShadow: 'inset 0px -15px 15px -11px rgb(0 0 0 / 5%)',
      content: '""',
      display: 'block',
      height: '50px',
      left: '0',
      position: 'absolute',
      pointerEvent: 'none',
      right: '0',
    },
  },
}));
