import {
  AdvancedSearchParams,
  formatArticleSearchBarInput,
} from '../../../utils/formatArticleSearchInput';
import {
  ArticleFormat,
  ArticleOrderByInput,
  ArticleStatus,
  BrandKey,
  GetArticlesVariables,
  SearchArticlesVariables,
} from '../../../__generated__/queries-web';
import { PAGINATION } from './';

export const NO_SOURCE_ID = 'NO_SOURCE_ID';

const orderByFromQueryStatus: Record<ArticleStatus, ArticleOrderByInput> = {
  [ArticleStatus.Deleted]: ArticleOrderByInput.publishedAt_DESC,
  [ArticleStatus.Draft]: ArticleOrderByInput.editedAt_DESC,
  [ArticleStatus.Frozen]: ArticleOrderByInput.editedAt_DESC,
  [ArticleStatus.Pending]: ArticleOrderByInput.publishedAt_DESC,
  [ArticleStatus.Proposal]: ArticleOrderByInput.editedAt_DESC,
  [ArticleStatus.Published]: ArticleOrderByInput.publishedAt_DESC,
  [ArticleStatus.Ready]: ArticleOrderByInput.editedAt_DESC,
  [ArticleStatus.Scheduled]: ArticleOrderByInput.publishedAt_ASC,
  [ArticleStatus.Todo]: ArticleOrderByInput.editedAt_DESC,
  [ArticleStatus.Private]: ArticleOrderByInput.editedAt_DESC,
};

export const getListPagesVariables = (
  articleFormat: ArticleFormat,
  searchParams: AdvancedSearchParams,
  status: ArticleStatus = ArticleStatus.Published,
  brandKey?: BrandKey,
): GetArticlesVariables | SearchArticlesVariables => {
  return {
    skip: 0,
    first: PAGINATION,
    orderBy: [
      status
        ? orderByFromQueryStatus[status]
        : ArticleOrderByInput.publishedAt_DESC,
    ],
    where: formatArticleSearchBarInput(
      articleFormat,
      searchParams,
      status,
      brandKey,
    ),
  };
};
