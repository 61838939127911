import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  GetRecipes,
  GetRecipesVariables,
  RecipeOrderByInput,
  RecipeStatus,
} from '../../__generated__/queries-recipe';
import {
  ArticleFormat,
  ArticleSearchOrderByInput,
  ArticleStatus,
  BrandKey,
  GetArticle_article_articleUrls,
  SearchArticles,
  SearchArticlesVariables,
} from '../../__generated__/queries-web';
import { GET_RECIPES } from '../../apollo/queries/recipes.recipe.graphql';
import { SEARCH_ARTICLES } from '../../apollo/queries/searchArticles.web.graphql';
import { assert } from '../../utils/assert';
import { getImageFromMedias } from '../../utils/media';
import { InternalLink, InternalLinkType } from './InternalLink';

const ITEMS_PER_PAGE = 100;

export const getArticleUrl = (
  articleUrls: GetArticle_article_articleUrls[],
): string => {
  if (articleUrls.length) {
    const [first] = articleUrls;
    let { domain } = first.url;
    if (
      domain.startsWith('www') &&
      !domain.endsWith('/') &&
      !first.url.path.startsWith('/')
    ) {
      domain = `${domain}/`;
    }
    return `https://${domain}${first.url.path}`;
  }
  return '';
};

export const baseRecipesURLs: Record<string, string> = {
  FAC: 'https://www.femmeactuelle.fr',
  CAC: 'https://www.cuisineactuelle.fr',
};

const getRecipes = async (
  client: ReturnType<typeof useApolloClient>,
  searchTerm: string,
  brandKey: BrandKey,
  type: InternalLinkType,
  page: number,
): Promise<InternalLink[]> => {
  if (page > 1) return [];

  // let id_in: string[] | undefined;

  // if (searchTerm) {
  //   const recommendRecipes = await getRecommendedRecipes(
  //     searchTerm,
  //     brandKey,
  //     100,
  //   );

  //   id_in = recommendRecipes?.map(({ idone }) => idone).filter(isDefined);
  // }

  const { data } = await client.query<GetRecipes, GetRecipesVariables>({
    query: GET_RECIPES,
    variables: {
      first: 100,
      where: {
        // id_in,
        ...(searchTerm ? { title_contains: searchTerm } : {}),
        status: RecipeStatus.Published,
        brandKey,
      },
      orderBy: [RecipeOrderByInput.publishedAt_DESC],
    },
  });

  // const recipes = id_in
  //   ? [...data.recipes].sort(({ id: idA }, { id: idB }) => {
  //       // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  //       const indexA = id_in!.indexOf(idA);
  //       // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  //       const indexB = id_in!.indexOf(idB);
  //       return indexA - indexB;
  //     })
  //   : data.recipes;

  const { recipes } = data;

  return recipes.map((recipe) => {
    return {
      id: recipe.id,
      title: recipe.title,
      type,
      imageSrc: getImageFromMedias(recipe.medias),
      brandKey,
      status: recipe.status,
      publishedAt: recipe.publishedAt || '',
      url:
        baseRecipesURLs[brandKey] +
        assert(recipe, ({ recipeUrls }) => recipeUrls[0].url?.path),
    };
  });
};

const getArticles = async (
  client: ReturnType<typeof useApolloClient>,
  searchTerm: string,
  brandKey: BrandKey,
  type: InternalLinkType,
  page: number,
): Promise<InternalLink[]> => {
  const { data } = await client.query<SearchArticles, SearchArticlesVariables>({
    query: SEARCH_ARTICLES,
    variables: {
      skip: (page - 1) * ITEMS_PER_PAGE,
      first: ITEMS_PER_PAGE,
      where: {
        ...(searchTerm ? { title_contains: searchTerm } : {}),
        format:
          type === InternalLinkType.ARTICLE
            ? ArticleFormat.Rich
            : ArticleFormat.Video,
        status: ArticleStatus.Published,
        brandKey,
      },
      orderBy: [ArticleSearchOrderByInput.publishedAt_DESC],
    },
  });

  return data?.searchArticles?.map((article) => {
    return {
      id: article.id,
      title: article.title || '',
      type,
      imageSrc: getImageFromMedias(article.medias),
      brandKey,
      status: article.status,
      publishedAt: article.publishedAt || '',
      url: getArticleUrl(article.articleUrls),
      medias: article.medias,
    };
  });
};

export const useInternalLinksGetter = () => {
  const client = useApolloClient();

  return useCallback(
    async (
      type: InternalLinkType,
      searchTerm: string,
      brandKey: BrandKey,
      page = 1,
    ): Promise<InternalLink[]> => {
      switch (type) {
        case InternalLinkType.RECIPE:
          return getRecipes(client, searchTerm, brandKey, type, page);
        case InternalLinkType.ARTICLE:
        case InternalLinkType.VIDEO:
          return getArticles(client, searchTerm, brandKey, type, page);
        default:
          return Promise.reject(`Unknown internal link type: ${type}`);
      }
    },
    [client],
  );
};
