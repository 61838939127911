import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

interface CollapseStyledProps {
  isPhotoLoading?: boolean;
  isOpen?: boolean;
}
export const useStyles = makeStyles<Theme, CollapseStyledProps>((theme) => ({
  collapseHeader: {
    borderBottom: `solid 1px ${theme.palette.grey[200]}`,
    padding: theme.spacing(1.5, 3),
  },
  headerLine: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 0,

    '& > span:first-child svg': {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.25),
      position: 'relative',
      top: '5px',
      zoom: 0.75,
    },
  },
  nbItemsBadge: ({ isPhotoLoading }) => ({
    marginRight: theme.spacing(1),
    paddingLeft: theme.spacing(isPhotoLoading ? 1 : 0.75),
  }),
  suggestText: {
    fontWeight: 500,
    color: theme.palette.common.black,
  },
  wrapperCollapse: ({ isOpen }) => ({
    ...(isOpen && {
      flex: '1 auto',

      '& > div': {
        height: '100%',
      },
    }),
  }),
}));
