import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
  fontTitle: {
    fontWeight: 'bold',
    fontSize: '1.3rem',
  },
  editItem: {
    '&.readOnly': {
      '& figcaption': { display: 'none' },
      '& .MuiPaper-elevation1': { boxShadow: 'none' },
      '& .megadraft-block': { padding: 0 },
    },
  },
  toolsContainer: {
    color: '#F44336;',
    justifyContent: 'flex-end',
    display: 'flex',
    marginTop: '16px',
  },
  button: {
    marginRight: theme.spacing(2),
  },
  inputs: {
    marginBottom: theme.spacing(3),
  },
  charCount: {
    display: 'grid',
    gridRowGap: 20,
    marginTop: '10px',
  },
  divider: { backgroundColor: '#949494', marginTop: '3px' },
  disabledText: { color: 'rgba(0, 0, 0, 0.38)', fontWeight: 'bold' },
}));
