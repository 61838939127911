import { Autocomplete, Box, Link, TextField } from '@mui/material';
import { useState } from 'react';
import { useSearchArticlesGetter } from '../../apollo/queries/searchArticles.web.graphql';
import {
  ArticleFormat,
  ArticleSearchOrderByInput,
  ArticleStatus,
  BrandKey,
} from '../../__generated__/queries-web';
import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import { generatePath } from 'react-router-dom';
import { paths } from '../../routing/Router/paths';
import { parseDraft } from '../../utils/draft';
import { SlideshowEditInformations } from './SlideshowCompleteForm';

export const SlideshowSelection = ({
  brandKey,
  selectedSlideshow,
  setSelectedSlideshow,
}: {
  brandKey: BrandKey;
  selectedSlideshow: SlideshowEditInformations | null;
  setSelectedSlideshow: React.Dispatch<
    React.SetStateAction<SlideshowEditInformations | null>
  >;
}) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<SlideshowEditInformations[]>([]);
  const searchSlideshow = useSearchArticlesGetter();

  const { pushNotification } = useNotification();

  let searchTimeout: NodeJS.Timeout | null = null;

  const handleInputChange = (
    event: React.ChangeEvent<unknown>,
    newInputValue: string,
  ) => {
    setInputValue(newInputValue);

    // Cancel previous search request
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Start a new search request after a delay
    searchTimeout = setTimeout(async () => {
      if (newInputValue.length >= 3) {
        try {
          const { data } = await searchSlideshow({
            first: 10,
            skip: 0,
            orderBy: [ArticleSearchOrderByInput.publishedAt_DESC],
            where: {
              title_contains: inputValue,
              format: ArticleFormat.Slideshow,
              status: ArticleStatus.Draft,
              brandKey,
            },
          });

          setOptions(
            (data.searchArticles
              .map((article) => {
                if (article.title && article.id && article.body) {
                  return {
                    title: article.title,
                    id: article.id,
                    body: article.body,
                  };
                }
                return null;
              })
              .filter(Boolean) as SlideshowEditInformations[]) || [],
          );
        } catch (error) {
          pushNotification({
            message:
              'Une erreur est survenue lors de la recherche des diaporamas',
            type: NotificationTypeEnum.error,
          });
        }
      } else {
        setOptions([]);
      }
    }, 300);
  };

  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={options}
        inputValue={inputValue}
        value={selectedSlideshow}
        onInputChange={handleInputChange}
        onChange={(
          event: React.ChangeEvent<unknown>,
          newValue: SlideshowEditInformations | null,
        ) => {
          setSelectedSlideshow(newValue);
        }}
        getOptionLabel={(option) => option.title}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Titre du diaporama"
            placeholder="Taper votre recherche"
          />
        )}
      />
      {selectedSlideshow?.id && (
        <Box
          sx={{
            mt: 3,
            display: 'flex',
            justifyContent: 'space-between;',
          }}
        >
          <Link
            href={`#${generatePath(paths.SLIDESHOW_EDIT, {
              id: selectedSlideshow.id,
            })}`}
            target="_blank"
          >
            Voir le diaporama
          </Link>
          <Box>
            Nombre de recettes actuellement:{' '}
            {
              parseDraft(selectedSlideshow.body).blocks.filter(
                (b: any) => b.type === 'atomic',
              ).length
            }
          </Box>
        </Box>
      )}
    </>
  );
};
