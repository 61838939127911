import {
  CardState,
  RequiredFields,
  ValidationActionType,
  CardEditActionTypes,
} from '../../types';

export const validateField = function (value: string) {
  if (!value || !value.trim()) {
    return false;
  }
  return true;
};

export const dispatchAllFieldsValidation = function (
  state: CardState,
  requiredFields: RequiredFields[],
  dispatch: React.Dispatch<ValidationActionType>,
) {
  requiredFields.forEach((fieldName) =>
    dispatchFieldValidation(dispatch, {
      fieldName,
      value: state[fieldName],
    }),
  );
};

export const dispatchFieldValidation = function (
  dispatch: React.Dispatch<ValidationActionType>,
  payload: ValidationActionType['payload'],
) {
  dispatch({
    type: CardEditActionTypes.VALIDATE_FIELD,
    payload,
  });
};
