import { lighten, Typography } from '@mui/material';
import { pluralize } from '@prismamedia/one-components';
import { FC } from 'react';
import { useStyles } from './styles';

interface GraphTooltipProps {
  active: boolean;
  payload: { stroke: string; name: string; value: number }[];
  label: string;
}

export const GraphTooltip: FC<GraphTooltipProps> = ({
  active,
  payload,
  label,
}) => {
  const classes = useStyles();

  if (active && payload?.length) {
    return (
      <div className={classes.wrapper}>
        <Typography>{label}</Typography>
        {payload
          .filter(({ value }) => !!value)
          .map(({ stroke, name, value }, i) => (
            <Typography
              key={i}
              variant="body2"
              sx={{ color: lighten(stroke, 0.4) }}
            >{`${value} ${pluralize(name, value)}`}</Typography>
          ))}
      </div>
    );
  }

  return null;
};
