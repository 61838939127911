import { gql } from '@apollo/client';

export const LIVE_POST_FRAGMENT = gql`
  fragment LivePostFragment on LivePost {
    id
    publishedAt
    title
    body
    live {
      id
      endedAt
    }
  }
`;

export const LIVE_FRAGMENT = gql`
  fragment LiveFragment on Live {
    id
    endedAt
    posts(first: 200, orderBy: [publishedAt_DESC]) {
      id
      publishedAt
      title
      body
    }
  }
`;
