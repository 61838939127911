export interface MobilePushFormState {
  title: string | null;
  message: string | null;
  android: boolean;
  ios: boolean;
  editorialAlert: boolean;
  datePicker: string | null;
  smartSegmentsChecked: boolean;
  smartSegmentNew: boolean;
  smartSegmentOneTime: boolean;
  smartSegmentEngaged: boolean;
  smartSegmentDormant: boolean;
}

export enum SmartSegment {
  NEW = 'NEW',
  ONE_TIME = 'ONE_TIME',
  ENGAGED = 'ENGAGED',
  DORMANT = 'DORMANT',
  IMPORTED = 'IMPORTED',
}

export enum Platform {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  EDITORIAL_ALERT = 'EDITORIAL_ALERT',
}
