import { gql, useMutation } from '@apollo/client';
import {
  DeleteLivePost,
  DeleteLivePostVariables,
} from '../../../__generated__/queries-web';

const DELETE_LIVE_POST = gql`
  mutation DeleteLivePost($where: LivePostWhereUniqueInput!) {
    deleteLivePost(where: $where) {
      id
    }
  }
`;

export const useLivePostDelete = (livePostId: string) =>
  useMutation<DeleteLivePost, DeleteLivePostVariables>(DELETE_LIVE_POST, {
    variables: {
      where: {
        id: livePostId,
      },
    },
  });
