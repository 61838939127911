import { FetchPolicy, gql, useApolloClient, useQuery } from '@apollo/client';
import { isDefined } from '@prismamedia/one-components';
import { useCallback, useEffect, useState } from 'react';
import {
  GetRecipes,
  GetRecipesVariables,
  RecipeCount,
  RecipeCountVariables,
} from '../../__generated__/queries-recipe';
import { getRecommendedRecipes } from '../../utils/recommendSearch';
import { DEFAULT_VOID_APOLLO_RESULT } from '../constants';
import { initialApolloState } from './searchArticles.web.graphql';

export const GET_RECIPES = gql`
  query GetRecipes(
    $where: RecipeWhereInput
    $orderBy: [RecipeOrderByInput!]
    $first: Int!
    $skip: Int
  ) {
    recipes(first: $first, where: $where, skip: $skip, orderBy: $orderBy) {
      brandKey
      createdAt
      id
      medias
      recipeUrls(first: 1, orderBy: position_ASC) {
        url {
          path
        }
      }
      publishedAt
      status
      title
      recipeCategories(first: 10, orderBy: position_ASC) {
        category {
          id
          title
        }
      }
      authorName
    }
  }
`;

export const useGetRecipes = (
  variables: GetRecipesVariables,
  skipQuery?: boolean,
) => {
  const [recommendLoading, setRecommendLoading] = useState(false);
  const [searchIds, setSearchIds] = useState<string[] | undefined>(undefined);
  const [recommendError, setRecommendError] = useState<Error | undefined>(
    undefined,
  );

  const title_contains = variables.where?.title_contains;
  const brandKey = variables.where?.brandKey;

  useEffect(() => {
    setRecommendError(undefined);

    if (!title_contains) {
      setSearchIds(undefined);
      setRecommendLoading(false);
      return;
    }

    setRecommendLoading(true);
    getRecommendedRecipes(title_contains, brandKey, 100)
      .then((recipes) =>
        setSearchIds(recipes?.map(({ idone }) => idone).filter(isDefined)),
      )
      .catch((e) => setRecommendError(e))
      .finally(() => setRecommendLoading(false));
  }, [brandKey, title_contains]);

  const { loading, error, ...rest } = useQuery<GetRecipes, GetRecipesVariables>(
    GET_RECIPES,
    {
      variables: {
        ...variables,
        where: {
          ...variables.where,
          id_in: searchIds,
          title_contains: undefined,
        },
      },
      skip: skipQuery,
      notifyOnNetworkStatusChange: true,
    },
  );

  return {
    ...rest,
    loading: loading || recommendLoading,
    error: error || recommendError,
  };
};

export const useRecipesGetter = () => {
  const client = useApolloClient();

  return async (variables: GetRecipesVariables, skip?: boolean) => {
    if (skip) return Promise.resolve(DEFAULT_VOID_APOLLO_RESULT);

    const title_contains = variables.where?.title_contains;
    const brandKey = variables.where?.brandKey;

    let id_in: string[] | undefined;

    if (title_contains) {
      const recommendRecipes = await getRecommendedRecipes(
        title_contains,
        brandKey,
        100,
      );

      id_in = recommendRecipes?.map(({ idone }) => idone).filter(isDefined);
    }

    return client.query<GetRecipes, GetRecipesVariables>({
      query: GET_RECIPES,
      variables: {
        ...variables,
        where: {
          ...variables.where,
          id_in,
          title_contains: undefined,
        },
      },
      fetchPolicy: 'network-only',
    });
  };
};

export const RECIPE_COUNT = gql`
  query RecipeCount($where: RecipeWhereInput) {
    recipeCount(where: $where)
  }
`;

export const useRecipeCount = (
  variables: RecipeCountVariables,
  skip?: boolean,
) =>
  useQuery<RecipeCount, RecipeCountVariables>(RECIPE_COUNT, {
    variables,
    skip,
  });

export const useRecipeCountGetter = () => {
  const client = useApolloClient();
  return useCallback(
    (
      variables: RecipeCountVariables,
      skip?: boolean,
      fetchPolicy: FetchPolicy = 'network-only',
    ) =>
      skip
        ? Promise.resolve(initialApolloState)
        : client.query<RecipeCount, RecipeCountVariables>({
            query: RECIPE_COUNT,
            variables,
            fetchPolicy,
          }),
    [client],
  );
};
