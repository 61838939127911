import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  textField: {
    '& > div': {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  paper: {
    position: 'absolute',
    zIndex: 10,
    width: '100%',
    maxHeight: 400,
    overflowY: 'scroll',
    top: 32,
  },
  suggestion: {
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
    padding: theme.spacing(0.7, 0.7, 0.7, 2),

    '& > div': {
      alignItems: 'center',
      display: 'flex',

      '& p': {
        color: theme.palette.grey[300],
      },

      '& svg': {
        margin: theme.spacing(0, 2),
        zoom: 0.5,
        fill: theme.palette.grey[400],
      },
    },

    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  suggestionlabel: {
    fontWeight: ({ isAncestor }: { isAncestor?: boolean } | void = {}) =>
      isAncestor
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
}));
