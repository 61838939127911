import React, { FC, ReactNode, useEffect, useState } from 'react';

import { AsyncSuggestion, SuggestItemType } from './types';
import { flatten } from './utils';

interface FlatSuggestProps {
  suggestions: SuggestItemType[] | AsyncSuggestion;
  searchValue: string;
  children: (items: SuggestItemType[]) => ReactNode;
}

export const FlatSuggest: FC<FlatSuggestProps> = ({
  suggestions,
  searchValue,
  children,
}) => {
  const [suggestItems, setSuggestItems] = useState<SuggestItemType[]>([]);

  useEffect(() => {
    async function getItems() {
      if (typeof suggestions === 'function') {
        const data = await suggestions();
        return setSuggestItems(flatten(data));
      }
      return setSuggestItems(flatten(suggestions));
    }
    getItems();
  }, [suggestions]);

  const getSuggestions = (value: string): SuggestItemType[] => {
    const filterCat = (cat: SuggestItemType) => {
      if (
        cat.title
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(
            value
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase(),
          )
      )
        return true;
    };
    return suggestItems.filter(filterCat);
  };

  return <>{children(getSuggestions(searchValue))}</>;
};
