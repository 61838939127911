import { Plugin } from '@prismamedia/one-components';
import {
  ContentState,
  convertFromHTML,
  DraftHandleValue,
  EditorState,
  Modifier,
} from 'draft-js';
import { MegadraftEditor } from 'megadraft';
import React from 'react';
import './megadraft.css';

interface customMap {
  CORRECTION: {
    color: string;
    backgroundColor: string;
  };
}
interface DraftEditorProps {
  editorState: EditorState;
  onChange: (e: EditorState) => void;
  plugins?: Plugin[];
  readOnly?: boolean;
  customStyleMap?: customMap;
}

export const DraftEditor: React.FC<DraftEditorProps> = ({
  editorState,
  onChange,
  ...rest
}) => (
  <MegadraftEditor
    {...rest}
    editorState={editorState}
    onChange={onChange}
    handlePastedText={(text: string, html?: string): DraftHandleValue => {
      if (!html) {
        return 'not-handled';
      }
      try {
        // remove img and links
        const cleanHtml = html.replace(/(<img[^>]*>)|(<a.*?>)|(<\/a>)/g, '');
        const blocksFromHTML = convertFromHTML(cleanHtml);
        const contentFromHtml = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        );
        const newContent = Modifier.replaceWithFragment(
          editorState.getCurrentContent(),
          editorState.getSelection(),
          contentFromHtml.getBlockMap(),
        );
        onChange(
          EditorState.push(editorState, newContent, 'insert-characters'),
        );
        return 'handled';
      } catch (e) {
        // unexpected behaviour, do nothing
        console.error(text, html, e);
        return 'handled';
      }
    }}
  />
);
