import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material';

interface FavoriteStyledProps {
  isLoading: boolean;
  isHeadlined: boolean;
}
export const useStyles = makeStyles<Theme, FavoriteStyledProps>((theme) => ({
  wrapper: ({ isHeadlined }) => ({
    color: !isHeadlined ? theme.palette.grey[500] : '#ffeb3b',
    // cursor: 'pointer',
    marginLeft: theme.spacing(1.75),
    position: 'relative',
    right: theme.spacing(-0.5),
    top: theme.spacing(0.1),

    // WAITING FOR API READY
    cursor: 'not-allowed',
    filter: 'grayscale(1)',
    opacity: 0.5,
    pointerEvents: 'none',
  }),
  starIcon: ({ isLoading }) => ({
    fontSize: '1.85rem',
    opacity: isLoading ? 0 : 1,
  }),
  loader: {
    position: 'absolute',
    left: '4px',
    top: '5px',
  },
}));
