import {
  TagProviderName,
  TagType,
} from '../../../../__generated__/queries-web';

export const ARTICLE_ENHANCEMENT_TAGS_TITLE = 'Tags';
export const NO_ARTICLE_TAG_SELECTED_TITLE = 'Aucun tag de selectionné';
export const ALLOWED_TAG_PROVIDERS = [
  TagProviderName.Company,
  TagProviderName.Destination,
  TagProviderName.Person,
  TagProviderName.Tag,
  TagProviderName.VideoTag,
];
export const ALLOWED_TAG_TYPES = [
  TagType.Company,
  TagType.Destination,
  TagType.Person,
  TagType.Tag,
  TagType.VideoTag,
];
export const TAG_CREATE_BUTTON_TEXT = 'Créer ce tag';
export const TAG_SUGGESTION_RESULT = 'Résultat(s)';
export const TAG_SEARCH_BAR_PLACEHOLDER = 'Rechercher un tag';
export const TAG_SEARCH_BAR_NO_RESULT_PLACEHOLDER =
  'Aucun tag ne correspond à votre saisie.';
export const TAGS_DEFAULT_SECTION_DATA_TESTID = 'default-tags-wrapper';
