import React from 'react';
import { convertToRaw, ContentState } from 'draft-js';
import { Description, InsertLink, People, Title } from '@mui/icons-material';
import { TabType } from '../../../../__generated__/queries-web';

export const SECTION_PLACEHOLDER_HEIGHT = 200;

export const EMPTY_RAW_DRAFT_CONTENT_STATE = convertToRaw(
  ContentState.createFromText(''),
);

export const ALLOWED_CATEGORY_FORM_SECTIONS = [
  {
    icon: <Description />,
    label: 'Contenu',
    type: TabType.Board,
  },
  {
    icon: <InsertLink />,
    label: 'Page externe',
    type: TabType.Url,
  },
  {
    icon: <People />,
    label: 'People',
    type: TabType.People,
  },
  {
    icon: <Title />,
    label: 'Texte',
    type: TabType.Text,
  },
];

export const GENERAL_CONFIG_LABEL = 'Configuration générale';
export const GENERAL_CONFIG_ANCHOR_HASH = 'general-configuration';

export const LIST_ANIMATION_DELAY = 100;
export const LIST_ANIMATION_DURATION = 250;
export const LIST_ENTER_ANIMATION = {
  from: { transform: 'translateX(100%)', opacity: '0.1' },
  to: { transform: 'translateX(0)', opacity: '1' },
};
