import axios from 'axios';
import { auth } from './auth';

export const getAxiosClient = async () => {
  const jwt = await auth.getJwt();
  if (!jwt) {
    throw Error('user token not found');
  }
  return axios.create({
    headers: { Authorization: `Bearer ${jwt}` },
  });
};
