import {
  ErrorMessages,
  DEFAULT_ERROR,
  getIsValidURLFormat,
} from '@prismamedia/one-components';
import { head, isNumber, last } from 'lodash';
import { TabType } from '../../../../../../__generated__/queries-web';
import {
  CATEGORY_SECTION_CONTENT_URL_PATTERN_ERROR_MESSAGE,
  CATEGORY_SECTION_TITLE_REQUIRED_ERROR_MESSAGE,
  DEFAULT_BOARD_SECTION_FORM_CONTENT,
  DEFAULT_PEOPLE_SECTION_FORM_CONTENT,
  DEFAULT_TEXT_SECTION_FORM_CONTENT,
  DEFAULT_URL_SECTION_FORM_CONTENT,
} from './constants';
import type { SectionErrors, SectionForm } from './types';

interface GetSectionFormErrorsParams {
  errors?: SectionErrors;
  form: SectionForm;
  path?: string[];
}
export const getSectionFormErrors = ({
  errors,
  form,
  path,
}: GetSectionFormErrorsParams) => {
  const isInitialization = !path && !errors;
  let newErrors = { ...errors };

  // Title check: string
  if (isInitialization || path?.[0] === 'title') {
    const hasRequiredRuleError = !form.title.length;
    const hasError = hasRequiredRuleError;
    const errorList = [
      ...((hasRequiredRuleError && [ErrorMessages.requiredRule]) || []),
    ];

    newErrors = {
      ...newErrors,
      title: {
        errorMessages: {
          [ErrorMessages.requiredRule]: CATEGORY_SECTION_TITLE_REQUIRED_ERROR_MESSAGE,
        },
        errors: errorList,
        hasError,
        isTouched: !isInitialization,
      },
    };
  }

  if (isInitialization || path?.[0] === 'content') {
    // URL sections content: [string, OneMedia?]
    if (form.type === TabType.Url) {
      // Link check
      if (isInitialization || path?.[1] === '0') {
        const hasPatternRuleError =
          !!form.content[0].length && !getIsValidURLFormat(form.content[0]);

        const hasError = hasPatternRuleError;
        const errorList = [
          ...((hasPatternRuleError && [ErrorMessages.patternRule]) || []),
        ];

        newErrors = {
          ...newErrors,
          content: (newErrors.content || [DEFAULT_ERROR])?.map(
            (contentError, index) =>
              index === 0
                ? {
                    errorMessages: {
                      [ErrorMessages.patternRule]: CATEGORY_SECTION_CONTENT_URL_PATTERN_ERROR_MESSAGE,
                    },
                    errors: errorList,
                    hasError,
                    isTouched: !isInitialization,
                  }
                : contentError,
          ),
        };
      }
    }
  }

  return newErrors as SectionErrors;
};

export const getSectionFormContent = (type: TabType) => {
  switch (type) {
    case TabType.Board:
      return DEFAULT_BOARD_SECTION_FORM_CONTENT;

    case TabType.People:
      return DEFAULT_PEOPLE_SECTION_FORM_CONTENT;

    case TabType.Text:
      return DEFAULT_TEXT_SECTION_FORM_CONTENT;

    case TabType.Url:
      return DEFAULT_URL_SECTION_FORM_CONTENT;
  }
};

export const getOrderFromList = (
  sections: { order: number; [key: string]: any }[],
  position: number | 'first' | 'last',
): number | undefined => {
  if (isNumber(position)) {
    return sections.at(position)?.order;
  }

  return position === 'first' ? head(sections)?.order : last(sections)?.order;
};
