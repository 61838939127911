import { alpha, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { combineStyles } from '../../../../../utils/styles/combineStyles';
import { link } from '../../../../../utils/styles/link';

const articleItemStyle = (theme: Theme) => ({
  articleItem: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    position: 'relative',
  },
  content: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  media: {
    height: 160,
    backgroundColor: theme.palette.grey[100],
    flexBasis: 250,
    flexShrink: 0,
  },
  event: {
    [theme.breakpoints.down('md')]: {
      fontSize: 8,
      bottom: 15,
    },
    position: 'absolute',
    left: 20,
    bottom: 40,
    fontWeight: 500,
    color: '#2e7d32',
  },
  category: {
    [theme.breakpoints.down('md')]: {
      fontSize: 8,
      bottom: 5,
    },
    position: 'absolute',
    left: 20,
    bottom: 20,
    fontWeight: 500,
    color: '#4a90e2',
  },
  mediasIcons: {
    position: 'absolute',
    right: 20,
    bottom: 20,
  },
  brandkey: {
    position: 'absolute',
    color: 'white',
    backgroundColor: alpha(theme.palette.common.black, 0.6),
    top: 5,
    left: 5,
    padding: '0 4px',
    zIndex: 1,
    borderRadius: 2,
  },
  header: {
    padding: '4px 4px 4px 0px',
    '& > div:first-of-type': {
      overflow: 'hidden',
    },
  },
  mobileLink: {
    display: 'block',
    width: '99.9%',
    textDecoration: 'none',
    color: theme.palette.common.black,
  },
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  pushIcon: {
    marginTop: '4px',
    marginRight: '-1px',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const enhancedLink = link({
  display: 'block',
  width: '99.9%',
  maxWidth: 1200,
  marginLeft: 'auto',
  marginRight: 'auto',
});

type withStyleProps =
  | keyof ReturnType<typeof articleItemStyle>
  | keyof ReturnType<ReturnType<typeof link>>;

export type ArticleItemStyleProps = Record<withStyleProps, string>;
const combinedStyles = combineStyles(articleItemStyle, enhancedLink);
export const useStyles = () => makeStyles(combinedStyles)();
