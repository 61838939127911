import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CategoryStatus } from '../../../../__generated__/queries-web';
import { CardSize } from '.';

export const LARGE_CARD_ITEM_HEIGHT = 125;
export const MEDIUM_CARD_ITEM_HEIGHT = 85;

interface CardStyledProps {
  size: CardSize;
  status: CategoryStatus | undefined;
  withTopTitle?: boolean;
}
export const useStyles = makeStyles<Theme, CardStyledProps>((theme) => ({
  empty: {
    color: theme.palette.grey[500],
  },
  imageFallback: {
    color: theme.palette.grey[300],
    fontSize: '4rem',
  },
  lockButton: ({ size }) => ({
    marginLeft: size === CardSize.LARGE ? theme.spacing(2) : theme.spacing(1),
    zIndex: 1,

    '& > button': {
      backgroundColor: theme.palette.primary.main,
      boxShadow: 'none',
      color: theme.palette.common.white,

      '&:hover': {
        boxShadow: 'none',
      },

      '& > span > div': {
        height: '32px',
        width: '32px',
      },

      '& svg': {
        fill: theme.palette.common.white,
      },
    },
  }),
  mainTitle: {
    '@supports (-webkit-line-clamp: 2)': {
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 2,
      display: '-webkit-box',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'initial',
    },
  },
  media: {
    backgroundColor: theme.palette.grey[200],
    flex: '1 auto',
    height: ({ size }) =>
      size === CardSize.LARGE
        ? LARGE_CARD_ITEM_HEIGHT
        : MEDIUM_CARD_ITEM_HEIGHT,
    overflow: 'hidden',
    width: '100%',
  },
  mediaOverlay: {
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, .6)',
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.white,
    display: 'flex',
    gap: theme.spacing(1),
    left: theme.spacing(1),
    padding: theme.spacing(0.35, 1),
    position: 'absolute',
    top: theme.spacing(1),
    zIndex: 1,

    '& > svg': {
      position: 'relative',
      top: '-.5px',
    },
  },
  innerWrapper: () => ({
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    position: 'relative',
    zIndex: 1,
    maxWidth: '100%',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  status: ({ status, withTopTitle }) => ({
    backgroundColor:
      status === CategoryStatus.Published
        ? `rgba(46, 125, 50, .1)`
        : status === CategoryStatus.Draft
        ? `rgba(2, 136, 209, .1)`
        : `rgba(66, 66, 66, .1)`,
    marginTop: theme.spacing(2),
    width: 'fit-content',
    ...(status === CategoryStatus.Published
      ? {
          borderColor: theme.palette.success.main,
          color: theme.palette.success.main,
        }
      : status === CategoryStatus.Draft
      ? { borderColor: theme.palette.info.main, color: theme.palette.info.main }
      : {
          borderColor: theme.palette.grey[800],
          color: theme.palette.grey[800],
        }),
    ...(!withTopTitle &&
      status && {
        position: 'absolute',
        bottom: theme.spacing(2.7),
      }),
  }),
  topTitle: {
    color: theme.palette.grey[500],
    marginBottom: theme.spacing(0.5),
    fontSize: ({ size }) =>
      size !== CardSize.LARGE ? theme.typography.caption.fontSize : 'inherit',
  },
  wrapper: ({ size }) => ({
    backgroundColor: theme.palette.common.white,
    border:
      size === CardSize.LARGE ? 'none' : `solid 1px ${theme.palette.grey[300]}`,
    boxShadow: 'none',
    height:
      size === CardSize.LARGE
        ? LARGE_CARD_ITEM_HEIGHT
        : MEDIUM_CARD_ITEM_HEIGHT,
    position: 'relative',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
  wrapperCenter: ({ size, withTopTitle }) => ({
    height:
      size === CardSize.LARGE
        ? LARGE_CARD_ITEM_HEIGHT
        : MEDIUM_CARD_ITEM_HEIGHT,
    flex: '1 auto',
    padding:
      size === CardSize.LARGE
        ? theme.spacing(1.5, 1.5, 1.5, 3)
        : theme.spacing(1, 1, 1, 2),

    '& > h2': {
      color: theme.palette.grey['800'],
      fontWeight: 500,
      fontSize: size === CardSize.LARGE ? 20 : 16,

      ...(!withTopTitle && {
        maxWidth: '65%',
        position: 'absolute',
      }),
    },
  }),
  wrapperLeftSide: ({ size }) => ({
    maxWidth:
      size !== CardSize.LARGE
        ? MEDIUM_CARD_ITEM_HEIGHT * 1.5
        : LARGE_CARD_ITEM_HEIGHT * 2,
    minWidth: size !== CardSize.LARGE ? MEDIUM_CARD_ITEM_HEIGHT * 1.5 : 140,
    minHeight:
      size === CardSize.LARGE
        ? LARGE_CARD_ITEM_HEIGHT
        : MEDIUM_CARD_ITEM_HEIGHT,
    position: 'relative',
    width: '25%',
  }),
  wrapperActionsIcons: {
    alignItems: 'center',
    color: theme.palette.grey[400],
    display: 'flex',
    position: 'absolute',
    right: theme.spacing(1.75),
    top: theme.spacing(1.75),

    '& span': {
      alignItems: 'center',
      display: 'flex',

      '& > p': {
        color: theme.palette.grey['500'],
        fontSize: '.85rem',
        marginLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.85),
      },

      '& svg': {
        fontSize: '1.75rem',
      },
    },
  },
  wrapperRightSide: ({ size }) => ({
    alignItems: size === CardSize.LARGE ? 'flex-end' : 'center',
    display: 'flex',
    padding: theme.spacing(2, 2, 2, 0),
  }),
}));
