import React, { FC } from 'react';
import { AutocompleteItem, ChipsInput } from '@prismamedia/one-components';
import { useGetCategories } from '../../apollo/sharedQueries/categories.query.web.graphql';
import {
  ArticleFormat,
  BrandKey,
  CategoryFormat,
  CategoryStatus,
} from '../../__generated__/queries-web';
import { spreadCategoryChildren } from './utils';
import { CATEGORIES_LABEL, CHANNELS_LABEL } from './constants';

const MIN_CATEGORY_LEVEL = 3;

interface CategoriesSearchProps {
  value?: AutocompleteItem[];
  onChange?: (value: AutocompleteItem[]) => void;
  className?: string;
  brandKey: BrandKey;
  articleFormat: ArticleFormat;
}

export const CategoriesSearch: FC<CategoriesSearchProps> = ({
  value,
  onChange,
  className,
  brandKey,
  articleFormat,
}) => {
  const getCategories = useGetCategories();

  const isVideo = articleFormat === ArticleFormat.Video;

  const fetchList = (search: string) =>
    getCategories({
      where: {
        title_contains: search,
        brandKey,
        format: isVideo ? CategoryFormat.Channel : CategoryFormat.Category,
        status: CategoryStatus.Published,
      },
    }).then(({ categories }) => {
      if (search) {
        return categories
          .filter(({ level }) => level >= MIN_CATEGORY_LEVEL)
          .map(({ id, title }) => ({
            id,
            label: title,
          }));
      }

      return spreadCategoryChildren(
        categories,
        categories.filter(({ level }) => level === MIN_CATEGORY_LEVEL),
      );
    });

  return (
    <ChipsInput
      showLevelIcons={true}
      label={isVideo ? CHANNELS_LABEL : CATEGORIES_LABEL}
      value={value}
      className={className}
      onChange={onChange}
      autocomplete={{ fetchList, selectChildren: true }}
    />
  );
};
