import { Add, ArrowBack } from '@mui/icons-material';
import { Fab, IconButton, Tooltip, Typography } from '@mui/material';
import { HomeAppBar, InfiniteTable } from '@prismamedia/one-components';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { QualifierOrderByInput } from '../../../__generated__/queries-web';
import { BrandSwitch } from '../../../components/BrandSwitch';
import { useQualifiers } from '../../../pages/ArticleEdit/DrawerContent/getQualifers.web.graphql';
import { replaceParams } from '../../../routing/Router';
import { paths } from '../../../routing/Router/paths';
import { auth } from '../../../utils/auth';
import { useGlobalBrandKey } from '../../../utils/globalState';
import { useQueryParams } from '../../../utils/useQueryParams';
import { useStyles } from './styles';

export const QualifierListPage: FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const [brandKey, setBrandKey] = useGlobalBrandKey();
  useQueryParams(true);

  const { data, loading, error } = useQualifiers({
    first: 1000,
    where: { brandKey },
    orderBy: [QualifierOrderByInput.createdAt_DESC],
  });

  return (
    <>
      <HomeAppBar
        appDrawerMenu={
          <IconButton
            className={classes.backIcon}
            onClick={() => history.push(paths.CRUD)}
            size="large"
          >
            <ArrowBack />
          </IconButton>
        }
        brandMenu={
          <>
            <Typography className={classes.title} variant="h6">
              Qualifiers
            </Typography>
            <BrandSwitch brandKey={brandKey} setBrandKey={setBrandKey} />
          </>
        }
        currentUser={auth.user}
        disconnectUser={auth.logout}
      />
      <div className={classes.listWrapper}>
        <InfiniteTable
          list={data?.qualifiers}
          loading={loading || !brandKey}
          error={error}
          columns={[
            {
              label: 'Titre',
              key: 'title',
              width: 200,
              flexGrow: 1,
            },
            {
              label: 'Marque',
              key: 'brandKey',
              width: 140,
            },
            {
              label: 'Statut',
              key: 'status',
              width: 140,
            },
            {
              label: 'Format',
              key: 'format',
              width: 140,
            },
          ]}
          onRowClick={(e, category) => {
            e.preventDefault();
            history.push(
              replaceParams(paths.QUALIFIER_EDIT, {
                id: category.id,
              }),
            );
          }}
        />
      </div>
      {brandKey && (
        <Tooltip title={`Créer un qualifier ${brandKey}`}>
          <Fab
            className={classes.newButton}
            color="primary"
            onClick={() => {
              history.push(replaceParams(paths.QUALIFIER_NEW, { brandKey }));
            }}
          >
            <Add />
          </Fab>
        </Tooltip>
      )}
    </>
  );
};
