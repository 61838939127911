import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface StylesProps {
  disableScroll: boolean;
}

export const useStyles = makeStyles<Theme, StylesProps>(() => ({
  wrapper: () => ({
    minHeight: '100%',
  }),
  menuWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 7,
  },
}));
