import Resizer from '@prismamedia/image-resizer-client';
import { Crop } from '../../types/draft';

export const resizer = new Resizer({
  protocol: 'https', // 'http', 'https', false (false means "protocol-relative")
  domain: (config && config.IMAGE_RESIZER_HOSTNAME) || 'one.img.pmdstatic.net',
  defaultParameters: { quality: 80 },
  defaultFormat: 'jpg', // 'jpg', 'gif', 'png'
});

export const getCropZoneParam = (
  crop: Crop | undefined,
): { 'crop-zone': string } | undefined => {
  if (!crop) return undefined;
  return { 'crop-zone': `${crop.x},${crop.y}-${crop.width}x${crop.height}` };
};
