import { BrandKey } from '@prismamedia/one-brandkey';
import { ArchiveKeyType } from '../../../../__generated__/queries-photo';

export type ONE_PHOTO_ARCHIVES =
  | ArchiveKeyType.PECO_WEB
  | ArchiveKeyType.TRV_WEB_GRAPHIX;

export const LAST_CREATED_PHOTOS_TEXT = 'LES DERNIÈRES PHOTOS CRÉEES';
export const SEARCH_BAR_PLACEHOLDER = 'Rechercher des images One Photos';

export const ARCHIVES_FILTER_PREMIUM: ONE_PHOTO_ARCHIVES[] = [
  ArchiveKeyType.TRV_WEB_GRAPHIX,
  ArchiveKeyType.PECO_WEB,
];

export const ARCHIVES_FILTER_DEFAULT: ONE_PHOTO_ARCHIVES[] = [
  ArchiveKeyType.TRV_WEB_GRAPHIX,
];

export const ARCHIVES_LABEL = {
  [ArchiveKeyType.TRV_WEB_GRAPHIX]: 'Graphics Sources',
  [ArchiveKeyType.PECO_WEB]: 'Base gratuite PECO',
};

export const BRANDS_PREMIUM_ACCESS_ARCHIVES = [
  BrandKey.BIN,
  BrandKey.CAP,
  BrandKey.CAM,
  BrandKey.GEO,
  BrandKey.NEO,
];
