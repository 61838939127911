import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridRowGap: 36,
  },
  root: {
    width: '15.5rem',
    marginTop: '4.1rem',
    paddingLeft: '0.4rem',
  },
  icon: {
    top: '50%',
    position: 'fixed',
    width: '26px',
    height: '26px',
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    color: 'black',
  },
  openIcon: {
    right: '1rem',
  },
  closeIcon: {
    right: '16rem',
  },
  children: {
    marginTop: '1rem',
  },
});
