import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  buttonCard: {
    padding: theme.spacing(2),
  },
  draft: {
    '& span': {
      fontSize: '16px',
    },
  },
}));
