import { EditAppBar } from '@prismamedia/one-components';
import { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { paths } from '../../routing/Router/paths';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ArticleGenerateForm } from './ArticleGoodDealGenerateForm';
import { ArticleGoodDealList } from './ArticleGoodDealList';

export const ArticleIA = ({ history }: RouteComponentProps) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Box
      sx={{
        backgroundColor: grey[200],
      }}
    >
      <EditAppBar
        onNavigateBack={() => {
          history.push(paths.ARTICLE_LIST);
        }}
        title={
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Typography variant="h6">
              I.A. Génération d'articles Bons Plans
            </Typography>
            <Box
              sx={{
                backgroundColor: '#f44a62',
                color: 'white',
                padding: '5px 12px 2px 12px',
                borderRadius: 1,
                ml: 3,
              }}
            >
              Bêta
            </Box>
          </Box>
        }
      />

      <Box
        sx={{
          padding: '80px 80px',
          width: '100%',
          minHeight: '900px',
        }}
      >
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => {
            setActiveTab(newValue);
          }}
          variant="fullWidth"
          sx={{
            backgroundColor: 'white',
          }}
        >
          <Tab label="Générer un nouveau bon plan" value={0} />
          <Tab label="Mes bons plans générés" value={1} />
        </Tabs>
        {(() => {
          if (activeTab === 0) {
            return <ArticleGenerateForm setActiveTab={setActiveTab} />;
          }
          return <ArticleGoodDealList />;
        })()}
      </Box>
    </Box>
  );
};
