import { Box, Card, CircularProgress, Link } from '@mui/material';
import { auth } from '../../utils/auth';
import { useEffect } from 'react';
import { generatePath } from 'react-router-dom';
import { paths } from '../../routing/Router/paths';
import { formatDate } from '../../utils/dateUtils';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {
  GetArticleGoodDealIaProcess,
  GetArticleGoodDealIaProcessVariables,
} from '../../__generated__/queries-web';
import { useQuery } from '@apollo/client';
import { GET_ARTICLE_GOOD_DEAL_PROCESS } from '../../apollo/queries/getArticleGoodDealAiProcess.web.graphql';

export const ArticleGoodDealList = () => {
  const userId = auth.user?.id || '';
  const { data, loading, refetch } = useQuery<
    GetArticleGoodDealIaProcess,
    GetArticleGoodDealIaProcessVariables
  >(GET_ARTICLE_GOOD_DEAL_PROCESS, {
    variables: {
      userId,
    },
    skip: !userId,
  });

  useEffect(() => {
    const isRunning = data?.articleGoodDealAiProcesses.some(
      (process) => process.status === 'running',
    );

    if (isRunning) {
      const intervalId = setInterval(() => {
        refetch();
      }, 10000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [data, refetch]);

  return (
    <Card
      variant="outlined"
      sx={{
        padding: '40px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)',
      }}
    >
      {loading && <Box>Chargement...</Box>}
      {data &&
        data.articleGoodDealAiProcesses.map((process) => {
          return (
            <Card
              key={process.id}
              sx={{
                padding: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '3px',
                      marginBottom: '5px',
                    }}
                  >
                    <AutoFixHighIcon fontSize="small" />
                    <Box
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      Date de création:
                    </Box>
                    <Box>
                      {formatDate(process.createdAt, 'dd/MM/yyyy à HH:mm')}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '3px',
                    }}
                  >
                    <Box
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      Statut:
                    </Box>

                    <Box>
                      {(() => {
                        if (process.status === 'success')
                          return (
                            <Box
                              sx={{
                                display: 'flex',
                                gap: '3px',
                              }}
                            >
                              <Box>Terminé </Box>
                              <CheckCircleOutlineIcon
                                fontSize="small"
                                sx={{ color: 'green' }}
                              />
                            </Box>
                          );
                        if (process.status === 'error')
                          return (
                            <Box
                              sx={{
                                display: 'flex',
                                gap: '3px',
                              }}
                            >
                              <Box>Erreur </Box>
                              <ErrorOutlineIcon
                                fontSize="small"
                                sx={{ color: 'red' }}
                              />
                            </Box>
                          );
                        if (process.status === 'running')
                          return (
                            <Box
                              sx={{
                                display: 'flex',
                                gap: '6px',
                              }}
                            >
                              <Box>En cours </Box>
                              <Box>
                                <CircularProgress size={15} />
                              </Box>
                            </Box>
                          );
                      })()}
                    </Box>
                  </Box>
                  {process.status === 'success' && process.article?.id && (
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '3px',
                        marginTop: '10px',
                      }}
                    >
                      <Link
                        href={`#${generatePath(paths.ARTICLE_EDIT, {
                          id: process.article.id,
                          articleType: 'article',
                          brandKey: 'CAP',
                        })}`}
                        target="_blank"
                      >
                        Voir l'article
                      </Link>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  marginTop: '20px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '3px',
                    }}
                  >
                    <Box
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      Temps de traitement:
                    </Box>
                    <Box>
                      {' '}
                      {(() => {
                        const createdAt: Date = new Date(process.createdAt);
                        const updatedAt: Date = new Date(process.updatedAt);
                        const time_difference_ms: number =
                          updatedAt.getTime() - createdAt.getTime();
                        const minutes: number = Math.floor(
                          time_difference_ms / (1000 * 60),
                        );
                        const seconds: number = Math.floor(
                          (time_difference_ms / 1000) % 60,
                        );
                        return `${
                          minutes ? `${minutes} minutes et ` : ''
                        } ${seconds} secondes`;
                      })()}
                    </Box>
                  </Box>
                </Box>
                {process.status === 'error' && (
                  <Box
                    sx={{
                      mt: '5px',
                      display: 'flex',
                      gap: '3px',
                    }}
                  >
                    <Box
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      Message d'ereur:
                    </Box>
                    <Box> {process.errorMsg || '---'}</Box>
                  </Box>
                )}
              </Box>
            </Card>
          );
        })}
    </Card>
  );
};
