import type {
  FormError,
  ItemSerialized,
  OneMedia,
} from '@prismamedia/one-components';
import type { RawDraftContentState } from 'draft-js';
import type { GetArticle_article_articleTags_tag } from '../../../../../../__generated__/queries-web';
import {
  GetPageCategories_categories_tabs,
  TabType,
} from '../../../../../../__generated__/queries-web';
import type { OmitMetaTypename } from '../../../../../../types/generic';
import { DEFAULT_CATEGORY_SECTION_ERROR } from './constants';

export enum CategoryFormSectionContentType {
  ARTICLE = 'article',
  RECIPE = 'recipe',
  SLIDESHOW = 'slideshow',
}

export type Section = (
  | { type: TabType.Board }
  | { type: TabType.People }
  | { type: TabType.Text }
  | { type: TabType.Url }
) &
  OmitMetaTypename<GetPageCategories_categories_tabs>;

/// CATEGORY SECTION FORM TYPES ////////////////////////////////////////////
export interface CategoryFormSectionBoardContent
  extends Omit<ItemSerialized, 'pluginType'> {
  id: string;
  order: number;
  type: CategoryFormSectionContentType;
}

export interface CategoryFormSectionPeopleContent
  extends GetArticle_article_articleTags_tag {
  fullName?: string;
  order?: number;
  person?: string;
  id: string;
  isActive?: boolean;
}

export interface CategoryFormSectionTextContent {
  body: RawDraftContentState;
  contentTitle: string;
}

export type CategoryFormSectionUrlContent = [string, OneMedia?];

export type SectionForm<C = void, T = void> = (C & T extends void
  ?
      | {
          content: CategoryFormSectionBoardContent[];
          type: TabType.Board;
        }
      | {
          content: CategoryFormSectionPeopleContent[];
          type: TabType.People;
        }
      | {
          content: CategoryFormSectionTextContent;
          type: TabType.Text;
        }
      | {
          content: CategoryFormSectionUrlContent;
          type: TabType.Url;
        }
  : { content: C; type: T }) & {
  createdAt?: GraphQL$DateTime;
  description: string;
  id: string;
  isActive: boolean;
  order: number;
  title: string;
  metas: string;
};

export type SectionErrors = typeof DEFAULT_CATEGORY_SECTION_ERROR & {
  content?: FormError[];
};
