import { gql } from '@apollo/client';
import { SEGMENT_FRAGMENT } from '../../../apollo/fragments/segment.web.graphql';

export const GET_SEGMENTS_BY_BRANDKEY = gql`
  query GetSegmentsByBrandKey(
    $first: Int!
    $where: SegmentWhereInput
    $orderBy: [SegmentOrderByInput!]
  ) {
    segments(first: $first, where: $where, orderBy: $orderBy) {
      ...SegmentFragment
    }
  }
  ${SEGMENT_FRAGMENT}
`;
