import React, { FC, ReactElement, useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  closestCenter,
  LayoutMeasuring,
  CollisionDetection,
  Modifiers,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
  SortingStrategy,
} from '@dnd-kit/sortable';
import { SortableItem } from './SortableItem';
import { useStyles } from './styles';

export type DragAndDropItem<T = any> = T & {
  id: string;
};
interface DragAndDropProps {
  dndContextProps?: {
    autoScroll?: boolean;
    collisionDetection?: CollisionDetection;
    layoutMeasuring?: LayoutMeasuring;
    modifiers?: Modifiers;
  };
  itemList?: DragAndDropItem[];
  onDragCancel?: { (): void };
  onDragEnd?: { (itemList: DragAndDropItem[]): void };
  onDragStart?: { (itemList: DragAndDropItem): void };
  renderItem: { (item: DragAndDropItem, index?: number): ReactElement };
  sortableContextProps?: {
    startegy?: SortingStrategy;
  };
  withPortalOverlay?: boolean;
}

export const DragAndDrop: FC<DragAndDropProps> = ({
  dndContextProps,
  itemList = [],
  onDragCancel,
  onDragEnd,
  onDragStart,
  renderItem,
  sortableContextProps,
  withPortalOverlay = true,
}) => {
  const [activeItem, setActiveItem] = useState<DragAndDropItem | null>(null);
  const classes = useStyles({});
  const defaultDndContextProps = useMemo(
    () => ({
      autoScroll: false,
      collisionDetection: closestCenter,
      zIndex: 666,
      ...dndContextProps,
    }),
    [dndContextProps],
  );
  const defaultSortableContextProps = useMemo(
    () => ({
      strategy: rectSortingStrategy,
      ...sortableContextProps,
    }),
    [sortableContextProps],
  );

  const handleDragCancel = useCallback((): void => {
    setActiveItem(null);
    onDragCancel?.();
  }, [onDragCancel]);

  const handleDragEnd = (
    event: DragEndEvent,
  ): DragAndDropItem[] | undefined => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = itemList.findIndex(({ id }) => id === active.id);
      const newIndex = itemList.findIndex(({ id }) => id === over.id);
      const newOrdoredList = arrayMove(itemList, oldIndex, newIndex);

      onDragEnd?.(newOrdoredList);

      return newOrdoredList;
    }

    setActiveItem(null);
  };

  const handleDragStart = (event: DragEndEvent): void => {
    const activeId = event.active.id;
    const selectedItem = itemList.find(
      (itemProps) => itemProps.id === activeId,
    );

    if (selectedItem) {
      setActiveItem(selectedItem);
      onDragStart?.(selectedItem);
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 0,
        tolerance: 10,
      },
    }),
  );

  return (
    <DndContext
      onDragCancel={handleDragCancel}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      sensors={sensors}
      {...defaultDndContextProps}
    >
      <SortableContext
        items={itemList.map(({ id }) => id)}
        {...defaultSortableContextProps}
      >
        {itemList.map((item, index) => (
          <SortableItem key={item.id} id={item.id}>
            {renderItem(item, index)}
          </SortableItem>
        ))}
      </SortableContext>

      {activeItem ? (
        withPortalOverlay ? (
          createPortal(
            <DragOverlay
              {...defaultDndContextProps}
              className={classes.wrapperOverlay}
              zIndex={defaultDndContextProps.zIndex + 1}
            >
              {renderItem(activeItem)}
            </DragOverlay>,
            document.body,
          )
        ) : (
          <DragOverlay
            {...defaultDndContextProps}
            className={classes.wrapperOverlay}
            zIndex={defaultDndContextProps.zIndex + 1}
          >
            {renderItem(activeItem)}
          </DragOverlay>
        )
      ) : null}
    </DndContext>
  );
};
