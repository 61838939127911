import { gql } from '@apollo/client';
import { CARD_FRAGMENT } from './card.web.graphql';

export const GET_CARDS = gql`
  ${CARD_FRAGMENT}
  query getCards(
    $first: Int!
    $where: CardWhereInput
    $orderBy: [CardOrderByInput!]
  ) {
    cards(first: $first, where: $where, orderBy: $orderBy) {
      ...cardFragment
    }
  }
`;
