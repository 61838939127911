import { BrandMenu } from '@prismamedia/one-components';
import { parse, stringify } from 'query-string';
import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useGetBrandKey } from '../../apollo/sharedQueries/getBrandKeys.web.graphql';

export const BrandSwitch: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const parsedSearch = parse(location.search);

  const switchBrand = (brandKey: string) => {
    const newLocation = {
      ...location,
      search: stringify({ ...parsedSearch, brand: brandKey }),
    };
    history.push(newLocation);
  };

  const { data: brandKeys } = useGetBrandKey();

  let defaultValue = '';
  if (brandKeys?.categories?.length === 1) {
    defaultValue = brandKeys.categories[0].brandKey;
  }

  if (!parsedSearch.brand && defaultValue) {
    switchBrand(defaultValue);
  }

  const currentBrand = parsedSearch.brand || defaultValue;
  if (!brandKeys) {
    return <></>;
  }
  return (
    <BrandMenu
      items={brandKeys.categories.map(({ id, brandKey }) => ({
        id,
        label: brandKey,
        selected: brandKey === currentBrand,
      }))}
      onItemSelect={({ label }) => switchBrand(label as string)}
    />
  );
};
