import { FetchResult } from '@apollo/client';
import {
  DeleteLivePost,
  UpdateLiveDates,
  UpdateLiveDates_updateLive,
  UpsertLivePost,
} from '../../__generated__/queries-web';

export const updateLivePostDates = async (
  mutate: () => Promise<FetchResult<UpdateLiveDates>>,
): Promise<UpdateLiveDates_updateLive | void> => {
  try {
    const result = await mutate();
    if (result.data && result.data.updateLive) {
      return result.data.updateLive;
    }
    console.warn("La mise à jour de l'article retourne un data vide");
  } catch (error) {
    console.error("Erreur durant la mise à jour d'un article", error);
  }
};

export const deletePostLive = async (
  mutate: () => Promise<FetchResult<DeleteLivePost>>,
) => {
  try {
    const result = await mutate();
    console.warn(`Le LivePost a été supprimé`);
    return result;
  } catch (error) {
    console.error("Erreur durant la suppression d'un LivePost", error);
  }
};

export const saveData = async (
  upsert: () => Promise<FetchResult<UpsertLivePost>>,
) => {
  try {
    const result = await upsert();
    if (result.data && result.data.upsertLivePost) {
      return result.data.upsertLivePost;
    }
    console.error("Erreur durant l'upsert des LivePosts");
  } catch (error) {
    console.error(error);
  }
};
