/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAffiliationCatalogProduct
// ====================================================

export interface CreateAffiliationCatalogProduct_createAffiliationCatalogProduct {
  __typename: "AffilizzCataglogProductCreation";
  productCatalogLocalizedId: string;
}

export interface CreateAffiliationCatalogProduct {
  /**
   * Affilizz search query
   */
  createAffiliationCatalogProduct: CreateAffiliationCatalogProduct_createAffiliationCatalogProduct;
}

export interface CreateAffiliationCatalogProductVariables {
  localizedProductId: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAffiliationCard
// ====================================================

export interface CreateAffiliationCard_createAffiliationCard_contents {
  __typename: "AffilizzCardContent";
  id: string;
}

export interface CreateAffiliationCard_createAffiliationCard {
  __typename: "AffilizzCard";
  id: string;
  name: string;
  contents: CreateAffiliationCard_createAffiliationCard_contents[];
}

export interface CreateAffiliationCard {
  /**
   * Affilizz search query
   */
  createAffiliationCard: CreateAffiliationCard_createAffiliationCard;
}

export interface CreateAffiliationCardVariables {
  localizedProductId: string;
  title: string;
  tag?: string | null;
  description?: string | null;
  positivePoints?: (string | null)[] | null;
  negativePoints?: (string | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertArticle
// ====================================================

export interface UpsertArticle_upsertArticle_live {
  __typename: "Live";
  /**
   * "LivePost.live"'s inverse relation count
   */
  postCount: number;
  endedAt: GraphQL$DateTime | null;
}

export interface UpsertArticle_upsertArticle_articleCategories_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface UpsertArticle_upsertArticle_articleCategories {
  __typename: "ArticleCategory";
  order: number;
  category: UpsertArticle_upsertArticle_articleCategories_category;
}

export interface UpsertArticle_upsertArticle_articleChannels_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface UpsertArticle_upsertArticle_articleChannels {
  __typename: "ArticleChannel";
  order: number;
  category: UpsertArticle_upsertArticle_articleChannels_category;
}

export interface UpsertArticle_upsertArticle_articleCreatorData {
  __typename: "ArticleCreatorData";
  name: string;
}

export interface UpsertArticle_upsertArticle_articleUrls_url {
  __typename: "Url";
  path: string;
  domain: string;
}

export interface UpsertArticle_upsertArticle_articleUrls {
  __typename: "ArticleUrl";
  url: UpsertArticle_upsertArticle_articleUrls_url;
}

export interface UpsertArticle_upsertArticle_articleQualifiers_qualifier {
  __typename: "Qualifier";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface UpsertArticle_upsertArticle_articleQualifiers {
  __typename: "ArticleQualifier";
  order: number;
  qualifier: UpsertArticle_upsertArticle_articleQualifiers_qualifier;
}

export interface UpsertArticle_upsertArticle_articleExtension_lockedBy {
  __typename: "User";
  id: string;
  name: string | null;
  email: string | null;
  avatarUrl: string | null;
}

export interface UpsertArticle_upsertArticle_articleExtension {
  __typename: "ArticleExtension";
  /**
   * The date of the article's last lock
   */
  lockedAt: GraphQL$DateTime | null;
  /**
   * The user currently locking the campaign
   */
  lockedBy: UpsertArticle_upsertArticle_articleExtension_lockedBy | null;
}

export interface UpsertArticle_upsertArticle_articleTags_tag {
  __typename: "Tag";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  relationId: string | null;
  title: string;
  type: TagType;
}

export interface UpsertArticle_upsertArticle_articleTags {
  __typename: "ArticleTag";
  order: number;
  tag: UpsertArticle_upsertArticle_articleTags_tag;
}

export interface UpsertArticle_upsertArticle_articleAuthors_author_user {
  __typename: "User";
  id: string;
  name: string | null;
  avatarUrl: string | null;
}

export interface UpsertArticle_upsertArticle_articleAuthors_author {
  __typename: "Author";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  name: string;
  user: UpsertArticle_upsertArticle_articleAuthors_author_user | null;
}

export interface UpsertArticle_upsertArticle_articleAuthors {
  __typename: "ArticleAuthor";
  order: number;
  author: UpsertArticle_upsertArticle_articleAuthors_author;
}

export interface UpsertArticle_upsertArticle_source {
  __typename: "Source";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  status: SourceStatus;
}

export interface UpsertArticle_upsertArticle_articleEvents_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface UpsertArticle_upsertArticle_articleEvents {
  __typename: "ArticleEvent";
  order: number;
  category: UpsertArticle_upsertArticle_articleEvents_category;
}

export interface UpsertArticle_upsertArticle_articleGuides_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface UpsertArticle_upsertArticle_articleGuides {
  __typename: "ArticleGuide";
  order: number;
  category: UpsertArticle_upsertArticle_articleGuides_category;
}

export interface UpsertArticle_upsertArticle_articleMachineTags_machineTag {
  __typename: "MachineTag";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  tag: string;
}

export interface UpsertArticle_upsertArticle_articleMachineTags {
  __typename: "ArticleMachineTag";
  order: number;
  machineTag: UpsertArticle_upsertArticle_articleMachineTags_machineTag;
}

export interface UpsertArticle_upsertArticle_parentArticle_article {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  brandKey: BrandKey;
}

export interface UpsertArticle_upsertArticle_parentArticle {
  __typename: "ArticleHistory";
  article: UpsertArticle_upsertArticle_parentArticle_article;
}

export interface UpsertArticle_upsertArticle_articleHistories_user {
  __typename: "User";
  id: string;
  name: string | null;
  avatarUrl: string | null;
}

export interface UpsertArticle_upsertArticle_articleHistories_article {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  /**
   * Defines whether an article is confidential or not
   */
  isClassified: boolean | null;
}

export interface UpsertArticle_upsertArticle_articleHistories_duplicatedArticle {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  brandKey: BrandKey;
}

export interface UpsertArticle_upsertArticle_articleHistories {
  __typename: "ArticleHistory";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  user: UpsertArticle_upsertArticle_articleHistories_user | null;
  status: ArticleStatus;
  article: UpsertArticle_upsertArticle_articleHistories_article;
  /**
   * This relation is used to keep track of article duplications
   */
  duplicatedArticle: UpsertArticle_upsertArticle_articleHistories_duplicatedArticle | null;
}

export interface UpsertArticle_upsertArticle {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  status: ArticleStatus;
  body: string | null;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  publishedAt: GraphQL$DateTime | null;
  editedAt: GraphQL$DateTime | null;
  brandKey: BrandKey;
  format: ArticleFormat;
  medias: string | null;
  /**
   * "Push.article"'s inverse relation count
   */
  pushCount: number;
  /**
   * "Live.article"'s inverse relation
   */
  live: UpsertArticle_upsertArticle_live | null;
  /**
   * "ArticleCategory.article"'s inverse relation
   */
  articleCategories: UpsertArticle_upsertArticle_articleCategories[];
  /**
   * "ArticleChannel.article"'s inverse relation
   */
  articleChannels: UpsertArticle_upsertArticle_articleChannels[];
  /**
   * "ArticleCreatorData.article"'s inverse relation
   */
  articleCreatorData: UpsertArticle_upsertArticle_articleCreatorData | null;
  /**
   * "ArticleUrl.article"'s inverse relation
   */
  articleUrls: UpsertArticle_upsertArticle_articleUrls[];
  /**
   * "ArticleQualifier.article"'s inverse relation
   */
  articleQualifiers: UpsertArticle_upsertArticle_articleQualifiers[];
  /**
   * "ArticleExtension.article"'s inverse relation
   */
  articleExtension: UpsertArticle_upsertArticle_articleExtension | null;
  /**
   * The proper article url based on the user device and the server environment
   */
  contextualizedUrl: string | null;
  secondaryTitle: string | null;
  partnerTitle: string | null;
  lead: string | null;
  slug: string | null;
  /**
   * "ArticleTag.article"'s inverse relation
   */
  articleTags: UpsertArticle_upsertArticle_articleTags[];
  /**
   * "ArticleAuthor.article"'s inverse relation
   */
  articleAuthors: UpsertArticle_upsertArticle_articleAuthors[];
  source: UpsertArticle_upsertArticle_source | null;
  /**
   * "ArticleEvent.article"'s inverse relation
   */
  articleEvents: UpsertArticle_upsertArticle_articleEvents[];
  /**
   * "ArticleGuide.article"'s inverse relation
   */
  articleGuides: UpsertArticle_upsertArticle_articleGuides[];
  /**
   * "ArticleMachineTag.article"'s inverse relation
   */
  articleMachineTags: UpsertArticle_upsertArticle_articleMachineTags[];
  /**
   * "ArticleHistory.duplicatedArticle"'s inverse relation
   */
  parentArticle: UpsertArticle_upsertArticle_parentArticle[];
  /**
   * "ArticleHistory.article"'s inverse relation
   */
  articleHistories: UpsertArticle_upsertArticle_articleHistories[];
  platformId: string | null;
}

export interface UpsertArticle {
  /**
   * Update or create a single "Article" node.
   */
  upsertArticle: UpsertArticle_upsertArticle;
}

export interface UpsertArticleVariables {
  create: ArticleCreateInput;
  update: ArticleUpdateInput;
  where: ArticleWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateArticle
// ====================================================

export interface UpdateArticle_updateArticle_live {
  __typename: "Live";
  /**
   * "LivePost.live"'s inverse relation count
   */
  postCount: number;
  endedAt: GraphQL$DateTime | null;
}

export interface UpdateArticle_updateArticle_articleCategories_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface UpdateArticle_updateArticle_articleCategories {
  __typename: "ArticleCategory";
  order: number;
  category: UpdateArticle_updateArticle_articleCategories_category;
}

export interface UpdateArticle_updateArticle_articleChannels_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface UpdateArticle_updateArticle_articleChannels {
  __typename: "ArticleChannel";
  order: number;
  category: UpdateArticle_updateArticle_articleChannels_category;
}

export interface UpdateArticle_updateArticle_articleCreatorData {
  __typename: "ArticleCreatorData";
  name: string;
}

export interface UpdateArticle_updateArticle_articleUrls_url {
  __typename: "Url";
  path: string;
  domain: string;
}

export interface UpdateArticle_updateArticle_articleUrls {
  __typename: "ArticleUrl";
  url: UpdateArticle_updateArticle_articleUrls_url;
}

export interface UpdateArticle_updateArticle_articleQualifiers_qualifier {
  __typename: "Qualifier";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface UpdateArticle_updateArticle_articleQualifiers {
  __typename: "ArticleQualifier";
  order: number;
  qualifier: UpdateArticle_updateArticle_articleQualifiers_qualifier;
}

export interface UpdateArticle_updateArticle_articleExtension_lockedBy {
  __typename: "User";
  id: string;
  name: string | null;
  email: string | null;
  avatarUrl: string | null;
}

export interface UpdateArticle_updateArticle_articleExtension {
  __typename: "ArticleExtension";
  /**
   * The date of the article's last lock
   */
  lockedAt: GraphQL$DateTime | null;
  /**
   * The user currently locking the campaign
   */
  lockedBy: UpdateArticle_updateArticle_articleExtension_lockedBy | null;
}

export interface UpdateArticle_updateArticle_articleTags_tag {
  __typename: "Tag";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  relationId: string | null;
  title: string;
  type: TagType;
}

export interface UpdateArticle_updateArticle_articleTags {
  __typename: "ArticleTag";
  order: number;
  tag: UpdateArticle_updateArticle_articleTags_tag;
}

export interface UpdateArticle_updateArticle_articleAuthors_author_user {
  __typename: "User";
  id: string;
  name: string | null;
  avatarUrl: string | null;
}

export interface UpdateArticle_updateArticle_articleAuthors_author {
  __typename: "Author";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  name: string;
  user: UpdateArticle_updateArticle_articleAuthors_author_user | null;
}

export interface UpdateArticle_updateArticle_articleAuthors {
  __typename: "ArticleAuthor";
  order: number;
  author: UpdateArticle_updateArticle_articleAuthors_author;
}

export interface UpdateArticle_updateArticle_source {
  __typename: "Source";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  status: SourceStatus;
}

export interface UpdateArticle_updateArticle_articleEvents_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface UpdateArticle_updateArticle_articleEvents {
  __typename: "ArticleEvent";
  order: number;
  category: UpdateArticle_updateArticle_articleEvents_category;
}

export interface UpdateArticle_updateArticle_articleGuides_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface UpdateArticle_updateArticle_articleGuides {
  __typename: "ArticleGuide";
  order: number;
  category: UpdateArticle_updateArticle_articleGuides_category;
}

export interface UpdateArticle_updateArticle_articleMachineTags_machineTag {
  __typename: "MachineTag";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  tag: string;
}

export interface UpdateArticle_updateArticle_articleMachineTags {
  __typename: "ArticleMachineTag";
  order: number;
  machineTag: UpdateArticle_updateArticle_articleMachineTags_machineTag;
}

export interface UpdateArticle_updateArticle_parentArticle_article {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  brandKey: BrandKey;
}

export interface UpdateArticle_updateArticle_parentArticle {
  __typename: "ArticleHistory";
  article: UpdateArticle_updateArticle_parentArticle_article;
}

export interface UpdateArticle_updateArticle_articleHistories_user {
  __typename: "User";
  id: string;
  name: string | null;
  avatarUrl: string | null;
}

export interface UpdateArticle_updateArticle_articleHistories_article {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  /**
   * Defines whether an article is confidential or not
   */
  isClassified: boolean | null;
}

export interface UpdateArticle_updateArticle_articleHistories_duplicatedArticle {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  brandKey: BrandKey;
}

export interface UpdateArticle_updateArticle_articleHistories {
  __typename: "ArticleHistory";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  user: UpdateArticle_updateArticle_articleHistories_user | null;
  status: ArticleStatus;
  article: UpdateArticle_updateArticle_articleHistories_article;
  /**
   * This relation is used to keep track of article duplications
   */
  duplicatedArticle: UpdateArticle_updateArticle_articleHistories_duplicatedArticle | null;
}

export interface UpdateArticle_updateArticle {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  status: ArticleStatus;
  body: string | null;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  publishedAt: GraphQL$DateTime | null;
  editedAt: GraphQL$DateTime | null;
  brandKey: BrandKey;
  format: ArticleFormat;
  medias: string | null;
  /**
   * "Push.article"'s inverse relation count
   */
  pushCount: number;
  /**
   * "Live.article"'s inverse relation
   */
  live: UpdateArticle_updateArticle_live | null;
  /**
   * "ArticleCategory.article"'s inverse relation
   */
  articleCategories: UpdateArticle_updateArticle_articleCategories[];
  /**
   * "ArticleChannel.article"'s inverse relation
   */
  articleChannels: UpdateArticle_updateArticle_articleChannels[];
  /**
   * "ArticleCreatorData.article"'s inverse relation
   */
  articleCreatorData: UpdateArticle_updateArticle_articleCreatorData | null;
  /**
   * "ArticleUrl.article"'s inverse relation
   */
  articleUrls: UpdateArticle_updateArticle_articleUrls[];
  /**
   * "ArticleQualifier.article"'s inverse relation
   */
  articleQualifiers: UpdateArticle_updateArticle_articleQualifiers[];
  /**
   * "ArticleExtension.article"'s inverse relation
   */
  articleExtension: UpdateArticle_updateArticle_articleExtension | null;
  /**
   * The proper article url based on the user device and the server environment
   */
  contextualizedUrl: string | null;
  secondaryTitle: string | null;
  partnerTitle: string | null;
  lead: string | null;
  slug: string | null;
  /**
   * "ArticleTag.article"'s inverse relation
   */
  articleTags: UpdateArticle_updateArticle_articleTags[];
  /**
   * "ArticleAuthor.article"'s inverse relation
   */
  articleAuthors: UpdateArticle_updateArticle_articleAuthors[];
  source: UpdateArticle_updateArticle_source | null;
  /**
   * "ArticleEvent.article"'s inverse relation
   */
  articleEvents: UpdateArticle_updateArticle_articleEvents[];
  /**
   * "ArticleGuide.article"'s inverse relation
   */
  articleGuides: UpdateArticle_updateArticle_articleGuides[];
  /**
   * "ArticleMachineTag.article"'s inverse relation
   */
  articleMachineTags: UpdateArticle_updateArticle_articleMachineTags[];
  /**
   * "ArticleHistory.duplicatedArticle"'s inverse relation
   */
  parentArticle: UpdateArticle_updateArticle_parentArticle[];
  /**
   * "ArticleHistory.article"'s inverse relation
   */
  articleHistories: UpdateArticle_updateArticle_articleHistories[];
  platformId: string | null;
}

export interface UpdateArticle {
  /**
   * Update a single "Article" node.
   */
  updateArticle: UpdateArticle_updateArticle | null;
}

export interface UpdateArticleVariables {
  where: ArticleWhereUniqueInput;
  data: ArticleUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGoodDealPrompts
// ====================================================

export interface UpdateGoodDealPrompts_updateArticleGoodDealAIPrompt {
  __typename: "ArticleGoodDealAIPrompt";
  /**
   * The public ID, an UUID v4
   */
  id: string | null;
}

export interface UpdateGoodDealPrompts {
  /**
   * Update a single "ArticleGoodDealAIPrompt" node.
   */
  updateArticleGoodDealAIPrompt: UpdateGoodDealPrompts_updateArticleGoodDealAIPrompt | null;
}

export interface UpdateGoodDealPromptsVariables {
  where: ArticleGoodDealAIPromptWhereUniqueInput;
  data: ArticleGoodDealAIPromptUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateArticleHistory
// ====================================================

export interface CreateArticleHistory_createArticleHistory {
  __typename: "ArticleHistory";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface CreateArticleHistory {
  /**
   * Create a single "ArticleHistory" node.
   */
  createArticleHistory: CreateArticleHistory_createArticleHistory;
}

export interface CreateArticleHistoryVariables {
  data: ArticleHistoryCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAuthor
// ====================================================

export interface UpdateAuthor_updateAuthor_authorHistories_user {
  __typename: "User";
  id: string;
  name: string | null;
  avatarUrl: string | null;
}

export interface UpdateAuthor_updateAuthor_authorHistories {
  __typename: "AuthorHistory";
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  user: UpdateAuthor_updateAuthor_authorHistories_user | null;
}

export interface UpdateAuthor_updateAuthor_authorAttributes_attribute {
  __typename: "Attribute";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface UpdateAuthor_updateAuthor_authorAttributes {
  __typename: "AuthorAttribute";
  order: number;
  attribute: UpdateAuthor_updateAuthor_authorAttributes_attribute;
}

export interface UpdateAuthor_updateAuthor {
  __typename: "Author";
  avatarUrl: string | null;
  brandKey: BrandKey;
  description: string | null;
  facebookId: string | null;
  googleId: string | null;
  hasPublicProfile: boolean | null;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  instagramId: string | null;
  isMain: boolean | null;
  linkedinId: string | null;
  media: string | null;
  name: string;
  pinterestId: string | null;
  snapshatId: string | null;
  twitterId: string | null;
  userId: string | null;
  resume: string | null;
  email: string | null;
  /**
   * "AuthorHistory.author"'s inverse relation
   */
  authorHistories: UpdateAuthor_updateAuthor_authorHistories[];
  /**
   * "AuthorAttribute.author"'s inverse relation
   */
  authorAttributes: UpdateAuthor_updateAuthor_authorAttributes[];
}

export interface UpdateAuthor {
  /**
   * Update a single "Author" node.
   */
  updateAuthor: UpdateAuthor_updateAuthor | null;
}

export interface UpdateAuthorVariables {
  where: AuthorWhereUniqueInput;
  data: AuthorUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAuthor
// ====================================================

export interface CreateAuthor_createAuthor_authorHistories_user {
  __typename: "User";
  id: string;
  name: string | null;
  avatarUrl: string | null;
}

export interface CreateAuthor_createAuthor_authorHistories {
  __typename: "AuthorHistory";
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  user: CreateAuthor_createAuthor_authorHistories_user | null;
}

export interface CreateAuthor_createAuthor_authorAttributes_attribute {
  __typename: "Attribute";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface CreateAuthor_createAuthor_authorAttributes {
  __typename: "AuthorAttribute";
  order: number;
  attribute: CreateAuthor_createAuthor_authorAttributes_attribute;
}

export interface CreateAuthor_createAuthor {
  __typename: "Author";
  avatarUrl: string | null;
  brandKey: BrandKey;
  description: string | null;
  facebookId: string | null;
  googleId: string | null;
  hasPublicProfile: boolean | null;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  instagramId: string | null;
  isMain: boolean | null;
  linkedinId: string | null;
  media: string | null;
  name: string;
  pinterestId: string | null;
  snapshatId: string | null;
  twitterId: string | null;
  userId: string | null;
  resume: string | null;
  email: string | null;
  /**
   * "AuthorHistory.author"'s inverse relation
   */
  authorHistories: CreateAuthor_createAuthor_authorHistories[];
  /**
   * "AuthorAttribute.author"'s inverse relation
   */
  authorAttributes: CreateAuthor_createAuthor_authorAttributes[];
}

export interface CreateAuthor {
  /**
   * Create a single "Author" node.
   */
  createAuthor: CreateAuthor_createAuthor;
}

export interface CreateAuthorVariables {
  data: AuthorCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CompleteSlideshowIA
// ====================================================

export interface CompleteSlideshowIA_completeSlideshowIA {
  __typename: "openAiProcessReturnType";
  /**
   * Internal OpenAI Process ID
   */
  processId: string;
}

export interface CompleteSlideshowIA {
  /**
   * Generate Slideshow with Vertex AI and ChatGpt
   */
  completeSlideshowIA: CompleteSlideshowIA_completeSlideshowIA;
}

export interface CompleteSlideshowIAVariables {
  recipeNumber: number;
  slideshowSubject: string;
  slideshowEditionId: string;
  brandKey: BrandKey;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateDailymotion
// ====================================================

export interface UpdateDailymotion_dailymotionUpdate {
  __typename: "DailymotionUpdateResult";
  id: string;
  private_id: string;
  status: string;
  title: string;
}

export interface UpdateDailymotion {
  /**
   * update dailymotion video
   */
  dailymotionUpdate: UpdateDailymotion_dailymotionUpdate;
}

export interface UpdateDailymotionVariables {
  brandKey: BrandKey;
  videoId: string;
  thumbnailUrl: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateGoodDealArticle
// ====================================================

export interface GenerateGoodDealArticle_generateGoodDealArticleIA {
  __typename: "GoodDealProcessReturnType";
  /**
   * Internal Good Deal Article Generation Process ID
   */
  processId: string;
}

export interface GenerateGoodDealArticle {
  /**
   * Generate good deal article with ChatGPT
   */
  generateGoodDealArticleIA: GenerateGoodDealArticle_generateGoodDealArticleIA;
}

export interface GenerateGoodDealArticleVariables {
  amazonProductUrl: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateSlideshowIA
// ====================================================

export interface GenerateSlideshowIA_generateSlideshowIA {
  __typename: "openAiProcessReturnType";
  /**
   * Internal OpenAI Process ID
   */
  processId: string;
}

export interface GenerateSlideshowIA {
  /**
   * Generate Slideshow with Vertex AI and ChatGPT
   */
  generateSlideshowIA: GenerateSlideshowIA_generateSlideshowIA;
}

export interface GenerateSlideshowIAVariables {
  recipeNumber: number;
  slideshowSubject: string;
  brandKey: BrandKey;
  recipeIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePrompt
// ====================================================

export interface UpdatePrompt_updatePrompt {
  __typename: "Prompt";
  body: string;
}

export interface UpdatePrompt {
  /**
   * Update a single "Prompt" node.
   */
  updatePrompt: UpdatePrompt_updatePrompt | null;
}

export interface UpdatePromptVariables {
  where: PromptWhereUniqueInput;
  data: PromptUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePush
// ====================================================

export interface CreatePush_createPush_article {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface CreatePush_createPush_user {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface CreatePush_createPush_pushSegments_segment {
  __typename: "Segment";
  title: string | null;
}

export interface CreatePush_createPush_pushSegments {
  __typename: "PushSegment";
  segment: CreatePush_createPush_pushSegments_segment;
}

export interface CreatePush_createPush {
  __typename: "Push";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  body: string;
  targetDevice: string;
  article: CreatePush_createPush_article;
  user: CreatePush_createPush_user | null;
  scheduledAt: GraphQL$DateTime;
  isCancelled: boolean | null;
  sentAt: GraphQL$DateTime | null;
  /**
   * "PushSegment.push"'s inverse relation
   */
  pushSegments: CreatePush_createPush_pushSegments[];
}

export interface CreatePush {
  /**
   * Create a single "Push" node.
   */
  createPush: CreatePush_createPush;
}

export interface CreatePushVariables {
  data: PushCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: recipeDescriptions
// ====================================================

export interface recipeDescriptions_updateSlideshowDescriptionsIA {
  __typename: "openAiProcessReturnType";
  /**
   * Internal OpenAI Process ID
   */
  processId: string;
}

export interface recipeDescriptions {
  /**
   * Generate Description with Vertex AI and ChatGpt
   */
  updateSlideshowDescriptionsIA: recipeDescriptions_updateSlideshowDescriptionsIA;
}

export interface recipeDescriptionsVariables {
  slideshowEditionId: string;
  recipesUrls: (string | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSearchAffiliationProduct
// ====================================================

export interface GetSearchAffiliationProduct_searchAffiliationProduct_pageable {
  __typename: "searchAffilizzProductResultPageable";
  pageNumber: number | null;
}

export interface GetSearchAffiliationProduct_searchAffiliationProduct_content {
  __typename: "AffilizzProduct";
  type: AffilizzProductType;
  id: string;
  productLocalizedId: string;
  currencyCode: string;
  defaultImage: string;
  minPrice: number;
  maxPrice: number;
  nbOffers: number;
  name: string;
  upsides: string[] | null;
  downsides: string[] | null;
}

export interface GetSearchAffiliationProduct_searchAffiliationProduct {
  __typename: "searchAffilizzProductResult";
  totalElements: number | null;
  totalPages: number | null;
  pageable: GetSearchAffiliationProduct_searchAffiliationProduct_pageable | null;
  content: GetSearchAffiliationProduct_searchAffiliationProduct_content[];
}

export interface GetSearchAffiliationProduct {
  /**
   * Affilizz search query
   */
  searchAffiliationProduct: GetSearchAffiliationProduct_searchAffiliationProduct;
}

export interface GetSearchAffiliationProductVariables {
  keyword: string;
  minPrice?: number | null;
  maxPrice?: number | null;
  sort?: AffilizzProductSearchSor | null;
  page?: number | null;
  size?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAffilizzDesigns
// ====================================================

export interface GetAffilizzDesigns_searchAffilliationDesign {
  __typename: "searchAffilizzDesignResult";
  id: string;
  name: string;
}

export interface GetAffilizzDesigns {
  /**
   * Affilizz design search query
   */
  searchAffilliationDesign: GetAffilizzDesigns_searchAffilliationDesign[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGoodDealPrompts
// ====================================================

export interface GetGoodDealPrompts_articleGoodDealAIPrompts {
  __typename: "ArticleGoodDealAIPrompt";
  promptOrder: number;
  prompt: string;
}

export interface GetGoodDealPrompts {
  /**
   * Retrieve a list of "ArticleGoodDealAIPrompt" nodes.
   */
  articleGoodDealAIPrompts: GetGoodDealPrompts_articleGoodDealAIPrompts[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetArticleHistoryCount
// ====================================================

export interface GetArticleHistoryCount {
  /**
   * Retrieve the number of "ArticleHistory" nodes.
   */
  articleHistoryCount: number;
}

export interface GetArticleHistoryCountVariables {
  where?: ArticleHistoryWhereInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetArticles
// ====================================================

export interface GetArticles_articles_live {
  __typename: "Live";
  /**
   * "LivePost.live"'s inverse relation count
   */
  postCount: number;
}

export interface GetArticles_articles_articleCategories_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface GetArticles_articles_articleCategories {
  __typename: "ArticleCategory";
  order: number;
  category: GetArticles_articles_articleCategories_category;
}

export interface GetArticles_articles_articleChannels_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface GetArticles_articles_articleChannels {
  __typename: "ArticleChannel";
  order: number;
  category: GetArticles_articles_articleChannels_category;
}

export interface GetArticles_articles_articleCreatorData {
  __typename: "ArticleCreatorData";
  name: string;
}

export interface GetArticles_articles_articleUrls_url {
  __typename: "Url";
  path: string;
  domain: string;
}

export interface GetArticles_articles_articleUrls {
  __typename: "ArticleUrl";
  url: GetArticles_articles_articleUrls_url;
}

export interface GetArticles_articles_articleQualifiers_qualifier {
  __typename: "Qualifier";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface GetArticles_articles_articleQualifiers {
  __typename: "ArticleQualifier";
  order: number;
  qualifier: GetArticles_articles_articleQualifiers_qualifier;
}

export interface GetArticles_articles_articleExtension_lockedBy {
  __typename: "User";
  id: string;
  name: string | null;
  email: string | null;
  avatarUrl: string | null;
}

export interface GetArticles_articles_articleExtension {
  __typename: "ArticleExtension";
  /**
   * The date of the article's last lock
   */
  lockedAt: GraphQL$DateTime | null;
  /**
   * The user currently locking the campaign
   */
  lockedBy: GetArticles_articles_articleExtension_lockedBy | null;
}

export interface GetArticles_articles_articleEvents_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface GetArticles_articles_articleEvents {
  __typename: "ArticleEvent";
  category: GetArticles_articles_articleEvents_category;
  order: number;
}

export interface GetArticles_articles_articleAuthors_author {
  __typename: "Author";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  name: string;
  avatarUrl: string | null;
}

export interface GetArticles_articles_articleAuthors {
  __typename: "ArticleAuthor";
  author: GetArticles_articles_articleAuthors_author;
}

export interface GetArticles_articles {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  status: ArticleStatus;
  body: string | null;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  publishedAt: GraphQL$DateTime | null;
  editedAt: GraphQL$DateTime | null;
  brandKey: BrandKey;
  format: ArticleFormat;
  medias: string | null;
  /**
   * "Push.article"'s inverse relation count
   */
  pushCount: number;
  /**
   * "Live.article"'s inverse relation
   */
  live: GetArticles_articles_live | null;
  /**
   * "ArticleCategory.article"'s inverse relation
   */
  articleCategories: GetArticles_articles_articleCategories[];
  /**
   * "ArticleChannel.article"'s inverse relation
   */
  articleChannels: GetArticles_articles_articleChannels[];
  /**
   * "ArticleCreatorData.article"'s inverse relation
   */
  articleCreatorData: GetArticles_articles_articleCreatorData | null;
  /**
   * "ArticleUrl.article"'s inverse relation
   */
  articleUrls: GetArticles_articles_articleUrls[];
  /**
   * "ArticleQualifier.article"'s inverse relation
   */
  articleQualifiers: GetArticles_articles_articleQualifiers[];
  /**
   * "ArticleExtension.article"'s inverse relation
   */
  articleExtension: GetArticles_articles_articleExtension | null;
  /**
   * The proper article url based on the user device and the server environment
   */
  contextualizedUrl: string | null;
  /**
   * "ArticleEvent.article"'s inverse relation
   */
  articleEvents: GetArticles_articles_articleEvents[];
  /**
   * "ArticleAuthor.article"'s inverse relation
   */
  articleAuthors: GetArticles_articles_articleAuthors[];
}

export interface GetArticles {
  /**
   * Retrieve a list of "Article" nodes.
   */
  articles: GetArticles_articles[];
}

export interface GetArticlesVariables {
  first: number;
  where?: ArticleWhereInput | null;
  skip?: number | null;
  orderBy?: ArticleOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetArticleCount
// ====================================================

export interface GetArticleCount {
  /**
   * Retrieve the number of "Article" nodes.
   */
  articleCount: number;
}

export interface GetArticleCountVariables {
  where?: ArticleWhereInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetArticle
// ====================================================

export interface GetArticle_article_live {
  __typename: "Live";
  /**
   * "LivePost.live"'s inverse relation count
   */
  postCount: number;
  endedAt: GraphQL$DateTime | null;
}

export interface GetArticle_article_articleCategories_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface GetArticle_article_articleCategories {
  __typename: "ArticleCategory";
  order: number;
  category: GetArticle_article_articleCategories_category;
}

export interface GetArticle_article_articleChannels_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface GetArticle_article_articleChannels {
  __typename: "ArticleChannel";
  order: number;
  category: GetArticle_article_articleChannels_category;
}

export interface GetArticle_article_articleCreatorData {
  __typename: "ArticleCreatorData";
  name: string;
}

export interface GetArticle_article_articleUrls_url {
  __typename: "Url";
  path: string;
  domain: string;
}

export interface GetArticle_article_articleUrls {
  __typename: "ArticleUrl";
  url: GetArticle_article_articleUrls_url;
}

export interface GetArticle_article_articleQualifiers_qualifier {
  __typename: "Qualifier";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface GetArticle_article_articleQualifiers {
  __typename: "ArticleQualifier";
  order: number;
  qualifier: GetArticle_article_articleQualifiers_qualifier;
}

export interface GetArticle_article_articleExtension_lockedBy {
  __typename: "User";
  id: string;
  name: string | null;
  email: string | null;
  avatarUrl: string | null;
}

export interface GetArticle_article_articleExtension {
  __typename: "ArticleExtension";
  /**
   * The date of the article's last lock
   */
  lockedAt: GraphQL$DateTime | null;
  /**
   * The user currently locking the campaign
   */
  lockedBy: GetArticle_article_articleExtension_lockedBy | null;
}

export interface GetArticle_article_articleTags_tag {
  __typename: "Tag";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  relationId: string | null;
  title: string;
  type: TagType;
}

export interface GetArticle_article_articleTags {
  __typename: "ArticleTag";
  order: number;
  tag: GetArticle_article_articleTags_tag;
}

export interface GetArticle_article_articleAuthors_author_user {
  __typename: "User";
  id: string;
  name: string | null;
  avatarUrl: string | null;
}

export interface GetArticle_article_articleAuthors_author {
  __typename: "Author";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  name: string;
  user: GetArticle_article_articleAuthors_author_user | null;
}

export interface GetArticle_article_articleAuthors {
  __typename: "ArticleAuthor";
  order: number;
  author: GetArticle_article_articleAuthors_author;
}

export interface GetArticle_article_source {
  __typename: "Source";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  status: SourceStatus;
}

export interface GetArticle_article_articleEvents_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface GetArticle_article_articleEvents {
  __typename: "ArticleEvent";
  order: number;
  category: GetArticle_article_articleEvents_category;
}

export interface GetArticle_article_articleGuides_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface GetArticle_article_articleGuides {
  __typename: "ArticleGuide";
  order: number;
  category: GetArticle_article_articleGuides_category;
}

export interface GetArticle_article_articleMachineTags_machineTag {
  __typename: "MachineTag";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  tag: string;
}

export interface GetArticle_article_articleMachineTags {
  __typename: "ArticleMachineTag";
  order: number;
  machineTag: GetArticle_article_articleMachineTags_machineTag;
}

export interface GetArticle_article_parentArticle_article {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  brandKey: BrandKey;
}

export interface GetArticle_article_parentArticle {
  __typename: "ArticleHistory";
  article: GetArticle_article_parentArticle_article;
}

export interface GetArticle_article_articleHistories_user {
  __typename: "User";
  id: string;
  name: string | null;
  avatarUrl: string | null;
}

export interface GetArticle_article_articleHistories_article {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  /**
   * Defines whether an article is confidential or not
   */
  isClassified: boolean | null;
}

export interface GetArticle_article_articleHistories_duplicatedArticle {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  brandKey: BrandKey;
}

export interface GetArticle_article_articleHistories {
  __typename: "ArticleHistory";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  user: GetArticle_article_articleHistories_user | null;
  status: ArticleStatus;
  article: GetArticle_article_articleHistories_article;
  /**
   * This relation is used to keep track of article duplications
   */
  duplicatedArticle: GetArticle_article_articleHistories_duplicatedArticle | null;
}

export interface GetArticle_article {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  status: ArticleStatus;
  body: string | null;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  publishedAt: GraphQL$DateTime | null;
  editedAt: GraphQL$DateTime | null;
  brandKey: BrandKey;
  format: ArticleFormat;
  medias: string | null;
  /**
   * "Push.article"'s inverse relation count
   */
  pushCount: number;
  /**
   * "Live.article"'s inverse relation
   */
  live: GetArticle_article_live | null;
  /**
   * "ArticleCategory.article"'s inverse relation
   */
  articleCategories: GetArticle_article_articleCategories[];
  /**
   * "ArticleChannel.article"'s inverse relation
   */
  articleChannels: GetArticle_article_articleChannels[];
  /**
   * "ArticleCreatorData.article"'s inverse relation
   */
  articleCreatorData: GetArticle_article_articleCreatorData | null;
  /**
   * "ArticleUrl.article"'s inverse relation
   */
  articleUrls: GetArticle_article_articleUrls[];
  /**
   * "ArticleQualifier.article"'s inverse relation
   */
  articleQualifiers: GetArticle_article_articleQualifiers[];
  /**
   * "ArticleExtension.article"'s inverse relation
   */
  articleExtension: GetArticle_article_articleExtension | null;
  /**
   * The proper article url based on the user device and the server environment
   */
  contextualizedUrl: string | null;
  secondaryTitle: string | null;
  partnerTitle: string | null;
  lead: string | null;
  slug: string | null;
  /**
   * "ArticleTag.article"'s inverse relation
   */
  articleTags: GetArticle_article_articleTags[];
  /**
   * "ArticleAuthor.article"'s inverse relation
   */
  articleAuthors: GetArticle_article_articleAuthors[];
  source: GetArticle_article_source | null;
  /**
   * "ArticleEvent.article"'s inverse relation
   */
  articleEvents: GetArticle_article_articleEvents[];
  /**
   * "ArticleGuide.article"'s inverse relation
   */
  articleGuides: GetArticle_article_articleGuides[];
  /**
   * "ArticleMachineTag.article"'s inverse relation
   */
  articleMachineTags: GetArticle_article_articleMachineTags[];
  /**
   * "ArticleHistory.duplicatedArticle"'s inverse relation
   */
  parentArticle: GetArticle_article_parentArticle[];
  /**
   * "ArticleHistory.article"'s inverse relation
   */
  articleHistories: GetArticle_article_articleHistories[];
  platformId: string | null;
}

export interface GetArticle {
  /**
   * Retrieve a single "Article" node.
   */
  article: GetArticle_article | null;
}

export interface GetArticleVariables {
  where: ArticleWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAuthors
// ====================================================

export interface GetAuthors_authors_authorHistories_user {
  __typename: "User";
  id: string;
  name: string | null;
  avatarUrl: string | null;
}

export interface GetAuthors_authors_authorHistories {
  __typename: "AuthorHistory";
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  user: GetAuthors_authors_authorHistories_user | null;
}

export interface GetAuthors_authors_authorAttributes_attribute {
  __typename: "Attribute";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface GetAuthors_authors_authorAttributes {
  __typename: "AuthorAttribute";
  order: number;
  attribute: GetAuthors_authors_authorAttributes_attribute;
}

export interface GetAuthors_authors {
  __typename: "Author";
  avatarUrl: string | null;
  brandKey: BrandKey;
  description: string | null;
  facebookId: string | null;
  googleId: string | null;
  hasPublicProfile: boolean | null;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  instagramId: string | null;
  isMain: boolean | null;
  linkedinId: string | null;
  media: string | null;
  name: string;
  pinterestId: string | null;
  snapshatId: string | null;
  twitterId: string | null;
  userId: string | null;
  resume: string | null;
  email: string | null;
  /**
   * "AuthorHistory.author"'s inverse relation
   */
  authorHistories: GetAuthors_authors_authorHistories[];
  /**
   * "AuthorAttribute.author"'s inverse relation
   */
  authorAttributes: GetAuthors_authors_authorAttributes[];
}

export interface GetAuthors {
  /**
   * Retrieve a list of "Author" nodes.
   */
  authors: GetAuthors_authors[];
}

export interface GetAuthorsVariables {
  first: number;
  where?: AuthorWhereInput | null;
  skip?: number | null;
  orderBy?: AuthorOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAuthor
// ====================================================

export interface GetAuthor_author_authorHistories_user {
  __typename: "User";
  id: string;
  name: string | null;
  avatarUrl: string | null;
}

export interface GetAuthor_author_authorHistories {
  __typename: "AuthorHistory";
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  user: GetAuthor_author_authorHistories_user | null;
}

export interface GetAuthor_author_authorAttributes_attribute {
  __typename: "Attribute";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface GetAuthor_author_authorAttributes {
  __typename: "AuthorAttribute";
  order: number;
  attribute: GetAuthor_author_authorAttributes_attribute;
}

export interface GetAuthor_author {
  __typename: "Author";
  avatarUrl: string | null;
  brandKey: BrandKey;
  description: string | null;
  facebookId: string | null;
  googleId: string | null;
  hasPublicProfile: boolean | null;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  instagramId: string | null;
  isMain: boolean | null;
  linkedinId: string | null;
  media: string | null;
  name: string;
  pinterestId: string | null;
  snapshatId: string | null;
  twitterId: string | null;
  userId: string | null;
  resume: string | null;
  email: string | null;
  /**
   * "AuthorHistory.author"'s inverse relation
   */
  authorHistories: GetAuthor_author_authorHistories[];
  /**
   * "AuthorAttribute.author"'s inverse relation
   */
  authorAttributes: GetAuthor_author_authorAttributes[];
}

export interface GetAuthor {
  /**
   * Retrieve a single "Author" node.
   */
  author: GetAuthor_author | null;
}

export interface GetAuthorVariables {
  where: AuthorWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAttribute
// ====================================================

export interface CreateAttribute_createAttribute {
  __typename: "Attribute";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface CreateAttribute {
  /**
   * Create a single "Attribute" node.
   */
  createAttribute: CreateAttribute_createAttribute;
}

export interface CreateAttributeVariables {
  data: AttributeCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDailymotionUploadURL
// ====================================================

export interface getDailymotionUploadURL_dailymotionUploadURL {
  __typename: "DailymotionUploadURLResult";
  uploadURL: string;
  progressURL: string;
}

export interface getDailymotionUploadURL {
  /**
   * dailymotion upload url
   */
  dailymotionUploadURL: getDailymotionUploadURL_dailymotionUploadURL[];
}

export interface getDailymotionUploadURLVariables {
  brandKey: BrandKey;
  count: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetArticleGoodDealIaProcess
// ====================================================

export interface GetArticleGoodDealIaProcess_articleGoodDealAiProcesses_article {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface GetArticleGoodDealIaProcess_articleGoodDealAiProcesses {
  __typename: "ArticleGoodDealAiProcess";
  article: GetArticleGoodDealIaProcess_articleGoodDealAiProcesses_article | null;
  status: GoodDealProcessStatus;
  /**
   * The public ID, an UUID v4
   */
  id: string | null;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  productTitle: string | null;
  errorMsg: string | null;
}

export interface GetArticleGoodDealIaProcess {
  /**
   * Retrieve a list of "ArticleGoodDealAiProcess" nodes.
   */
  articleGoodDealAiProcesses: GetArticleGoodDealIaProcess_articleGoodDealAiProcesses[];
}

export interface GetArticleGoodDealIaProcessVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAttributes
// ====================================================

export interface GetAttributes_attributes_authorAttributes_author {
  __typename: "Author";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  name: string;
}

export interface GetAttributes_attributes_authorAttributes {
  __typename: "AuthorAttribute";
  author: GetAttributes_attributes_authorAttributes_author;
}

export interface GetAttributes_attributes {
  __typename: "Attribute";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  brandKey: BrandKey;
  slug: string;
  /**
   * "AuthorAttribute.attribute"'s inverse relation
   */
  authorAttributes: GetAttributes_attributes_authorAttributes[];
}

export interface GetAttributes {
  /**
   * Retrieve a list of "Attribute" nodes.
   */
  attributes: GetAttributes_attributes[];
}

export interface GetAttributesVariables {
  first: number;
  where?: AttributeWhereInput | null;
  skip?: number | null;
  orderBy?: AttributeOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSearchEnabledTags
// ====================================================

export interface GetSearchEnabledTags_searchEnabledTags_items {
  __typename: "TagProviderResult";
  id: string | null;
  title: string;
  type: TagType;
  relationId: string | null;
  /**
   * Number of articles associated with this tag
   */
  articleCount: number | null;
  isEnabled: boolean | null;
}

export interface GetSearchEnabledTags_searchEnabledTags {
  __typename: "TagSearchResult";
  /**
   * Name of the "search"
   */
  name: TagProviderName;
  itemCount: number;
  items: GetSearchEnabledTags_searchEnabledTags_items[];
}

export interface GetSearchEnabledTags {
  /**
   * Search "tags" across the different services
   */
  searchEnabledTags: GetSearchEnabledTags_searchEnabledTags[];
}

export interface GetSearchEnabledTagsVariables {
  providers: TagProviderName[];
  brandKey: BrandKey;
  query: string;
  first: number;
  types: TagSearchTypes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTag
// ====================================================

export interface GetTag_tag {
  __typename: "Tag";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface GetTag {
  /**
   * Retrieve a single "Tag" node.
   */
  tag: GetTag_tag | null;
}

export interface GetTagVariables {
  where: TagWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getOpenAiProcesses
// ====================================================

export interface getOpenAiProcesses_openAiProcesses_article {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface getOpenAiProcesses_openAiProcesses {
  __typename: "OpenAiProcess";
  article: getOpenAiProcesses_openAiProcesses_article | null;
  /**
   * The public ID, an UUID v4
   */
  id: string | null;
  slideshowSubject: string | null;
  recipeNumber: number | null;
  status: OpenAIProcessStatus;
  errorMsg: string | null;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  generatedTitle: string | null;
}

export interface getOpenAiProcesses {
  /**
   * Retrieve a list of "OpenAiProcess" nodes.
   */
  openAiProcesses: getOpenAiProcesses_openAiProcesses[];
}

export interface getOpenAiProcessesVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSuggestedTagsList
// ====================================================

export interface GetSuggestedTagsList_suggestTags_items {
  __typename: "TagProviderResult";
  id: string | null;
  title: string;
  type: TagType;
  relationId: string | null;
  /**
   * Number of articles associated with this tag
   */
  articleCount: number | null;
  isEnabled: boolean | null;
}

export interface GetSuggestedTagsList_suggestTags {
  __typename: "TagSuggestResult";
  items: GetSuggestedTagsList_suggestTags_items[];
}

export interface GetSuggestedTagsList {
  /**
   * Suggest tags used in similar articles
   */
  suggestTags: GetSuggestedTagsList_suggestTags | null;
}

export interface GetSuggestedTagsListVariables {
  brandKey: BrandKey;
  exclude?: (string | null)[] | null;
  search: SimilarArticleArgsInputType;
  providers: TagProviderName[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetVertexAIRecipes
// ====================================================

export interface GetVertexAIRecipes_getVertexAIRecipes_ingredients {
  __typename: "vertexAIRecipesIngredientsType";
  name: string;
  count: number;
}

export interface GetVertexAIRecipes_getVertexAIRecipes {
  __typename: "vertexAIRecipesResultType";
  recipesIds: string[];
  ingredients: GetVertexAIRecipes_getVertexAIRecipes_ingredients[];
}

export interface GetVertexAIRecipes {
  /**
   * List segmentations by brandKey and TargetDevices
   */
  getVertexAIRecipes: GetVertexAIRecipes_getVertexAIRecipes | null;
}

export interface GetVertexAIRecipesVariables {
  excludedIngredients?: string[] | null;
  recipeNumber: number;
  slideshowSubject: string;
  brandKey: BrandKey;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProvidersPhotos
// ====================================================

export interface GetProvidersPhotos_searchPhotosByProviders_items_preview {
  __typename: "PhotoPreview";
  height: number;
  url: string;
  width: number;
}

export interface GetProvidersPhotos_searchPhotosByProviders_items {
  __typename: "PhotoProviderResult";
  id: string;
  medium: string | null;
  large: string | null;
  preview: GetProvidersPhotos_searchPhotosByProviders_items_preview | null;
  small: string | null;
}

export interface GetProvidersPhotos_searchPhotosByProviders {
  __typename: "PhotoSearchResult";
  /**
   * Provider name
   */
  name: PhotoProvider;
  items: GetProvidersPhotos_searchPhotosByProviders_items[];
}

export interface GetProvidersPhotos {
  /**
   * Search images by providers
   */
  searchPhotosByProviders: GetProvidersPhotos_searchPhotosByProviders[];
}

export interface GetProvidersPhotosVariables {
  query: string;
  perPage: number;
  providers: PhotoProvider[];
  currentPage?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPrompts
// ====================================================

export interface GetPrompts_prompts {
  __typename: "Prompt";
  field: PromptField;
  label: string;
  body: string;
  brandKey: BrandKey;
}

export interface GetPrompts {
  /**
   * Retrieve a list of "Prompt" nodes.
   */
  prompts: GetPrompts_prompts[];
}

export interface GetPromptsVariables {
  first: number;
  where?: PromptWhereInput | null;
  skip?: number | null;
  orderBy?: PromptOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPrompt
// ====================================================

export interface GetPrompt_prompt {
  __typename: "Prompt";
  body: string;
}

export interface GetPrompt {
  /**
   * Retrieve a single "Prompt" node.
   */
  prompt: GetPrompt_prompt | null;
}

export interface GetPromptVariables {
  brandKey: BrandKey;
  field: PromptField;
  label: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchArticles
// ====================================================

export interface SearchArticles_searchArticles_live {
  __typename: "Live";
  /**
   * "LivePost.live"'s inverse relation count
   */
  postCount: number;
}

export interface SearchArticles_searchArticles_articleCategories_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface SearchArticles_searchArticles_articleCategories {
  __typename: "ArticleCategory";
  order: number;
  category: SearchArticles_searchArticles_articleCategories_category;
}

export interface SearchArticles_searchArticles_articleChannels_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface SearchArticles_searchArticles_articleChannels {
  __typename: "ArticleChannel";
  order: number;
  category: SearchArticles_searchArticles_articleChannels_category;
}

export interface SearchArticles_searchArticles_articleCreatorData {
  __typename: "ArticleCreatorData";
  name: string;
}

export interface SearchArticles_searchArticles_articleUrls_url {
  __typename: "Url";
  path: string;
  domain: string;
}

export interface SearchArticles_searchArticles_articleUrls {
  __typename: "ArticleUrl";
  url: SearchArticles_searchArticles_articleUrls_url;
}

export interface SearchArticles_searchArticles_articleQualifiers_qualifier {
  __typename: "Qualifier";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface SearchArticles_searchArticles_articleQualifiers {
  __typename: "ArticleQualifier";
  order: number;
  qualifier: SearchArticles_searchArticles_articleQualifiers_qualifier;
}

export interface SearchArticles_searchArticles_articleExtension_lockedBy {
  __typename: "User";
  id: string;
  name: string | null;
  email: string | null;
  avatarUrl: string | null;
}

export interface SearchArticles_searchArticles_articleExtension {
  __typename: "ArticleExtension";
  /**
   * The date of the article's last lock
   */
  lockedAt: GraphQL$DateTime | null;
  /**
   * The user currently locking the campaign
   */
  lockedBy: SearchArticles_searchArticles_articleExtension_lockedBy | null;
}

export interface SearchArticles_searchArticles_articleEvents_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface SearchArticles_searchArticles_articleEvents {
  __typename: "ArticleEvent";
  category: SearchArticles_searchArticles_articleEvents_category;
  order: number;
}

export interface SearchArticles_searchArticles_articleAuthors_author {
  __typename: "Author";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  name: string;
  avatarUrl: string | null;
}

export interface SearchArticles_searchArticles_articleAuthors {
  __typename: "ArticleAuthor";
  author: SearchArticles_searchArticles_articleAuthors_author;
}

export interface SearchArticles_searchArticles {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  status: ArticleStatus;
  body: string | null;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  publishedAt: GraphQL$DateTime | null;
  editedAt: GraphQL$DateTime | null;
  brandKey: BrandKey;
  format: ArticleFormat;
  medias: string | null;
  /**
   * "Push.article"'s inverse relation count
   */
  pushCount: number;
  /**
   * "Live.article"'s inverse relation
   */
  live: SearchArticles_searchArticles_live | null;
  /**
   * "ArticleCategory.article"'s inverse relation
   */
  articleCategories: SearchArticles_searchArticles_articleCategories[];
  /**
   * "ArticleChannel.article"'s inverse relation
   */
  articleChannels: SearchArticles_searchArticles_articleChannels[];
  /**
   * "ArticleCreatorData.article"'s inverse relation
   */
  articleCreatorData: SearchArticles_searchArticles_articleCreatorData | null;
  /**
   * "ArticleUrl.article"'s inverse relation
   */
  articleUrls: SearchArticles_searchArticles_articleUrls[];
  /**
   * "ArticleQualifier.article"'s inverse relation
   */
  articleQualifiers: SearchArticles_searchArticles_articleQualifiers[];
  /**
   * "ArticleExtension.article"'s inverse relation
   */
  articleExtension: SearchArticles_searchArticles_articleExtension | null;
  /**
   * The proper article url based on the user device and the server environment
   */
  contextualizedUrl: string | null;
  /**
   * "ArticleEvent.article"'s inverse relation
   */
  articleEvents: SearchArticles_searchArticles_articleEvents[];
  /**
   * "ArticleAuthor.article"'s inverse relation
   */
  articleAuthors: SearchArticles_searchArticles_articleAuthors[];
}

export interface SearchArticles {
  /**
   * Search articles using ElasticSearch
   */
  searchArticles: SearchArticles_searchArticles[];
}

export interface SearchArticlesVariables {
  where?: ArticleSearchWhereInput | null;
  first: number;
  skip: number;
  orderBy?: ArticleSearchOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchArticleCount
// ====================================================

export interface SearchArticleCount {
  /**
   * Count articles using ElasticSearch
   */
  searchArticleCount: number;
}

export interface SearchArticleCountVariables {
  where?: ArticleSearchWhereInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchMediaArticleCount
// ====================================================

export interface SearchMediaArticleCount_searchMediaArticleCount {
  __typename: "searchMediaArticleCountResultType";
  mediaElement: string;
  count: number;
}

export interface SearchMediaArticleCount {
  /**
   * Count Media Elements in an Articles
   */
  searchMediaArticleCount: (SearchMediaArticleCount_searchMediaArticleCount | null)[];
}

export interface SearchMediaArticleCountVariables {
  mediaElements: (string | null)[];
  mediaSite: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrphans
// ====================================================

export interface GetOrphans_orphanPages {
  __typename: "OrphanPage";
  /**
   * The public ID, an UUID v4
   */
  id: string | null;
  publishedAt: GraphQL$DateTime | null;
  editedAt: GraphQL$DateTime | null;
  status: OrphanPageStatus;
  title: string;
  content: string | null;
  metas: string | null;
  isCMPEnabled: boolean;
  isAdsEnabled: boolean;
  isFullScreen: boolean | null;
  brandKey: BrandKey;
  uri: string | null;
}

export interface GetOrphans {
  /**
   * Retrieve a list of "OrphanPage" nodes.
   */
  orphanPages: GetOrphans_orphanPages[];
}

export interface GetOrphansVariables {
  where?: OrphanPageWhereInput | null;
  first: number;
  skip: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SuggestArticles
// ====================================================

export interface SuggestArticles_suggestArticles_items_live {
  __typename: "Live";
  /**
   * "LivePost.live"'s inverse relation count
   */
  postCount: number;
}

export interface SuggestArticles_suggestArticles_items_articleCategories_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface SuggestArticles_suggestArticles_items_articleCategories {
  __typename: "ArticleCategory";
  order: number;
  category: SuggestArticles_suggestArticles_items_articleCategories_category;
}

export interface SuggestArticles_suggestArticles_items_articleChannels_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface SuggestArticles_suggestArticles_items_articleChannels {
  __typename: "ArticleChannel";
  order: number;
  category: SuggestArticles_suggestArticles_items_articleChannels_category;
}

export interface SuggestArticles_suggestArticles_items_articleCreatorData {
  __typename: "ArticleCreatorData";
  name: string;
}

export interface SuggestArticles_suggestArticles_items_articleUrls_url {
  __typename: "Url";
  path: string;
  domain: string;
}

export interface SuggestArticles_suggestArticles_items_articleUrls {
  __typename: "ArticleUrl";
  url: SuggestArticles_suggestArticles_items_articleUrls_url;
}

export interface SuggestArticles_suggestArticles_items_articleQualifiers_qualifier {
  __typename: "Qualifier";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface SuggestArticles_suggestArticles_items_articleQualifiers {
  __typename: "ArticleQualifier";
  order: number;
  qualifier: SuggestArticles_suggestArticles_items_articleQualifiers_qualifier;
}

export interface SuggestArticles_suggestArticles_items_articleExtension_lockedBy {
  __typename: "User";
  id: string;
  name: string | null;
  email: string | null;
  avatarUrl: string | null;
}

export interface SuggestArticles_suggestArticles_items_articleExtension {
  __typename: "ArticleExtension";
  /**
   * The date of the article's last lock
   */
  lockedAt: GraphQL$DateTime | null;
  /**
   * The user currently locking the campaign
   */
  lockedBy: SuggestArticles_suggestArticles_items_articleExtension_lockedBy | null;
}

export interface SuggestArticles_suggestArticles_items_articleEvents_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface SuggestArticles_suggestArticles_items_articleEvents {
  __typename: "ArticleEvent";
  category: SuggestArticles_suggestArticles_items_articleEvents_category;
  order: number;
}

export interface SuggestArticles_suggestArticles_items_articleAuthors_author {
  __typename: "Author";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  name: string;
  avatarUrl: string | null;
}

export interface SuggestArticles_suggestArticles_items_articleAuthors {
  __typename: "ArticleAuthor";
  author: SuggestArticles_suggestArticles_items_articleAuthors_author;
}

export interface SuggestArticles_suggestArticles_items {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  status: ArticleStatus;
  body: string | null;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  publishedAt: GraphQL$DateTime | null;
  editedAt: GraphQL$DateTime | null;
  brandKey: BrandKey;
  format: ArticleFormat;
  medias: string | null;
  /**
   * "Push.article"'s inverse relation count
   */
  pushCount: number;
  /**
   * "Live.article"'s inverse relation
   */
  live: SuggestArticles_suggestArticles_items_live | null;
  /**
   * "ArticleCategory.article"'s inverse relation
   */
  articleCategories: SuggestArticles_suggestArticles_items_articleCategories[];
  /**
   * "ArticleChannel.article"'s inverse relation
   */
  articleChannels: SuggestArticles_suggestArticles_items_articleChannels[];
  /**
   * "ArticleCreatorData.article"'s inverse relation
   */
  articleCreatorData: SuggestArticles_suggestArticles_items_articleCreatorData | null;
  /**
   * "ArticleUrl.article"'s inverse relation
   */
  articleUrls: SuggestArticles_suggestArticles_items_articleUrls[];
  /**
   * "ArticleQualifier.article"'s inverse relation
   */
  articleQualifiers: SuggestArticles_suggestArticles_items_articleQualifiers[];
  /**
   * "ArticleExtension.article"'s inverse relation
   */
  articleExtension: SuggestArticles_suggestArticles_items_articleExtension | null;
  /**
   * The proper article url based on the user device and the server environment
   */
  contextualizedUrl: string | null;
  /**
   * "ArticleEvent.article"'s inverse relation
   */
  articleEvents: SuggestArticles_suggestArticles_items_articleEvents[];
  /**
   * "ArticleAuthor.article"'s inverse relation
   */
  articleAuthors: SuggestArticles_suggestArticles_items_articleAuthors[];
}

export interface SuggestArticles_suggestArticles {
  __typename: "ArticleSuggestionResult";
  items: SuggestArticles_suggestArticles_items[];
}

export interface SuggestArticles {
  /**
   * Search similar articles
   */
  suggestArticles: SuggestArticles_suggestArticles | null;
}

export interface SuggestArticlesVariables {
  search: SimilarArticleArgsInputType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSuggestedCategory
// ====================================================

export interface GetSuggestedCategory_suggestCategories_items {
  __typename: "CategorySuggestion";
  id: string;
  title: string;
  slug: string;
  format: string | null;
  level: number | null;
}

export interface GetSuggestedCategory_suggestCategories {
  __typename: "CategorySuggestionResult";
  itemCount: number;
  items: GetSuggestedCategory_suggestCategories_items[];
}

export interface GetSuggestedCategory {
  /**
   * Search category used in similar articles
   */
  suggestCategories: GetSuggestedCategory_suggestCategories;
}

export interface GetSuggestedCategoryVariables {
  categoryFormat?: CategoryFormat | null;
  search: SimilarArticleArgsInputType;
  exclude?: (string | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TranslateArticle
// ====================================================

export interface TranslateArticle_translateArticle {
  __typename: "TranslateArticleType";
  title: string;
  body: string | null;
  lead: string | null;
}

export interface TranslateArticle {
  /**
   * Translate article
   */
  translateArticle: TranslateArticle_translateArticle | null;
}

export interface TranslateArticleVariables {
  id: string;
  source_lang: string;
  target_lang: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCategories
// ====================================================

export interface GetCategories_categories_parent {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface GetCategories_categories {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  level: number;
  parent: GetCategories_categories_parent | null;
}

export interface GetCategories {
  /**
   * Retrieve a list of "Category" nodes.
   */
  categories: GetCategories_categories[];
}

export interface GetCategoriesVariables {
  where: CategoryWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCategory
// ====================================================

export interface GetCategory_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface GetCategory {
  /**
   * Retrieve a single "Category" node.
   */
  category: GetCategory_category | null;
}

export interface GetCategoryVariables {
  where: CategoryWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBrandKeys
// ====================================================

export interface GetBrandKeys_categories {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  level: number;
  status: CategoryStatus;
  brandKey: BrandKey;
}

export interface GetBrandKeys {
  /**
   * Retrieve a list of "Category" nodes.
   */
  categories: GetBrandKeys_categories[];
}

export interface GetBrandKeysVariables {
  first: number;
  level: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetImageSourceCategories
// ====================================================

export interface GetImageSourceCategories_imageSourceCategories_imageSources {
  __typename: "ImageSource";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface GetImageSourceCategories_imageSourceCategories {
  __typename: "ImageSourceCategory";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  /**
   * "ImageSource.imageSourceCategory"'s inverse relation
   */
  imageSources: GetImageSourceCategories_imageSourceCategories_imageSources[];
}

export interface GetImageSourceCategories {
  /**
   * Retrieve a list of "ImageSourceCategory" nodes.
   */
  imageSourceCategories: GetImageSourceCategories_imageSourceCategories[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchImageSourceCategories
// ====================================================

export interface SearchImageSourceCategories_imageSourceCategories_imageSources {
  __typename: "ImageSource";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface SearchImageSourceCategories_imageSourceCategories {
  __typename: "ImageSourceCategory";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  /**
   * "ImageSource.imageSourceCategory"'s inverse relation
   */
  imageSources: SearchImageSourceCategories_imageSourceCategories_imageSources[];
}

export interface SearchImageSourceCategories {
  /**
   * Retrieve a list of "ImageSourceCategory" nodes.
   */
  imageSourceCategories: SearchImageSourceCategories_imageSourceCategories[];
}

export interface SearchImageSourceCategoriesVariables {
  where?: ImageSourceCategoryWhereInput | null;
  sourceWhere?: ImageSourceWhereInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSearchCategoryMedias
// ====================================================

export interface GetSearchCategoryMedias_category {
  __typename: "Category";
  medias: string | null;
}

export interface GetSearchCategoryMedias {
  /**
   * Retrieve a single "Category" node.
   */
  category: GetSearchCategoryMedias_category | null;
}

export interface GetSearchCategoryMediasVariables {
  where: CategoryWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetQualifier
// ====================================================

export interface GetQualifier_qualifier {
  __typename: "Qualifier";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  status: QualifierStatus;
  brandKey: BrandKey;
  format: ArticleFormat;
}

export interface GetQualifier {
  /**
   * Retrieve a single "Qualifier" node.
   */
  qualifier: GetQualifier_qualifier | null;
}

export interface GetQualifierVariables {
  where: QualifierWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateQualifier
// ====================================================

export interface UpdateQualifier_updateQualifier {
  __typename: "Qualifier";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface UpdateQualifier {
  /**
   * Update a single "Qualifier" node.
   */
  updateQualifier: UpdateQualifier_updateQualifier | null;
}

export interface UpdateQualifierVariables {
  where: QualifierWhereUniqueInput;
  data: QualifierUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateQualifier
// ====================================================

export interface CreateQualifier_createQualifier {
  __typename: "Qualifier";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface CreateQualifier {
  /**
   * Create a single "Qualifier" node.
   */
  createQualifier: CreateQualifier_createQualifier;
}

export interface CreateQualifierVariables {
  data: QualifierCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSegment
// ====================================================

export interface GetSegment_segment {
  __typename: "Segment";
  active: boolean | null;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string | null;
  ios: string | null;
  android: string | null;
  brandKey: BrandKey | null;
  status: SegmentStatus;
  editorialAlert: string | null;
}

export interface GetSegment {
  /**
   * Retrieve a single "Segment" node.
   */
  segment: GetSegment_segment | null;
}

export interface GetSegmentVariables {
  where: SegmentWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSegment
// ====================================================

export interface UpdateSegment_updateSegment {
  __typename: "Segment";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface UpdateSegment {
  /**
   * Update a single "Segment" node.
   */
  updateSegment: UpdateSegment_updateSegment | null;
}

export interface UpdateSegmentVariables {
  where: SegmentWhereUniqueInput;
  data: SegmentUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateSegment
// ====================================================

export interface CreateSegment_createSegment {
  __typename: "Segment";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface CreateSegment {
  /**
   * Create a single "Segment" node.
   */
  createSegment: CreateSegment_createSegment;
}

export interface CreateSegmentVariables {
  data: SegmentCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSegments
// ====================================================

export interface GetSegments_segments {
  __typename: "Segment";
  active: boolean | null;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string | null;
  brandKey: BrandKey | null;
  status: SegmentStatus;
}

export interface GetSegments {
  /**
   * Retrieve a list of "Segment" nodes.
   */
  segments: GetSegments_segments[];
  /**
   * Retrieve the number of "Segment" nodes.
   */
  segmentCount: number;
}

export interface GetSegmentsVariables {
  first: number;
  where?: SegmentWhereInput | null;
  orderBy?: SegmentOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetQualifiers
// ====================================================

export interface GetQualifiers_qualifiers {
  __typename: "Qualifier";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  status: QualifierStatus;
  brandKey: BrandKey;
  format: ArticleFormat;
}

export interface GetQualifiers {
  /**
   * Retrieve a list of "Qualifier" nodes.
   */
  qualifiers: GetQualifiers_qualifiers[];
}

export interface GetQualifiersVariables {
  first: number;
  where?: QualifierWhereInput | null;
  orderBy?: QualifierOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSources
// ====================================================

export interface GetSources_sources {
  __typename: "Source";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  brandKey: BrandKey;
  status: SourceStatus;
}

export interface GetSources {
  /**
   * Retrieve a list of "Source" nodes.
   */
  sources: GetSources_sources[];
}

export interface GetSourcesVariables {
  first: number;
  where?: SourceWhereInput | null;
  orderBy?: SourceOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSource
// ====================================================

export interface GetSource_source {
  __typename: "Source";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface GetSource {
  /**
   * Retrieve a single "Source" node.
   */
  source: GetSource_source | null;
}

export interface GetSourceVariables {
  where: SourceWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSourceCount
// ====================================================

export interface GetSourceCount {
  /**
   * Retrieve the number of "Source" nodes.
   */
  sourceCount: number;
}

export interface GetSourceCountVariables {
  where?: SourceWhereInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateMachineTagForDuplication
// ====================================================

export interface CreateMachineTagForDuplication_createMachineTag {
  __typename: "MachineTag";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface CreateMachineTagForDuplication {
  /**
   * Create a single "MachineTag" node.
   */
  createMachineTag: CreateMachineTagForDuplication_createMachineTag;
}

export interface CreateMachineTagForDuplicationVariables {
  data: MachineTagCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertTag
// ====================================================

export interface UpsertTag_upsertTag {
  __typename: "Tag";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  slug: string;
  type: TagType;
  relationId: string | null;
}

export interface UpsertTag {
  /**
   * Update or create a single "Tag" node.
   */
  upsertTag: UpsertTag_upsertTag;
}

export interface UpsertTagVariables {
  create: TagCreateInput;
  update: TagUpdateInput;
  where: TagWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: upsertCard
// ====================================================

export interface upsertCard_upsertCard_cardCategories_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  level: number;
  title: string;
}

export interface upsertCard_upsertCard_cardCategories {
  __typename: "CardCategory";
  order: number;
  category: upsertCard_upsertCard_cardCategories_category;
}

export interface upsertCard_upsertCard_cardTags_tag {
  __typename: "Tag";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  type: TagType;
}

export interface upsertCard_upsertCard_cardTags {
  __typename: "CardTag";
  order: number;
  tag: upsertCard_upsertCard_cardTags_tag;
}

export interface upsertCard_upsertCard {
  __typename: "Card";
  /**
   * The public ID, an UUID v4
   */
  id: string | null;
  title: string;
  secondaryTitle: string | null;
  description: string | null;
  url: string;
  label: string;
  brandKey: BrandKey;
  status: CardStatus;
  media: string | null;
  body: string | null;
  /**
   * "CardCategory.card"'s inverse relation
   */
  cardCategories: upsertCard_upsertCard_cardCategories[];
  /**
   * "CardTag.card"'s inverse relation
   */
  cardTags: upsertCard_upsertCard_cardTags[];
}

export interface upsertCard {
  /**
   * Update or create a single "Card" node.
   */
  upsertCard: upsertCard_upsertCard;
}

export interface upsertCardVariables {
  where: CardWhereUniqueInput;
  create: CardCreateInput;
  update: CardUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCard
// ====================================================

export interface getCard_card_cardCategories_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  level: number;
  title: string;
}

export interface getCard_card_cardCategories {
  __typename: "CardCategory";
  order: number;
  category: getCard_card_cardCategories_category;
}

export interface getCard_card_cardTags_tag {
  __typename: "Tag";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  type: TagType;
}

export interface getCard_card_cardTags {
  __typename: "CardTag";
  order: number;
  tag: getCard_card_cardTags_tag;
}

export interface getCard_card {
  __typename: "Card";
  /**
   * The public ID, an UUID v4
   */
  id: string | null;
  title: string;
  secondaryTitle: string | null;
  description: string | null;
  url: string;
  label: string;
  brandKey: BrandKey;
  status: CardStatus;
  media: string | null;
  body: string | null;
  /**
   * "CardCategory.card"'s inverse relation
   */
  cardCategories: getCard_card_cardCategories[];
  /**
   * "CardTag.card"'s inverse relation
   */
  cardTags: getCard_card_cardTags[];
}

export interface getCard {
  /**
   * Retrieve a single "Card" node.
   */
  card: getCard_card | null;
}

export interface getCardVariables {
  where: CardWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCards
// ====================================================

export interface getCards_cards_cardCategories_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  level: number;
  title: string;
}

export interface getCards_cards_cardCategories {
  __typename: "CardCategory";
  order: number;
  category: getCards_cards_cardCategories_category;
}

export interface getCards_cards_cardTags_tag {
  __typename: "Tag";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  type: TagType;
}

export interface getCards_cards_cardTags {
  __typename: "CardTag";
  order: number;
  tag: getCards_cards_cardTags_tag;
}

export interface getCards_cards {
  __typename: "Card";
  /**
   * The public ID, an UUID v4
   */
  id: string | null;
  title: string;
  secondaryTitle: string | null;
  description: string | null;
  url: string;
  label: string;
  brandKey: BrandKey;
  status: CardStatus;
  media: string | null;
  body: string | null;
  /**
   * "CardCategory.card"'s inverse relation
   */
  cardCategories: getCards_cards_cardCategories[];
  /**
   * "CardTag.card"'s inverse relation
   */
  cardTags: getCards_cards_cardTags[];
}

export interface getCards {
  /**
   * Retrieve a list of "Card" nodes.
   */
  cards: getCards_cards[];
}

export interface getCardsVariables {
  first: number;
  where?: CardWhereInput | null;
  orderBy?: CardOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteLivePost
// ====================================================

export interface DeleteLivePost_deleteLivePost {
  __typename: "LivePost";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface DeleteLivePost {
  /**
   * Delete a single "LivePost" node.
   */
  deleteLivePost: DeleteLivePost_deleteLivePost | null;
}

export interface DeleteLivePostVariables {
  where: LivePostWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateLiveDates
// ====================================================

export interface UpdateLiveDates_updateLive_posts {
  __typename: "LivePost";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  publishedAt: GraphQL$DateTime;
  title: string | null;
  body: string | null;
}

export interface UpdateLiveDates_updateLive {
  __typename: "Live";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  endedAt: GraphQL$DateTime | null;
  /**
   * "LivePost.live"'s inverse relation
   */
  posts: UpdateLiveDates_updateLive_posts[];
}

export interface UpdateLiveDates {
  /**
   * Update a single "Live" node.
   */
  updateLive: UpdateLiveDates_updateLive | null;
}

export interface UpdateLiveDatesVariables {
  where: LiveWhereUniqueInput;
  endDate?: GraphQL$DateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertLive
// ====================================================

export interface UpsertLive_upsertLive_article {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface UpsertLive_upsertLive_posts {
  __typename: "LivePost";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  publishedAt: GraphQL$DateTime;
  title: string | null;
  body: string | null;
}

export interface UpsertLive_upsertLive {
  __typename: "Live";
  article: UpsertLive_upsertLive_article;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  endedAt: GraphQL$DateTime | null;
  /**
   * "LivePost.live"'s inverse relation
   */
  posts: UpsertLive_upsertLive_posts[];
}

export interface UpsertLive {
  /**
   * Update or create a single "Live" node.
   */
  upsertLive: UpsertLive_upsertLive;
}

export interface UpsertLiveVariables {
  where: LiveWhereUniqueInput;
  create: LiveCreateInput;
  update: LiveUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertLivePost
// ====================================================

export interface UpsertLivePost_upsertLivePost_live {
  __typename: "Live";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  endedAt: GraphQL$DateTime | null;
}

export interface UpsertLivePost_upsertLivePost {
  __typename: "LivePost";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  publishedAt: GraphQL$DateTime;
  title: string | null;
  body: string | null;
  live: UpsertLivePost_upsertLivePost_live;
}

export interface UpsertLivePost {
  /**
   * Update or create a single "LivePost" node.
   */
  upsertLivePost: UpsertLivePost_upsertLivePost;
}

export interface UpsertLivePostVariables {
  create: LivePostCreateInput;
  where: LivePostWhereUniqueInput;
  update: LivePostUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updatePush
// ====================================================

export interface updatePush_updatePush {
  __typename: "Push";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  body: string;
  isCancelled: boolean | null;
}

export interface updatePush {
  /**
   * Update a single "Push" node.
   */
  updatePush: updatePush_updatePush | null;
}

export interface updatePushVariables {
  where: PushWhereUniqueInput;
  data: PushUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPushesByBrandKey
// ====================================================

export interface getPushesByBrandKey_pushes_article {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  brandKey: BrandKey;
  format: ArticleFormat;
}

export interface getPushesByBrandKey_pushes_user {
  __typename: "User";
  name: string | null;
  id: string;
}

export interface getPushesByBrandKey_pushes_pushSegments_segment {
  __typename: "Segment";
  title: string | null;
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface getPushesByBrandKey_pushes_pushSegments {
  __typename: "PushSegment";
  segment: getPushesByBrandKey_pushes_pushSegments_segment;
}

export interface getPushesByBrandKey_pushes {
  __typename: "Push";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  article: getPushesByBrandKey_pushes_article;
  user: getPushesByBrandKey_pushes_user | null;
  sentAt: GraphQL$DateTime | null;
  targetDevice: string;
  scheduledAt: GraphQL$DateTime;
  /**
   * "PushSegment.push"'s inverse relation
   */
  pushSegments: getPushesByBrandKey_pushes_pushSegments[];
}

export interface getPushesByBrandKey {
  /**
   * Retrieve the number of "Push" nodes.
   */
  pushCount: number;
  /**
   * Retrieve a list of "Push" nodes.
   */
  pushes: getPushesByBrandKey_pushes[];
}

export interface getPushesByBrandKeyVariables {
  first: number;
  skip?: number | null;
  where?: PushWhereInput | null;
  orderBy?: PushOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPush
// ====================================================

export interface getPush_pushes_article {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface getPush_pushes_user {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface getPush_pushes_pushSegments_segment {
  __typename: "Segment";
  title: string | null;
}

export interface getPush_pushes_pushSegments {
  __typename: "PushSegment";
  segment: getPush_pushes_pushSegments_segment;
}

export interface getPush_pushes {
  __typename: "Push";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  body: string;
  targetDevice: string;
  article: getPush_pushes_article;
  user: getPush_pushes_user | null;
  scheduledAt: GraphQL$DateTime;
  sentAt: GraphQL$DateTime | null;
  isCancelled: boolean | null;
  /**
   * "PushSegment.push"'s inverse relation
   */
  pushSegments: getPush_pushes_pushSegments[];
}

export interface getPush {
  /**
   * Retrieve a list of "Push" nodes.
   */
  pushes: getPush_pushes[];
}

export interface getPushVariables {
  first: number;
  where?: PushWhereInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSegmentsByBrandKey
// ====================================================

export interface GetSegmentsByBrandKey_segments_pushSegments_segment {
  __typename: "Segment";
  title: string | null;
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface GetSegmentsByBrandKey_segments_pushSegments {
  __typename: "PushSegment";
  segment: GetSegmentsByBrandKey_segments_pushSegments_segment;
}

export interface GetSegmentsByBrandKey_segments {
  __typename: "Segment";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  title: string | null;
  active: boolean | null;
  device: string | null;
  brandKey: BrandKey | null;
  ios: string | null;
  android: string | null;
  editorialAlert: string | null;
  status: SegmentStatus;
  /**
   * "PushSegment.segment"'s inverse relation
   */
  pushSegments: GetSegmentsByBrandKey_segments_pushSegments[];
}

export interface GetSegmentsByBrandKey {
  /**
   * Retrieve a list of "Segment" nodes.
   */
  segments: GetSegmentsByBrandKey_segments[];
}

export interface GetSegmentsByBrandKeyVariables {
  first: number;
  where?: SegmentWhereInput | null;
  orderBy?: SegmentOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getOrphanPage
// ====================================================

export interface getOrphanPage_orphanPage {
  __typename: "OrphanPage";
  /**
   * The public ID, an UUID v4
   */
  id: string | null;
  publishedAt: GraphQL$DateTime | null;
  editedAt: GraphQL$DateTime | null;
  status: OrphanPageStatus;
  title: string;
  content: string | null;
  metas: string | null;
  isCMPEnabled: boolean;
  isAdsEnabled: boolean;
  isFullScreen: boolean | null;
  brandKey: BrandKey;
  uri: string | null;
}

export interface getOrphanPage {
  /**
   * Retrieve a single "OrphanPage" node.
   */
  orphanPage: getOrphanPage_orphanPage | null;
}

export interface getOrphanPageVariables {
  id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: upsertOrphanPage
// ====================================================

export interface upsertOrphanPage_upsertOrphanPage {
  __typename: "OrphanPage";
  /**
   * The public ID, an UUID v4
   */
  id: string | null;
  publishedAt: GraphQL$DateTime | null;
  editedAt: GraphQL$DateTime | null;
  status: OrphanPageStatus;
  title: string;
  content: string | null;
  metas: string | null;
  isCMPEnabled: boolean;
  isAdsEnabled: boolean;
  isFullScreen: boolean | null;
  brandKey: BrandKey;
  uri: string | null;
}

export interface upsertOrphanPage {
  /**
   * Update or create a single "OrphanPage" node.
   */
  upsertOrphanPage: upsertOrphanPage_upsertOrphanPage;
}

export interface upsertOrphanPageVariables {
  where: OrphanPageWhereUniqueInput;
  update: OrphanPageUpdateInput;
  create: OrphanPageCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePageCategory
// ====================================================

export interface UpdatePageCategory_updateCategory_lockedBy {
  __typename: "User";
  avatarUrl: string | null;
  email: string | null;
  id: string;
  name: string | null;
}

export interface UpdatePageCategory_updateCategory_parent {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface UpdatePageCategory_updateCategory_tabs {
  __typename: "Tab";
  content: string | null;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  description: string | null;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  isOnline: boolean;
  order: number;
  title: string;
  type: TabType;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  metas: string | null;
}

export interface UpdatePageCategory_updateCategory {
  __typename: "Category";
  brandKey: BrandKey;
  bodyDraft: string | null;
  /**
   * "CategoryHistory.category"'s inverse relation count
   */
  categoryHistoryCount: number;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  format: CategoryFormat;
  description: string | null;
  isHeadlined: boolean;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  level: number;
  /**
   * The date of the category's last lock
   */
  lockedAt: GraphQL$DateTime | null;
  /**
   * The user currently locking the campaign
   */
  lockedBy: UpdatePageCategory_updateCategory_lockedBy | null;
  medias: string | null;
  parent: UpdatePageCategory_updateCategory_parent | null;
  publishedAt: GraphQL$DateTime | null;
  slug: string;
  status: CategoryStatus;
  subtitle: string | null;
  /**
   * "Tab.category"'s inverse relation
   */
  tabs: UpdatePageCategory_updateCategory_tabs[];
  tags: string | null;
  title: string;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  editedAt: GraphQL$DateTime | null;
  urls: string | null;
}

export interface UpdatePageCategory {
  /**
   * Update a single "Category" node.
   */
  updateCategory: UpdatePageCategory_updateCategory | null;
}

export interface UpdatePageCategoryVariables {
  where: CategoryWhereUniqueInput;
  data: CategoryUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePageCategory
// ====================================================

export interface CreatePageCategory_createCategory_lockedBy {
  __typename: "User";
  avatarUrl: string | null;
  email: string | null;
  id: string;
  name: string | null;
}

export interface CreatePageCategory_createCategory_parent {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface CreatePageCategory_createCategory_tabs {
  __typename: "Tab";
  content: string | null;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  description: string | null;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  isOnline: boolean;
  order: number;
  title: string;
  type: TabType;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  metas: string | null;
}

export interface CreatePageCategory_createCategory {
  __typename: "Category";
  brandKey: BrandKey;
  bodyDraft: string | null;
  /**
   * "CategoryHistory.category"'s inverse relation count
   */
  categoryHistoryCount: number;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  format: CategoryFormat;
  description: string | null;
  isHeadlined: boolean;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  level: number;
  /**
   * The date of the category's last lock
   */
  lockedAt: GraphQL$DateTime | null;
  /**
   * The user currently locking the campaign
   */
  lockedBy: CreatePageCategory_createCategory_lockedBy | null;
  medias: string | null;
  parent: CreatePageCategory_createCategory_parent | null;
  publishedAt: GraphQL$DateTime | null;
  slug: string;
  status: CategoryStatus;
  subtitle: string | null;
  /**
   * "Tab.category"'s inverse relation
   */
  tabs: CreatePageCategory_createCategory_tabs[];
  tags: string | null;
  title: string;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  editedAt: GraphQL$DateTime | null;
  urls: string | null;
}

export interface CreatePageCategory {
  /**
   * Create a single "Category" node.
   */
  createCategory: CreatePageCategory_createCategory;
}

export interface CreatePageCategoryVariables {
  data: CategoryCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPageCategories
// ====================================================

export interface GetPageCategories_categories_lockedBy {
  __typename: "User";
  avatarUrl: string | null;
  email: string | null;
  id: string;
  name: string | null;
}

export interface GetPageCategories_categories_parent {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface GetPageCategories_categories_tabs {
  __typename: "Tab";
  content: string | null;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  description: string | null;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  isOnline: boolean;
  order: number;
  title: string;
  type: TabType;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  metas: string | null;
}

export interface GetPageCategories_categories_children_lockedBy {
  __typename: "User";
  avatarUrl: string | null;
  email: string | null;
  id: string;
  name: string | null;
}

export interface GetPageCategories_categories_children_parent {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface GetPageCategories_categories_children_tabs {
  __typename: "Tab";
  content: string | null;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  description: string | null;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  isOnline: boolean;
  order: number;
  title: string;
  type: TabType;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  metas: string | null;
}

export interface GetPageCategories_categories_children_children_lockedBy {
  __typename: "User";
  avatarUrl: string | null;
  email: string | null;
  id: string;
  name: string | null;
}

export interface GetPageCategories_categories_children_children_parent {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface GetPageCategories_categories_children_children_tabs {
  __typename: "Tab";
  content: string | null;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  description: string | null;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  isOnline: boolean;
  order: number;
  title: string;
  type: TabType;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  metas: string | null;
}

export interface GetPageCategories_categories_children_children_children_lockedBy {
  __typename: "User";
  avatarUrl: string | null;
  email: string | null;
  id: string;
  name: string | null;
}

export interface GetPageCategories_categories_children_children_children_parent {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface GetPageCategories_categories_children_children_children_tabs {
  __typename: "Tab";
  content: string | null;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  description: string | null;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  isOnline: boolean;
  order: number;
  title: string;
  type: TabType;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  metas: string | null;
}

export interface GetPageCategories_categories_children_children_children_children_lockedBy {
  __typename: "User";
  avatarUrl: string | null;
  email: string | null;
  id: string;
  name: string | null;
}

export interface GetPageCategories_categories_children_children_children_children_parent {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface GetPageCategories_categories_children_children_children_children_tabs {
  __typename: "Tab";
  content: string | null;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  description: string | null;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  isOnline: boolean;
  order: number;
  title: string;
  type: TabType;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  metas: string | null;
}

export interface GetPageCategories_categories_children_children_children_children {
  __typename: "Category";
  brandKey: BrandKey;
  bodyDraft: string | null;
  /**
   * "CategoryHistory.category"'s inverse relation count
   */
  categoryHistoryCount: number;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  format: CategoryFormat;
  description: string | null;
  isHeadlined: boolean;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  level: number;
  /**
   * The date of the category's last lock
   */
  lockedAt: GraphQL$DateTime | null;
  /**
   * The user currently locking the campaign
   */
  lockedBy: GetPageCategories_categories_children_children_children_children_lockedBy | null;
  medias: string | null;
  parent: GetPageCategories_categories_children_children_children_children_parent | null;
  publishedAt: GraphQL$DateTime | null;
  slug: string;
  status: CategoryStatus;
  subtitle: string | null;
  /**
   * "Tab.category"'s inverse relation
   */
  tabs: GetPageCategories_categories_children_children_children_children_tabs[];
  tags: string | null;
  title: string;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  editedAt: GraphQL$DateTime | null;
  urls: string | null;
}

export interface GetPageCategories_categories_children_children_children {
  __typename: "Category";
  brandKey: BrandKey;
  bodyDraft: string | null;
  /**
   * "CategoryHistory.category"'s inverse relation count
   */
  categoryHistoryCount: number;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  format: CategoryFormat;
  description: string | null;
  isHeadlined: boolean;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  level: number;
  /**
   * The date of the category's last lock
   */
  lockedAt: GraphQL$DateTime | null;
  /**
   * The user currently locking the campaign
   */
  lockedBy: GetPageCategories_categories_children_children_children_lockedBy | null;
  medias: string | null;
  parent: GetPageCategories_categories_children_children_children_parent | null;
  publishedAt: GraphQL$DateTime | null;
  slug: string;
  status: CategoryStatus;
  subtitle: string | null;
  /**
   * "Tab.category"'s inverse relation
   */
  tabs: GetPageCategories_categories_children_children_children_tabs[];
  tags: string | null;
  title: string;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  editedAt: GraphQL$DateTime | null;
  urls: string | null;
  /**
   * "Category.parent"'s inverse relation
   */
  children: GetPageCategories_categories_children_children_children_children[];
}

export interface GetPageCategories_categories_children_children {
  __typename: "Category";
  brandKey: BrandKey;
  bodyDraft: string | null;
  /**
   * "CategoryHistory.category"'s inverse relation count
   */
  categoryHistoryCount: number;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  format: CategoryFormat;
  description: string | null;
  isHeadlined: boolean;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  level: number;
  /**
   * The date of the category's last lock
   */
  lockedAt: GraphQL$DateTime | null;
  /**
   * The user currently locking the campaign
   */
  lockedBy: GetPageCategories_categories_children_children_lockedBy | null;
  medias: string | null;
  parent: GetPageCategories_categories_children_children_parent | null;
  publishedAt: GraphQL$DateTime | null;
  slug: string;
  status: CategoryStatus;
  subtitle: string | null;
  /**
   * "Tab.category"'s inverse relation
   */
  tabs: GetPageCategories_categories_children_children_tabs[];
  tags: string | null;
  title: string;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  editedAt: GraphQL$DateTime | null;
  urls: string | null;
  /**
   * "Category.parent"'s inverse relation
   */
  children: GetPageCategories_categories_children_children_children[];
}

export interface GetPageCategories_categories_children {
  __typename: "Category";
  brandKey: BrandKey;
  bodyDraft: string | null;
  /**
   * "CategoryHistory.category"'s inverse relation count
   */
  categoryHistoryCount: number;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  format: CategoryFormat;
  description: string | null;
  isHeadlined: boolean;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  level: number;
  /**
   * The date of the category's last lock
   */
  lockedAt: GraphQL$DateTime | null;
  /**
   * The user currently locking the campaign
   */
  lockedBy: GetPageCategories_categories_children_lockedBy | null;
  medias: string | null;
  parent: GetPageCategories_categories_children_parent | null;
  publishedAt: GraphQL$DateTime | null;
  slug: string;
  status: CategoryStatus;
  subtitle: string | null;
  /**
   * "Tab.category"'s inverse relation
   */
  tabs: GetPageCategories_categories_children_tabs[];
  tags: string | null;
  title: string;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  editedAt: GraphQL$DateTime | null;
  urls: string | null;
  /**
   * "Category.parent"'s inverse relation
   */
  children: GetPageCategories_categories_children_children[];
}

export interface GetPageCategories_categories {
  __typename: "Category";
  brandKey: BrandKey;
  bodyDraft: string | null;
  /**
   * "CategoryHistory.category"'s inverse relation count
   */
  categoryHistoryCount: number;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  format: CategoryFormat;
  description: string | null;
  isHeadlined: boolean;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  level: number;
  /**
   * The date of the category's last lock
   */
  lockedAt: GraphQL$DateTime | null;
  /**
   * The user currently locking the campaign
   */
  lockedBy: GetPageCategories_categories_lockedBy | null;
  medias: string | null;
  parent: GetPageCategories_categories_parent | null;
  publishedAt: GraphQL$DateTime | null;
  slug: string;
  status: CategoryStatus;
  subtitle: string | null;
  /**
   * "Tab.category"'s inverse relation
   */
  tabs: GetPageCategories_categories_tabs[];
  tags: string | null;
  title: string;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  editedAt: GraphQL$DateTime | null;
  urls: string | null;
  /**
   * "Category.parent"'s inverse relation
   */
  children: GetPageCategories_categories_children[];
}

export interface GetPageCategories {
  /**
   * Retrieve a list of "Category" nodes.
   */
  categories: GetPageCategories_categories[];
}

export interface GetPageCategoriesVariables {
  first: number;
  where: CategoryWhereInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPageCategoryHistory
// ====================================================

export interface GetPageCategoryHistory_categoryHistories_category_lockedBy {
  __typename: "User";
  avatarUrl: string | null;
  email: string | null;
  id: string;
  name: string | null;
}

export interface GetPageCategoryHistory_categoryHistories_category_parent {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface GetPageCategoryHistory_categoryHistories_category_tabs {
  __typename: "Tab";
  content: string | null;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  description: string | null;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  isOnline: boolean;
  order: number;
  title: string;
  type: TabType;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  metas: string | null;
}

export interface GetPageCategoryHistory_categoryHistories_category {
  __typename: "Category";
  brandKey: BrandKey;
  bodyDraft: string | null;
  /**
   * "CategoryHistory.category"'s inverse relation count
   */
  categoryHistoryCount: number;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  format: CategoryFormat;
  description: string | null;
  isHeadlined: boolean;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  level: number;
  /**
   * The date of the category's last lock
   */
  lockedAt: GraphQL$DateTime | null;
  /**
   * The user currently locking the campaign
   */
  lockedBy: GetPageCategoryHistory_categoryHistories_category_lockedBy | null;
  medias: string | null;
  parent: GetPageCategoryHistory_categoryHistories_category_parent | null;
  publishedAt: GraphQL$DateTime | null;
  slug: string;
  status: CategoryStatus;
  subtitle: string | null;
  /**
   * "Tab.category"'s inverse relation
   */
  tabs: GetPageCategoryHistory_categoryHistories_category_tabs[];
  tags: string | null;
  title: string;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  editedAt: GraphQL$DateTime | null;
  urls: string | null;
}

export interface GetPageCategoryHistory_categoryHistories_user {
  __typename: "User";
  avatarUrl: string | null;
  email: string | null;
  id: string;
  name: string | null;
}

export interface GetPageCategoryHistory_categoryHistories {
  __typename: "CategoryHistory";
  category: GetPageCategoryHistory_categoryHistories_category;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  status: CategoryStatus;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  user: GetPageCategoryHistory_categoryHistories_user | null;
}

export interface GetPageCategoryHistory {
  /**
   * Retrieve a list of "CategoryHistory" nodes.
   */
  categoryHistories: GetPageCategoryHistory_categoryHistories[];
}

export interface GetPageCategoryHistoryVariables {
  where?: CategoryHistoryWhereInput | null;
  orderBy?: CategoryHistoryOrderByInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBrands
// ====================================================

export interface GetBrands_categories {
  __typename: "Category";
  brandKey: BrandKey;
  title: string;
}

export interface GetBrands {
  /**
   * Retrieve a list of "Category" nodes.
   */
  categories: GetBrands_categories[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBrandCategories
// ====================================================

export interface GetBrandCategories_categories_children_children_children {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  level: number;
  status: CategoryStatus;
}

export interface GetBrandCategories_categories_children_children {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  level: number;
  status: CategoryStatus;
  /**
   * "Category.parent"'s inverse relation
   */
  children: GetBrandCategories_categories_children_children_children[];
}

export interface GetBrandCategories_categories_children {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  level: number;
  status: CategoryStatus;
  /**
   * "Category.parent"'s inverse relation
   */
  children: GetBrandCategories_categories_children_children[];
}

export interface GetBrandCategories_categories {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  level: number;
  status: CategoryStatus;
  /**
   * "Category.parent"'s inverse relation
   */
  children: GetBrandCategories_categories_children[];
}

export interface GetBrandCategories {
  /**
   * Retrieve a list of "Category" nodes.
   */
  categories: GetBrandCategories_categories[];
}

export interface GetBrandCategoriesVariables {
  brandKey: BrandKey;
  format?: CategoryFormat | null;
  status?: CategoryStatus | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBrandFilterCategories
// ====================================================

export interface GetBrandFilterCategories_categories_children_children_children {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  level: number;
  status: CategoryStatus;
}

export interface GetBrandFilterCategories_categories_children_children {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  level: number;
  status: CategoryStatus;
  /**
   * "Category.parent"'s inverse relation
   */
  children: GetBrandFilterCategories_categories_children_children_children[];
}

export interface GetBrandFilterCategories_categories_children {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  level: number;
  status: CategoryStatus;
  /**
   * "Category.parent"'s inverse relation
   */
  children: GetBrandFilterCategories_categories_children_children[];
}

export interface GetBrandFilterCategories_categories {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  level: number;
  status: CategoryStatus;
  /**
   * "Category.parent"'s inverse relation
   */
  children: GetBrandFilterCategories_categories_children[];
}

export interface GetBrandFilterCategories {
  /**
   * Retrieve a list of "Category" nodes.
   */
  categories: GetBrandFilterCategories_categories[];
}

export interface GetBrandFilterCategoriesVariables {
  brandKey: BrandKey;
  format?: CategoryFormat | null;
  status?: CategoryStatus | null;
  title_not_in?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ContextualizedUrlFragment
// ====================================================

export interface ContextualizedUrlFragment {
  __typename: "Article";
  /**
   * The proper article url based on the user device and the server environment
   */
  contextualizedUrl: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CommonArticleFragment
// ====================================================

export interface CommonArticleFragment_live {
  __typename: "Live";
  /**
   * "LivePost.live"'s inverse relation count
   */
  postCount: number;
}

export interface CommonArticleFragment_articleCategories_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface CommonArticleFragment_articleCategories {
  __typename: "ArticleCategory";
  order: number;
  category: CommonArticleFragment_articleCategories_category;
}

export interface CommonArticleFragment_articleChannels_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface CommonArticleFragment_articleChannels {
  __typename: "ArticleChannel";
  order: number;
  category: CommonArticleFragment_articleChannels_category;
}

export interface CommonArticleFragment_articleCreatorData {
  __typename: "ArticleCreatorData";
  name: string;
}

export interface CommonArticleFragment_articleUrls_url {
  __typename: "Url";
  path: string;
  domain: string;
}

export interface CommonArticleFragment_articleUrls {
  __typename: "ArticleUrl";
  url: CommonArticleFragment_articleUrls_url;
}

export interface CommonArticleFragment_articleQualifiers_qualifier {
  __typename: "Qualifier";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface CommonArticleFragment_articleQualifiers {
  __typename: "ArticleQualifier";
  order: number;
  qualifier: CommonArticleFragment_articleQualifiers_qualifier;
}

export interface CommonArticleFragment_articleExtension_lockedBy {
  __typename: "User";
  id: string;
  name: string | null;
  email: string | null;
  avatarUrl: string | null;
}

export interface CommonArticleFragment_articleExtension {
  __typename: "ArticleExtension";
  /**
   * The date of the article's last lock
   */
  lockedAt: GraphQL$DateTime | null;
  /**
   * The user currently locking the campaign
   */
  lockedBy: CommonArticleFragment_articleExtension_lockedBy | null;
}

export interface CommonArticleFragment {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  status: ArticleStatus;
  body: string | null;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  publishedAt: GraphQL$DateTime | null;
  editedAt: GraphQL$DateTime | null;
  brandKey: BrandKey;
  format: ArticleFormat;
  medias: string | null;
  /**
   * "Push.article"'s inverse relation count
   */
  pushCount: number;
  /**
   * "Live.article"'s inverse relation
   */
  live: CommonArticleFragment_live | null;
  /**
   * "ArticleCategory.article"'s inverse relation
   */
  articleCategories: CommonArticleFragment_articleCategories[];
  /**
   * "ArticleChannel.article"'s inverse relation
   */
  articleChannels: CommonArticleFragment_articleChannels[];
  /**
   * "ArticleCreatorData.article"'s inverse relation
   */
  articleCreatorData: CommonArticleFragment_articleCreatorData | null;
  /**
   * "ArticleUrl.article"'s inverse relation
   */
  articleUrls: CommonArticleFragment_articleUrls[];
  /**
   * "ArticleQualifier.article"'s inverse relation
   */
  articleQualifiers: CommonArticleFragment_articleQualifiers[];
  /**
   * "ArticleExtension.article"'s inverse relation
   */
  articleExtension: CommonArticleFragment_articleExtension | null;
  /**
   * The proper article url based on the user device and the server environment
   */
  contextualizedUrl: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ArticleFragment
// ====================================================

export interface ArticleFragment_live {
  __typename: "Live";
  /**
   * "LivePost.live"'s inverse relation count
   */
  postCount: number;
}

export interface ArticleFragment_articleCategories_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface ArticleFragment_articleCategories {
  __typename: "ArticleCategory";
  order: number;
  category: ArticleFragment_articleCategories_category;
}

export interface ArticleFragment_articleChannels_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface ArticleFragment_articleChannels {
  __typename: "ArticleChannel";
  order: number;
  category: ArticleFragment_articleChannels_category;
}

export interface ArticleFragment_articleCreatorData {
  __typename: "ArticleCreatorData";
  name: string;
}

export interface ArticleFragment_articleUrls_url {
  __typename: "Url";
  path: string;
  domain: string;
}

export interface ArticleFragment_articleUrls {
  __typename: "ArticleUrl";
  url: ArticleFragment_articleUrls_url;
}

export interface ArticleFragment_articleQualifiers_qualifier {
  __typename: "Qualifier";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface ArticleFragment_articleQualifiers {
  __typename: "ArticleQualifier";
  order: number;
  qualifier: ArticleFragment_articleQualifiers_qualifier;
}

export interface ArticleFragment_articleExtension_lockedBy {
  __typename: "User";
  id: string;
  name: string | null;
  email: string | null;
  avatarUrl: string | null;
}

export interface ArticleFragment_articleExtension {
  __typename: "ArticleExtension";
  /**
   * The date of the article's last lock
   */
  lockedAt: GraphQL$DateTime | null;
  /**
   * The user currently locking the campaign
   */
  lockedBy: ArticleFragment_articleExtension_lockedBy | null;
}

export interface ArticleFragment_articleEvents_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface ArticleFragment_articleEvents {
  __typename: "ArticleEvent";
  category: ArticleFragment_articleEvents_category;
  order: number;
}

export interface ArticleFragment_articleAuthors_author {
  __typename: "Author";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  name: string;
  avatarUrl: string | null;
}

export interface ArticleFragment_articleAuthors {
  __typename: "ArticleAuthor";
  author: ArticleFragment_articleAuthors_author;
}

export interface ArticleFragment {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  status: ArticleStatus;
  body: string | null;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  publishedAt: GraphQL$DateTime | null;
  editedAt: GraphQL$DateTime | null;
  brandKey: BrandKey;
  format: ArticleFormat;
  medias: string | null;
  /**
   * "Push.article"'s inverse relation count
   */
  pushCount: number;
  /**
   * "Live.article"'s inverse relation
   */
  live: ArticleFragment_live | null;
  /**
   * "ArticleCategory.article"'s inverse relation
   */
  articleCategories: ArticleFragment_articleCategories[];
  /**
   * "ArticleChannel.article"'s inverse relation
   */
  articleChannels: ArticleFragment_articleChannels[];
  /**
   * "ArticleCreatorData.article"'s inverse relation
   */
  articleCreatorData: ArticleFragment_articleCreatorData | null;
  /**
   * "ArticleUrl.article"'s inverse relation
   */
  articleUrls: ArticleFragment_articleUrls[];
  /**
   * "ArticleQualifier.article"'s inverse relation
   */
  articleQualifiers: ArticleFragment_articleQualifiers[];
  /**
   * "ArticleExtension.article"'s inverse relation
   */
  articleExtension: ArticleFragment_articleExtension | null;
  /**
   * The proper article url based on the user device and the server environment
   */
  contextualizedUrl: string | null;
  /**
   * "ArticleEvent.article"'s inverse relation
   */
  articleEvents: ArticleFragment_articleEvents[];
  /**
   * "ArticleAuthor.article"'s inverse relation
   */
  articleAuthors: ArticleFragment_articleAuthors[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ArticleDetailsFragment
// ====================================================

export interface ArticleDetailsFragment_live {
  __typename: "Live";
  /**
   * "LivePost.live"'s inverse relation count
   */
  postCount: number;
  endedAt: GraphQL$DateTime | null;
}

export interface ArticleDetailsFragment_articleCategories_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface ArticleDetailsFragment_articleCategories {
  __typename: "ArticleCategory";
  order: number;
  category: ArticleDetailsFragment_articleCategories_category;
}

export interface ArticleDetailsFragment_articleChannels_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface ArticleDetailsFragment_articleChannels {
  __typename: "ArticleChannel";
  order: number;
  category: ArticleDetailsFragment_articleChannels_category;
}

export interface ArticleDetailsFragment_articleCreatorData {
  __typename: "ArticleCreatorData";
  name: string;
}

export interface ArticleDetailsFragment_articleUrls_url {
  __typename: "Url";
  path: string;
  domain: string;
}

export interface ArticleDetailsFragment_articleUrls {
  __typename: "ArticleUrl";
  url: ArticleDetailsFragment_articleUrls_url;
}

export interface ArticleDetailsFragment_articleQualifiers_qualifier {
  __typename: "Qualifier";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface ArticleDetailsFragment_articleQualifiers {
  __typename: "ArticleQualifier";
  order: number;
  qualifier: ArticleDetailsFragment_articleQualifiers_qualifier;
}

export interface ArticleDetailsFragment_articleExtension_lockedBy {
  __typename: "User";
  id: string;
  name: string | null;
  email: string | null;
  avatarUrl: string | null;
}

export interface ArticleDetailsFragment_articleExtension {
  __typename: "ArticleExtension";
  /**
   * The date of the article's last lock
   */
  lockedAt: GraphQL$DateTime | null;
  /**
   * The user currently locking the campaign
   */
  lockedBy: ArticleDetailsFragment_articleExtension_lockedBy | null;
}

export interface ArticleDetailsFragment_articleTags_tag {
  __typename: "Tag";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  relationId: string | null;
  title: string;
  type: TagType;
}

export interface ArticleDetailsFragment_articleTags {
  __typename: "ArticleTag";
  order: number;
  tag: ArticleDetailsFragment_articleTags_tag;
}

export interface ArticleDetailsFragment_articleAuthors_author_user {
  __typename: "User";
  id: string;
  name: string | null;
  avatarUrl: string | null;
}

export interface ArticleDetailsFragment_articleAuthors_author {
  __typename: "Author";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  name: string;
  user: ArticleDetailsFragment_articleAuthors_author_user | null;
}

export interface ArticleDetailsFragment_articleAuthors {
  __typename: "ArticleAuthor";
  order: number;
  author: ArticleDetailsFragment_articleAuthors_author;
}

export interface ArticleDetailsFragment_source {
  __typename: "Source";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  status: SourceStatus;
}

export interface ArticleDetailsFragment_articleEvents_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface ArticleDetailsFragment_articleEvents {
  __typename: "ArticleEvent";
  order: number;
  category: ArticleDetailsFragment_articleEvents_category;
}

export interface ArticleDetailsFragment_articleGuides_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface ArticleDetailsFragment_articleGuides {
  __typename: "ArticleGuide";
  order: number;
  category: ArticleDetailsFragment_articleGuides_category;
}

export interface ArticleDetailsFragment_articleMachineTags_machineTag {
  __typename: "MachineTag";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  tag: string;
}

export interface ArticleDetailsFragment_articleMachineTags {
  __typename: "ArticleMachineTag";
  order: number;
  machineTag: ArticleDetailsFragment_articleMachineTags_machineTag;
}

export interface ArticleDetailsFragment_parentArticle_article {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  brandKey: BrandKey;
}

export interface ArticleDetailsFragment_parentArticle {
  __typename: "ArticleHistory";
  article: ArticleDetailsFragment_parentArticle_article;
}

export interface ArticleDetailsFragment_articleHistories_user {
  __typename: "User";
  id: string;
  name: string | null;
  avatarUrl: string | null;
}

export interface ArticleDetailsFragment_articleHistories_article {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  /**
   * Defines whether an article is confidential or not
   */
  isClassified: boolean | null;
}

export interface ArticleDetailsFragment_articleHistories_duplicatedArticle {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  brandKey: BrandKey;
}

export interface ArticleDetailsFragment_articleHistories {
  __typename: "ArticleHistory";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  user: ArticleDetailsFragment_articleHistories_user | null;
  status: ArticleStatus;
  article: ArticleDetailsFragment_articleHistories_article;
  /**
   * This relation is used to keep track of article duplications
   */
  duplicatedArticle: ArticleDetailsFragment_articleHistories_duplicatedArticle | null;
}

export interface ArticleDetailsFragment {
  __typename: "Article";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  status: ArticleStatus;
  body: string | null;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  publishedAt: GraphQL$DateTime | null;
  editedAt: GraphQL$DateTime | null;
  brandKey: BrandKey;
  format: ArticleFormat;
  medias: string | null;
  /**
   * "Push.article"'s inverse relation count
   */
  pushCount: number;
  /**
   * "Live.article"'s inverse relation
   */
  live: ArticleDetailsFragment_live | null;
  /**
   * "ArticleCategory.article"'s inverse relation
   */
  articleCategories: ArticleDetailsFragment_articleCategories[];
  /**
   * "ArticleChannel.article"'s inverse relation
   */
  articleChannels: ArticleDetailsFragment_articleChannels[];
  /**
   * "ArticleCreatorData.article"'s inverse relation
   */
  articleCreatorData: ArticleDetailsFragment_articleCreatorData | null;
  /**
   * "ArticleUrl.article"'s inverse relation
   */
  articleUrls: ArticleDetailsFragment_articleUrls[];
  /**
   * "ArticleQualifier.article"'s inverse relation
   */
  articleQualifiers: ArticleDetailsFragment_articleQualifiers[];
  /**
   * "ArticleExtension.article"'s inverse relation
   */
  articleExtension: ArticleDetailsFragment_articleExtension | null;
  /**
   * The proper article url based on the user device and the server environment
   */
  contextualizedUrl: string | null;
  secondaryTitle: string | null;
  partnerTitle: string | null;
  lead: string | null;
  slug: string | null;
  /**
   * "ArticleTag.article"'s inverse relation
   */
  articleTags: ArticleDetailsFragment_articleTags[];
  /**
   * "ArticleAuthor.article"'s inverse relation
   */
  articleAuthors: ArticleDetailsFragment_articleAuthors[];
  source: ArticleDetailsFragment_source | null;
  /**
   * "ArticleEvent.article"'s inverse relation
   */
  articleEvents: ArticleDetailsFragment_articleEvents[];
  /**
   * "ArticleGuide.article"'s inverse relation
   */
  articleGuides: ArticleDetailsFragment_articleGuides[];
  /**
   * "ArticleMachineTag.article"'s inverse relation
   */
  articleMachineTags: ArticleDetailsFragment_articleMachineTags[];
  /**
   * "ArticleHistory.duplicatedArticle"'s inverse relation
   */
  parentArticle: ArticleDetailsFragment_parentArticle[];
  /**
   * "ArticleHistory.article"'s inverse relation
   */
  articleHistories: ArticleDetailsFragment_articleHistories[];
  platformId: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AttributeFragment
// ====================================================

export interface AttributeFragment_authorAttributes_author {
  __typename: "Author";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  name: string;
}

export interface AttributeFragment_authorAttributes {
  __typename: "AuthorAttribute";
  author: AttributeFragment_authorAttributes_author;
}

export interface AttributeFragment {
  __typename: "Attribute";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  brandKey: BrandKey;
  slug: string;
  /**
   * "AuthorAttribute.attribute"'s inverse relation
   */
  authorAttributes: AttributeFragment_authorAttributes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AuthorFragment
// ====================================================

export interface AuthorFragment_authorHistories_user {
  __typename: "User";
  id: string;
  name: string | null;
  avatarUrl: string | null;
}

export interface AuthorFragment_authorHistories {
  __typename: "AuthorHistory";
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  user: AuthorFragment_authorHistories_user | null;
}

export interface AuthorFragment_authorAttributes_attribute {
  __typename: "Attribute";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
}

export interface AuthorFragment_authorAttributes {
  __typename: "AuthorAttribute";
  order: number;
  attribute: AuthorFragment_authorAttributes_attribute;
}

export interface AuthorFragment {
  __typename: "Author";
  avatarUrl: string | null;
  brandKey: BrandKey;
  description: string | null;
  facebookId: string | null;
  googleId: string | null;
  hasPublicProfile: boolean | null;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  instagramId: string | null;
  isMain: boolean | null;
  linkedinId: string | null;
  media: string | null;
  name: string;
  pinterestId: string | null;
  snapshatId: string | null;
  twitterId: string | null;
  userId: string | null;
  resume: string | null;
  email: string | null;
  /**
   * "AuthorHistory.author"'s inverse relation
   */
  authorHistories: AuthorFragment_authorHistories[];
  /**
   * "AuthorAttribute.author"'s inverse relation
   */
  authorAttributes: AuthorFragment_authorAttributes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrphanPageFragment
// ====================================================

export interface OrphanPageFragment {
  __typename: "OrphanPage";
  /**
   * The public ID, an UUID v4
   */
  id: string | null;
  publishedAt: GraphQL$DateTime | null;
  editedAt: GraphQL$DateTime | null;
  status: OrphanPageStatus;
  title: string;
  content: string | null;
  metas: string | null;
  isCMPEnabled: boolean;
  isAdsEnabled: boolean;
  isFullScreen: boolean | null;
  brandKey: BrandKey;
  uri: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PromptFragment
// ====================================================

export interface PromptFragment {
  __typename: "Prompt";
  field: PromptField;
  label: string;
  body: string;
  brandKey: BrandKey;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SegmentFragment
// ====================================================

export interface SegmentFragment_pushSegments_segment {
  __typename: "Segment";
  title: string | null;
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface SegmentFragment_pushSegments {
  __typename: "PushSegment";
  segment: SegmentFragment_pushSegments_segment;
}

export interface SegmentFragment {
  __typename: "Segment";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  title: string | null;
  active: boolean | null;
  device: string | null;
  brandKey: BrandKey | null;
  ios: string | null;
  android: string | null;
  editorialAlert: string | null;
  status: SegmentStatus;
  /**
   * "PushSegment.segment"'s inverse relation
   */
  pushSegments: SegmentFragment_pushSegments[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SearchTagFragment
// ====================================================

export interface SearchTagFragment {
  __typename: "TagProviderResult";
  id: string | null;
  title: string;
  type: TagType;
  relationId: string | null;
  /**
   * Number of articles associated with this tag
   */
  articleCount: number | null;
  isEnabled: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: cardFragment
// ====================================================

export interface cardFragment_cardCategories_category {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  level: number;
  title: string;
}

export interface cardFragment_cardCategories {
  __typename: "CardCategory";
  order: number;
  category: cardFragment_cardCategories_category;
}

export interface cardFragment_cardTags_tag {
  __typename: "Tag";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  type: TagType;
}

export interface cardFragment_cardTags {
  __typename: "CardTag";
  order: number;
  tag: cardFragment_cardTags_tag;
}

export interface cardFragment {
  __typename: "Card";
  /**
   * The public ID, an UUID v4
   */
  id: string | null;
  title: string;
  secondaryTitle: string | null;
  description: string | null;
  url: string;
  label: string;
  brandKey: BrandKey;
  status: CardStatus;
  media: string | null;
  body: string | null;
  /**
   * "CardCategory.card"'s inverse relation
   */
  cardCategories: cardFragment_cardCategories[];
  /**
   * "CardTag.card"'s inverse relation
   */
  cardTags: cardFragment_cardTags[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LivePostFragment
// ====================================================

export interface LivePostFragment_live {
  __typename: "Live";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  endedAt: GraphQL$DateTime | null;
}

export interface LivePostFragment {
  __typename: "LivePost";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  publishedAt: GraphQL$DateTime;
  title: string | null;
  body: string | null;
  live: LivePostFragment_live;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LiveFragment
// ====================================================

export interface LiveFragment_posts {
  __typename: "LivePost";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  publishedAt: GraphQL$DateTime;
  title: string | null;
  body: string | null;
}

export interface LiveFragment {
  __typename: "Live";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  endedAt: GraphQL$DateTime | null;
  /**
   * "LivePost.live"'s inverse relation
   */
  posts: LiveFragment_posts[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CategoryFragment
// ====================================================

export interface CategoryFragment_lockedBy {
  __typename: "User";
  avatarUrl: string | null;
  email: string | null;
  id: string;
  name: string | null;
}

export interface CategoryFragment_parent {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
}

export interface CategoryFragment_tabs {
  __typename: "Tab";
  content: string | null;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  description: string | null;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  isOnline: boolean;
  order: number;
  title: string;
  type: TabType;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  metas: string | null;
}

export interface CategoryFragment {
  __typename: "Category";
  brandKey: BrandKey;
  bodyDraft: string | null;
  /**
   * "CategoryHistory.category"'s inverse relation count
   */
  categoryHistoryCount: number;
  /**
   * The date, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  format: CategoryFormat;
  description: string | null;
  isHeadlined: boolean;
  /**
   * The public ID, an UUID v4
   */
  id: string;
  level: number;
  /**
   * The date of the category's last lock
   */
  lockedAt: GraphQL$DateTime | null;
  /**
   * The user currently locking the campaign
   */
  lockedBy: CategoryFragment_lockedBy | null;
  medias: string | null;
  parent: CategoryFragment_parent | null;
  publishedAt: GraphQL$DateTime | null;
  slug: string;
  status: CategoryStatus;
  subtitle: string | null;
  /**
   * "Tab.category"'s inverse relation
   */
  tabs: CategoryFragment_tabs[];
  tags: string | null;
  title: string;
  /**
   * The date of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  editedAt: GraphQL$DateTime | null;
  urls: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BrandCategoryFragment
// ====================================================

export interface BrandCategoryFragment {
  __typename: "Category";
  /**
   * The public ID, an UUID v4
   */
  id: string;
  title: string;
  level: number;
  status: CategoryStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AffilizzProductSearchSor {
  NB_OFFERS = "NB_OFFERS",
  PRICE_ASC = "PRICE_ASC",
  PRICE_DESC = "PRICE_DESC",
  RELEVANCE = "RELEVANCE",
}

export enum AffilizzProductType {
  CatalogProduct = "CatalogProduct",
  Product = "Product",
}

export enum ArticleFormat {
  Rich = "Rich",
  Slideshow = "Slideshow",
  Video = "Video",
}

export enum ArticleOrderByInput {
  _id_ASC = "_id_ASC",
  _id_DESC = "_id_DESC",
  bodyMedias_ASC = "bodyMedias_ASC",
  bodyMedias_DESC = "bodyMedias_DESC",
  body_ASC = "body_ASC",
  body_DESC = "body_DESC",
  brandKey_ASC = "brandKey_ASC",
  brandKey_DESC = "brandKey_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  creatorId_ASC = "creatorId_ASC",
  creatorId_DESC = "creatorId_DESC",
  discoverTitle_ASC = "discoverTitle_ASC",
  discoverTitle_DESC = "discoverTitle_DESC",
  editedAt_ASC = "editedAt_ASC",
  editedAt_DESC = "editedAt_DESC",
  format_ASC = "format_ASC",
  format_DESC = "format_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  lead_ASC = "lead_ASC",
  lead_DESC = "lead_DESC",
  medias_ASC = "medias_ASC",
  medias_DESC = "medias_DESC",
  partnerTitle_ASC = "partnerTitle_ASC",
  partnerTitle_DESC = "partnerTitle_DESC",
  platformId_ASC = "platformId_ASC",
  platformId_DESC = "platformId_DESC",
  platform_ASC = "platform_ASC",
  platform_DESC = "platform_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  secondaryTitle_ASC = "secondaryTitle_ASC",
  secondaryTitle_DESC = "secondaryTitle_DESC",
  signature_ASC = "signature_ASC",
  signature_DESC = "signature_DESC",
  slug_ASC = "slug_ASC",
  slug_DESC = "slug_DESC",
  status_ASC = "status_ASC",
  status_DESC = "status_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum ArticleSearchFieldsType {
  Body = "Body",
  BodyMedias = "BodyMedias",
  Lead = "Lead",
  Medias = "Medias",
  Title = "Title",
}

export enum ArticleSearchOrderByInput {
  brandKey_ASC = "brandKey_ASC",
  brandKey_DESC = "brandKey_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  creatorId_ASC = "creatorId_ASC",
  creatorId_DESC = "creatorId_DESC",
  editedAt_ASC = "editedAt_ASC",
  editedAt_DESC = "editedAt_DESC",
  format_ASC = "format_ASC",
  format_DESC = "format_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  status_ASC = "status_ASC",
  status_DESC = "status_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum ArticleStatus {
  Deleted = "Deleted",
  Draft = "Draft",
  Frozen = "Frozen",
  Pending = "Pending",
  Private = "Private",
  Proposal = "Proposal",
  Published = "Published",
  Ready = "Ready",
  Scheduled = "Scheduled",
  Todo = "Todo",
}

export enum AttributeOrderByInput {
  _id_ASC = "_id_ASC",
  _id_DESC = "_id_DESC",
  brandKey_ASC = "brandKey_ASC",
  brandKey_DESC = "brandKey_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  slug_ASC = "slug_ASC",
  slug_DESC = "slug_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum AuthorOrderByInput {
  _id_ASC = "_id_ASC",
  _id_DESC = "_id_DESC",
  activeOnBrandKeys_ASC = "activeOnBrandKeys_ASC",
  activeOnBrandKeys_DESC = "activeOnBrandKeys_DESC",
  avatarUrl_ASC = "avatarUrl_ASC",
  avatarUrl_DESC = "avatarUrl_DESC",
  brandKey_ASC = "brandKey_ASC",
  brandKey_DESC = "brandKey_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  description_ASC = "description_ASC",
  description_DESC = "description_DESC",
  email_ASC = "email_ASC",
  email_DESC = "email_DESC",
  existsOnBrandKeys_ASC = "existsOnBrandKeys_ASC",
  existsOnBrandKeys_DESC = "existsOnBrandKeys_DESC",
  facebookId_ASC = "facebookId_ASC",
  facebookId_DESC = "facebookId_DESC",
  googleId_ASC = "googleId_ASC",
  googleId_DESC = "googleId_DESC",
  hasPublicProfile_ASC = "hasPublicProfile_ASC",
  hasPublicProfile_DESC = "hasPublicProfile_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  instagramId_ASC = "instagramId_ASC",
  instagramId_DESC = "instagramId_DESC",
  isMain_ASC = "isMain_ASC",
  isMain_DESC = "isMain_DESC",
  linkedinId_ASC = "linkedinId_ASC",
  linkedinId_DESC = "linkedinId_DESC",
  media_ASC = "media_ASC",
  media_DESC = "media_DESC",
  name_ASC = "name_ASC",
  name_DESC = "name_DESC",
  pinterestId_ASC = "pinterestId_ASC",
  pinterestId_DESC = "pinterestId_DESC",
  prismaConnectId_ASC = "prismaConnectId_ASC",
  prismaConnectId_DESC = "prismaConnectId_DESC",
  resume_ASC = "resume_ASC",
  resume_DESC = "resume_DESC",
  snapshatId_ASC = "snapshatId_ASC",
  snapshatId_DESC = "snapshatId_DESC",
  twitterId_ASC = "twitterId_ASC",
  twitterId_DESC = "twitterId_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
  userId_ASC = "userId_ASC",
  userId_DESC = "userId_DESC",
}

export enum BrandKey {
  BEA = "BEA",
  BIN = "BIN",
  BONE = "BONE",
  BRGTS = "BRGTS",
  BROMM = "BROMM",
  CAC = "CAC",
  CAM = "CAM",
  CAP = "CAP",
  CTR = "CTR",
  DEGTS = "DEGTS",
  DEOMM = "DEOMM",
  DESAS = "DESAS",
  ESGTS = "ESGTS",
  ESOMM = "ESOMM",
  FAC = "FAC",
  FRGSD = "FRGSD",
  FRGSG = "FRGSG",
  FRGSL = "FRGSL",
  FRGSS = "FRGSS",
  FRGSV = "FRGSV",
  FROMM = "FROMM",
  GAL = "GAL",
  GEO = "GEO",
  HBR = "HBR",
  HBZ = "HBZ",
  ITGTS = "ITGTS",
  ITOMM = "ITOMM",
  NEO = "NEO",
  SHGFAC = "SHGFAC",
  SIM = "SIM",
  TEL = "TEL",
  TESTBRAND = "TESTBRAND",
  TGC = "TGC",
  TLA = "TLA",
  TLS = "TLS",
  UKGTS = "UKGTS",
  UKOMM = "UKOMM",
  UKSAS = "UKSAS",
  VDF = "VDF",
  VOI = "VOI",
}

export enum CardOrderByInput {
  _id_ASC = "_id_ASC",
  _id_DESC = "_id_DESC",
  body_ASC = "body_ASC",
  body_DESC = "body_DESC",
  brandKey_ASC = "brandKey_ASC",
  brandKey_DESC = "brandKey_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  description_ASC = "description_ASC",
  description_DESC = "description_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  label_ASC = "label_ASC",
  label_DESC = "label_DESC",
  media_ASC = "media_ASC",
  media_DESC = "media_DESC",
  secondaryTitle_ASC = "secondaryTitle_ASC",
  secondaryTitle_DESC = "secondaryTitle_DESC",
  slug_ASC = "slug_ASC",
  slug_DESC = "slug_DESC",
  status_ASC = "status_ASC",
  status_DESC = "status_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
  url_ASC = "url_ASC",
  url_DESC = "url_DESC",
}

export enum CardStatus {
  Archived = "Archived",
  Published = "Published",
}

export enum CategoryFormat {
  Category = "Category",
  Channel = "Channel",
  Event = "Event",
  Guide = "Guide",
}

export enum CategoryHistoryOrderByInput {
  _id_ASC = "_id_ASC",
  _id_DESC = "_id_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  status_ASC = "status_ASC",
  status_DESC = "status_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
  userId_ASC = "userId_ASC",
  userId_DESC = "userId_DESC",
}

export enum CategoryStatus {
  Archived = "Archived",
  Draft = "Draft",
  Published = "Published",
  Scheduled = "Scheduled",
}

export enum GoodDealProcessStatus {
  error = "error",
  running = "running",
  success = "success",
}

export enum OpenAIProcessStatus {
  error = "error",
  running = "running",
  success = "success",
}

export enum OrphanPageStatus {
  Archived = "Archived",
  Draft = "Draft",
  Published = "Published",
}

export enum PhotoProvider {
  Getty = "Getty",
  Pixabay = "Pixabay",
  Unsplash = "Unsplash",
}

export enum PromptField {
  Body = "Body",
  Clickbait = "Clickbait",
  DiscoverTitle = "DiscoverTitle",
  Head = "Head",
  Title = "Title",
}

export enum PromptOrderByInput {
  body_ASC = "body_ASC",
  body_DESC = "body_DESC",
  brandKey_ASC = "brandKey_ASC",
  brandKey_DESC = "brandKey_DESC",
  field_ASC = "field_ASC",
  field_DESC = "field_DESC",
  icon_ASC = "icon_ASC",
  icon_DESC = "icon_DESC",
  label_ASC = "label_ASC",
  label_DESC = "label_DESC",
}

export enum PushOrderByInput {
  _id_ASC = "_id_ASC",
  _id_DESC = "_id_DESC",
  body_ASC = "body_ASC",
  body_DESC = "body_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  errorReason_ASC = "errorReason_ASC",
  errorReason_DESC = "errorReason_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  inError_ASC = "inError_ASC",
  inError_DESC = "inError_DESC",
  isCancelled_ASC = "isCancelled_ASC",
  isCancelled_DESC = "isCancelled_DESC",
  media_ASC = "media_ASC",
  media_DESC = "media_DESC",
  scheduledAt_ASC = "scheduledAt_ASC",
  scheduledAt_DESC = "scheduledAt_DESC",
  sentAt_ASC = "sentAt_ASC",
  sentAt_DESC = "sentAt_DESC",
  targetDevice_ASC = "targetDevice_ASC",
  targetDevice_DESC = "targetDevice_DESC",
  targeting_ASC = "targeting_ASC",
  targeting_DESC = "targeting_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
  userId_ASC = "userId_ASC",
  userId_DESC = "userId_DESC",
}

export enum QualifierOrderByInput {
  _id_ASC = "_id_ASC",
  _id_DESC = "_id_DESC",
  brandKey_ASC = "brandKey_ASC",
  brandKey_DESC = "brandKey_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  format_ASC = "format_ASC",
  format_DESC = "format_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  slug_ASC = "slug_ASC",
  slug_DESC = "slug_DESC",
  status_ASC = "status_ASC",
  status_DESC = "status_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum QualifierStatus {
  Draft = "Draft",
  Published = "Published",
}

export enum SegmentOrderByInput {
  _id_ASC = "_id_ASC",
  _id_DESC = "_id_DESC",
  active_ASC = "active_ASC",
  active_DESC = "active_DESC",
  android_ASC = "android_ASC",
  android_DESC = "android_DESC",
  brandKey_ASC = "brandKey_ASC",
  brandKey_DESC = "brandKey_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  device_ASC = "device_ASC",
  device_DESC = "device_DESC",
  editorialAlert_ASC = "editorialAlert_ASC",
  editorialAlert_DESC = "editorialAlert_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  ios_ASC = "ios_ASC",
  ios_DESC = "ios_DESC",
  status_ASC = "status_ASC",
  status_DESC = "status_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum SegmentStatus {
  Draft = "Draft",
  Published = "Published",
}

export enum SourceOrderByInput {
  _id_ASC = "_id_ASC",
  _id_DESC = "_id_DESC",
  brandKey_ASC = "brandKey_ASC",
  brandKey_DESC = "brandKey_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  slug_ASC = "slug_ASC",
  slug_DESC = "slug_DESC",
  status_ASC = "status_ASC",
  status_DESC = "status_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export enum SourceStatus {
  Draft = "Draft",
  Published = "Published",
}

export enum TabType {
  Board = "Board",
  Broadcast = "Broadcast",
  People = "People",
  Text = "Text",
  Url = "Url",
}

export enum TagLang {
  DE = "DE",
  EN = "EN",
  ES = "ES",
  FR = "FR",
  IT = "IT",
  PT = "PT",
}

export enum TagProviderName {
  Company = "Company",
  Destination = "Destination",
  MedecineTag = "MedecineTag",
  Person = "Person",
  Tag = "Tag",
  TvBy = "TvBy",
  TvCollection = "TvCollection",
  TvProgram = "TvProgram",
  VideoTag = "VideoTag",
}

export enum TagSearchTypes {
  Broadcast = "Broadcast",
  Cinema = "Cinema",
  Collection = "Collection",
  VOD = "VOD",
}

export enum TagType {
  Company = "Company",
  Destination = "Destination",
  HubPage = "HubPage",
  Isin = "Isin",
  MedecineTag = "MedecineTag",
  Person = "Person",
  Recipe = "Recipe",
  Semantic = "Semantic",
  Tag = "Tag",
  TvChannel = "TvChannel",
  TvCollection = "TvCollection",
  TvProgram = "TvProgram",
  VideoTag = "VideoTag",
}

export enum TaxonomyType {
  IAB = "IAB",
  Semantic = "Semantic",
}

export interface ArticleAuthorArticleAuthorsSearchWhereInput {
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  author?: AuthorArticleAuthorsAuthorSearchWhereInput | null;
  AND?: ArticleAuthorArticleAuthorsSearchWhereInput[] | null;
  OR?: ArticleAuthorArticleAuthorsSearchWhereInput[] | null;
  NOT?: ArticleAuthorArticleAuthorsSearchWhereInput | null;
}

export interface ArticleAuthorNestedArticleCreateInput {
  connect?: ArticleWhereUniqueInput | null;
  update?: ArticleAuthorNestedUpdateArticleCreateInput | null;
  create?: ArticleCreateInput | null;
  upsert?: ArticleAuthorNestedUpsertArticleCreateInput | null;
}

export interface ArticleAuthorNestedAuthorCreateInput {
  connect?: AuthorWhereUniqueInput | null;
  update?: ArticleAuthorNestedUpdateAuthorCreateInput | null;
  create?: AuthorCreateInput | null;
  upsert?: ArticleAuthorNestedUpsertAuthorCreateInput | null;
}

export interface ArticleAuthorNestedUpdateArticleCreateInput {
  where: ArticleWhereUniqueInput;
  data: ArticleUpdateInput;
}

export interface ArticleAuthorNestedUpdateAuthorCreateInput {
  where: AuthorWhereUniqueInput;
  data: AuthorUpdateInput;
}

export interface ArticleAuthorNestedUpsertArticleCreateInput {
  where: ArticleWhereUniqueInput;
  update: ArticleUpdateInput;
  create: ArticleCreateInput;
}

export interface ArticleAuthorNestedUpsertAuthorCreateInput {
  where: AuthorWhereUniqueInput;
  update: AuthorUpdateInput;
  create: AuthorCreateInput;
}

/**
 * Filters the "ArticleAuthor" nodes by specifying some conditions
 */
export interface ArticleAuthorWhereInput {
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  article?: ArticleWhereInput | null;
  author?: AuthorWhereInput | null;
  AND?: ArticleAuthorWhereInput[] | null;
  OR?: ArticleAuthorWhereInput[] | null;
  NOT?: ArticleAuthorWhereInput | null;
}

export interface ArticleAuthorWithForcedArticleCreateInput {
  order: number;
  author: ArticleAuthorNestedAuthorCreateInput;
}

export interface ArticleAuthorWithForcedArticleUpdateInput {
  order?: number | null;
}

/**
 * Given a forced "article", identifies exactly one "ArticleAuthor" node with one of these unique values:
 * - author
 * - order
 */
export interface ArticleAuthorWithForcedArticleWhereUniqueInput {
  author?: AuthorWhereUniqueInput | null;
  order?: number | null;
}

export interface ArticleAuthorWithForcedAuthorCreateInput {
  order: number;
  article: ArticleAuthorNestedArticleCreateInput;
}

export interface ArticleAuthorWithForcedAuthorUpdateInput {
  order?: number | null;
}

/**
 * Given a forced "author", identifies exactly one "ArticleAuthor" node.
 */
export interface ArticleAuthorWithForcedAuthorWhereUniqueInput {
  article: ArticleWhereUniqueInput;
}

export interface ArticleCategoryArticleCategoriesSearchWhereInput {
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  category?: CategoryArticleCategoriesCategorySearchWhereInput | null;
  AND?: ArticleCategoryArticleCategoriesSearchWhereInput[] | null;
  OR?: ArticleCategoryArticleCategoriesSearchWhereInput[] | null;
  NOT?: ArticleCategoryArticleCategoriesSearchWhereInput | null;
}

export interface ArticleCategoryNestedArticleCreateInput {
  connect?: ArticleWhereUniqueInput | null;
  update?: ArticleCategoryNestedUpdateArticleCreateInput | null;
  create?: ArticleCreateInput | null;
  upsert?: ArticleCategoryNestedUpsertArticleCreateInput | null;
}

export interface ArticleCategoryNestedCategoryCreateInput {
  connect?: CategoryWhereUniqueInput | null;
  update?: ArticleCategoryNestedUpdateCategoryCreateInput | null;
  create?: CategoryCreateInput | null;
  upsert?: ArticleCategoryNestedUpsertCategoryCreateInput | null;
}

export interface ArticleCategoryNestedUpdateArticleCreateInput {
  where: ArticleWhereUniqueInput;
  data: ArticleUpdateInput;
}

export interface ArticleCategoryNestedUpdateCategoryCreateInput {
  where: CategoryWhereUniqueInput;
  data: CategoryUpdateInput;
}

export interface ArticleCategoryNestedUpsertArticleCreateInput {
  where: ArticleWhereUniqueInput;
  update: ArticleUpdateInput;
  create: ArticleCreateInput;
}

export interface ArticleCategoryNestedUpsertCategoryCreateInput {
  where: CategoryWhereUniqueInput;
  update: CategoryUpdateInput;
  create: CategoryCreateInput;
}

/**
 * Filters the "ArticleCategory" nodes by specifying some conditions
 */
export interface ArticleCategoryWhereInput {
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  article?: ArticleWhereInput | null;
  category?: CategoryWhereInput | null;
  AND?: ArticleCategoryWhereInput[] | null;
  OR?: ArticleCategoryWhereInput[] | null;
  NOT?: ArticleCategoryWhereInput | null;
}

export interface ArticleCategoryWithForcedArticleCreateInput {
  order: number;
  category: ArticleCategoryNestedCategoryCreateInput;
}

export interface ArticleCategoryWithForcedArticleUpdateInput {
  order?: number | null;
}

/**
 * Given a forced "article", identifies exactly one "ArticleCategory" node with one of these unique values:
 * - category
 * - order
 */
export interface ArticleCategoryWithForcedArticleWhereUniqueInput {
  category?: CategoryWhereUniqueInput | null;
  order?: number | null;
}

export interface ArticleCategoryWithForcedCategoryCreateInput {
  order: number;
  article: ArticleCategoryNestedArticleCreateInput;
}

export interface ArticleCategoryWithForcedCategoryUpdateInput {
  order?: number | null;
}

/**
 * Given a forced "category", identifies exactly one "ArticleCategory" node.
 */
export interface ArticleCategoryWithForcedCategoryWhereUniqueInput {
  article: ArticleWhereUniqueInput;
}

export interface ArticleChannelArticleChannelsSearchWhereInput {
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  category?: CategoryArticleChannelsCategorySearchWhereInput | null;
  AND?: ArticleChannelArticleChannelsSearchWhereInput[] | null;
  OR?: ArticleChannelArticleChannelsSearchWhereInput[] | null;
  NOT?: ArticleChannelArticleChannelsSearchWhereInput | null;
}

export interface ArticleChannelNestedArticleCreateInput {
  connect?: ArticleWhereUniqueInput | null;
  update?: ArticleChannelNestedUpdateArticleCreateInput | null;
  create?: ArticleCreateInput | null;
  upsert?: ArticleChannelNestedUpsertArticleCreateInput | null;
}

export interface ArticleChannelNestedCategoryCreateInput {
  connect?: CategoryWhereUniqueInput | null;
  update?: ArticleChannelNestedUpdateCategoryCreateInput | null;
  create?: CategoryCreateInput | null;
  upsert?: ArticleChannelNestedUpsertCategoryCreateInput | null;
}

export interface ArticleChannelNestedUpdateArticleCreateInput {
  where: ArticleWhereUniqueInput;
  data: ArticleUpdateInput;
}

export interface ArticleChannelNestedUpdateCategoryCreateInput {
  where: CategoryWhereUniqueInput;
  data: CategoryUpdateInput;
}

export interface ArticleChannelNestedUpsertArticleCreateInput {
  where: ArticleWhereUniqueInput;
  update: ArticleUpdateInput;
  create: ArticleCreateInput;
}

export interface ArticleChannelNestedUpsertCategoryCreateInput {
  where: CategoryWhereUniqueInput;
  update: CategoryUpdateInput;
  create: CategoryCreateInput;
}

/**
 * Filters the "ArticleChannel" nodes by specifying some conditions
 */
export interface ArticleChannelWhereInput {
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  article?: ArticleWhereInput | null;
  category?: CategoryWhereInput | null;
  AND?: ArticleChannelWhereInput[] | null;
  OR?: ArticleChannelWhereInput[] | null;
  NOT?: ArticleChannelWhereInput | null;
}

export interface ArticleChannelWithForcedArticleCreateInput {
  order: number;
  category: ArticleChannelNestedCategoryCreateInput;
}

export interface ArticleChannelWithForcedArticleUpdateInput {
  order?: number | null;
}

/**
 * Given a forced "article", identifies exactly one "ArticleChannel" node with one of these unique values:
 * - category
 * - order
 */
export interface ArticleChannelWithForcedArticleWhereUniqueInput {
  category?: CategoryWhereUniqueInput | null;
  order?: number | null;
}

export interface ArticleChannelWithForcedCategoryCreateInput {
  order: number;
  article: ArticleChannelNestedArticleCreateInput;
}

export interface ArticleChannelWithForcedCategoryUpdateInput {
  order?: number | null;
}

/**
 * Given a forced "category", identifies exactly one "ArticleChannel" node.
 */
export interface ArticleChannelWithForcedCategoryWhereUniqueInput {
  article: ArticleWhereUniqueInput;
}

export interface ArticleCreateInput {
  id?: string | null;
  format: ArticleFormat;
  status: ArticleStatus;
  publishedAt?: GraphQL$DateTime | null;
  editedAt?: GraphQL$DateTime | null;
  title: string;
  secondaryTitle?: string | null;
  partnerTitle?: string | null;
  discoverTitle?: string | null;
  slug?: string | null;
  lead?: string | null;
  body?: string | null;
  signature?: string | null;
  medias?: string | null;
  bodyMedias?: string | null;
  brandKey: BrandKey;
  platform?: string | null;
  platformId?: string | null;
  source?: ArticleNestedSourceCreateInput | null;
  articleAuthors?: ArticleNestedArticleAuthorsCreateInput | null;
  articleCategories?: ArticleNestedArticleCategoriesCreateInput | null;
  articleChannels?: ArticleNestedArticleChannelsCreateInput | null;
  articleCreatorData?: ArticleNestedArticleCreatorDataCreateInput | null;
  articleEvents?: ArticleNestedArticleEventsCreateInput | null;
  articleExtension?: ArticleNestedArticleExtensionCreateInput | null;
  articleGoodDealAiProcesses?: ArticleNestedArticleGoodDealAiProcessesCreateInput | null;
  articleGuides?: ArticleNestedArticleGuidesCreateInput | null;
  articleHistories?: ArticleNestedArticleHistoriesCreateInput | null;
  parentArticle?: ArticleNestedParentArticleCreateInput | null;
  articleMachineTags?: ArticleNestedArticleMachineTagsCreateInput | null;
  articleQualifiers?: ArticleNestedArticleQualifiersCreateInput | null;
  articleTags?: ArticleNestedArticleTagsCreateInput | null;
  articleTaxonomies?: ArticleNestedArticleTaxonomiesCreateInput | null;
  articleUrls?: ArticleNestedArticleUrlsCreateInput | null;
  articleUsers?: ArticleNestedArticleUsersCreateInput | null;
  live?: ArticleNestedLiveCreateInput | null;
  openAiProcesses?: ArticleNestedOpenAiProcessesCreateInput | null;
  pushes?: ArticleNestedPushesCreateInput | null;
}

/**
 * Filters the "ArticleCreatorData" nodes by specifying some conditions
 */
export interface ArticleCreatorDataWhereInput {
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  userId?: string | null;
  userId_not?: string | null;
  userId_in?: string[] | null;
  userId_not_in?: string[] | null;
  departments?: string | null;
  departments_not?: string | null;
  departments_is_null?: boolean | null;
  departments_in?: (string | null)[] | null;
  departments_not_in?: (string | null)[] | null;
  departments_contains?: string | null;
  departments_not_contains?: string | null;
  departments_starts_with?: string | null;
  departments_not_starts_with?: string | null;
  departments_ends_with?: string | null;
  departments_not_ends_with?: string | null;
  departments_lt?: string | null;
  departments_lte?: string | null;
  departments_gt?: string | null;
  departments_gte?: string | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  article?: ArticleWhereInput | null;
  AND?: ArticleCreatorDataWhereInput[] | null;
  OR?: ArticleCreatorDataWhereInput[] | null;
  NOT?: ArticleCreatorDataWhereInput | null;
}

export interface ArticleCreatorDataWithForcedArticleCreateInput {
  name: string;
  userId: string;
  departments?: string | null;
}

export interface ArticleCreatorDataWithForcedArticleUpdateInput {
  name?: string | null;
  userId?: string | null;
  departments?: string | null;
}

export interface ArticleEventArticleEventsSearchWhereInput {
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  category?: CategoryArticleEventsCategorySearchWhereInput | null;
  AND?: ArticleEventArticleEventsSearchWhereInput[] | null;
  OR?: ArticleEventArticleEventsSearchWhereInput[] | null;
  NOT?: ArticleEventArticleEventsSearchWhereInput | null;
}

export interface ArticleEventNestedArticleCreateInput {
  connect?: ArticleWhereUniqueInput | null;
  update?: ArticleEventNestedUpdateArticleCreateInput | null;
  create?: ArticleCreateInput | null;
  upsert?: ArticleEventNestedUpsertArticleCreateInput | null;
}

export interface ArticleEventNestedCategoryCreateInput {
  connect?: CategoryWhereUniqueInput | null;
  update?: ArticleEventNestedUpdateCategoryCreateInput | null;
  create?: CategoryCreateInput | null;
  upsert?: ArticleEventNestedUpsertCategoryCreateInput | null;
}

export interface ArticleEventNestedUpdateArticleCreateInput {
  where: ArticleWhereUniqueInput;
  data: ArticleUpdateInput;
}

export interface ArticleEventNestedUpdateCategoryCreateInput {
  where: CategoryWhereUniqueInput;
  data: CategoryUpdateInput;
}

export interface ArticleEventNestedUpsertArticleCreateInput {
  where: ArticleWhereUniqueInput;
  update: ArticleUpdateInput;
  create: ArticleCreateInput;
}

export interface ArticleEventNestedUpsertCategoryCreateInput {
  where: CategoryWhereUniqueInput;
  update: CategoryUpdateInput;
  create: CategoryCreateInput;
}

/**
 * Filters the "ArticleEvent" nodes by specifying some conditions
 */
export interface ArticleEventWhereInput {
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  article?: ArticleWhereInput | null;
  category?: CategoryWhereInput | null;
  AND?: ArticleEventWhereInput[] | null;
  OR?: ArticleEventWhereInput[] | null;
  NOT?: ArticleEventWhereInput | null;
}

export interface ArticleEventWithForcedArticleCreateInput {
  order: number;
  category: ArticleEventNestedCategoryCreateInput;
}

export interface ArticleEventWithForcedArticleUpdateInput {
  order?: number | null;
}

/**
 * Given a forced "article", identifies exactly one "ArticleEvent" node with one of these unique values:
 * - category
 * - order
 */
export interface ArticleEventWithForcedArticleWhereUniqueInput {
  category?: CategoryWhereUniqueInput | null;
  order?: number | null;
}

export interface ArticleEventWithForcedCategoryCreateInput {
  order: number;
  article: ArticleEventNestedArticleCreateInput;
}

export interface ArticleEventWithForcedCategoryUpdateInput {
  order?: number | null;
}

/**
 * Given a forced "category", identifies exactly one "ArticleEvent" node.
 */
export interface ArticleEventWithForcedCategoryWhereUniqueInput {
  article: ArticleWhereUniqueInput;
}

/**
 * Filters the "ArticleExtension" nodes by specifying some conditions
 */
export interface ArticleExtensionWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  isClassified?: boolean | null;
  isClassified_not?: boolean | null;
  isClassified_is_null?: boolean | null;
  isClassified_in?: (boolean | null)[] | null;
  isClassified_not_in?: (boolean | null)[] | null;
  lockerId?: string | null;
  lockerId_not?: string | null;
  lockerId_is_null?: boolean | null;
  lockerId_in?: (string | null)[] | null;
  lockerId_not_in?: (string | null)[] | null;
  lockedAt?: GraphQL$DateTime | null;
  lockedAt_not?: GraphQL$DateTime | null;
  lockedAt_is_null?: boolean | null;
  lockedAt_in?: (GraphQL$DateTime | null)[] | null;
  lockedAt_not_in?: (GraphQL$DateTime | null)[] | null;
  lockedAt_lt?: GraphQL$DateTime | null;
  lockedAt_lte?: GraphQL$DateTime | null;
  lockedAt_gt?: GraphQL$DateTime | null;
  lockedAt_gte?: GraphQL$DateTime | null;
  article?: ArticleWhereInput | null;
  AND?: ArticleExtensionWhereInput[] | null;
  OR?: ArticleExtensionWhereInput[] | null;
  NOT?: ArticleExtensionWhereInput | null;
}

/**
 * Identifies exactly one "ArticleExtension" node with one of these unique values:
 * - _id
 * - id
 * - article
 */
export interface ArticleExtensionWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
  article?: ArticleWhereUniqueInput | null;
}

export interface ArticleExtensionWithForcedArticleCreateInput {
  id?: string | null;
  isClassified?: boolean | null;
  lockerId?: string | null;
}

export interface ArticleExtensionWithForcedArticleUpdateInput {
  isClassified?: boolean | null;
  lockerId?: string | null;
}

/**
 * Given a known "article", identifies exactly one "ArticleExtension" node with one of these unique values:
 * - _id
 * - id
 * - article (optional)
 */
export interface ArticleExtensionWithOptionalArticleWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
  article?: ArticleWhereUniqueInput | null;
}

export interface ArticleGoodDealAIPromptUpdateInput {
  id?: string | null;
  createdAt?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  promptOrder?: number | null;
  prompt?: string | null;
}

/**
 * Identifies exactly one "ArticleGoodDealAIPrompt" node with one of these unique values:
 * - _id
 * - id
 * - promptOrder
 */
export interface ArticleGoodDealAIPromptWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
  promptOrder?: number | null;
}

/**
 * Filters the "ArticleGoodDealAiProcess" nodes by specifying some conditions
 */
export interface ArticleGoodDealAiProcessWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_is_null?: boolean | null;
  id_in?: (string | null)[] | null;
  id_not_in?: (string | null)[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  status?: GoodDealProcessStatus | null;
  status_not?: GoodDealProcessStatus | null;
  status_in?: GoodDealProcessStatus[] | null;
  status_not_in?: GoodDealProcessStatus[] | null;
  errorMsg?: string | null;
  errorMsg_not?: string | null;
  errorMsg_is_null?: boolean | null;
  errorMsg_in?: (string | null)[] | null;
  errorMsg_not_in?: (string | null)[] | null;
  errorMsg_contains?: string | null;
  errorMsg_not_contains?: string | null;
  errorMsg_starts_with?: string | null;
  errorMsg_not_starts_with?: string | null;
  errorMsg_ends_with?: string | null;
  errorMsg_not_ends_with?: string | null;
  errorMsg_lt?: string | null;
  errorMsg_lte?: string | null;
  errorMsg_gt?: string | null;
  errorMsg_gte?: string | null;
  productTitle?: string | null;
  productTitle_not?: string | null;
  productTitle_is_null?: boolean | null;
  productTitle_in?: (string | null)[] | null;
  productTitle_not_in?: (string | null)[] | null;
  productTitle_contains?: string | null;
  productTitle_not_contains?: string | null;
  productTitle_starts_with?: string | null;
  productTitle_not_starts_with?: string | null;
  productTitle_ends_with?: string | null;
  productTitle_not_ends_with?: string | null;
  productTitle_lt?: string | null;
  productTitle_lte?: string | null;
  productTitle_gt?: string | null;
  productTitle_gte?: string | null;
  userId?: string | null;
  userId_not?: string | null;
  userId_in?: string[] | null;
  userId_not_in?: string[] | null;
  article?: ArticleWhereInput | null;
  article_is_null?: boolean | null;
  AND?: ArticleGoodDealAiProcessWhereInput[] | null;
  OR?: ArticleGoodDealAiProcessWhereInput[] | null;
  NOT?: ArticleGoodDealAiProcessWhereInput | null;
}

/**
 * Identifies exactly one "ArticleGoodDealAiProcess" node with one of these unique values:
 * - _id
 * - id
 */
export interface ArticleGoodDealAiProcessWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
}

export interface ArticleGoodDealAiProcessWithForcedArticleCreateInput {
  id?: string | null;
  createdAt?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  status: GoodDealProcessStatus;
  errorMsg?: string | null;
  productTitle?: string | null;
}

export interface ArticleGoodDealAiProcessWithForcedArticleUpdateInput {
  id?: string | null;
  createdAt?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  status?: GoodDealProcessStatus | null;
  errorMsg?: string | null;
  productTitle?: string | null;
}

export interface ArticleGuideArticleGuidesSearchWhereInput {
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  category?: CategoryArticleGuidesCategorySearchWhereInput | null;
  AND?: ArticleGuideArticleGuidesSearchWhereInput[] | null;
  OR?: ArticleGuideArticleGuidesSearchWhereInput[] | null;
  NOT?: ArticleGuideArticleGuidesSearchWhereInput | null;
}

export interface ArticleGuideNestedArticleCreateInput {
  connect?: ArticleWhereUniqueInput | null;
  update?: ArticleGuideNestedUpdateArticleCreateInput | null;
  create?: ArticleCreateInput | null;
  upsert?: ArticleGuideNestedUpsertArticleCreateInput | null;
}

export interface ArticleGuideNestedCategoryCreateInput {
  connect?: CategoryWhereUniqueInput | null;
  update?: ArticleGuideNestedUpdateCategoryCreateInput | null;
  create?: CategoryCreateInput | null;
  upsert?: ArticleGuideNestedUpsertCategoryCreateInput | null;
}

export interface ArticleGuideNestedUpdateArticleCreateInput {
  where: ArticleWhereUniqueInput;
  data: ArticleUpdateInput;
}

export interface ArticleGuideNestedUpdateCategoryCreateInput {
  where: CategoryWhereUniqueInput;
  data: CategoryUpdateInput;
}

export interface ArticleGuideNestedUpsertArticleCreateInput {
  where: ArticleWhereUniqueInput;
  update: ArticleUpdateInput;
  create: ArticleCreateInput;
}

export interface ArticleGuideNestedUpsertCategoryCreateInput {
  where: CategoryWhereUniqueInput;
  update: CategoryUpdateInput;
  create: CategoryCreateInput;
}

/**
 * Filters the "ArticleGuide" nodes by specifying some conditions
 */
export interface ArticleGuideWhereInput {
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  article?: ArticleWhereInput | null;
  category?: CategoryWhereInput | null;
  AND?: ArticleGuideWhereInput[] | null;
  OR?: ArticleGuideWhereInput[] | null;
  NOT?: ArticleGuideWhereInput | null;
}

export interface ArticleGuideWithForcedArticleCreateInput {
  order: number;
  category: ArticleGuideNestedCategoryCreateInput;
}

export interface ArticleGuideWithForcedArticleUpdateInput {
  order?: number | null;
}

/**
 * Given a forced "article", identifies exactly one "ArticleGuide" node with one of these unique values:
 * - category
 * - order
 */
export interface ArticleGuideWithForcedArticleWhereUniqueInput {
  category?: CategoryWhereUniqueInput | null;
  order?: number | null;
}

export interface ArticleGuideWithForcedCategoryCreateInput {
  order: number;
  article: ArticleGuideNestedArticleCreateInput;
}

export interface ArticleGuideWithForcedCategoryUpdateInput {
  order?: number | null;
}

/**
 * Given a forced "category", identifies exactly one "ArticleGuide" node.
 */
export interface ArticleGuideWithForcedCategoryWhereUniqueInput {
  article: ArticleWhereUniqueInput;
}

export interface ArticleHistoryCreateInput {
  id?: string | null;
  userId: string;
  status: ArticleStatus;
  article: ArticleHistoryNestedArticleCreateInput;
  duplicatedArticle?: ArticleHistoryNestedDuplicatedArticleCreateInput | null;
}

export interface ArticleHistoryNestedArticleCreateInput {
  connect?: ArticleWhereUniqueInput | null;
  update?: ArticleHistoryNestedUpdateArticleCreateInput | null;
  create?: ArticleCreateInput | null;
  upsert?: ArticleHistoryNestedUpsertArticleCreateInput | null;
}

export interface ArticleHistoryNestedArticleUpdateInput {
  connect?: ArticleWhereUniqueInput | null;
  update?: ArticleHistoryNestedUpdateArticleUpdateInput | null;
  create?: ArticleCreateInput | null;
  upsert?: ArticleHistoryNestedUpsertArticleUpdateInput | null;
}

export interface ArticleHistoryNestedDuplicatedArticleCreateInput {
  connect?: ArticleWhereUniqueInput | null;
  update?: ArticleHistoryNestedUpdateDuplicatedArticleCreateInput | null;
  create?: ArticleCreateInput | null;
  upsert?: ArticleHistoryNestedUpsertDuplicatedArticleCreateInput | null;
}

export interface ArticleHistoryNestedDuplicatedArticleUpdateInput {
  connect?: ArticleWhereUniqueInput | null;
  update?: ArticleHistoryNestedUpdateDuplicatedArticleUpdateInput | null;
  create?: ArticleCreateInput | null;
  upsert?: ArticleHistoryNestedUpsertDuplicatedArticleUpdateInput | null;
  disconnect?: boolean | null;
}

export interface ArticleHistoryNestedUpdateArticleCreateInput {
  where: ArticleWhereUniqueInput;
  data: ArticleUpdateInput;
}

export interface ArticleHistoryNestedUpdateArticleUpdateInput {
  where: ArticleWhereUniqueInput;
  data: ArticleUpdateInput;
}

export interface ArticleHistoryNestedUpdateDuplicatedArticleCreateInput {
  where: ArticleWhereUniqueInput;
  data: ArticleUpdateInput;
}

export interface ArticleHistoryNestedUpdateDuplicatedArticleUpdateInput {
  where: ArticleWhereUniqueInput;
  data: ArticleUpdateInput;
}

export interface ArticleHistoryNestedUpsertArticleCreateInput {
  where: ArticleWhereUniqueInput;
  update: ArticleUpdateInput;
  create: ArticleCreateInput;
}

export interface ArticleHistoryNestedUpsertArticleUpdateInput {
  where: ArticleWhereUniqueInput;
  update: ArticleUpdateInput;
  create: ArticleCreateInput;
}

export interface ArticleHistoryNestedUpsertDuplicatedArticleCreateInput {
  where: ArticleWhereUniqueInput;
  update: ArticleUpdateInput;
  create: ArticleCreateInput;
}

export interface ArticleHistoryNestedUpsertDuplicatedArticleUpdateInput {
  where: ArticleWhereUniqueInput;
  update: ArticleUpdateInput;
  create: ArticleCreateInput;
}

/**
 * Filters the "ArticleHistory" nodes by specifying some conditions
 */
export interface ArticleHistoryWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  userId?: string | null;
  userId_not?: string | null;
  userId_in?: string[] | null;
  userId_not_in?: string[] | null;
  status?: ArticleStatus | null;
  status_not?: ArticleStatus | null;
  status_in?: ArticleStatus[] | null;
  status_not_in?: ArticleStatus[] | null;
  article?: ArticleWhereInput | null;
  duplicatedArticle?: ArticleWhereInput | null;
  duplicatedArticle_is_null?: boolean | null;
  AND?: ArticleHistoryWhereInput[] | null;
  OR?: ArticleHistoryWhereInput[] | null;
  NOT?: ArticleHistoryWhereInput | null;
}

/**
 * Identifies exactly one "ArticleHistory" node with one of these unique values:
 * - _id
 * - id
 */
export interface ArticleHistoryWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
}

export interface ArticleHistoryWithForcedArticleCreateInput {
  id?: string | null;
  userId: string;
  status: ArticleStatus;
  duplicatedArticle?: ArticleHistoryNestedDuplicatedArticleCreateInput | null;
}

export interface ArticleHistoryWithForcedArticleUpdateInput {
  userId?: string | null;
  status?: ArticleStatus | null;
  duplicatedArticle?: ArticleHistoryNestedDuplicatedArticleUpdateInput | null;
}

export interface ArticleHistoryWithForcedDuplicatedArticleCreateInput {
  id?: string | null;
  userId: string;
  status: ArticleStatus;
  article: ArticleHistoryNestedArticleCreateInput;
}

export interface ArticleHistoryWithForcedDuplicatedArticleUpdateInput {
  userId?: string | null;
  status?: ArticleStatus | null;
  article?: ArticleHistoryNestedArticleUpdateInput | null;
}

export interface ArticleMachineTagArticleMachineTagsSearchWhereInput {
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  machineTag?: MachineTagArticleMachineTagsMachineTagSearchWhereInput | null;
  AND?: ArticleMachineTagArticleMachineTagsSearchWhereInput[] | null;
  OR?: ArticleMachineTagArticleMachineTagsSearchWhereInput[] | null;
  NOT?: ArticleMachineTagArticleMachineTagsSearchWhereInput | null;
}

export interface ArticleMachineTagNestedArticleCreateInput {
  connect?: ArticleWhereUniqueInput | null;
  update?: ArticleMachineTagNestedUpdateArticleCreateInput | null;
  create?: ArticleCreateInput | null;
  upsert?: ArticleMachineTagNestedUpsertArticleCreateInput | null;
}

export interface ArticleMachineTagNestedMachineTagCreateInput {
  connect?: MachineTagWhereUniqueInput | null;
  update?: ArticleMachineTagNestedUpdateMachineTagCreateInput | null;
  create?: MachineTagCreateInput | null;
  upsert?: ArticleMachineTagNestedUpsertMachineTagCreateInput | null;
}

export interface ArticleMachineTagNestedUpdateArticleCreateInput {
  where: ArticleWhereUniqueInput;
  data: ArticleUpdateInput;
}

export interface ArticleMachineTagNestedUpdateMachineTagCreateInput {
  where: MachineTagWhereUniqueInput;
  data: MachineTagUpdateInput;
}

export interface ArticleMachineTagNestedUpsertArticleCreateInput {
  where: ArticleWhereUniqueInput;
  update: ArticleUpdateInput;
  create: ArticleCreateInput;
}

export interface ArticleMachineTagNestedUpsertMachineTagCreateInput {
  where: MachineTagWhereUniqueInput;
  update: MachineTagUpdateInput;
  create: MachineTagCreateInput;
}

/**
 * Filters the "ArticleMachineTag" nodes by specifying some conditions
 */
export interface ArticleMachineTagWhereInput {
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  article?: ArticleWhereInput | null;
  machineTag?: MachineTagWhereInput | null;
  AND?: ArticleMachineTagWhereInput[] | null;
  OR?: ArticleMachineTagWhereInput[] | null;
  NOT?: ArticleMachineTagWhereInput | null;
}

export interface ArticleMachineTagWithForcedArticleCreateInput {
  order: number;
  machineTag: ArticleMachineTagNestedMachineTagCreateInput;
}

export interface ArticleMachineTagWithForcedArticleUpdateInput {
  order?: number | null;
}

/**
 * Given a forced "article", identifies exactly one "ArticleMachineTag" node with one of these unique values:
 * - machineTag
 * - order
 */
export interface ArticleMachineTagWithForcedArticleWhereUniqueInput {
  machineTag?: MachineTagWhereUniqueInput | null;
  order?: number | null;
}

export interface ArticleMachineTagWithForcedMachineTagCreateInput {
  order: number;
  article: ArticleMachineTagNestedArticleCreateInput;
}

export interface ArticleMachineTagWithForcedMachineTagUpdateInput {
  order?: number | null;
}

/**
 * Given a forced "machineTag", identifies exactly one "ArticleMachineTag" node.
 */
export interface ArticleMachineTagWithForcedMachineTagWhereUniqueInput {
  article: ArticleWhereUniqueInput;
}

export interface ArticleNestedArticleAuthorsCreateInput {
  create?: ArticleAuthorWithForcedArticleCreateInput[] | null;
}

export interface ArticleNestedArticleAuthorsUpdateInput {
  update?: ArticleNestedUpdateArticleAuthorsUpdateInput[] | null;
  create?: ArticleAuthorWithForcedArticleCreateInput[] | null;
  upsert?: ArticleNestedUpsertArticleAuthorsUpdateInput[] | null;
  delete?: ArticleAuthorWithForcedArticleWhereUniqueInput[] | null;
}

export interface ArticleNestedArticleCategoriesCreateInput {
  create?: ArticleCategoryWithForcedArticleCreateInput[] | null;
}

export interface ArticleNestedArticleCategoriesUpdateInput {
  update?: ArticleNestedUpdateArticleCategoriesUpdateInput[] | null;
  create?: ArticleCategoryWithForcedArticleCreateInput[] | null;
  upsert?: ArticleNestedUpsertArticleCategoriesUpdateInput[] | null;
  delete?: ArticleCategoryWithForcedArticleWhereUniqueInput[] | null;
}

export interface ArticleNestedArticleChannelsCreateInput {
  create?: ArticleChannelWithForcedArticleCreateInput[] | null;
}

export interface ArticleNestedArticleChannelsUpdateInput {
  update?: ArticleNestedUpdateArticleChannelsUpdateInput[] | null;
  create?: ArticleChannelWithForcedArticleCreateInput[] | null;
  upsert?: ArticleNestedUpsertArticleChannelsUpdateInput[] | null;
  delete?: ArticleChannelWithForcedArticleWhereUniqueInput[] | null;
}

export interface ArticleNestedArticleCreatorDataCreateInput {
  create?: ArticleCreatorDataWithForcedArticleCreateInput | null;
}

export interface ArticleNestedArticleCreatorDataUpdateInput {
  update?: ArticleNestedUpdateArticleCreatorDataUpdateInput | null;
  create?: ArticleCreatorDataWithForcedArticleCreateInput | null;
  upsert?: ArticleNestedUpsertArticleCreatorDataUpdateInput | null;
  delete?: boolean | null;
}

export interface ArticleNestedArticleEventsCreateInput {
  create?: ArticleEventWithForcedArticleCreateInput[] | null;
}

export interface ArticleNestedArticleEventsUpdateInput {
  update?: ArticleNestedUpdateArticleEventsUpdateInput[] | null;
  create?: ArticleEventWithForcedArticleCreateInput[] | null;
  upsert?: ArticleNestedUpsertArticleEventsUpdateInput[] | null;
  delete?: ArticleEventWithForcedArticleWhereUniqueInput[] | null;
}

export interface ArticleNestedArticleExtensionCreateInput {
  connect?: ArticleExtensionWhereUniqueInput | null;
  create?: ArticleExtensionWithForcedArticleCreateInput | null;
}

export interface ArticleNestedArticleExtensionUpdateInput {
  connect?: ArticleExtensionWhereUniqueInput | null;
  update?: ArticleNestedUpdateArticleExtensionUpdateInput | null;
  create?: ArticleExtensionWithForcedArticleCreateInput | null;
  upsert?: ArticleNestedUpsertArticleExtensionUpdateInput | null;
  delete?: boolean | null;
}

export interface ArticleNestedArticleGoodDealAiProcessesCreateInput {
  connect?: ArticleGoodDealAiProcessWhereUniqueInput[] | null;
  create?: ArticleGoodDealAiProcessWithForcedArticleCreateInput[] | null;
}

export interface ArticleNestedArticleGoodDealAiProcessesUpdateInput {
  connect?: ArticleGoodDealAiProcessWhereUniqueInput[] | null;
  disconnect?: ArticleGoodDealAiProcessWhereUniqueInput[] | null;
  update?: ArticleNestedUpdateArticleGoodDealAiProcessesUpdateInput[] | null;
  create?: ArticleGoodDealAiProcessWithForcedArticleCreateInput[] | null;
  upsert?: ArticleNestedUpsertArticleGoodDealAiProcessesUpdateInput[] | null;
  delete?: ArticleGoodDealAiProcessWhereUniqueInput[] | null;
}

export interface ArticleNestedArticleGuidesCreateInput {
  create?: ArticleGuideWithForcedArticleCreateInput[] | null;
}

export interface ArticleNestedArticleGuidesUpdateInput {
  update?: ArticleNestedUpdateArticleGuidesUpdateInput[] | null;
  create?: ArticleGuideWithForcedArticleCreateInput[] | null;
  upsert?: ArticleNestedUpsertArticleGuidesUpdateInput[] | null;
  delete?: ArticleGuideWithForcedArticleWhereUniqueInput[] | null;
}

export interface ArticleNestedArticleHistoriesCreateInput {
  connect?: ArticleHistoryWhereUniqueInput[] | null;
  create?: ArticleHistoryWithForcedArticleCreateInput[] | null;
}

export interface ArticleNestedArticleHistoriesUpdateInput {
  connect?: ArticleHistoryWhereUniqueInput[] | null;
  update?: ArticleNestedUpdateArticleHistoriesUpdateInput[] | null;
  create?: ArticleHistoryWithForcedArticleCreateInput[] | null;
  upsert?: ArticleNestedUpsertArticleHistoriesUpdateInput[] | null;
  delete?: ArticleHistoryWhereUniqueInput[] | null;
}

export interface ArticleNestedArticleMachineTagsCreateInput {
  create?: ArticleMachineTagWithForcedArticleCreateInput[] | null;
}

export interface ArticleNestedArticleMachineTagsUpdateInput {
  update?: ArticleNestedUpdateArticleMachineTagsUpdateInput[] | null;
  create?: ArticleMachineTagWithForcedArticleCreateInput[] | null;
  upsert?: ArticleNestedUpsertArticleMachineTagsUpdateInput[] | null;
  delete?: ArticleMachineTagWithForcedArticleWhereUniqueInput[] | null;
}

export interface ArticleNestedArticleQualifiersCreateInput {
  create?: ArticleQualifierWithForcedArticleCreateInput[] | null;
}

export interface ArticleNestedArticleQualifiersUpdateInput {
  update?: ArticleNestedUpdateArticleQualifiersUpdateInput[] | null;
  create?: ArticleQualifierWithForcedArticleCreateInput[] | null;
  upsert?: ArticleNestedUpsertArticleQualifiersUpdateInput[] | null;
  delete?: ArticleQualifierWithForcedArticleWhereUniqueInput[] | null;
}

export interface ArticleNestedArticleTagsCreateInput {
  create?: ArticleTagWithForcedArticleCreateInput[] | null;
}

export interface ArticleNestedArticleTagsUpdateInput {
  update?: ArticleNestedUpdateArticleTagsUpdateInput[] | null;
  create?: ArticleTagWithForcedArticleCreateInput[] | null;
  upsert?: ArticleNestedUpsertArticleTagsUpdateInput[] | null;
  delete?: ArticleTagWithForcedArticleWhereUniqueInput[] | null;
}

export interface ArticleNestedArticleTaxonomiesCreateInput {
  create?: ArticleTaxonomyWithForcedArticleCreateInput[] | null;
}

export interface ArticleNestedArticleTaxonomiesUpdateInput {
  update?: ArticleNestedUpdateArticleTaxonomiesUpdateInput[] | null;
  create?: ArticleTaxonomyWithForcedArticleCreateInput[] | null;
  upsert?: ArticleNestedUpsertArticleTaxonomiesUpdateInput[] | null;
  delete?: ArticleTaxonomyWithForcedArticleWhereUniqueInput[] | null;
}

export interface ArticleNestedArticleUrlsCreateInput {
  create?: ArticleUrlWithForcedArticleCreateInput[] | null;
}

export interface ArticleNestedArticleUrlsUpdateInput {
  update?: ArticleNestedUpdateArticleUrlsUpdateInput[] | null;
  create?: ArticleUrlWithForcedArticleCreateInput[] | null;
  upsert?: ArticleNestedUpsertArticleUrlsUpdateInput[] | null;
  delete?: ArticleUrlWithForcedArticleWhereUniqueInput[] | null;
}

export interface ArticleNestedArticleUsersCreateInput {
  connect?: ArticleUserWhereUniqueInput[] | null;
  create?: ArticleUserWithForcedArticleCreateInput[] | null;
}

export interface ArticleNestedArticleUsersUpdateInput {
  connect?: ArticleUserWhereUniqueInput[] | null;
  update?: ArticleNestedUpdateArticleUsersUpdateInput[] | null;
  create?: ArticleUserWithForcedArticleCreateInput[] | null;
  upsert?: ArticleNestedUpsertArticleUsersUpdateInput[] | null;
  delete?: ArticleUserWhereUniqueInput[] | null;
}

export interface ArticleNestedLiveCreateInput {
  connect?: LiveWhereUniqueInput | null;
  create?: LiveWithForcedArticleCreateInput | null;
}

export interface ArticleNestedLiveUpdateInput {
  connect?: LiveWhereUniqueInput | null;
  update?: ArticleNestedUpdateLiveUpdateInput | null;
  create?: LiveWithForcedArticleCreateInput | null;
  upsert?: ArticleNestedUpsertLiveUpdateInput | null;
  delete?: boolean | null;
}

export interface ArticleNestedOpenAiProcessesCreateInput {
  connect?: OpenAiProcessWhereUniqueInput[] | null;
  create?: OpenAiProcessWithForcedArticleCreateInput[] | null;
}

export interface ArticleNestedOpenAiProcessesUpdateInput {
  connect?: OpenAiProcessWhereUniqueInput[] | null;
  disconnect?: OpenAiProcessWhereUniqueInput[] | null;
  update?: ArticleNestedUpdateOpenAiProcessesUpdateInput[] | null;
  create?: OpenAiProcessWithForcedArticleCreateInput[] | null;
  upsert?: ArticleNestedUpsertOpenAiProcessesUpdateInput[] | null;
  delete?: OpenAiProcessWhereUniqueInput[] | null;
}

export interface ArticleNestedParentArticleCreateInput {
  connect?: ArticleHistoryWhereUniqueInput[] | null;
  create?: ArticleHistoryWithForcedDuplicatedArticleCreateInput[] | null;
}

export interface ArticleNestedParentArticleUpdateInput {
  connect?: ArticleHistoryWhereUniqueInput[] | null;
  disconnect?: ArticleHistoryWhereUniqueInput[] | null;
  update?: ArticleNestedUpdateParentArticleUpdateInput[] | null;
  create?: ArticleHistoryWithForcedDuplicatedArticleCreateInput[] | null;
  upsert?: ArticleNestedUpsertParentArticleUpdateInput[] | null;
  delete?: ArticleHistoryWhereUniqueInput[] | null;
}

export interface ArticleNestedPushesCreateInput {
  connect?: PushWhereUniqueInput[] | null;
  create?: PushWithForcedArticleCreateInput[] | null;
}

export interface ArticleNestedPushesUpdateInput {
  connect?: PushWhereUniqueInput[] | null;
  update?: ArticleNestedUpdatePushesUpdateInput[] | null;
  create?: PushWithForcedArticleCreateInput[] | null;
  upsert?: ArticleNestedUpsertPushesUpdateInput[] | null;
  delete?: PushWhereUniqueInput[] | null;
}

export interface ArticleNestedSourceCreateInput {
  connect?: SourceWhereUniqueInput | null;
  update?: ArticleNestedUpdateSourceCreateInput | null;
  create?: SourceCreateInput | null;
  upsert?: ArticleNestedUpsertSourceCreateInput | null;
}

export interface ArticleNestedSourceUpdateInput {
  connect?: SourceWhereUniqueInput | null;
  update?: ArticleNestedUpdateSourceUpdateInput | null;
  create?: SourceCreateInput | null;
  upsert?: ArticleNestedUpsertSourceUpdateInput | null;
  disconnect?: boolean | null;
}

export interface ArticleNestedUpdateArticleAuthorsUpdateInput {
  where: ArticleAuthorWithForcedArticleWhereUniqueInput;
  data: ArticleAuthorWithForcedArticleUpdateInput;
}

export interface ArticleNestedUpdateArticleCategoriesUpdateInput {
  where: ArticleCategoryWithForcedArticleWhereUniqueInput;
  data: ArticleCategoryWithForcedArticleUpdateInput;
}

export interface ArticleNestedUpdateArticleChannelsUpdateInput {
  where: ArticleChannelWithForcedArticleWhereUniqueInput;
  data: ArticleChannelWithForcedArticleUpdateInput;
}

export interface ArticleNestedUpdateArticleCreatorDataUpdateInput {
  data: ArticleCreatorDataWithForcedArticleUpdateInput;
}

export interface ArticleNestedUpdateArticleEventsUpdateInput {
  where: ArticleEventWithForcedArticleWhereUniqueInput;
  data: ArticleEventWithForcedArticleUpdateInput;
}

export interface ArticleNestedUpdateArticleExtensionUpdateInput {
  where: ArticleExtensionWithOptionalArticleWhereUniqueInput;
  data: ArticleExtensionWithForcedArticleUpdateInput;
}

export interface ArticleNestedUpdateArticleGoodDealAiProcessesUpdateInput {
  where: ArticleGoodDealAiProcessWhereUniqueInput;
  data: ArticleGoodDealAiProcessWithForcedArticleUpdateInput;
}

export interface ArticleNestedUpdateArticleGuidesUpdateInput {
  where: ArticleGuideWithForcedArticleWhereUniqueInput;
  data: ArticleGuideWithForcedArticleUpdateInput;
}

export interface ArticleNestedUpdateArticleHistoriesUpdateInput {
  where: ArticleHistoryWhereUniqueInput;
  data: ArticleHistoryWithForcedArticleUpdateInput;
}

export interface ArticleNestedUpdateArticleMachineTagsUpdateInput {
  where: ArticleMachineTagWithForcedArticleWhereUniqueInput;
  data: ArticleMachineTagWithForcedArticleUpdateInput;
}

export interface ArticleNestedUpdateArticleQualifiersUpdateInput {
  where: ArticleQualifierWithForcedArticleWhereUniqueInput;
  data: ArticleQualifierWithForcedArticleUpdateInput;
}

export interface ArticleNestedUpdateArticleTagsUpdateInput {
  where: ArticleTagWithForcedArticleWhereUniqueInput;
  data: ArticleTagWithForcedArticleUpdateInput;
}

export interface ArticleNestedUpdateArticleTaxonomiesUpdateInput {
  where: ArticleTaxonomyWithForcedArticleWhereUniqueInput;
  data: ArticleTaxonomyWithForcedArticleUpdateInput;
}

export interface ArticleNestedUpdateArticleUrlsUpdateInput {
  where: ArticleUrlWithForcedArticleWhereUniqueInput;
  data: ArticleUrlWithForcedArticleUpdateInput;
}

export interface ArticleNestedUpdateArticleUsersUpdateInput {
  where: ArticleUserWhereUniqueInput;
  data: ArticleUserWithForcedArticleUpdateInput;
}

export interface ArticleNestedUpdateLiveUpdateInput {
  where: LiveWithOptionalArticleWhereUniqueInput;
  data: LiveWithForcedArticleUpdateInput;
}

export interface ArticleNestedUpdateOpenAiProcessesUpdateInput {
  where: OpenAiProcessWhereUniqueInput;
  data: OpenAiProcessWithForcedArticleUpdateInput;
}

export interface ArticleNestedUpdateParentArticleUpdateInput {
  where: ArticleHistoryWhereUniqueInput;
  data: ArticleHistoryWithForcedDuplicatedArticleUpdateInput;
}

export interface ArticleNestedUpdatePushesUpdateInput {
  where: PushWhereUniqueInput;
  data: PushWithForcedArticleUpdateInput;
}

export interface ArticleNestedUpdateSourceCreateInput {
  where: SourceWhereUniqueInput;
  data: SourceUpdateInput;
}

export interface ArticleNestedUpdateSourceUpdateInput {
  where: SourceWhereUniqueInput;
  data: SourceUpdateInput;
}

export interface ArticleNestedUpsertArticleAuthorsUpdateInput {
  where: ArticleAuthorWithForcedArticleWhereUniqueInput;
  update: ArticleAuthorWithForcedArticleUpdateInput;
  create: ArticleAuthorWithForcedArticleCreateInput;
}

export interface ArticleNestedUpsertArticleCategoriesUpdateInput {
  where: ArticleCategoryWithForcedArticleWhereUniqueInput;
  update: ArticleCategoryWithForcedArticleUpdateInput;
  create: ArticleCategoryWithForcedArticleCreateInput;
}

export interface ArticleNestedUpsertArticleChannelsUpdateInput {
  where: ArticleChannelWithForcedArticleWhereUniqueInput;
  update: ArticleChannelWithForcedArticleUpdateInput;
  create: ArticleChannelWithForcedArticleCreateInput;
}

export interface ArticleNestedUpsertArticleCreatorDataUpdateInput {
  update: ArticleCreatorDataWithForcedArticleUpdateInput;
  create: ArticleCreatorDataWithForcedArticleCreateInput;
}

export interface ArticleNestedUpsertArticleEventsUpdateInput {
  where: ArticleEventWithForcedArticleWhereUniqueInput;
  update: ArticleEventWithForcedArticleUpdateInput;
  create: ArticleEventWithForcedArticleCreateInput;
}

export interface ArticleNestedUpsertArticleExtensionUpdateInput {
  where: ArticleExtensionWithOptionalArticleWhereUniqueInput;
  update: ArticleExtensionWithForcedArticleUpdateInput;
  create: ArticleExtensionWithForcedArticleCreateInput;
}

export interface ArticleNestedUpsertArticleGoodDealAiProcessesUpdateInput {
  where: ArticleGoodDealAiProcessWhereUniqueInput;
  update: ArticleGoodDealAiProcessWithForcedArticleUpdateInput;
  create: ArticleGoodDealAiProcessWithForcedArticleCreateInput;
}

export interface ArticleNestedUpsertArticleGuidesUpdateInput {
  where: ArticleGuideWithForcedArticleWhereUniqueInput;
  update: ArticleGuideWithForcedArticleUpdateInput;
  create: ArticleGuideWithForcedArticleCreateInput;
}

export interface ArticleNestedUpsertArticleHistoriesUpdateInput {
  where: ArticleHistoryWhereUniqueInput;
  update: ArticleHistoryWithForcedArticleUpdateInput;
  create: ArticleHistoryWithForcedArticleCreateInput;
}

export interface ArticleNestedUpsertArticleMachineTagsUpdateInput {
  where: ArticleMachineTagWithForcedArticleWhereUniqueInput;
  update: ArticleMachineTagWithForcedArticleUpdateInput;
  create: ArticleMachineTagWithForcedArticleCreateInput;
}

export interface ArticleNestedUpsertArticleQualifiersUpdateInput {
  where: ArticleQualifierWithForcedArticleWhereUniqueInput;
  update: ArticleQualifierWithForcedArticleUpdateInput;
  create: ArticleQualifierWithForcedArticleCreateInput;
}

export interface ArticleNestedUpsertArticleTagsUpdateInput {
  where: ArticleTagWithForcedArticleWhereUniqueInput;
  update: ArticleTagWithForcedArticleUpdateInput;
  create: ArticleTagWithForcedArticleCreateInput;
}

export interface ArticleNestedUpsertArticleTaxonomiesUpdateInput {
  where: ArticleTaxonomyWithForcedArticleWhereUniqueInput;
  update: ArticleTaxonomyWithForcedArticleUpdateInput;
  create: ArticleTaxonomyWithForcedArticleCreateInput;
}

export interface ArticleNestedUpsertArticleUrlsUpdateInput {
  where: ArticleUrlWithForcedArticleWhereUniqueInput;
  update: ArticleUrlWithForcedArticleUpdateInput;
  create: ArticleUrlWithForcedArticleCreateInput;
}

export interface ArticleNestedUpsertArticleUsersUpdateInput {
  where: ArticleUserWhereUniqueInput;
  update: ArticleUserWithForcedArticleUpdateInput;
  create: ArticleUserWithForcedArticleCreateInput;
}

export interface ArticleNestedUpsertLiveUpdateInput {
  where: LiveWithOptionalArticleWhereUniqueInput;
  update: LiveWithForcedArticleUpdateInput;
  create: LiveWithForcedArticleCreateInput;
}

export interface ArticleNestedUpsertOpenAiProcessesUpdateInput {
  where: OpenAiProcessWhereUniqueInput;
  update: OpenAiProcessWithForcedArticleUpdateInput;
  create: OpenAiProcessWithForcedArticleCreateInput;
}

export interface ArticleNestedUpsertParentArticleUpdateInput {
  where: ArticleHistoryWhereUniqueInput;
  update: ArticleHistoryWithForcedDuplicatedArticleUpdateInput;
  create: ArticleHistoryWithForcedDuplicatedArticleCreateInput;
}

export interface ArticleNestedUpsertPushesUpdateInput {
  where: PushWhereUniqueInput;
  update: PushWithForcedArticleUpdateInput;
  create: PushWithForcedArticleCreateInput;
}

export interface ArticleNestedUpsertSourceCreateInput {
  where: SourceWhereUniqueInput;
  update: SourceUpdateInput;
  create: SourceCreateInput;
}

export interface ArticleNestedUpsertSourceUpdateInput {
  where: SourceWhereUniqueInput;
  update: SourceUpdateInput;
  create: SourceCreateInput;
}

export interface ArticleQualifierArticleQualifiersSearchWhereInput {
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  qualifier?: QualifierArticleQualifiersQualifierSearchWhereInput | null;
  AND?: ArticleQualifierArticleQualifiersSearchWhereInput[] | null;
  OR?: ArticleQualifierArticleQualifiersSearchWhereInput[] | null;
  NOT?: ArticleQualifierArticleQualifiersSearchWhereInput | null;
}

export interface ArticleQualifierNestedArticleCreateInput {
  connect?: ArticleWhereUniqueInput | null;
  update?: ArticleQualifierNestedUpdateArticleCreateInput | null;
  create?: ArticleCreateInput | null;
  upsert?: ArticleQualifierNestedUpsertArticleCreateInput | null;
}

export interface ArticleQualifierNestedQualifierCreateInput {
  connect?: QualifierWhereUniqueInput | null;
  update?: ArticleQualifierNestedUpdateQualifierCreateInput | null;
  create?: QualifierCreateInput | null;
  upsert?: ArticleQualifierNestedUpsertQualifierCreateInput | null;
}

export interface ArticleQualifierNestedUpdateArticleCreateInput {
  where: ArticleWhereUniqueInput;
  data: ArticleUpdateInput;
}

export interface ArticleQualifierNestedUpdateQualifierCreateInput {
  where: QualifierWhereUniqueInput;
  data: QualifierUpdateInput;
}

export interface ArticleQualifierNestedUpsertArticleCreateInput {
  where: ArticleWhereUniqueInput;
  update: ArticleUpdateInput;
  create: ArticleCreateInput;
}

export interface ArticleQualifierNestedUpsertQualifierCreateInput {
  where: QualifierWhereUniqueInput;
  update: QualifierUpdateInput;
  create: QualifierCreateInput;
}

/**
 * Filters the "ArticleQualifier" nodes by specifying some conditions
 */
export interface ArticleQualifierWhereInput {
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  article?: ArticleWhereInput | null;
  qualifier?: QualifierWhereInput | null;
  AND?: ArticleQualifierWhereInput[] | null;
  OR?: ArticleQualifierWhereInput[] | null;
  NOT?: ArticleQualifierWhereInput | null;
}

export interface ArticleQualifierWithForcedArticleCreateInput {
  order: number;
  qualifier: ArticleQualifierNestedQualifierCreateInput;
}

export interface ArticleQualifierWithForcedArticleUpdateInput {
  order?: number | null;
}

/**
 * Given a forced "article", identifies exactly one "ArticleQualifier" node with one of these unique values:
 * - qualifier
 * - order
 */
export interface ArticleQualifierWithForcedArticleWhereUniqueInput {
  qualifier?: QualifierWhereUniqueInput | null;
  order?: number | null;
}

export interface ArticleQualifierWithForcedQualifierCreateInput {
  order: number;
  article: ArticleQualifierNestedArticleCreateInput;
}

export interface ArticleQualifierWithForcedQualifierUpdateInput {
  order?: number | null;
}

/**
 * Given a forced "qualifier", identifies exactly one "ArticleQualifier" node.
 */
export interface ArticleQualifierWithForcedQualifierWhereUniqueInput {
  article: ArticleWhereUniqueInput;
}

export interface ArticleSearchWhereInput {
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  editedAt_is_null?: boolean | null;
  editedAt?: GraphQL$DateTime | null;
  editedAt_not?: GraphQL$DateTime | null;
  editedAt_in?: (GraphQL$DateTime | null)[] | null;
  editedAt_not_in?: (GraphQL$DateTime | null)[] | null;
  editedAt_lt?: GraphQL$DateTime | null;
  editedAt_lte?: GraphQL$DateTime | null;
  editedAt_gt?: GraphQL$DateTime | null;
  editedAt_gte?: GraphQL$DateTime | null;
  publishedAt_is_null?: boolean | null;
  publishedAt?: GraphQL$DateTime | null;
  publishedAt_not?: GraphQL$DateTime | null;
  publishedAt_in?: (GraphQL$DateTime | null)[] | null;
  publishedAt_not_in?: (GraphQL$DateTime | null)[] | null;
  publishedAt_lt?: GraphQL$DateTime | null;
  publishedAt_lte?: GraphQL$DateTime | null;
  publishedAt_gt?: GraphQL$DateTime | null;
  publishedAt_gte?: GraphQL$DateTime | null;
  brandKey?: BrandKey | null;
  brandKey_not?: BrandKey | null;
  brandKey_in?: BrandKey[] | null;
  brandKey_not_in?: BrandKey[] | null;
  format?: ArticleFormat | null;
  format_not?: ArticleFormat | null;
  format_in?: ArticleFormat[] | null;
  format_not_in?: ArticleFormat[] | null;
  status?: ArticleStatus | null;
  status_not?: ArticleStatus | null;
  status_in?: ArticleStatus[] | null;
  status_not_in?: ArticleStatus[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  lead_is_null?: boolean | null;
  lead_contains?: string | null;
  lead_not_contains?: string | null;
  body_is_null?: boolean | null;
  body_contains?: string | null;
  body_not_contains?: string | null;
  source?: SourceSourceSearchWhereInput | null;
  source_is_null?: boolean | null;
  articleAuthors_some?: ArticleAuthorArticleAuthorsSearchWhereInput | null;
  articleCategories_some?: ArticleCategoryArticleCategoriesSearchWhereInput | null;
  articleChannels_some?: ArticleChannelArticleChannelsSearchWhereInput | null;
  articleEvents_some?: ArticleEventArticleEventsSearchWhereInput | null;
  articleGuides_some?: ArticleGuideArticleGuidesSearchWhereInput | null;
  articleMachineTags_some?: ArticleMachineTagArticleMachineTagsSearchWhereInput | null;
  articleQualifiers_some?: ArticleQualifierArticleQualifiersSearchWhereInput | null;
  articleTags_some?: ArticleTagArticleTagsSearchWhereInput | null;
  articleUrls_some?: ArticleUrlArticleUrlsSearchWhereInput | null;
  creatorId_is_null?: boolean | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: (string | null)[] | null;
  creatorId_not_in?: (string | null)[] | null;
  medias_is_null?: boolean | null;
  medias_contains?: string | null;
  medias_not_contains?: string | null;
  bodyMedias_is_null?: boolean | null;
  bodyMedias_contains?: string | null;
  bodyMedias_not_contains?: string | null;
  live?: LiveLiveSearchWhereInput | null;
  live_is_null?: boolean | null;
  pushes_some?: PushPushesSearchWhereInput | null;
  AND?: ArticleSearchWhereInput[] | null;
  OR?: ArticleSearchWhereInput[] | null;
  NOT?: ArticleSearchWhereInput | null;
}

export interface ArticleTagArticleTagsSearchWhereInput {
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  tag?: TagArticleTagsTagSearchWhereInput | null;
  AND?: ArticleTagArticleTagsSearchWhereInput[] | null;
  OR?: ArticleTagArticleTagsSearchWhereInput[] | null;
  NOT?: ArticleTagArticleTagsSearchWhereInput | null;
}

export interface ArticleTagNestedArticleCreateInput {
  connect?: ArticleWhereUniqueInput | null;
  update?: ArticleTagNestedUpdateArticleCreateInput | null;
  create?: ArticleCreateInput | null;
  upsert?: ArticleTagNestedUpsertArticleCreateInput | null;
}

export interface ArticleTagNestedTagCreateInput {
  connect?: TagWhereUniqueInput | null;
  update?: ArticleTagNestedUpdateTagCreateInput | null;
  create?: TagCreateInput | null;
  upsert?: ArticleTagNestedUpsertTagCreateInput | null;
}

export interface ArticleTagNestedUpdateArticleCreateInput {
  where: ArticleWhereUniqueInput;
  data: ArticleUpdateInput;
}

export interface ArticleTagNestedUpdateTagCreateInput {
  where: TagWhereUniqueInput;
  data: TagUpdateInput;
}

export interface ArticleTagNestedUpsertArticleCreateInput {
  where: ArticleWhereUniqueInput;
  update: ArticleUpdateInput;
  create: ArticleCreateInput;
}

export interface ArticleTagNestedUpsertTagCreateInput {
  where: TagWhereUniqueInput;
  update: TagUpdateInput;
  create: TagCreateInput;
}

/**
 * Filters the "ArticleTag" nodes by specifying some conditions
 */
export interface ArticleTagWhereInput {
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  article?: ArticleWhereInput | null;
  tag?: TagWhereInput | null;
  AND?: ArticleTagWhereInput[] | null;
  OR?: ArticleTagWhereInput[] | null;
  NOT?: ArticleTagWhereInput | null;
}

export interface ArticleTagWithForcedArticleCreateInput {
  order: number;
  tag: ArticleTagNestedTagCreateInput;
}

export interface ArticleTagWithForcedArticleUpdateInput {
  order?: number | null;
}

/**
 * Given a forced "article", identifies exactly one "ArticleTag" node with one of these unique values:
 * - tag
 * - order
 */
export interface ArticleTagWithForcedArticleWhereUniqueInput {
  tag?: TagWhereUniqueInput | null;
  order?: number | null;
}

export interface ArticleTagWithForcedTagCreateInput {
  order: number;
  article: ArticleTagNestedArticleCreateInput;
}

export interface ArticleTagWithForcedTagUpdateInput {
  order?: number | null;
}

/**
 * Given a forced "tag", identifies exactly one "ArticleTag" node.
 */
export interface ArticleTagWithForcedTagWhereUniqueInput {
  article: ArticleWhereUniqueInput;
}

export interface ArticleTaxonomyNestedArticleCreateInput {
  connect?: ArticleWhereUniqueInput | null;
  update?: ArticleTaxonomyNestedUpdateArticleCreateInput | null;
  create?: ArticleCreateInput | null;
  upsert?: ArticleTaxonomyNestedUpsertArticleCreateInput | null;
}

export interface ArticleTaxonomyNestedTaxonomyCreateInput {
  connect?: TaxonomyWhereUniqueInput | null;
  update?: ArticleTaxonomyNestedUpdateTaxonomyCreateInput | null;
  create?: TaxonomyCreateInput | null;
  upsert?: ArticleTaxonomyNestedUpsertTaxonomyCreateInput | null;
}

export interface ArticleTaxonomyNestedUpdateArticleCreateInput {
  where: ArticleWhereUniqueInput;
  data: ArticleUpdateInput;
}

export interface ArticleTaxonomyNestedUpdateTaxonomyCreateInput {
  where: TaxonomyWhereUniqueInput;
  data: TaxonomyUpdateInput;
}

export interface ArticleTaxonomyNestedUpsertArticleCreateInput {
  where: ArticleWhereUniqueInput;
  update: ArticleUpdateInput;
  create: ArticleCreateInput;
}

export interface ArticleTaxonomyNestedUpsertTaxonomyCreateInput {
  where: TaxonomyWhereUniqueInput;
  update: TaxonomyUpdateInput;
  create: TaxonomyCreateInput;
}

/**
 * Filters the "ArticleTaxonomy" nodes by specifying some conditions
 */
export interface ArticleTaxonomyWhereInput {
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  article?: ArticleWhereInput | null;
  taxonomy?: TaxonomyWhereInput | null;
  AND?: ArticleTaxonomyWhereInput[] | null;
  OR?: ArticleTaxonomyWhereInput[] | null;
  NOT?: ArticleTaxonomyWhereInput | null;
}

export interface ArticleTaxonomyWithForcedArticleCreateInput {
  order: number;
  taxonomy: ArticleTaxonomyNestedTaxonomyCreateInput;
}

export interface ArticleTaxonomyWithForcedArticleUpdateInput {
  order?: number | null;
}

/**
 * Given a forced "article", identifies exactly one "ArticleTaxonomy" node with one of these unique values:
 * - taxonomy
 * - order
 */
export interface ArticleTaxonomyWithForcedArticleWhereUniqueInput {
  taxonomy?: TaxonomyWhereUniqueInput | null;
  order?: number | null;
}

export interface ArticleTaxonomyWithForcedTaxonomyCreateInput {
  order: number;
  article: ArticleTaxonomyNestedArticleCreateInput;
}

export interface ArticleTaxonomyWithForcedTaxonomyUpdateInput {
  order?: number | null;
}

/**
 * Given a forced "taxonomy", identifies exactly one "ArticleTaxonomy" node.
 */
export interface ArticleTaxonomyWithForcedTaxonomyWhereUniqueInput {
  article: ArticleWhereUniqueInput;
}

export interface ArticleUpdateInput {
  format?: ArticleFormat | null;
  status?: ArticleStatus | null;
  publishedAt?: GraphQL$DateTime | null;
  editedAt?: GraphQL$DateTime | null;
  title?: string | null;
  secondaryTitle?: string | null;
  partnerTitle?: string | null;
  discoverTitle?: string | null;
  slug?: string | null;
  lead?: string | null;
  body?: string | null;
  signature?: string | null;
  medias?: string | null;
  bodyMedias?: string | null;
  brandKey?: BrandKey | null;
  platform?: string | null;
  platformId?: string | null;
  source?: ArticleNestedSourceUpdateInput | null;
  articleAuthors?: ArticleNestedArticleAuthorsUpdateInput | null;
  articleCategories?: ArticleNestedArticleCategoriesUpdateInput | null;
  articleChannels?: ArticleNestedArticleChannelsUpdateInput | null;
  articleCreatorData?: ArticleNestedArticleCreatorDataUpdateInput | null;
  articleEvents?: ArticleNestedArticleEventsUpdateInput | null;
  articleExtension?: ArticleNestedArticleExtensionUpdateInput | null;
  articleGoodDealAiProcesses?: ArticleNestedArticleGoodDealAiProcessesUpdateInput | null;
  articleGuides?: ArticleNestedArticleGuidesUpdateInput | null;
  articleHistories?: ArticleNestedArticleHistoriesUpdateInput | null;
  parentArticle?: ArticleNestedParentArticleUpdateInput | null;
  articleMachineTags?: ArticleNestedArticleMachineTagsUpdateInput | null;
  articleQualifiers?: ArticleNestedArticleQualifiersUpdateInput | null;
  articleTags?: ArticleNestedArticleTagsUpdateInput | null;
  articleTaxonomies?: ArticleNestedArticleTaxonomiesUpdateInput | null;
  articleUrls?: ArticleNestedArticleUrlsUpdateInput | null;
  articleUsers?: ArticleNestedArticleUsersUpdateInput | null;
  live?: ArticleNestedLiveUpdateInput | null;
  openAiProcesses?: ArticleNestedOpenAiProcessesUpdateInput | null;
  pushes?: ArticleNestedPushesUpdateInput | null;
}

export interface ArticleUrlArticleUrlsSearchWhereInput {
  url?: UrlArticleUrlsUrlSearchWhereInput | null;
  AND?: ArticleUrlArticleUrlsSearchWhereInput[] | null;
  OR?: ArticleUrlArticleUrlsSearchWhereInput[] | null;
  NOT?: ArticleUrlArticleUrlsSearchWhereInput | null;
}

export interface ArticleUrlNestedArticleCreateInput {
  connect?: ArticleWhereUniqueInput | null;
  update?: ArticleUrlNestedUpdateArticleCreateInput | null;
  create?: ArticleCreateInput | null;
  upsert?: ArticleUrlNestedUpsertArticleCreateInput | null;
}

export interface ArticleUrlNestedUpdateArticleCreateInput {
  where: ArticleWhereUniqueInput;
  data: ArticleUpdateInput;
}

export interface ArticleUrlNestedUpsertArticleCreateInput {
  where: ArticleWhereUniqueInput;
  update: ArticleUpdateInput;
  create: ArticleCreateInput;
}

export interface ArticleUrlNestedUrlCreateInput {
  connect?: UrlWhereUniqueInput | null;
  create?: UrlCreateInput | null;
}

/**
 * Filters the "ArticleUrl" nodes by specifying some conditions
 */
export interface ArticleUrlWhereInput {
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  article?: ArticleWhereInput | null;
  url?: UrlWhereInput | null;
  AND?: ArticleUrlWhereInput[] | null;
  OR?: ArticleUrlWhereInput[] | null;
  NOT?: ArticleUrlWhereInput | null;
}

export interface ArticleUrlWithForcedArticleCreateInput {
  order: number;
  url: ArticleUrlNestedUrlCreateInput;
}

export interface ArticleUrlWithForcedArticleUpdateInput {
  order?: number | null;
}

/**
 * Given a forced "article", identifies exactly one "ArticleUrl" node with one of these unique values:
 * - url
 * - order
 */
export interface ArticleUrlWithForcedArticleWhereUniqueInput {
  url?: UrlWhereUniqueInput | null;
  order?: number | null;
}

export interface ArticleUrlWithForcedUrlCreateInput {
  order: number;
  article: ArticleUrlNestedArticleCreateInput;
}

/**
 * Filters the "ArticleUser" nodes by specifying some conditions
 */
export interface ArticleUserWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  userId?: string | null;
  userId_not?: string | null;
  userId_in?: string[] | null;
  userId_not_in?: string[] | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_is_null?: boolean | null;
  creatorId_in?: (string | null)[] | null;
  creatorId_not_in?: (string | null)[] | null;
  article?: ArticleWhereInput | null;
  AND?: ArticleUserWhereInput[] | null;
  OR?: ArticleUserWhereInput[] | null;
  NOT?: ArticleUserWhereInput | null;
}

/**
 * Identifies exactly one "ArticleUser" node with one of these unique values:
 * - _id
 * - id
 */
export interface ArticleUserWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
}

export interface ArticleUserWithForcedArticleCreateInput {
  id?: string | null;
  userId: string;
}

export interface ArticleUserWithForcedArticleUpdateInput {
  userId?: string | null;
}

/**
 * Filters the "Article" nodes by specifying some conditions
 */
export interface ArticleWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  format?: ArticleFormat | null;
  format_not?: ArticleFormat | null;
  format_in?: ArticleFormat[] | null;
  format_not_in?: ArticleFormat[] | null;
  status?: ArticleStatus | null;
  status_not?: ArticleStatus | null;
  status_in?: ArticleStatus[] | null;
  status_not_in?: ArticleStatus[] | null;
  publishedAt?: GraphQL$DateTime | null;
  publishedAt_not?: GraphQL$DateTime | null;
  publishedAt_is_null?: boolean | null;
  publishedAt_in?: (GraphQL$DateTime | null)[] | null;
  publishedAt_not_in?: (GraphQL$DateTime | null)[] | null;
  publishedAt_lt?: GraphQL$DateTime | null;
  publishedAt_lte?: GraphQL$DateTime | null;
  publishedAt_gt?: GraphQL$DateTime | null;
  publishedAt_gte?: GraphQL$DateTime | null;
  editedAt?: GraphQL$DateTime | null;
  editedAt_not?: GraphQL$DateTime | null;
  editedAt_is_null?: boolean | null;
  editedAt_in?: (GraphQL$DateTime | null)[] | null;
  editedAt_not_in?: (GraphQL$DateTime | null)[] | null;
  editedAt_lt?: GraphQL$DateTime | null;
  editedAt_lte?: GraphQL$DateTime | null;
  editedAt_gt?: GraphQL$DateTime | null;
  editedAt_gte?: GraphQL$DateTime | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  title_starts_with?: string | null;
  title_not_starts_with?: string | null;
  title_ends_with?: string | null;
  title_not_ends_with?: string | null;
  title_lt?: string | null;
  title_lte?: string | null;
  title_gt?: string | null;
  title_gte?: string | null;
  secondaryTitle?: string | null;
  secondaryTitle_not?: string | null;
  secondaryTitle_is_null?: boolean | null;
  secondaryTitle_in?: (string | null)[] | null;
  secondaryTitle_not_in?: (string | null)[] | null;
  secondaryTitle_contains?: string | null;
  secondaryTitle_not_contains?: string | null;
  secondaryTitle_starts_with?: string | null;
  secondaryTitle_not_starts_with?: string | null;
  secondaryTitle_ends_with?: string | null;
  secondaryTitle_not_ends_with?: string | null;
  secondaryTitle_lt?: string | null;
  secondaryTitle_lte?: string | null;
  secondaryTitle_gt?: string | null;
  secondaryTitle_gte?: string | null;
  partnerTitle?: string | null;
  partnerTitle_not?: string | null;
  partnerTitle_is_null?: boolean | null;
  partnerTitle_in?: (string | null)[] | null;
  partnerTitle_not_in?: (string | null)[] | null;
  partnerTitle_contains?: string | null;
  partnerTitle_not_contains?: string | null;
  partnerTitle_starts_with?: string | null;
  partnerTitle_not_starts_with?: string | null;
  partnerTitle_ends_with?: string | null;
  partnerTitle_not_ends_with?: string | null;
  partnerTitle_lt?: string | null;
  partnerTitle_lte?: string | null;
  partnerTitle_gt?: string | null;
  partnerTitle_gte?: string | null;
  discoverTitle?: string | null;
  discoverTitle_not?: string | null;
  discoverTitle_is_null?: boolean | null;
  discoverTitle_in?: (string | null)[] | null;
  discoverTitle_not_in?: (string | null)[] | null;
  discoverTitle_contains?: string | null;
  discoverTitle_not_contains?: string | null;
  discoverTitle_starts_with?: string | null;
  discoverTitle_not_starts_with?: string | null;
  discoverTitle_ends_with?: string | null;
  discoverTitle_not_ends_with?: string | null;
  discoverTitle_lt?: string | null;
  discoverTitle_lte?: string | null;
  discoverTitle_gt?: string | null;
  discoverTitle_gte?: string | null;
  slug?: string | null;
  slug_not?: string | null;
  slug_is_null?: boolean | null;
  slug_in?: (string | null)[] | null;
  slug_not_in?: (string | null)[] | null;
  slug_contains?: string | null;
  slug_not_contains?: string | null;
  slug_starts_with?: string | null;
  slug_not_starts_with?: string | null;
  slug_ends_with?: string | null;
  slug_not_ends_with?: string | null;
  slug_lt?: string | null;
  slug_lte?: string | null;
  slug_gt?: string | null;
  slug_gte?: string | null;
  lead?: string | null;
  lead_not?: string | null;
  lead_is_null?: boolean | null;
  lead_in?: (string | null)[] | null;
  lead_not_in?: (string | null)[] | null;
  lead_contains?: string | null;
  lead_not_contains?: string | null;
  lead_starts_with?: string | null;
  lead_not_starts_with?: string | null;
  lead_ends_with?: string | null;
  lead_not_ends_with?: string | null;
  lead_lt?: string | null;
  lead_lte?: string | null;
  lead_gt?: string | null;
  lead_gte?: string | null;
  body?: string | null;
  body_not?: string | null;
  body_is_null?: boolean | null;
  body_in?: (string | null)[] | null;
  body_not_in?: (string | null)[] | null;
  body_contains?: string | null;
  body_not_contains?: string | null;
  body_starts_with?: string | null;
  body_not_starts_with?: string | null;
  body_ends_with?: string | null;
  body_not_ends_with?: string | null;
  body_lt?: string | null;
  body_lte?: string | null;
  body_gt?: string | null;
  body_gte?: string | null;
  signature?: string | null;
  signature_not?: string | null;
  signature_is_null?: boolean | null;
  signature_in?: (string | null)[] | null;
  signature_not_in?: (string | null)[] | null;
  signature_contains?: string | null;
  signature_not_contains?: string | null;
  signature_starts_with?: string | null;
  signature_not_starts_with?: string | null;
  signature_ends_with?: string | null;
  signature_not_ends_with?: string | null;
  signature_lt?: string | null;
  signature_lte?: string | null;
  signature_gt?: string | null;
  signature_gte?: string | null;
  medias?: string | null;
  medias_not?: string | null;
  medias_is_null?: boolean | null;
  medias_in?: (string | null)[] | null;
  medias_not_in?: (string | null)[] | null;
  medias_contains?: string | null;
  medias_not_contains?: string | null;
  medias_starts_with?: string | null;
  medias_not_starts_with?: string | null;
  medias_ends_with?: string | null;
  medias_not_ends_with?: string | null;
  medias_lt?: string | null;
  medias_lte?: string | null;
  medias_gt?: string | null;
  medias_gte?: string | null;
  bodyMedias?: string | null;
  bodyMedias_not?: string | null;
  bodyMedias_is_null?: boolean | null;
  bodyMedias_in?: (string | null)[] | null;
  bodyMedias_not_in?: (string | null)[] | null;
  bodyMedias_contains?: string | null;
  bodyMedias_not_contains?: string | null;
  bodyMedias_starts_with?: string | null;
  bodyMedias_not_starts_with?: string | null;
  bodyMedias_ends_with?: string | null;
  bodyMedias_not_ends_with?: string | null;
  bodyMedias_lt?: string | null;
  bodyMedias_lte?: string | null;
  bodyMedias_gt?: string | null;
  bodyMedias_gte?: string | null;
  brandKey?: BrandKey | null;
  brandKey_not?: BrandKey | null;
  brandKey_in?: BrandKey[] | null;
  brandKey_not_in?: BrandKey[] | null;
  platform?: string | null;
  platform_not?: string | null;
  platform_is_null?: boolean | null;
  platform_in?: (string | null)[] | null;
  platform_not_in?: (string | null)[] | null;
  platform_contains?: string | null;
  platform_not_contains?: string | null;
  platform_starts_with?: string | null;
  platform_not_starts_with?: string | null;
  platform_ends_with?: string | null;
  platform_not_ends_with?: string | null;
  platform_lt?: string | null;
  platform_lte?: string | null;
  platform_gt?: string | null;
  platform_gte?: string | null;
  platformId?: string | null;
  platformId_not?: string | null;
  platformId_is_null?: boolean | null;
  platformId_in?: (string | null)[] | null;
  platformId_not_in?: (string | null)[] | null;
  platformId_contains?: string | null;
  platformId_not_contains?: string | null;
  platformId_starts_with?: string | null;
  platformId_not_starts_with?: string | null;
  platformId_ends_with?: string | null;
  platformId_not_ends_with?: string | null;
  platformId_lt?: string | null;
  platformId_lte?: string | null;
  platformId_gt?: string | null;
  platformId_gte?: string | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_is_null?: boolean | null;
  creatorId_in?: (string | null)[] | null;
  creatorId_not_in?: (string | null)[] | null;
  source?: SourceWhereInput | null;
  source_is_null?: boolean | null;
  articleAuthors_some?: ArticleAuthorWhereInput | null;
  articleCategories_some?: ArticleCategoryWhereInput | null;
  articleChannels_some?: ArticleChannelWhereInput | null;
  articleCreatorData?: ArticleCreatorDataWhereInput | null;
  articleCreatorData_is_null?: boolean | null;
  articleEvents_some?: ArticleEventWhereInput | null;
  articleExtension?: ArticleExtensionWhereInput | null;
  articleExtension_is_null?: boolean | null;
  articleGoodDealAiProcesses_some?: ArticleGoodDealAiProcessWhereInput | null;
  articleGuides_some?: ArticleGuideWhereInput | null;
  articleHistories_some?: ArticleHistoryWhereInput | null;
  parentArticle_some?: ArticleHistoryWhereInput | null;
  articleMachineTags_some?: ArticleMachineTagWhereInput | null;
  articleQualifiers_some?: ArticleQualifierWhereInput | null;
  articleTags_some?: ArticleTagWhereInput | null;
  articleTaxonomies_some?: ArticleTaxonomyWhereInput | null;
  articleUrls_some?: ArticleUrlWhereInput | null;
  articleUsers_some?: ArticleUserWhereInput | null;
  live?: LiveWhereInput | null;
  live_is_null?: boolean | null;
  openAiProcesses_some?: OpenAiProcessWhereInput | null;
  pushes_some?: PushWhereInput | null;
  AND?: ArticleWhereInput[] | null;
  OR?: ArticleWhereInput[] | null;
  NOT?: ArticleWhereInput | null;
}

/**
 * Identifies exactly one "Article" node with one of these unique combinations of values:
 * - _id
 * - id
 * - brandKey, platform, platformId
 */
export interface ArticleWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
  brandKey?: BrandKey | null;
  platform?: string | null;
  platformId?: string | null;
}

export interface ArticleWithForcedSourceCreateInput {
  id?: string | null;
  format: ArticleFormat;
  status: ArticleStatus;
  publishedAt?: GraphQL$DateTime | null;
  editedAt?: GraphQL$DateTime | null;
  title: string;
  secondaryTitle?: string | null;
  partnerTitle?: string | null;
  discoverTitle?: string | null;
  slug?: string | null;
  lead?: string | null;
  body?: string | null;
  signature?: string | null;
  medias?: string | null;
  bodyMedias?: string | null;
  brandKey: BrandKey;
  platform?: string | null;
  platformId?: string | null;
  articleAuthors?: ArticleNestedArticleAuthorsCreateInput | null;
  articleCategories?: ArticleNestedArticleCategoriesCreateInput | null;
  articleChannels?: ArticleNestedArticleChannelsCreateInput | null;
  articleCreatorData?: ArticleNestedArticleCreatorDataCreateInput | null;
  articleEvents?: ArticleNestedArticleEventsCreateInput | null;
  articleExtension?: ArticleNestedArticleExtensionCreateInput | null;
  articleGoodDealAiProcesses?: ArticleNestedArticleGoodDealAiProcessesCreateInput | null;
  articleGuides?: ArticleNestedArticleGuidesCreateInput | null;
  articleHistories?: ArticleNestedArticleHistoriesCreateInput | null;
  parentArticle?: ArticleNestedParentArticleCreateInput | null;
  articleMachineTags?: ArticleNestedArticleMachineTagsCreateInput | null;
  articleQualifiers?: ArticleNestedArticleQualifiersCreateInput | null;
  articleTags?: ArticleNestedArticleTagsCreateInput | null;
  articleTaxonomies?: ArticleNestedArticleTaxonomiesCreateInput | null;
  articleUrls?: ArticleNestedArticleUrlsCreateInput | null;
  articleUsers?: ArticleNestedArticleUsersCreateInput | null;
  live?: ArticleNestedLiveCreateInput | null;
  openAiProcesses?: ArticleNestedOpenAiProcessesCreateInput | null;
  pushes?: ArticleNestedPushesCreateInput | null;
}

export interface ArticleWithForcedSourceUpdateInput {
  format?: ArticleFormat | null;
  status?: ArticleStatus | null;
  publishedAt?: GraphQL$DateTime | null;
  editedAt?: GraphQL$DateTime | null;
  title?: string | null;
  secondaryTitle?: string | null;
  partnerTitle?: string | null;
  discoverTitle?: string | null;
  slug?: string | null;
  lead?: string | null;
  body?: string | null;
  signature?: string | null;
  medias?: string | null;
  bodyMedias?: string | null;
  brandKey?: BrandKey | null;
  platform?: string | null;
  platformId?: string | null;
  articleAuthors?: ArticleNestedArticleAuthorsUpdateInput | null;
  articleCategories?: ArticleNestedArticleCategoriesUpdateInput | null;
  articleChannels?: ArticleNestedArticleChannelsUpdateInput | null;
  articleCreatorData?: ArticleNestedArticleCreatorDataUpdateInput | null;
  articleEvents?: ArticleNestedArticleEventsUpdateInput | null;
  articleExtension?: ArticleNestedArticleExtensionUpdateInput | null;
  articleGoodDealAiProcesses?: ArticleNestedArticleGoodDealAiProcessesUpdateInput | null;
  articleGuides?: ArticleNestedArticleGuidesUpdateInput | null;
  articleHistories?: ArticleNestedArticleHistoriesUpdateInput | null;
  parentArticle?: ArticleNestedParentArticleUpdateInput | null;
  articleMachineTags?: ArticleNestedArticleMachineTagsUpdateInput | null;
  articleQualifiers?: ArticleNestedArticleQualifiersUpdateInput | null;
  articleTags?: ArticleNestedArticleTagsUpdateInput | null;
  articleTaxonomies?: ArticleNestedArticleTaxonomiesUpdateInput | null;
  articleUrls?: ArticleNestedArticleUrlsUpdateInput | null;
  articleUsers?: ArticleNestedArticleUsersUpdateInput | null;
  live?: ArticleNestedLiveUpdateInput | null;
  openAiProcesses?: ArticleNestedOpenAiProcessesUpdateInput | null;
  pushes?: ArticleNestedPushesUpdateInput | null;
}

export interface AttributeCreateInput {
  id?: string | null;
  brandKey: BrandKey;
  title: string;
  slug?: string | null;
  authorAttributes?: AttributeNestedAuthorAttributesCreateInput | null;
}

export interface AttributeNestedAuthorAttributesCreateInput {
  create?: AuthorAttributeWithForcedAttributeCreateInput[] | null;
}

export interface AttributeNestedAuthorAttributesUpdateInput {
  update?: AttributeNestedUpdateAuthorAttributesUpdateInput[] | null;
  create?: AuthorAttributeWithForcedAttributeCreateInput[] | null;
  upsert?: AttributeNestedUpsertAuthorAttributesUpdateInput[] | null;
  delete?: AuthorAttributeWithForcedAttributeWhereUniqueInput[] | null;
}

export interface AttributeNestedUpdateAuthorAttributesUpdateInput {
  where: AuthorAttributeWithForcedAttributeWhereUniqueInput;
  data: AuthorAttributeWithForcedAttributeUpdateInput;
}

export interface AttributeNestedUpsertAuthorAttributesUpdateInput {
  where: AuthorAttributeWithForcedAttributeWhereUniqueInput;
  update: AuthorAttributeWithForcedAttributeUpdateInput;
  create: AuthorAttributeWithForcedAttributeCreateInput;
}

export interface AttributeUpdateInput {
  authorAttributes?: AttributeNestedAuthorAttributesUpdateInput | null;
}

/**
 * Filters the "Attribute" nodes by specifying some conditions
 */
export interface AttributeWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  brandKey?: BrandKey | null;
  brandKey_not?: BrandKey | null;
  brandKey_in?: BrandKey[] | null;
  brandKey_not_in?: BrandKey[] | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  title_starts_with?: string | null;
  title_not_starts_with?: string | null;
  title_ends_with?: string | null;
  title_not_ends_with?: string | null;
  title_lt?: string | null;
  title_lte?: string | null;
  title_gt?: string | null;
  title_gte?: string | null;
  slug?: string | null;
  slug_not?: string | null;
  slug_in?: string[] | null;
  slug_not_in?: string[] | null;
  slug_contains?: string | null;
  slug_not_contains?: string | null;
  slug_starts_with?: string | null;
  slug_not_starts_with?: string | null;
  slug_ends_with?: string | null;
  slug_not_ends_with?: string | null;
  slug_lt?: string | null;
  slug_lte?: string | null;
  slug_gt?: string | null;
  slug_gte?: string | null;
  authorAttributes_some?: AuthorAttributeWhereInput | null;
  AND?: AttributeWhereInput[] | null;
  OR?: AttributeWhereInput[] | null;
  NOT?: AttributeWhereInput | null;
}

/**
 * Identifies exactly one "Attribute" node with one of these unique combinations of values:
 * - _id
 * - id
 * - brandKey, title
 * - brandKey, slug
 */
export interface AttributeWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
  brandKey?: BrandKey | null;
  title?: string | null;
  slug?: string | null;
}

export interface AuthorArticleAuthorsAuthorSearchWhereInput {
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  AND?: AuthorArticleAuthorsAuthorSearchWhereInput[] | null;
  OR?: AuthorArticleAuthorsAuthorSearchWhereInput[] | null;
  NOT?: AuthorArticleAuthorsAuthorSearchWhereInput | null;
}

export interface AuthorAttributeNestedAttributeCreateInput {
  connect?: AttributeWhereUniqueInput | null;
  update?: AuthorAttributeNestedUpdateAttributeCreateInput | null;
  create?: AttributeCreateInput | null;
  upsert?: AuthorAttributeNestedUpsertAttributeCreateInput | null;
}

export interface AuthorAttributeNestedAuthorCreateInput {
  connect?: AuthorWhereUniqueInput | null;
  update?: AuthorAttributeNestedUpdateAuthorCreateInput | null;
  create?: AuthorCreateInput | null;
  upsert?: AuthorAttributeNestedUpsertAuthorCreateInput | null;
}

export interface AuthorAttributeNestedUpdateAttributeCreateInput {
  where: AttributeWhereUniqueInput;
  data: AttributeUpdateInput;
}

export interface AuthorAttributeNestedUpdateAuthorCreateInput {
  where: AuthorWhereUniqueInput;
  data: AuthorUpdateInput;
}

export interface AuthorAttributeNestedUpsertAttributeCreateInput {
  where: AttributeWhereUniqueInput;
  update: AttributeUpdateInput;
  create: AttributeCreateInput;
}

export interface AuthorAttributeNestedUpsertAuthorCreateInput {
  where: AuthorWhereUniqueInput;
  update: AuthorUpdateInput;
  create: AuthorCreateInput;
}

/**
 * Filters the "AuthorAttribute" nodes by specifying some conditions
 */
export interface AuthorAttributeWhereInput {
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  author?: AuthorWhereInput | null;
  attribute?: AttributeWhereInput | null;
  AND?: AuthorAttributeWhereInput[] | null;
  OR?: AuthorAttributeWhereInput[] | null;
  NOT?: AuthorAttributeWhereInput | null;
}

export interface AuthorAttributeWithForcedAttributeCreateInput {
  order: number;
  author: AuthorAttributeNestedAuthorCreateInput;
}

export interface AuthorAttributeWithForcedAttributeUpdateInput {
  order?: number | null;
}

/**
 * Given a forced "attribute", identifies exactly one "AuthorAttribute" node.
 */
export interface AuthorAttributeWithForcedAttributeWhereUniqueInput {
  author: AuthorWhereUniqueInput;
}

export interface AuthorAttributeWithForcedAuthorCreateInput {
  order: number;
  attribute: AuthorAttributeNestedAttributeCreateInput;
}

export interface AuthorAttributeWithForcedAuthorUpdateInput {
  order?: number | null;
}

/**
 * Given a forced "author", identifies exactly one "AuthorAttribute" node with one of these unique values:
 * - attribute
 * - order
 */
export interface AuthorAttributeWithForcedAuthorWhereUniqueInput {
  attribute?: AttributeWhereUniqueInput | null;
  order?: number | null;
}

export interface AuthorCreateInput {
  id?: string | null;
  name: string;
  brandKey: BrandKey;
  googleId?: string | null;
  facebookId?: string | null;
  twitterId?: string | null;
  instagramId?: string | null;
  pinterestId?: string | null;
  snapshatId?: string | null;
  linkedinId?: string | null;
  avatarUrl?: string | null;
  description?: string | null;
  media?: string | null;
  hasPublicProfile?: boolean | null;
  isMain?: boolean | null;
  prismaConnectId?: string | null;
  userId?: string | null;
  resume?: string | null;
  email?: string | null;
  articleAuthors?: AuthorNestedArticleAuthorsCreateInput | null;
  authorAttributes?: AuthorNestedAuthorAttributesCreateInput | null;
  authorHistories?: AuthorNestedAuthorHistoriesCreateInput | null;
}

/**
 * Filters the "AuthorHistory" nodes by specifying some conditions
 */
export interface AuthorHistoryWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  userId?: string | null;
  userId_not?: string | null;
  userId_in?: string[] | null;
  userId_not_in?: string[] | null;
  author?: AuthorWhereInput | null;
  AND?: AuthorHistoryWhereInput[] | null;
  OR?: AuthorHistoryWhereInput[] | null;
  NOT?: AuthorHistoryWhereInput | null;
}

/**
 * Identifies exactly one "AuthorHistory" node with one of these unique values:
 * - _id
 * - id
 */
export interface AuthorHistoryWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
}

export interface AuthorHistoryWithForcedAuthorCreateInput {
  id?: string | null;
  userId: string;
}

export interface AuthorHistoryWithForcedAuthorUpdateInput {
  userId?: string | null;
}

export interface AuthorNestedArticleAuthorsCreateInput {
  create?: ArticleAuthorWithForcedAuthorCreateInput[] | null;
}

export interface AuthorNestedArticleAuthorsUpdateInput {
  update?: AuthorNestedUpdateArticleAuthorsUpdateInput[] | null;
  create?: ArticleAuthorWithForcedAuthorCreateInput[] | null;
  upsert?: AuthorNestedUpsertArticleAuthorsUpdateInput[] | null;
  delete?: ArticleAuthorWithForcedAuthorWhereUniqueInput[] | null;
}

export interface AuthorNestedAuthorAttributesCreateInput {
  create?: AuthorAttributeWithForcedAuthorCreateInput[] | null;
}

export interface AuthorNestedAuthorAttributesUpdateInput {
  update?: AuthorNestedUpdateAuthorAttributesUpdateInput[] | null;
  create?: AuthorAttributeWithForcedAuthorCreateInput[] | null;
  upsert?: AuthorNestedUpsertAuthorAttributesUpdateInput[] | null;
  delete?: AuthorAttributeWithForcedAuthorWhereUniqueInput[] | null;
}

export interface AuthorNestedAuthorHistoriesCreateInput {
  connect?: AuthorHistoryWhereUniqueInput[] | null;
  create?: AuthorHistoryWithForcedAuthorCreateInput[] | null;
}

export interface AuthorNestedAuthorHistoriesUpdateInput {
  connect?: AuthorHistoryWhereUniqueInput[] | null;
  update?: AuthorNestedUpdateAuthorHistoriesUpdateInput[] | null;
  create?: AuthorHistoryWithForcedAuthorCreateInput[] | null;
  upsert?: AuthorNestedUpsertAuthorHistoriesUpdateInput[] | null;
  delete?: AuthorHistoryWhereUniqueInput[] | null;
}

export interface AuthorNestedUpdateArticleAuthorsUpdateInput {
  where: ArticleAuthorWithForcedAuthorWhereUniqueInput;
  data: ArticleAuthorWithForcedAuthorUpdateInput;
}

export interface AuthorNestedUpdateAuthorAttributesUpdateInput {
  where: AuthorAttributeWithForcedAuthorWhereUniqueInput;
  data: AuthorAttributeWithForcedAuthorUpdateInput;
}

export interface AuthorNestedUpdateAuthorHistoriesUpdateInput {
  where: AuthorHistoryWhereUniqueInput;
  data: AuthorHistoryWithForcedAuthorUpdateInput;
}

export interface AuthorNestedUpsertArticleAuthorsUpdateInput {
  where: ArticleAuthorWithForcedAuthorWhereUniqueInput;
  update: ArticleAuthorWithForcedAuthorUpdateInput;
  create: ArticleAuthorWithForcedAuthorCreateInput;
}

export interface AuthorNestedUpsertAuthorAttributesUpdateInput {
  where: AuthorAttributeWithForcedAuthorWhereUniqueInput;
  update: AuthorAttributeWithForcedAuthorUpdateInput;
  create: AuthorAttributeWithForcedAuthorCreateInput;
}

export interface AuthorNestedUpsertAuthorHistoriesUpdateInput {
  where: AuthorHistoryWhereUniqueInput;
  update: AuthorHistoryWithForcedAuthorUpdateInput;
  create: AuthorHistoryWithForcedAuthorCreateInput;
}

export interface AuthorUpdateInput {
  name?: string | null;
  brandKey?: BrandKey | null;
  googleId?: string | null;
  facebookId?: string | null;
  twitterId?: string | null;
  instagramId?: string | null;
  pinterestId?: string | null;
  snapshatId?: string | null;
  linkedinId?: string | null;
  avatarUrl?: string | null;
  description?: string | null;
  media?: string | null;
  hasPublicProfile?: boolean | null;
  isMain?: boolean | null;
  prismaConnectId?: string | null;
  userId?: string | null;
  resume?: string | null;
  email?: string | null;
  articleAuthors?: AuthorNestedArticleAuthorsUpdateInput | null;
  authorAttributes?: AuthorNestedAuthorAttributesUpdateInput | null;
  authorHistories?: AuthorNestedAuthorHistoriesUpdateInput | null;
}

/**
 * Filters the "Author" nodes by specifying some conditions
 */
export interface AuthorWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  brandKey?: BrandKey | null;
  brandKey_not?: BrandKey | null;
  brandKey_in?: BrandKey[] | null;
  brandKey_not_in?: BrandKey[] | null;
  googleId?: string | null;
  googleId_not?: string | null;
  googleId_is_null?: boolean | null;
  googleId_in?: (string | null)[] | null;
  googleId_not_in?: (string | null)[] | null;
  googleId_contains?: string | null;
  googleId_not_contains?: string | null;
  googleId_starts_with?: string | null;
  googleId_not_starts_with?: string | null;
  googleId_ends_with?: string | null;
  googleId_not_ends_with?: string | null;
  googleId_lt?: string | null;
  googleId_lte?: string | null;
  googleId_gt?: string | null;
  googleId_gte?: string | null;
  facebookId?: string | null;
  facebookId_not?: string | null;
  facebookId_is_null?: boolean | null;
  facebookId_in?: (string | null)[] | null;
  facebookId_not_in?: (string | null)[] | null;
  facebookId_contains?: string | null;
  facebookId_not_contains?: string | null;
  facebookId_starts_with?: string | null;
  facebookId_not_starts_with?: string | null;
  facebookId_ends_with?: string | null;
  facebookId_not_ends_with?: string | null;
  facebookId_lt?: string | null;
  facebookId_lte?: string | null;
  facebookId_gt?: string | null;
  facebookId_gte?: string | null;
  twitterId?: string | null;
  twitterId_not?: string | null;
  twitterId_is_null?: boolean | null;
  twitterId_in?: (string | null)[] | null;
  twitterId_not_in?: (string | null)[] | null;
  twitterId_contains?: string | null;
  twitterId_not_contains?: string | null;
  twitterId_starts_with?: string | null;
  twitterId_not_starts_with?: string | null;
  twitterId_ends_with?: string | null;
  twitterId_not_ends_with?: string | null;
  twitterId_lt?: string | null;
  twitterId_lte?: string | null;
  twitterId_gt?: string | null;
  twitterId_gte?: string | null;
  instagramId?: string | null;
  instagramId_not?: string | null;
  instagramId_is_null?: boolean | null;
  instagramId_in?: (string | null)[] | null;
  instagramId_not_in?: (string | null)[] | null;
  instagramId_contains?: string | null;
  instagramId_not_contains?: string | null;
  instagramId_starts_with?: string | null;
  instagramId_not_starts_with?: string | null;
  instagramId_ends_with?: string | null;
  instagramId_not_ends_with?: string | null;
  instagramId_lt?: string | null;
  instagramId_lte?: string | null;
  instagramId_gt?: string | null;
  instagramId_gte?: string | null;
  pinterestId?: string | null;
  pinterestId_not?: string | null;
  pinterestId_is_null?: boolean | null;
  pinterestId_in?: (string | null)[] | null;
  pinterestId_not_in?: (string | null)[] | null;
  pinterestId_contains?: string | null;
  pinterestId_not_contains?: string | null;
  pinterestId_starts_with?: string | null;
  pinterestId_not_starts_with?: string | null;
  pinterestId_ends_with?: string | null;
  pinterestId_not_ends_with?: string | null;
  pinterestId_lt?: string | null;
  pinterestId_lte?: string | null;
  pinterestId_gt?: string | null;
  pinterestId_gte?: string | null;
  snapshatId?: string | null;
  snapshatId_not?: string | null;
  snapshatId_is_null?: boolean | null;
  snapshatId_in?: (string | null)[] | null;
  snapshatId_not_in?: (string | null)[] | null;
  snapshatId_contains?: string | null;
  snapshatId_not_contains?: string | null;
  snapshatId_starts_with?: string | null;
  snapshatId_not_starts_with?: string | null;
  snapshatId_ends_with?: string | null;
  snapshatId_not_ends_with?: string | null;
  snapshatId_lt?: string | null;
  snapshatId_lte?: string | null;
  snapshatId_gt?: string | null;
  snapshatId_gte?: string | null;
  linkedinId?: string | null;
  linkedinId_not?: string | null;
  linkedinId_is_null?: boolean | null;
  linkedinId_in?: (string | null)[] | null;
  linkedinId_not_in?: (string | null)[] | null;
  linkedinId_contains?: string | null;
  linkedinId_not_contains?: string | null;
  linkedinId_starts_with?: string | null;
  linkedinId_not_starts_with?: string | null;
  linkedinId_ends_with?: string | null;
  linkedinId_not_ends_with?: string | null;
  linkedinId_lt?: string | null;
  linkedinId_lte?: string | null;
  linkedinId_gt?: string | null;
  linkedinId_gte?: string | null;
  avatarUrl?: string | null;
  avatarUrl_not?: string | null;
  avatarUrl_is_null?: boolean | null;
  avatarUrl_in?: (string | null)[] | null;
  avatarUrl_not_in?: (string | null)[] | null;
  avatarUrl_contains?: string | null;
  avatarUrl_not_contains?: string | null;
  avatarUrl_starts_with?: string | null;
  avatarUrl_not_starts_with?: string | null;
  avatarUrl_ends_with?: string | null;
  avatarUrl_not_ends_with?: string | null;
  avatarUrl_lt?: string | null;
  avatarUrl_lte?: string | null;
  avatarUrl_gt?: string | null;
  avatarUrl_gte?: string | null;
  description?: string | null;
  description_not?: string | null;
  description_is_null?: boolean | null;
  description_in?: (string | null)[] | null;
  description_not_in?: (string | null)[] | null;
  description_contains?: string | null;
  description_not_contains?: string | null;
  description_starts_with?: string | null;
  description_not_starts_with?: string | null;
  description_ends_with?: string | null;
  description_not_ends_with?: string | null;
  description_lt?: string | null;
  description_lte?: string | null;
  description_gt?: string | null;
  description_gte?: string | null;
  media?: string | null;
  media_not?: string | null;
  media_is_null?: boolean | null;
  media_in?: (string | null)[] | null;
  media_not_in?: (string | null)[] | null;
  media_contains?: string | null;
  media_not_contains?: string | null;
  media_starts_with?: string | null;
  media_not_starts_with?: string | null;
  media_ends_with?: string | null;
  media_not_ends_with?: string | null;
  media_lt?: string | null;
  media_lte?: string | null;
  media_gt?: string | null;
  media_gte?: string | null;
  hasPublicProfile?: boolean | null;
  hasPublicProfile_not?: boolean | null;
  hasPublicProfile_is_null?: boolean | null;
  hasPublicProfile_in?: (boolean | null)[] | null;
  hasPublicProfile_not_in?: (boolean | null)[] | null;
  isMain?: boolean | null;
  isMain_not?: boolean | null;
  isMain_is_null?: boolean | null;
  isMain_in?: (boolean | null)[] | null;
  isMain_not_in?: (boolean | null)[] | null;
  prismaConnectId?: string | null;
  prismaConnectId_not?: string | null;
  prismaConnectId_is_null?: boolean | null;
  prismaConnectId_in?: (string | null)[] | null;
  prismaConnectId_not_in?: (string | null)[] | null;
  prismaConnectId_contains?: string | null;
  prismaConnectId_not_contains?: string | null;
  prismaConnectId_starts_with?: string | null;
  prismaConnectId_not_starts_with?: string | null;
  prismaConnectId_ends_with?: string | null;
  prismaConnectId_not_ends_with?: string | null;
  prismaConnectId_lt?: string | null;
  prismaConnectId_lte?: string | null;
  prismaConnectId_gt?: string | null;
  prismaConnectId_gte?: string | null;
  userId?: string | null;
  userId_not?: string | null;
  userId_is_null?: boolean | null;
  userId_in?: (string | null)[] | null;
  userId_not_in?: (string | null)[] | null;
  resume?: string | null;
  resume_not?: string | null;
  resume_is_null?: boolean | null;
  resume_in?: (string | null)[] | null;
  resume_not_in?: (string | null)[] | null;
  resume_contains?: string | null;
  resume_not_contains?: string | null;
  resume_starts_with?: string | null;
  resume_not_starts_with?: string | null;
  resume_ends_with?: string | null;
  resume_not_ends_with?: string | null;
  resume_lt?: string | null;
  resume_lte?: string | null;
  resume_gt?: string | null;
  resume_gte?: string | null;
  email?: string | null;
  email_not?: string | null;
  email_is_null?: boolean | null;
  email_in?: (string | null)[] | null;
  email_not_in?: (string | null)[] | null;
  email_contains?: string | null;
  email_not_contains?: string | null;
  email_starts_with?: string | null;
  email_not_starts_with?: string | null;
  email_ends_with?: string | null;
  email_not_ends_with?: string | null;
  email_lt?: string | null;
  email_lte?: string | null;
  email_gt?: string | null;
  email_gte?: string | null;
  activeOnBrandKeys?: string | null;
  activeOnBrandKeys_not?: string | null;
  activeOnBrandKeys_is_null?: boolean | null;
  activeOnBrandKeys_in?: (string | null)[] | null;
  activeOnBrandKeys_not_in?: (string | null)[] | null;
  activeOnBrandKeys_contains?: string | null;
  activeOnBrandKeys_not_contains?: string | null;
  activeOnBrandKeys_starts_with?: string | null;
  activeOnBrandKeys_not_starts_with?: string | null;
  activeOnBrandKeys_ends_with?: string | null;
  activeOnBrandKeys_not_ends_with?: string | null;
  activeOnBrandKeys_lt?: string | null;
  activeOnBrandKeys_lte?: string | null;
  activeOnBrandKeys_gt?: string | null;
  activeOnBrandKeys_gte?: string | null;
  existsOnBrandKeys?: string | null;
  existsOnBrandKeys_not?: string | null;
  existsOnBrandKeys_is_null?: boolean | null;
  existsOnBrandKeys_in?: (string | null)[] | null;
  existsOnBrandKeys_not_in?: (string | null)[] | null;
  existsOnBrandKeys_contains?: string | null;
  existsOnBrandKeys_not_contains?: string | null;
  existsOnBrandKeys_starts_with?: string | null;
  existsOnBrandKeys_not_starts_with?: string | null;
  existsOnBrandKeys_ends_with?: string | null;
  existsOnBrandKeys_not_ends_with?: string | null;
  existsOnBrandKeys_lt?: string | null;
  existsOnBrandKeys_lte?: string | null;
  existsOnBrandKeys_gt?: string | null;
  existsOnBrandKeys_gte?: string | null;
  articleAuthors_some?: ArticleAuthorWhereInput | null;
  authorAttributes_some?: AuthorAttributeWhereInput | null;
  authorHistories_some?: AuthorHistoryWhereInput | null;
  AND?: AuthorWhereInput[] | null;
  OR?: AuthorWhereInput[] | null;
  NOT?: AuthorWhereInput | null;
}

/**
 * Identifies exactly one "Author" node with one of these unique combinations of values:
 * - _id
 * - id
 * - brandKey, name
 */
export interface AuthorWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
  brandKey?: BrandKey | null;
  name?: string | null;
}

export interface CardCategoryNestedCardCreateInput {
  connect?: CardWhereUniqueInput | null;
  update?: CardCategoryNestedUpdateCardCreateInput | null;
  create?: CardCreateInput | null;
  upsert?: CardCategoryNestedUpsertCardCreateInput | null;
}

export interface CardCategoryNestedCategoryCreateInput {
  connect?: CategoryWhereUniqueInput | null;
  update?: CardCategoryNestedUpdateCategoryCreateInput | null;
  create?: CategoryCreateInput | null;
  upsert?: CardCategoryNestedUpsertCategoryCreateInput | null;
}

export interface CardCategoryNestedUpdateCardCreateInput {
  where: CardWhereUniqueInput;
  data: CardUpdateInput;
}

export interface CardCategoryNestedUpdateCategoryCreateInput {
  where: CategoryWhereUniqueInput;
  data: CategoryUpdateInput;
}

export interface CardCategoryNestedUpsertCardCreateInput {
  where: CardWhereUniqueInput;
  update: CardUpdateInput;
  create: CardCreateInput;
}

export interface CardCategoryNestedUpsertCategoryCreateInput {
  where: CategoryWhereUniqueInput;
  update: CategoryUpdateInput;
  create: CategoryCreateInput;
}

/**
 * Filters the "CardCategory" nodes by specifying some conditions
 */
export interface CardCategoryWhereInput {
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  card?: CardWhereInput | null;
  category?: CategoryWhereInput | null;
  AND?: CardCategoryWhereInput[] | null;
  OR?: CardCategoryWhereInput[] | null;
  NOT?: CardCategoryWhereInput | null;
}

export interface CardCategoryWithForcedCardCreateInput {
  order: number;
  category: CardCategoryNestedCategoryCreateInput;
}

export interface CardCategoryWithForcedCardUpdateInput {
  order?: number | null;
}

/**
 * Given a forced "card", identifies exactly one "CardCategory" node with one of these unique values:
 * - category
 * - order
 */
export interface CardCategoryWithForcedCardWhereUniqueInput {
  category?: CategoryWhereUniqueInput | null;
  order?: number | null;
}

export interface CardCategoryWithForcedCategoryCreateInput {
  order: number;
  card: CardCategoryNestedCardCreateInput;
}

export interface CardCategoryWithForcedCategoryUpdateInput {
  order?: number | null;
}

/**
 * Given a forced "category", identifies exactly one "CardCategory" node.
 */
export interface CardCategoryWithForcedCategoryWhereUniqueInput {
  card: CardWhereUniqueInput;
}

export interface CardCreateInput {
  id?: string | null;
  createdAt?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  title: string;
  secondaryTitle?: string | null;
  description?: string | null;
  url: string;
  brandKey: BrandKey;
  media?: string | null;
  label: string;
  slug?: string | null;
  status: CardStatus;
  body?: string | null;
  cardCategories?: CardNestedCardCategoriesCreateInput | null;
  cardTags?: CardNestedCardTagsCreateInput | null;
}

export interface CardNestedCardCategoriesCreateInput {
  create?: CardCategoryWithForcedCardCreateInput[] | null;
}

export interface CardNestedCardCategoriesUpdateInput {
  update?: CardNestedUpdateCardCategoriesUpdateInput[] | null;
  create?: CardCategoryWithForcedCardCreateInput[] | null;
  upsert?: CardNestedUpsertCardCategoriesUpdateInput[] | null;
  delete?: CardCategoryWithForcedCardWhereUniqueInput[] | null;
}

export interface CardNestedCardTagsCreateInput {
  create?: CardTagWithForcedCardCreateInput[] | null;
}

export interface CardNestedCardTagsUpdateInput {
  update?: CardNestedUpdateCardTagsUpdateInput[] | null;
  create?: CardTagWithForcedCardCreateInput[] | null;
  upsert?: CardNestedUpsertCardTagsUpdateInput[] | null;
  delete?: CardTagWithForcedCardWhereUniqueInput[] | null;
}

export interface CardNestedUpdateCardCategoriesUpdateInput {
  where: CardCategoryWithForcedCardWhereUniqueInput;
  data: CardCategoryWithForcedCardUpdateInput;
}

export interface CardNestedUpdateCardTagsUpdateInput {
  where: CardTagWithForcedCardWhereUniqueInput;
  data: CardTagWithForcedCardUpdateInput;
}

export interface CardNestedUpsertCardCategoriesUpdateInput {
  where: CardCategoryWithForcedCardWhereUniqueInput;
  update: CardCategoryWithForcedCardUpdateInput;
  create: CardCategoryWithForcedCardCreateInput;
}

export interface CardNestedUpsertCardTagsUpdateInput {
  where: CardTagWithForcedCardWhereUniqueInput;
  update: CardTagWithForcedCardUpdateInput;
  create: CardTagWithForcedCardCreateInput;
}

export interface CardTagNestedCardCreateInput {
  connect?: CardWhereUniqueInput | null;
  update?: CardTagNestedUpdateCardCreateInput | null;
  create?: CardCreateInput | null;
  upsert?: CardTagNestedUpsertCardCreateInput | null;
}

export interface CardTagNestedTagCreateInput {
  connect?: TagWhereUniqueInput | null;
  update?: CardTagNestedUpdateTagCreateInput | null;
  create?: TagCreateInput | null;
  upsert?: CardTagNestedUpsertTagCreateInput | null;
}

export interface CardTagNestedUpdateCardCreateInput {
  where: CardWhereUniqueInput;
  data: CardUpdateInput;
}

export interface CardTagNestedUpdateTagCreateInput {
  where: TagWhereUniqueInput;
  data: TagUpdateInput;
}

export interface CardTagNestedUpsertCardCreateInput {
  where: CardWhereUniqueInput;
  update: CardUpdateInput;
  create: CardCreateInput;
}

export interface CardTagNestedUpsertTagCreateInput {
  where: TagWhereUniqueInput;
  update: TagUpdateInput;
  create: TagCreateInput;
}

/**
 * Filters the "CardTag" nodes by specifying some conditions
 */
export interface CardTagWhereInput {
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  card?: CardWhereInput | null;
  tag?: TagWhereInput | null;
  AND?: CardTagWhereInput[] | null;
  OR?: CardTagWhereInput[] | null;
  NOT?: CardTagWhereInput | null;
}

export interface CardTagWithForcedCardCreateInput {
  order: number;
  tag: CardTagNestedTagCreateInput;
}

export interface CardTagWithForcedCardUpdateInput {
  order?: number | null;
}

/**
 * Given a forced "card", identifies exactly one "CardTag" node with one of these unique values:
 * - tag
 * - order
 */
export interface CardTagWithForcedCardWhereUniqueInput {
  tag?: TagWhereUniqueInput | null;
  order?: number | null;
}

export interface CardTagWithForcedTagCreateInput {
  order: number;
  card: CardTagNestedCardCreateInput;
}

export interface CardTagWithForcedTagUpdateInput {
  order?: number | null;
}

/**
 * Given a forced "tag", identifies exactly one "CardTag" node.
 */
export interface CardTagWithForcedTagWhereUniqueInput {
  card: CardWhereUniqueInput;
}

export interface CardUpdateInput {
  id?: string | null;
  createdAt?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  title?: string | null;
  secondaryTitle?: string | null;
  description?: string | null;
  url?: string | null;
  brandKey?: BrandKey | null;
  media?: string | null;
  label?: string | null;
  slug?: string | null;
  status?: CardStatus | null;
  body?: string | null;
  cardCategories?: CardNestedCardCategoriesUpdateInput | null;
  cardTags?: CardNestedCardTagsUpdateInput | null;
}

/**
 * Filters the "Card" nodes by specifying some conditions
 */
export interface CardWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_is_null?: boolean | null;
  id_in?: (string | null)[] | null;
  id_not_in?: (string | null)[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  title_starts_with?: string | null;
  title_not_starts_with?: string | null;
  title_ends_with?: string | null;
  title_not_ends_with?: string | null;
  title_lt?: string | null;
  title_lte?: string | null;
  title_gt?: string | null;
  title_gte?: string | null;
  secondaryTitle?: string | null;
  secondaryTitle_not?: string | null;
  secondaryTitle_is_null?: boolean | null;
  secondaryTitle_in?: (string | null)[] | null;
  secondaryTitle_not_in?: (string | null)[] | null;
  secondaryTitle_contains?: string | null;
  secondaryTitle_not_contains?: string | null;
  secondaryTitle_starts_with?: string | null;
  secondaryTitle_not_starts_with?: string | null;
  secondaryTitle_ends_with?: string | null;
  secondaryTitle_not_ends_with?: string | null;
  secondaryTitle_lt?: string | null;
  secondaryTitle_lte?: string | null;
  secondaryTitle_gt?: string | null;
  secondaryTitle_gte?: string | null;
  description?: string | null;
  description_not?: string | null;
  description_is_null?: boolean | null;
  description_in?: (string | null)[] | null;
  description_not_in?: (string | null)[] | null;
  description_contains?: string | null;
  description_not_contains?: string | null;
  description_starts_with?: string | null;
  description_not_starts_with?: string | null;
  description_ends_with?: string | null;
  description_not_ends_with?: string | null;
  description_lt?: string | null;
  description_lte?: string | null;
  description_gt?: string | null;
  description_gte?: string | null;
  url?: string | null;
  url_not?: string | null;
  url_in?: string[] | null;
  url_not_in?: string[] | null;
  url_contains?: string | null;
  url_not_contains?: string | null;
  url_starts_with?: string | null;
  url_not_starts_with?: string | null;
  url_ends_with?: string | null;
  url_not_ends_with?: string | null;
  url_lt?: string | null;
  url_lte?: string | null;
  url_gt?: string | null;
  url_gte?: string | null;
  brandKey?: BrandKey | null;
  brandKey_not?: BrandKey | null;
  brandKey_in?: BrandKey[] | null;
  brandKey_not_in?: BrandKey[] | null;
  media?: string | null;
  media_not?: string | null;
  media_is_null?: boolean | null;
  media_in?: (string | null)[] | null;
  media_not_in?: (string | null)[] | null;
  media_contains?: string | null;
  media_not_contains?: string | null;
  media_starts_with?: string | null;
  media_not_starts_with?: string | null;
  media_ends_with?: string | null;
  media_not_ends_with?: string | null;
  media_lt?: string | null;
  media_lte?: string | null;
  media_gt?: string | null;
  media_gte?: string | null;
  label?: string | null;
  label_not?: string | null;
  label_in?: string[] | null;
  label_not_in?: string[] | null;
  label_contains?: string | null;
  label_not_contains?: string | null;
  label_starts_with?: string | null;
  label_not_starts_with?: string | null;
  label_ends_with?: string | null;
  label_not_ends_with?: string | null;
  label_lt?: string | null;
  label_lte?: string | null;
  label_gt?: string | null;
  label_gte?: string | null;
  slug?: string | null;
  slug_not?: string | null;
  slug_is_null?: boolean | null;
  slug_in?: (string | null)[] | null;
  slug_not_in?: (string | null)[] | null;
  slug_contains?: string | null;
  slug_not_contains?: string | null;
  slug_starts_with?: string | null;
  slug_not_starts_with?: string | null;
  slug_ends_with?: string | null;
  slug_not_ends_with?: string | null;
  slug_lt?: string | null;
  slug_lte?: string | null;
  slug_gt?: string | null;
  slug_gte?: string | null;
  status?: CardStatus | null;
  status_not?: CardStatus | null;
  status_in?: CardStatus[] | null;
  status_not_in?: CardStatus[] | null;
  body?: string | null;
  body_not?: string | null;
  body_is_null?: boolean | null;
  body_in?: (string | null)[] | null;
  body_not_in?: (string | null)[] | null;
  body_contains?: string | null;
  body_not_contains?: string | null;
  body_starts_with?: string | null;
  body_not_starts_with?: string | null;
  body_ends_with?: string | null;
  body_not_ends_with?: string | null;
  body_lt?: string | null;
  body_lte?: string | null;
  body_gt?: string | null;
  body_gte?: string | null;
  cardCategories_some?: CardCategoryWhereInput | null;
  cardTags_some?: CardTagWhereInput | null;
  AND?: CardWhereInput[] | null;
  OR?: CardWhereInput[] | null;
  NOT?: CardWhereInput | null;
}

/**
 * Identifies exactly one "Card" node with one of these unique values:
 * - _id
 * - id
 */
export interface CardWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
}

export interface CategoryArticleCategoriesCategorySearchWhereInput {
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  AND?: CategoryArticleCategoriesCategorySearchWhereInput[] | null;
  OR?: CategoryArticleCategoriesCategorySearchWhereInput[] | null;
  NOT?: CategoryArticleCategoriesCategorySearchWhereInput | null;
}

export interface CategoryArticleChannelsCategorySearchWhereInput {
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  AND?: CategoryArticleChannelsCategorySearchWhereInput[] | null;
  OR?: CategoryArticleChannelsCategorySearchWhereInput[] | null;
  NOT?: CategoryArticleChannelsCategorySearchWhereInput | null;
}

export interface CategoryArticleEventsCategorySearchWhereInput {
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  AND?: CategoryArticleEventsCategorySearchWhereInput[] | null;
  OR?: CategoryArticleEventsCategorySearchWhereInput[] | null;
  NOT?: CategoryArticleEventsCategorySearchWhereInput | null;
}

export interface CategoryArticleGuidesCategorySearchWhereInput {
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  AND?: CategoryArticleGuidesCategorySearchWhereInput[] | null;
  OR?: CategoryArticleGuidesCategorySearchWhereInput[] | null;
  NOT?: CategoryArticleGuidesCategorySearchWhereInput | null;
}

export interface CategoryCreateInput {
  id?: string | null;
  brandKey: BrandKey;
  status: CategoryStatus;
  publishedAt?: GraphQL$DateTime | null;
  editedAt?: GraphQL$DateTime | null;
  format: CategoryFormat;
  level?: number | null;
  title: string;
  slug?: string | null;
  isHeadlined?: boolean | null;
  subtitle?: string | null;
  description?: string | null;
  bodyDraft?: string | null;
  medias?: string | null;
  left?: number | null;
  right?: number | null;
  urls?: string | null;
  tags?: string | null;
  metas?: string | null;
  lockerId?: string | null;
  minBoardPinned?: number | null;
  maxBoardPinned?: number | null;
  parent?: CategoryNestedParentCreateInput | null;
  articleCategories?: CategoryNestedArticleCategoriesCreateInput | null;
  articleChannels?: CategoryNestedArticleChannelsCreateInput | null;
  articleEvents?: CategoryNestedArticleEventsCreateInput | null;
  articleGuides?: CategoryNestedArticleGuidesCreateInput | null;
  cardCategories?: CategoryNestedCardCategoriesCreateInput | null;
  children?: CategoryNestedChildrenCreateInput | null;
  categoryHistories?: CategoryNestedCategoryHistoriesCreateInput | null;
  tabs?: CategoryNestedTabsCreateInput | null;
}

/**
 * Filters the "CategoryHistory" nodes by specifying some conditions
 */
export interface CategoryHistoryWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  userId?: string | null;
  userId_not?: string | null;
  userId_in?: string[] | null;
  userId_not_in?: string[] | null;
  status?: CategoryStatus | null;
  status_not?: CategoryStatus | null;
  status_in?: CategoryStatus[] | null;
  status_not_in?: CategoryStatus[] | null;
  category?: CategoryWhereInput | null;
  AND?: CategoryHistoryWhereInput[] | null;
  OR?: CategoryHistoryWhereInput[] | null;
  NOT?: CategoryHistoryWhereInput | null;
}

/**
 * Identifies exactly one "CategoryHistory" node with one of these unique values:
 * - _id
 * - id
 */
export interface CategoryHistoryWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
}

export interface CategoryHistoryWithForcedCategoryCreateInput {
  id?: string | null;
  userId: string;
  status: CategoryStatus;
}

export interface CategoryHistoryWithForcedCategoryUpdateInput {
  userId?: string | null;
  status?: CategoryStatus | null;
}

export interface CategoryNestedArticleCategoriesCreateInput {
  create?: ArticleCategoryWithForcedCategoryCreateInput[] | null;
}

export interface CategoryNestedArticleCategoriesUpdateInput {
  update?: CategoryNestedUpdateArticleCategoriesUpdateInput[] | null;
  create?: ArticleCategoryWithForcedCategoryCreateInput[] | null;
  upsert?: CategoryNestedUpsertArticleCategoriesUpdateInput[] | null;
  delete?: ArticleCategoryWithForcedCategoryWhereUniqueInput[] | null;
}

export interface CategoryNestedArticleChannelsCreateInput {
  create?: ArticleChannelWithForcedCategoryCreateInput[] | null;
}

export interface CategoryNestedArticleChannelsUpdateInput {
  update?: CategoryNestedUpdateArticleChannelsUpdateInput[] | null;
  create?: ArticleChannelWithForcedCategoryCreateInput[] | null;
  upsert?: CategoryNestedUpsertArticleChannelsUpdateInput[] | null;
  delete?: ArticleChannelWithForcedCategoryWhereUniqueInput[] | null;
}

export interface CategoryNestedArticleEventsCreateInput {
  create?: ArticleEventWithForcedCategoryCreateInput[] | null;
}

export interface CategoryNestedArticleEventsUpdateInput {
  update?: CategoryNestedUpdateArticleEventsUpdateInput[] | null;
  create?: ArticleEventWithForcedCategoryCreateInput[] | null;
  upsert?: CategoryNestedUpsertArticleEventsUpdateInput[] | null;
  delete?: ArticleEventWithForcedCategoryWhereUniqueInput[] | null;
}

export interface CategoryNestedArticleGuidesCreateInput {
  create?: ArticleGuideWithForcedCategoryCreateInput[] | null;
}

export interface CategoryNestedArticleGuidesUpdateInput {
  update?: CategoryNestedUpdateArticleGuidesUpdateInput[] | null;
  create?: ArticleGuideWithForcedCategoryCreateInput[] | null;
  upsert?: CategoryNestedUpsertArticleGuidesUpdateInput[] | null;
  delete?: ArticleGuideWithForcedCategoryWhereUniqueInput[] | null;
}

export interface CategoryNestedCardCategoriesCreateInput {
  create?: CardCategoryWithForcedCategoryCreateInput[] | null;
}

export interface CategoryNestedCardCategoriesUpdateInput {
  update?: CategoryNestedUpdateCardCategoriesUpdateInput[] | null;
  create?: CardCategoryWithForcedCategoryCreateInput[] | null;
  upsert?: CategoryNestedUpsertCardCategoriesUpdateInput[] | null;
  delete?: CardCategoryWithForcedCategoryWhereUniqueInput[] | null;
}

export interface CategoryNestedCategoryHistoriesCreateInput {
  connect?: CategoryHistoryWhereUniqueInput[] | null;
  create?: CategoryHistoryWithForcedCategoryCreateInput[] | null;
}

export interface CategoryNestedCategoryHistoriesUpdateInput {
  connect?: CategoryHistoryWhereUniqueInput[] | null;
  update?: CategoryNestedUpdateCategoryHistoriesUpdateInput[] | null;
  create?: CategoryHistoryWithForcedCategoryCreateInput[] | null;
  upsert?: CategoryNestedUpsertCategoryHistoriesUpdateInput[] | null;
  delete?: CategoryHistoryWhereUniqueInput[] | null;
}

export interface CategoryNestedChildrenCreateInput {
  connect?: CategoryWhereUniqueInput[] | null;
  create?: CategoryWithForcedParentCreateInput[] | null;
}

export interface CategoryNestedChildrenUpdateInput {
  connect?: CategoryWhereUniqueInput[] | null;
  disconnect?: CategoryWithForcedParentWhereUniqueInput[] | null;
  update?: CategoryNestedUpdateChildrenUpdateInput[] | null;
  create?: CategoryWithForcedParentCreateInput[] | null;
  upsert?: CategoryNestedUpsertChildrenUpdateInput[] | null;
  delete?: CategoryWithForcedParentWhereUniqueInput[] | null;
}

export interface CategoryNestedParentCreateInput {
  connect?: CategoryWhereUniqueInput | null;
  update?: CategoryNestedUpdateParentCreateInput | null;
  create?: CategoryCreateInput | null;
  upsert?: CategoryNestedUpsertParentCreateInput | null;
}

export interface CategoryNestedParentUpdateInput {
  connect?: CategoryWhereUniqueInput | null;
  update?: CategoryNestedUpdateParentUpdateInput | null;
  create?: CategoryCreateInput | null;
  upsert?: CategoryNestedUpsertParentUpdateInput | null;
  disconnect?: boolean | null;
}

export interface CategoryNestedTabsCreateInput {
  connect?: TabWhereUniqueInput[] | null;
  create?: TabWithForcedCategoryCreateInput[] | null;
}

export interface CategoryNestedTabsUpdateInput {
  connect?: TabWhereUniqueInput[] | null;
  update?: CategoryNestedUpdateTabsUpdateInput[] | null;
  create?: TabWithForcedCategoryCreateInput[] | null;
  upsert?: CategoryNestedUpsertTabsUpdateInput[] | null;
  delete?: TabWhereUniqueInput[] | null;
}

export interface CategoryNestedUpdateArticleCategoriesUpdateInput {
  where: ArticleCategoryWithForcedCategoryWhereUniqueInput;
  data: ArticleCategoryWithForcedCategoryUpdateInput;
}

export interface CategoryNestedUpdateArticleChannelsUpdateInput {
  where: ArticleChannelWithForcedCategoryWhereUniqueInput;
  data: ArticleChannelWithForcedCategoryUpdateInput;
}

export interface CategoryNestedUpdateArticleEventsUpdateInput {
  where: ArticleEventWithForcedCategoryWhereUniqueInput;
  data: ArticleEventWithForcedCategoryUpdateInput;
}

export interface CategoryNestedUpdateArticleGuidesUpdateInput {
  where: ArticleGuideWithForcedCategoryWhereUniqueInput;
  data: ArticleGuideWithForcedCategoryUpdateInput;
}

export interface CategoryNestedUpdateCardCategoriesUpdateInput {
  where: CardCategoryWithForcedCategoryWhereUniqueInput;
  data: CardCategoryWithForcedCategoryUpdateInput;
}

export interface CategoryNestedUpdateCategoryHistoriesUpdateInput {
  where: CategoryHistoryWhereUniqueInput;
  data: CategoryHistoryWithForcedCategoryUpdateInput;
}

export interface CategoryNestedUpdateChildrenUpdateInput {
  where: CategoryWithOptionalParentWhereUniqueInput;
  data: CategoryWithForcedParentUpdateInput;
}

export interface CategoryNestedUpdateParentCreateInput {
  where: CategoryWhereUniqueInput;
  data: CategoryUpdateInput;
}

export interface CategoryNestedUpdateParentUpdateInput {
  where: CategoryWhereUniqueInput;
  data: CategoryUpdateInput;
}

export interface CategoryNestedUpdateTabsUpdateInput {
  where: TabWhereUniqueInput;
  data: TabWithForcedCategoryUpdateInput;
}

export interface CategoryNestedUpsertArticleCategoriesUpdateInput {
  where: ArticleCategoryWithForcedCategoryWhereUniqueInput;
  update: ArticleCategoryWithForcedCategoryUpdateInput;
  create: ArticleCategoryWithForcedCategoryCreateInput;
}

export interface CategoryNestedUpsertArticleChannelsUpdateInput {
  where: ArticleChannelWithForcedCategoryWhereUniqueInput;
  update: ArticleChannelWithForcedCategoryUpdateInput;
  create: ArticleChannelWithForcedCategoryCreateInput;
}

export interface CategoryNestedUpsertArticleEventsUpdateInput {
  where: ArticleEventWithForcedCategoryWhereUniqueInput;
  update: ArticleEventWithForcedCategoryUpdateInput;
  create: ArticleEventWithForcedCategoryCreateInput;
}

export interface CategoryNestedUpsertArticleGuidesUpdateInput {
  where: ArticleGuideWithForcedCategoryWhereUniqueInput;
  update: ArticleGuideWithForcedCategoryUpdateInput;
  create: ArticleGuideWithForcedCategoryCreateInput;
}

export interface CategoryNestedUpsertCardCategoriesUpdateInput {
  where: CardCategoryWithForcedCategoryWhereUniqueInput;
  update: CardCategoryWithForcedCategoryUpdateInput;
  create: CardCategoryWithForcedCategoryCreateInput;
}

export interface CategoryNestedUpsertCategoryHistoriesUpdateInput {
  where: CategoryHistoryWhereUniqueInput;
  update: CategoryHistoryWithForcedCategoryUpdateInput;
  create: CategoryHistoryWithForcedCategoryCreateInput;
}

export interface CategoryNestedUpsertChildrenUpdateInput {
  where: CategoryWithOptionalParentWhereUniqueInput;
  update: CategoryWithForcedParentUpdateInput;
  create: CategoryWithForcedParentCreateInput;
}

export interface CategoryNestedUpsertParentCreateInput {
  where: CategoryWhereUniqueInput;
  update: CategoryUpdateInput;
  create: CategoryCreateInput;
}

export interface CategoryNestedUpsertParentUpdateInput {
  where: CategoryWhereUniqueInput;
  update: CategoryUpdateInput;
  create: CategoryCreateInput;
}

export interface CategoryNestedUpsertTabsUpdateInput {
  where: TabWhereUniqueInput;
  update: TabWithForcedCategoryUpdateInput;
  create: TabWithForcedCategoryCreateInput;
}

export interface CategoryUpdateInput {
  brandKey?: BrandKey | null;
  status?: CategoryStatus | null;
  publishedAt?: GraphQL$DateTime | null;
  editedAt?: GraphQL$DateTime | null;
  format?: CategoryFormat | null;
  level?: number | null;
  title?: string | null;
  slug?: string | null;
  isHeadlined?: boolean | null;
  subtitle?: string | null;
  description?: string | null;
  bodyDraft?: string | null;
  medias?: string | null;
  left?: number | null;
  right?: number | null;
  urls?: string | null;
  tags?: string | null;
  metas?: string | null;
  lockerId?: string | null;
  minBoardPinned?: number | null;
  maxBoardPinned?: number | null;
  parent?: CategoryNestedParentUpdateInput | null;
  articleCategories?: CategoryNestedArticleCategoriesUpdateInput | null;
  articleChannels?: CategoryNestedArticleChannelsUpdateInput | null;
  articleEvents?: CategoryNestedArticleEventsUpdateInput | null;
  articleGuides?: CategoryNestedArticleGuidesUpdateInput | null;
  cardCategories?: CategoryNestedCardCategoriesUpdateInput | null;
  children?: CategoryNestedChildrenUpdateInput | null;
  categoryHistories?: CategoryNestedCategoryHistoriesUpdateInput | null;
  tabs?: CategoryNestedTabsUpdateInput | null;
}

/**
 * Filters the "Category" nodes by specifying some conditions
 */
export interface CategoryWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  brandKey?: BrandKey | null;
  brandKey_not?: BrandKey | null;
  brandKey_in?: BrandKey[] | null;
  brandKey_not_in?: BrandKey[] | null;
  status?: CategoryStatus | null;
  status_not?: CategoryStatus | null;
  status_in?: CategoryStatus[] | null;
  status_not_in?: CategoryStatus[] | null;
  publishedAt?: GraphQL$DateTime | null;
  publishedAt_not?: GraphQL$DateTime | null;
  publishedAt_is_null?: boolean | null;
  publishedAt_in?: (GraphQL$DateTime | null)[] | null;
  publishedAt_not_in?: (GraphQL$DateTime | null)[] | null;
  publishedAt_lt?: GraphQL$DateTime | null;
  publishedAt_lte?: GraphQL$DateTime | null;
  publishedAt_gt?: GraphQL$DateTime | null;
  publishedAt_gte?: GraphQL$DateTime | null;
  editedAt?: GraphQL$DateTime | null;
  editedAt_not?: GraphQL$DateTime | null;
  editedAt_is_null?: boolean | null;
  editedAt_in?: (GraphQL$DateTime | null)[] | null;
  editedAt_not_in?: (GraphQL$DateTime | null)[] | null;
  editedAt_lt?: GraphQL$DateTime | null;
  editedAt_lte?: GraphQL$DateTime | null;
  editedAt_gt?: GraphQL$DateTime | null;
  editedAt_gte?: GraphQL$DateTime | null;
  format?: CategoryFormat | null;
  format_not?: CategoryFormat | null;
  format_in?: CategoryFormat[] | null;
  format_not_in?: CategoryFormat[] | null;
  level?: number | null;
  level_not?: number | null;
  level_in?: number[] | null;
  level_not_in?: number[] | null;
  level_lt?: number | null;
  level_lte?: number | null;
  level_gt?: number | null;
  level_gte?: number | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  title_starts_with?: string | null;
  title_not_starts_with?: string | null;
  title_ends_with?: string | null;
  title_not_ends_with?: string | null;
  title_lt?: string | null;
  title_lte?: string | null;
  title_gt?: string | null;
  title_gte?: string | null;
  slug?: string | null;
  slug_not?: string | null;
  slug_in?: string[] | null;
  slug_not_in?: string[] | null;
  slug_contains?: string | null;
  slug_not_contains?: string | null;
  slug_starts_with?: string | null;
  slug_not_starts_with?: string | null;
  slug_ends_with?: string | null;
  slug_not_ends_with?: string | null;
  slug_lt?: string | null;
  slug_lte?: string | null;
  slug_gt?: string | null;
  slug_gte?: string | null;
  isHeadlined?: boolean | null;
  isHeadlined_not?: boolean | null;
  isHeadlined_in?: boolean[] | null;
  isHeadlined_not_in?: boolean[] | null;
  subtitle?: string | null;
  subtitle_not?: string | null;
  subtitle_is_null?: boolean | null;
  subtitle_in?: (string | null)[] | null;
  subtitle_not_in?: (string | null)[] | null;
  subtitle_contains?: string | null;
  subtitle_not_contains?: string | null;
  subtitle_starts_with?: string | null;
  subtitle_not_starts_with?: string | null;
  subtitle_ends_with?: string | null;
  subtitle_not_ends_with?: string | null;
  subtitle_lt?: string | null;
  subtitle_lte?: string | null;
  subtitle_gt?: string | null;
  subtitle_gte?: string | null;
  description?: string | null;
  description_not?: string | null;
  description_is_null?: boolean | null;
  description_in?: (string | null)[] | null;
  description_not_in?: (string | null)[] | null;
  description_contains?: string | null;
  description_not_contains?: string | null;
  description_starts_with?: string | null;
  description_not_starts_with?: string | null;
  description_ends_with?: string | null;
  description_not_ends_with?: string | null;
  description_lt?: string | null;
  description_lte?: string | null;
  description_gt?: string | null;
  description_gte?: string | null;
  bodyDraft?: string | null;
  bodyDraft_not?: string | null;
  bodyDraft_is_null?: boolean | null;
  bodyDraft_in?: (string | null)[] | null;
  bodyDraft_not_in?: (string | null)[] | null;
  bodyDraft_contains?: string | null;
  bodyDraft_not_contains?: string | null;
  bodyDraft_starts_with?: string | null;
  bodyDraft_not_starts_with?: string | null;
  bodyDraft_ends_with?: string | null;
  bodyDraft_not_ends_with?: string | null;
  bodyDraft_lt?: string | null;
  bodyDraft_lte?: string | null;
  bodyDraft_gt?: string | null;
  bodyDraft_gte?: string | null;
  medias?: string | null;
  medias_not?: string | null;
  medias_is_null?: boolean | null;
  medias_in?: (string | null)[] | null;
  medias_not_in?: (string | null)[] | null;
  medias_contains?: string | null;
  medias_not_contains?: string | null;
  medias_starts_with?: string | null;
  medias_not_starts_with?: string | null;
  medias_ends_with?: string | null;
  medias_not_ends_with?: string | null;
  medias_lt?: string | null;
  medias_lte?: string | null;
  medias_gt?: string | null;
  medias_gte?: string | null;
  left?: number | null;
  left_not?: number | null;
  left_is_null?: boolean | null;
  left_in?: (number | null)[] | null;
  left_not_in?: (number | null)[] | null;
  left_lt?: number | null;
  left_lte?: number | null;
  left_gt?: number | null;
  left_gte?: number | null;
  right?: number | null;
  right_not?: number | null;
  right_is_null?: boolean | null;
  right_in?: (number | null)[] | null;
  right_not_in?: (number | null)[] | null;
  right_lt?: number | null;
  right_lte?: number | null;
  right_gt?: number | null;
  right_gte?: number | null;
  urls?: string | null;
  urls_not?: string | null;
  urls_is_null?: boolean | null;
  urls_in?: (string | null)[] | null;
  urls_not_in?: (string | null)[] | null;
  urls_contains?: string | null;
  urls_not_contains?: string | null;
  urls_starts_with?: string | null;
  urls_not_starts_with?: string | null;
  urls_ends_with?: string | null;
  urls_not_ends_with?: string | null;
  urls_lt?: string | null;
  urls_lte?: string | null;
  urls_gt?: string | null;
  urls_gte?: string | null;
  tags?: string | null;
  tags_not?: string | null;
  tags_is_null?: boolean | null;
  tags_in?: (string | null)[] | null;
  tags_not_in?: (string | null)[] | null;
  tags_contains?: string | null;
  tags_not_contains?: string | null;
  tags_starts_with?: string | null;
  tags_not_starts_with?: string | null;
  tags_ends_with?: string | null;
  tags_not_ends_with?: string | null;
  tags_lt?: string | null;
  tags_lte?: string | null;
  tags_gt?: string | null;
  tags_gte?: string | null;
  metas?: string | null;
  metas_not?: string | null;
  metas_is_null?: boolean | null;
  metas_in?: (string | null)[] | null;
  metas_not_in?: (string | null)[] | null;
  metas_contains?: string | null;
  metas_not_contains?: string | null;
  metas_starts_with?: string | null;
  metas_not_starts_with?: string | null;
  metas_ends_with?: string | null;
  metas_not_ends_with?: string | null;
  metas_lt?: string | null;
  metas_lte?: string | null;
  metas_gt?: string | null;
  metas_gte?: string | null;
  lockerId?: string | null;
  lockerId_not?: string | null;
  lockerId_is_null?: boolean | null;
  lockerId_in?: (string | null)[] | null;
  lockerId_not_in?: (string | null)[] | null;
  lockedAt?: GraphQL$DateTime | null;
  lockedAt_not?: GraphQL$DateTime | null;
  lockedAt_is_null?: boolean | null;
  lockedAt_in?: (GraphQL$DateTime | null)[] | null;
  lockedAt_not_in?: (GraphQL$DateTime | null)[] | null;
  lockedAt_lt?: GraphQL$DateTime | null;
  lockedAt_lte?: GraphQL$DateTime | null;
  lockedAt_gt?: GraphQL$DateTime | null;
  lockedAt_gte?: GraphQL$DateTime | null;
  minBoardPinned?: number | null;
  minBoardPinned_not?: number | null;
  minBoardPinned_is_null?: boolean | null;
  minBoardPinned_in?: (number | null)[] | null;
  minBoardPinned_not_in?: (number | null)[] | null;
  minBoardPinned_lt?: number | null;
  minBoardPinned_lte?: number | null;
  minBoardPinned_gt?: number | null;
  minBoardPinned_gte?: number | null;
  maxBoardPinned?: number | null;
  maxBoardPinned_not?: number | null;
  maxBoardPinned_is_null?: boolean | null;
  maxBoardPinned_in?: (number | null)[] | null;
  maxBoardPinned_not_in?: (number | null)[] | null;
  maxBoardPinned_lt?: number | null;
  maxBoardPinned_lte?: number | null;
  maxBoardPinned_gt?: number | null;
  maxBoardPinned_gte?: number | null;
  parent?: CategoryWhereInput | null;
  parent_is_null?: boolean | null;
  articleCategories_some?: ArticleCategoryWhereInput | null;
  articleChannels_some?: ArticleChannelWhereInput | null;
  articleEvents_some?: ArticleEventWhereInput | null;
  articleGuides_some?: ArticleGuideWhereInput | null;
  cardCategories_some?: CardCategoryWhereInput | null;
  children_some?: CategoryWhereInput | null;
  categoryHistories_some?: CategoryHistoryWhereInput | null;
  tabs_some?: TabWhereInput | null;
  AND?: CategoryWhereInput[] | null;
  OR?: CategoryWhereInput[] | null;
  NOT?: CategoryWhereInput | null;
}

/**
 * Identifies exactly one "Category" node with one of these unique combinations of values:
 * - _id
 * - id
 * - parent, slug
 * - brandKey, format, title
 */
export interface CategoryWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
  parent?: CategoryWhereUniqueInput | null;
  slug?: string | null;
  brandKey?: BrandKey | null;
  format?: CategoryFormat | null;
  title?: string | null;
}

export interface CategoryWithForcedParentCreateInput {
  id?: string | null;
  brandKey: BrandKey;
  status: CategoryStatus;
  publishedAt?: GraphQL$DateTime | null;
  editedAt?: GraphQL$DateTime | null;
  format: CategoryFormat;
  level?: number | null;
  title: string;
  slug?: string | null;
  isHeadlined?: boolean | null;
  subtitle?: string | null;
  description?: string | null;
  bodyDraft?: string | null;
  medias?: string | null;
  left?: number | null;
  right?: number | null;
  urls?: string | null;
  tags?: string | null;
  metas?: string | null;
  lockerId?: string | null;
  minBoardPinned?: number | null;
  maxBoardPinned?: number | null;
  articleCategories?: CategoryNestedArticleCategoriesCreateInput | null;
  articleChannels?: CategoryNestedArticleChannelsCreateInput | null;
  articleEvents?: CategoryNestedArticleEventsCreateInput | null;
  articleGuides?: CategoryNestedArticleGuidesCreateInput | null;
  cardCategories?: CategoryNestedCardCategoriesCreateInput | null;
  children?: CategoryNestedChildrenCreateInput | null;
  categoryHistories?: CategoryNestedCategoryHistoriesCreateInput | null;
  tabs?: CategoryNestedTabsCreateInput | null;
}

export interface CategoryWithForcedParentUpdateInput {
  brandKey?: BrandKey | null;
  status?: CategoryStatus | null;
  publishedAt?: GraphQL$DateTime | null;
  editedAt?: GraphQL$DateTime | null;
  format?: CategoryFormat | null;
  level?: number | null;
  title?: string | null;
  slug?: string | null;
  isHeadlined?: boolean | null;
  subtitle?: string | null;
  description?: string | null;
  bodyDraft?: string | null;
  medias?: string | null;
  left?: number | null;
  right?: number | null;
  urls?: string | null;
  tags?: string | null;
  metas?: string | null;
  lockerId?: string | null;
  minBoardPinned?: number | null;
  maxBoardPinned?: number | null;
  articleCategories?: CategoryNestedArticleCategoriesUpdateInput | null;
  articleChannels?: CategoryNestedArticleChannelsUpdateInput | null;
  articleEvents?: CategoryNestedArticleEventsUpdateInput | null;
  articleGuides?: CategoryNestedArticleGuidesUpdateInput | null;
  cardCategories?: CategoryNestedCardCategoriesUpdateInput | null;
  children?: CategoryNestedChildrenUpdateInput | null;
  categoryHistories?: CategoryNestedCategoryHistoriesUpdateInput | null;
  tabs?: CategoryNestedTabsUpdateInput | null;
}

/**
 * Given a forced "parent", identifies exactly one "Category" node.
 */
export interface CategoryWithForcedParentWhereUniqueInput {
  slug: string;
}

/**
 * Given a known "parent", identifies exactly one "Category" node with one of these unique combinations of values:
 * - _id
 * - id
 * - parent (optional), slug
 * - brandKey, format, title
 */
export interface CategoryWithOptionalParentWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
  parent?: CategoryWhereUniqueInput | null;
  slug?: string | null;
  brandKey?: BrandKey | null;
  format?: CategoryFormat | null;
  title?: string | null;
}

/**
 * Filters the "ImageSourceCategory" nodes by specifying some conditions
 */
export interface ImageSourceCategoryWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  title_starts_with?: string | null;
  title_not_starts_with?: string | null;
  title_ends_with?: string | null;
  title_not_ends_with?: string | null;
  title_lt?: string | null;
  title_lte?: string | null;
  title_gt?: string | null;
  title_gte?: string | null;
  imageSources_some?: ImageSourceWhereInput | null;
  AND?: ImageSourceCategoryWhereInput[] | null;
  OR?: ImageSourceCategoryWhereInput[] | null;
  NOT?: ImageSourceCategoryWhereInput | null;
}

/**
 * Filters the "ImageSource" nodes by specifying some conditions
 */
export interface ImageSourceWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  title_starts_with?: string | null;
  title_not_starts_with?: string | null;
  title_ends_with?: string | null;
  title_not_ends_with?: string | null;
  title_lt?: string | null;
  title_lte?: string | null;
  title_gt?: string | null;
  title_gte?: string | null;
  imageSourceCategory?: ImageSourceCategoryWhereInput | null;
  AND?: ImageSourceWhereInput[] | null;
  OR?: ImageSourceWhereInput[] | null;
  NOT?: ImageSourceWhereInput | null;
}

export interface LiveCreateInput {
  id?: string | null;
  endedAt?: GraphQL$DateTime | null;
  article: LiveNestedArticleCreateInput;
  posts?: LiveNestedPostsCreateInput | null;
}

export interface LiveLiveSearchWhereInput {
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  posts_some?: LivePostLivePostsSearchWhereInput | null;
  AND?: LiveLiveSearchWhereInput[] | null;
  OR?: LiveLiveSearchWhereInput[] | null;
  NOT?: LiveLiveSearchWhereInput | null;
}

export interface LiveNestedArticleCreateInput {
  connect?: ArticleWhereUniqueInput | null;
  update?: LiveNestedUpdateArticleCreateInput | null;
  create?: ArticleCreateInput | null;
  upsert?: LiveNestedUpsertArticleCreateInput | null;
}

export interface LiveNestedArticleUpdateInput {
  connect?: ArticleWhereUniqueInput | null;
  update?: LiveNestedUpdateArticleUpdateInput | null;
  create?: ArticleCreateInput | null;
  upsert?: LiveNestedUpsertArticleUpdateInput | null;
}

export interface LiveNestedPostsCreateInput {
  connect?: LivePostWhereUniqueInput[] | null;
  create?: LivePostWithForcedLiveCreateInput[] | null;
}

export interface LiveNestedPostsUpdateInput {
  connect?: LivePostWhereUniqueInput[] | null;
  update?: LiveNestedUpdatePostsUpdateInput[] | null;
  create?: LivePostWithForcedLiveCreateInput[] | null;
  upsert?: LiveNestedUpsertPostsUpdateInput[] | null;
  delete?: LivePostWhereUniqueInput[] | null;
}

export interface LiveNestedUpdateArticleCreateInput {
  where: ArticleWhereUniqueInput;
  data: ArticleUpdateInput;
}

export interface LiveNestedUpdateArticleUpdateInput {
  where: ArticleWhereUniqueInput;
  data: ArticleUpdateInput;
}

export interface LiveNestedUpdatePostsUpdateInput {
  where: LivePostWhereUniqueInput;
  data: LivePostWithForcedLiveUpdateInput;
}

export interface LiveNestedUpsertArticleCreateInput {
  where: ArticleWhereUniqueInput;
  update: ArticleUpdateInput;
  create: ArticleCreateInput;
}

export interface LiveNestedUpsertArticleUpdateInput {
  where: ArticleWhereUniqueInput;
  update: ArticleUpdateInput;
  create: ArticleCreateInput;
}

export interface LiveNestedUpsertPostsUpdateInput {
  where: LivePostWhereUniqueInput;
  update: LivePostWithForcedLiveUpdateInput;
  create: LivePostWithForcedLiveCreateInput;
}

export interface LivePostCreateInput {
  id?: string | null;
  publishedAt?: GraphQL$DateTime | null;
  title?: string | null;
  body?: string | null;
  live: LivePostNestedLiveCreateInput;
}

export interface LivePostLivePostsSearchWhereInput {
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  title_is_null?: boolean | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  publishedAt?: GraphQL$DateTime | null;
  publishedAt_not?: GraphQL$DateTime | null;
  publishedAt_in?: GraphQL$DateTime[] | null;
  publishedAt_not_in?: GraphQL$DateTime[] | null;
  publishedAt_lt?: GraphQL$DateTime | null;
  publishedAt_lte?: GraphQL$DateTime | null;
  publishedAt_gt?: GraphQL$DateTime | null;
  publishedAt_gte?: GraphQL$DateTime | null;
  AND?: LivePostLivePostsSearchWhereInput[] | null;
  OR?: LivePostLivePostsSearchWhereInput[] | null;
  NOT?: LivePostLivePostsSearchWhereInput | null;
}

export interface LivePostNestedLiveCreateInput {
  connect?: LiveWhereUniqueInput | null;
  update?: LivePostNestedUpdateLiveCreateInput | null;
  create?: LiveCreateInput | null;
  upsert?: LivePostNestedUpsertLiveCreateInput | null;
}

export interface LivePostNestedLiveUpdateInput {
  connect?: LiveWhereUniqueInput | null;
  update?: LivePostNestedUpdateLiveUpdateInput | null;
  create?: LiveCreateInput | null;
  upsert?: LivePostNestedUpsertLiveUpdateInput | null;
}

export interface LivePostNestedUpdateLiveCreateInput {
  where: LiveWhereUniqueInput;
  data: LiveUpdateInput;
}

export interface LivePostNestedUpdateLiveUpdateInput {
  where: LiveWhereUniqueInput;
  data: LiveUpdateInput;
}

export interface LivePostNestedUpsertLiveCreateInput {
  where: LiveWhereUniqueInput;
  update: LiveUpdateInput;
  create: LiveCreateInput;
}

export interface LivePostNestedUpsertLiveUpdateInput {
  where: LiveWhereUniqueInput;
  update: LiveUpdateInput;
  create: LiveCreateInput;
}

export interface LivePostUpdateInput {
  publishedAt?: GraphQL$DateTime | null;
  title?: string | null;
  body?: string | null;
  live?: LivePostNestedLiveUpdateInput | null;
}

/**
 * Filters the "LivePost" nodes by specifying some conditions
 */
export interface LivePostWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  publishedAt?: GraphQL$DateTime | null;
  publishedAt_not?: GraphQL$DateTime | null;
  publishedAt_in?: GraphQL$DateTime[] | null;
  publishedAt_not_in?: GraphQL$DateTime[] | null;
  publishedAt_lt?: GraphQL$DateTime | null;
  publishedAt_lte?: GraphQL$DateTime | null;
  publishedAt_gt?: GraphQL$DateTime | null;
  publishedAt_gte?: GraphQL$DateTime | null;
  title?: string | null;
  title_not?: string | null;
  title_is_null?: boolean | null;
  title_in?: (string | null)[] | null;
  title_not_in?: (string | null)[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  title_starts_with?: string | null;
  title_not_starts_with?: string | null;
  title_ends_with?: string | null;
  title_not_ends_with?: string | null;
  title_lt?: string | null;
  title_lte?: string | null;
  title_gt?: string | null;
  title_gte?: string | null;
  body?: string | null;
  body_not?: string | null;
  body_is_null?: boolean | null;
  body_in?: (string | null)[] | null;
  body_not_in?: (string | null)[] | null;
  body_contains?: string | null;
  body_not_contains?: string | null;
  body_starts_with?: string | null;
  body_not_starts_with?: string | null;
  body_ends_with?: string | null;
  body_not_ends_with?: string | null;
  body_lt?: string | null;
  body_lte?: string | null;
  body_gt?: string | null;
  body_gte?: string | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  live?: LiveWhereInput | null;
  AND?: LivePostWhereInput[] | null;
  OR?: LivePostWhereInput[] | null;
  NOT?: LivePostWhereInput | null;
}

/**
 * Identifies exactly one "LivePost" node with one of these unique values:
 * - _id
 * - id
 */
export interface LivePostWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
}

export interface LivePostWithForcedLiveCreateInput {
  id?: string | null;
  publishedAt?: GraphQL$DateTime | null;
  title?: string | null;
  body?: string | null;
}

export interface LivePostWithForcedLiveUpdateInput {
  publishedAt?: GraphQL$DateTime | null;
  title?: string | null;
  body?: string | null;
}

export interface LiveUpdateInput {
  endedAt?: GraphQL$DateTime | null;
  article?: LiveNestedArticleUpdateInput | null;
  posts?: LiveNestedPostsUpdateInput | null;
}

/**
 * Filters the "Live" nodes by specifying some conditions
 */
export interface LiveWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  endedAt?: GraphQL$DateTime | null;
  endedAt_not?: GraphQL$DateTime | null;
  endedAt_is_null?: boolean | null;
  endedAt_in?: (GraphQL$DateTime | null)[] | null;
  endedAt_not_in?: (GraphQL$DateTime | null)[] | null;
  endedAt_lt?: GraphQL$DateTime | null;
  endedAt_lte?: GraphQL$DateTime | null;
  endedAt_gt?: GraphQL$DateTime | null;
  endedAt_gte?: GraphQL$DateTime | null;
  article?: ArticleWhereInput | null;
  posts_some?: LivePostWhereInput | null;
  AND?: LiveWhereInput[] | null;
  OR?: LiveWhereInput[] | null;
  NOT?: LiveWhereInput | null;
}

/**
 * Identifies exactly one "Live" node with one of these unique values:
 * - _id
 * - id
 * - article
 */
export interface LiveWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
  article?: ArticleWhereUniqueInput | null;
}

export interface LiveWithForcedArticleCreateInput {
  id?: string | null;
  endedAt?: GraphQL$DateTime | null;
  posts?: LiveNestedPostsCreateInput | null;
}

export interface LiveWithForcedArticleUpdateInput {
  endedAt?: GraphQL$DateTime | null;
  posts?: LiveNestedPostsUpdateInput | null;
}

/**
 * Given a known "article", identifies exactly one "Live" node with one of these unique values:
 * - _id
 * - id
 * - article (optional)
 */
export interface LiveWithOptionalArticleWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
  article?: ArticleWhereUniqueInput | null;
}

export interface MachineTagArticleMachineTagsMachineTagSearchWhereInput {
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  tag_contains?: string | null;
  tag_not_contains?: string | null;
  AND?: MachineTagArticleMachineTagsMachineTagSearchWhereInput[] | null;
  OR?: MachineTagArticleMachineTagsMachineTagSearchWhereInput[] | null;
  NOT?: MachineTagArticleMachineTagsMachineTagSearchWhereInput | null;
}

export interface MachineTagCreateInput {
  id?: string | null;
  tag: string;
  articleMachineTags?: MachineTagNestedArticleMachineTagsCreateInput | null;
}

export interface MachineTagNestedArticleMachineTagsCreateInput {
  create?: ArticleMachineTagWithForcedMachineTagCreateInput[] | null;
}

export interface MachineTagNestedArticleMachineTagsUpdateInput {
  update?: MachineTagNestedUpdateArticleMachineTagsUpdateInput[] | null;
  create?: ArticleMachineTagWithForcedMachineTagCreateInput[] | null;
  upsert?: MachineTagNestedUpsertArticleMachineTagsUpdateInput[] | null;
  delete?: ArticleMachineTagWithForcedMachineTagWhereUniqueInput[] | null;
}

export interface MachineTagNestedUpdateArticleMachineTagsUpdateInput {
  where: ArticleMachineTagWithForcedMachineTagWhereUniqueInput;
  data: ArticleMachineTagWithForcedMachineTagUpdateInput;
}

export interface MachineTagNestedUpsertArticleMachineTagsUpdateInput {
  where: ArticleMachineTagWithForcedMachineTagWhereUniqueInput;
  update: ArticleMachineTagWithForcedMachineTagUpdateInput;
  create: ArticleMachineTagWithForcedMachineTagCreateInput;
}

export interface MachineTagUpdateInput {
  tag?: string | null;
  articleMachineTags?: MachineTagNestedArticleMachineTagsUpdateInput | null;
}

/**
 * Filters the "MachineTag" nodes by specifying some conditions
 */
export interface MachineTagWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  tag?: string | null;
  tag_not?: string | null;
  tag_in?: string[] | null;
  tag_not_in?: string[] | null;
  tag_contains?: string | null;
  tag_not_contains?: string | null;
  tag_starts_with?: string | null;
  tag_not_starts_with?: string | null;
  tag_ends_with?: string | null;
  tag_not_ends_with?: string | null;
  tag_lt?: string | null;
  tag_lte?: string | null;
  tag_gt?: string | null;
  tag_gte?: string | null;
  articleMachineTags_some?: ArticleMachineTagWhereInput | null;
  AND?: MachineTagWhereInput[] | null;
  OR?: MachineTagWhereInput[] | null;
  NOT?: MachineTagWhereInput | null;
}

/**
 * Identifies exactly one "MachineTag" node with one of these unique values:
 * - _id
 * - id
 * - tag
 */
export interface MachineTagWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
  tag?: string | null;
}

/**
 * Filters the "OpenAiProcess" nodes by specifying some conditions
 */
export interface OpenAiProcessWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_is_null?: boolean | null;
  id_in?: (string | null)[] | null;
  id_not_in?: (string | null)[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  status?: OpenAIProcessStatus | null;
  status_not?: OpenAIProcessStatus | null;
  status_in?: OpenAIProcessStatus[] | null;
  status_not_in?: OpenAIProcessStatus[] | null;
  errorMsg?: string | null;
  errorMsg_not?: string | null;
  errorMsg_is_null?: boolean | null;
  errorMsg_in?: (string | null)[] | null;
  errorMsg_not_in?: (string | null)[] | null;
  errorMsg_contains?: string | null;
  errorMsg_not_contains?: string | null;
  errorMsg_starts_with?: string | null;
  errorMsg_not_starts_with?: string | null;
  errorMsg_ends_with?: string | null;
  errorMsg_not_ends_with?: string | null;
  errorMsg_lt?: string | null;
  errorMsg_lte?: string | null;
  errorMsg_gt?: string | null;
  errorMsg_gte?: string | null;
  slideshowSubject?: string | null;
  slideshowSubject_not?: string | null;
  slideshowSubject_is_null?: boolean | null;
  slideshowSubject_in?: (string | null)[] | null;
  slideshowSubject_not_in?: (string | null)[] | null;
  slideshowSubject_contains?: string | null;
  slideshowSubject_not_contains?: string | null;
  slideshowSubject_starts_with?: string | null;
  slideshowSubject_not_starts_with?: string | null;
  slideshowSubject_ends_with?: string | null;
  slideshowSubject_not_ends_with?: string | null;
  slideshowSubject_lt?: string | null;
  slideshowSubject_lte?: string | null;
  slideshowSubject_gt?: string | null;
  slideshowSubject_gte?: string | null;
  recipeNumber?: number | null;
  recipeNumber_not?: number | null;
  recipeNumber_is_null?: boolean | null;
  recipeNumber_in?: (number | null)[] | null;
  recipeNumber_not_in?: (number | null)[] | null;
  recipeNumber_lt?: number | null;
  recipeNumber_lte?: number | null;
  recipeNumber_gt?: number | null;
  recipeNumber_gte?: number | null;
  brandKey?: BrandKey | null;
  brandKey_not?: BrandKey | null;
  brandKey_is_null?: boolean | null;
  brandKey_in?: (BrandKey | null)[] | null;
  brandKey_not_in?: (BrandKey | null)[] | null;
  generatedTitle?: string | null;
  generatedTitle_not?: string | null;
  generatedTitle_is_null?: boolean | null;
  generatedTitle_in?: (string | null)[] | null;
  generatedTitle_not_in?: (string | null)[] | null;
  generatedTitle_contains?: string | null;
  generatedTitle_not_contains?: string | null;
  generatedTitle_starts_with?: string | null;
  generatedTitle_not_starts_with?: string | null;
  generatedTitle_ends_with?: string | null;
  generatedTitle_not_ends_with?: string | null;
  generatedTitle_lt?: string | null;
  generatedTitle_lte?: string | null;
  generatedTitle_gt?: string | null;
  generatedTitle_gte?: string | null;
  recipePaths?: string | null;
  recipePaths_not?: string | null;
  recipePaths_is_null?: boolean | null;
  recipePaths_in?: (string | null)[] | null;
  recipePaths_not_in?: (string | null)[] | null;
  recipePaths_contains?: string | null;
  recipePaths_not_contains?: string | null;
  recipePaths_starts_with?: string | null;
  recipePaths_not_starts_with?: string | null;
  recipePaths_ends_with?: string | null;
  recipePaths_not_ends_with?: string | null;
  recipePaths_lt?: string | null;
  recipePaths_lte?: string | null;
  recipePaths_gt?: string | null;
  recipePaths_gte?: string | null;
  userId?: string | null;
  userId_not?: string | null;
  userId_in?: string[] | null;
  userId_not_in?: string[] | null;
  article?: ArticleWhereInput | null;
  article_is_null?: boolean | null;
  AND?: OpenAiProcessWhereInput[] | null;
  OR?: OpenAiProcessWhereInput[] | null;
  NOT?: OpenAiProcessWhereInput | null;
}

/**
 * Identifies exactly one "OpenAiProcess" node with one of these unique values:
 * - _id
 * - id
 */
export interface OpenAiProcessWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
}

export interface OpenAiProcessWithForcedArticleCreateInput {
  id?: string | null;
  createdAt?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  status: OpenAIProcessStatus;
  errorMsg?: string | null;
  slideshowSubject?: string | null;
  recipeNumber?: number | null;
  brandKey?: BrandKey | null;
  generatedTitle?: string | null;
  recipePaths?: string | null;
}

export interface OpenAiProcessWithForcedArticleUpdateInput {
  id?: string | null;
  createdAt?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  status?: OpenAIProcessStatus | null;
  errorMsg?: string | null;
  slideshowSubject?: string | null;
  recipeNumber?: number | null;
  brandKey?: BrandKey | null;
  generatedTitle?: string | null;
  recipePaths?: string | null;
}

export interface OrphanPageCreateInput {
  id?: string | null;
  createdAt?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  publishedAt?: GraphQL$DateTime | null;
  editedAt?: GraphQL$DateTime | null;
  title: string;
  content?: string | null;
  uri?: string | null;
  brandKey: BrandKey;
  status: OrphanPageStatus;
  isFullScreen?: boolean | null;
  metas?: string | null;
  isCMPEnabled?: boolean | null;
  isAdsEnabled?: boolean | null;
}

export interface OrphanPageUpdateInput {
  id?: string | null;
  createdAt?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  publishedAt?: GraphQL$DateTime | null;
  editedAt?: GraphQL$DateTime | null;
  title?: string | null;
  content?: string | null;
  uri?: string | null;
  brandKey?: BrandKey | null;
  status?: OrphanPageStatus | null;
  isFullScreen?: boolean | null;
  metas?: string | null;
  isCMPEnabled?: boolean | null;
  isAdsEnabled?: boolean | null;
}

/**
 * Filters the "OrphanPage" nodes by specifying some conditions
 */
export interface OrphanPageWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_is_null?: boolean | null;
  id_in?: (string | null)[] | null;
  id_not_in?: (string | null)[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  publishedAt?: GraphQL$DateTime | null;
  publishedAt_not?: GraphQL$DateTime | null;
  publishedAt_is_null?: boolean | null;
  publishedAt_in?: (GraphQL$DateTime | null)[] | null;
  publishedAt_not_in?: (GraphQL$DateTime | null)[] | null;
  publishedAt_lt?: GraphQL$DateTime | null;
  publishedAt_lte?: GraphQL$DateTime | null;
  publishedAt_gt?: GraphQL$DateTime | null;
  publishedAt_gte?: GraphQL$DateTime | null;
  editedAt?: GraphQL$DateTime | null;
  editedAt_not?: GraphQL$DateTime | null;
  editedAt_is_null?: boolean | null;
  editedAt_in?: (GraphQL$DateTime | null)[] | null;
  editedAt_not_in?: (GraphQL$DateTime | null)[] | null;
  editedAt_lt?: GraphQL$DateTime | null;
  editedAt_lte?: GraphQL$DateTime | null;
  editedAt_gt?: GraphQL$DateTime | null;
  editedAt_gte?: GraphQL$DateTime | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  title_starts_with?: string | null;
  title_not_starts_with?: string | null;
  title_ends_with?: string | null;
  title_not_ends_with?: string | null;
  title_lt?: string | null;
  title_lte?: string | null;
  title_gt?: string | null;
  title_gte?: string | null;
  content?: string | null;
  content_not?: string | null;
  content_is_null?: boolean | null;
  content_in?: (string | null)[] | null;
  content_not_in?: (string | null)[] | null;
  content_contains?: string | null;
  content_not_contains?: string | null;
  content_starts_with?: string | null;
  content_not_starts_with?: string | null;
  content_ends_with?: string | null;
  content_not_ends_with?: string | null;
  content_lt?: string | null;
  content_lte?: string | null;
  content_gt?: string | null;
  content_gte?: string | null;
  uri?: string | null;
  uri_not?: string | null;
  uri_is_null?: boolean | null;
  uri_in?: (string | null)[] | null;
  uri_not_in?: (string | null)[] | null;
  uri_contains?: string | null;
  uri_not_contains?: string | null;
  uri_starts_with?: string | null;
  uri_not_starts_with?: string | null;
  uri_ends_with?: string | null;
  uri_not_ends_with?: string | null;
  uri_lt?: string | null;
  uri_lte?: string | null;
  uri_gt?: string | null;
  uri_gte?: string | null;
  brandKey?: BrandKey | null;
  brandKey_not?: BrandKey | null;
  brandKey_in?: BrandKey[] | null;
  brandKey_not_in?: BrandKey[] | null;
  status?: OrphanPageStatus | null;
  status_not?: OrphanPageStatus | null;
  status_in?: OrphanPageStatus[] | null;
  status_not_in?: OrphanPageStatus[] | null;
  isFullScreen?: boolean | null;
  isFullScreen_not?: boolean | null;
  isFullScreen_is_null?: boolean | null;
  isFullScreen_in?: (boolean | null)[] | null;
  isFullScreen_not_in?: (boolean | null)[] | null;
  metas?: string | null;
  metas_not?: string | null;
  metas_is_null?: boolean | null;
  metas_in?: (string | null)[] | null;
  metas_not_in?: (string | null)[] | null;
  metas_contains?: string | null;
  metas_not_contains?: string | null;
  metas_starts_with?: string | null;
  metas_not_starts_with?: string | null;
  metas_ends_with?: string | null;
  metas_not_ends_with?: string | null;
  metas_lt?: string | null;
  metas_lte?: string | null;
  metas_gt?: string | null;
  metas_gte?: string | null;
  isCMPEnabled?: boolean | null;
  isCMPEnabled_not?: boolean | null;
  isCMPEnabled_in?: boolean[] | null;
  isCMPEnabled_not_in?: boolean[] | null;
  isAdsEnabled?: boolean | null;
  isAdsEnabled_not?: boolean | null;
  isAdsEnabled_in?: boolean[] | null;
  isAdsEnabled_not_in?: boolean[] | null;
  AND?: OrphanPageWhereInput[] | null;
  OR?: OrphanPageWhereInput[] | null;
  NOT?: OrphanPageWhereInput | null;
}

/**
 * Identifies exactly one "OrphanPage" node with one of these unique values:
 * - _id
 * - id
 */
export interface OrphanPageWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
}

export interface PromptUpdateInput {
  body?: string | null;
  icon?: string | null;
}

/**
 * Filters the "Prompt" nodes by specifying some conditions
 */
export interface PromptWhereInput {
  brandKey?: BrandKey | null;
  brandKey_not?: BrandKey | null;
  brandKey_in?: BrandKey[] | null;
  brandKey_not_in?: BrandKey[] | null;
  field?: PromptField | null;
  field_not?: PromptField | null;
  field_in?: PromptField[] | null;
  field_not_in?: PromptField[] | null;
  label?: string | null;
  label_not?: string | null;
  label_in?: string[] | null;
  label_not_in?: string[] | null;
  label_contains?: string | null;
  label_not_contains?: string | null;
  label_starts_with?: string | null;
  label_not_starts_with?: string | null;
  label_ends_with?: string | null;
  label_not_ends_with?: string | null;
  label_lt?: string | null;
  label_lte?: string | null;
  label_gt?: string | null;
  label_gte?: string | null;
  body?: string | null;
  body_not?: string | null;
  body_in?: string[] | null;
  body_not_in?: string[] | null;
  body_contains?: string | null;
  body_not_contains?: string | null;
  body_starts_with?: string | null;
  body_not_starts_with?: string | null;
  body_ends_with?: string | null;
  body_not_ends_with?: string | null;
  body_lt?: string | null;
  body_lte?: string | null;
  body_gt?: string | null;
  body_gte?: string | null;
  icon?: string | null;
  icon_not?: string | null;
  icon_is_null?: boolean | null;
  icon_in?: (string | null)[] | null;
  icon_not_in?: (string | null)[] | null;
  icon_contains?: string | null;
  icon_not_contains?: string | null;
  icon_starts_with?: string | null;
  icon_not_starts_with?: string | null;
  icon_ends_with?: string | null;
  icon_not_ends_with?: string | null;
  icon_lt?: string | null;
  icon_lte?: string | null;
  icon_gt?: string | null;
  icon_gte?: string | null;
  AND?: PromptWhereInput[] | null;
  OR?: PromptWhereInput[] | null;
  NOT?: PromptWhereInput | null;
}

/**
 * Identifies exactly one "Prompt" node.
 */
export interface PromptWhereUniqueInput {
  brandKey: BrandKey;
  field: PromptField;
  label: string;
}

export interface PushCreateInput {
  id?: string | null;
  title: string;
  body: string;
  media: string;
  targetDevice: string;
  scheduledAt: GraphQL$DateTime;
  sentAt?: GraphQL$DateTime | null;
  isCancelled?: boolean | null;
  targeting?: string | null;
  inError?: boolean | null;
  errorReason?: string | null;
  userId: string;
  article: PushNestedArticleCreateInput;
  pushSegments?: PushNestedPushSegmentsCreateInput | null;
}

export interface PushNestedArticleCreateInput {
  connect?: ArticleWhereUniqueInput | null;
  update?: PushNestedUpdateArticleCreateInput | null;
  create?: ArticleCreateInput | null;
  upsert?: PushNestedUpsertArticleCreateInput | null;
}

export interface PushNestedArticleUpdateInput {
  connect?: ArticleWhereUniqueInput | null;
  update?: PushNestedUpdateArticleUpdateInput | null;
  create?: ArticleCreateInput | null;
  upsert?: PushNestedUpsertArticleUpdateInput | null;
}

export interface PushNestedPushSegmentsCreateInput {
  create?: PushSegmentWithForcedPushCreateInput[] | null;
}

export interface PushNestedPushSegmentsUpdateInput {
  create?: PushSegmentWithForcedPushCreateInput[] | null;
  delete?: PushSegmentWithForcedPushWhereUniqueInput[] | null;
}

export interface PushNestedUpdateArticleCreateInput {
  where: ArticleWhereUniqueInput;
  data: ArticleUpdateInput;
}

export interface PushNestedUpdateArticleUpdateInput {
  where: ArticleWhereUniqueInput;
  data: ArticleUpdateInput;
}

export interface PushNestedUpsertArticleCreateInput {
  where: ArticleWhereUniqueInput;
  update: ArticleUpdateInput;
  create: ArticleCreateInput;
}

export interface PushNestedUpsertArticleUpdateInput {
  where: ArticleWhereUniqueInput;
  update: ArticleUpdateInput;
  create: ArticleCreateInput;
}

export interface PushPushesSearchWhereInput {
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  sentAt_is_null?: boolean | null;
  sentAt?: GraphQL$DateTime | null;
  sentAt_not?: GraphQL$DateTime | null;
  sentAt_in?: (GraphQL$DateTime | null)[] | null;
  sentAt_not_in?: (GraphQL$DateTime | null)[] | null;
  sentAt_lt?: GraphQL$DateTime | null;
  sentAt_lte?: GraphQL$DateTime | null;
  sentAt_gt?: GraphQL$DateTime | null;
  sentAt_gte?: GraphQL$DateTime | null;
  AND?: PushPushesSearchWhereInput[] | null;
  OR?: PushPushesSearchWhereInput[] | null;
  NOT?: PushPushesSearchWhereInput | null;
}

export interface PushSegmentNestedPushCreateInput {
  connect?: PushWhereUniqueInput | null;
  update?: PushSegmentNestedUpdatePushCreateInput | null;
  create?: PushCreateInput | null;
  upsert?: PushSegmentNestedUpsertPushCreateInput | null;
}

export interface PushSegmentNestedSegmentCreateInput {
  connect?: SegmentWhereUniqueInput | null;
  update?: PushSegmentNestedUpdateSegmentCreateInput | null;
  create?: SegmentCreateInput | null;
  upsert?: PushSegmentNestedUpsertSegmentCreateInput | null;
}

export interface PushSegmentNestedUpdatePushCreateInput {
  where: PushWhereUniqueInput;
  data: PushUpdateInput;
}

export interface PushSegmentNestedUpdateSegmentCreateInput {
  where: SegmentWhereUniqueInput;
  data: SegmentUpdateInput;
}

export interface PushSegmentNestedUpsertPushCreateInput {
  where: PushWhereUniqueInput;
  update: PushUpdateInput;
  create: PushCreateInput;
}

export interface PushSegmentNestedUpsertSegmentCreateInput {
  where: SegmentWhereUniqueInput;
  update: SegmentUpdateInput;
  create: SegmentCreateInput;
}

/**
 * Filters the "PushSegment" nodes by specifying some conditions
 */
export interface PushSegmentWhereInput {
  push?: PushWhereInput | null;
  segment?: SegmentWhereInput | null;
  AND?: PushSegmentWhereInput[] | null;
  OR?: PushSegmentWhereInput[] | null;
  NOT?: PushSegmentWhereInput | null;
}

export interface PushSegmentWithForcedPushCreateInput {
  segment: PushSegmentNestedSegmentCreateInput;
}

/**
 * Given a forced "push", identifies exactly one "PushSegment" node.
 */
export interface PushSegmentWithForcedPushWhereUniqueInput {
  segment: SegmentWhereUniqueInput;
}

export interface PushSegmentWithForcedSegmentCreateInput {
  push: PushSegmentNestedPushCreateInput;
}

/**
 * Given a forced "segment", identifies exactly one "PushSegment" node.
 */
export interface PushSegmentWithForcedSegmentWhereUniqueInput {
  push: PushWhereUniqueInput;
}

export interface PushUpdateInput {
  title?: string | null;
  body?: string | null;
  media?: string | null;
  targetDevice?: string | null;
  scheduledAt?: GraphQL$DateTime | null;
  sentAt?: GraphQL$DateTime | null;
  isCancelled?: boolean | null;
  targeting?: string | null;
  inError?: boolean | null;
  errorReason?: string | null;
  userId?: string | null;
  article?: PushNestedArticleUpdateInput | null;
  pushSegments?: PushNestedPushSegmentsUpdateInput | null;
}

/**
 * Filters the "Push" nodes by specifying some conditions
 */
export interface PushWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  title_starts_with?: string | null;
  title_not_starts_with?: string | null;
  title_ends_with?: string | null;
  title_not_ends_with?: string | null;
  title_lt?: string | null;
  title_lte?: string | null;
  title_gt?: string | null;
  title_gte?: string | null;
  body?: string | null;
  body_not?: string | null;
  body_in?: string[] | null;
  body_not_in?: string[] | null;
  body_contains?: string | null;
  body_not_contains?: string | null;
  body_starts_with?: string | null;
  body_not_starts_with?: string | null;
  body_ends_with?: string | null;
  body_not_ends_with?: string | null;
  body_lt?: string | null;
  body_lte?: string | null;
  body_gt?: string | null;
  body_gte?: string | null;
  media?: string | null;
  media_not?: string | null;
  media_in?: string[] | null;
  media_not_in?: string[] | null;
  media_contains?: string | null;
  media_not_contains?: string | null;
  media_starts_with?: string | null;
  media_not_starts_with?: string | null;
  media_ends_with?: string | null;
  media_not_ends_with?: string | null;
  media_lt?: string | null;
  media_lte?: string | null;
  media_gt?: string | null;
  media_gte?: string | null;
  targetDevice?: string | null;
  targetDevice_not?: string | null;
  targetDevice_in?: string[] | null;
  targetDevice_not_in?: string[] | null;
  targetDevice_contains?: string | null;
  targetDevice_not_contains?: string | null;
  targetDevice_starts_with?: string | null;
  targetDevice_not_starts_with?: string | null;
  targetDevice_ends_with?: string | null;
  targetDevice_not_ends_with?: string | null;
  targetDevice_lt?: string | null;
  targetDevice_lte?: string | null;
  targetDevice_gt?: string | null;
  targetDevice_gte?: string | null;
  scheduledAt?: GraphQL$DateTime | null;
  scheduledAt_not?: GraphQL$DateTime | null;
  scheduledAt_in?: GraphQL$DateTime[] | null;
  scheduledAt_not_in?: GraphQL$DateTime[] | null;
  scheduledAt_lt?: GraphQL$DateTime | null;
  scheduledAt_lte?: GraphQL$DateTime | null;
  scheduledAt_gt?: GraphQL$DateTime | null;
  scheduledAt_gte?: GraphQL$DateTime | null;
  sentAt?: GraphQL$DateTime | null;
  sentAt_not?: GraphQL$DateTime | null;
  sentAt_is_null?: boolean | null;
  sentAt_in?: (GraphQL$DateTime | null)[] | null;
  sentAt_not_in?: (GraphQL$DateTime | null)[] | null;
  sentAt_lt?: GraphQL$DateTime | null;
  sentAt_lte?: GraphQL$DateTime | null;
  sentAt_gt?: GraphQL$DateTime | null;
  sentAt_gte?: GraphQL$DateTime | null;
  isCancelled?: boolean | null;
  isCancelled_not?: boolean | null;
  isCancelled_is_null?: boolean | null;
  isCancelled_in?: (boolean | null)[] | null;
  isCancelled_not_in?: (boolean | null)[] | null;
  targeting?: string | null;
  targeting_not?: string | null;
  targeting_is_null?: boolean | null;
  targeting_in?: (string | null)[] | null;
  targeting_not_in?: (string | null)[] | null;
  targeting_contains?: string | null;
  targeting_not_contains?: string | null;
  targeting_starts_with?: string | null;
  targeting_not_starts_with?: string | null;
  targeting_ends_with?: string | null;
  targeting_not_ends_with?: string | null;
  targeting_lt?: string | null;
  targeting_lte?: string | null;
  targeting_gt?: string | null;
  targeting_gte?: string | null;
  inError?: boolean | null;
  inError_not?: boolean | null;
  inError_is_null?: boolean | null;
  inError_in?: (boolean | null)[] | null;
  inError_not_in?: (boolean | null)[] | null;
  errorReason?: string | null;
  errorReason_not?: string | null;
  errorReason_is_null?: boolean | null;
  errorReason_in?: (string | null)[] | null;
  errorReason_not_in?: (string | null)[] | null;
  errorReason_contains?: string | null;
  errorReason_not_contains?: string | null;
  errorReason_starts_with?: string | null;
  errorReason_not_starts_with?: string | null;
  errorReason_ends_with?: string | null;
  errorReason_not_ends_with?: string | null;
  errorReason_lt?: string | null;
  errorReason_lte?: string | null;
  errorReason_gt?: string | null;
  errorReason_gte?: string | null;
  userId?: string | null;
  userId_not?: string | null;
  userId_in?: string[] | null;
  userId_not_in?: string[] | null;
  article?: ArticleWhereInput | null;
  pushSegments_some?: PushSegmentWhereInput | null;
  AND?: PushWhereInput[] | null;
  OR?: PushWhereInput[] | null;
  NOT?: PushWhereInput | null;
}

/**
 * Identifies exactly one "Push" node with one of these unique values:
 * - _id
 * - id
 */
export interface PushWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
}

export interface PushWithForcedArticleCreateInput {
  id?: string | null;
  title: string;
  body: string;
  media: string;
  targetDevice: string;
  scheduledAt: GraphQL$DateTime;
  sentAt?: GraphQL$DateTime | null;
  isCancelled?: boolean | null;
  targeting?: string | null;
  inError?: boolean | null;
  errorReason?: string | null;
  userId: string;
  pushSegments?: PushNestedPushSegmentsCreateInput | null;
}

export interface PushWithForcedArticleUpdateInput {
  title?: string | null;
  body?: string | null;
  media?: string | null;
  targetDevice?: string | null;
  scheduledAt?: GraphQL$DateTime | null;
  sentAt?: GraphQL$DateTime | null;
  isCancelled?: boolean | null;
  targeting?: string | null;
  inError?: boolean | null;
  errorReason?: string | null;
  userId?: string | null;
  pushSegments?: PushNestedPushSegmentsUpdateInput | null;
}

export interface QualifierArticleQualifiersQualifierSearchWhereInput {
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  AND?: QualifierArticleQualifiersQualifierSearchWhereInput[] | null;
  OR?: QualifierArticleQualifiersQualifierSearchWhereInput[] | null;
  NOT?: QualifierArticleQualifiersQualifierSearchWhereInput | null;
}

export interface QualifierCreateInput {
  id?: string | null;
  brandKey: BrandKey;
  title: string;
  slug?: string | null;
  status?: QualifierStatus | null;
  format?: ArticleFormat | null;
  articleQualifiers?: QualifierNestedArticleQualifiersCreateInput | null;
}

export interface QualifierNestedArticleQualifiersCreateInput {
  create?: ArticleQualifierWithForcedQualifierCreateInput[] | null;
}

export interface QualifierNestedArticleQualifiersUpdateInput {
  update?: QualifierNestedUpdateArticleQualifiersUpdateInput[] | null;
  create?: ArticleQualifierWithForcedQualifierCreateInput[] | null;
  upsert?: QualifierNestedUpsertArticleQualifiersUpdateInput[] | null;
  delete?: ArticleQualifierWithForcedQualifierWhereUniqueInput[] | null;
}

export interface QualifierNestedUpdateArticleQualifiersUpdateInput {
  where: ArticleQualifierWithForcedQualifierWhereUniqueInput;
  data: ArticleQualifierWithForcedQualifierUpdateInput;
}

export interface QualifierNestedUpsertArticleQualifiersUpdateInput {
  where: ArticleQualifierWithForcedQualifierWhereUniqueInput;
  update: ArticleQualifierWithForcedQualifierUpdateInput;
  create: ArticleQualifierWithForcedQualifierCreateInput;
}

export interface QualifierUpdateInput {
  status?: QualifierStatus | null;
  format?: ArticleFormat | null;
  articleQualifiers?: QualifierNestedArticleQualifiersUpdateInput | null;
}

/**
 * Filters the "Qualifier" nodes by specifying some conditions
 */
export interface QualifierWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  brandKey?: BrandKey | null;
  brandKey_not?: BrandKey | null;
  brandKey_in?: BrandKey[] | null;
  brandKey_not_in?: BrandKey[] | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  title_starts_with?: string | null;
  title_not_starts_with?: string | null;
  title_ends_with?: string | null;
  title_not_ends_with?: string | null;
  title_lt?: string | null;
  title_lte?: string | null;
  title_gt?: string | null;
  title_gte?: string | null;
  slug?: string | null;
  slug_not?: string | null;
  slug_in?: string[] | null;
  slug_not_in?: string[] | null;
  slug_contains?: string | null;
  slug_not_contains?: string | null;
  slug_starts_with?: string | null;
  slug_not_starts_with?: string | null;
  slug_ends_with?: string | null;
  slug_not_ends_with?: string | null;
  slug_lt?: string | null;
  slug_lte?: string | null;
  slug_gt?: string | null;
  slug_gte?: string | null;
  status?: QualifierStatus | null;
  status_not?: QualifierStatus | null;
  status_in?: QualifierStatus[] | null;
  status_not_in?: QualifierStatus[] | null;
  format?: ArticleFormat | null;
  format_not?: ArticleFormat | null;
  format_in?: ArticleFormat[] | null;
  format_not_in?: ArticleFormat[] | null;
  articleQualifiers_some?: ArticleQualifierWhereInput | null;
  AND?: QualifierWhereInput[] | null;
  OR?: QualifierWhereInput[] | null;
  NOT?: QualifierWhereInput | null;
}

/**
 * Identifies exactly one "Qualifier" node with one of these unique combinations of values:
 * - _id
 * - id
 * - brandKey, format, slug
 * - brandKey, format, title
 */
export interface QualifierWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
  brandKey?: BrandKey | null;
  format?: ArticleFormat | null;
  slug?: string | null;
  title?: string | null;
}

export interface SegmentCreateInput {
  id?: string | null;
  title?: string | null;
  active?: boolean | null;
  device?: string | null;
  brandKey?: BrandKey | null;
  ios?: string | null;
  android?: string | null;
  editorialAlert?: string | null;
  status?: SegmentStatus | null;
  pushSegments?: SegmentNestedPushSegmentsCreateInput | null;
}

export interface SegmentNestedPushSegmentsCreateInput {
  create?: PushSegmentWithForcedSegmentCreateInput[] | null;
}

export interface SegmentNestedPushSegmentsUpdateInput {
  create?: PushSegmentWithForcedSegmentCreateInput[] | null;
  delete?: PushSegmentWithForcedSegmentWhereUniqueInput[] | null;
}

export interface SegmentUpdateInput {
  title?: string | null;
  active?: boolean | null;
  device?: string | null;
  brandKey?: BrandKey | null;
  ios?: string | null;
  android?: string | null;
  editorialAlert?: string | null;
  status?: SegmentStatus | null;
  pushSegments?: SegmentNestedPushSegmentsUpdateInput | null;
}

/**
 * Filters the "Segment" nodes by specifying some conditions
 */
export interface SegmentWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  title?: string | null;
  title_not?: string | null;
  title_is_null?: boolean | null;
  title_in?: (string | null)[] | null;
  title_not_in?: (string | null)[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  title_starts_with?: string | null;
  title_not_starts_with?: string | null;
  title_ends_with?: string | null;
  title_not_ends_with?: string | null;
  title_lt?: string | null;
  title_lte?: string | null;
  title_gt?: string | null;
  title_gte?: string | null;
  active?: boolean | null;
  active_not?: boolean | null;
  active_is_null?: boolean | null;
  active_in?: (boolean | null)[] | null;
  active_not_in?: (boolean | null)[] | null;
  device?: string | null;
  device_not?: string | null;
  device_is_null?: boolean | null;
  device_in?: (string | null)[] | null;
  device_not_in?: (string | null)[] | null;
  device_contains?: string | null;
  device_not_contains?: string | null;
  device_starts_with?: string | null;
  device_not_starts_with?: string | null;
  device_ends_with?: string | null;
  device_not_ends_with?: string | null;
  device_lt?: string | null;
  device_lte?: string | null;
  device_gt?: string | null;
  device_gte?: string | null;
  brandKey?: BrandKey | null;
  brandKey_not?: BrandKey | null;
  brandKey_is_null?: boolean | null;
  brandKey_in?: (BrandKey | null)[] | null;
  brandKey_not_in?: (BrandKey | null)[] | null;
  ios?: string | null;
  ios_not?: string | null;
  ios_is_null?: boolean | null;
  ios_in?: (string | null)[] | null;
  ios_not_in?: (string | null)[] | null;
  ios_contains?: string | null;
  ios_not_contains?: string | null;
  ios_starts_with?: string | null;
  ios_not_starts_with?: string | null;
  ios_ends_with?: string | null;
  ios_not_ends_with?: string | null;
  ios_lt?: string | null;
  ios_lte?: string | null;
  ios_gt?: string | null;
  ios_gte?: string | null;
  android?: string | null;
  android_not?: string | null;
  android_is_null?: boolean | null;
  android_in?: (string | null)[] | null;
  android_not_in?: (string | null)[] | null;
  android_contains?: string | null;
  android_not_contains?: string | null;
  android_starts_with?: string | null;
  android_not_starts_with?: string | null;
  android_ends_with?: string | null;
  android_not_ends_with?: string | null;
  android_lt?: string | null;
  android_lte?: string | null;
  android_gt?: string | null;
  android_gte?: string | null;
  editorialAlert?: string | null;
  editorialAlert_not?: string | null;
  editorialAlert_is_null?: boolean | null;
  editorialAlert_in?: (string | null)[] | null;
  editorialAlert_not_in?: (string | null)[] | null;
  editorialAlert_contains?: string | null;
  editorialAlert_not_contains?: string | null;
  editorialAlert_starts_with?: string | null;
  editorialAlert_not_starts_with?: string | null;
  editorialAlert_ends_with?: string | null;
  editorialAlert_not_ends_with?: string | null;
  editorialAlert_lt?: string | null;
  editorialAlert_lte?: string | null;
  editorialAlert_gt?: string | null;
  editorialAlert_gte?: string | null;
  status?: SegmentStatus | null;
  status_not?: SegmentStatus | null;
  status_in?: SegmentStatus[] | null;
  status_not_in?: SegmentStatus[] | null;
  pushSegments_some?: PushSegmentWhereInput | null;
  AND?: SegmentWhereInput[] | null;
  OR?: SegmentWhereInput[] | null;
  NOT?: SegmentWhereInput | null;
}

/**
 * Identifies exactly one "Segment" node with one of these unique values:
 * - _id
 * - id
 */
export interface SegmentWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
}

export interface SimilarArticleArgsInputType {
  article?: SimilarArticleInputType | null;
  fields?: (ArticleSearchFieldsType | null)[] | null;
  threshold?: number | null;
  first: number;
}

export interface SimilarArticleInputType {
  format?: ArticleFormat | null;
  brandKey?: BrandKey | null;
  id?: string | null;
  title?: string | null;
  lead?: string | null;
  body?: string | null;
  medias?: string | null;
  bodyMedias?: string | null;
}

export interface SourceCreateInput {
  id?: string | null;
  brandKey: BrandKey;
  title: string;
  slug?: string | null;
  status: SourceStatus;
  articles?: SourceNestedArticlesCreateInput | null;
}

export interface SourceNestedArticlesCreateInput {
  connect?: ArticleWhereUniqueInput[] | null;
  create?: ArticleWithForcedSourceCreateInput[] | null;
}

export interface SourceNestedArticlesUpdateInput {
  connect?: ArticleWhereUniqueInput[] | null;
  disconnect?: ArticleWhereUniqueInput[] | null;
  update?: SourceNestedUpdateArticlesUpdateInput[] | null;
  create?: ArticleWithForcedSourceCreateInput[] | null;
  upsert?: SourceNestedUpsertArticlesUpdateInput[] | null;
  delete?: ArticleWhereUniqueInput[] | null;
}

export interface SourceNestedUpdateArticlesUpdateInput {
  where: ArticleWhereUniqueInput;
  data: ArticleWithForcedSourceUpdateInput;
}

export interface SourceNestedUpsertArticlesUpdateInput {
  where: ArticleWhereUniqueInput;
  update: ArticleWithForcedSourceUpdateInput;
  create: ArticleWithForcedSourceCreateInput;
}

export interface SourceSourceSearchWhereInput {
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  AND?: SourceSourceSearchWhereInput[] | null;
  OR?: SourceSourceSearchWhereInput[] | null;
  NOT?: SourceSourceSearchWhereInput | null;
}

export interface SourceUpdateInput {
  brandKey?: BrandKey | null;
  title?: string | null;
  slug?: string | null;
  status?: SourceStatus | null;
  articles?: SourceNestedArticlesUpdateInput | null;
}

/**
 * Filters the "Source" nodes by specifying some conditions
 */
export interface SourceWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  brandKey?: BrandKey | null;
  brandKey_not?: BrandKey | null;
  brandKey_in?: BrandKey[] | null;
  brandKey_not_in?: BrandKey[] | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  title_starts_with?: string | null;
  title_not_starts_with?: string | null;
  title_ends_with?: string | null;
  title_not_ends_with?: string | null;
  title_lt?: string | null;
  title_lte?: string | null;
  title_gt?: string | null;
  title_gte?: string | null;
  slug?: string | null;
  slug_not?: string | null;
  slug_in?: string[] | null;
  slug_not_in?: string[] | null;
  slug_contains?: string | null;
  slug_not_contains?: string | null;
  slug_starts_with?: string | null;
  slug_not_starts_with?: string | null;
  slug_ends_with?: string | null;
  slug_not_ends_with?: string | null;
  slug_lt?: string | null;
  slug_lte?: string | null;
  slug_gt?: string | null;
  slug_gte?: string | null;
  status?: SourceStatus | null;
  status_not?: SourceStatus | null;
  status_in?: SourceStatus[] | null;
  status_not_in?: SourceStatus[] | null;
  articles_some?: ArticleWhereInput | null;
  AND?: SourceWhereInput[] | null;
  OR?: SourceWhereInput[] | null;
  NOT?: SourceWhereInput | null;
}

/**
 * Identifies exactly one "Source" node with one of these unique combinations of values:
 * - _id
 * - id
 * - brandKey, title
 * - brandKey, slug
 */
export interface SourceWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
  brandKey?: BrandKey | null;
  title?: string | null;
  slug?: string | null;
}

/**
 * Filters the "Tab" nodes by specifying some conditions
 */
export interface TabWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  title_starts_with?: string | null;
  title_not_starts_with?: string | null;
  title_ends_with?: string | null;
  title_not_ends_with?: string | null;
  title_lt?: string | null;
  title_lte?: string | null;
  title_gt?: string | null;
  title_gte?: string | null;
  slug?: string | null;
  slug_not?: string | null;
  slug_in?: string[] | null;
  slug_not_in?: string[] | null;
  slug_contains?: string | null;
  slug_not_contains?: string | null;
  slug_starts_with?: string | null;
  slug_not_starts_with?: string | null;
  slug_ends_with?: string | null;
  slug_not_ends_with?: string | null;
  slug_lt?: string | null;
  slug_lte?: string | null;
  slug_gt?: string | null;
  slug_gte?: string | null;
  type?: TabType | null;
  type_not?: TabType | null;
  type_in?: TabType[] | null;
  type_not_in?: TabType[] | null;
  enabledLabel?: string | null;
  enabledLabel_not?: string | null;
  enabledLabel_is_null?: boolean | null;
  enabledLabel_in?: (string | null)[] | null;
  enabledLabel_not_in?: (string | null)[] | null;
  enabledLabel_contains?: string | null;
  enabledLabel_not_contains?: string | null;
  enabledLabel_starts_with?: string | null;
  enabledLabel_not_starts_with?: string | null;
  enabledLabel_ends_with?: string | null;
  enabledLabel_not_ends_with?: string | null;
  enabledLabel_lt?: string | null;
  enabledLabel_lte?: string | null;
  enabledLabel_gt?: string | null;
  enabledLabel_gte?: string | null;
  disabledLabel?: string | null;
  disabledLabel_not?: string | null;
  disabledLabel_is_null?: boolean | null;
  disabledLabel_in?: (string | null)[] | null;
  disabledLabel_not_in?: (string | null)[] | null;
  disabledLabel_contains?: string | null;
  disabledLabel_not_contains?: string | null;
  disabledLabel_starts_with?: string | null;
  disabledLabel_not_starts_with?: string | null;
  disabledLabel_ends_with?: string | null;
  disabledLabel_not_ends_with?: string | null;
  disabledLabel_lt?: string | null;
  disabledLabel_lte?: string | null;
  disabledLabel_gt?: string | null;
  disabledLabel_gte?: string | null;
  description?: string | null;
  description_not?: string | null;
  description_is_null?: boolean | null;
  description_in?: (string | null)[] | null;
  description_not_in?: (string | null)[] | null;
  description_contains?: string | null;
  description_not_contains?: string | null;
  description_starts_with?: string | null;
  description_not_starts_with?: string | null;
  description_ends_with?: string | null;
  description_not_ends_with?: string | null;
  description_lt?: string | null;
  description_lte?: string | null;
  description_gt?: string | null;
  description_gte?: string | null;
  isOnline?: boolean | null;
  isOnline_not?: boolean | null;
  isOnline_in?: boolean[] | null;
  isOnline_not_in?: boolean[] | null;
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_lt?: number | null;
  order_lte?: number | null;
  order_gt?: number | null;
  order_gte?: number | null;
  content?: string | null;
  content_not?: string | null;
  content_is_null?: boolean | null;
  content_in?: (string | null)[] | null;
  content_not_in?: (string | null)[] | null;
  content_contains?: string | null;
  content_not_contains?: string | null;
  content_starts_with?: string | null;
  content_not_starts_with?: string | null;
  content_ends_with?: string | null;
  content_not_ends_with?: string | null;
  content_lt?: string | null;
  content_lte?: string | null;
  content_gt?: string | null;
  content_gte?: string | null;
  metas?: string | null;
  metas_not?: string | null;
  metas_is_null?: boolean | null;
  metas_in?: (string | null)[] | null;
  metas_not_in?: (string | null)[] | null;
  metas_contains?: string | null;
  metas_not_contains?: string | null;
  metas_starts_with?: string | null;
  metas_not_starts_with?: string | null;
  metas_ends_with?: string | null;
  metas_not_ends_with?: string | null;
  metas_lt?: string | null;
  metas_lte?: string | null;
  metas_gt?: string | null;
  metas_gte?: string | null;
  category?: CategoryWhereInput | null;
  AND?: TabWhereInput[] | null;
  OR?: TabWhereInput[] | null;
  NOT?: TabWhereInput | null;
}

/**
 * Identifies exactly one "Tab" node with one of these unique values:
 * - _id
 * - id
 */
export interface TabWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
}

export interface TabWithForcedCategoryCreateInput {
  id?: string | null;
  title: string;
  slug?: string | null;
  type: TabType;
  enabledLabel?: string | null;
  disabledLabel?: string | null;
  description?: string | null;
  isOnline: boolean;
  order: number;
  content?: string | null;
  metas?: string | null;
}

export interface TabWithForcedCategoryUpdateInput {
  title?: string | null;
  slug?: string | null;
  type?: TabType | null;
  enabledLabel?: string | null;
  disabledLabel?: string | null;
  description?: string | null;
  isOnline?: boolean | null;
  order?: number | null;
  content?: string | null;
  metas?: string | null;
}

export interface TagArticleTagsTagSearchWhereInput {
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  AND?: TagArticleTagsTagSearchWhereInput[] | null;
  OR?: TagArticleTagsTagSearchWhereInput[] | null;
  NOT?: TagArticleTagsTagSearchWhereInput | null;
}

/**
 * Filters the "TagBrandUsage" nodes by specifying some conditions
 */
export interface TagBrandUsageWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  brandKey?: BrandKey | null;
  brandKey_not?: BrandKey | null;
  brandKey_in?: BrandKey[] | null;
  brandKey_not_in?: BrandKey[] | null;
  isEnabled?: boolean | null;
  isEnabled_not?: boolean | null;
  isEnabled_in?: boolean[] | null;
  isEnabled_not_in?: boolean[] | null;
  articleCount?: number | null;
  articleCount_not?: number | null;
  articleCount_in?: number[] | null;
  articleCount_not_in?: number[] | null;
  articleCount_lt?: number | null;
  articleCount_lte?: number | null;
  articleCount_gt?: number | null;
  articleCount_gte?: number | null;
  statusSwitchedAt?: GraphQL$Date | null;
  statusSwitchedAt_not?: GraphQL$Date | null;
  statusSwitchedAt_in?: GraphQL$Date[] | null;
  statusSwitchedAt_not_in?: GraphQL$Date[] | null;
  statusSwitchedAt_lt?: GraphQL$Date | null;
  statusSwitchedAt_lte?: GraphQL$Date | null;
  statusSwitchedAt_gt?: GraphQL$Date | null;
  statusSwitchedAt_gte?: GraphQL$Date | null;
  computedAt?: GraphQL$Date | null;
  computedAt_not?: GraphQL$Date | null;
  computedAt_in?: GraphQL$Date[] | null;
  computedAt_not_in?: GraphQL$Date[] | null;
  computedAt_lt?: GraphQL$Date | null;
  computedAt_lte?: GraphQL$Date | null;
  computedAt_gt?: GraphQL$Date | null;
  computedAt_gte?: GraphQL$Date | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  tag?: TagWhereInput | null;
  AND?: TagBrandUsageWhereInput[] | null;
  OR?: TagBrandUsageWhereInput[] | null;
  NOT?: TagBrandUsageWhereInput | null;
}

/**
 * Identifies exactly one "TagBrandUsage" node with one of these unique combinations of values:
 * - _id
 * - brandKey, tag
 */
export interface TagBrandUsageWhereUniqueInput {
  _id?: number | null;
  brandKey?: BrandKey | null;
  tag?: TagWhereUniqueInput | null;
}

export interface TagBrandUsageWithForcedTagCreateInput {
  brandKey: BrandKey;
  isEnabled: boolean;
  articleCount: number;
  statusSwitchedAt: GraphQL$Date;
  computedAt: GraphQL$Date;
}

export interface TagBrandUsageWithForcedTagUpdateInput {
  brandKey?: BrandKey | null;
  isEnabled?: boolean | null;
  articleCount?: number | null;
  statusSwitchedAt?: GraphQL$Date | null;
  computedAt?: GraphQL$Date | null;
}

/**
 * Given a forced "tag", identifies exactly one "TagBrandUsage" node.
 */
export interface TagBrandUsageWithForcedTagWhereUniqueInput {
  brandKey: BrandKey;
}

/**
 * Given a known "tag", identifies exactly one "TagBrandUsage" node with one of these unique combinations of values:
 * - _id
 * - brandKey, tag (optional)
 */
export interface TagBrandUsageWithOptionalTagWhereUniqueInput {
  _id?: number | null;
  brandKey?: BrandKey | null;
  tag?: TagWhereUniqueInput | null;
}

export interface TagCreateInput {
  id?: string | null;
  title: string;
  slug?: string | null;
  type: TagType;
  relationId?: string | null;
  lang?: TagLang | null;
  mergedTo?: number | null;
  articleTags?: TagNestedArticleTagsCreateInput | null;
  cardTags?: TagNestedCardTagsCreateInput | null;
  tagBrandUsages?: TagNestedTagBrandUsagesCreateInput | null;
}

export interface TagNestedArticleTagsCreateInput {
  create?: ArticleTagWithForcedTagCreateInput[] | null;
}

export interface TagNestedArticleTagsUpdateInput {
  update?: TagNestedUpdateArticleTagsUpdateInput[] | null;
  create?: ArticleTagWithForcedTagCreateInput[] | null;
  upsert?: TagNestedUpsertArticleTagsUpdateInput[] | null;
  delete?: ArticleTagWithForcedTagWhereUniqueInput[] | null;
}

export interface TagNestedCardTagsCreateInput {
  create?: CardTagWithForcedTagCreateInput[] | null;
}

export interface TagNestedCardTagsUpdateInput {
  update?: TagNestedUpdateCardTagsUpdateInput[] | null;
  create?: CardTagWithForcedTagCreateInput[] | null;
  upsert?: TagNestedUpsertCardTagsUpdateInput[] | null;
  delete?: CardTagWithForcedTagWhereUniqueInput[] | null;
}

export interface TagNestedTagBrandUsagesCreateInput {
  connect?: TagBrandUsageWhereUniqueInput[] | null;
  create?: TagBrandUsageWithForcedTagCreateInput[] | null;
}

export interface TagNestedTagBrandUsagesUpdateInput {
  connect?: TagBrandUsageWhereUniqueInput[] | null;
  update?: TagNestedUpdateTagBrandUsagesUpdateInput[] | null;
  create?: TagBrandUsageWithForcedTagCreateInput[] | null;
  upsert?: TagNestedUpsertTagBrandUsagesUpdateInput[] | null;
  delete?: TagBrandUsageWithForcedTagWhereUniqueInput[] | null;
}

export interface TagNestedUpdateArticleTagsUpdateInput {
  where: ArticleTagWithForcedTagWhereUniqueInput;
  data: ArticleTagWithForcedTagUpdateInput;
}

export interface TagNestedUpdateCardTagsUpdateInput {
  where: CardTagWithForcedTagWhereUniqueInput;
  data: CardTagWithForcedTagUpdateInput;
}

export interface TagNestedUpdateTagBrandUsagesUpdateInput {
  where: TagBrandUsageWithOptionalTagWhereUniqueInput;
  data: TagBrandUsageWithForcedTagUpdateInput;
}

export interface TagNestedUpsertArticleTagsUpdateInput {
  where: ArticleTagWithForcedTagWhereUniqueInput;
  update: ArticleTagWithForcedTagUpdateInput;
  create: ArticleTagWithForcedTagCreateInput;
}

export interface TagNestedUpsertCardTagsUpdateInput {
  where: CardTagWithForcedTagWhereUniqueInput;
  update: CardTagWithForcedTagUpdateInput;
  create: CardTagWithForcedTagCreateInput;
}

export interface TagNestedUpsertTagBrandUsagesUpdateInput {
  where: TagBrandUsageWithOptionalTagWhereUniqueInput;
  update: TagBrandUsageWithForcedTagUpdateInput;
  create: TagBrandUsageWithForcedTagCreateInput;
}

export interface TagUpdateInput {
  title?: string | null;
  slug?: string | null;
  type?: TagType | null;
  relationId?: string | null;
  lang?: TagLang | null;
  mergedTo?: number | null;
  articleTags?: TagNestedArticleTagsUpdateInput | null;
  cardTags?: TagNestedCardTagsUpdateInput | null;
  tagBrandUsages?: TagNestedTagBrandUsagesUpdateInput | null;
}

/**
 * Filters the "Tag" nodes by specifying some conditions
 */
export interface TagWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  title_starts_with?: string | null;
  title_not_starts_with?: string | null;
  title_ends_with?: string | null;
  title_not_ends_with?: string | null;
  title_lt?: string | null;
  title_lte?: string | null;
  title_gt?: string | null;
  title_gte?: string | null;
  slug?: string | null;
  slug_not?: string | null;
  slug_in?: string[] | null;
  slug_not_in?: string[] | null;
  slug_contains?: string | null;
  slug_not_contains?: string | null;
  slug_starts_with?: string | null;
  slug_not_starts_with?: string | null;
  slug_ends_with?: string | null;
  slug_not_ends_with?: string | null;
  slug_lt?: string | null;
  slug_lte?: string | null;
  slug_gt?: string | null;
  slug_gte?: string | null;
  type?: TagType | null;
  type_not?: TagType | null;
  type_in?: TagType[] | null;
  type_not_in?: TagType[] | null;
  relationId?: string | null;
  relationId_not?: string | null;
  relationId_is_null?: boolean | null;
  relationId_in?: (string | null)[] | null;
  relationId_not_in?: (string | null)[] | null;
  lang?: TagLang | null;
  lang_not?: TagLang | null;
  lang_in?: TagLang[] | null;
  lang_not_in?: TagLang[] | null;
  mergedTo?: number | null;
  mergedTo_not?: number | null;
  mergedTo_is_null?: boolean | null;
  mergedTo_in?: (number | null)[] | null;
  mergedTo_not_in?: (number | null)[] | null;
  mergedTo_lt?: number | null;
  mergedTo_lte?: number | null;
  mergedTo_gt?: number | null;
  mergedTo_gte?: number | null;
  articleTags_some?: ArticleTagWhereInput | null;
  cardTags_some?: CardTagWhereInput | null;
  tagBrandUsages_some?: TagBrandUsageWhereInput | null;
  AND?: TagWhereInput[] | null;
  OR?: TagWhereInput[] | null;
  NOT?: TagWhereInput | null;
}

/**
 * Identifies exactly one "Tag" node with one of these unique combinations of values:
 * - _id
 * - id
 * - lang, title, type
 * - lang, slug, type
 */
export interface TagWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
  lang?: TagLang | null;
  title?: string | null;
  type?: TagType | null;
  slug?: string | null;
}

export interface TaxonomyCreateInput {
  id?: string | null;
  title: string;
  type: TaxonomyType;
  level: string;
  provider: string;
  version: number;
  articleTaxonomies?: TaxonomyNestedArticleTaxonomiesCreateInput | null;
}

export interface TaxonomyNestedArticleTaxonomiesCreateInput {
  create?: ArticleTaxonomyWithForcedTaxonomyCreateInput[] | null;
}

export interface TaxonomyNestedArticleTaxonomiesUpdateInput {
  update?: TaxonomyNestedUpdateArticleTaxonomiesUpdateInput[] | null;
  create?: ArticleTaxonomyWithForcedTaxonomyCreateInput[] | null;
  upsert?: TaxonomyNestedUpsertArticleTaxonomiesUpdateInput[] | null;
  delete?: ArticleTaxonomyWithForcedTaxonomyWhereUniqueInput[] | null;
}

export interface TaxonomyNestedUpdateArticleTaxonomiesUpdateInput {
  where: ArticleTaxonomyWithForcedTaxonomyWhereUniqueInput;
  data: ArticleTaxonomyWithForcedTaxonomyUpdateInput;
}

export interface TaxonomyNestedUpsertArticleTaxonomiesUpdateInput {
  where: ArticleTaxonomyWithForcedTaxonomyWhereUniqueInput;
  update: ArticleTaxonomyWithForcedTaxonomyUpdateInput;
  create: ArticleTaxonomyWithForcedTaxonomyCreateInput;
}

export interface TaxonomyUpdateInput {
  title?: string | null;
  type?: TaxonomyType | null;
  level?: string | null;
  provider?: string | null;
  version?: number | null;
  articleTaxonomies?: TaxonomyNestedArticleTaxonomiesUpdateInput | null;
}

/**
 * Filters the "Taxonomy" nodes by specifying some conditions
 */
export interface TaxonomyWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  title_starts_with?: string | null;
  title_not_starts_with?: string | null;
  title_ends_with?: string | null;
  title_not_ends_with?: string | null;
  title_lt?: string | null;
  title_lte?: string | null;
  title_gt?: string | null;
  title_gte?: string | null;
  slug?: string | null;
  slug_not?: string | null;
  slug_in?: string[] | null;
  slug_not_in?: string[] | null;
  slug_contains?: string | null;
  slug_not_contains?: string | null;
  slug_starts_with?: string | null;
  slug_not_starts_with?: string | null;
  slug_ends_with?: string | null;
  slug_not_ends_with?: string | null;
  slug_lt?: string | null;
  slug_lte?: string | null;
  slug_gt?: string | null;
  slug_gte?: string | null;
  type?: TaxonomyType | null;
  type_not?: TaxonomyType | null;
  type_in?: TaxonomyType[] | null;
  type_not_in?: TaxonomyType[] | null;
  level?: string | null;
  level_not?: string | null;
  level_in?: string[] | null;
  level_not_in?: string[] | null;
  level_contains?: string | null;
  level_not_contains?: string | null;
  level_starts_with?: string | null;
  level_not_starts_with?: string | null;
  level_ends_with?: string | null;
  level_not_ends_with?: string | null;
  level_lt?: string | null;
  level_lte?: string | null;
  level_gt?: string | null;
  level_gte?: string | null;
  provider?: string | null;
  provider_not?: string | null;
  provider_in?: string[] | null;
  provider_not_in?: string[] | null;
  provider_contains?: string | null;
  provider_not_contains?: string | null;
  provider_starts_with?: string | null;
  provider_not_starts_with?: string | null;
  provider_ends_with?: string | null;
  provider_not_ends_with?: string | null;
  provider_lt?: string | null;
  provider_lte?: string | null;
  provider_gt?: string | null;
  provider_gte?: string | null;
  version?: number | null;
  version_not?: number | null;
  version_in?: number[] | null;
  version_not_in?: number[] | null;
  version_lt?: number | null;
  version_lte?: number | null;
  version_gt?: number | null;
  version_gte?: number | null;
  articleTaxonomies_some?: ArticleTaxonomyWhereInput | null;
  AND?: TaxonomyWhereInput[] | null;
  OR?: TaxonomyWhereInput[] | null;
  NOT?: TaxonomyWhereInput | null;
}

/**
 * Identifies exactly one "Taxonomy" node with one of these unique combinations of values:
 * - _id
 * - id
 * - level, provider, title, type, version
 * - level, provider, slug, type, version
 */
export interface TaxonomyWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
  level?: string | null;
  provider?: string | null;
  title?: string | null;
  type?: TaxonomyType | null;
  version?: number | null;
  slug?: string | null;
}

export interface UrlArticleUrlsUrlSearchWhereInput {
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  path?: string | null;
  path_not?: string | null;
  path_in?: string[] | null;
  path_not_in?: string[] | null;
  AND?: UrlArticleUrlsUrlSearchWhereInput[] | null;
  OR?: UrlArticleUrlsUrlSearchWhereInput[] | null;
  NOT?: UrlArticleUrlsUrlSearchWhereInput | null;
}

export interface UrlCreateInput {
  id?: string | null;
  brandKey: BrandKey;
  domain: string;
  path: string;
  publicId: string;
  articleUrls?: UrlNestedArticleUrlsCreateInput | null;
}

export interface UrlNestedArticleUrlsCreateInput {
  create?: ArticleUrlWithForcedUrlCreateInput[] | null;
}

/**
 * Filters the "Url" nodes by specifying some conditions
 */
export interface UrlWhereInput {
  _id?: number | null;
  _id_not?: number | null;
  _id_in?: number[] | null;
  _id_not_in?: number[] | null;
  _id_lt?: number | null;
  _id_lte?: number | null;
  _id_gt?: number | null;
  _id_gte?: number | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  brandKey?: BrandKey | null;
  brandKey_not?: BrandKey | null;
  brandKey_in?: BrandKey[] | null;
  brandKey_not_in?: BrandKey[] | null;
  domain?: string | null;
  domain_not?: string | null;
  domain_in?: string[] | null;
  domain_not_in?: string[] | null;
  domain_contains?: string | null;
  domain_not_contains?: string | null;
  domain_starts_with?: string | null;
  domain_not_starts_with?: string | null;
  domain_ends_with?: string | null;
  domain_not_ends_with?: string | null;
  domain_lt?: string | null;
  domain_lte?: string | null;
  domain_gt?: string | null;
  domain_gte?: string | null;
  path?: string | null;
  path_not?: string | null;
  path_in?: string[] | null;
  path_not_in?: string[] | null;
  path_contains?: string | null;
  path_not_contains?: string | null;
  path_starts_with?: string | null;
  path_not_starts_with?: string | null;
  path_ends_with?: string | null;
  path_not_ends_with?: string | null;
  path_lt?: string | null;
  path_lte?: string | null;
  path_gt?: string | null;
  path_gte?: string | null;
  publicId?: string | null;
  publicId_not?: string | null;
  publicId_in?: string[] | null;
  publicId_not_in?: string[] | null;
  publicId_contains?: string | null;
  publicId_not_contains?: string | null;
  publicId_starts_with?: string | null;
  publicId_not_starts_with?: string | null;
  publicId_ends_with?: string | null;
  publicId_not_ends_with?: string | null;
  publicId_lt?: string | null;
  publicId_lte?: string | null;
  publicId_gt?: string | null;
  publicId_gte?: string | null;
  articleUrls_some?: ArticleUrlWhereInput | null;
  AND?: UrlWhereInput[] | null;
  OR?: UrlWhereInput[] | null;
  NOT?: UrlWhereInput | null;
}

/**
 * Identifies exactly one "Url" node with one of these unique values:
 * - _id
 * - id
 */
export interface UrlWhereUniqueInput {
  _id?: number | null;
  id?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
