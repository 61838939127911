import { History, RemoveRedEye } from '@mui/icons-material';
import {
  EditAppBar,
  useConcurrentialAccess,
  useDialog,
} from '@prismamedia/one-components';
import type { FC } from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { CategoryStatus } from '../../../../../__generated__/queries-web';
import { Skeletons } from '../../../../../components/Skeletons';
import { paths } from '../../../../../routing/Router/paths';
import { auth } from '../../../../../utils/auth';
import { useUpdatePageCategoryLockerId } from '../../../apollo/mutations/category.web.graphql';
import { CategoriesContext } from '../../../categories.context';
import { FormContext } from '../../../form.context';
import { Category, PageRouteParams } from '../../../types';
import { isPageEditView, navigateTo } from '../../../utils';
import { PageHistory } from './components/PageHistory';
import { StatusMenu } from './components/StatusMenu';
import { Title } from './components/Title';
import {
  STATUS_MENU_PLACEHOLDER_HEIGHT,
  STATUS_MENU_PLACEHOLDER_WIDTH,
} from './constants';
import { useStyles } from './styles';

export const PageEditAppBar: FC = () => {
  const classes = useStyles();
  const {
    activeCategory,
    isCategoriesLoading,
    refetchCategories,
    setActiveCategory,
  } = useContext(CategoriesContext);
  const {
    form,
    handlers,
    isFormLoading,
    isFormInvalid,
    isFormPristine,
  } = useContext(FormContext);

  const { closeDialog, openDialog } = useDialog();
  const routeParams = useParams<PageRouteParams>();
  const history = useHistory();

  const searchParams = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search],
  );

  const actions = useMemo(
    () => [
      {
        disabled: !activeCategory?.categoryHistoryCount,
        id: 'history',
        label: 'Historique',
        icon: <History />,
        onClick: () =>
          openDialog(
            <PageHistory
              activeCategory={activeCategory}
              onCloseHistory={() => {
                closeDialog();
              }}
            />,
            { fullWidth: true },
          ),
      },
      {
        disabled: true,
        icon: <RemoveRedEye />,
        id: 'see-front',
        label: 'Visualiser',
        onClick: () => {},
      },
    ],
    [activeCategory, closeDialog, openDialog],
  );

  const updateLockerId = useUpdatePageCategoryLockerId();

  /**
   * We pushing back into the view list
   */
  const handleNavigationBack = useCallback(() => {
    if (activeCategory) {
      // We first unlock the category
      updateLockerId(activeCategory.id, null).catch(() => {});

      const parentPath = activeCategory.path.split('/').slice(0, -1).join('/');
      const newPath =
        activeCategory.children?.length &&
        searchParams.get('from') === 'Breadcrumbs'
          ? activeCategory.path
          : parentPath;

      const pathname = `${generatePath(paths.PAGE_LIST, {
        brandKey: routeParams.brandKey,
        format: routeParams.format,
      })}${newPath}`;

      navigateTo({ pathname, opts: { method: 'REPLACE', search: '' } });
    }
  }, [searchParams, activeCategory, updateLockerId, routeParams]);

  // Need this hack to disable concurrentialAccess while the context set the correct activeCategory
  // TODO refacto this shit -> CategoriesContext
  const [ready, setReady] = useState(false);
  useEffect(() => {
    if (!ready) {
      setReady(true);
    }
  }, [activeCategory, ready]);

  const onLock = useCallback(
    (firstLock) =>
      updateLockerId(activeCategory?.id, auth?.user?.id || null, !firstLock),
    [activeCategory?.id, updateLockerId],
  );

  useConcurrentialAccess({
    currentUser: auth.user,
    lockStatus: ready ? activeCategory : null,
    unlockTimeout: 600000,
    onLock,
    onNavigateBack: handleNavigationBack,
    disableLockForV0: true,
  });

  return (
    <EditAppBar
      actions={actions}
      additionalElement={
        isCategoriesLoading && isPageEditView() ? (
          <Skeletons
            height={STATUS_MENU_PLACEHOLDER_HEIGHT}
            width={STATUS_MENU_PLACEHOLDER_WIDTH}
          />
        ) : (
          <StatusMenu
            status={form.status}
            onStatusChange={(newStatus: CategoryStatus) => {
              handlers.handleFormChange({
                path: ['status'],
                value: newStatus,
              });
            }}
          />
        )
      }
      className={classes.appBar}
      {...(isPageEditView() && {
        concurrentialAccess: {
          lockStatus: activeCategory,
          disableLockForV0: true,
        },
      })}
      navigationBackDisabled={!activeCategory}
      title={<Title />}
      onNavigateBack={handleNavigationBack}
      save={{
        disabled: isCategoriesLoading || isFormInvalid || isFormPristine,
        loading: isFormLoading,
        onClick: () => {
          handlers.handleFormSubmit(form, (newCategory?: Category) => {
            // Form reinitialization
            handlers.setForm(form, true);

            // User redirection to /edit view in case of new category
            if (
              newCategory &&
              newCategory.updatedAt === newCategory.createdAt
            ) {
              refetchCategories();
              setActiveCategory(newCategory);

              // We redirect
              navigateTo({
                pathname: `${generatePath(paths.PAGE_EDIT, routeParams)}/${
                  newCategory.id
                }`,
                opts: { method: 'REPLACE' },
              });
            }
          });
        },
        requiredFields: null,
      }}
    />
  );
};
