import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  innerWrapper: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '5px',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: 'center',
    width: '100%',

    '& > p': {
      color: theme.palette.grey[400],
      padding: theme.spacing(2),
      width: '100%',
    },
  },
  subTitle: {
    fontSize: '1rem',
    fontWeight: 500,

    '& > span': {
      fontWeight: 200,
    },
  },
  wrapper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2, 0),
  },
}));
