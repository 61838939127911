import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface StylesProps {
  appBarHeight: number;
  loadingOrError: boolean;
}

export const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  wrapper: ({ appBarHeight, loadingOrError }) => ({
    height: loadingOrError ? 'auto' : `calc(100vh - ${appBarHeight - 1}px)`,
    flex: loadingOrError ? 1 : undefined,
    overflow: 'hidden',
    position: 'relative',
    margin: theme.spacing(0, 2),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  }),
  dateWrapper: {
    padding: theme.spacing(1, 2),
  },
  titleWrapper: {
    maxWidth: '100%',
    padding: theme.spacing(1, 2),
    alignSelf: 'flex-start',
    maxHeight: 58,
    overflow: 'hidden',
  },
  imageCard: {
    alignSelf: 'stretch',
    margin: theme.spacing(1),
  },
  image: {
    height: '100%',
  },
  chipsList: {
    padding: theme.spacing(0, 1),
  },
  mediaIconsWrapper: {
    padding: theme.spacing(0.5),
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  paidArticleIcon: {
    backgroundColor: 'rgb(243, 167, 18)',
    color: theme.palette.common.white,
    borderRadius: 2,
    fontSize: 14,
    padding: theme.spacing(0, 0.4),
    margin: theme.spacing(0, 1, 0, 0),
    display: 'inline-block',
  },
}));
