import { useDebounce } from '@prismamedia/one-components';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { AsyncSuggestion, SuggestItemType } from './types';

interface AsyncSuggestProps {
  suggestions: AsyncSuggestion;
  searchValue: string;
  children: ({
    items,
    isLoading,
  }: {
    items: SuggestItemType[];
    isLoading?: boolean;
  }) => ReactNode;
}

export const AsyncSuggest: FC<AsyncSuggestProps> = ({
  suggestions,
  searchValue,
  children,
}) => {
  const [suggestItems, setSuggestitems] = useState<SuggestItemType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const debouncedValue = useDebounce<string>(searchValue, 500);

  useEffect(() => {
    let isCancelled = false;
    async function getItems() {
      setIsLoading(true);
      const items = await suggestions(debouncedValue);
      setIsLoading(false);
      if (!isCancelled) {
        setSuggestitems(items);
      }
    }
    if (debouncedValue !== '') {
      getItems();
    } else {
      setSuggestitems([]);
    }
    return () => {
      isCancelled = true;
    };
  }, [debouncedValue, suggestions, searchValue]);

  return <>{children({ items: suggestItems, isLoading })}</>;
};
