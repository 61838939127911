import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import { MULTIMEDIA_MANAGER_TABS_WIDTH } from '@prismamedia/one-components';

const MULTIMEDIA_MANAGER_SEARCH_BLOCK_HEIGHT = 150;
const THUMBNAIL_LIST_HEIGHT = 140;

interface PodcastSearchStyledProps {
  isLoading?: boolean;
  isSelected?: boolean;
  topInnerWrapperHeight?: number;
}
export const useStyles = makeStyles<Theme, PodcastSearchStyledProps | any>(
  (theme) => ({
    busyIcon: {
      bottom: '12px',
      minWidth: 'auto',
      position: 'absolute',
      right: '16px',
    },
    content: {
      width: '80%',
      position: 'relative',
    },
    dialogContent: {
      bottom: 0,
      display: 'flex',
      left: 0,
      overflowY: 'auto',
      paddingBottom: 0,
      paddingLeft: `${MULTIMEDIA_MANAGER_TABS_WIDTH}px`,
      paddingRight: 0,
      paddingTop: '65px',
      position: 'absolute',
      right: 0,
      top: 0,
    },
    input: {
      border: `solid 1px ${theme.palette.grey[300]}`,
      boxShadow: 'none',
      height: '48px',
    },
    inputTextfield: {
      width: '85%',
      padding: theme.spacing(1),
      marginRight: theme.spacing(3),
    },
    innerWrapper: {
      flex: '1 auto',
      width: '100%',
      position: 'relative',
    },
    media: {
      backgroundColor: theme.palette.grey[200],
      height: '100%',
      width: 'auto',
    },
    paperSearch: {
      alignItems: 'center',
      display: 'flex',
      height: theme.spacing(6),
      justifyContent: 'space-between',
      paddingRight: theme.spacing(1),
    },
    selectionIcon: {
      opacity: ({ isSelected }) => (isSelected ? 1 : 0),
    },
    selectedWrapperCard: {
      borderColor: 'transparent !important',
      position: 'relative',

      '&::before': {
        borderRadius: '4px',
        bottom: 0,
        boxShadow: `inset 0 0 0 2px ${theme.palette.primary.main}`,
        content: '""',
        display: 'block',
        left: 0,
        position: 'absolute',
        pointerEvents: 'none',
        right: 0,
        top: 0,
        zIndex: 1,
      },
    },
    subForm: {
      marginTop: theme.spacing(2.5),

      // Tracking link field
      '& > div': {
        width: '80%',
      },

      // Playlist switch
      '& > label': {
        flex: '1 auto',
        justifyContent: 'right',
        marginRight: 0,
        position: 'relative',
        top: '5px',
      },
    },
    title: ({ isLoading }) => ({
      color: isLoading ? theme.palette.grey['500'] : theme.palette.grey['800'],
      fontWeight: 500,
      fontSize: 20,
      marginTop: theme.spacing(0.25),
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    topInnerWrapper: {
      backgroundColor: theme.palette.grey[100],
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(0.5),
      paddingTop: theme.spacing(3),
      position: 'absolute',
      height: `${MULTIMEDIA_MANAGER_SEARCH_BLOCK_HEIGHT}px`,
      width: `calc(100% - (${MULTIMEDIA_MANAGER_TABS_WIDTH}px + ${theme.spacing(
        2.8,
      )}))`,
      zIndex: 2,
    },
    wrapperCard: ({ isLoading, isSelected }) => ({
      cursor: isLoading ? 'default' : 'pointer',
      display: 'flex',
      flexDirection: 'row',
      marginLeft: theme.spacing(3),
      marginRight: `calc(${theme.spacing(3)} - 3px)`,
      maxWidth: '883px',
      pointerEvents: isLoading ? 'none' : 'auto',
      position: 'relative',

      '&:hover $selectionIcon': {
        opacity: isSelected && !isLoading ? 1 : 0.35,
      },
    }),
    wrapperContent: {
      alignItems: 'stretch',
      display: 'flex',
      flexDirection: 'column',
      height: `calc(${THUMBNAIL_LIST_HEIGHT}px - (${theme.spacing(2)} - 1px))`,
      padding: `${theme.spacing(2, 3)} !important`,
      position: 'relative',
      width: '75%',

      '& p': {
        color: theme.palette.grey['500'],
        fontSize: '.85rem',
        paddingRight: theme.spacing(0.85),
      },
    },
    wrapperMedia: ({ isLoading }) => ({
      filter: `grayscale(${isLoading ? 1 : 0})`,
      flex: '1 1',
      height: `calc(${THUMBNAIL_LIST_HEIGHT}px - (${theme.spacing(2)} - 1px))`,
      minWidth: '150px',
      width: 'auto',
    }),
    wrapperList: ({ topInnerWrapperHeight }) => ({
      paddingTop: `calc(${topInnerWrapperHeight + 60}px + ${theme.spacing(1)})`,
    }),
    wrapperListVoid: ({ topInnerWrapperHeight }) => ({
      backgroundColor: theme.palette.grey[100],
      paddingTop: `calc(${topInnerWrapperHeight}px + ${theme.spacing(1)})`,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '& > svg': {
        fill: theme.palette.grey[200],
        fontSize: '7rem',
        marginTop: `calc(-1 * ${theme.spacing(10)})`,
      },
    }),
  }),
);
