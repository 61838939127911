import { gql, useQuery } from '@apollo/client';
import {
  GetPhotos,
  GetPhotosVariables,
} from '../../__generated__/queries-photo';
import { PHOTO_FRAGMENT } from '../fragments/photo.photo.graphql';

export const GET_PHOTOS = gql`
  query GetPhotos(
    $where: PhotoWhereInput
    $first: Int!
    $orderBy: [PhotoOrderByInput!]
    $skip: Int
  ) {
    photoCount: searchPhotosCount(where: $where)
    photos: searchPhotos(
      where: $where
      first: $first
      orderBy: $orderBy
      skip: $skip
    ) {
      ...Photo
    }
  }
  ${PHOTO_FRAGMENT}
`;

export const useGetPhotos = (variables: GetPhotosVariables, skip = false) =>
  useQuery<GetPhotos, GetPhotosVariables>(GET_PHOTOS, {
    fetchPolicy: 'network-only',
    skip,
    variables,
  });
