import {
  Collections,
  Description,
  Headset,
  PhotoCamera,
  Restaurant,
  Videocam,
} from '@mui/icons-material';
import { MEDIA_TYPES_LABEL } from '@prismamedia/one-components';
import { BrandKey } from '../../../../__generated__/queries-web';
import { FotoSearch } from '../../../MediaInput/FotoSearch';
import { PodcastSearch } from '../../../MediaInput/PodcastSearch';

export const getAllowedMedias = (
  brandKey: BrandKey,
  { subjectId }: { subjectId?: string },
) => {
  const isRecipe = brandKey === BrandKey.FAC || brandKey === BrandKey.CAC;

  return [
    {
      Icon: Description,
      label: 'Article',
      type: MEDIA_TYPES_LABEL.ARTICLES,
    },
    ...(isRecipe
      ? [
          {
            Icon: Restaurant,
            label: 'Recette',
            type: MEDIA_TYPES_LABEL.RECIPES,
          },
        ]
      : []),
    {
      Icon: Videocam,
      label: 'Video',
      type: MEDIA_TYPES_LABEL.VIDEOS,
    },
    {
      Icon: Collections,
      label: 'Diapo',
      type: MEDIA_TYPES_LABEL.SLIDESHOWS,
    },
    ...(brandKey !== BrandKey.HBZ
      ? [
          {
            Component: PodcastSearch,
            Icon: Headset,
            label: 'Podcast',
            type: MEDIA_TYPES_LABEL.PODCASTS,
          },
        ]
      : []),
    {
      Component: FotoSearch,
      Icon: PhotoCamera,
      label: 'Photo',
      type: MEDIA_TYPES_LABEL.IMAGES,
      props: { topicId: subjectId },
    },
  ];
};
