import { TextField } from '@mui/material';
import {
  PageWithDrawer,
  NonEditableField,
  getRequiredErrorMessage,
  objectsAreDifferents,
  EditAppBar,
  FieldValidator,
  AutocompleteSelect,
} from '@prismamedia/one-components';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { replaceParams } from '../../../routing/Router';
import { paths } from '../../../routing/Router/paths';
import {
  CreateGoogleGroup,
  GetGoogleGroup_googleGroup,
  GetGoogleGroup_googleGroup_role,
} from '../../../__generated__/queries-auth';
import { useGetRoles } from '../../userRole/userRoleList/roles.auth.graphql';
import { useGetGoogleGroup } from './getGoogleGroup.auth.graphql';
import { useStyles } from './styles';
import {
  useUpdateGoogleGroup,
  useCreateGoogleGroup,
} from './updateGoogleGroup.auth.graphql';

const emptyRole: GetGoogleGroup_googleGroup_role = {
  __typename: 'Role',
  id: '',
  slug: '',
};

const emptyItem: GetGoogleGroup_googleGroup = {
  __typename: 'GoogleGroup',
  id: '',
  groupTitle: '',
  role: emptyRole,
};

const saveMandatoryFields: FieldValidator<GetGoogleGroup_googleGroup>[] = [
  {
    label: 'Titre',
    validate: ({ groupTitle }) => !!groupTitle,
  },
  {
    label: 'Rôle',
    validate: ({ role }) => !!role.id,
  },
];

export const UserGroupEditPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [item, setItem] = useState<GetGoogleGroup_googleGroup | undefined>(
    undefined,
  );
  const { id } = useParams();
  const isCreate = id === 'new';
  const { data, loading, error } = useGetGoogleGroup(id, isCreate);
  const [saveLoading, setSaveLoading] = useState(false);
  const updateItem = useUpdateGoogleGroup();
  const createItem = useCreateGoogleGroup();
  const getRoles = useGetRoles();
  const itemDiff = objectsAreDifferents(
    item,
    isCreate ? emptyItem : data?.googleGroup,
  );
  const requiredFields = getRequiredErrorMessage<GetGoogleGroup_googleGroup>(
    item,
    saveMandatoryFields,
  );

  useEffect(() => {
    setItem(isCreate ? emptyItem : data?.googleGroup || undefined);
  }, [isCreate, data, setItem]);

  const fetchRoles = (search: string) =>
    getRoles({
      first: 1000,
      where: { slug_contains: search },
    }).then((res) =>
      res.roles.map((role) => ({
        id: role.id,
        label: role.slug || '',
        additionnals: { role },
      })),
    );

  const onSave = async () => {
    if (item) {
      setSaveLoading(true);
      const res = isCreate
        ? await createItem({
            groupTitle: item.groupTitle,
            role: {
              connect: { id: item.role.id },
            },
          })
        : await updateItem(item.id, {
            groupTitle: item.groupTitle,
            role: {
              connect: { id: item.role.id },
            },
          });
      setSaveLoading(false);
      if (res && isCreate) {
        history.replace(
          replaceParams(paths.USER_GROUP_EDIT, {
            id: (res.data as CreateGoogleGroup).createGoogleGroup.id,
          }),
        );
      }
    }
  };

  return (
    <>
      <EditAppBar
        title={
          isCreate
            ? item?.groupTitle || 'Nouveau groupe utilisateur'
            : data?.googleGroup?.groupTitle || ''
        }
        onNavigateBack={() => history.push(paths.USER_GROUP_LIST)}
        save={{
          disabled: !itemDiff && !isCreate,
          loading: saveLoading,
          onClick: onSave,
          requiredFields,
        }}
      />
      <PageWithDrawer
        loading={loading}
        error={
          error ||
          (!item && !data?.googleGroup
            ? Error("Ce groupe utilisateur n'existe pas")
            : undefined)
        }
        rightDrawer={
          <NonEditableField
            className={classes.field}
            label="Id"
            value={item?.id}
          />
        }
      >
        {item && (
          <>
            <TextField
              className={classes.field}
              label="Titre"
              onChange={({ target: { value } }) => {
                setItem((prev) =>
                  prev ? { ...prev, groupTitle: value } : prev,
                );
              }}
              value={item.groupTitle}
              variant="standard"
            />
            <AutocompleteSelect
              label="Rôle"
              className={classes.field}
              value={{ id: item.role.id, label: item.role.slug }}
              fetchList={fetchRoles}
              onChange={(value) =>
                setItem((prev) =>
                  prev
                    ? {
                        ...prev,
                        role: value ? value.additionnals?.role : emptyRole,
                      }
                    : prev,
                )
              }
            />
          </>
        )}
      </PageWithDrawer>
    </>
  );
};
