import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import { IconMenuItem, NestedMenuItem } from 'mui-nested-menu';
import { FC } from 'react';
import { useStyles } from './styles';
import { Menu, MenuItem as MenuItemType } from '..';

interface TextSuggestionMenuProps {
  anchorRef: HTMLDivElement | null;
  className?: string;
  handleClose: () => void;
  menuItems: Menu;
  menuKey: 'new' | 'edit' | 'confirm';
}

export const TextSuggestionMenu: FC<TextSuggestionMenuProps> = ({
  anchorRef,
  handleClose,
  menuItems,
  menuKey,
}) => {
  const classes = useStyles();

  return (
    <>
      <Popper
        anchorEl={anchorRef}
        open={!!anchorRef}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        className={classes.popper}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {menuItems[menuKey]?.map(
                    (menuItem: MenuItemType, key: number) =>
                      menuItem.subMenuItems ? (
                        <NestedMenuItem
                          key={key}
                          leftIcon={menuItem.icon()}
                          label={menuItem.label}
                          parentMenuOpen={!!anchorRef}
                        >
                          {menuItem.subMenuItems.map(
                            (subItem: MenuItemType, keySubItems: number) => (
                              <MenuItem
                                key={keySubItems}
                                onClick={() => {
                                  subItem.onClick();
                                  handleClose();
                                }}
                              >
                                {subItem.label}
                              </MenuItem>
                            ),
                          )}
                        </NestedMenuItem>
                      ) : (
                        <IconMenuItem
                          key={key}
                          onClick={menuItem.onClick}
                          leftIcon={menuItem.icon()}
                          label={menuItem.label}
                          className={classes.label}
                        />
                      ),
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
