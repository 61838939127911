import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { combineStyles } from '../../utils/styles/combineStyles';
import { link } from '../../utils/styles/link';

const speedDialStyle = (theme: Theme) => ({
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    zIndex: 1,
  },
  actions: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
  },
  fab: {
    alignSelf: 'flex-end',
  },
});

type withStyleProps =
  | keyof ReturnType<typeof speedDialStyle>
  | keyof ReturnType<ReturnType<typeof link>>;

export type SpeedDialStyleProps = Record<withStyleProps, string>;
const combinedStyles = combineStyles(speedDialStyle, link({}));
export const useStyles = makeStyles(combinedStyles);
