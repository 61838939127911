import { gql, useQuery } from '@apollo/client';
import { GetRole, GetRoleVariables } from '../../../__generated__/queries-auth';

export const GET_ROLE = gql`
  query GetRole($where: RoleWhereUniqueInput!) {
    role(where: $where) {
      id
      slug
    }
  }
`;

export const useGetRole = (id?: string, skip?: boolean) =>
  useQuery<GetRole, GetRoleVariables>(GET_ROLE, {
    variables: { where: { id } },
    skip: !id || skip,
  });
