import { atom, useRecoilState } from 'recoil';
import {
  ArticleStatus,
  BrandKey,
  CategoryFormat,
} from '../__generated__/queries-web';
import { DEFAULT_ALLOWED_FORMAT } from '../pages/Pages/constants';
import { AdvancedSearchParams } from './formatArticleSearchInput';

const searchParams = atom<AdvancedSearchParams>({
  key: 'searchParams',
  default: {},
});
export const useGlobalSearchParams = () => useRecoilState(searchParams);

const brandKey = atom<BrandKey | undefined>({
  key: 'brandKey',
  default: undefined,
});
export const useGlobalBrandKey = () => useRecoilState(brandKey);

const format = atom<CategoryFormat>({
  key: 'format',
  default: DEFAULT_ALLOWED_FORMAT,
});
export const useGlobalFormat = () => useRecoilState(format);

const status = atom<ArticleStatus>({
  key: 'status',
  default: ArticleStatus.Published,
});
export const useGlobalStatus = () => useRecoilState(status);

const thumbnail = atom<string | undefined>({
  key: 'thumbnail',
  default: undefined,
});

export const useGlobalThumbnail = () => useRecoilState(thumbnail);
