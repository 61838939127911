import { isToday } from 'date-fns';
import { ArticleStatus } from '../../../__generated__/queries-web';

export enum Format {
  Article = 'Article',
  Slideshow = 'Slideshow',
  Recipe = 'Recipe',
}

export interface Filters {
  startDate?: Date;
  endDate?: Date;
  status?: ArticleStatus[];
  authorIds?: string[];
  tagIds?: string[];
  sourceIds?: string[];
  categoryIds?: string[];
  qualifierIds?: string[];
  format?: Format[];
}

export const filtersAreDefault = (filters: Filters) =>
  Object.values(filters).filter((val) => !!val).length === 4 &&
  filters.status?.length === 2 &&
  filters.status?.includes(ArticleStatus.Published) &&
  filters.status?.includes(ArticleStatus.Scheduled) &&
  filters.startDate &&
  isToday(filters.startDate) &&
  filters.endDate &&
  isToday(filters.endDate) &&
  filters.format?.length === 3 &&
  filters.format?.includes(Format.Slideshow) &&
  filters.format?.includes(Format.Article) &&
  filters.format?.includes(Format.Recipe);

export const getDefaultFilters = () => ({
  status: [ArticleStatus.Published, ArticleStatus.Scheduled],
  startDate: new Date(),
  endDate: new Date(),
  format: [Format.Slideshow, Format.Article, Format.Recipe],
});
