import { gql } from '@apollo/client';

export const GET_ARTICLE_GOOD_DEAL_PROCESS = gql`
  query GetArticleGoodDealIaProcess($userId: ID!) {
    articleGoodDealAiProcesses(
      first: 30
      orderBy: _id_DESC
      where: { userId: $userId }
    ) {
      article {
        id
      }
      status
      id
      createdAt
      updatedAt
      productTitle
      errorMsg
    }
  }
`;
