import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import React, { FC, ChangeEvent } from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }),
);

interface DatePickerProps {
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  value: string;
}

export const DatePicker: FC<DatePickerProps> = ({ label, onChange, value }) => {
  const classes = useStyles();

  return (
    <TextField
      InputLabelProps={{ shrink: true }}
      className={classes.textField}
      label={label}
      onChange={onChange}
      type="datetime-local"
      value={value}
      variant="standard"
    />
  );
};
