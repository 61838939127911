import { gql, useMutation } from '@apollo/client';
import {
  CreateArticleHistory,
  CreateArticleHistoryVariables,
} from '../../__generated__/queries-web';

const CREATE_ARTICLE_HISTORY = gql`
  mutation CreateArticleHistory($data: ArticleHistoryCreateInput!) {
    createArticleHistory(data: $data) {
      id
    }
  }
`;

export const useCreateArticleHistory = () =>
  useMutation<CreateArticleHistory, CreateArticleHistoryVariables>(
    CREATE_ARTICLE_HISTORY,
  );
