import { Add, ArrowBack } from '@mui/icons-material';
import { Fab, IconButton, Tooltip, Typography } from '@mui/material';
import { HomeAppBar, InfiniteTable } from '@prismamedia/one-components';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { GetGoogleGroups_googleGroups } from '../../../__generated__/queries-auth';
import { replaceParams } from '../../../routing/Router';
import { paths } from '../../../routing/Router/paths';
import { auth } from '../../../utils/auth';
import { useGoogleGroups } from './googleGroups.auth.graphql';
import { useStyles } from './styles';

export const UserGroupListPage: FC = () => {
  const history = useHistory();
  const classes = useStyles();

  const { data, loading, error } = useGoogleGroups({
    first: 1000,
  });

  return (
    <>
      <HomeAppBar
        appDrawerMenu={
          <IconButton
            className={classes.backIcon}
            onClick={() => history.push(paths.CRUD)}
            size="large"
          >
            <ArrowBack />
          </IconButton>
        }
        brandMenu={
          <>
            <Typography className={classes.title} variant="h6">
              Groupes utilisateurs
            </Typography>
          </>
        }
        currentUser={auth.user}
        disconnectUser={auth.logout}
      />
      <div className={classes.listWrapper}>
        <InfiniteTable
          list={data?.googleGroups}
          loading={loading}
          error={error}
          columns={[
            {
              label: 'Titre',
              key: 'groupTitle',
              width: 200,
              flexGrow: 1,
            },
            {
              label: 'Rôle',
              key: 'role',
              cellDataGetter: ({
                rowData,
              }: {
                rowData: GetGoogleGroups_googleGroups;
              }) => rowData.role.slug,
              width: 200,
              flexGrow: 1,
            },
          ]}
          onRowClick={(e, category) => {
            e.preventDefault();
            history.push(
              replaceParams(paths.USER_GROUP_EDIT, {
                id: category.id,
              }),
            );
          }}
        />
      </div>
      <Tooltip title="Créer un groupe utilisateur">
        <Fab
          className={classes.newButton}
          color="primary"
          onClick={() => {
            history.push(paths.USER_GROUP_NEW);
          }}
        >
          <Add />
        </Fab>
      </Tooltip>
    </>
  );
};
