import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100vh - 128px)',
  },
  cards: {
    display: 'grid',
    gridColumnGap: theme.spacing(2),
    gridRowGap: theme.spacing(2),
    gridTemplateColumns: 'repeat(4, 1fr)',
    padding: theme.spacing(18, 5, 3, 5.5),
  },
  speedDial: {
    bottom: '1rem',
    position: 'fixed',
    right: '3rem',
  },
  innerWrapper: {
    alignItems: 'center',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    margin: '0 auto',
    width: '100vw',
  },
}));
