import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import { CardState } from '../../types';
import { requiredFields } from '../../utils/form';

export const useValidateCard = () => {
  const { pushNotification } = useNotification();

  function isCardValid(card: CardState) {
    const valid = requiredFields.every((requiredField) =>
      Boolean(card[requiredField]),
    );

    if (!valid) {
      pushNotification({
        message: `Le contenu saisi n'est pas valide`,
        type: NotificationTypeEnum.error,
      });
    }
    return valid;
  }
  return { isCardValid };
};
