import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridRowGap: 36,
  },
  inputTitle: {
    fontSize: theme.typography.h5.fontSize,
  },
  formGroup: {
    position: 'relative',
  },
  multimediaHeader: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)',
    '& > span': {
      color: '#d0021b',
      fontSize: '14px',
    },
  },
  iconLeft: {
    cursor: 'pointer',
    left: '-35px',
    position: 'absolute',
    top: '18px',
  },
  textFieldLabelIcon: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: '0.5rem',
      color: 'black',
    },
  },
  textFieldChip: {
    marginRight: '1rem',
    '& svg': {
      padding: '0.2rem 0 0.2rem 0.2rem',
    },
  },
}));
