import { useMutation } from '@apollo/client';
import { getBrandLang } from '@prismamedia/one-brandkey';
import { isNull, unionBy } from 'lodash';
import React, { useCallback } from 'react';
import { UPSERT_TAG } from '../../pages/ArticleEdit/queries/upsertTagMutation.web.graphql';
import {
  getArticleEvent,
  getArticleTag,
} from '../../pages/ArticleEdit/utils/models';
import { getCardTag } from '../../pages/Card/CardEdit/utils/models';
import { isCardTag, isEventTag } from '../../types/guards';
import {
  BrandKey,
  GetArticle_article_articleEvents,
  GetArticle_article_articleEvents_category,
  GetArticle_article_articleGuides,
  GetArticle_article_articleGuides_category,
  GetArticle_article_articleTags,
  GetArticle_article_articleTags_tag,
  getCard_card_cardTags,
  getCard_card_cardTags_tag,
  TagCreateInput,
  TagLang,
  UpsertTag,
  UpsertTagVariables,
  UpsertTag_upsertTag,
} from '../../__generated__/queries-web';
import { DragAndDropItem } from '../DragAndDrop';

const convertTag = (tag: any, order: number) => {
  if (!tag.type) return tag;

  if (isEventTag(tag)) {
    return getArticleEvent({ ...tag, order });
  }

  if (isCardTag(tag)) {
    return getCardTag({ ...tag, order });
  }

  return getArticleTag({ ...tag, order });
};

interface Handlers {
  handleAddTag: { (tag: Record<string, any>): void };
  handleChangeTag: { (tag: Record<string, any>): void };
  handleCreateTag: { (tag: Record<string, any>): void };
  handleDeleteTag: { (tag: Record<string, any>): void };
  handleReorderTagList: { (tagList: DragAndDropItem[]): void };
  clear: { (): void };
}
interface UseEditableTagProps {
  handlers: Handlers;
  newTags: (
    | GetArticle_article_articleEvents
    | GetArticle_article_articleTags
    | getCard_card_cardTags
    | GetArticle_article_articleGuides
  )[];
}
const useEditableTag = <
  T extends
    | GetArticle_article_articleEvents_category
    | GetArticle_article_articleTags_tag
    | getCard_card_cardTags_tag
    | GetArticle_article_articleGuides_category
>(
  tags: T[],
  brandKey: BrandKey,
): UseEditableTagProps => {
  const [upsertTag] = useMutation<UpsertTag, UpsertTagVariables>(UPSERT_TAG);
  const [state, setState] = React.useState<any>(null);

  const createNewTag = useCallback(
    async (tag): Promise<UpsertTag_upsertTag | undefined> => {
      const newTag: TagCreateInput = {
        title: tag.title,
        type: tag.type,
        ...(tag.relationId && { relationId: tag.relationId }),
      };

      return upsertTag({
        variables: {
          create: {
            ...newTag,
            lang: getBrandLang(brandKey) as TagLang,
          },
          update: {},
          where: {
            title: newTag.title,
            lang: getBrandLang(brandKey) as TagLang,
            type: newTag.type,
          },
        },
      }).then((result) => result?.data?.upsertTag);
    },
    [brandKey, upsertTag],
  );

  const handlers = React.useMemo(
    (): Handlers => ({
      handleAddTag: (candidateTag) => {
        const isTagAlreadyExists: boolean =
          tags.findIndex(
            (tag) =>
              (!isNull(candidateTag.id) && tag?.id === candidateTag.id) ||
              tag.title === candidateTag.title,
          ) > -1;

        if (isTagAlreadyExists) {
          return;
        }

        if (!candidateTag.id) {
          handlers.handleCreateTag(candidateTag);
          return;
        }

        setState((prev: any) =>
          [...(prev || tags), { ...candidateTag, isActive: false }].map(
            convertTag,
          ),
        );
      },
      handleChangeTag: (newTag) => {
        const newTagList = tags
          .map((tag) => (tag.id === newTag.id ? newTag : tag))
          .map((tag, index) => convertTag(tag, tag.order || index));

        setState(newTagList);
      },
      handleCreateTag: async (tag) => {
        const newTag = await createNewTag(tag);

        if (newTag) {
          handlers.handleAddTag(newTag);
        }
      },
      handleDeleteTag: (removedTag) => {
        const newTagList = tags
          .filter((tag) => tag.id !== removedTag.id)
          .map(convertTag);

        setState(newTagList);
      },
      handleReorderTagList: (ordoredTagList) => {
        const newTagList = unionBy([...ordoredTagList, ...tags], 'id').map(
          convertTag,
        );

        setState(newTagList);
      },
      clear: () => {
        setState(null);
      },
    }),
    [createNewTag, tags],
  );

  return { newTags: state, handlers };
};

export { useEditableTag };
